import gql from 'graphql-tag'

export const saveRouteCostMutation = gql`
  mutation saveRouteCost(
    $market: String!,
    $report: MarketRouteCostInput!,
    $year: Int!,
    $month: String!,
  ) {
    saveRouteCost(
      market: $market,
      report: $report,
      year: $year,
      month: $month,
    ) {
      ${`
        market
      `}
    }
  }
`

export const generateRouteCostExcelFileMutation = gql`
  mutation generateRouteCostExcelFile(
    $markets: [String]!
    $routes: [String]
    $allRoutes: Boolean!
    $date: String!
    $includePreviousYear: Boolean!
  ) {
    generateRouteCostExcelFile(
      markets: $markets
      routes: $routes
      allRoutes: $allRoutes
      date: $date
      includePreviousYear: $includePreviousYear
    ) {
      name
      url
    }
  }
`

export const generateQuarterlyRouteCostExcelFileMutation = gql`
  mutation generateQuarterlyRouteCostExcelFile(
    $market: String!
    $routes: [String]
    $allRoutes: Boolean!
    $year: String!
    $quarter: String!
    $includePreviousYear: Boolean!
  ) {
    generateQuarterlyRouteCostExcelFile(
      market: $market
      routes: $routes
      allRoutes: $allRoutes
      year: $year
      quarter: $quarter
      includePreviousYear: $includePreviousYear
    ) {
      name
      url
    }
  }
`

export const generateCumulativeQuarterlyRouteCostExcelFileMutation = gql`
  mutation generateCumulativeQuarterlyRouteCostExcelFile(
    $market: String!
    $routes: [String]
    $allRoutes: Boolean!
    $year: String!
    $includePreviousYear: Boolean!
  ) {
    generateCumulativeQuarterlyRouteCostExcelFile(
      market: $market
      routes: $routes
      allRoutes: $allRoutes
      year: $year
      includePreviousYear: $includePreviousYear
    ) {
      name
      url
    }
  }
`
