import React from 'react'
import _toNumber from 'lodash/toNumber'

import { FormSection, Field } from 'redux-form'
import { withForm } from '../../connectors/forms'
import { Form, Button } from 'semantic-ui-react'
import FormField from '../../components/FormField'
import CheckboxField from '../../components/CheckboxField'
import DatePicker from '../../components/DatePicker'
import Select from '../../components/Select'

import './styles.scss'

const DateField = ({ label, ...props }) => (
  <Form.Field {...props}>
    <label>{label}</label>
    <DatePicker preventOnClose {...props} />
  </Form.Field>
)

const RadioButton = ({ label, value, onChange, onBlur, error, ...props }) => (
  <Button
    {...props}
    circular
    basic={!!error}
    active={!!value}
    color={error ? 'red' : !!value && 'blue'}
    onBlur={ev => onBlur(!!value)}
    onClick={ev => {
      ev.preventDefault()
      onChange(!value)
    }}
    content={label}
    className="RadioButton"
  />
)
RadioButton.defaultProps = {
  value: false,
}

const FieldRadioButton = props => (
  <Field {...props} component={FormField} formComponent={RadioButton} />
)
const FieldDays = () => (
  <Form.Field width="5">
    <label>Repeat on</label>
    <FieldRadioButton name="sunday" label="S" />
    <FieldRadioButton name="monday" label="M" />
    <FieldRadioButton name="tuesday" label="T" />
    <FieldRadioButton name="wednesday" label="W" />
    <FieldRadioButton name="thursday" label="T" />
    <FieldRadioButton name="friday" label="F" />
    <FieldRadioButton name="saturday" label="S" />
  </Form.Field>
)

@withForm('ScheduleRunForm')
export default class Recurring extends React.PureComponent {
  static defaultProps = {
    everyOptions: [
      { text: 'Days', value: 'days' },
      { text: 'Weeks', value: 'weeks' },
    ],
  }

  render() {
    const { selector, everyOptions } = this.props
    const customize = selector('customize')
    const recurring = selector('is_recurring')
    const every = selector('recurring.every')

    return (
      <React.Fragment>
        <Field
          name="is_recurring"
          label="Recurring"
          className="field"
          component={FormField}
          formComponent={CheckboxField}
          disabled={customize}
          toggle
        />
        {recurring && (
          <FormSection name="recurring">
            <Form.Group>
              <Field
                width="8"
                name="during"
                label="During"
                required
                component={FormField}
                formComponent={DateField}
              />
              <div className="FieldAsOne field wide three">
                <Field
                  name="amount"
                  label="Repeat"
                  defaultValue="1"
                  min="1"
                  normalize={_toNumber}
                  component={FormField}
                  formComponent={Form.Input}
                  type="number"
                />
                <Field
                  name="every"
                  label="every"
                  defaultValue="days"
                  component={FormField}
                  formComponent={Select}
                  options={everyOptions}
                />
              </div>
              {every === 'weeks' && <FieldDays />}
            </Form.Group>
          </FormSection>
        )}
      </React.Fragment>
    )
  }
}
