import React from 'react'
import PropTypes from 'prop-types'

import ScheduleRun from '../ScheduleRun'
import { Link, withRouter } from 'react-router-dom'
import { Card, Icon, Popup, Grid, Message } from 'semantic-ui-react'
import IconThreeDots from '../IconThreeDots'
import './styles.scss'

const OptionsPopup = withRouter(({ match: { url }, data }) => (
  <Popup
    inverted
    trigger={<IconThreeDots />}
    position="bottom center"
    hoverable
  >
    <Grid divided columns="equal">
      <Grid.Column>
        <Link to={`${url}/${data.id}?delete=true`}>
          <Icon
            className="User__Card__Icon__Action"
            name="trash"
            size="large"
          />
        </Link>
      </Grid.Column>
      <Grid.Column>
        <Link to={`${url}/${data.id}?edit=true`}>
          <Icon className="User__Card__Icon__Action" name="edit" size="large" />
        </Link>
      </Grid.Column>
    </Grid>
  </Popup>
))

const cardStyle = color => ({
  borderTopColor: color,
})

const CardInfo = ({ content, icon }) => (
  <Card.Description>
    <Icon name={icon} />
    {content}
  </Card.Description>
)
const UserCard = ({ data, onBottomClick, ...props }) => (
  <Card {...props} style={cardStyle(data.color)} className="User__Card">
    <Card.Content>
      <OptionsPopup data={data} />
      <Card.Header>
        {data.first_name} {data.last_name}
      </Card.Header>
    </Card.Content>
    <Card.Content>
      <CardInfo content={data.contact_type} icon="address card outline" />
      <CardInfo content={data.user_name} icon="user outline" />
      <CardInfo
        content={!!data.primaryPhone ? data.primaryPhone.value : ''}
        icon="phone"
      />
    </Card.Content>
    {data.deactivated && (
      <Card.Content>
        <Message size="mini" negative>
          Inactive
        </Message>
      </Card.Content>
    )}
    {!data.deactivated && <ScheduleRun driver={data.id} />}
  </Card>
)
UserCard.defaultProps = {
  data: {
    id: 1,
    name: 'Gregory Mcdonal',
    type: 'Driver',
    user: 'Gregory.Mcdonal',
    phone: '555-555-5555',
    color: '#C0C0C0',
  },
  onBottomClick: () => {},
}

UserCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    user: PropTypes.string,
    phone: PropTypes.string,
    color: PropTypes.string,
  }),
  onBottomClick: PropTypes.func,
}

export default UserCard
