import * as QL from '../api/Query/partnerships'
import WithQuery from './query'
import WithMutator from './mutator'
import { Partnerships } from '../selectors/partnerships'

export const WithPartnerships = WithQuery({
  QL,
  queryName: 'partnerships',
  selector: Partnerships,
  fetchPolicy: 'network-only',
  variablesPicker: props => {
    return { name: 'all' }
  },
})

const refetchPartnerships = [
  { query: QL.partnerships, variables: { name: 'all' } },
]
export const WithPartnershipsCreator = WithMutator('createPartnership', {
  refetchQueries: refetchPartnerships,
})

export const WithPartnershipsUpdater = WithMutator('updatePartnership', {
  refetchQueries: refetchPartnerships,
})
