import _get from 'lodash/get'
import _map from 'lodash/map'
import _max from 'lodash/max'
import PropTypes from 'prop-types'
import React from 'react'

import { Field, reduxForm } from 'redux-form'
import { Button, Form, Grid, Message } from 'semantic-ui-react'
import validator from 'validator'

import { weather } from './weathers'

import DatePicker from '../../components/DatePicker'
import DriverPicker from '../../components/DriverPicker'
import FormField from '../../components/FormField'
import ModalTrigger from '../../components/ModalTrigger'
import Select from '../../components/Select'
import AssignRouteLocationForm from '../../forms/AssignRouteLocation'

import RunLocationInput from './RunLocationInput'

import './styles.scss'

export const validate = args => {
  const errors = {}
  const fields = [
    { id: 'market' },
    { id: 'driver' },
    { id: 'date' },
    { id: 'route' },
    // { id: 'end_miles', num: true },
    { id: 'end_time' },
    // { id: 'start_miles', num: true },
    { id: 'start_time' },
    { id: 'total_cloth', num: true },
    { id: 'total_misc', num: true },
    { id: 'total_trash', num: true },
    { id: 'truck_number' },
    { id: 'weather' },
  ]

  fields.forEach(field => {
    if (args[field.id] === undefined || args[field.id] === null) {
      errors[field.id] = 'invalid/required'
    }
  })

  fields.filter(field => field.num).forEach(field => {
    if (args[field.id] && !validator.isNumeric(String(args[field.id]))) {
      errors[field.id] = 'invalid/format'
    }
  })

  if (args.start_time > args.end_time) {
    errors.end_time = 'invalid/value'
  }

  if (
    args.end_miles &&
    !validator.isInt(String(args.end_miles), {
      min: parseInt(args.start_miles, 10),
    })
  ) {
    errors.end_miles = 'invalid/value'
  }

  return errors
}

const validates = {
  positive: val => {
    if (!val) return undefined
    return val && validator.isInt(String(val), { min: 0 })
      ? undefined
      : 'invalid/value'
  },
}

const DateField = ({ label, ...props }) => (
  <Form.Field {...props}>
    <label>{label}</label>
    <DatePicker singleDay={true} {...props} />
  </Form.Field>
)

@reduxForm({
  form: 'CreateRunForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  pure: false,
  validate,
})
export default class CreateRunForm extends React.PureComponent {
  static propTypes = {
    markets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    drivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      })
    ),
    helpers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      })
    ),
    onMarketChange: PropTypes.func.isRequired,
    onRouteChange: PropTypes.func.isRequired,
  }

  state = {
    market: null,
  }

  componentDidMount() {
    const {
      onMarketChange,
      onRouteChange,
      initialValues: { market, route } = {},
    } = this.props

    if (market) {
      onMarketChange(market)
    }

    if (route) {
      onRouteChange(route)
    }
  }

  changeMarket = (e, marketId) => {
    const { onMarketChange } = this.props
    onMarketChange(marketId)
    this.setState({
      market: marketId,
    })
  }

  changeRoute = (e, routeId) => {
    const { onRouteChange } = this.props
    onRouteChange(routeId)
  }

  fixCollection(items, getText) {
    if (!items) {
      return []
    }

    return items.map(item => ({
      value: item.id,
      text: getText ? getText(item) : item.name,
    }))
  }

  render() {
    const {
      formError,
      handleSubmit,
      addLocation,
      markets,
      routes,
      drivers,
      driver,
      helpers,
      loading,
      route,
      initialValues: values,
    } = this.props

    const locations = _get(values, 'locations')
    const maxStopSequence = _max(
      _map(locations, location => location.location.stop_sequence)
    )
    const market = this.state.market || values.market

    return (
      <Form onSubmit={handleSubmit} className="CreateRunForm__Form">
        <Form.Group widths="equal">
          <Field
            name="market"
            label="Market"
            component={FormField}
            required
            formComponent={Select}
            options={this.fixCollection(markets)}
            loading={loading}
            onChange={this.changeMarket}
            disabled={!!values.id}
          />
          <Field
            name="route"
            label="Route"
            required
            component={FormField}
            formComponent={Select}
            options={this.fixCollection(routes)}
            loading={loading}
            onChange={this.changeRoute}
            disabled={!!values.id}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="driver"
            label="Driver"
            component={FormField}
            required
            formComponent={DriverPicker}
            drivers={this.fixCollection(
              drivers,
              item => `${item.first_name} ${item.last_name}`
            )}
            driver={driver}
            loading={loading}
          />
          <Field
            name="helper"
            label="Helper"
            component={FormField}
            formComponent={DriverPicker}
            drivers={this.fixCollection(
              helpers,
              item => `${item.first_name} ${item.last_name}`
            )}
            loading={loading}
            allowEmpty={true}
            emptyOptionText="No helper"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="date"
            label="Date"
            required
            component={FormField}
            onBlur={e => e.preventDefault()}
            formComponent={DateField}
          />
          <Field
            name="weather"
            label="Weather"
            required
            component={FormField}
            formComponent={Select}
            options={weather}
          />
          <Field
            name="truck_number"
            label="Truck Number"
            required
            component={FormField}
            formComponent={Form.Input}
            validate={[validates.positive]}
            placeholder="1111"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="start_time"
            label="Start Time"
            required
            component={FormField}
            type="datetime-local"
            formComponent={Form.Input}
          />
          <Field
            name="end_time"
            label="End Time"
            required
            component={FormField}
            type="datetime-local"
            formComponent={Form.Input}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="start_miles"
            label="Start Mileage"
            type="number"
            component={FormField}
            formComponent={Form.Input}
            validate={[validates.positive]}
            placeholder="0"
          />
          <Field
            name="end_miles"
            label="End Mileage"
            type="number"
            component={FormField}
            formComponent={Form.Input}
            validate={[validates.positive]}
            placeholder="0"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="total_cloth"
            label="Total Cloth"
            required
            type="number"
            component={FormField}
            formComponent={Form.Input}
            placeholder="0"
          />
          <Field
            name="total_misc"
            label="Total Misc"
            required
            type="number"
            component={FormField}
            formComponent={Form.Input}
            placeholder="0"
          />
          <Field
            name="total_trash"
            label="Total Trash"
            required
            type="number"
            component={FormField}
            formComponent={Form.Input}
            placeholder="0"
          />
        </Form.Group>

        <Form.Field required>
          <label>Location Weights</label>
        </Form.Field>
        <Grid>
          {_map(locations, (location, idx) => (
            <RunLocationInput
              name={`locations[${idx}]`}
              key={location.location.id}
              location={location}
              color={_get(route, 'color')}
              market={market}
              className="create_run_form_location_input"
            />
          ))}
          {route && (
            <Grid.Row textAlign="right" style={{ order: maxStopSequence + 1 }}>
              <Grid.Column>
                <ModalTrigger
                  title="Adding Location to run"
                  trigger={
                    <Button style={{ order: maxStopSequence + 1 }}>
                      Add Location
                    </Button>
                  }
                  form={
                    <AssignRouteLocationForm
                      addLocationToRun={addLocation}
                      isCustomRouteForRun={true}
                      routeId={route.id}
                      route={route}
                      simple_locations={false}
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
