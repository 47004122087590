import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Dropdown, Grid } from 'semantic-ui-react'

import DateRangePicker from '../../components/DateRangePicker'
import { withRoutes } from '../../connectors/routes'
import moment from 'moment'
import { withAuth } from '../../connectors/auth'

const Filters = withRouter(
  withRoutes(
    ({
      match: { url },
      location: { search },
      history,
      routes: { loading, routes },
      drivers,
      auth: { user: { markets, primary_market } },
      currentMarket,
      setCurrentMarket,
    }) => {
      const query = new URLSearchParams(search)
      const marketId = query.get('market')

      const [filterState, setFilterState] = useState({
        market: !!marketId
          ? marketId
          : !!currentMarket
            ? currentMarket.id
            : !!primary_market ? primary_market : null,
        routeId: query.get('routeId') || null,
        driverId: query.get('driverId') || null,
        defaultStart: moment()
          .subtract(2, 'month')
          .set('date', 1)
          .startOf('day')
          .toISOString(),
        defaultEnd: moment()
          .add(1, 'month')
          .set('date', 1)
          .subtract(1, 'day')
          .endOf('day')
          .toISOString(),
        start: moment()
          .subtract(2, 'month')
          .set('date', 1)
          .startOf('day')
          .toISOString(),
        end: moment()
          .add(1, 'month')
          .set('date', 1)
          .subtract(1, 'day')
          .endOf('day')
          .toISOString(),
      })

      function setFilter(name, value = null) {
        switch (name) {
          case 'market':
            setFilterState({
              ...filterState,
              market: value,
              routeId: null,
              driverId: null,
            })
            setCurrentMarket(value)
            break
          case 'routeId':
            setFilterState({
              ...filterState,
              routeId: value,
            })
            break
          case 'driverId':
            setFilterState({
              ...filterState,
              driverId: value,
            })
            break
          case 'dateRange':
            if (!value) {
              setFilterState({
                ...filterState,
                start: moment()
                  .subtract(2, 'month')
                  .set('date', 1)
                  .startOf('day')
                  .toISOString(),
                end: moment()
                  .add(1, 'month')
                  .set('date', 1)
                  .subtract(1, 'day')
                  .endOf('day')
                  .toISOString(),
              })
            } else {
              setFilterState({
                ...filterState,
                start: moment(value.start)
                  .startOf('day')
                  .toISOString(),
                end: moment(value.end)
                  .startOf('day')
                  .toISOString(),
              })
            }
            break
          default:
            break
        }
      }

      useEffect(() => {
        if (query.has('start') && query.has('end')) {
          setFilterState({
            ...filterState,
            start: moment(query.get('start'))
              .startOf('day')
              .toISOString(),
            end: moment(query.get('end'))
              .startOf('day')
              .toISOString(),
          })
        }
      }, [])

      useEffect(
        () => {
          if (filterState.market) query.set('market', filterState.market)
          else query.delete('market')

          if (filterState.routeId) query.set('routeId', filterState.routeId)
          else query.delete('routeId')

          if (filterState.driverId) query.set('driverId', filterState.driverId)
          else query.delete('driverId')

          if (filterState.start && filterState.end) {
            query.set('start', filterState.start)
            query.set('end', filterState.end)
          } else {
            query.delete('start')
            query.delete('end')
          }

          history.push(`${url}?${query.toString()}`)
        },
        [
          filterState.market,
          filterState.routeId,
          filterState.driverId,
          filterState.start,
          filterState.end,
        ]
      )

      return (
        <Grid className="Reports__Filters">
          <Grid.Column mobile={16} computer={4}>
            <Dropdown
              fluid
              defaultValue={query.get('market')}
              value={query.get('market')}
              selection
              options={
                markets && [
                  { text: 'All Markets', value: null },
                  ...markets.map(market => ({
                    text: market.name,
                    value: market.id,
                  })),
                ]
              }
              placeholder="Select Market"
              onChange={(e, { value }) => {
                if (value) {
                  setFilter('market', value)
                } else {
                  setFilter('market', null)
                }
              }}
            />
          </Grid.Column>

          <Grid.Column mobile={16} computer={4} key="col1">
            <Dropdown
              fluid
              defaultValue={query.get('routeId')}
              value={query.get('routeId')}
              selection
              options={
                routes && [
                  { text: 'All Routes', value: null },
                  ...routes.map(r => ({
                    text: r.name,
                    value: r.id,
                  })),
                ]
              }
              placeholder="Select Route"
              loading={loading}
              disabled={loading || !routes.length || !query.get('market')}
              onChange={(e, { value }) => {
                if (value) {
                  setFilter('routeId', value)
                } else {
                  setFilter('routeId', null)
                }
              }}
            />
          </Grid.Column>

          <Grid.Column mobile={16} computer={4} key="col3">
            <Dropdown
              fluid
              selection
              defaultValue={query.get('driverId')}
              value={query.get('driverId')}
              options={[{ text: 'All Drivers', value: null }, ...drivers]}
              placeholder="Select Driver"
              disabled={false}
              onChange={(e, { value }) => {
                if (value) {
                  setFilter('driverId', value)
                } else {
                  setFilter('driverId', null)
                }
              }}
            />
          </Grid.Column>

          <Grid.Column mobile={16} computer={4} key="col5">
            <DateRangePicker
              placement="bottomEnd"
              placeholder="Date Range"
              defaultValue={[
                new Date(filterState.defaultStart),
                new Date(filterState.defaultEnd),
              ]}
              value={[new Date(query.get('start')), new Date(query.get('end'))]}
              hoverRange="month"
              ranges={[]}
              onClean={() => {
                setFilter('dateRange', null)
              }}
              onOk={([start, end]) => {
                if (start && end) {
                  setFilter('dateRange', {
                    start,
                    end,
                  })
                } else {
                  setFilter('dateRange', null)
                }
              }}
            />
          </Grid.Column>
        </Grid>
      )
    }
  )
)

export default withAuth(Filters)
