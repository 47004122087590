import {
  COMPETITORS_FETCH_SUCCESS,
  COMPETITORS_REMOVE_SUCCESS,
  COMPETITORS_ADD_SUCCESS,
  COMPETITORS_ERROR_OCCURRED,
  COMPETITORS_START_lOADING,
  COMPETITORS_FINISH_lOADING,
  COMPETITORS_RESET_STATUS,
} from '../actions/competitors'

const status = {
  default: null,
  success: 'SUCCESS',
  failed: 'FAILED',
}

const initialState = {
  currentCompetitors: [],
  competitorHistory: [],
  inProgress: false,
  message: null,
  status: status.default,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPETITORS_FETCH_SUCCESS:
      return {
        ...state,
        inProgress: false,
        currentCompetitors: action.payload.current_competitors || [],
        competitorHistory: action.payload.competitor_history || [],
        message: null,
        status: status.default,
      }
    case COMPETITORS_START_lOADING:
      return {
        ...state,
        inProgress: true,
        message: null,
        status: status.default,
      }
    case COMPETITORS_FINISH_lOADING:
      return {
        ...state,
        inProgress: false,
      }
    case COMPETITORS_REMOVE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        currentCompetitors: action.payload.current_competitors || [],
        competitorHistory: action.payload.competitor_history || [],
        message: 'Competitor has been removed successfully',
        status: status.success,
      }
    case COMPETITORS_ADD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        currentCompetitors: action.payload.current_competitors || [],
        competitorHistory: action.payload.competitor_history || [],
        message: 'Competitor has been added successfully',
        status: status.success,
      }
    case COMPETITORS_ERROR_OCCURRED:
      return {
        ...state,
        inProgress: false,
        message: action.payload,
        status: status.failed,
      }
    case COMPETITORS_RESET_STATUS:
      return {
        ...state,
        inProgress: false,
        message: null,
        status: status.default,
      }
    default:
      return state
  }
}

export default reducer
