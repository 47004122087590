import gql from 'graphql-tag'

export const maintenanceRequestModel = `
  id
  binId
  userId
  locationId
  date
  notes
  photos
  reasons_front_side
  reasons_back_side
  reasons_roof
  reasons_left_side
  reasons_right_side
  other
  status
`

export const maintenanceRequestWithDetailsModel = `
  id
  binId
  userId
  locationId
  date
  notes
  photos
  reasons_front_side
  reasons_back_side
  reasons_roof
  reasons_left_side
  reasons_right_side
  other
  status
  bin {
    serial_number
  }
  user {
    first_name
    last_name
  }
  location {
    name
  }
`

export const maintenanceRequestsQuery = gql`
  query maintenanceRequests($status: String) {
    maintenanceRequests (
      filter: { status: $status }
    ) {
      ${maintenanceRequestWithDetailsModel}
    }
  }
`

export const maintenanceRequest = gql`
  query($id: String!) {
    maintenanceRequestData(id: $id) {
      ${maintenanceRequestModel}
    }
  }
`

export const createMaintenanceRequest = gql`
  mutation createMaintenanceRequest($maintenanceRequest: MaintenanceRequestInput!) {
    createMaintenanceRequest(maintenanceRequest: $maintenanceRequest) {
      ${maintenanceRequestModel}
    }
  }
`

export const updateMaintenanceRequest = gql`
  mutation updateMaintenanceRequest($maintenanceRequestId: String!, $maintenanceRequest: MaintenanceRequestInput!) {
    updateMaintenanceRequest(maintenanceRequestId: $maintenanceRequestId, maintenanceRequest: $maintenanceRequest) {
      ${maintenanceRequestModel}
    }
  }
`

export const deleteMutation = gql`
  mutation($maintenanceRequestId: String!) {
    deleteMaintenanceRequest(id: $maintenanceRequestId){
      ${maintenanceRequestModel}
    }
  }
`
