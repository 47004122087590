import FormField from '../../components/FormField'
import Select from '../../components/Select'
import moment from 'moment'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Form, Header } from 'semantic-ui-react'
import validator from 'validator'
import './style.scss'

export const validate = args => {
  const errors = {}
  const fields = [
    {
      name: 'name',
      rules: {
        required: true,
      },
    },
    {
      name: 'leased_atrs_rep',
      rules: {
        maxChars: 100,
        alphabeticOnly: true,
      },
    },
    {
      name: 'signed_atrs_rep',
      rules: {
        maxChars: 100,
        alphabeticOnly: true,
      },
    },
    {
      name: 'verbal_atrs_rep',
      rules: {
        maxChars: 100,
        alphabeticOnly: true,
      },
    },
  ]

  fields.forEach(field => {
    if (field.rules) {
      if (
        field.rules.required &&
        (!args[field.name] || validator.isEmpty(args[field.name]))
      ) {
        errors[field.name] = 'invalid/required'
      }

      if (field.rules.maxChars) {
        if (args[field.name]) {
          if (args[field.name].length > field.rules.maxChars) {
            errors[field.name] = 'invalid/maximum_limit'
          }
        }
      }

      if (field.rules.alphabeticOnly) {
        if (args[field.name]) {
          const regex = new RegExp('^[A-Za-z ]*$', 'gm')
          if (!regex.test(args[field.name])) {
            errors[field.name] = 'invalid/alphabetic'
          }
        }
      }
    }
  })

  return errors
}

const storeOrPMOptions = [
  { key: 'Store', text: 'Store', value: 'Store' },
  { key: 'PM Company', text: 'PM Company', value: 'PM Company' },
]

const DateField = ({ label, ...props }) => {
  const inputProps = { ...props }

  if (inputProps.value) {
    inputProps.value = moment(inputProps.value)
    inputProps.value = inputProps.value.format()
    inputProps.value = inputProps.value.split('T')[0]
  }

  return (
    <Form.Field {...inputProps}>
      <label>{label}</label>
      <input {...inputProps} />
    </Form.Field>
  )
}
@reduxForm({
  form: 'PartnershipForm',
  validate,
})
export default class PartnershipForm extends React.PureComponent {
  render() {
    const { handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Field
            name="name"
            label="Name"
            placeholder="Partnership Type"
            component={FormField}
            required
            formComponent={Form.Input}
          />
          <Field
            name="telephone"
            label="Telephone Number"
            placeholder="Phone Number"
            component={FormField}
            formComponent={Form.Input}
          />
        </Form.Group>
        <Form.Group>
          <Field
            name="store"
            label="Store/PM Company"
            placeholder="Company Name"
            component={FormField}
            formComponent={Form.Input}
            width={5}
            className="store-name"
          />
          <Field
            name="store_or_pm"
            label="&nbsp;"
            component={FormField}
            formComponent={Select}
            options={storeOrPMOptions}
            width={4}
            className="store-type"
          />
          <Field
            name="position"
            label="Position"
            placeholder="Job Title"
            component={FormField}
            formComponent={Form.Input}
            width={8}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="email"
            label="email"
            placeholder="name@domain.com"
            component={FormField}
            formComponent={Form.Input}
          />
          <Field
            name="address"
            label="Address"
            placeholder="Company Address"
            component={FormField}
            formComponent={Form.Input}
          />
        </Form.Group>
        <Header>Leased Location</Header>
        <Form.Group widths="equal">
          <Field
            name="leased_location_cost_per_month"
            label="Cost/month"
            placeholder="TX"
            component={FormField}
            formComponent={Form.Input}
          />
          <Field
            name="leased_location_period_start"
            label="Lease Period Start"
            component={FormField}
            onBlur={e => e.preventDefault()}
            formComponent={DateField}
            type="date"
          />
          <Field
            name="leased_location_period_end"
            label="Lease Period End"
            component={FormField}
            onBlur={e => e.preventDefault()}
            formComponent={DateField}
            type="date"
          />
          <Field
            name="leased_atrs_rep"
            label="ATRS rep"
            placeholder="ATRS responsible person"
            component={FormField}
            formComponent={Form.Input}
          />
        </Form.Group>
        <Header>Signed Location</Header>
        <Form.Group widths="equal">
          <Field
            name="signed_location_by"
            label="Signed By"
            placeholder="Enter Name"
            component={FormField}
            formComponent={Form.Input}
          />
          <Field
            name="signed_location_date"
            label="Date Signed"
            component={FormField}
            onBlur={e => e.preventDefault()}
            formComponent={DateField}
            type="date"
          />
          <Field
            name="signed_atrs_rep"
            label="ATRS rep"
            placeholder="ATRS responsible person"
            component={FormField}
            formComponent={Form.Input}
          />
        </Form.Group>
        <Header>Verbal Location</Header>
        <Form.Group widths="equal">
          <Field
            name="verbal_location_by"
            label="Verbal By"
            placeholder="Enter Name"
            component={FormField}
            formComponent={Form.Input}
          />
          <Field
            name="verbal_location_date"
            label="Verbal Date"
            component={FormField}
            onBlur={e => e.preventDefault()}
            formComponent={DateField}
            type="date"
          />
          <Field
            name="verbal_atrs_rep"
            label="ATRS rep"
            placeholder="ATRS responsible person"
            component={FormField}
            formComponent={Form.Input}
          />
        </Form.Group>
      </Form>
    )
  }
}
