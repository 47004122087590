import React from 'react'
import { Field, reduxForm } from 'redux-form'
import validator from 'validator'
import { Form, Message } from 'semantic-ui-react'

import FormField from '../../components/FormField'
import LocationsInput from '../../components/LocationsInput'

export const validate = args => {
  const errors = {}
  const fields = ['name']

  fields.forEach(field => {
    if (!args[field] || validator.isEmpty(args[field])) {
      errors[field] = 'invalid/required'
    }
  })

  return errors
}

@reduxForm({
  form: 'MarketForm',
  validate,
})
export default class MarketForm extends React.Component {
  render() {
    const { formError, handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Market Name"
          placeholder="HOUSTON, TORONTO, etc"
          component={FormField}
          required
          formComponent={Form.Input}
        />
        <Field
          name="unloading_locations"
          label="To add an unloading location, type in an address below:"
          component={FormField}
          required
          formComponent={LocationsInput}
        />
        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
