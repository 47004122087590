import { withMarketData, withMarkets } from '../../connectors/markets'
import React from 'react'
import { Divider } from 'semantic-ui-react'
import 'url-search-params-polyfill'

import { withAuth } from '../../connectors/auth'
import { WithCumulativeQuarterlyRoutCostReport } from '../../connectors/routeCostAnalysis'
import CumulativeQuarterlyReviewRouteCostReport from './CumulativeQuarterlyReviewRouteCostReport'
import Filters from './Filters'

@withAuth
@withMarkets
@withMarketData
@WithCumulativeQuarterlyRoutCostReport
export class CumulativeQuarterlyReviewRouteCostAnalysisViewIndex extends React.Component {
  extractRoutes() {
    const { cumulativeQuarterlyRouteCostData } = this.props
    const routes = []
    if (!cumulativeQuarterlyRouteCostData) return routes
    if (cumulativeQuarterlyRouteCostData.length === 0) return routes

    cumulativeQuarterlyRouteCostData.forEach(yearReport => {
      yearReport.months.forEach(monthReport => {
        monthReport.report.routes.forEach(routeReport => {
          if (!routes.some(route => route.id === routeReport.id))
            routes.push({
              id: routeReport.id,
              name: routeReport.name,
            })
        })
      })
    })

    return routes
  }

  render() {
    const {
      location: { search },
      auth: { user: { markets } },
      loading,
      cumulativeQuarterlyRouteCostData,
    } = this.props

    const query = new URLSearchParams(search)
    const routes = this.extractRoutes()

    return (
      <div>
        <Divider hidden />
        <Filters loading={loading} routes={routes} />
        <Divider hidden />
        <CumulativeQuarterlyReviewRouteCostReport
          marketId={query.get('market')}
          routes={routes}
          year={query.get('year')}
          routeId={query.get('route') ? query.get('route').split(',') : []}
          include_previous_year={
            query.get('include-previous-year') &&
            query.get('include-previous-year') === 'true'
              ? true
              : false
          }
          markets={markets}
          loading={loading}
          cumulativeQuarterlyRouteCostData={cumulativeQuarterlyRouteCostData}
        />
      </div>
    )
  }
}
