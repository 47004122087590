import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Popup } from 'semantic-ui-react'
import classNames from 'classnames'

import './styles.scss'
const NavLink = ({ tooltip, icon, size, active }) => {
  const trigger = (
    <div
      className={classNames('NavLink__Button', {
        'NavLink__Button-active': active,
      })}
    >
      <Icon name={icon} size={size} />
    </div>
  )
  return (
    <Popup
      position="right center"
      trigger={trigger}
      content={tooltip}
      on="hover"
      inverted
    />
  )
}

NavLink.propTypes = {
  active: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    'mini',
    'tiny',
    'small',
    'large',
    'big',
    'huge',
    'massive',
  ]),
}

NavLink.defaultProps = {
  active: false,
  size: 'huge',
}

export default NavLink
