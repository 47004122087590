import React from 'react'
import 'url-search-params-polyfill'
import { withMarkets, withMarketData } from '../../connectors/markets'
import { withRoutes, withRoutesReport } from '../../connectors/routes'
import { generateRangeDate } from '../../utils/date'

import { Divider } from 'semantic-ui-react'

import Filters from '../ReportsLeasedLocations/Filters'

import LocationsReportTable from './LocationsReportTable'

@withMarkets
@withMarketData
@withRoutesReport
@withRoutes
export default class ReportsLocationsPropertyManager extends React.PureComponent {
  state = {
    pms: [],
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps
    const {
      getRouteReports,
      getRoutesByMarket,
      getMarketReports,
      setCurrent,
      location: { search: oldSearch },
    } = this.props
    const query = new URLSearchParams(search)
    const oldQuery = new URLSearchParams(oldSearch)

    const getRange = _ => {
      const range = generateRangeDate(query.get('range'))
      return {
        start: range.start.toISOString(),
        end: range.end.toISOString(),
      }
    }
    const market = query.get('market')
    if (
      market &&
      query.get('route') &&
      query.get('range') &&
      search !== oldSearch
    ) {
      getRouteReports(query.get('route'), getRange())
    } else if (market && query.get('range') && search !== oldSearch) {
      getMarketReports({
        ids: [market],
        ...getRange(),
      })
    }
    if (market && market !== oldQuery.get('market')) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  componentDidMount() {
    const {
      getMarkets,
      getRoutesByMarket,
      setCurrent,
      location: { search },
    } = this.props

    getMarkets()

    const query = new URLSearchParams(search)
    const market = query.get('market')
    if (market) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  setPMs = pms => {
    this.setState({
      ...this.state,
      pms,
    })
  }

  render() {
    const { location: { search } } = this.props

    const query = new URLSearchParams(search)

    return (
      <div>
        <Divider hidden />
        <Filters pms={this.state.pms} reportType="property_manager" />
        <Divider hidden />
        <LocationsReportTable
          reportType="property_manager"
          setPMs={this.setPMs}
          routeId={query.get('route')}
          marketId={query.get('market')}
          start={query.get('start') || undefined}
          end={query.get('end') || undefined}
          npo={query.get('npo')}
          pm={query.get('pm')}
          status={query.get('status')}
        />
      </div>
    )
  }
}
