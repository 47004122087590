import { withMarketData, withMarkets } from '../../connectors/markets'
import React from 'react'
import 'url-search-params-polyfill'

import { Divider } from 'semantic-ui-react'

import { withAuth } from '../../connectors/auth'
import { WithQuarterlyRoutCostReport } from '../../connectors/routeCostAnalysis'
import Filters from './Filters'
import QuarterlyRouteCostReport from './QuarterlyRouteCostReport'

@withAuth
@withMarkets
@withMarketData
@WithQuarterlyRoutCostReport
export class QuarterlyRouteCostAnalysisViewIndex extends React.Component {
  extractRoutes() {
    const { getQuarterlyRouteCostData } = this.props
    const routes = []
    if (!getQuarterlyRouteCostData) return routes
    if (getQuarterlyRouteCostData.length === 0) return routes

    getQuarterlyRouteCostData.forEach(yearReport => {
      yearReport.months.forEach(monthReport => {
        monthReport.report.routes.forEach(routeReport => {
          if (!routes.some(route => route.id === routeReport.id))
            routes.push({
              id: routeReport.id,
              name: routeReport.name,
            })
        })
      })
    })

    return routes
  }

  render() {
    const {
      location: { search },
      auth: { user: { markets } },
      loading,
      getQuarterlyRouteCostData,
    } = this.props

    const query = new URLSearchParams(search)
    const routes = this.extractRoutes()

    return (
      <div>
        <Divider hidden />
        <Filters loading={loading} routes={routes} />
        <Divider hidden />
        <QuarterlyRouteCostReport
          marketId={query.get('market')}
          routes={routes}
          year={query.get('year')}
          routeId={query.get('route') ? query.get('route').split(',') : []}
          include_previous_year={
            query.get('include-previous-year') &&
            query.get('include-previous-year') === 'true'
              ? true
              : false
          }
          markets={markets}
          loading={loading}
          quarterlyRouteCostData={getQuarterlyRouteCostData}
        />
      </div>
    )
  }
}
