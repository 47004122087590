import React from 'react'
import Geosuggest from 'react-geosuggest'
import { Form, Button } from 'semantic-ui-react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import DataTable from '../DataTable'

import './styles.scss'

export default class LocationsInput extends React.PureComponent {
  state = {
    location: null,
    locations: [],
  }

  componentWillReceiveNewProps(nextProps) {
    const { value } = this.props
    const { value: nextValue } = nextProps

    if (value !== nextValue) {
      this.setState({ locations: nextValue })
    }
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      this.setState({ locations: value })
    }
  }

  normalizeLocation = ({ gmaps, location, label } = {}) => {
    if (!gmaps) {
      return null
    }

    const ret = gmaps.address_components.reduce((ret, item) => {
      item.types.forEach(type => {
        ret[type] = item.long_name
      })

      return ret
    }, location)

    return {
      name: ret.name || label,
      city: ret.locality || ret.political,
      address: [ret.street_number, ret.route].join(' '),
      state: ret.administrative_area_level_1 || ret.political,
      postal: ret.postal_code,
      latitude: ret.lat,
      longitude: ret.lng,
    }
  }

  selectLocation = location => {
    this.setState({ location: this.normalizeLocation(location) })
  }

  onSubmit = args => {
    const { locations, location } = this.state
    const { onChange } = this.props
    const newLocations = [...locations, location]
    this.setState({
      locations: newLocations,
      location: null,
    })
    onChange(newLocations)
    this._geoSelect.clear()
  }

  changeInput = (e, { name, value }) => {
    const { location } = this.state
    this.setState({ location: { ...location, [name]: value } })
  }

  removeLocation = ({ item, index }) => {
    const { locations } = this.state
    locations.splice(index, 1)
    this.setState({ locations: [...locations] })
  }

  render() {
    const { label, required, disabled } = this.props
    const { location, locations } = this.state

    return (
      <React.Fragment>
        <Form.Field>
          <label>{label}</label>
          <Geosuggest
            ref={el => (this._geoSelect = el)}
            onSuggestSelect={this.selectLocation}
            className="Geosuggest__container"
            required={required}
            disabled={disabled}
          />
        </Form.Field>

        {location && (
          <Grid className="LocationsInput__LocationForm">
            <Row>
              <Col xs>
                <Form.Input
                  name="name"
                  label="Location Name"
                  placeholder="Library #1"
                  required
                  onChange={this.changeInput}
                  value={location.name || ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xs>
                <Form.Input
                  name="address"
                  label="Address"
                  placeholder="123 Washington St"
                  required
                  onChange={this.changeInput}
                  value={location.address}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Input
                  name="city"
                  label="City"
                  placeholder="Houston"
                  required
                  onChange={this.changeInput}
                  value={location.city}
                />
              </Col>
              <Col xs>
                <Form.Input
                  name="state"
                  label="State"
                  placeholder="TX"
                  required
                  onChange={this.changeInput}
                  value={location.state}
                />
              </Col>
              <Col xs>
                <Form.Input
                  name="postal"
                  label="Postal Code"
                  placeholder="12345"
                  required
                  onChange={this.changeInput}
                  value={location.postal}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
              <Col xs>
                <Form.Button onClick={this.onSubmit} content="Add Location" />
              </Col>
            </Row>
          </Grid>
        )}

        {!!locations.length && (
          <DataTable
            columns={[
              { title: 'Name', name: 'name' },
              {
                title: 'Location',
                name: 'address',
                renderCell: item =>
                  `${item.address}, ${item.city}, ${item.state} ${item.postal}`,
              },
            ]}
            data={locations}
            renderMenu={row => (
              <Button
                icon="trash"
                onClick={() => {
                  this.removeLocation(row)
                }}
              />
            )}
          />
        )}
      </React.Fragment>
    )
  }
}
