import React from 'react'
import 'url-search-params-polyfill'
import { withUserActions, withUsers } from '../../connectors/users'
import { Route } from 'react-router-dom'

import { Checkbox } from 'semantic-ui-react'

import { Grid, Row, Col } from 'react-flexbox-grid'
import Header from './Header'
import UsersGrid from '../../components/UsersGrid'
import ModalTrigger from '../../components/ModalTrigger'
import UserForm from '../../forms/User'
import Confirmation from '../../components/Confirmation'
import Loading from '../../components/Loading'

@withUserActions
@withUsers
class Users extends React.PureComponent {
  state = {
    deactivated: false,
  }

  componentDidMount() {
    const { fetch } = this.props
    fetch()
  }

  handleToggleChange = (_e, { checked }) => {
    const { fetch } = this.props
    const deactivated = checked
    this.setState({ deactivated })
    fetch(deactivated)
  }

  render() {
    const { loadingUsers, filteredUsers, remove } = this.props
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header
              children={
                <Checkbox
                  toggle
                  label="Show only deactivated Users"
                  onChange={this.handleToggleChange}
                />
              }
            />
          </Col>
        </Row>
        <Route
          path="/app/users"
          render={() => [
            loadingUsers && <Loading key="loadingUsers" height={300} />,
            !loadingUsers && (
              <UsersGrid users={filteredUsers} key="usersGrid" />
            ),
          ]}
        />
        <Route
          path="/app/users/:userId?"
          render={({
            match: { params: { userId }, url },
            location: { search },
            history,
          }) => {
            const query = new URLSearchParams(search)
            return (
              <div>
                <ModalTrigger
                  open={query.get('edit') ? true : false}
                  title="Editing User"
                  form={<UserForm />}
                  onClose={() => {
                    history.push(`/app/users`)
                  }}
                />
                <Confirmation
                  open={query.get('delete') ? true : false}
                  title="Deleting User"
                  createLabel="Delete"
                  onConfirm={() => {
                    history.replace(url)
                    remove(userId)
                  }}
                  onClose={() => {
                    history.replace(url)
                  }}
                  message="Are you sure to delete this User?"
                />
              </div>
            )
          }}
        />
      </Grid>
    )
  }
}

export default Users
