import React from 'react'
import _pick from 'lodash/pick'
import _has from 'lodash/has'
import _get from 'lodash/get'

import { withForm } from '../../connectors/forms'
import {
  WithPartnershipsCreator,
  WithPartnershipsUpdater,
} from '../../connectors/partnerships'

import { Button, Message } from 'semantic-ui-react'
import { LoadingMessage } from '../../components/Loading'
import PartnershipForm from './form'
import moment from 'moment'

@withForm('PartnershipForm')
class AddPartnership extends React.PureComponent {
  static defaultProps = {
    onSubmit: () => Promise.resolve,
    trigger: <Button>Go</Button>,
    closeModal: () => {},
    renderButtons: () => {},
  }

  componentDidMount() {
    this.renderButtons()
  }

  componentDidUpdate(prevProps) {
    if (_get(this.props, 'mutating') !== _get(prevProps, 'mutating')) {
      this.renderButtons()
    }
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      mutating,
      editing,
    } = this.props

    return renderButtons(
      <div>
        <Button negative onClick={closeModal} disabled={mutating}>
          Nevermind
        </Button>
        <Button
          positive
          onClick={submitForm}
          disabled={mutating}
          loading={mutating}
        >
          {editing ? 'Update' : 'Create'}
        </Button>
      </div>
    )
  }

  submit = data => {
    const {
      onSubmit,
      closeModal,
      editing,
      partnership,
      createPartnership,
      updatePartnership,
    } = this.props
    const partnershipId = _get(partnership, 'id')
    const partnershipData = _pick(data, [
      'id',
      'name',
      'store',
      'store_or_pm',
      'position',
      'telephone',
      'email',
      'address',
    ])

    // Make dates ISO
    data.leased_location_period_start = data.leased_location_period_start
      ? moment(data.leased_location_period_start).toISOString()
      : null
    data.leased_location_period_end = data.leased_location_period_end
      ? moment(data.leased_location_period_end).toISOString()
      : null
    data.signed_location_date = data.signed_location_date
      ? moment(data.signed_location_date).toISOString()
      : null
    data.verbal_location_date = data.verbal_location_date
      ? moment(data.verbal_location_date).toISOString()
      : null

    const prom = editing
      ? updatePartnership({ id: partnershipId, partnership: partnershipData })
      : createPartnership({ partnership: partnershipData })

    return prom
      .then(partnership => {
        setImmediate(closeModal)

        // return partnership

        // Return partnership data + location props
        if (!editing)
          data = {
            ...data,
            ...partnership,
          }

        return data
      })
      .then(onSubmit)
  }

  render() {
    const { loading, partnership, editing, mutating, error } = this.props

    if (loading)
      return (
        <LoadingMessage
          title="Partnership is loading"
          text="We are fetching the partnership info."
        />
      )
    if (mutating)
      return (
        <LoadingMessage
          title={`${editing ? 'Updating' : 'Creating'} this partnership`}
        />
      )

    if (error) return <Message error {...error} />

    return (
      <PartnershipForm onSubmit={this.submit} initialValues={partnership} />
    )
  }
}

export default class SwitchFormAction extends React.PureComponent {
  render() {
    const editing =
      _has(this.props, 'partnership') || _has(this.props, 'partnershipId')

    const Form = editing
      ? WithPartnershipsUpdater(AddPartnership)
      : WithPartnershipsCreator(AddPartnership)

    return <Form {...this.props} editing={editing} />
  }
}
