import gql from 'graphql-tag'
import { Route, RouteBase } from '../Query/routes'

export const createRoute = gql`
  mutation createRoute($route: RouteInput!) {
    createRoute(route: $route) { ${Route} }
  }
`

export const editRoute = gql`
  mutation editRoute($routeId: String!, $route: RouteEditInput!) {
    editRoute(id: $routeId, route: $route) {${Route}}
  }
`

export const copyRoute = gql`
  mutation copyRoute($routeId: String!) {
    copyRoute(id: $routeId){
      ${Route}
    }
  }
`

export const deleteRoute = gql`
  mutation deleteRoute($routeId: String!) {
    deleteRoute(id: $routeId){
      ${RouteBase}
    }
  }
`
