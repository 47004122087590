import React from 'react'
import PropTypes from 'prop-types'
import ModalTrigger from '../ModalTrigger'
import { Button, Icon } from 'semantic-ui-react'

import CreateRunForm from '../../forms/CreateRun'

export default class CreateRun extends React.PureComponent {
  static propTypes = {
    market: PropTypes.string,
    route: PropTypes.string,
    driver: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onSchedule: PropTypes.func,
    basic: PropTypes.bool,
  }

  static defaultProps = {
    market: null,
    route: null,
    driver: null,
    disabled: false,
    className: null,
    onSchedule: () => {},
    basic: null,
  }

  render() {
    const { className, disabled, onSchedule, basic, trigger } = this.props
    const { market, route, driver } = this.props

    const initialValues = { market, route, driver }

    const Trigger = trigger
    const DefaultTrigger = (
      <Button color="red" disabled={disabled} className={className}>
        <Icon name="plus" />
        Create Run
      </Button>
    )

    return (
      <ModalTrigger
        title="Create Run"
        trigger={Trigger || DefaultTrigger}
        scrolling
        form={
          <CreateRunForm
            onSchedule={onSchedule}
            initialValues={initialValues}
            basic={basic}
          />
        }
      />
    )
  }
}
