import React from 'react'

import { Link } from 'react-router-dom'

import { Button, Message } from 'semantic-ui-react'
import { LoadingMessage } from '../../../components/Loading'

import _pick from 'lodash/pick'
import _has from 'lodash/has'
import _get from 'lodash/get'

import WithMutator from '../../../connectors/mutator'
import { withForm } from '../../../connectors/forms'
import { withAuth } from '../../../connectors/auth'

import {
  WithMaintenanceRequest,
  withMaintenanceRequestFetchAction,
} from '../../../connectors/maintenanceRequests'

import moment from 'moment'

import MaintenanceRequestForm from './form'

@withAuth
@withMaintenanceRequestFetchAction
@withForm('MaintenanceRequestForm')
class MaintenanceRequest extends React.PureComponent {
  onSubmit = data => {
    const {
      createMaintenanceRequest,
      updateMaintenanceRequest,
      maintenanceRequest,
      editing,
      fetch,
    } = this.props

    const maintenanceRequestData = _pick(data, [
      'binId',
      'userId',
      'date',
      'notes',
      'photos',
      'reasons_front_side',
      'reasons_back_side',
      'reasons_roof',
      'reasons_left_side',
      'reasons_right_side',
      'other',
    ])

    maintenanceRequestData.date = moment(
      maintenanceRequestData.date
    ).toISOString()

    if (editing) {
      maintenanceRequestData.id = _get(maintenanceRequest, 'id')
      return updateMaintenanceRequest({
        maintenanceRequestId: _get(maintenanceRequest, 'id'),
        maintenanceRequest: maintenanceRequestData,
      }).then(_res => {
        fetch()
      })
    } else {
      maintenanceRequestData.status = 'new'
      return createMaintenanceRequest({
        maintenanceRequest: maintenanceRequestData,
      }).then(_res => {
        fetch()
      })
    }
  }

  componentDidUpdate() {
    this.renderButtons()
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      mutating,
      result,
      viewMode,
    } = this.props

    if (result)
      return renderButtons(
        <Button positive onClick={closeModal}>
          Close
        </Button>
      )

    return renderButtons(
      <div>
        <Button negative onClick={closeModal} disabled={mutating}>
          Close
        </Button>
        {!viewMode && (
          <Button positive onClick={submitForm} disabled={mutating}>
            Submit
          </Button>
        )}
      </div>
    )
  }

  render() {
    const {
      error,
      loading,
      mutating,
      result,
      maintenanceRequest,
      binId,
      editing,
      viewMode,
    } = this.props

    if (result)
      return (
        <Message positive>
          Maintenance Request saved. If you want to create Police Report for
          same Bin,
          <Link to={`/app/bin-management/${result.binId}?police-report=true`}>
            click here
          </Link>.
        </Message>
      )
    if (mutating)
      return <LoadingMessage title="Saving Maintenance Request..." />
    if (loading)
      return <LoadingMessage title="Loading Maintenance Request info..." />
    if (error)
      return (
        <Message
          error
          header="Error"
          key="maintenanceRequestError"
          content={error}
        />
      )

    return (
      <MaintenanceRequestForm
        key="MaintenanceRequestForm"
        onSubmit={this.onSubmit}
        initialValues={
          maintenanceRequest && {
            ...maintenanceRequest,
          }
        }
        binId={binId}
        editing={editing}
        viewMode={viewMode}
      />
    )
  }
}

export default class SwitchFormAction extends React.PureComponent {
  render() {
    const editing =
      _has(this.props, 'maintenanceRequest') ||
      _has(this.props, 'maintenanceRequestId')

    const Form = editing
      ? WithMutator('updateMaintenanceRequest')(
          WithMaintenanceRequest(MaintenanceRequest)
        )
      : WithMutator('createMaintenanceRequest')(MaintenanceRequest)

    return <Form {...this.props} editing={editing} />
  }
}
