import FormField from '../../components/FormField'
import { WithGoals } from '../../connectors/goals'
import moment from 'moment'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, getFormValues, reduxForm } from 'redux-form'
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Grid,
  Input,
  Label,
  Loader,
} from 'semantic-ui-react'
import 'url-search-params-polyfill'

import { get, has } from 'lodash'
import AllMarketsGoalsTable from './AllMarketsGoalsTable'
import './style.scss'

const columnKeys = [
  'jan',
  'feb',
  'mar',
  'q1',
  'apr',
  'may',
  'jun',
  'q2',
  'jul',
  'aug',
  'sep',
  'q3',
  'oct',
  'nov',
  'dec',
  'q4',
]

const quarterKeys = ['q1', 'q2', 'q3', 'q4']

const validates = {
  numeric(val) {
    return /^[0-9]*,?[0-9]*\.?[0-9]*$/.test(val) || !val
      ? undefined
      : 'invalid/format'
  },
  required(val) {
    return val !== null && val !== undefined ? undefined : 'invalid/required'
  },
}

function formatAsDecimal(num) {
  return Number(num || '').toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })
}

function allowNumberOnly(event) {
  const nativeEvent = event.nativeEvent

  if (isNaN(Number(nativeEvent.data)) && nativeEvent.data !== '.')
    return event.preventDefault()
}

function removeFirstZeroChar(event) {
  const nativeEvent = event.nativeEvent
  const value = nativeEvent.target.value

  if (value.startsWith('0') && value[1] !== '.') {
    const newValue = Number(value.slice(1, value.length))
    nativeEvent.target.value = newValue === '' ? 0 : newValue
  }
}

@WithGoals
export default class MonthlyGoalForm extends React.PureComponent {
  render() {
    const {
      data,
      onSubmit,
      marketId,
      loading,
      savingGoals,
      savedSuccessfully,
      year,
    } = this.props
    let marketGoals = {}
    let routes = []

    if (has(data, 'goals')) {
      if (marketId === 'multiple-markets') {
        marketGoals = data.goals
      } else {
        marketGoals = data.goals[0].market_goals
        routes = data.goals[0].routes

        Object.keys(marketGoals).forEach(key => {
          if (marketGoals[key] === 0) marketGoals[key] = ''
        })
      }
    }

    const initialValues = {
      marketGoals,
    }

    return (
      <Fragment>
        {marketId !== 'multiple-markets' ? (
          <MonthlyGoalEntryTableContainer
            loading={loading || savingGoals}
            savedSuccessfully={savedSuccessfully}
            form={`MonthlyGoalEntryTable`}
            marketGoals={marketGoals}
            routes={routes}
            marketId={marketId}
            onSubmit={onSubmit}
            initialValues={initialValues}
            year={Number(year)}
          />
        ) : (
          <AllMarketsGoalsTable
            loading={loading || savingGoals}
            marketGoals={marketGoals}
          />
        )}
      </Fragment>
    )
  }
}

@reduxForm({
  enableReinitialize: true,
})
export class MonthlyGoalEntryTable extends React.PureComponent {
  state = {
    isLock: true,
  }

  componentDidUpdate() {
    const { savedSuccessfully } = this.props

    if (savedSuccessfully) {
      this.reInitialForm()
      this.lockForm()
    }
  }

  lockForm = () => {
    this.setState({
      isLock: true,
    })
  }

  unlockForm = e => {
    e.preventDefault()

    this.setState({
      isLock: false,
    })
  }

  resetForm = e => {
    e.preventDefault()
    this.props.reset()
    this.lockForm()
  }

  reInitialForm = () => {
    const { formValues, initialize } = this.props
    initialize(formValues)
  }

  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th />
          {columnKeys.map((month, index) => {
            const quarterNumber = Math.floor(index / 4) + 1

            return (
              <th class={`quarter_${quarterNumber}`}>
                <span>{month.toUpperCase()}</span>
              </th>
            )
          })}
          <th class={`annual_goal`}>
            <span>Total</span>
          </th>
        </tr>
      </thead>
    )
  }

  colsToCalculateAndShowValue = () => {
    const { year } = this.props
    const currentYear = moment().year()
    const currentMonth = moment().month()
    const allMonths = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ]

    if (year !== currentYear) {
      return allMonths
    } else {
      const quarterNumber = Math.ceil((currentMonth + 1) / 3)

      return [
        ...allMonths.filter((month, index) => {
          if (Math.ceil((index + 1) / 3) <= quarterNumber) return true
          else return false
        }),
        ...quarterKeys.filter((quarter, index) => {
          if (index < quarterNumber) return true
          else return false
        }),
      ]
    }
  }

  renderTableRow(route) {
    const { formValues, year } = this.props
    const reports = {
      jan: 0,
      feb: 0,
      mar: 0,
      q1: 0,
      apr: 0,
      may: 0,
      jun: 0,
      q2: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      q3: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      q4: 0,
    }
    const colsToCalculateAndShowValue = this.colsToCalculateAndShowValue()

    Object.keys(reports).forEach(reportKey => {
      if (['jan', 'feb', 'mar'].includes(reportKey)) {
        if (colsToCalculateAndShowValue.includes(reportKey)) {
          reports[reportKey] =
            Number(formValues.marketGoals[reportKey]) *
            Number(route.actuals.quarter_4_prev_year.percentage)
        }
        reports.q1 =
          Math.ceil(Number(reports.jan)) +
          Math.ceil(Number(reports.feb)) +
          Math.ceil(Number(reports.mar))
      } else if (['apr', 'may', 'jun'].includes(reportKey)) {
        if (colsToCalculateAndShowValue.includes(reportKey)) {
          reports[reportKey] =
            Number(formValues.marketGoals[reportKey]) *
            Number(route.actuals.quarter_1.percentage)
        }
        reports.q2 =
          Math.ceil(Number(reports.apr)) +
          Math.ceil(Number(reports.may)) +
          Math.ceil(Number(reports.jun))
      } else if (['jul', 'aug', 'sep'].includes(reportKey)) {
        if (colsToCalculateAndShowValue.includes(reportKey)) {
          reports[reportKey] =
            Number(formValues.marketGoals[reportKey]) *
            Number(route.actuals.quarter_2.percentage)
        }
        reports.q3 =
          Math.ceil(Number(reports.jul)) +
          Math.ceil(Number(reports.aug)) +
          Math.ceil(Number(reports.sep))
      } else if (['oct', 'nov', 'dec'].includes(reportKey)) {
        if (colsToCalculateAndShowValue.includes(reportKey)) {
          reports[reportKey] =
            Number(formValues.marketGoals[reportKey]) *
            Number(route.actuals.quarter_3.percentage)
        }
        reports.q4 =
          Math.ceil(Number(reports.oct)) +
          Math.ceil(Number(reports.nov)) +
          Math.ceil(Number(reports.dec))
      }
    })

    const total = reports.q1 + reports.q2 + reports.q3 + reports.q4

    const row = (
      <tr key={`${route.id}-${year}`}>
        <td>
          <span>{route.name}</span>
        </td>
        {columnKeys.map((columnKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label>
                {colsToCalculateAndShowValue.includes(columnKey) &&
                  formValues.marketGoals[columnKey] !== '' &&
                  // reports[`q${quarterNumber}`] !== 0 &&
                  formatAsDecimal(Math.ceil(reports[columnKey]))}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label>{formatAsDecimal(Math.ceil(total))}</Label>
        </td>
      </tr>
    )

    return row
  }

  hasError() {
    const { formValues: { marketGoals } } = this.props
    return Object.values(marketGoals).some(
      value => !/^[0-9]*,?[0-9]*\.?[0-9]*$/.test(value) && value !== ''
    )
  }

  renderMarketGoalsRow() {
    const { formValues } = this.props

    const quarters = {
      q1:
        Number(get(formValues.marketGoals, 'jan', 0)) +
        Number(get(formValues.marketGoals, 'feb', 0)) +
        Number(get(formValues.marketGoals, 'mar', 0)),
      q2:
        Number(get(formValues.marketGoals, 'apr', 0)) +
        Number(get(formValues.marketGoals, 'may', 0)) +
        Number(get(formValues.marketGoals, 'jun', 0)),
      q3:
        Number(get(formValues.marketGoals, 'jul', 0)) +
        Number(get(formValues.marketGoals, 'aug', 0)) +
        Number(get(formValues.marketGoals, 'sep', 0)),
      q4:
        Number(get(formValues.marketGoals, 'oct', 0)) +
        Number(get(formValues.marketGoals, 'nov', 0)) +
        Number(get(formValues.marketGoals, 'dec', 0)),
    }
    const total = Object.values(quarters).reduce((total, curr) => total + curr)

    const rows = [
      <tr key="market-goals-lbs">
        <td>lbs</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td
              class={`route quarter_${quarterNumber}`}
              key={`market_goals.${colKey}`}
            >
              {!quarterKeys.includes(colKey) && (
                <Input disabled={this.state.isLock}>
                  <Field
                    name={`marketGoals.${colKey}`}
                    placeholder=""
                    type="text"
                    component={FormField}
                    formComponent={Form.Input}
                    validate={[validates.numeric, validates.required]}
                    onBeforeInput={e => {
                      allowNumberOnly(e)
                      removeFirstZeroChar(e)
                    }}
                  />
                </Input>
              )}

              {quarterKeys.includes(colKey) && (
                <Label>{formatAsDecimal(quarters[`q${quarterNumber}`])}</Label>
              )}
            </td>
          )
        })}
        <td class="row-total-column">
          <Label>{formatAsDecimal(total)}</Label>
        </td>
      </tr>,
      <tr key="market-goals-percentages">
        <td>%</td>

        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1
          const monthGoal = get(formValues.marketGoals, colKey, 0)
          const quarterTotal = quarters[`q${quarterNumber}`]
          const monthPercentage =
            monthGoal === 0 ? 0 : monthGoal / quarterTotal * 100

          return (
            <td
              class={`route quarter_${quarterNumber}`}
              key={`market_goals.${colKey}`}
            >
              {!quarterKeys.includes(colKey) && (
                <Label>{formatAsDecimal(monthPercentage)}</Label>
              )}

              {quarterKeys.includes(colKey) && ''}
            </td>
          )
        })}
      </tr>,
    ]

    return rows
  }

  renderButtons() {
    const { submitForm, loading } = this.props

    if (this.state.isLock)
      return (
        <React.Fragment>
          <br />
          <Button color="blue" onClick={this.unlockForm} disabled={loading}>
            Edit
          </Button>
        </React.Fragment>
      )
    else
      return (
        <React.Fragment>
          <br />
          <Button color="blue" onClick={this.resetForm} disabled={loading}>
            Discard Changes
          </Button>
          <Button
            color="green"
            onClick={submitForm}
            disabled={this.hasError() || loading}
          >
            Save Changes
          </Button>
        </React.Fragment>
      )
  }

  submitGolasForm() {
    const { formValues, onSubmit } = this.props
    onSubmit(formValues)
  }

  render() {
    const { formValues, loading, routes } = this.props

    return (
      <div>
        <Form onSubmit={() => this.submitGolasForm()}>
          <Grid>
            <Grid.Column textAlign="right" mobile={16} computer={16}>
              {this.renderButtons()}
              <Divider />
            </Grid.Column>
          </Grid>

          <div
            style={{
              overflowX: 'auto',
              maxHeight: '500px',
            }}
          >
            <table className="ui sortable table monthly-data-entry-form">
              {this.renderTableHeader()}
              <tbody>
                {loading ? (
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                ) : routes.length && formValues.marketGoals ? (
                  [
                    this.renderMarketGoalsRow(),
                    <React.Fragment>
                      {routes.map(route => (
                        <React.Fragment>
                          {this.renderTableRow(route)}
                        </React.Fragment>
                      ))}
                    </React.Fragment>,
                  ]
                ) : (
                  <tr>
                    <td />
                    <td colSpan={17}>No data!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const formValues = getFormValues('MonthlyGoalEntryTable')(state) || {}

  return {
    formValues,
  }
}

const MonthlyGoalEntryTableContainer = connect(mapStateToProps, null)(
  MonthlyGoalEntryTable
)
