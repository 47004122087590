import { withForm } from '../../connectors/forms'
import { withUserActions, withUsers } from '../../connectors/users'
import React from 'react'

import { first } from 'lodash'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import { Redirect, withRouter } from 'react-router-dom'
import { Button, Confirm, Container, Message } from 'semantic-ui-react'
import Form from './form'

@withRouter
@withUserActions
@withUsers
@withForm('UserForm')
export default class UserForm extends React.PureComponent {
  state = {
    redirect: false,
    error: null,
    saved: false,
    user: null,
    loading: false,
    deactivatedConfirmation: false,
  }

  componentWillReceiveProps(nextProps) {
    const { closeModal, renderButtons, selector: oldSelector } = this.props
    const { selector } = nextProps

    // Undefined check serves to stop confirm deactivation dialog from
    // showing up on form load when user is already deactivated
    if (
      oldSelector('deactivated') !== undefined &&
      !oldSelector('deactivated') &&
      selector('deactivated')
    ) {
      setImmediate(p => this.setState({ deactivatedConfirmation: true }))
      return
    }

    if (_isEqual(this.props, nextProps)) {
      return
    }
    if (this.state.error || !this.state.saved) {
      return
    }

    const buttons = (
      <Container>
        <Button positive onClick={closeModal}>
          Done
        </Button>
      </Container>
    )
    renderButtons(buttons)
  }

  componentDidMount() {
    const { renderButtons, closeModal, submitForm, clearError } = this.props
    // Clear any errors when the form loads
    clearError()

    const buttons = (
      <Container>
        <Button negative onClick={closeModal}>
          Nevermind
        </Button>
        <Button positive onClick={submitForm}>
          Submit
        </Button>
      </Container>
    )

    renderButtons(buttons)

    // Resolve editing a user
    const { getUser, match: { params: { userId } = {} } = {} } = this.props

    if (userId) {
      this.setState({ loading: true })
      getUser(userId)
        .then(user => user.payload)
        .then(user => {
          this.setState({ user })
          this.setState({ loading: false })
        })
    }
  }

  onSubmit = args => {
    this.setState({ saved: null, error: null })
    const { updateUser } = this.props
    const markets = args.markets.map(market => ({ id: market }))
    const firstSelectedMarket = first(markets)

    const user = {
      id: args.id,
      first_name: args.first_name,
      last_name: args.last_name,
      contact_type: args.contact_type,
      password_hash: args.password_hash,
      primary_email: args.primary_email,
      user_name: args.user_name,
      deactivated: args.deactivated || false,
      phones: [{ is_primary: true, type: 'business', value: args.phone }],
      primary_market: args.primary_market
        ? args.primary_market
        : firstSelectedMarket.id,
    }

    return updateUser(user, markets)
      .then(data => {
        if (data.type === 'USERS_ERROR') {
          return {
            error: _get(
              data,
              'payload.graphQLErrors[0].message',
              'There was an error saving the user.'
            ),
          }
        }
        return { saved: true }
      })
      .then(state => this.setState(state))
  }

  handleConfirm = p => {
    this.setState({ deactivatedConfirmation: false })
  }
  handleCancel = p => {
    const { changeFieldValue } = this.props
    this.setState({ deactivatedConfirmation: false })
    setImmediate(p => changeFieldValue('deactivated', false))
  }

  render() {
    const {
      redirect,
      error,
      saved,
      user,
      loading,
      deactivatedConfirmation,
    } = this.state

    if (redirect) {
      return <Redirect to={redirect} />
    }

    if (saved) {
      return <Message positive>User Saved</Message>
    }

    return [
      !loading && (
        <Form
          onSubmit={this.onSubmit}
          key="userForm"
          initialValues={
            user && {
              ...user,
              phone: user.phones && user.phones.length && user.phones[0].value,
              markets: user.markets && user.markets.map(m => m.id),
            }
          }
        />
      ),
      loading && <h2 key="userLoading">Loading User ...</h2>,
      error && (
        <Message error header="Error" key="userError">
          {error}
        </Message>
      ),
      <Confirm
        open={deactivatedConfirmation}
        header="Deactivating user"
        content="Are you sure you want to deactivate this user?"
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
      />,
    ]
  }
}
