import React from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from 'semantic-ui-react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import './styles.scss'
import Logo from '../../components/Logo'
import Logout from '../../components/Logout'

export const UserInfo = ({ name, role, renderMenu, onChange }) => {
  const label = (
    <div>
      <h3>{name}</h3>
      {role && <span>{role}</span>}
      <Logout>
        <a href="#logout">Logout</a>
      </Logout>
    </div>
  )

  return (
    <div className="MainHeader__name">
      {renderMenu ? (
        <Dropdown trigger={label} options={renderMenu()} onChange={onChange} />
      ) : (
        label
      )}
    </div>
  )
}

const MainHeader = ({ user, renderMenu, onChange }) => (
  <Grid fluid className="MainHeader__Grid">
    <Row middle="xs">
      <Col className="MainHeader__Logo">
        <Logo />
      </Col>
      <Col xs />
      <Col xs={4}>
        <UserInfo
          name={user.name}
          role={user.role}
          renderMenu={renderMenu}
          onChange={onChange}
        />
      </Col>
    </Row>
  </Grid>
)

MainHeader.propTypes = {
  user: PropTypes.any.isRequired,
  renderMenu: PropTypes.func,
  onChange: PropTypes.func,
}

export default MainHeader
