import React from 'react'
import { Query } from 'react-apollo'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/policeReports'
import {
  searchPoliceReports,
  PoliceReport,
  filterByMarkets,
} from '../selectors/policeReports'

import * as QL from '../api/Query/policeReports'

export const withPoliceReportActions = WrappedComponent =>
  connect(null, dispatch => bindActionCreators(actions, dispatch))(
    WrappedComponent
  )

export const withPoliceReportFetchAction = WrappedComponent =>
  connect(null, dispatch =>
    bindActionCreators({ fetch: actions.fetch }, dispatch)
  )(WrappedComponent)

export const withPoliceReports = WrappedComponent =>
  connect(state => ({
    searchedPoliceReports: searchPoliceReports(state),
    policeReports: state.policeReports.policeReports
      ? state.policeReports.policeReports.map(PoliceReport)
      : [],
    loadingPoliceReports: state.policeReports.loading,
    filteredPoliceReports: filterByMarkets(state),
  }))(WrappedComponent)

export const WithPoliceReport = Component => {
  class WrappedComponent extends React.Component {
    render() {
      const { policeReportId, ...rest } = this.props

      return (
        <Query query={QL.policeReport} variables={{ id: policeReportId }}>
          {({ loading, error, data }) => (
            <Component
              error={error}
              loading={loading}
              policeReport={data.policeReportData}
              {...rest}
            />
          )}
        </Query>
      )
    }
  }

  return WrappedComponent
}
