import _get from 'lodash/get'
import React from 'react'
import { Form, Radio } from 'semantic-ui-react'

import LocationInput from '../../components/LocationInput'
import WithQuerySearch from '../../connectors/querySearch'
import locationsFilterOptions from './locationsFilterOptions'

@WithQuerySearch
export class BinsSearch extends React.Component {
  updateSearch = location => {
    const { search, updateSearch } = this.props
    updateSearch({
      ...search,
      bin_search: `${_get(location, 'latitude')},${_get(
        location,
        'longitude'
      )}`,
    })
  }

  render() {
    const { search, setLocationsFilter, locationsFilter } = this.props
    const value = _get(search, 'bin_search')

    return (
      <div className="ui form SearchLocationPanel">
        <LocationInput
          label="Address / Name"
          value={value}
          onChange={this.updateSearch}
          style={{ height: '52px' }}
        />
        <Form.Group width="16">
          <Form.Field width="5">
            <Radio
              checked={
                locationsFilter === locationsFilterOptions.All ? true : false
              }
              label="All locations"
              onChange={() => setLocationsFilter(locationsFilterOptions.All)}
            />
          </Form.Field>
          <Form.Field width="5">
            <Radio
              checked={
                locationsFilter === locationsFilterOptions.Active ? true : false
              }
              label="Active locations"
              onChange={() => setLocationsFilter(locationsFilterOptions.Active)}
            />
          </Form.Field>
          <Form.Field width="6">
            <Radio
              checked={
                locationsFilter === locationsFilterOptions.inActive
                  ? true
                  : false
              }
              label="Inactive locations"
              onChange={() =>
                setLocationsFilter(locationsFilterOptions.inActive)
              }
            />
          </Form.Field>
        </Form.Group>
      </div>
    )
  }
}

export default BinsSearch
