import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'semantic-ui-react'
import logo from '../../assets/logo-color-102x102.png'
import './styles.scss'

const Logo = ({ size }) => (
  <Image src={logo} size={size} circular />
)

Logo.propTypes = {
  size: PropTypes.oneOf([
    'mini',
    'tiny',
    'small',
    'large',
    'huge',
  ]),
}

Logo.defaultProps = {
  size: 'mini',
}

export default Logo
