import './styles.scss'

import React from 'react'

import { Pagination } from 'semantic-ui-react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import { withAuth } from '../../../connectors/auth'

import MaintenanceRequestCard from '../MaintenanceRequestCard'

@withAuth
class MaintenanceRequestGrid extends React.PureComponent {
  state = {
    activePage: 0,
    size: 20,
  }

  onPageChange(_event, data) {
    this.setState({ activePage: data.activePage })
  }

  render() {
    const { maintenanceRequests, auth } = this.props
    const { activePage, size } = this.state

    const userId = auth.user.id

    const maintenanceRequestsToPaginate = []

    maintenanceRequests.forEach(maintenanceRequest => {
      const record = maintenanceRequest

      if (record.userId === userId) {
        record.isAssignedToMe = true
      }

      maintenanceRequestsToPaginate.push(record)
    })

    maintenanceRequestsToPaginate.sort((a, b) => {
      return b.isAssignedToMe ? 1 : -1
    })

    let paginated = []

    if (maintenanceRequestsToPaginate) {
      let pageNumber = activePage
      if (activePage > 0) {
        pageNumber = pageNumber - 1
      }

      paginated = maintenanceRequestsToPaginate.slice(
        pageNumber * size,
        (pageNumber + 1) * size
      )
    }

    console.log('MRs to render: ', { paginated })

    return (
      <Grid className="MaintenanceRequestGrid">
        {paginated.length < 1 && (
          <div style={{ textAlign: 'center' }}>
            <h2>There are no any Maintenance Requests yet.</h2>
          </div>
        )}

        <Row start="xs" className="MaintenanceRequestGrid_row">
          {paginated.length > 0 &&
            paginated.map(maintenanceRequest => (
              <Col xs={6} sm={4} md={3} key={maintenanceRequest.id}>
                <MaintenanceRequestCard data={maintenanceRequest} />
              </Col>
            ))}
        </Row>

        <Row>
          {paginated.length > 0 && (
            <Pagination
              className="MaintenanceRequestGrid_pagination"
              onPageChange={this.onPageChange.bind(this)}
              defaultActivePage={1}
              totalPages={Math.ceil(
                maintenanceRequestsToPaginate.length / size
              )}
            />
          )}
        </Row>
      </Grid>
    )
  }
}

export default MaintenanceRequestGrid
