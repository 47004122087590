import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

export class CalculateRouteLengthModalError extends React.Component {
  state = {
    open: true,
  }

  setOpen(open) {
    this.setState({
      open,
    })
  }

  onClose() {
    const { onCalculateRouteLengthModalClose } = this.props

    onCalculateRouteLengthModalClose()
    this.setOpen(false)
  }

  render() {
    const { message } = this.props
    const { open } = this.state

    return (
      <Modal
        onClose={() => this.onClose()}
        onOpen={() => this.setOpen(true)}
        open={open}
        // dimmer={'blurring'}
        size={'tiny'}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Route Length Calculation Error</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{message}</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => this.onClose()}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
