import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

import { Button, Message } from 'semantic-ui-react'
import { LoadingMessage } from '../../../components/Loading'

import _pick from 'lodash/pick'
import _has from 'lodash/has'
import _get from 'lodash/get'

import WithMutator from '../../../connectors/mutator'
import { withForm } from '../../../connectors/forms'
import { withAuth } from '../../../connectors/auth'
import { WithBin, withBins, withBinFetchAction } from '../../../connectors/bins'

import BinForm from './form'

@withRouter
@withAuth
@withBins
@withBinFetchAction
@withForm('BinForm')
class Bin extends React.PureComponent {
  state = {
    submittedBin: null,
  }

  fetchBins() {
    const { fetch, binsFilters, auth: { user: { markets } } } = this.props
    const deactivated = binsFilters.status === 'deactivated' ? true : false
    const marketIds = markets.map(market => market.id)

    if (binsFilters.status === 'unassigned')
      fetch(deactivated, binsFilters.status, [])
    else fetch(deactivated, binsFilters.status || 'all', marketIds)
  }

  onSubmit = data => {
    const { createBin, updateBin, editing } = this.props

	console.log({ onSubmit: data })

    const binData = _pick(data, [
      'deactivated',
      'serial_number',
      'material',
      'size',
      'chute',
      'manufacture',
      'date_in_service',
      'photos',
      'market',
	  'moleiot_mac',
    ])

    if (!binData.photos) {
      binData.photos = []
    }

    binData.box_code = binData.serial_number
    binData.unique_name = binData.serial_number.toLowerCase()
    binData.date_in_service = moment(binData.date_in_service).toISOString()
    binData.moleiot_mac = binData.moleiot_mac?.toUpperCase()

    if (editing) {
      updateBin({ binId: _get(data, 'id'), bin: binData }).then(res => {
        this.fetchBins()
      })
    } else {
      binData.deactivated = false
      binData.status = 'new'

      this.setState({
        submittedBin: binData,
      })

      createBin({ bin: binData }).then(res => {
        this.fetchBins()
      })
    }
  }

  componentDidUpdate() {
    this.renderButtons()
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      mutating,
      result,
      viewMode,
      history,
      bin,
      editButton,
    } = this.props

    if (result)
      return renderButtons(
        <Button
          positive
          onClick={() => {
            closeModal()
          }}
        >
          Close
        </Button>
      )

    return renderButtons(
      <div>
        <Button negative onClick={closeModal} disabled={mutating}>
          Close
        </Button>
        {viewMode &&
          (editButton || (
            <Button
              color="orange"
              onClick={() =>
                history.push(`/app/bin-management/${bin.id}?edit=true`)
              }
              disabled={mutating}
            >
              Edit
            </Button>
          ))}
        {!viewMode && (
          <Button positive onClick={submitForm} disabled={mutating}>
            Submit
          </Button>
        )}
      </div>
    )
  }

  componentDidMount() {
    this.prepairMarketsOptions()
  }

  prepairMarketsOptions() {
    const { auth: { user: { markets } } } = this.props

    this.setState({
      markets: markets.map(market => ({
        key: market.id,
        text: market.name,
        value: market.id,
      })),
    })
  }

  render() {
    const { error, loading, mutating, result, bin, viewMode } = this.props
    const { submittedBin, markets } = this.state
    let initialValues = null

    if (result) return <Message positive>Bin saved.</Message>
    if (mutating) return <LoadingMessage title="Saving Bin..." />
    if (loading) return <LoadingMessage title="Loading Bin info..." />

    if (submittedBin) {
      initialValues = submittedBin
    } else if (bin) {
      initialValues = {
        ...bin,
        deactivated: bin.deactivated === null ? false : bin.deactivated,
      }
    }

    return [
      <BinForm
        key="BinForm"
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        viewMode={viewMode}
        markets={markets}
      />,
      error && (
        <Message
          error
          header="Error"
          key="binError"
          content={error.description}
        />
      ),
    ]
  }
}

export default class SwitchFormAction extends React.PureComponent {
  render() {
    const editing = _has(this.props, 'bin') || _has(this.props, 'binId')

    const Form = editing
      ? WithMutator('updateBin')(WithBin(Bin))
      : WithMutator('createBin')(Bin)

    return <Form {...this.props} editing={editing} />
  }
}
