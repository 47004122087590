import React from 'react'
import { withAuth } from '../../connectors/auth'

import LoginForm from './form'

@withAuth
export default class Login extends React.PureComponent {
  componentDidMount() {
    const { clearError } = this.props
    clearError()
  }

  render() {
    console.log(process.env);
    const { auth, signin } = this.props

    return <LoginForm onSubmit={signin} formError={auth.error} />
  }
}
