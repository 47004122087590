import gql from 'graphql-tag'
import { maintenanceRequestModel } from '../Query/maintenanceRequests'

export const createMaintenanceRequest = gql`
  mutation createMaintenanceRequest(
    $maintenanceRequest: MaintenanceRequestInput!
  ) {
    createMaintenanceRequest(
      maintenanceRequest: $maintenanceRequest
    ) {
      ${maintenanceRequestModel}
    }
  }
`

export const updateMaintenanceRequest = gql`
  mutation updateMaintenanceRequest(
    $maintenanceRequestId: String!
    $maintenanceRequest: MaintenanceRequestInput!
  ) {
    updateMaintenanceRequest(
      maintenanceRequestId: $maintenanceRequestId
      maintenanceRequest: $maintenanceRequest
    ) {
      ${maintenanceRequestModel}
    }
  }
`
