import { createSelector } from 'reselect'
import _map from 'lodash/map'
import _first from 'lodash/first'
import _get from 'lodash/get'
import _compose from 'lodash/fp/compose'

const buildFullName = partnership => ({
  ...partnership,
  fullName: `${_get(partnership, 'store')} - ${_get(partnership, 'name')}`,
})

const Partnership = _compose(buildFullName)

export const Partnerships = createSelector(
  data => _get(data, 'partnerships'),
  rawPartnerships => {
    const partnerships = _map(rawPartnerships, Partnership)
    const partnership = _first(partnerships)

    return {
      partnerships,
      partnership,
    }
  }
)
