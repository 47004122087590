import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withAuth } from '../connectors/auth'

const PrivateRoute = ({ component: Component, auth = false, ...rest }) => {
  if (auth === false || !auth.loaded) {
    return null
  }

  const user = auth.user

  function renderRoute(props) {
    return user ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: props.location },
        }}
      />
    )
  }

  return <Route {...rest} render={renderRoute} />
}

export default withAuth(PrivateRoute)
