import React from 'react'
import { IconContext } from 'react-icons'
import { BsFillCCircleFill } from 'react-icons/bs'
import { Popup } from 'semantic-ui-react'
import { CurrentCompetitors } from '../LocationCompetitors/currentCompetitors'

export default class MarkedCompetiorsAsRemovedPopup extends React.Component {
  render() {
    const { competitors } = this.props

    return (
      <Popup
        trigger={
          <div>
            <CompetitorsPopupIcon />
          </div>
        }
      >
        <Popup.Header>Marked Competitors</Popup.Header>
        <Popup.Content>
          <p>{`${competitors.length} competitor${
            competitors.length > 1 ? 's have' : ' has'
          } been marked as removed`}</p>
          <CurrentCompetitors currentCompetitors={competitors} />
        </Popup.Content>
      </Popup>
    )
  }
}

class CompetitorsPopupIcon extends React.Component {
  render() {
    return (
      <IconContext.Provider value={{ color: '#db2828', size: 22 }}>
        <BsFillCCircleFill />
      </IconContext.Provider>
    )
  }
}
