import DataTable from '../DataTable'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Label, Popup } from 'semantic-ui-react'
import { contractSelectionLabel } from '../../utils/competitors'
import EditedStopPopup from './EditedStopPopup'
import MarkedCompetiorsAsRemovedPopup from './MarkedCompetiorsAsRemovedPopup'

const hasSkipped = bins => {
  if (!bins) return false

  return bins.find(
    b =>
      b.report &&
      b.report.tagInfo &&
      b.report.tagInfo.find(info =>
        ['skipped bin', 'truck-broke-down'].includes(info.title.toLowerCase())
      )
  )
}

const getTitle = title => {
  // if (title === 'truck-broke-down') {
  //   return 'Skipped bin'
  // } else
  if (title === 'Fix') {
    return 'Bin Maintenance required'
  } else {
    return title
  }
}

const getColor = label => {
  const codes = {
    'skipped bin': 'grey',
    vandalism: 'red',
    trash: 'purple',
    'missing bin': 'red',
    'bin maintenance required': 'orange',
    competitor: 'yellow',
  }

  if (codes[label.toLowerCase()]) {
    return codes[label.toLowerCase()]
  }

  return 'grey'
}

const findTimestamp = bins =>
  _isArray(bins) &&
  _get(bins.find(bin => _pick(bin, 'report.created_at')), 'report.created_at')

const columns = [
  { title: 'Stop', name: 'stop' },
  {
    title: 'Name',
    name: 'name',
    renderCell: ({ name, bins }) => (
      <div>
        <span style={{ display: 'block' }}>{name}</span>
        {bins &&
          bins
            .filter(bin => bin.report && bin.report.tagInfo)
            .map(bin => bin.report.tagInfo)
            .map(tagInfo =>
              tagInfo.map(info => ({
                ...info,
                title: getTitle(info.title),
              }))
            )
            .map(tagInfo =>
              tagInfo.map(info => (
                <Popup
                  key={info.title}
                  trigger={
                    <Label color={getColor(info.title)} size="small">
                      {info.title}
                    </Label>
                  }
                  content={[
                    info.children &&
                      info.children.map(c => {
                        let title = c.title

                        if (c.selections) {
                          title = `${title} (${c.selections
                            .map(selection =>
                              contractSelectionLabel(selection.label)
                            )
                            .join(', ')})`
                        }

                        return (
                          <span key={title} style={{ display: 'block' }}>
                            {title}
                          </span>
                        )
                      }),
                    !info.children &&
                      info.title === 'truck-broke-down' && (
                        <span
                          style={{ display: 'block' }}
                          key="truck-broke-down"
                        >
                          Truck Broke Down
                        </span>
                      ),
                  ]}
                />
              ))
            )}
      </div>
    ),
  },
  {
    title: 'Location',
    name: 'location',
    selectable: true,
    renderCell: ({ location, locationId }) => (
      <Link to={`/app/locations/${locationId}`}>{location}</Link>
    ),
  },
  {
    title: 'Weight Collected',
    name: 'weight',
    renderCell: ({ weight, comment, complete, bins, edited }) => (
      <div>
        {complete && !hasSkipped(bins) ? (
          <span
            className={edited ? 'weight-edited' : ''}
            title={
              edited &&
              `Manually entered: ${moment(edited.edited).format('llll')}`
            }
          >
            {weight}
          </span>
        ) : (
          '-'
        )}
        {comment && (
          <Popup
            trigger={<Icon name="comment" style={{ marginLeft: '0.5rem' }} />}
            content={comment}
            position="top right"
          />
        )}
      </div>
    ),
  },
  {
    title: 'Timestamp',
    name: 'timestamp',
    renderCell: ({ bins }) => {
      const timestamp = findTimestamp(bins)
      return timestamp ? moment(timestamp).format('llll') : '-'
    },
  },
  {
    title: 'Info',
    name: 'info',
    renderCell: ({ info }) => (
      <Fragment>
        {info.markedCompetitorsAsRemove.length > 0 && (
          <MarkedCompetiorsAsRemovedPopup
            competitors={info.markedCompetitorsAsRemove}
          />
        )}
        {info.editedStop && <EditedStopPopup />}
      </Fragment>
    ),
  },
]

const address = ({ address, city, state, postal }) =>
  `${address}, ${city}, ${state} ${postal}`

const RunStopsTable = ({ stops }) => {
  return (
    <DataTable
      columns={columns}
      data={_map(_sortBy(stops, 'location.stop_sequence'), (stop, index) => ({
        locationId: stop.location.id,
        stop: stop.location.stop_sequence,
        name: stop.location.name,
        location: address(stop.location),
        comment: stop.comment,
        weight: stop.weight,
        complete: stop.complete,
        bins: stop.bins,
        edited: stop.bins && stop.bins.find(b => b.edited),
        info: {
          markedCompetitorsAsRemove:
            stop.location.markedCompetitorsAsRemove || [],
          editedStop: stop.bins.some(bin => {
            if (bin.report) {
              if (bin.report.edited) return true
            }

            return false
          }),
        },
      }))}
      initialSort="stop"
    />
  )
}

export default RunStopsTable
