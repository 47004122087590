import {
  _POLICE_REPORTS_FETCH,
  _POLICE_REPORTS_ERROR,
  _POLICE_REPORTS_SEARCH,
  _POLICE_REPORTS_LOADING,
  _POLICE_REPORT_ADDED,
  _POLICE_REPORTS_REMOVE,
} from '../actions/policeReports'

const initialState = {
  policeReports: [],
  policeReport: {},
  search: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _POLICE_REPORTS_SEARCH:
      return {
        ...state,
        search: action.payload,
      }

    case _POLICE_REPORT_ADDED:
      const found = state.policeReports.findIndex(
        policeReport => policeReport.id === action.payload.id
      )
      return {
        ...state,
        loading: false,
        policeReport: action.payload,
        error: null,
        policeReports:
          found >= 0
            ? Object.assign([], state.policeReports, {
                [found]: action.payload,
              })
            : [...state.policeReports, action.payload],
      }

    case _POLICE_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
      }

    case _POLICE_REPORTS_FETCH:
      return {
        ...state,
        policeReports: action.payload,
        loading: false,
      }
    case _POLICE_REPORTS_REMOVE:
      return {
        ...state,
        policeReports: state.users.filter(
          policeReport => policeReport.id !== action.payload
        ),
        loading: false,
      }

    case _POLICE_REPORTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default reducer
