import gql from 'graphql-tag'

export const getReportTags = gql`
  query {
    reportsDictionary {
      id
      title
      children {
        id
        title
        markets
      }
    }
  }
`

export const getTagsByMarketsAndDates = gql`
  query data($marketIds: [String], $startDate: String, $endDate: String) {
    tagsByMarketsAndDates(
      marketIds: $marketIds
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      title
      children {
        id
        title
      }
    }
  }
`
