import {
  _BINS_FETCH,
  _BINS_ERROR,
  _BINS_SEARCH,
  _BINS_LOADING,
  _BIN_ADDED,
  _BIN_DEACTIVATE,
  _BIN_ACTIVATE,
  _BIN_RESET_FILTER,
  _BIN_SET_FILTER_MARKET,
  _BIN_SET_FILTER_ROUTE,
  _BIN_SET_FILTER_STATUS,
  _BINS_START_FETCH_UNASSIGNED_BINS_OPTIONS,
  _BINS_END_FETCH_UNASSIGNED_BINS_OPTIONS,
} from '../actions/bins'

const initialState = {
  bins: [],
  unassingedBinsOptions: {
    bins: [],
    loading: false,
  },
  bin: {},
  filters: {
    market: null,
    page: 0,
    route: null,
    search: null,
    status: null,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _BINS_SEARCH:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload,
        },
      }
    case _BIN_SET_FILTER_MARKET:
      return {
        ...state,
        filters: {
          ...state.filters,
          market: action.payload,
        },
      }
    case _BIN_SET_FILTER_ROUTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          route: action.payload,
        },
      }
    case _BIN_SET_FILTER_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload,
        },
      }
    case _BIN_RESET_FILTER:
      const filters = state.filters

      return {
        ...state,
        filters: {
          ...filters,
          ...action.payload,
        },
      }
    case _BIN_ADDED:
      const found = state.bins.findIndex(bin => bin.id === action.payload.id)
      return {
        ...state,
        loading: false,
        bin: action.payload,
        error: null,
        bins:
          found >= 0
            ? Object.assign([], state.bins, { [found]: action.payload })
            : [...state.bins, action.payload],
      }
    case _BINS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case _BINS_FETCH:
      return {
        ...state,
        bins: action.payload,
        loading: false,
      }
    case _BIN_DEACTIVATE:
      return {
        ...state,
        bins: state.bins.filter(bin => bin.id !== action.payload),
        loading: false,
      }
    case _BIN_ACTIVATE:
      return {
        ...state,
        bins: state.bins.filter(bin => bin.id !== action.payload),
        loading: false,
      }
    case _BINS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case _BINS_START_FETCH_UNASSIGNED_BINS_OPTIONS:
      return {
        ...state,
        unassingedBinsOptions: {
          bins: [],
          loading: true,
        },
      }
    case _BINS_END_FETCH_UNASSIGNED_BINS_OPTIONS:
      return {
        ...state,
        unassingedBinsOptions: {
          bins: action.payload,
          loading: false,
        },
      }
    default:
      return state
  }
}

export default reducer
