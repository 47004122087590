import React from 'react'

import { Menu, Dropdown } from 'semantic-ui-react'
import './styles.scss'
import classNames from 'classnames'

export const MenuDropdown = ({ label, active, children }) => (
  <Menu.Menu className="MenuDropdown__Menu">
    {label && (
      <span
        className={classNames('MenuDropdown__activeLabel', {
          'MenuDropdown__activeLabel--active': active,
        })}
      >
        {label}
      </span>
    )}
    {children}
  </Menu.Menu>
)

export default ({
  label,
  options,
  loading,
  active,
  activeLabel = null,
  onSelect,
  showAll = true,
  ...props
}) => (
  <MenuDropdown label={activeLabel} active={active}>
    <Dropdown
      item
      text={active ? active.name : label}
      loading={loading}
      disabled={loading}
      {...props}
    >
      <Dropdown.Menu>
        {showAll && (
          <Dropdown.Item
            active={active === null}
            onClick={() => onSelect('all')}
            value={null}
          >
            {label}
          </Dropdown.Item>
        )}
        {options &&
          options.map(option => (
            <Dropdown.Item
              icon={option.icon}
              active={active && option.id === active.id}
              key={option.id}
              onClick={() => onSelect(option)}
              value={option.id}
              text={option.name}
            />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  </MenuDropdown>
)
