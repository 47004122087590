import cs from 'classnames'
import React, { useState } from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Icon, Popup } from 'semantic-ui-react'

const CalendarEvent = props => {
  const { event, showContextMenu, hideContextMenu } = props
  const {
    runId,
    route,
    complete,
    started,
    totals: { misc = 0, cloth = 0, trash = 0 } = {},
    totalMiles,
    driver,
    helper,
    driver_accuracy,
  } = event

  const [touchState, setTouchState] = useState({
    latestTap: 0,
    touchStart: null,
    timerHandler: null,
  })

  const className = cs('Route__RunLink Schedule__RunLink', {
    'Route__RunLink-complete': complete,
  })

  const onTouchStart = (e, runId) => {
    if (!touchState.timerHandler) {
      const timerHandler = setTimeout(() => {
        showContextMenu(runId)

        setTouchState({
          ...touchState,
          timerHandler: null,
        })
      }, 1000)

      setTouchState({
        ...touchState,
        touchStart: e.touches[0],
        timerHandler: timerHandler,
      })
    }
  }

  const onTouchMove = e => {
    if (!touchState.touchStart) return false

    if (
      Math.abs(touchState.touchStart.clientX - e.touches[0].clientX) > 35 ||
      Math.abs(touchState.touchStart.clientY - e.touches[0].clientY) > 35
    ) {
      hideContextMenu()
      clearTimeout(touchState.timerHandler)
      setTouchState({
        ...touchState,
        timerHandler: null,
      })
    }
  }

  const onTouchEnd = e => {
    clearTimeout(touchState.timerHandler)
    setTouchState({
      ...touchState,
      timerHandler: null,
    })
  }

  const onTap = () => {
    const latestClick = new Date().getTime()

    // Simulate double-tap
    if (latestClick - touchState.latestTap < 600) onTouchEnd()

    setTouchState({
      ...touchState,
      latestTap: latestClick,
    })
  }

  let optimized_miles = -1

  if (event) {
    if (event.optimized_miles) optimized_miles = event.optimized_miles
    else if (event.route.optimized_miles)
      optimized_miles = event.route.optimized_miles
  }

  return (
    <Popup
      trigger={
        complete || started ? (
          <Link
            className={className}
            to={`/app/reports/run/${runId}`}
            target="_blank"
            onContextMenu={e => {
              hideContextMenu(e)
            }}
          >
            <span>
              {event.is_custom && (
                <Icon
                  name="cubes"
                  size="small"
                  className="customized_run_icon"
                />
              )}
              {route.name}
            </span>
            <strong>{`${driver.first_name} ${driver.last_name}`}</strong>
          </Link>
        ) : (
          <div
            id={`event-${runId}`}
            className={className}
            onContextMenu={e => {
              e.preventDefault()
              showContextMenu(runId)
            }}
            onTouchStart={e => {
              onTouchStart(e, runId)
            }}
            onTouchMove={e => onTouchMove(e)}
            onTouchEnd={e => {
              onTouchEnd(runId)
            }}
            onClick={() => onTap()}
          >
            <span>
              {event.is_custom && (
                <Icon
                  name="cubes"
                  size="small"
                  className="customized_run_icon"
                />
              )}
              {route.name}
            </span>
            <strong>{`${driver.first_name} ${driver.last_name}`}</strong>
          </div>
        )
      }
    >
      <Popup.Content className="calendar-event-popup-content">
        {helper && <FaUser className="tooltip-helper-driver" />}
        <Grid style={{ maxWidth: '230px' }}>
          <Row>
            <Col xs>
              <label>Pickup Totals</label>
            </Col>
          </Row>
          <Row>
            <Col xs>Cloth:</Col>
            <Col xs={5}>{cloth || 'n/a'}</Col>
          </Row>
          <Row>
            <Col xs>Misc:</Col>
            <Col xs={5}>{misc || 'n/a'}</Col>
          </Row>
          <Row>
            <Col xs>Trash:</Col>
            <Col xs={5}>{trash || 'n/a'}</Col>
          </Row>
          <Row>
            <Col xs>Driver Accuracy:</Col>
            <Col xs={5}>{complete ? `${driver_accuracy}%` : 'n/a'}</Col>
          </Row>
          <Row>
            <Col xs>Total Miles:</Col>
            <Col xs={5}>{totalMiles || 'n/a'}</Col>
          </Row>
          <Row>
            <Col xs>Optimized Miles:</Col>
            <Col xs={5}>
              {optimized_miles === -1 ? 'n/a' : optimized_miles.toFixed(2)}
            </Col>
          </Row>
        </Grid>
      </Popup.Content>
    </Popup>
  )
}

export default CalendarEvent
