import gql from 'graphql-tag'

export const userModel = `
  id
  user_name
  primary_email
  contact_type
  first_name
  last_name
  deactivated
  phones {
    id
    value
    is_primary
    type
  }
  markets {
    id
    name
  }
  primary_market
`
export const auth = gql`
  query($username: String!, $password: String!, $dashboard: Boolean) {
    auth(username: $username, password: $password, dashboard: $dashboard) {
      token
    }
  }
`

export const createUser = gql`
  mutation createUser($user: UserInput!, $markets: [MarketUserInput]!) {
    createUser(user: $user, markets: $markets) {
      ${userModel}
    }
  }
`

export const updateUser = gql`
  mutation updateUser($user: UserInput!, $markets: [MarketUserInput]!) {
    updateUser(user: $user, markets: $markets) {
      ${userModel}
    }
  }
`

export const deleteMutation = gql`
  mutation($userId: String!) {
    deleteUser(id: $userId){
      ${userModel}
    }
  }
`

export const usersQuery = gql`
  query getAllUsers($deactivated: Boolean) {
    allUsers(
      filter: { deactivated: $deactivated, phones: { is_primary: true } }
    ) {
      id
      user_name
      primary_email
      contact_type
      first_name
      last_name
      deactivated
      primaryPhone {
        value
        type
      }
      phones {
        id
        value
        is_primary
        type
      }
      markets {
        id
        name
      }
      primary_market
    }
  }
`

export const user = gql`
  query($id: String!) {
    userData(id: $id) {
      id
      primary_email
      user_name
      first_name
      last_name
      contact_type
      deactivated
      markets {
        id
        name
      }
      phones {
        value
      }
      assignedMaintenanceRequestsCount
      assignedPoliceReportsCount
      primary_market
    }
  }
`
