import React from 'react'
import moment from 'moment'
import { get } from 'lodash'

import { withMarkets } from '../../connectors/markets'
import { withForm } from '../../connectors/forms'
import { withAuth } from '../../connectors/auth'
import { Button, Message, Icon } from 'semantic-ui-react'

import RouteReportForm from '../RouteReport/form'

export const LoadingMessage = ({ message, title = 'Just one second' }) => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>{title}</Message.Header>
      {message}
    </Message.Content>
  </Message>
)

@withMarkets
@withAuth
@withForm('RouteReportForm')
export default class Market extends React.PureComponent {
  state = {
    loading: false,
    routeReport: {},
  }

  componentDidMount() {
    this.renderButtons()
  }

  onSubmit = ({ market, ...args }) => {
    const { downloadMarketEOMReport, closeModal, marketId } = this.props
    this.setState({ submitting: true })
    return downloadMarketEOMReport({ marketId: market || marketId, ...args })
      .then(data => data && this.fireDownload(data.url))
      .then(_ => closeModal())
      .catch(error => this.setState({ error, submitting: false }))
  }

  fireDownload(url) {
    window.open(url, '_blank')
  }

  renderButtons() {
    const { renderButtons, closeModal, submitForm } = this.props

    return renderButtons(
      <div>
        <Button negative onClick={closeModal}>
          Nevermind
        </Button>
        <Button
          positive
          labelPosition="right"
          icon="download"
          onClick={submitForm}
          content="Generate"
        />
      </div>
    )
  }

  render() {
    const { error, submitting, history, loadingHistory } = this.state
    const { marketId, auth: { user: { markets } } } = this.props

    if (submitting) {
      return <LoadingMessage message="Generating report..." />
    }
    return (
      <div>
        <RouteReportForm
          key="RouteReportForm"
          onSubmit={this.onSubmit}
          history={history}
          initialValues={{
            year: moment().year(),
            month: moment().month() + 1,
            regenerate: false,
            market: get(markets.find(({ id }) => id === marketId), 'id'),
          }}
          isAllRoutes={true}
          loadingHistory={loadingHistory}
        />
        {error && (
          <Message error header="Error" key="userError">
            {error.message}
          </Message>
        )}
      </div>
    )
  }
}
