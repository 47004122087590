import React from 'react'
import { Link } from 'react-router-dom'

import _map from 'lodash/map'

import { Button, Modal, Message, Icon } from 'semantic-ui-react'

const MaintenanceRequestHistoryLink = ({
  id,
  date,
  bin: { serial_number },
}) => (
  <div>
    <Link to={`/app/maintenance-requests/${id}?view=true`} target="_blank">
      Maintenance Request for Bin ({serial_number}) on {date.substring(0, 10)}
    </Link>
  </div>
)

class MaintenanceRequestsHistory extends React.PureComponent {
  state = { open: false }

  onOpen = () => {
    this.setState({ open: true })
  }

  close = () => this.setState({ open: false })

  render() {
    const { trigger, loading, items } = this.props
    const { open, error } = this.state

    return (
      <Modal
        trigger={trigger}
        dimmer="blurring"
        size="tiny"
        open={open}
        onOpen={this.onOpen}
        onClose={this.close}
      >
        <Modal.Header>Maintenance Requests History</Modal.Header>
        <Modal.Content scrolling>
          {_map(items, item => (
            <MaintenanceRequestHistoryLink key={item.id} {...item} />
          ))}
          {loading && (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                We are fetching the history.
              </Message.Content>
            </Message>
          )}
          {error && <Message error header={error} />}
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Close"
            onClick={this.close}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default MaintenanceRequestsHistory
