import React from 'react'

export default ({ formComponent, meta, ...props }) => {
  const Component = formComponent
  const error = meta.touched && meta.error
  const { input, ...otherProps } = props
  const valueProps = {}

  // Check for checkbox cuz it needs a `checked` not a `value`
  if (formComponent._meta && formComponent._meta.name === 'FormCheckbox') {
    valueProps.checked = input.value === true ? true : false
  } else {
    valueProps.value = input.value
  }

  return (
    <Component {...input} {...otherProps} {...valueProps} error={!!error} />
  )
}
