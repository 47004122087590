import DriverPicker from '../../DriverPicker'
import FormField from '../../FormField'
import { withRuns } from '../../../connectors/runs'
import moment from 'moment'
import React, { Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Grid, Icon, Message, Modal } from 'semantic-ui-react'
import DatePickerSingle from '../../DatePickerSingle'

const validate = args => {
  const fields = [{ id: 'driver' }, { id: 'run_date' }]
  const errors = {}

  fields.forEach(field => {
    if (args[field.id] === undefined || args[field.id] === null) {
      errors[field.id] = 'invalid/required'
    }
  })

  return errors
}

@reduxForm({
  form: 'QuickEditRunForm',
  validate,
})
@withRuns
export class QuickEditRunModal extends React.Component {
  state = {
    open: true,
    error: null,
    editSuccessfully: false,
    runDate: null,
  }

  setOpen(open) {
    this.setState({
      open,
    })
  }

  clearError() {
    this.setState({
      error: null,
    })
  }

  setError(error) {
    this.setState({
      error,
    })
  }

  close() {
    const { onCloseModal } = this.props

    onCloseModal()
    this.setOpen(false)
  }

  confirm(data) {
    const { run, quickEdit, runs: { drivers } } = this.props
    const { runDate } = this.state

    const run_date = moment(runDate)
      .set('hours', moment(runDate).get('hours'))
      .set('minutes', moment(runDate).get('minutes'))
      .set('seconds', moment(runDate).get('seconds'))

    const runDateString = run_date
      .utc()
      .hours(12)
      .toISOString()

    this.clearError()

    quickEdit(run.runId, runDateString, data.driver, data.helper).then(
      result => {
        if (result.type === 'RUN_EDITED_SUCCESSFULLY') {
          this.runEditedSuccessfully({
            id: run.runId,
            route: run.route.id,
            driver: drivers.find(driver => driver.id === data.driver),
            helper: drivers.find(driver => driver.id === data.helper),
            runDate: moment(result.payload.run_date).toDate(),
            start: moment(result.payload.run_date).startOf('D'),
            end: moment(result.payload.run_date).endOf('D'),
            is_custom: run.is_custom,
          })
        } else if (result.type === 'RUN_EDIT_FAILED') {
          this.setError(result.payload.message)
        }
      }
    )
  }

  runEditedSuccessfully(updates) {
    const { onSuccessful, run } = this.props

    this.setState({
      editSuccessfully: true,
    })

    onSuccessful({
      ...run,
      ...updates,
    })
  }

  onPickDate = date => {
    this.setState({
      runDate: date,
    })
  }

  componentDidMount() {
    const { run, getMarketData, initialValues: { run_date } } = this.props
    this.setState({
      runDate: run_date,
    })
    getMarketData(run.route.market.id)
  }

  render() {
    const {
      runs: { loading, drivers },
      handleSubmit,
      initialValues: { run_date },
    } = this.props
    const { open, error, editSuccessfully } = this.state

    return (
      <Modal
        open={open}
        dimmer={'blurring'}
        size={'tiny'}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Editing Run ...</Modal.Header>
        <Modal.Content>
          {!editSuccessfully && (
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label class="label">Date</label>
                    <DatePickerSingle
                      format="MM/dd/yyyy"
                      value={run_date}
                      onSelect={this.onPickDate}
                      disabled={loading}
                      loading={loading}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Field
                      name="driver"
                      label="Driver"
                      component={FormField}
                      formComponent={DriverPicker}
                      drivers={drivers.map(driver => ({
                        value: driver.id,
                        text: `${driver.first_name} ${driver.last_name}`,
                      }))}
                      required
                      loading={loading}
                      disabled={loading}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      name="helper"
                      label="Helper"
                      component={FormField}
                      formComponent={DriverPicker}
                      drivers={drivers.map(driver => ({
                        value: driver.id,
                        text: `${driver.first_name} ${driver.last_name}`,
                      }))}
                      loading={loading}
                      disabled={loading}
                      allowEmpty={true}
                      emptyOptionText="No helper"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}

          {error && (
            <Message color="red">
              <Icon name="exclamation triangle" />
              {error}
            </Message>
          )}

          {editSuccessfully && (
            <Message color="green">
              <Icon name="check" />
              The run has been updated successfully
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          {editSuccessfully ? (
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => this.close()}
              positive
            >
              Done
            </Button>
          ) : (
            <Fragment>
              <Button
                content="Confirm"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit(data => this.confirm(data))}
                positive
              />
              <Button
                loading={loading}
                disabled={loading}
                color="red"
                onClick={() => this.close()}
              >
                Cancel
              </Button>
            </Fragment>
          )}
        </Modal.Actions>
      </Modal>
    )
  }
}

export default QuickEditRunModal
