import React from 'react'
import { withAuth } from '../../connectors/auth'

import { Button, Modal } from 'semantic-ui-react'

@withAuth
export default class Logout extends React.Component {
  state = {
    open: false,
  }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  logout = () => {
    const { signout } = this.props
    signout()
  }

  render() {
    const { children } = this.props
    const { open } = this.state

    return (
      <Modal
        closeOnRootNodeClick={false}
        onClose={this.close}
        open={open}
        trigger={<div onClick={this.open}>{children}</div>}
      >
        <Modal.Header>Confirm</Modal.Header>
        <Modal.Content>Are you sure you want to logout?</Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close} color="red">
            Nevermind
          </Button>
          <Button onClick={this.logout} color="green">
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
