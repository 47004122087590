import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Header from './Header'
import ReportsLocationsPropertyManagerView from '../../views/ReportsLocationsPropertyManager'

import './styles.scss'

export default class ReportsLeasedLocations extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <Switch>
          <Route component={ReportsLocationsPropertyManagerView} />
        </Switch>
      </Grid>
    )
  }
}
