import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import React from 'react'

import { WithReportTags } from '../../connectors/reportTags'
import { Dropdown, Form } from 'semantic-ui-react'
import { contractSelectionLabel } from '../../utils/competitors'

import './styles.scss'

const getText = tag => _get(tag, 'title', _get(tag, 'title', tag.label))
const renderLabel = (values, onRemove) => ({ value }) => {
  const tag = _find(values, { id: value })
  const title = getText(tag)
  const children = _get(tag, 'children')
  const hasChildren = _isArray(children)
  let text = title

  if (hasChildren) {
    const hasSelectAll = _find(children, childTag => childTag.id === tag.id)

    if (hasSelectAll) {
      text += '(All)'
    } else {
      text += ` (${_map(children, child => {
        const title = getText(child)
        const selections = _get(child, 'selections')

        if (selections) {
          return (
            title +
            ` (${selections
              .map(selection => {
                const selectionName = selection.label
                  ? selection.label
                  : selection.title

                return contractSelectionLabel(selectionName)
              })
              .join(', ')})`
          )
        } else {
          return title
        }
      }).join()})`
    }
  }

  return {
    content: text,
    onRemove: onRemove,
  }
}

const ItemTag = ({ id, title, markets, onSelect, asSelections, ...props }) => {
  const children = asSelections
    ? _get(props, 'selections')
    : _get(props, 'children')
  const subValue = asSelections ? 'selections' : 'children'

  if (children) {
    const onClick = item =>
      onSelect({ id, title, markets, [`${subValue}`]: [item] })
    return (
      <Dropdown text={title} pointing="right" className="link item">
        <Dropdown.Menu>
          {_map(children, tag => (
            <ItemTag key={tag.id} {...tag} onSelect={onClick} asSelections />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  } else {
    return (
      <Dropdown.Item
        value={id}
        text={title}
        onClick={() => onSelect({ id, title, markets })}
      />
    )
  }
}

const customMerge = (obj, srcObj) => {
  if (_isArray(obj)) {
    return [
      ..._map(obj, item => customMerge(item, _find(srcObj, { id: item.id }))),
      ..._filter(srcObj, ({ id }) => !_find(obj, { id })),
    ]
  }

  if (!_isArray(obj) && _isObject(obj)) {
    return _reduce(
      obj,
      (ac, value, prop) => {
        if (prop === 'children') {
          ac[prop] = customMerge(value, _get(srcObj, prop, value))
        } else if (_isObject(value)) {
          ac[prop] = customMerge(value, _get(srcObj, prop, value))
        } else {
          ac[prop] = _get(srcObj, prop, value)
        }

        return ac
      },
      {}
    )
  } else return srcObj ? srcObj : obj
}

@WithReportTags
export default class ReportTagsField extends React.PureComponent {
  addValue = itemTag => {
    const { value, onChange } = this.props
    if (_isArray(value)) {
      onChange(customMerge(value, [itemTag]))
    } else onChange([itemTag])
  }
  removeValue = (ev, data) => {
    const { value, onChange } = this.props

    onChange(_filter(value, tag => tag.id !== data.value))
  }

  render() {
    const { label, value, reportTags, options, loading, market } = this.props

    const subDetailsTag = reportTags
      ? reportTags.find(tag => tag.title === 'Sub Detail')
      : null
    const reportTagsWithoutSubDetail = reportTags
      ? reportTags.filter(tag => tag.title !== 'Sub Detail')
      : []
    const reportTagOptions = []

    if (subDetailsTag) {
      subDetailsTag.children.forEach(tag => {
        delete tag['markets']
      })
    }

    if (reportTagsWithoutSubDetail) {
      reportTagsWithoutSubDetail.forEach(tag => {
        const tagCopy = { ...tag }

        if (tagCopy.title === 'Competitor') {
          if (market) {
            tagCopy.children = tag.children
              .filter(children => {
                if (!children.markets.includes(market)) {
                  return false
                }

                delete children['markets']
                children['selections'] = [...subDetailsTag.children]

                return children
              })
              .sort((a, b) => a.title.localeCompare(b.title))
          }

          reportTagOptions.push(tagCopy)
        } else {
          reportTagOptions.push(tagCopy)
        }
      })
    }

    const dropdownOptions = _map(options || reportTagOptions, tag => ({
      value: tag.id,
    }))

    return (
      <Form.Field>
        {label && <label>{label}</label>}
        <Dropdown
          fluid
          selection
          scrolling={false}
          multiple
          value={_map(value, 'id')}
          renderLabel={renderLabel(value, this.removeValue)}
          loading={loading}
          className="ReportTagsField"
          options={dropdownOptions}
          {...!dropdownOptions.length &&
            !loading && { text: 'No tags available' }}
        >
          <Dropdown.Menu>
            {!!dropdownOptions.length &&
              _map(options || reportTagOptions, tag => (
                <ItemTag key={tag.id} {...tag} onSelect={this.addValue} />
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Field>
    )
  }
}
