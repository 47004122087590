import React from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { Route, Switch } from 'react-router-dom'

import { ReportCraRouteViewIndex } from '../../views/ReportsCraRoute'
import Header from './Header'
import './styles.scss'

export default class ReportsCraRouteReport extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <Switch>
          <Route component={ReportCraRouteViewIndex} />
        </Switch>
      </Grid>
    )
  }
}
