import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Button, Modal, Feed, Message, Icon } from 'semantic-ui-react'
import { withRuns } from '../../connectors/runs'

const eventsTypes = {
  TRUCK_CURRENT_ADD_TRUCK_NUMBER: 'Driver have entered the Truck Number',
  TRUCK_CURRENT_ADD_TRUCK_MILEAGE:
    'Driver have entered the initial or final mileaje',
  RUN_ADD_REPORT: 'Add a report to the run. No in use',
  RUN_ADD_WEATHER_REPORT: 'Add the weather state',
  RUN_SET_STALLED:
    'Added 0 weight to all Route/Bins reports, Report the Truck Full or Brokedown',
  RUN_SET_FINISHED:
    'Added "finished_at" params to the app_state when Driver click on Logout',
  ROUTES_ADD_REPORT:
    'Added Unloading report when Drive click the Submit Button',
  ROUTES_UPDATE_REPORT: 'Unloading Report is goin to be edited',
  ROUTES_REMOVE_REPORT: 'This is not in use.',
  ROUTES_BINLOCATIONS_BINS_ADD_BIN_REPORT: 'Report is been attached to the bin',
  ROUTES_BINLOCATIONS_BINS_ADD_ALL_BIN_REPORT:
    'Driver have been click some reason of Truck Stalled',
}
const LogEvent = ({ data }) => (
  <Feed.Event>
    <Feed.Label>
      <Icon name="pencil" />
    </Feed.Label>
    <Feed.Content>
      <Feed.Summary>
        <a>{data.type}</a> {eventsTypes[data.type]}
        <Feed.Date>{moment(data.created_at).calendar()}</Feed.Date>
      </Feed.Summary>
      <Feed.Extra text>{data.payload}</Feed.Extra>
    </Feed.Content>
  </Feed.Event>
)

@withRuns
class RunLogs extends Component {
  state = { open: false }

  onOpen = () => {
    const { runId, getRunLogs } = this.props
    this.setState({ open: true, loading: true, error: null })
    getRunLogs(runId)
      .then(logs => {
        if (_.isArray(logs)) return { logs }
        return { error: 'Can`t load logs for this run.' }
      })
      .catch(err => {
        return { error: 'Can`t load logs for this run.' }
      })
      .then(state => this.setState({ ...state, loading: false }))
  }
  close = () => this.setState({ open: false })

  render() {
    const { trigger } = this.props
    const { open, error, loading, logs } = this.state

    return (
      <Modal
        trigger={trigger}
        dimmer="blurring"
        size="tiny"
        open={open}
        onOpen={this.onOpen}
        onClose={this.close}
      >
        <Modal.Header>Run Logs</Modal.Header>
        <Modal.Content scrolling>
          <Feed>{logs && logs.map(event => <LogEvent data={event} />)}</Feed>
          {loading && (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                We are fetching the logs.
              </Message.Content>
            </Message>
          )}
          {error && <Message error header={error} />}
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Close"
            onClick={this.close}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default RunLogs
