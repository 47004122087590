import gql from 'graphql-tag'

export const binModel = `
  id
  deactivated
  status
  box_code
  locationId
  serial_number
  unique_name
  date_in_service
  moleiot_mac
  material
  size
  chute
  manufacture
  photos
  location {
    name
    address
    city
    postal
    state
    latitude
    longitude
  }
  routes {
    color
    name
    id
    market
  }
  market
  markets
  color {
    color
    name
  }
  missing
`

export const bins = gql`
  query bins($deactivated: Boolean, $status: String, $markets: [String]) {
    bins (
      filter: {
        deactivated: $deactivated,
        status: $status,
        markets: $markets
      }
    ) {
      ${binModel}
    }
  }
`

export const bin = gql`
  query($id: String!) {
    binData(id: $id) {
      ${binModel}
    }
  }
`

export const createBin = gql`
  mutation createBin($bin: BinInput!) {
    createBin(bin: $bin) {
      ${binModel}
    }
  }
`

export const updateBin = gql`
  mutation updateBin($binId: String!, $bin: BinInput!) {
    updateBin(binId: $binId, bin: $bin) {
      ${binModel}
    }
  }
`

export const deactivateBin = gql`
  mutation($binId: String!) {
    deactivateBin(binId: $binId){
      ${binModel}
    }
  }
`

export const activateBin = gql`
  mutation($binId: String!) {
    activateBin(binId: $binId){
      ${binModel}
    }
  }
`

export const getMissingBins = gql`
  query($markets: [String]!) {
    getMissingBins(markets: $markets) {
      ${binModel}
    }
  }
`
