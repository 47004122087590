import React from 'react'
import { Divider } from 'semantic-ui-react'
import 'url-search-params-polyfill'
import { generateRangeDate } from '../../utils/date'

import { withMarketData, withMarkets } from '../../connectors/markets'
import { WithDriversReport } from '../../connectors/reports'
import { withRoutes } from '../../connectors/routes'
import { withRouter } from 'react-router-dom'

import DriversReportTable from './DriversReportTable'
import Filters from './Filters'

@withMarkets
@withMarketData
@withRoutes
@WithDriversReport
@withRouter
export default class ReportsDrivers extends React.PureComponent {
  state = {
    pms: [],
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps
    const {
      getRouteReports,
      getRoutesByMarket,
      getMarketReports,
      setCurrent,
      location: { search: oldSearch },
    } = this.props
    const query = new URLSearchParams(search)
    const oldQuery = new URLSearchParams(oldSearch)

    const getRange = _ => {
      const range = generateRangeDate(query.get('range'))
      return {
        start: range.start.toISOString(),
        end: range.end.toISOString(),
      }
    }
    const market = query.get('market')
    if (
      market &&
      query.get('route') &&
      query.get('range') &&
      search !== oldSearch
    ) {
      getRouteReports(query.get('route'), getRange())
    } else if (market && query.get('range') && search !== oldSearch) {
      getMarketReports({
        ids: [market],
        ...getRange(),
      })
    }
    if (market && market !== oldQuery.get('market')) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  componentDidMount() {
    const {
      getMarkets,
      getRoutesByMarket,
      setCurrent,
      location: { search },
    } = this.props

    getMarkets()

    const query = new URLSearchParams(search)
    const market = query.get('market')
    if (market) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  setPMs = pms => {
    this.setState({
      ...this.state,
      pms,
    })
  }

  render() {
    const {
      location: { search },
      driversReport,
      loading,
      drivers,
      current,
      setCurrent,
    } = this.props
    const query = new URLSearchParams(search)

    return (
      <div>
        <Divider hidden />
        <Filters
          currentMarket={current}
          setCurrentMarket={setCurrent}
          drivers={drivers}
        />
        <Divider hidden />
        <DriversReportTable
          driversReport={driversReport}
          loading={loading}
          routeId={query.get('route')}
          start={query.get('start') || undefined}
          end={query.get('end') || undefined}
        />
      </div>
    )
  }
}
