import _first from 'lodash/first'
import _get from 'lodash/get'
import _has from 'lodash/has'
import { createSelector } from 'reselect'

import moment from 'moment/moment'
import { groupByDay } from '../selectors'
import { _getNumber } from '../utils'

const _markets = state => state.auth.user.markets
const _current = state => state.markets.current
const _selectedMarketsForDashboard = state =>
  state.markets.selectedMarketsDashboard
const _range = state => state.markets.range
const _data = state => state.markets.data
const _dataRange = state => state.markets.dataRange
const _userMarkets = state => state.auth.user && state.auth.user.markets

const _selectMarket = (markets, current) => {
  if (!current || current === '') {
    return null
  }
  return markets.find(market => market.id === current)
}

export const getCurrentMarket = createSelector(
  _markets,
  _current,
  (markets, current) => _selectMarket(markets, current)
)

export const getReportParams = createSelector(
  _userMarkets,
  _current,
  _range,
  (markets = [], current, range) => {
    if (!markets) {
      return null
    }

    return {
      ids: current ? [current] : markets.map(market => market.id),
      start: range.start.format(),
      end: range.end.format(),
    }
  }
)

export const getReportParamsForDashboard = createSelector(
  _userMarkets,
  _selectedMarketsForDashboard,
  _range,
  (markets = [], selectedMarketsDashboard, range) => {
    if (!markets) {
      return null
    }

    let ids = selectedMarketsDashboard

    if (ids.length === 0) {
      ids = markets.map(market => market.id)
    }

    return {
      ids,
      start: range.start.format(),
      end: range.end.format(),
    }
  }
)

function addWeights(obj, includeTrash = true) {
  return (
    _getNumber(obj, 'cloth') +
    _getNumber(obj, 'misc') +
    (includeTrash ? _getNumber(obj, 'trash') : 0)
  )
}

function addBins(totals, reportBins) {
  if (!isNaN(reportBins)) {
    // Support old version
    totals.bins += Number(reportBins)
    totals.bin += Number(reportBins)
  } else {
    totals.bins += Number(reportBins.bins)
    totals.bin += Number(reportBins.bin)
    totals.container += Number(reportBins.container)
  }

  return totals
}

function addLocations(totals, reportLocations) {
  if (!isNaN(reportLocations)) {
    // Support old version
    totals.locations += Number(reportLocations)
    totals.bin_locations += Number(reportLocations)
  } else {
    totals.locations += Number(reportLocations.locations)
    totals.bin_locations += Number(reportLocations.bin_locations)
    totals.container_locations += Number(reportLocations.container_locations)
  }

  return totals
}

export function distributeRangeInMonths(start, end) {
  const months = []
  let pointer = moment(start)

  while (pointer.isBefore(end)) {
    const year = moment(pointer).format('YYYY')
    const month = moment(pointer)
      .format('MMM')
      .toLowerCase()
    const days = moment(pointer).daysInMonth()
    let selectedDays = 0

    if (moment(pointer).format('MMM') === moment(end).format('MMM')) {
      selectedDays = Math.abs(moment(end).date() - moment(pointer).date()) + 1
    } else {
      selectedDays =
        Math.abs(
          moment(pointer)
            .endOf('month')
            .date() - moment(pointer).date()
        ) + 1
    }

    months.push({
      year,
      month,
      days,
      selectedDays,
    })

    pointer = moment(pointer)
      .add(1, 'month')
      .startOf('month')
  }

  return months
}

export function calculateGoal(months, marketGoals) {
  try {
    let goal = 0

    months.forEach(month => {
      if (_has(marketGoals, month.year)) {
        goal +=
          _getNumber(marketGoals[month.year], month.month) /
          month.days *
          month.selectedDays
      }
    })

    return goal
  } catch (error) {
    console.log(error)
  }
}

const _getReportData = (data, range) => {
  if (!data) {
    return null
  }

  const rangeMonths = distributeRangeInMonths(
    moment(range.start).toDate(),
    moment(range.end).toDate()
  )

  const summary = data.reduce(
    (ret, market) => {
      const { reports } = market
      const keys = [
        'cloth',
        'misc',
        'trash',
        'bins_weight',
        'containers_weight',
      ]
      // This shouldn't happen, but it did
      if (!reports) {
        return ret
      }

      // Aggregate for each type of pickup
      if (reports.totals) {
        keys.forEach(
          key => (ret.totals[key] += _getNumber(reports, ['totals', key], 0))
        )
      }

      const previousYearReportTotals = reports.previousYearReportList.reduce(
        (totals, curr) => {
          totals.cloth += curr.cloth
          totals.misc += curr.misc
          totals.total += curr.trash

          return totals
        },
        {
          cloth: 0,
          misc: 0,
          total: 0,
        }
      )

      const untilTodayReportTotals = reports.untilTodayReportList.reduce(
        (totals, curr) => {
          totals.cloth += curr.cloth
          totals.misc += curr.misc
          totals.total += curr.trash

          return totals
        },
        {
          cloth: 0,
          misc: 0,
          total: 0,
        }
      )

      const untilTodayLastYearReportTotals = reports.untilTodayLastYearReportList.reduce(
        (totals, curr) => {
          totals.cloth += curr.cloth
          totals.misc += curr.misc
          totals.total += curr.trash

          return totals
        },
        {
          cloth: 0,
          misc: 0,
          total: 0,
        }
      )

      // Record totals
      ret.totalBins = addBins(ret.totalBins, reports.bins)
      ret.totalLocations = addLocations(ret.totalLocations, reports.locations)
      ret.totalWeight += addWeights(reports.totals)
      ret.totalSellable += addWeights(reports.totals, false)
      ret.totalGoals += calculateGoal(rangeMonths, reports.goals)
      // ret.projected += _getNumber(market, 'projection')
      ret.totalProjectedOrActual += _getNumber(
        reports,
        'projectedOrActual.projected'
      )
      ret.totalBinsProjectedActual += _getNumber(
        reports,
        'projectedOrActual.bins_projected'
      )
      ret.totalContainersProjectedActual += _getNumber(
        reports,
        'projectedOrActual.containers_projected'
      )
      ret.previousYearSellableTotal += addWeights(
        previousYearReportTotals,
        false
      )
      ret.untilTodaySellableTotal += addWeights(untilTodayReportTotals, false)
      ret.untilTodayLastYearSellableTotal += addWeights(
        untilTodayLastYearReportTotals,
        false
      )

      return ret
    },
    {
      // Base report object
      totals: {
        cloth: 0,
        misc: 0,
        trash: 0,
        bins_weight: 0,
        containers_weight: 0,
      },
      avgBinWeight: {
        avgPerBins: 0,
        avgPerBin: 0,
        avgPerContainer: 0,
      },
      avgLocationWeight: {
        avgPerLocations: 0,
        avgPerBinLocation: 0,
        avgPerContainerLocation: 0,
      },
      // projected: 0,
      totalBins: {
        bins: 0,
        bin: 0,
        container: 0,
      },
      totalGoals: 0,
      totalLocations: {
        locations: 0,
        bin_locations: 0,
        container_locations: 0,
      },
      totalSellable: 0,
      totalWeight: 0,
      totalProjectedOrActual: 0,
      totalBinsProjectedActual: 0,
      totalContainersProjectedActual: 0,
      previousYearSellableTotal: 0,
      untilTodaySellableTotal: 0,
      untilTodayLastYearSellableTotal: 0,
    }
  )

  // Finally get the average of total weight over bins
  summary.avgBinWeight.avgPerBins =
    summary.totalProjectedOrActual / summary.totalBins.bins
  summary.avgBinWeight.avgPerBin =
    summary.totalBinsProjectedActual / summary.totalBins.bin

	if(summary.totalBins.container > 0) {
		summary.avgBinWeight.avgPerContainer
			=
			summary.totalContainersProjectedActual
			/
			summary.totalBins.container
	}

  summary.avgLocationWeight.avgPerLocations =
    summary.totalProjectedOrActual / summary.totalLocations.locations
  summary.avgLocationWeight.avgPerBinLocation =
    summary.totalBinsProjectedActual / summary.totalLocations.bin_locations

	if(summary.totalLocations.container_locations > 0) {
		summary.avgLocationWeight.avgPerContainerLocation
			=
			summary.totalContainersProjectedActual
			/
			summary.totalLocations.container_locations
	}


  return summary
}

export const getCurrentMarketData = createSelector(
  _data,
  _current,
  (markets, current) => {
    const market = _selectMarket(markets, current)
    if (!market) {
      return null
    }

    const data = market.reports && market.reports.reportList
    if (!data) {
      return null
    }

    return groupByDay(data, market.reports.totals)
  }
)

export const getReportData = createSelector(_data, _range, _getReportData)

export const getReportDataRange = createSelector(
  _dataRange,
  _range,
  _getReportData
)

export const getWeeklyReport = createSelector(
  _data,
  _current,
  (markets, current) => {
    if (!current || current === '') {
      return null
    }
    const market = markets.find(market => market.id === current)

    if (!market) {
      return null
    }

    const { reports: { reportList } } = market

    if (!reportList) {
      return null
    }

    const weeklySummary = market.reports.reportList.map(report => {
      let total = {
        name: '',
        totalWeight: 0,
      }
      if (report) {
        total.name = report.name
        total.totalWeight += addWeights(report)
      }
      return total
    })

    return weeklySummary
  }
)

export const Markets = createSelector(
  (data, entry) => _get(data, entry),
  rawMarkets => {
    const markets = rawMarkets
    const market = _first(markets)

    return {
      markets,
      market,
    }
  }
)
