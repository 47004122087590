import React from 'react'
import 'url-search-params-polyfill'
import _get from 'lodash/get'
import { withMarkets, withMarketData } from '../../connectors/markets'
import { withRoutes, withRoutesReport } from '../../connectors/routes'
import { generateRangeDate } from '../../utils/date'

import { Divider, Grid } from 'semantic-ui-react'
import { FormattedNumber } from 'react-intl'

import ChartCard from '../../components/ChartCard'
import ReportsTotal from '../../components/ReportsTotal'
import ReportCard from '../../components/ReportCard'

import Filters from './Filters'

import { dataKeys } from './data-keys'

@withMarkets
@withMarketData
@withRoutesReport
@withRoutes
export default class ReportsMonthlyGoal extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps
    const {
      getRouteReports,
      getRoutesByMarket,
      getMarketReports,
      setCurrent,
      location: { search: oldSearch },
    } = this.props
    const query = new URLSearchParams(search)
    const oldQuery = new URLSearchParams(oldSearch)

    const getRange = _ => {
      const range = generateRangeDate(query.get('range'))
      return {
        start: range.start.toISOString(),
        end: range.end.toISOString(),
      }
    }
    const market = query.get('market')
    if (
      market &&
      query.get('route') &&
      query.get('range') &&
      search !== oldSearch
    ) {
      getRouteReports(query.get('route'), getRange())
    } else if (market && query.get('range') && search !== oldSearch) {
      getMarketReports({
        ids: [market],
        ...getRange(),
      })
    }
    if (market !== oldQuery.get('market')) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  componentDidMount() {
    const { getMarkets } = this.props

    getMarkets()
  }

  render() {
    const {
      routes: { data },
      currentData,
      currentRouteData,
      marketReport,
    } = this.props

    const chartData = data ? currentRouteData : currentData
    const { trash, sellable } = _get(chartData, 'stats', {})

    const total = trash + sellable

    return (
      <div>
        <Divider hidden />
        <Filters />
        <Divider hidden />
        <table class="ui sortable table">
          <thead>
            <tr>
              <th>
                <span>JAN</span>
              </th>
              <th>
                <span>FEB</span>
              </th>
              <th>
                <span>MAR</span>
              </th>
              <th>
                <span>APR</span>
              </th>
              <th>
                <span>MAY</span>
              </th>
              <th>
                <span>JUN</span>
              </th>
              <th>
                <span>JUL</span>
              </th>
              <th>
                <span>AUG</span>
              </th>
              <th>
                <span>SEP</span>
              </th>
              <th>
                <span>OCT</span>
              </th>
              <th>
                <span>NOV</span>
              </th>
              <th>
                <span>DEC</span>
              </th>
            </tr>
          </thead>
          <tbody>No data.</tbody>
        </table>
        {/* TODO: The following div was only made to not create unused-vars error
          during deploy - this should be removed when working on report */}
        <div hidden>
          <Grid>
            {total} {marketReport}
          </Grid>
          <FormattedNumber>{dataKeys}</FormattedNumber>
          <ChartCard />
          <ReportsTotal />
          <ReportCard />
          {/* {dataKeys} */}
        </div>
      </div>
    )
  }
}
