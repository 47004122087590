import React from 'react'
import { IconContext } from 'react-icons'
import { BsEraserFill } from 'react-icons/bs'
import { Popup } from 'semantic-ui-react'

export default class EditedStopPopup extends React.Component {
  render() {
    return (
      <Popup
        trigger={
          <div>
            <EditedStopIcon />
          </div>
        }
      >
        <Popup.Content>Edited Stop</Popup.Content>
      </Popup>
    )
  }
}

class EditedStopIcon extends React.Component {
  render() {
    return (
      <IconContext.Provider value={{ size: 22 }}>
        <BsEraserFill />
      </IconContext.Provider>
    )
  }
}
