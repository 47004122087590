import { withAuth } from '../../connectors/auth'
import { withMarkets } from '../../connectors/markets'
import {
  withAllRoutesReport,
  withRoutes,
  withShowInactiveRoutes,
} from '../../connectors/routes'
import _first from 'lodash/first'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import 'url-search-params-polyfill'

import { Col, Grid, Row } from 'react-flexbox-grid'
import { Segment } from 'semantic-ui-react'

import Confirmation from '../../components/Confirmation'
import ModalTrigger from '../../components/ModalTrigger'
import RoutesGrid from '../../components/RoutesGrid'
import RouteForm from '../../forms/Route'
import Header from './Header'

import RouteView from '../../views/Route'
import './styles.scss'

const CopyRouteConfirmation = withRoutes(
  ({ copy, routeId, open, history, url }) => (
    <Confirmation
      open={open}
      title="Copy Route"
      createLabel="Copy"
      onConfirm={() => {
        history.replace(url)
        copy(routeId)
      }}
      onClose={() => {
        history.replace(url)
      }}
      message={<h3>Are you sure to copy this Route</h3>}
    />
  )
)

const RemoveRouteConfirmation = withRoutes(
  ({ remove, routeId, open, history, url }) => (
    <Confirmation
      open={open}
      title="Deleting Route"
      createLabel="Delete"
      onConfirm={() => {
        history.replace(url)
        remove(routeId)
      }}
      onClose={() => {
        history.replace(url)
      }}
      message={<h3>Are you sure to delete this Route</h3>}
    />
  )
)

@withAllRoutesReport
@withMarkets
@withAuth
@withShowInactiveRoutes
class Routes extends React.PureComponent {
  setCurrentMarket(nextProps = {}) {
    const { getRoutesByMarket } = this.props
    const { match: { params: { marketId } } } = this.props
    const {
      match: { params: { marketId: nextMarketId } = {} } = {},
    } = nextProps

    if (marketId !== nextMarketId)
      getRoutesByMarket(nextMarketId || marketId, { cached: true })
  }

  componentWillReceiveProps(nextProps) {
    this.setCurrentMarket(nextProps)
  }

  componentDidMount() {
    this.setCurrentMarket()
  }

  render() {
    const {
      current,
      routes: { routes, loading },
      auth: { user: { markets, primary_market } },
      showInactiveRoutesStatus,
    } = this.props
    // const markets = _get(auth, 'user.markets')

    let filteredRoutes = []

    if (showInactiveRoutesStatus === 'inactiveRoutes')
      filteredRoutes = routes.filter(route => !!!route.inactive === false)
    else filteredRoutes = routes.filter(route => !!route.inactive === false)

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        {!markets ? (
          <Segment
            loading={true}
            padded={false}
            style={{ minHeight: '300px' }}
          />
        ) : (
          <Switch>
            <Route
              exact
              path="/app/routes/:marketId/:routeId"
              component={RouteView}
            />
            <Route
              exact
              path="/app/routes/:marketId"
              render={() => (
                <RoutesGrid loading={loading} routes={filteredRoutes} />
              )}
            />
            <Route
              render={() => {
                const selected = !!current
                  ? current.id
                  : !!primary_market ? primary_market : _first(markets).id

                return <Redirect to={`/app/routes/${selected}`} />
              }}
            />
          </Switch>
        )}
        <Route
          path="/app/routes/:marketId/:routeId?"
          render={({
            match: { params: { routeId, marketId }, url },
            location: { search },
            history,
          }) => {
            const query = new URLSearchParams(search)
            return (
              <div>
                <ModalTrigger
                  open={query.get('edit') ? true : false}
                  title="Editing Route"
                  form={<RouteForm routeId={routeId} />}
                  onClose={() => {
                    history.push(`/app/routes/${marketId}`)
                  }}
                />
                <RemoveRouteConfirmation
                  routeId={routeId}
                  open={query.get('delete') ? true : false}
                  url={`/app/routes/${marketId}`}
                  history={history}
                />
                <CopyRouteConfirmation
                  routeId={routeId}
                  open={query.get('copy') ? true : false}
                  url={`/app/routes/${marketId}`}
                  history={history}
                />
              </div>
            )
          }}
        />
      </Grid>
    )
  }
}
export default Routes
