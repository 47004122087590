import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import thunk from 'redux-thunk'

import * as reducers from '../reducers'

const combinedReducers = combineReducers({
	form: reduxFormReducer,
	...reducers,
})

let composeEnhancers
if(process.env.REACT_APP_ENV === 'local') {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const configureStore = initialState => {
	let middlewares = [thunk];

	if(process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
		middlewares.push(
			createLogger({ collapsed: true })
		);
	}

	let middleware = applyMiddleware(...middlewares);

	let store
	if(process.env.REACT_APP_ENV === 'local') {
		store = createStore(
			combinedReducers,
			initialState,
			composeEnhancers(middleware)
		)
	} else {
		store = createStore(combinedReducers, initialState, middleware)
	}

	if(module.hot) {
		module.hot.accept('../reducers', () => {
			store.replaceReducer(require('../reducers').default)
		})
	}

	return store
}

export default configureStore
