import React from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { Route, Switch } from 'react-router-dom'

import RunReport from '../../views/RunReport'
import ReportsView from '../../views/Reports'
import Header from './Header'

import './styles.scss'

export default class Reports extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <Switch>
          <Route path="/app/reports/run/:runId" component={RunReport} />
          <Route component={ReportsView} />
        </Switch>
      </Grid>
    )
  }
}
