import React from 'react'
import { Route } from 'react-router-dom'
import 'url-search-params-polyfill'

import { Col, Grid, Row } from 'react-flexbox-grid'
import { Checkbox } from 'semantic-ui-react'

import {
  withPoliceReportActions,
  withPoliceReports,
} from '../../../connectors/policeReports'

import Confirmation from '../../../components/Confirmation'
import Loading from '../../../components/Loading'
import ModalTrigger from '../../../components/ModalTrigger'

import PoliceReportGrid from '../../../components/BinManagement/PoliceReportGrid'
import PoliceReportForm from '../../../forms/Bin/PoliceReport'

import Header from './Header'

@withPoliceReportActions
@withPoliceReports
class PoliceReportsLayout extends React.PureComponent {
  state = {
    resolvedPoliceReports: false,
  }

  componentDidMount() {
    const { fetch } = this.props
    fetch()
  }

  handleToggleChange = (_e, { checked }) => {
    const { fetch } = this.props
    const resolvedPoliceReports = checked
    this.setState({ resolvedPoliceReports })

    if (resolvedPoliceReports) {
      fetch('resolved')
    } else {
      fetch('new')
    }
  }

  render() {
    const {
      loadingPoliceReports,
      policeReports,
      fetch,
      updatePoliceReport,
    } = this.props

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header
              children={
                <Checkbox
                  toggle
                  label="Show resolved Police Reports"
                  onChange={this.handleToggleChange}
                />
              }
            />
          </Col>
        </Row>

        <Route
          path="/app/police-reports"
          render={() => [
            loadingPoliceReports && (
              <Loading key="loadingPoliceReports" height={300} />
            ),
            !loadingPoliceReports && (
              <PoliceReportGrid
                policeReports={policeReports}
                key="policeReportGrid"
              />
            ),
          ]}
        />

        <Route
          path="/app/police-reports/:policeReportId?"
          render={({
            match: { params: { policeReportId }, url },
            location: { search },
            history,
          }) => {
            const query = new URLSearchParams(search)

            return (
              <div>
                <ModalTrigger
                  open={query.get('view') ? true : false}
                  title="Viewing Police Report"
                  form={
                    <PoliceReportForm
                      policeReportId={policeReportId}
                      viewMode={true}
                    />
                  }
                  scrolling={true}
                  onClose={() => {
                    history.push(`/app/police-reports`)
                  }}
                />

                <ModalTrigger
                  open={query.get('edit') ? true : false}
                  title="Editing Police Report"
                  form={<PoliceReportForm policeReportId={policeReportId} />}
                  scrolling={true}
                  onClose={() => {
                    history.push(`/app/police-reports`)
                  }}
                />

                <Confirmation
                  open={query.get('status') ? true : false}
                  title="Resolving Police Reports"
                  createLabel="Resolve"
                  onConfirm={() => {
                    history.replace(url)

                    let policeReport = policeReports.find(
                      policeReport => policeReport.id === policeReportId
                    )

                    const policeReportData = {
                      id: policeReport.id,
                      binId: policeReport.binId,
                      date_of_incident: policeReport.date_of_incident,
                      description_of_damages_notes:
                        policeReport.description_of_damages_notes,
                      documents: policeReport.documents,
                      approximate_associated_costs:
                        policeReport.approximate_associated_costs,
                      notes_on_follow_up_with_police:
                        policeReport.notes_on_follow_up_with_police,
                      reason: policeReport.reason,
                      photos: policeReport.photos,
                      report_number: policeReport.report_number,
                      responding_officers_name:
                        policeReport.responding_officers_name,
                      status: 'resolved',
                      userId: policeReport.userId,
                    }

                    updatePoliceReport(policeReportData)

                    fetch()
                  }}
                  onClose={() => {
                    history.replace(url)
                  }}
                  message="Are you sure to resolve this Police Report?"
                />
              </div>
            )
          }}
        />
      </Grid>
    )
  }
}

export default PoliceReportsLayout
