import FormField from '../FormField'
import { has } from 'lodash'
import React from 'react'
import { Button, Grid, Header, Message, Modal } from 'semantic-ui-react'
import { withRoutes } from '../../connectors/routes'
import DateField from '../DatePickerPureHtml'
import { SimpleLoading } from '../Loading'

@withRoutes
export default class RemoveRouteLocationConfirmationModal extends React.Component {
  state = {
    open: true,
    selectedDate: null,
    showLoading: false,
    error: '',
  }

  close = () => {
    const { onClose } = this.props
    this.setState({
      open: false,
    })
    onClose()
  }

  confirm = () => {
    const {
      isCustomRouteForRun,
      removeLocation,
      deleteLocation,
      route,
      location,
      showCalculateRouteLengthModal,
    } = this.props
    const { selectedDate } = this.state

    if (location.routes.length === 1) {
      if (!selectedDate) {
        this.setState({
          error: 'Please pick the date!',
          showLoading: false,
        })

        return false
      }
    }

    this.setState({
      error: '',
      showLoading: true,
    })

    if (!isCustomRouteForRun) {
      if (location.routes.length === 1) {
        deleteLocation(route.id, location.id, selectedDate).then(result => {
          if (has(result.payload, 'mile_calculation_result'))
            if (!result.payload.mile_calculation_result.status)
              showCalculateRouteLengthModal(
                result.payload.mile_calculation_result.message
              )

          this.close()
        })
      } else {
        deleteLocation(route.id, location.id, null).then(result => {
          this.close()
        })
      }
    } else {
      removeLocation(location.id).then(result => {
        this.close()
      })
    }
  }

  onChange = data => {
    if (data) {
      this.setState({
        selectedDate: data.value,
      })
    }
  }

  render() {
    const { location, route, isCustomRouteForRun } = this.props
    const { selectedDate, error, open, showLoading } = this.state

    return (
      <Modal open={open} className="form" dimmer={'blurring'}>
        <Header content="Confirm" />
        <Modal.Content>
          <p>
            Are you sure you want to remove <strong>{location.name}</strong>{' '}
            location from <strong>{route.name}</strong> route?
          </p>
          {location.routes.length === 1 && (
            <Grid>
              <Grid.Column width={4}>
                {!isCustomRouteForRun && (
                  <DateField
                    name="date"
                    label="Remove Date: "
                    component={FormField}
                    onBlur={e => e.preventDefault()}
                    required
                    type="date"
                    onChange={(e, value) => this.onChange(value)}
                    value={selectedDate}
                    error={!!error}
                  />
                )}
              </Grid.Column>
            </Grid>
          )}

          {showLoading && <SimpleLoading loading={true} />}

          {error && (
            <Message negative>
              <p>{error}</p>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={this.close}>
            Nevermind
          </Button>
          <Button positive onClick={this.confirm}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
