import React, { useEffect } from 'react'
import { Dropdown, Grid } from 'semantic-ui-react'

import MarketsDropdown from '../../components/MenuDropdown/Markets'
import { withRouter } from 'react-router-dom'
import { generateArrayOfYears } from '../../utils/date'

const Filters = withRouter(
  ({ match: { url }, location: { search }, history, routes, loading }) => {
    const query = new URLSearchParams(search)

    function updateQuery(name, value) {
      if (value) {
        query.set(name, value)
        history.push(`${url}?${query.toString()}`)
      }
    }

    function resetMarket() {
      query.delete('market')
      query.set('route', 'all-routes')
      history.push(`${url}?${query.toString()}`)
    }

    function onMarketChange(market) {
      query.set('market', market.id)
      query.set('route', 'all-routes')
      history.push(`${url}?${query.toString()}`)
    }

    function dropFilter(name) {
      query.delete(name)
      history.push(`${url}?${query.toString()}`)
    }

    const years = generateArrayOfYears(50)
    const reportTypeOptions = [
      {
        text: 'Total lbs',
        value: 'total',
      },
      {
        text: 'Sellable goods',
        value: 'sellable',
      },
      {
        text: 'Cloth only',
        value: 'cloth',
      },
      {
        text: 'Miscel only',
        value: 'miscel',
      },
      {
        text: 'Trash only',
        value: 'trash',
      },
    ]

    // Component did mount
    useEffect(() => {
      if (!query.has('type')) {
        updateQuery('type', reportTypeOptions[0].value)
      }
      query.set('route', 'all-routes')
    }, [])

    const routeOptions = [{ text: 'All Routes', value: 'all-routes' }]

    if (query.has('market') && routes) {
      routes.forEach(route => {
        routeOptions.push({
          text: route.name,
          value: route.id,
        })
      })
    }

    return (
      <Grid className="Reports__Filters">
        <Grid.Column mobile={16} computer={4}>
          <MarketsDropdown
            activeLabel={false}
            label="All Markets"
            showAll
            selected={query.get('market')}
            onSelect={market => {
              if (!market) {
                resetMarket()
              } else {
                onMarketChange(market)
              }
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={4} key="col1">
          <Dropdown
            fluid
            value={query.get('route') || 'all-routes'}
            selection
            defaultValue="all-routes"
            options={routeOptions}
            placeholder="Select Route"
            loading={loading}
            disabled={loading || routes.length === 0}
            onChange={(e, { value }) => {
              if (value) updateQuery('route', value)
              else dropFilter('route')
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={4} key="col2">
          <Dropdown
            fluid
            selection
            defaultValue={query.get('year')}
            value={query.get('year')}
            options={years.map(year => ({ text: year, value: String(year) }))}
            placeholder="Select Year"
            loading={loading}
            disabled={loading}
            onChange={(e, { value }) => {
              if (value) {
                updateQuery('year', value)
              } else {
                dropFilter('year')
              }
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={4} key="col3">
          <Dropdown
            fluid
            selection
            defaultValue={query.get('type')}
            value={query.get('type')}
            options={reportTypeOptions}
            placeholder="Select report type"
            disabled={loading}
            loading={loading}
            onChange={(e, { value }) => {
              if (value) {
                updateQuery('type', value)
              } else {
                dropFilter('type')
              }
            }}
          />
        </Grid.Column>
      </Grid>
    )
  }
)

export default Filters
