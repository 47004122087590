import _first from 'lodash/first'
import _flow from 'lodash/flow'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _sum from 'lodash/sum'

import moment from 'moment'
import { createSelector } from 'reselect'
import { groupByDay } from '../selectors'
import { _getNumber } from '../utils'
import { Locations } from './locations'

const _range = state => state.markets.range

const dateFormat = 'MM-D-YYYY'
const getNextRunDate = route =>
  _has(route, 'nextRun.run_date')
    ? moment(route.nextRun.run_date).format(dateFormat)
    : 'Unscheduled'

const routeReport = route => {
  const reports = {
    totalStops: 0,
    totalBins: 0,
    sellable_weight: 0,
    total: 0,
    total_cloth: 0,
    total_trash: 0,
    total_misc: 0,
    distribution: 0,
  }

  if (_has(route, 'locations') && _isArray(route.locations)) {
    reports.totalStops = route.locations.length
    reports.totalBins = route.locations.reduce(
      (ac, location) =>
        _isArray(location.bins) ? ac + location.bins.length : ac,
      0
    )
    reports.runDate = getNextRunDate(route)
  }

  if (_has(route, 'reports.totals')) {
    const totals = route.reports.totals

    reports.sellable_weight =
      _getNumber(totals, 'cloth') + _getNumber(totals, 'misc')
    reports.total =
      _getNumber(totals, 'cloth') +
      _getNumber(totals, 'misc') +
      _getNumber(totals, 'trash')
    reports.total_cloth = _getNumber(totals, 'cloth')
    reports.total_trash = _getNumber(totals, 'trash')
    reports.total_misc = _getNumber(totals, 'misc')
    reports.distribution = 0
  }

  return {
    ...route,
    ...reports,
  }
}

// const routeProjection = range => {
//   const isThisMonth = _get(range, 'named') !== 'this.month'

//   return route =>
//     isThisMonth
//       ? {
//           ...route,
//           projection: _getNumber(route, 'sellable_weight'),
//         }
//       : route
// }

const validate2ScheduleRun = route => {
  if (route.locations && _isArray(route.locations) && !!route.locations.length)
    return route.locations.every(
      location => _isArray(location.bins) && !!location.bins.length
    )
  return false
}

const getBinCountForRoutes = routes =>
  _flow(
    routes =>
      _reduce(
        routes,
        (ac, route) => {
          _forEach(route.locations || [], location => {
            _forEach(location.bins || [], bin => {
              ac[bin.id] = true
            })
          })
          return ac
        },
        {}
      ),
    _keys
  )(routes).length

const Route = (route = {}) => ({
  ...route,
  locations: Locations(route).locations,
  schedulable: validate2ScheduleRun(route),
})

export const withReport = createSelector(
  state => state.routes,
  _range,
  (routes, range) => {
    if (!routes.routes) {
      return routes
    }

    const activeRoutesInDateRange = routes.routes.filter(route => {
      const activationHistoryLogsInRange = !route.activation_history
        ? []
        : route.activation_history.filter(log =>
            moment(log.at).isBetween(
              moment(range.start).add(1, 'second'),
              range.end
            )
          )

      const activationHistoryLogsBeforeRange = !route.activation_history
        ? []
        : route.activation_history.filter(log =>
            moment(log.at)
              .startOf('day')
              .isBefore(moment(range.start).add(1, 'second'))
          )

      // Make decision if the route is active
      if (activationHistoryLogsInRange.length) {
        if (
          moment(
            activationHistoryLogsInRange[
              activationHistoryLogsInRange.length - 1
            ].at
          ).isSame(range.start) &&
          activationHistoryLogsInRange[activationHistoryLogsInRange.length - 1]
            .type === 'inactivation'
        ) {
          return false
        }

        return true
      } else if (activationHistoryLogsBeforeRange.length) {
        if (
          activationHistoryLogsBeforeRange[
            activationHistoryLogsBeforeRange.length - 1
          ].type === 'activation'
        )
          return true
        else return false
      } else {
        return false
      }
    })

    const routesWithReport = _map(activeRoutesInDateRange, routeReport)

    return {
      ...routes,
      routes: routesWithReport,
    }
  }
)

export const withAllReport = createSelector(
  state => state.routes,
  routes => {
    if (!routes.routes) {
      return routes
    }

    const routesWithReport = _map(routes.routes, routeReport)

    return {
      ...routes,
      routes: routesWithReport,
    }
  }
)

export const currentRoute = createSelector(
  state => state.routes.routes,
  state => state.routes.current,
  (routes, current) => {
    if (!current || !routes) {
      return null
    }

    return Route(routes.find(route => route.id === current))
  }
)

export const getCurrentReportData = createSelector(
  state => state.routes.data,
  data => {
    if (!data || !data.reports || !data.reports.reportList) {
      return null
    }

    return groupByDay(data.reports.reportList, data.reports.totals)
  }
)

export const prepareRoutes = createSelector(
  state => state.routes,
  routes => ({
    ...routes,
    route: _isObject(_get(routes, 'route')) && Route(routes.route),
    routes: _isArray(routes.routes) && routes.routes.map(Route),
  })
)

export const getReportData = createSelector(withReport, routes => {
  if (!routes.routes) {
    return {}
  }

  const prop2Report = [
    'totalBins',
    'sellable_weight',
    'total',
    'total_cloth',
    'total_misc',
    'total_trash',
    'projection',
  ]

  const binCount = getBinCountForRoutes(routes.routes)

  const result = routes.routes.reduce((ac, route) => {
    prop2Report.forEach(name => {
      if (name !== 'totalBins') {
        ac[name] = _sum([ac[name], route[name]])
      }
    })
    return ac
  }, {})
  result['totalBins'] = binCount
  return result
})

export const queryRoutes = createSelector(
  (state, path = 'routes') => _get(state, path),
  rawRoutes => {
    const routes = _map(rawRoutes, Route)
    const route = _first(routes)

    return {
      routes,
      route,
    }
  }
)

export const showInactiveRoutesStatus = createSelector(
  state => state.routes,
  routes => {
    return routes.showInactiveRoutes
  }
)
