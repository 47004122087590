import React from 'react'
import _pick from 'lodash/pick'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _has from 'lodash/has'
import { Link } from 'react-router-dom'
import { withForm } from '../../connectors/forms'
import { withAuth } from '../../connectors/auth'
import { WithMarkets } from '../../connectors/markets'
import WithMutator from '../../connectors/mutator'

import { Button, Message } from 'semantic-ui-react'
import { LoadingMessage } from '../../components/Loading'
import MarketForm from './form'
import { models } from '../../utils'

@WithMarkets
@withAuth
@withForm('MarketForm')
class AddMarket extends React.PureComponent {
  state = {
    saved: false,
  }

  componentDidMount() {
    const { clearError } = this.props

    // Clear any errors when the form loads
    clearError()
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      mutating,
      editing,
      result,
    } = this.props

    return renderButtons(
      <div>
        {!result && (
          <Button
            negative
            onClick={closeModal}
            disabled={mutating}
            content="Nevermind"
          />
        )}
        {!result && (
          <Button
            positive
            onClick={submitForm}
            disabled={mutating}
            loading={mutating}
            content={editing ? 'Update' : 'Submit'}
          />
        )}
        {result && <Button positive content="Done" onClick={closeModal} />}
      </div>
    )
  }

  submit = args => {
    const {
      auth: { user },
      editing,
      updateMarket,
      createMarket,
      closeModal,
    } = this.props
    const data = _pick(args, ['name', 'alias'])
    const marketId = _get(args, 'id')

    data.userId = _get(user, 'id')
    data.unloading_locations = _map(args.unloading_locations, location =>
      _pick(location, models.location)
    )
    data.staff_members = _map(args.staff_members, member =>
      _pick(member, ['name', 'phone'])
    )

    const prom = editing
      ? updateMarket({ marketId, market: data })
      : createMarket({ market: data })

    return prom.then(market => {
      if (editing) closeModal()
    })
  }

  render() {
    const {
      routes,
      market,
      loading,
      mutating,
      error,
      result,
      editing,
    } = this.props

    this.renderButtons()

    if (!editing && _has(result, 'id'))
      return (
        <Message
          success
          header="Market created successfully"
          content={
            <Link to={`/app/routes/${result.id}`}>
              Click to go to the market.
            </Link>
          }
        />
      )

    if (loading)
      return (
        <LoadingMessage
          title="Market is loading"
          text="We are fetching the market info."
        />
      )
    if (mutating) return <LoadingMessage title="Saving this market" />
    if (error) return <Message error {...error} />

    return (
      <MarketForm
        initialValues={market}
        onSubmit={this.submit}
        formError={routes && routes.error}
      />
    )
  }
}

export default class SwitchFormAction extends React.PureComponent {
  render() {
    const editing = _has(this.props, 'market') || _has(this.props, 'marketId')
    const Form = editing
      ? WithMutator('updateMarket')(AddMarket)
      : WithMutator('createMarket')(AddMarket)

    return <Form {...this.props} editing={editing} />
  }
}
