import { cloneDeep } from 'lodash'
import React from 'react'
import { Dimmer, Label, List, Loader } from 'semantic-ui-react'
import 'url-search-params-polyfill'

import './style.scss'

const columnKeys = [
  'jan',
  'feb',
  'mar',
  'q1',
  'apr',
  'may',
  'jun',
  'q2',
  'jul',
  'aug',
  'sep',
  'q3',
  'oct',
  'nov',
  'dec',
  'q4',
]

const quarterKeys = ['q1', 'q2', 'q3', 'q4']
const quarterMonths = {
  q1: ['jan', 'feb', 'mar'],
  q2: ['apr', 'may', 'jun'],
  q3: ['jul', 'aug', 'sep'],
  q4: ['oct', 'nov', 'dec'],
}

const monthValuesTemplate = {
  jan: 0,
  feb: 0,
  mar: 0,
  q1: 0,
  apr: 0,
  may: 0,
  jun: 0,
  q2: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  q3: 0,
  oct: 0,
  nov: 0,
  dec: 0,
  q4: 0,
}

function formatAsDecimal(num) {
  return Number(num || '').toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })
}

export default class PreviousYearComparisonReport extends React.PureComponent {
  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th />
          {columnKeys.map((month, index) => {
            const quarterNumber = Math.floor(index / 4) + 1

            return (
              <th class={`quarter_${quarterNumber}`}>
                <span>{month.toUpperCase()}</span>
              </th>
            )
          })}
          <th class={`annual_goal`}>
            <span>Total</span>
          </th>
        </tr>
      </thead>
    )
  }

  renderGoalsActualRows() {
    const { year } = this.props
    const currentRoutes = this.filterRoutes(year)
    const lastRoutes = this.filterRoutes(Number(year) - 1)
    const cumGoals = cloneDeep(monthValuesTemplate)
    const cumActuals = cloneDeep(monthValuesTemplate)
    const cumVariances = cloneDeep(monthValuesTemplate)
    const cumGrowths = cloneDeep(monthValuesTemplate)
    const versusBudget = cloneDeep(monthValuesTemplate)
    const currentYearGoals = cloneDeep(monthValuesTemplate)
    const currentYearActuals = cloneDeep(monthValuesTemplate)
    const lastYearActuals = cloneDeep(monthValuesTemplate)
    const increaseVersusLY = cloneDeep(monthValuesTemplate)

    columnKeys.forEach((currCol, index) => {
      lastRoutes.forEach(route => {
        const actuals = {
          ...route.actuals.quarter_1,
          ...route.actuals.quarter_2,
          ...route.actuals.quarter_3,
          ...route.actuals.quarter_4,
          q1: route.actuals.quarter_1.total,
          q2: route.actuals.quarter_2.total,
          q3: route.actuals.quarter_3.total,
          q4: route.actuals.quarter_4.total,
        }

        lastYearActuals[currCol] += actuals[currCol]
      })

      const currMonthGoalTotal = currentRoutes.reduce((prev, curr) => {
        const goals = {
          ...curr.goals,
          q1: curr.goals.jan + curr.goals.feb + curr.goals.mar,
          q2: curr.goals.apr + curr.goals.may + curr.goals.jun,
          q3: curr.goals.jul + curr.goals.aug + curr.goals.sep,
          q4: curr.goals.oct + curr.goals.nov + curr.goals.dec,
        }
        currentYearGoals[currCol] += goals[currCol]

        if (!quarterKeys.includes(currCol)) {
          return prev + curr.goals[currCol]
        } else return prev
      }, 0)

      const currMonthActualTotal = currentRoutes.reduce((prev, curr) => {
        const actuals = {
          ...curr.actuals.quarter_1,
          ...curr.actuals.quarter_2,
          ...curr.actuals.quarter_3,
          ...curr.actuals.quarter_4,
          q1: curr.actuals.quarter_1.total,
          q2: curr.actuals.quarter_2.total,
          q3: curr.actuals.quarter_3.total,
          q4: curr.actuals.quarter_4.total,
        }

        currentYearActuals[currCol] += actuals[currCol]
        versusBudget[currCol] =
          (currentYearActuals[currCol] - currentYearGoals[currCol]) /
          currentYearGoals[currCol]
        increaseVersusLY[currCol] =
          (currentYearActuals[currCol] - lastYearActuals[currCol]) /
          lastYearActuals[currCol]

        if (!quarterKeys.includes(currCol)) {
          return prev + actuals[currCol]
        } else return prev
      }, 0)

      if (!quarterKeys.includes(currCol)) {
        let prevMonth = null

        if (index === 0) prevMonth = null
        else prevMonth = columnKeys[index - 1]

        if (quarterKeys.includes(prevMonth)) prevMonth = columnKeys[index - 2]

        if (prevMonth) {
          cumGoals[currCol] = cumGoals[prevMonth] + currMonthGoalTotal
          cumActuals[currCol] = cumActuals[prevMonth] + currMonthActualTotal
        } else {
          cumGoals[currCol] += currMonthGoalTotal
          cumActuals[currCol] += currMonthActualTotal
        }

        cumVariances[currCol] = cumActuals[currCol] - cumGoals[currCol]
        cumGrowths[currCol] =
          (cumActuals[currCol] - cumGoals[currCol]) / cumGoals[currCol] * 100
      }
    })

    const rows = [
      <tr>
        <td>
          <span>CUM GOAL</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={'label-normal'}>
                {!quarterKeys.includes(month)
                  ? formatAsDecimal(Math.ceil(cumGoals[month]))
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>CUM ACT.</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                color={
                  cumVariances[month] < 0
                    ? 'label-red'
                    : cumVariances[month] > 0 ? 'label-green' : 'label-normal'
                }
              >
                {!quarterKeys.includes(month)
                  ? formatAsDecimal(cumActuals[month])
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>VARIANCE</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                className={
                  cumVariances[month] < 0
                    ? 'label-red'
                    : cumVariances[month] > 0 ? 'label-green' : 'label-normal'
                }
              >
                {!quarterKeys.includes(month)
                  ? formatAsDecimal(Math.abs(cumVariances[month]))
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>GROWTH</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                className={
                  cumGrowths[month] < 0 && isFinite(cumGrowths[month])
                    ? 'label-red'
                    : cumGrowths[month] > 0 && isFinite(cumGrowths[month])
                      ? 'label-green'
                      : 'label-normal'
                }
              >
                {!quarterKeys.includes(month)
                  ? isFinite(cumGrowths[month])
                    ? `${formatAsDecimal(cumGrowths[month])}%`
                    : 'N/A'
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
    ]

    return rows
  }

  renderGoalsRow() {
    const { year } = this.props
    const routes = this.filterRoutes(year)
    const goals = {
      jan: 0,
      feb: 0,
      mar: 0,
      q1: 0,
      apr: 0,
      may: 0,
      jun: 0,
      q2: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      q3: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      q4: 0,
    }
    let total = 0

    routes.forEach(route => {
      const routeGoals = {
        ...route.goals,
        q1: route.goals.jan + route.goals.feb + route.goals.mar,
        q2: route.goals.apr + route.goals.may + route.goals.jun,
        q3: route.goals.jul + route.goals.aug + route.goals.sep,
        q4: route.goals.oct + route.goals.nov + route.goals.dec,
      }

      Object.keys(routeGoals).forEach(key => {
        goals[key] += routeGoals[key] || 0

        if (!quarterKeys.includes(key)) total += routeGoals[key] || 0
      })
    })

    const row = (
      <tr key="market-goals-lbs">
        <td>{year} Goal LBS</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={'label-normal'}>
                {formatAsDecimal(goals[colKey])}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label className={'label-normal'}>{formatAsDecimal(total)}</Label>
        </td>
      </tr>
    )

    return row
  }

  filterRoutes(year) {
    const { marketId, routeId, data } = this.props
    const marketReports = (data && data.previousYearComparison) || []
    const routes = []
    let yearsReports = []

    marketReports.forEach(marketReport => {
      if (marketId && marketReport.id === marketId)
        yearsReports = [...yearsReports, ...marketReport.yearReports]

      if (!marketId)
        yearsReports = [...yearsReports, ...marketReport.yearReports]
    })

    if (routeId === 'all-routes' || !routeId) {
      yearsReports.forEach(report => {
        if (report.year === Number(year))
          report.routes.forEach(route => {
            routes.push(route)
          })
      })
    } else {
      yearsReports.forEach(report => {
        if (report.year === Number(year))
          report.routes.forEach(route => {
            if (routeId === route.id) routes.push(route)
          })
      })
    }

    return routes
  }

  calculateActualForYear(year) {
    const { reportType } = this.props
    const routes = this.filterRoutes(year)
    const actuals = {
      jan: 0,
      feb: 0,
      mar: 0,
      q1: 0,
      apr: 0,
      may: 0,
      jun: 0,
      q2: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      q3: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      q4: 0,
    }
    let total = 0

    routes.forEach(route => {
      Object.keys(actuals).forEach(col => {
        switch (reportType) {
          case 'total':
            if (!quarterKeys.includes(col)) {
              actuals[col] +=
                route.cloth[col] + route.misc[col] + route.trash[col]
              quarterKeys.forEach(quarterKey => {
                if (quarterMonths[quarterKey].includes(col))
                  actuals[quarterKey] +=
                    route.cloth[col] + route.misc[col] + route.trash[col]
              })
            }
            break
          case 'sellable':
            if (!quarterKeys.includes(col)) {
              actuals[col] += route.cloth[col] + route.misc[col]
              quarterKeys.forEach(quarterKey => {
                if (quarterMonths[quarterKey].includes(col))
                  actuals[quarterKey] += route.cloth[col] + route.misc[col]
              })
            }
            break
          case 'cloth':
            if (!quarterKeys.includes(col)) {
              actuals[col] += route.cloth[col]
              quarterKeys.forEach(quarterKey => {
                if (quarterMonths[quarterKey].includes(col))
                  actuals[quarterKey] += route.cloth[col]
              })
            }
            break
          case 'miscel':
            if (!quarterKeys.includes(col)) {
              actuals[col] += route.misc[col]
              quarterKeys.forEach(quarterKey => {
                if (quarterMonths[quarterKey].includes(col))
                  actuals[quarterKey] += route.misc[col]
              })
            }
            break
          case 'trash':
            if (!quarterKeys.includes(col)) {
              actuals[col] += route.trash[col]
              quarterKeys.forEach(quarterKey => {
                if (quarterMonths[quarterKey].includes(col))
                  actuals[quarterKey] += route.trash[col]
              })
            }
            break
          default:
            break
        }
      })
    })

    Object.keys(actuals).forEach(col => {
      if (!quarterKeys.includes(col)) total += actuals[col]
    })

    return {
      actuals,
      total,
      year,
    }
  }

  renderActualsRowForYears(previousYearData, currentYearData) {
    const rows = [
      <tr key={`market-actuals-lbs-${previousYearData.year}`}>
        <td>{previousYearData.year}</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={'label-normal'}>
                {formatAsDecimal(previousYearData.actuals[colKey])}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label className={'label-normal'}>
            {formatAsDecimal(previousYearData.total)}
          </Label>
        </td>
      </tr>,
      <tr key={`market-actuals-lbs-${currentYearData.year}`}>
        <td>{currentYearData.year}</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1
          const yearsVariance =
            currentYearData.actuals[colKey] - previousYearData.actuals[colKey]

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                className={
                  yearsVariance > 0
                    ? 'label-green'
                    : yearsVariance < 0 ? 'label-red' : 'label-normal'
                }
              >
                {formatAsDecimal(currentYearData.actuals[colKey])}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label className={'label-normal'}>
            {formatAsDecimal(currentYearData.total)}
          </Label>
        </td>
      </tr>,
    ]

    return rows
  }

  renderActualsVarianceRow(prevYear, currentYear) {
    const totalVariance = currentYear.total - prevYear.total

    const row = (
      <tr key={`actuals-variance-lbs`}>
        <td>VARIANCE</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1
          const variance =
            currentYear.actuals[colKey] - prevYear.actuals[colKey]

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                className={
                  variance < 0
                    ? 'label-red'
                    : variance > 0 ? 'label-green' : 'label-normal'
                }
              >
                {formatAsDecimal(Math.abs(variance))}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label
            className={
              totalVariance < 0
                ? 'label-red'
                : totalVariance > 0 ? 'label-green' : 'label-normal'
            }
          >
            {formatAsDecimal(Math.abs(totalVariance))}
          </Label>
        </td>
      </tr>
    )

    return row
  }

  renderActualsGrowthRow(prevYear, currentYear) {
    const totalGrowth =
      (currentYear.total - prevYear.total) / prevYear.total * 100

    const row = (
      <tr key={`actuals-growth-lbs`}>
        <td>GROWTH</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1
          const growth =
            (currentYear.actuals[colKey] - prevYear.actuals[colKey]) /
            prevYear.actuals[colKey] *
            100

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                className={
                  growth < 0 && isFinite(growth)
                    ? 'label-red'
                    : growth > 0 && isFinite(growth)
                      ? 'label-green'
                      : 'label-normal'
                }
              >
                {isFinite(growth) ? `${formatAsDecimal(growth)}%` : 'N/A'}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label
            className={
              totalGrowth < 0 && isFinite(totalGrowth)
                ? 'label-red'
                : totalGrowth > 0 && isFinite(totalGrowth)
                  ? 'label-green'
                  : 'label-normal'
            }
          >
            {isFinite(totalGrowth) ? `${formatAsDecimal(totalGrowth)}%` : 'N/A'}
          </Label>
        </td>
      </tr>
    )

    return row
  }

  renderLegends() {
    const legends = {
      YBL:
        'Year Before Last (2 years before the year selected in the year filter above)',
      LY:
        'Last Year (the year before the one selected in the year filter above)',
      CY: 'Current Year (the year selected in the year filter above)',
    }

    return (
      <List className={'legends'}>
        {Object.keys(legends).map(legendKey => (
          <List.Item>
            <span class="legend-title">{legendKey}</span> ={' '}
            <span class="legend-description">{legends[legendKey]}</span>
          </List.Item>
        ))}
      </List>
    )
  }

  render() {
    const { loading, year, data } = this.props

    const prevYearData = this.calculateActualForYear(year - 1)
    const currentYearData = this.calculateActualForYear(year)

    return [
      // <div className="actual-vs-goals-legends-container">
      //   {this.renderLegends()}
      // </div>,
      <div
        style={{
          overflowX: 'auto',
        }}
        className="actual-vs-goals-table-container"
      >
        <table className="ui sortable table actual-vs-goals-table">
          {this.renderTableHeader()}
          <tbody>
            {loading ? (
              <Dimmer active>
                <Loader />
              </Dimmer>
            ) : data ? (
              [
                this.renderActualsRowForYears(prevYearData, currentYearData),
                this.renderActualsVarianceRow(prevYearData, currentYearData),
                this.renderActualsGrowthRow(prevYearData, currentYearData),
                this.renderGoalsActualRows(),
              ]
            ) : (
              <tr>
                <td />
                <td colSpan={17}>No data!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>,
    ]
  }
}
