import React from 'react'
import { withAuth } from '../../connectors/auth'

import ForgotPasswordForm from './form'

@withAuth
export default class ForgotPassword extends React.PureComponent {
  state = {
    success: null,
  }

  componentDidMount() {
    const { clearError } = this.props
    clearError()
  }

  onSubmit = args => {
    const { resetPassword } = this.props

    this.setState({ success: null })
    resetPassword(args).then(payload => {
      if (payload.type === 'AUTH_ERROR') {
        return
      }

      this.setState({
        success: {
          message: 'Check your email with further instructions',
        },
      })
    })
  }

  render() {
    const { auth } = this.props
    const { success } = this.state

    return (
      <ForgotPasswordForm
        onSubmit={this.onSubmit}
        formError={auth.error}
        formSuccess={success}
      />
    )
  }
}
