import React from 'react'
import 'url-search-params-polyfill'
import moment from 'moment'
import { Loader, Dimmer } from 'semantic-ui-react'

import { WithLocationsReport } from '../../connectors/reports'

function getMonthsInterval(start, end) {
  const dateStart = moment(start)
  const dateEnd = moment(end)
  const interim = dateStart.clone()
  const timeValues = []

  while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
    timeValues.push(interim.format('YYYY-MM'))
    interim.add(1, 'month')
  }
  return timeValues
}

@WithLocationsReport
export default class LocationsReportTable extends React.PureComponent {
  renderTableHeader(monthIntervals) {
    return (
      <thead>
        <tr>
          <th>
            <span>Period</span>
          </th>
          <th>
            <span>NPO</span>
          </th>
          <th>
            <span>Cost/lb</span>
          </th>
          <th>
            <span>Route</span>
          </th>
          <th>
            <span>Name</span>
          </th>
          <th>
            <span>Location</span>
          </th>
          {monthIntervals.map(m => (
            <th colspan="2" scope="colgroup" style={{ textAlign: 'center' }}>
              <span>{moment(m).format('MMMM')}</span>
            </th>
          ))}
          <th colspan="2" scope="colgroup" style={{ textAlign: 'center' }}>
            <span>TOTAL</span>
          </th>
        </tr>
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          {monthIntervals.map(() => (
            <React.Fragment>
              <th scope="col">
                <span>LBS</span>
              </th>
              <th scope="col">
                <span>$</span>
              </th>
            </React.Fragment>
          ))}
          <th scope="col">
            <span>LBS</span>
          </th>
          <th scope="col">
            <span>$</span>
          </th>
        </tr>
      </thead>
    )
  }

  renderTableRow(location, monthIntervals, lbsTotal, costTotal, totals) {
    const {
      start = moment()
        .endOf('month')
        .subtract(2, 'months')
        .startOf('month'),
      end = moment().endOf('month'),
    } = this.props

    let total = 0
    let totalCostPerLbs = 0

    let npo_cost_per_lb = Number(location.npo_cost_per_lb || 0)

    let periodColumn = `${moment(start).format('MM/DD/YY')} - ${moment(
      end
    ).format('MM/DD/YY')}`
    if (location.isTotalSummary) {
      periodColumn = 'TOTAL ALL'
    } else if (location.isTotalRow) {
      periodColumn = `${location.route.name.toUpperCase()} TOTAL`
    }

    const row = (
      <tr
        key={`${location.id}-${location.isTotalRow ? 'total' : ''}`}
        style={{ fontWeight: location.isTotalRow ? 'bold' : 'normal' }}
      >
        <td>
          <span>{periodColumn}</span>
        </td>
        <td>
          <span>{location.npo_name}</span>
        </td>
        <td>
          <span>
            ${Number(npo_cost_per_lb).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </span>
        </td>
        <td>
          <span>{location.route.name}</span>
        </td>
        <td>
          <span>{location.name}</span>
        </td>
        <td>
          <span>{location.address}</span>
        </td>
        {monthIntervals.map(m => {
          if (location.collections[m] && location.collections[m].weight) {
            total += location.collections[m].weight
            totalCostPerLbs += location.collections[m].cost
              ? location.collections[m].cost
              : npo_cost_per_lb * location.collections[m].weight
          }

          if (lbsTotal) {
            let weight =
              (location.collections[m] && location.collections[m].weight) || 0
            if (
              lbsTotal[location.route.name] &&
              lbsTotal[location.route.name][m]
            ) {
              lbsTotal[location.route.name][m].weight += weight
              lbsTotal[location.route.name][m].cost += npo_cost_per_lb * weight
            } else {
              lbsTotal[location.route.name] = {
                ...(lbsTotal[location.route.name] || {}),
                [m]: {
                  weight,
                  cost: npo_cost_per_lb * weight,
                },
              }
            }
          }

          return (
            <React.Fragment key={m}>
              <td>
                <span>
                  {Number(
                    location.collections[m] ? location.collections[m].weight : 0
                  ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </span>
              </td>
              <td>
                <span>
                  {Number(
                    location.collections[m] && location.collections[m].weight
                      ? location.collections[m].cost
                        ? location.collections[m].cost
                        : npo_cost_per_lb * location.collections[m].weight
                      : 0
                  ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </span>
              </td>
            </React.Fragment>
          )
        })}
        <td>
          <span>
            {Number(
              location.totals ? location.totals.weight : total
            ).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </span>
        </td>
        <td>
          <span>
            {Number(
              location.totals ? location.totals.cost : totalCostPerLbs
            ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        </td>
      </tr>
    )

    if (costTotal) {
      console.log(npo_cost_per_lb, ' klk')
      if (costTotal[location.route.name]) {
        costTotal[location.route.name] += npo_cost_per_lb
      } else {
        costTotal[location.route.name] = npo_cost_per_lb
      }
    }

    if (totals) {
      totals.cost += totalCostPerLbs
      totals.weight += total
    }

    return row
  }

  render() {
    const {
      locationsReport,
      start = moment()
        .endOf('month')
        .subtract(2, 'months')
        .startOf('month'),
      end = moment().endOf('month'),
      loading,
    } = this.props

    const monthIntervals = getMonthsInterval(start, end)

    let lbsTotal = {}
    let costTotal = {}
    const totals = { weight: 0, cost: 0 }
    let currentRoute = ''

    return (
      <div>
        <table
          className="ui sortable table"
          style={{
            display: 'block',
            'overflow-x': 'auto',
          }}
        >
          {this.renderTableHeader(monthIntervals)}
          <tbody>
            {loading ? (
              <Dimmer active>
                <Loader />
              </Dimmer>
            ) : locationsReport && locationsReport.length ? (
              <React.Fragment>
                {locationsReport.map((location, i) => {
                  const displayTotal =
                    currentRoute && currentRoute !== location.route.name
                  const displayLastRouteTotal = i === locationsReport.length - 1

                  const rowElement = this.renderTableRow(
                    location,
                    monthIntervals,
                    lbsTotal,
                    costTotal,
                    totals
                  )
                  if (displayTotal) {
                    console.log(costTotal, ' costTotal')
                  }
                  const totalRow = {
                    id: currentRoute,
                    isTotalRow: true,
                    npo_cost_per_lb: costTotal[currentRoute],
                    collections: lbsTotal[currentRoute],
                    route: {
                      name: currentRoute,
                    },
                  }
                  const lastRouteTotalRow = {
                    id: location.route.name,
                    isTotalRow: true,
                    npo_cost_per_lb: costTotal[location.route.name],
                    collections: lbsTotal[location.route.name],
                    route: {
                      name: location.route.name,
                    },
                  }
                  if (!currentRoute || currentRoute !== location.route.name) {
                    currentRoute = location.route.name
                  }
                  return (
                    <React.Fragment>
                      {displayTotal
                        ? this.renderTableRow(totalRow, monthIntervals)
                        : null}
                      {rowElement}
                      {displayLastRouteTotal
                        ? this.renderTableRow(lastRouteTotalRow, monthIntervals)
                        : null}
                    </React.Fragment>
                  )
                })}
                {this.renderTableRow(
                  {
                    id: 'total-summary',
                    isTotalRow: true,
                    isTotalSummary: true,
                    route: {},
                    npo_cost_per_lb: Object.keys(costTotal).reduce(
                      (acc, curr) => acc + Number(costTotal[curr] || 0),
                      0
                    ),
                    totals,
                    collections: monthIntervals.reduce((acc, month) => {
                      acc[month] = Object.keys(lbsTotal).reduce(
                        (lbsAcc, route) => {
                          lbsAcc.weight +=
                            (lbsTotal[route] &&
                              lbsTotal[route][month] &&
                              lbsTotal[route][month].weight) ||
                            0
                          lbsAcc.cost +=
                            (lbsTotal[route] &&
                              lbsTotal[route][month] &&
                              lbsTotal[route][month].cost) ||
                            0
                          return lbsAcc
                        },
                        { weight: 0, cost: 0 }
                      )
                      return acc
                    }, {}),
                  },
                  monthIntervals
                )}
              </React.Fragment>
            ) : (
              'No data.'
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
