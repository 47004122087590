import gql from 'graphql-tag'

export const partnershipFields = [
  'id',
  'name',
  'store',
  'store_or_pm',
  'position',
  'telephone',
  'email',
  'address',
]
export const Partnership = `
  id
  name
  store
  store_or_pm
  position
  telephone
  email
  address
`

export const partnerships = gql`
  query {
    partnerships {
      ${Partnership}
    }
  }
`
