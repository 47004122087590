import React from 'react'
import { Query } from 'react-apollo'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/bins'
import {
  searchBins,
  Bin,
  filterBins,
  BinDropdownOptions,
  binsCount,
  binsFilters,
  getBinsFilterRouteOption,
  getUnassignedBinsOptions,
} from '../selectors/bins'
import WithQuery from './query'
import * as QL from '../api/Query/bins'

export const withBinActions = WrappedComponent =>
  connect(null, dispatch => bindActionCreators(actions, dispatch))(
    WrappedComponent
  )

export const withBinFetchAction = WrappedComponent =>
  connect(null, dispatch =>
    bindActionCreators({ fetch: actions.fetch }, dispatch)
  )(WrappedComponent)

export const withBinActivateAction = WrappedComponent =>
  connect(null, dispatch =>
    bindActionCreators({ activate: actions.activate }, dispatch)
  )(WrappedComponent)

export const withBins = WrappedComponent =>
  connect(state => ({
    searchedBins: searchBins(state),
    binsFilters: binsFilters(state),
    bins: state.bins.bins ? state.bins.bins.map(Bin) : [],
    loadingBins: state.bins.loading,
    filteredBins: filterBins(state),
    binsCount: binsCount(state),
    binsFilterRouteOption: getBinsFilterRouteOption(state),
    unassignedBinsOptions: getUnassignedBinsOptions(state),
  }))(WrappedComponent)

export const withBinDropdownOptions = WithQuery({
  selector: BinDropdownOptions,
  QL,
  queryName: 'bins',
  variablesPicker: props => {
    const { auth: { user: { markets } } } = props
    const marketIds = markets.map(market => market.id)

    return {
      deactivated: false,
      markets: marketIds,
      status: 'all',
    }
  },
  fetchPolicy: 'network-only',
})

export const WithBin = Component => {
  class WrappedComponent extends React.Component {
    render() {
      const { binId, ...rest } = this.props

      return (
        <Query query={QL.bin} variables={{ id: binId }}>
          {({ loading, error, data }) => (
            <Component
              error={error}
              loading={loading}
              bin={data.binData}
              {...rest}
            />
          )}
        </Query>
      )
    }
  }

  return WrappedComponent
}
