import React, { Fragment } from 'react'
import { Button, Modal, Form, Message, Icon } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import FormField from '../FormField'
import Select from '../Select'
import {
  WithCompetitorActions,
  WithCompetitors,
} from '../../connectors/competitors'

@reduxForm({
  form: 'addCompetitorToLocation',
})
@WithCompetitors
@WithCompetitorActions
export class AddCompetitorModal extends React.Component {
  state = {
    open: true,
    location_id: null,
    values: {
      competitor: null,
      type: null,
    },
    formStatus: null,
    formMessage: null,
  }

  onOpen = () => {
    this.setState({ open: true })
  }

  close = () => {
    const { onClose, resetCompetitorsStatus } = this.props
    resetCompetitorsStatus()
    onClose()
  }

  competitorsOptions = () => {
    const { reportTags, markets } = this.props

    const competitorTags = reportTags.find(tags => tags.title === 'Competitor')

    if (markets)
      competitorTags.children = competitorTags.children.filter(child => {
        if (!child.markets) return false

        return child.markets.some(marketId => markets.includes(marketId))
      })

    if (competitorTags) {
      const options = competitorTags.children.map(child => ({
        value: child.id,
        text: child.title.trim() || '',
      }))

      return options.sort((a, b) => a.text.localeCompare(b.text))
    } else return []
  }

  competitorTypeOptions = () => {
    const { reportTags } = this.props

    const competitorTypes = reportTags.find(tags => tags.title === 'Sub Detail')

    if (competitorTypes) {
      competitorTypes.children = competitorTypes.children.filter(
        child => child.title !== 'Removed'
      )

      const options = competitorTypes.children.map(child => ({
        value: child.id,
        text: child.title.trim() || '',
      }))

      return options.sort((a, b) => a.text.localeCompare(b.text))
    } else return []
  }

  selectCompetitor = (e, value) => {
    const { values } = this.state

    this.setState({
      values: {
        ...values,
        competitor: value,
      },
    })
  }

  selectType = (e, value) => {
    const { values } = this.state

    this.setState({
      values: {
        ...values,
        type: value,
      },
    })
  }

  submit = () => {
    const { values } = this.state
    const { locationId, addLocationCompetitor, reportTags } = this.props

    if (!values.competitor || !values.type)
      this.setState({
        formStatus: 'FAILED',
        formMessage: 'Please select both Competitor and Type',
      })
    else {
      this.setState({
        formStatus: null,
        formMessage: null,
      })

      const competitorTags = reportTags.find(
        tags => tags.title === 'Competitor'
      )
      const competitorTypes = reportTags.find(
        tags => tags.title === 'Sub Detail'
      )

      const selectedCompetitor = competitorTags.children.find(
        option => option.id === values.competitor
      )
      const selectedType = competitorTypes.children.find(
        option => option.id === values.type
      )
      const competitor = {
        id: selectedCompetitor.id,
        label: selectedCompetitor.title,
      }
      const type = {
        id: selectedType.id,
        label: selectedType.title,
      }

      addLocationCompetitor(locationId, competitor, type)
    }
  }

  render() {
    const { inProgress, message, status } = this.props
    const { open, formMessage, formStatus } = this.state

    return (
      <React.Fragment>
        <Modal
          dimmer="blurring"
          closeOnDimmerClick={false}
          size="small"
          open={open}
          onOpen={this.onOpen}
          onClose={this.close}
        >
          <Modal.Header>Add Competitor</Modal.Header>
          <Modal.Content>
            {status !== 'SUCCESS' && (
              <Fragment>
                <Form className="AddCompetitorToLocation__Form">
                  <Form.Group widths="equal">
                    <Field
                      name="competitor"
                      label="Competitor"
                      component={FormField}
                      required
                      formComponent={Select}
                      options={this.competitorsOptions()}
                      loading={inProgress}
                      onChange={this.selectCompetitor}
                      disabled={inProgress}
                    />

                    <Field
                      name="type"
                      label="Type"
                      component={FormField}
                      required
                      formComponent={Select}
                      options={this.competitorTypeOptions()}
                      loading={inProgress}
                      onChange={this.selectType}
                      disabled={inProgress}
                    />
                  </Form.Group>
                </Form>
              </Fragment>
            )}

            {(message || formMessage) && (
              <Message.Content
                style={{
                  color:
                    status === 'SUCCESS' || formStatus === 'SUCCESS'
                      ? 'green'
                      : status === 'FAILED' || formStatus === 'FAILED'
                        ? 'red'
                        : '',
                }}
              >
                <Icon
                  name={
                    status === 'SUCCESS' || formStatus === 'SUCCESS'
                      ? 'check circle outline'
                      : status === 'FAILED' || formStatus === 'FAILED'
                        ? 'exclamation circle'
                        : ''
                  }
                />
                {message || formMessage}
              </Message.Content>
            )}
          </Modal.Content>
          <Modal.Actions>
            {status === 'SUCCESS' ? (
              <Button positive content="Close" onClick={this.close} />
            ) : (
              <Fragment>
                <Button
                  color="red"
                  content="Nevermind"
                  disabled={inProgress}
                  loading={inProgress}
                  onClick={this.close}
                />
                <Button
                  color="green"
                  content="Confirm"
                  disabled={inProgress}
                  loading={inProgress}
                  onClick={this.submit}
                />
              </Fragment>
            )}
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}
