import { withAuth } from '../../connectors/auth'
import React, { Fragment, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react'
import { generateArrayOfYears, getCurrentYear } from '../../utils/date'
import MarketsDropdown from '../../components/MenuDropdown/Markets'
import { WithGoalsActions } from '../../connectors/goals'
import { withMarkets } from '../../connectors/markets'
import { withRoutes } from '../../connectors/routes'

const Filters = withRouter(
  withRoutes(
    ({
      match: { url },
      location: { search },
      history,
      generateGoalsExcelFile,
      current,
      ...props
    }) => {
      const query = new URLSearchParams(search)
      const { auth: { user: { markets, primary_market } } } = props
      const marketsOptions = markets.map(market => ({
        text: market.name,
        value: market.id,
      }))
      const singleSelectMarketOptions = [
        {
          text: 'Multiple Markets',
          value: 'multiple-markets',
        },
        ...marketsOptions,
      ]
      const years = generateArrayOfYears(50)
      years.unshift(getCurrentYear() + 1)
      const yearOptions = years.map(year => ({
        text: year,
        value: String(year),
      }))

      const [selectedMultipleMarkets, setSelectedMultipleMarkets] = useState({
        selectedMarkets: [],
        selectedMarketIds: [],
      })
      const [selectedMarket, setSelectedMarket] = useState('')
      const [selectedYear, setSelectedYear] = useState('')
      const [reportShouldBeReload, setReportShouldBeReload] = useState(false)
      const [
        exportToExcelInprogress,
        setExportToExcelFileInprogress,
      ] = useState(false)

      function updateQuery(name, value) {
        if (name === 'market') {
          setSelectedMarket(value)

          if (value !== 'multiple-markets')
            setSelectedMultipleMarkets({
              selectedMarketIds: [],
              selectedMarkets: [],
            })
        }

        if (name === 'year') setSelectedYear(value)
      }

      function onChangeMultipleSelectMarket(value) {
        const valueContainsTheAllMarketOption = value.some(
          item => item === 'all'
        )
        let selectedValues = []

        if (valueContainsTheAllMarketOption) {
          if (
            selectedMultipleMarkets.selectedMarkets.length === markets.length
          ) {
            selectedValues = []
          } else {
            selectedValues = markets
          }
        } else {
          selectedValues = value
        }

        const marketIds = selectedValues.map(item => item.id)

        setSelectedMultipleMarkets({
          selectedMarkets: selectedValues,
          selectedMarketIds: marketIds,
        })
      }

      function doLoadReport() {
        if (selectedMarket) query.set('market', selectedMarket)
        else query.delete('market')

        if (selectedYear) query.set('year', selectedYear)
        else query.delete('year')

        if (selectedMultipleMarkets.selectedMarketIds.length)
          query.set(
            'selected-markets',
            selectedMultipleMarkets.selectedMarketIds.join(',')
          )
        else query.delete('selected-markets')

        setReportShouldBeReload(false)
        history.push(`${url}?${query.toString()}`)
      }

      function onExportToExcelClick() {
        setExportToExcelFileInprogress(true)
        let selectedMarkets = []

        if (selectedMarket === 'multiple-markets') {
          selectedMarkets = [...selectedMultipleMarkets.selectedMarketIds]
        } else if (selectedMarket !== 'multiple-markets') {
          selectedMarkets = [selectedMarket]
        }

        generateGoalsExcelFile(
          selectedMarkets,
          selectedYear,
          markets.length === selectedMarkets.length
        )
          .then(data => {
            window.open(data.data.generateGoalsExcelFile.url)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            setExportToExcelFileInprogress(false)
          })
      }

      function renderExportButton() {
        let disabled = true

        if (selectedYear) {
          if (
            selectedMarket === 'multiple-markets' &&
            selectedMultipleMarkets.selectedMarketIds.length > 0
          ) {
            disabled = false
          } else if (selectedMarket !== 'multiple-markets' && selectedMarket) {
            disabled = false
          }
        }

        return (
          <Button
            color="green"
            disabled={disabled || exportToExcelInprogress}
            onClick={onExportToExcelClick}
            loading={exportToExcelInprogress}
          >
            <Icon name="file excel outline" />
            Export to xlsx
          </Button>
        )
      }

      // ComponentDidMount
      useEffect(() => {
        const queryMarketId = query.get('market')
        const selectedMarketId = queryMarketId
          ? queryMarketId
          : current ? current.id : primary_market ? primary_market : null

        setReportShouldBeReload(false)
        setSelectedMarket(selectedMarketId)
        setSelectedYear(query.get('year'))

        const selectedMarketIds = query.get('selected-markets')
          ? query.get('selected-markets').split(',')
          : []
        setSelectedMultipleMarkets({
          selectedMarkets: markets.filter(market =>
            selectedMarketIds.includes(market.id)
          ),
          selectedMarketIds,
        })
      }, [])

      // ComponentDidUpdate by selectedMarket, selectedYear
      useEffect(
        () => {
          doLoadReport()
        },
        [selectedMarket, selectedYear]
      )

      // ComponentDidUpdate by selectedMultipleMarkets
      useEffect(
        () => {
          setReportShouldBeReload(true)
        },
        [selectedMultipleMarkets.selectedMarketIds]
      )

      return (
        <Grid className="Reports__Filters">
          <Grid.Column mobile={16} computer={4}>
            <Dropdown
              fluid
              selection
              options={singleSelectMarketOptions}
              placeholder="Select Market"
              onChange={(e, { value }) => {
                updateQuery('market', value)
              }}
              value={selectedMarket}
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={4} key="col2">
            <Dropdown
              fluid
              selection
              defaultValue={selectedYear}
              value={selectedYear}
              options={yearOptions}
              placeholder="Select Year"
              onChange={(e, { value }) => {
                updateQuery('year', value)
              }}
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={4} />
          <Grid.Column mobile={16} computer={4} textAlign="right">
            {renderExportButton()}
          </Grid.Column>

          {selectedMarket === 'multiple-markets' && (
            <Fragment>
              <Grid.Column mobile={16} computer={13} key="col3">
                <MarketsDropdown
                  activeLabel={false}
                  multiple
                  showAll={true}
                  selected={selectedMultipleMarkets.selectedMarkets}
                  onSelect={value => onChangeMultipleSelectMarket(value)}
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={3} key="col4">
                <Button
                  fluid
                  primary
                  disabled={!reportShouldBeReload}
                  onClick={doLoadReport}
                >
                  Update Report
                </Button>
              </Grid.Column>
            </Fragment>
          )}
        </Grid>
      )
    }
  )
)

export default withMarkets(WithGoalsActions(withAuth(Filters)))
