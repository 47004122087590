import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/users'
import {
  searchUsers,
  User,
  filterByMarkets,
  UserDropdownOptions,
} from '../selectors/users'
import * as QL from '../api/Query/users'
import WithQuery from './query'

export const withUserActions = WrappedComponent =>
  connect(null, dispatch => bindActionCreators(actions, dispatch))(
    WrappedComponent
  )

export const withUsers = WrappedComponent =>
  connect(state => ({
    searchedUsers: searchUsers(state),
    users: state.users.users.map(User),
    loadingUsers: state.users.loading,
    filteredUsers: filterByMarkets(state),
  }))(WrappedComponent)

export const withUserDropdownOptions = WithQuery({
  selector: UserDropdownOptions,
  QL,
  queryName: 'usersQuery',
  variablesPicker: props => {
    return { deactivated: false }
  },
  fetchPolicy: 'network-only',
})
