import React from 'react'
import 'url-search-params-polyfill'
import { Loader, Dimmer } from 'semantic-ui-react'

import { WithLocationsReport } from '../../connectors/reports'

@WithLocationsReport
export default class LocationsReportTable extends React.PureComponent {
  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>
            <span>Company</span>
          </th>
          <th>
            <span>Contact</span>
          </th>
          <th>
            <span>Phone</span>
          </th>
          <th>
            <span>Email</span>
          </th>
          <th>
            <span>Address</span>
          </th>
          <th colspan="3" scope="colgroup" style={{ textAlign: 'center' }}>
            <span>Properties</span>
          </th>
        </tr>
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td />
          <th scope="col">
            <span>Name</span>
          </th>
          <th scope="col">
            <span>Address</span>
          </th>
          <th scope="col">
            <span>Route</span>
          </th>
        </tr>
      </thead>
    )
  }

  renderTableRow(location) {
    const partnership = location.partnership

    const row = (
      <tr key={`${location.id}-${location.isTotalRow ? 'total' : ''}`}>
        <td>
          <span>{partnership.store}</span>
        </td>
        <td>
          <span>{partnership.name}</span>
        </td>
        <td>
          <span>{partnership.telephone}</span>
        </td>
        <td>
          <span>{partnership.email}</span>
        </td>
        <td>
          <span>{partnership.address}</span>
        </td>
        <td>
          <span>{location.name}</span>
        </td>
        <td>
          <span>{location.address}</span>
        </td>
        <td>
          <span>{location.route.name}</span>
        </td>
      </tr>
    )

    return row
  }

  render() {
    const { locationsReport, loading } = this.props

    let lastPropertyManager = ''

    return (
      <div>
        <table
          className="ui sortable table"
          style={{
            display: 'block',
            'overflow-x': 'auto',
          }}
        >
          {this.renderTableHeader()}
          <tbody>
            {loading ? (
              <Dimmer active>
                <Loader />
              </Dimmer>
            ) : locationsReport && locationsReport.length ? (
              <React.Fragment>
                {locationsReport.map((location, i) => {
                  if (lastPropertyManager === location.partnership.name) {
                    location.partnership = {}
                  } else {
                    lastPropertyManager = location.partnership.name
                  }
                  const rowElement = this.renderTableRow(location)
                  return <React.Fragment>{rowElement}</React.Fragment>
                })}
              </React.Fragment>
            ) : (
              'No data.'
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
