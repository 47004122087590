import { withForm } from '../../connectors/forms'
import React, { Fragment } from 'react'

import { LoadingMessage } from '../../components/Loading'
import ModalTrigger from '../../components/ModalTrigger'
import { Button, Grid, Icon } from 'semantic-ui-react'
import AddLocationForm from '../../forms/AddLocation'

import ExistingLocationForm from './form'
import GetReAddedDateModal from './getReAddedDateModal'

export class CreateLocationTrigger extends React.PureComponent {
  render() {
    const { onCreateLocation } = this.props

    return (
      <ModalTrigger
        title={`Creating new Location`}
        scrolling={true}
        trigger={
          <Button icon labelPosition="left">
            <Icon name="add" />
            Create New Location
          </Button>
        }
        form={<AddLocationForm onSubmit={onCreateLocation} />}
      />
    )
  }
}

@withForm('ExistingLocationForm')
export default class ExistingLocation extends React.PureComponent {
  static defaultProps = {
    onSubmit: () => Promise.resolve,
    trigger: <Button>Go</Button>,
    closeModal: () => {},
    renderButtons: () => {},
  }

  state = {
    submitting: false,
    showReAddedDateModal: false,
    reAddedDate: null,
    selectedLocation: null,
  }

  componentDidMount() {
    this.renderButtons()
  }

  openReAddedDateModal() {
    this.setState({
      showReAddedDateModal: true,
    })
  }

  onCloseReAddedDateModal = () => {
    this.closeReAddedDateModal()
  }

  closeReAddedDateModal = () => {
    this.setState({
      showReAddedDateModal: false,
    })
  }

  onReAddedDateConfirmed = value => {
    const { submitForm } = this.props
    this.setState({
      reAddedDate: value,
    })
    submitForm()
  }

  renderButtons() {
    const { renderButtons, closeModal, submitForm } = this.props
    const { submitting } = this.state

    return renderButtons(
      <Grid columns={2}>
        <Grid.Column textAlign="left">
          {!submitting && (
            <CreateLocationTrigger onCreateLocation={this.setLocation} />
          )}
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button negative onClick={closeModal} disabled={submitting}>
            Nevermind
          </Button>
          <Button
            positive
            onClick={submitForm}
            disabled={submitting}
            loading={submitting}
          >
            Submit
          </Button>
        </Grid.Column>
      </Grid>
    )
  }

  submit = ({ location, address }) => {
    const { routeId, onSubmit, closeModal } = this.props
    const { reAddedDate } = this.state
    this.setState({ submitting: true }, this.renderButtons)
    location['date_re_added'] = reAddedDate

    return onSubmit({ routeId, location }).then(res => {
      this.setState({ submitting: false }, closeModal)
      return res
    })
  }

  setLocation = location => {
    const { submitForm, changeFieldValue } = this.props
    this.setState({
      selectedLocation: location,
    })
    changeFieldValue('location', location)
    setImmediate(() => submitForm())
  }

  render() {
    const {
      selector,
      changeFieldValue,
      submitForm,
      simple_locations,
    } = this.props
    const { showReAddedDateModal } = this.state

    const { submitting } = this.state
    const lookupLocation = selector('lookupLocation')

    if (submitting) return <LoadingMessage title="Saving this Route Location" />

    return (
      <Fragment>
        {showReAddedDateModal && (
          <GetReAddedDateModal
            onCloseModal={this.onCloseReAddedDateModal}
            onReAddedDateConfirmed={this.onReAddedDateConfirmed}
          />
        )}
        <ExistingLocationForm
          submitForm={submitForm}
          changeFieldValue={changeFieldValue}
          onSubmit={this.submit}
          lookupLocation={lookupLocation}
          simple_locations={simple_locations}
        />
      </Fragment>
    )
  }
}
