import gql from 'graphql-tag'

export const saveGoals = gql`
  mutation saveGoal(
    $marketId: String!
    $marketGoals: MarketGoalInput!
    $year: String!
  ) {
    saveGoal(marketId: $marketId, marketGoals: $marketGoals, year: $year) {
      id
    }
  }
`

export const generateGoalsExcelFile = gql`
  mutation generateGoalsExcelFile(
    $markets: [String]!
    $year: String!
    $allMarkets: Boolean!
  ) {
    generateGoalsExcelFile(
      markets: $markets
      year: $year
      allMarkets: $allMarkets
    ) {
      name
      url
    }
  }
`

export const generateActualVsGoalsExcelFile = gql`
  mutation generateActualVsGoalsExcelFile(
    $markets: [String]!
    $route: String!
    $year: String!
    $allMarkets: Boolean!
  ) {
    generateActualVsGoalsExcelFile(
      markets: $markets
      route: $route
      year: $year
      allMarkets: $allMarkets
    ) {
      name
      url
    }
  }
`
