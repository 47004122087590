import React from 'react'

import ContentHeader from '../../components/ContentHeader'

class Header extends React.PureComponent {
  render() {
    return <ContentHeader title="REPORT: Drivers" showBackButton />
  }
}

export default Header
