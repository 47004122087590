import React from 'react'

import CheckboxField from '../../components/CheckboxField'
import FormField from '../../components/FormField'
import { withForm } from '../../connectors/forms'
import { Field, FormSection } from 'redux-form'
import { Form } from 'semantic-ui-react'

@withForm('LocationForm')
export default class Partnership extends React.PureComponent {
  parsePermitNumber(value) {
    const regex = /[a-zA-Z0-9-_()+:;/\s]/gm

    if (!value) return ''

    const matchedChars = value.match(regex)

    if (!matchedChars) return ''

    return matchedChars.join('')
  }

  render() {
    const { selector } = this.props
    const permit_required = selector('permit_required')

    return (
      <React.Fragment>
        <Field
          name="permit_required"
          label="Permit required"
          className="field"
          component={FormField}
          formComponent={CheckboxField}
          toggle
        />
        {permit_required && (
          <FormSection name="permit">
            <Form.Group widths="equal">
              <Field
                name="email"
                type="email"
                label="Email"
                placeholder="john@doe.com"
                component={FormField}
                required
                formComponent={Form.Input}
              />
              <Field
                name="phone"
                label="Phone"
                placeholder="Phone Number"
                component={FormField}
                required
                formComponent={Form.Input}
              />
              <Field
                name="address"
                label="Address"
                placeholder="Address"
                component={FormField}
                required
                formComponent={Form.Input}
              />
              <Field
                name="signed_by"
                label="Signed by"
                placeholder="Auth agent, PM, Owner etc."
                component={FormField}
                required
                formComponent={Form.Input}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="city"
                label="City"
                placeholder="City name"
                component={FormField}
                required
                formComponent={Form.Input}
              />
              <Field
                name="permit_number"
                label="Permit Number"
                placeholder="Permit number"
                component={FormField}
                required
                formComponent={Form.Input}
                type="text"
                parse={val => this.parsePermitNumber(val)}
              />
              <Field
                name="issued_date"
                label="Issued"
                placeholder="10/02/2019"
                component={FormField}
                required
                formComponent={Form.Input}
                type="date"
              />
              <Field
                name="expiration_date"
                label="Permit expiration"
                placeholder="10/02/2019"
                component={FormField}
                required
                formComponent={Form.Input}
                type="date"
              />
            </Form.Group>
          </FormSection>
        )}
      </React.Fragment>
    )
  }
}
