import _get from 'lodash/get'
import _toNumber from 'lodash/toNumber'
import _reduce from 'lodash/reduce'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _map from 'lodash/map'

export const _getNumber = (obj, key) =>
  _get(obj, key) ? _toNumber(_get(obj, key)) : 0

export const removeTypename = (prop = '__typename') => obj => {
  return _reduce(
    obj,
    (ac, value, key) => {
      if (key === prop) return ac

      if (value === null || value === undefined) return ac

      if (_isArray(value)) ac[key] = _map(value, removeTypename())
      else if (_isObject(value)) ac[key] = removeTypename()(value)
      else ac[key] = value

      return ac
    },
    {}
  )
}

export const models = {
  location: [
    'id',
    'address',
    'bins',
    'city',
    'latitude',
    'longitude',
    'name',
    'postal',
    'state',
  ],
}
