import React from 'react'
import { Dropdown, Grid } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import { withRoutes } from '../../connectors/routes'

import MarketsDropdown from '../../components/MenuDropdown/Markets'
import DateDropdown from '../../components/DateDropdown'

const Filters = withRouter(
  withRoutes(
    ({
      match: { url },
      location: { search },
      history,
      routes: { loading, routes },
    }) => {
      const query = new URLSearchParams(search)
      const updateQuery = (name, value) => {
        if (value) {
          query.set(name, value)
          history.push(`${url}?${query.toString()}`)
        }
      }

      return (
        <Grid className="Reports__Filters">
          <Grid.Column mobile={16} computer={4}>
            <MarketsDropdown
              activeLabel={false}
              label="Select Market"
              showAll={false}
              selected={query.get('market')}
              onSelect={market => updateQuery('market', market)}
            />
          </Grid.Column>
          {query.get('market') && (
            <Grid.Column mobile={16} computer={4} key="col1">
              <Dropdown
                fluid
                selected={query.get('route')}
                selection
                options={
                  routes && [
                    { text: 'All Routes', value: null },
                    ...routes.map(r => ({
                      text: r.name,
                      value: r.id,
                    })),
                  ]
                }
                placeholder="Select Route"
                loading={loading}
                disabled={loading || !routes.length}
                onChange={(e, { value }) => updateQuery('route', value)}
              />
            </Grid.Column>
          )}

          {query.get('market') && (
            <Grid.Column mobile={16} computer={4} key="col1">
              <Dropdown
                fluid
                selection
                options={[
                  { text: '2022', value: 2022 },
                  { text: '2021', value: 2021 },
                  { text: '2020', value: 2020 },
                  { text: '2019', value: 2019 },
                  { text: '2018', value: 2018 },
                ]}
                placeholder="Select Year"
                loading={loading}
                disabled={loading}
              />
            </Grid.Column>
          )}

          <Grid.Column mobile={16} computer={4} key="col2">
            <DateDropdown
              current={query.get('range')}
              onChange={({ named }) => updateQuery('range', named)}
              disabled={loading}
            />
          </Grid.Column>
        </Grid>
      )
    }
  )
)

export default Filters
