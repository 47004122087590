import firebase from 'firebase/compat/app'

import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/storage'

import { firebase as fbConfig } from 'atrs-config'

firebase.initializeApp(fbConfig)

if (process.env.REACT_APP_ENV !== 'production') {
  console.log('Firebase config: ', { fbConfig })
}

const auth = firebase.auth()

if (process.env.REACT_APP_ENV === 'local') {
  auth.useEmulator(fbConfig.authDomain)
}

const database = firebase.database()
const storage = (firebase.storage || function() {})()

export { auth, database, storage }
