export const binMaterialOptions = [
  {
    key: 0,
    text: 'Wood',
    value: 'wood',
  },
  {
    key: 1,
    text: 'Metal',
    value: 'metal',
  },
]

export const binSizeOptions = {
  wood: [
    {
      key: 0,
      text: '4X4',
      value: '4x4',
    },
    {
      key: 1,
      text: '5X5',
      value: '5x5',
    },
    {
      key: 2,
      text: '6X6',
      value: '6x6',
    },
  ],
  metal: [
    {
      key: 0,
      text: '4X4X6.6',
      value: '4x4x6.1',
    },
    {
      key: 1,
      text: 'Dog Eared',
      value: 'dog_eared',
    },
    {
      key: 2,
      text: 'Double',
      value: 'double',
    },
    {
      key: 3,
      text: 'Go Green Bins',
      value: 'go_green_bins',
    },
    {
      key: 4,
      text: 'Dully Bins',
      value: 'dully_bins',
    },
    {
      key: 5,
      text: 'Container',
      value: 'container',
    },
    {
      key: 6,
      text: 'Other',
      value: 'other',
    },
  ],
}

export const binChuteOptions = [
  {
    key: 0,
    text: 'DPR Design',
    value: 'dpr_design',
  },
  {
    key: 1,
    text: 'Spindle',
    value: 'spindle',
  },
  {
    key: 2,
    text: 'Open',
    value: 'open',
  },
]

export const binManufactureOptions = [
  {
    key: 0,
    text: 'DPR',
    value: 'dpr',
  },
  {
    key: 1,
    text: 'BearSaver - Securr',
    value: 'bearsaver_securr',
  },
  {
    key: 2,
    text: 'Kasper',
    value: 'kasper',
  },
  {
    key: 3,
    text: 'JDY',
    value: 'jdy',
  },
  {
    key: 4,
    text: 'Houston',
    value: 'houston',
  },
]

export const reasonsFrontSideOptions = [
  {
    key: 0,
    text: 'Trim  Needs Paint',
    value: 'trim_needs_paint',
  },
  {
    key: 1,
    text: 'Paneling Needs Paint',
    value: 'paneling_needs_paint',
  },
  {
    key: 2,
    text: 'Trim Missing or Damaged',
    value: 'trim_missing_or_damaged',
  },
  {
    key: 3,
    text: 'Paneling Damaged',
    value: 'paneling_damaged',
  },
  {
    key: 4,
    text: 'Sign Faded or Damaged',
    value: 'sign_faded_or_damaged',
  },
  {
    key: 5,
    text: 'Sign Missing',
    value: 'sign_missing',
  },
  {
    key: 6,
    text: 'Lock Housing Damaged',
    value: 'lock_housing_damaged',
  },
  {
    key: 7,
    text: 'Lock Missing or Damaged',
    value: 'lock_missing_or_damaged',
  },
  {
    key: 8,
    text: 'Chute Damaged / Inoperable',
    value: 'chute_damaged_inoperable',
  },
]

export const reasonsLeftSideOptions = [
  {
    key: 0,
    text: 'Chute Damaged / Inoperable',
    value: 'chute_damaged_inoperable',
  },
  {
    key: 1,
    text: 'Paneling Needs Paint',
    value: 'paneling_needs_paint',
  },
  {
    key: 2,
    text: 'Trim Missing or Damaged',
    value: 'trim_missing_or_damaged',
  },
  {
    key: 3,
    text: 'Paneling Damaged',
    value: 'paneling_damaged',
  },
  {
    key: 4,
    text: 'Sign Faded or Damaged',
    value: 'sign_faded_or_damaged',
  },
  {
    key: 5,
    text: 'Sign Missing',
    value: 'sign_missing',
  },
]

export const reasonsBackSideOptions = [
  {
    key: 0,
    text: 'Chute Damaged / Inoperable',
    value: 'chute_damaged_inoperable',
  },
  {
    key: 1,
    text: 'Paneling Needs Paint',
    value: 'paneling_needs_paint',
  },
  {
    key: 2,
    text: 'Trim Missing or Damaged',
    value: 'trim_missing_or_damaged',
  },
  {
    key: 3,
    text: 'Paneling Damaged',
    value: 'paneling_damaged',
  },
  {
    key: 4,
    text: 'Sign Faded or Damaged',
    value: 'sign_faded_or_damaged',
  },
  {
    key: 5,
    text: 'Sign Missing',
    value: 'sign_missing',
  },
]

export const reasonsRightSideOptions = [
  {
    key: 0,
    text: 'Chute Damaged / Inoperable',
    value: 'chute_damaged_inoperable',
  },
  {
    key: 1,
    text: 'Paneling Needs Paint',
    value: 'paneling_needs_paint',
  },
  {
    key: 2,
    text: 'Trim Missing or Damaged',
    value: 'trim_missing_or_damaged',
  },
  {
    key: 3,
    text: 'Paneling Damaged',
    value: 'paneling_damaged',
  },
  {
    key: 4,
    text: 'Sign Faded or Damaged',
    value: 'sign_faded_or_damaged',
  },
  {
    key: 5,
    text: 'Sign Missing',
    value: 'sign_missing',
  },
]

export const reasonsRoofOptions = [
  {
    key: 0,
    text: 'Missing - Damaged',
    value: 'missing_damaged',
  },
  {
    key: 1,
    text: 'Needs Paint',
    value: 'needs_paint',
  },
]

export const reasonsPoliceReportOptions = [
  {
    key: 0,
    text: 'Bin Damaged',
    value: 'bin_damaged',
  },
  {
    key: 1,
    text: 'Bin Destroyed',
    value: 'bin_destroyed',
  },
  {
    key: 2,
    text: 'Bin Missing',
    value: 'bin_missing',
  },
  {
    key: 3,
    text: 'Theft of product',
    value: 'theft_of_product',
  },
]
