import React from 'react'
import PropTypes from 'prop-types'

import { Link, withRouter } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid'
import { Card, Feed, Statistic, Icon, Popup, Grid } from 'semantic-ui-react'
import ScheduleRun from '../ScheduleRun'
import IconThreeDots from '../IconThreeDots'

import './styles.scss'

const cardStyle = color => ({
  borderTopColor: color,
})

const OptionsPopup = withRouter(({ match: { url }, data }) => (
  <Popup
    inverted
    trigger={
      <IconThreeDots
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      />
    }
    position="bottom center"
    hoverable
  >
    <Grid divided columns="equal">
      <Grid.Column>
        <Link to={`${url}/${data.id}?delete=true`}>
          <Icon
            className="User__Card__Icon__Action"
            name="trash"
            size="large"
          />
        </Link>
      </Grid.Column>
      <Grid.Column>
        <Link to={`${url}/${data.id}?edit=true`}>
          <Icon className="User__Card__Icon__Action" name="edit" size="large" />
        </Link>
      </Grid.Column>
      <Grid.Column>
        <Link to={`${url}/${data.id}?copy=true`}>
          <Icon className="User__Card__Icon__Action" name="copy" size="large" />
        </Link>
      </Grid.Column>
    </Grid>
  </Popup>
))

const RunEvent = ({ date, totalStops, totalBins }) => (
  <Feed>
    <Feed.Event>
      <Feed.Content>
        <Feed.Date>Next Scheduled Run</Feed.Date>
        <Feed.Summary>{date}</Feed.Summary>
        <Feed.Extra>
          <Row className="RunEvent__Row">
            <Col xs={6}>
              <Statistic label="Stops" value={totalStops} size="tiny" />
            </Col>
            <Col xs={6}>
              <Statistic label="Bins" value={totalBins} size="tiny" />
            </Col>
          </Row>
        </Feed.Extra>
      </Feed.Content>
    </Feed.Event>
  </Feed>
)

const RouteCard = ({ data, onBottomClick, ...props }) => (
  <Card
    {...props}
    style={cardStyle(data.color)}
    className="RouteCard__container"
  >
    <Card.Content>
      <OptionsPopup data={data} />
      <Card.Header>{data.name}</Card.Header>
    </Card.Content>
    <Card.Content>
      <RunEvent
        date={data.runDate}
        totalStops={data.totalStops}
        totalBins={data.totalBins}
      />
    </Card.Content>
    <ScheduleRun market={data.market.id} route={data.id} />
  </Card>
)
RouteCard.defaultProps = {
  data: {
    name: 'value',
    runDate: '10-11-2017',
    totalStops: 3,
    totalBins: 4,
    color: '#C0C0C0C0',
  },
  onBottomClick: () => {},
}
RouteCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    runDate: PropTypes.string,
    totalStops: PropTypes.number,
    totalBins: PropTypes.number,
    color: PropTypes.string,
  }),
  onBottomClick: PropTypes.func,
}
export default RouteCard
