import _get from 'lodash/get'
import _map from 'lodash/map'
import React from 'react'

import { Field } from 'redux-form'
import { Form, Grid, Header } from 'semantic-ui-react'
import validator from 'validator'

import FormField from '../../components/FormField'
import ReportTagsInput from '../../components/ReportTagsInput'

const validates = {
  numeric(val) {
    return validator.isFloat(String(val)) || !val ? undefined : 'invalid/format'
  },
  required(val) {
    return val !== null && val !== undefined ? undefined : 'invalid/required'
  },
}

const BinReporter = ({ name, id, box_code, location, market }) => (
  <Grid.Column className="RouteLocationsTable__BinContainer">
    {/* <Field
      label={`${box_code || id}`}
      name={`${name}.report.weight`}
      placeholder={box_code || `Bin ${id + 1}`}
      type="number"
      component={FormField}
      formComponent={Form.Input}
      validate={[validates.numeric]}
      parse={val => Number(val)}
      className="row"
    /> */}
    <Field
      label={`${box_code || id}`}
      name={`${name}.report.tagInfo`}
      component={FormField}
      formComponent={ReportTagsInput}
      className="row"
      market={market}
    />
  </Grid.Column>
)

export default class RunLocationInput extends React.PureComponent {
  state = {
    commenting: false,
    hasComment: false,
  }

  toggleComments = () => {
    this.setState({ commenting: !this.state.commenting })
  }

  sniffValue = (e, value) => {
    this.setState({ hasComment: value && !validator.isEmpty(String(value)) })
  }

  render() {
    const { location, color: background, name, market, className } = this.props

    const loc = _get(location, 'location', location)
    const stop_sequence = _get(loc, 'stop_sequence')

    return (
      <Grid.Row style={{ order: stop_sequence }}>
        <Grid.Column className="RouteLocationsTable__LocationContainer">
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <div className="RouteLocationsTable__LocationTitle">
                  <div className="RouteLocationsTable__sequence">
                    <span style={{ background }}>{stop_sequence}</span>
                  </div>
                  <Header
                    content={loc.name}
                    subheader={`${loc.address}, ${loc.city}`}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <Header as="h4">Weight</Header>
                <Field
                  label="&nbsp;"
                  name={`${name}.weight`}
                  placeholder="Weight"
                  type="number"
                  component={FormField}
                  formComponent={Form.Input}
                  validate={[validates.numeric]}
                  parse={val => Number(val)}
                  className="row"
                />
              </Grid.Column>
              <Grid.Column className={`${className}`}>
                <Header as="h4">Tags</Header>
                {_map(location.bins, (bin, idx) => (
                  <BinReporter
                    {...bin}
                    location={location}
                    name={`${name}.bins[${idx}]`}
                    market={market}
                  />
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    )
  }
}
