import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/auth'

export const withAuth = WrappedComponent =>
  connect(
    state => ({
      auth: state.auth,
    }),
    dispatch => bindActionCreators(actions, dispatch)
  )(WrappedComponent)
