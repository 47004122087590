import ScheduleRun from '../../components/ScheduleRun'
import Select from '../../components/Select'
import { withAuth } from '../../connectors/auth'
import { withMarkets } from '../../connectors/markets'
import { withRoutes } from '../../connectors/routes'
import moment from 'moment'
import React from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { withRouter } from 'react-router-dom'
import { Icon, Label, List } from 'semantic-ui-react'
import 'url-search-params-polyfill'
import ScheduleCalendar from '../../components/ScheduleCalendar'

import './styles.scss'

@withRouter
@withAuth
@withRoutes
@withMarkets
export default class Route extends React.PureComponent {
  state = {
    filteredRoutes: [],
    date: undefined,
    routes: null,
    reload: Date.now(),
  }

  componentWillReceiveProps(nextProps) {
    const { setCurrent, getMarketDrivers, location: { search } } = nextProps
    const nextQuery = new URLSearchParams(search)
    const query = new URLSearchParams(
      this.props.location && this.props.location.search
    )

    if (nextQuery) {
      if (
        !!nextQuery.get('market') &&
        nextQuery.get('market') !== query.get('market')
      ) {
        setCurrent(nextQuery.get('market'))
        getMarketDrivers(nextQuery.get('market'))
        this.retrieveRoutes(nextQuery.get('market'), nextProps)
      }
      if (nextQuery.get('driver') !== query.get('driver')) {
        //Set the current user/driver
      }
      if (nextQuery.get('route') !== query.get('route')) {
        this.toggleRouteFilter(nextQuery.get('route'))()
      }
    }
  }

  componentDidMount() {
    const {
      getRoutesByMarketSimple,
      markets: { drivers },
      current,
      getMarketDrivers,
      location: { search },
      auth: { user: { markets, primary_market } },
    } = this.props
    const query = new URLSearchParams(search)

    if (query.get('market')) {
      getRoutesByMarketSimple(query.get('market')).then(routes =>
        this.setState({ routes })
      )
    } else if (!!current) {
      this.onSelectMarket(current.id)
      return
    } else if (!!primary_market) {
      this.onSelectMarket(primary_market)
    } else if (markets.length) {
      // Select the first market in the dropdown so the page doesn't look broken
      this.onSelectMarket(markets[0].id)
      return
    }

    if (query.get('route')) this.toggleRouteFilter(query.get('route'))()

    if (!drivers.length) {
      getMarketDrivers(query.get('market'))
    }
    if (query.get('date')) {
      this.setState({ date: moment(query.get('date')) })
    }
  }

  retrieveRoutes = (marketId, props = this.props) => {
    const { getRoutesByMarketSimple } = props

    getRoutesByMarketSimple(marketId, { cached: false }).then(routes =>
      this.setState({ routes })
    )
  }

  onSelectMarket = market => {
    const { match: { url }, location: { search }, history } = this.props
    const query = new URLSearchParams(search)

    if (market) {
      query.set('market', market)
      query.delete('driver')
      this.setState({ filteredRoutes: [] })
      return history.push(`${url}?${query.toString()}`)
    }
  }

  onSelectDriver = driver => {
    const { match: { url }, location: { search }, history } = this.props
    const query = new URLSearchParams(search)

    if (driver) {
      query.set('driver', driver)
    } else {
      query.delete('driver', driver)
    }
    return history.push(`${url}?${query.toString()}`)
  }

  toggleRouteFilter = routeId => () => {
    const { filteredRoutes } = this.state

    if (routeId === false) {
      return this.setState({ filteredRoutes: [] })
    }

    const found = filteredRoutes.indexOf(routeId)
    const routes = [...filteredRoutes]
    if (found >= 0) {
      routes.splice(found, 1)
    }

    this.setState({
      filteredRoutes: found >= 0 ? routes : [...filteredRoutes, routeId],
    })
  }

  onNavigate = date => {
    this.setState({ date })
  }

  onNewScheduledRun = () => {
    this.setState({
      reload: Date.now(),
    })
  }

  render() {
    const {
      markets: { loading, drivers },
      location: { search },
      auth: { user: { markets } },
    } = this.props
    const { filteredRoutes, date, routes } = this.state

    const query = new URLSearchParams(search)
    const driver = query.get('driver')
    const market = query.get('market')

    return (
      <Grid className="Schedule__View">
        <Row>
          <Col xs={12} sm={4} md={3} lg={2}>
            <ScheduleRun
              button={{
                fluid: true,
                className: 'Schedule_Run__Button',
              }}
              onSchedule={this.onNewScheduledRun}
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={3}>
            <Select
              label=" "
              options={markets.map(({ id, name }) => ({
                key: id,
                text: name,
                value: id,
              }))}
              onChange={this.onSelectMarket}
              value={market}
              loading={!markets.length || loading}
              fluid
            />
          </Col>
          <Col xs={12} sm={5} md={4} lg={3}>
            <Select
              label=" "
              placeholder="All Drivers"
              options={[
                { key: 0, text: 'All Drivers', value: null },
                ...drivers.map(({ id, name }) => ({
                  key: id,
                  text: name,
                  value: id,
                })),
              ]}
              onChange={this.onSelectDriver}
              value={driver}
              loading={!drivers.length}
              fluid
            />
          </Col>
        </Row>
        {routes && (
          <Row>
            <Col xs={12}>
              <List horizontal className="Schedule__Routes">
                {routes &&
                  routes.map(({ id, name, color }) => (
                    <List.Item
                      key={id}
                      onClick={this.toggleRouteFilter(id)}
                      className="Schedule__Route"
                      style={{
                        opacity:
                          !filteredRoutes.length || filteredRoutes.includes(id)
                            ? '1'
                            : '0.5',
                      }}
                    >
                      <List.Icon
                        name={filteredRoutes.includes(id) ? 'check' : 'circle'}
                        size="small"
                        style={{ color }}
                      />
                      <List.Content>{name}</List.Content>
                    </List.Item>
                  ))}
              </List>
            </Col>
            <Col xs={12}>
              <div className="Schedule__RoutesClear__PlaceHolder">
                {!!filteredRoutes.length && (
                  <Label
                    icon="times rectangle"
                    content="Clear Filtered Routes"
                    className="Schedule__RoutesClear"
                    color="red"
                    onClick={this.toggleRouteFilter(false)}
                  >
                    <Icon name="times rectangle" />
                    Clear Filtered Routes
                  </Label>
                )}
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs>
            {routes && (
              <ScheduleCalendar
                reload={this.state.reload}
                routeData={routes}
                filter={{ driver, routes: filteredRoutes }}
                big
                date={date}
                onNavigate={this.onNavigate}
                popup={false}
              />
            )}
          </Col>
        </Row>
      </Grid>
    )
  }
}
