import PropTypes from 'prop-types'
import React from 'react'

import NumericTooltip from '../NumericTooltip'
import { Card, Statistic } from 'semantic-ui-react'
import './styles.scss'

const ReportCard = ({
  title,
  total,
  label,
  color,
  loading,
  tooltip,
  subtitle,
  ...props
}) => (
  <Card {...props}>
    <Card.Content header={title} />
    <Card.Content>
      <NumericTooltip value={tooltip}>
        <Statistic horizontal className="ReportCard__Statistic" color={color}>
          <Statistic.Value>{loading ? '...' : total}</Statistic.Value>
          {subtitle && <Statistic.Label>{subtitle}</Statistic.Label>}
        </Statistic>
      </NumericTooltip>
    </Card.Content>
  </Card>
)
ReportCard.defaultProps = {
  title: 'value',
  total: '524,187',
  color: '#C0C0C0C0',
}
ReportCard.propTypes = {
  title: PropTypes.string,
  total: PropTypes.any,
  label: PropTypes.string,
  color: PropTypes.string,
}
export default ReportCard
