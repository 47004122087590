import get from 'lodash/get'
import has from 'lodash/has'
import React, { Fragment } from 'react'
import { Dimmer, Loader, Message } from 'semantic-ui-react'
import 'url-search-params-polyfill'

import { cloneDeep } from 'lodash'
import './style.scss'

function formatAsDecimal(num, maximumFractionDigits = 2) {
  return Number(num || '').toLocaleString(undefined, {
    maximumFractionDigits,
  })
}

export default class QuarterlyRouteCostReport extends React.PureComponent {
  columns = [
    'bins',
    'runs',
    'total_lbs_collected',
    'average_lbs_collected',
    'truck_fixed_monthly_cost',
    'truck_average_cost_per_run',
    'truck_cost_cpp',
    'total_mileage',
    'average_mileage',
    'average_mileage_cost_cpp',
    'price_per_gallon',
    'average_mpg',
    'average_fuel_cost',
    'average_fuel_cost_cpp',
    'average_salary',
    'salary_cost_cpp',
    'average_cost_per_run',
    'average_cost_per_bin',
    'route_cost_cpp',
  ]

  currencyColumns = [
    'truck_fixed_monthly_cost',
    'truck_average_cost_per_run',
    'truck_cost_cpp',
    'average_mileage_cost_cpp',
    'price_per_gallon',
    'average_fuel_cost',
    'average_fuel_cost_cpp',
    'salary_cost_cpp',
    'average_cost_per_run',
    'average_cost_per_bin',
    'route_cost_cpp',
  ]

  columnRules = {
    name: {},
    bins: {
      round: true,
    },
    runs: {
      round: true,
    },
    total_lbs_collected: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_lbs_collected: {
      round: true,
    },
    truck_fixed_monthly_cost: {
      formatAsDecimal: true,
      decimals: 2,
    },
    truck_average_cost_per_run: {
      formatAsDecimal: true,
      decimals: 2,
    },
    truck_cost_cpp: {
      formatAsDecimal: true,
      decimals: 2,
    },
    total_mileage: {
      round: true,
    },
    average_mileage: {
      round: true,
    },
    average_mileage_cost_cpp: {
      formatAsDecimal: true,
      decimals: 5,
    },
    price_per_gallon: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_mpg: {
      formatAsDecimal: true,
      decimals: 3,
    },
    average_fuel_cost: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_fuel_cost_cpp: {
      formatAsDecimal: true,
      decimals: 3,
    },
    average_salary: {
      formatAsDecimal: true,
      decimals: 3,
    },
    salary_cost_cpp: {
      formatAsDecimal: true,
      decimals: 3,
    },
    average_cost_per_bin: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_cost_per_run: {
      formatAsDecimal: true,
      decimals: 2,
    },
    route_cost_cpp: {
      formatAsDecimal: true,
      decimals: 3,
    },
  }

  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>Month</th>
          <th>Bin Count</th>
          <th>No. of Runs</th>
          <th>Total LBS Collected</th>
          <th>Average LBS Collected</th>
          <th>Truck Fixed Monthly Cost</th>
          <th>Truck Average Cost Per Run</th>
          <th>Truck Cost (CPP)</th>
          <th>Total Mileage</th>
          <th>Average Mileage</th>
          <th>Average Mileage Cost (CPP)</th>
          <th>Price per Gallon</th>
          <th>Average MPG</th>
          <th>Average Fuel Cost</th>
          <th>Average Fuel Cost (CPP)</th>
          <th>Average Salary</th>
          <th>Salary Cost (CPP)</th>
          <th>Average Cost per Run</th>
          <th>Average Cost per Bin</th>
          <th>Route Cost (CPP)</th>
        </tr>
      </thead>
    )
  }

  renderMonthReport(currentYearMonthReport, previousYearMonthReport) {
    const { year, include_previous_year } = this.props
    const reportYear = Number(year)
    const rows = []

    rows.push(
      <tr className="current-year">
        <td rowSpan={include_previous_year && previousYearMonthReport ? 2 : 1}>
          {currentYearMonthReport['month'].toUpperCase()}
          {include_previous_year &&
            previousYearMonthReport && (
              <Fragment>
                <span class="current-year-number">{reportYear}</span>
                <span class="previous-year-number">{reportYear - 1}</span>
              </Fragment>
            )}
        </td>
        {this.renderMonthReportCells(currentYearMonthReport.report)}
      </tr>
    )

    if (include_previous_year && previousYearMonthReport)
      rows.push(
        <tr>{this.renderMonthReportCells(previousYearMonthReport.report)}</tr>
      )

    return rows
  }

  renderMonthReportCells(report) {
    return (
      <Fragment>
        {this.columns.map(column => {
          let value = 'N/A'

          if (isFinite(report[column])) {
            value = report[column]

            if (this.columnRules[column].round) value = Math.round(value)

            if (this.columnRules[column].formatAsDecimal)
              value = formatAsDecimal(value, this.columnRules[column].decimals)
          }

          if (value !== 'N/A' && this.currencyColumns.includes(column))
            value = `$ ${value}`

          return <td>{value}</td>
        })}
      </Fragment>
    )
  }

  renderMarketReport(currentYearMarketReport, previousYearMarketReport) {
    return (
      <div
        style={{
          overflowX: 'auto',
        }}
        className="route-cost-report-table-container"
      >
        <table className="ui sortable table">
          {this.renderTableHeader()}
          <tbody>
            {currentYearMarketReport.months.map(currentYearMonthReport => {
              let previousYearMonthReport = null

              if (previousYearMarketReport)
                previousYearMonthReport = previousYearMarketReport.months.find(
                  month => month.month === currentYearMonthReport.month
                )

              return [
                this.renderMonthReport(
                  currentYearMonthReport,
                  previousYearMonthReport
                ),
              ]
            })}
            {this.renderAverageRow(
              currentYearMarketReport,
              previousYearMarketReport
            )}
          </tbody>
        </table>
      </div>
    )
  }

  renderAverageRow(currentYearMarketReport, previousYearMarketReport) {
    const { year, include_previous_year } = this.props
    const reportYear = Number(year)
    const rows = []

    const currentYearAverage = {}
    this.columns.forEach(col => {
      const sum = currentYearMarketReport.months.reduce((prev, curr) => {
        if (isNaN(curr.report[col])) return prev

        return prev + curr.report[col]
      }, 0)
      currentYearAverage[col] = sum / currentYearMarketReport.months.length
    })

    rows.push(
      <tr className="current-year">
        <td rowSpan={include_previous_year && previousYearMarketReport ? 2 : 1}>
          Quarterly Average
          {include_previous_year &&
            previousYearMarketReport && (
              <Fragment>
                <span class="current-year-number">{reportYear}</span>
                <span class="previous-year-number">{reportYear - 1}</span>
              </Fragment>
            )}
        </td>
        {this.renderMonthReportCells(currentYearAverage)}
      </tr>
    )

    if (include_previous_year && previousYearMarketReport) {
      const previousYearAverage = {}
      this.columns.forEach(col => {
        const sum = previousYearMarketReport.months.reduce((prev, curr) => {
          if (isNaN(curr.report[col])) return prev

          return prev + curr.report[col]
        }, 0)
        previousYearAverage[col] = sum / previousYearMarketReport.months.length
      })

      rows.push(<tr>{this.renderMonthReportCells(previousYearAverage)}</tr>)
    }

    return rows
  }

  renderReport() {
    const { year, quarterlyRouteCostData, routeId } = this.props
    const yearReport = year ? Number(year) : null
    let table = null
    let renderReport = false

    let currentYearData =
      quarterlyRouteCostData.length > 0
        ? quarterlyRouteCostData.find(
            report => Number(report.year) === yearReport
          )
        : null
    let previousYearData =
      quarterlyRouteCostData.length > 0
        ? quarterlyRouteCostData.find(
            report => Number(report.year) === yearReport - 1
          )
        : null

    if (currentYearData) {
      renderReport = true

      currentYearData = {
        year: yearReport,
        months: currentYearData.months.map(monthReport => ({
          month: monthReport.month,
          report: calculateMarketReport(cloneDeep(monthReport.report), routeId),
        })),
      }
    }

    if (previousYearData)
      previousYearData = {
        year: yearReport - 1,
        months: previousYearData.months.map(monthReport => ({
          month: monthReport.month,
          report: calculateMarketReport(monthReport.report, routeId),
        })),
      }

    if (renderReport)
      table = this.renderMarketReport(currentYearData, previousYearData)
    else table = this.renderNoDataMessage()

    return table
  }

  renderNoDataMessage() {
    return (
      <Message black>
        <Message.Header>There is no data to display!</Message.Header>
        <p>Please pick a date to load report</p>
      </Message>
    )
  }

  render() {
    const { loading, quarterlyRouteCostData } = this.props

    return [
      <div>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {quarterlyRouteCostData ? (
          <Fragment>{this.renderReport()}</Fragment>
        ) : (
          <Fragment>{this.renderNoDataMessage()}</Fragment>
        )}
      </div>,
    ]
  }
}

function calculateMarketReport(report, routeId) {
  const total_truck_lease_payment = has(report, 'total_truck_lease_payment')
    ? report.total_truck_lease_payment
    : 0
  const mileage_charge = has(report, 'mileage_charge')
    ? report.mileage_charge
    : 0
  const price_per_gallon = has(report, 'price_per_gallon')
    ? report.price_per_gallon
    : 0
  const average_mpg = has(report, 'average_mpg') ? report.average_mpg : 0
  const truck_cost_per_run = has(report, 'truck_cost_per_run')
    ? !isFinite(report.truck_cost_per_run) || isNaN(report.truck_cost_per_run)
      ? 0
      : Number(report.truck_cost_per_run)
    : 0

  report.average_mpg = isFinite(average_mpg) ? average_mpg : report.average_mpg
  report.mileage_charge = isFinite(mileage_charge)
    ? mileage_charge
    : report.mileage_charge
  report.price_per_gallon = isFinite(price_per_gallon)
    ? price_per_gallon
    : report.price_per_gallon
  report.total_truck_lease_payment = isFinite(total_truck_lease_payment)
    ? total_truck_lease_payment
    : report.total_truck_lease_payment
  report.truck_cost_per_run = isFinite(truck_cost_per_run)
    ? truck_cost_per_run
    : report.truck_cost_per_run
  report.truck_cost_per_run = truck_cost_per_run
  report['total'] = {
    name: 'Totals',
    bins: 0,
    runs: 0,
    total_lbs_collected: 0,
    truck_fixed_monthly_cost: 0,
    total_mileage: 0,
    salary: 0,
    average_mileage: 0,
    average_lbs_collected: 0,
    truck_cost_cpp: 0,
    average_mileage_cost: 0,
    average_mileage_cost_cpp: 0,
    average_fuel_cost: 0,
    average_fuel_cost_cpp: 0,
    salary_cost_cpp: 0,
    average_cost_per_run: 0,
    average_cost_per_bin: 0,
    route_cost_cpp: 0,
    totalAverageMileage: 0,
    totalAverageFuelCost: 0,
    totalSalary: 0,
    totalAverageLbsCollected: 0,
    totalAverageMileageCost: 0,
    totalAverageCostPerBin: 0,
    numberOfAverageCostPerBin: 0,
    numberOfSalary: 0,
  }

  report.routes.forEach(routeReport => {
    // Route report
    routeReport.truck_fixed_monthly_cost = truck_cost_per_run * routeReport.runs
    routeReport.truck_cost_cpp =
      truck_cost_per_run / routeReport.average_lbs_collected
    routeReport.average_mileage_cost =
      routeReport.average_mileage * mileage_charge
    routeReport.average_mileage_cost_cpp =
      routeReport.average_mileage_cost / routeReport.average_lbs_collected
    routeReport.average_fuel_cost =
      routeReport.average_mileage / average_mpg * price_per_gallon
    routeReport.average_fuel_cost_cpp =
      routeReport.average_fuel_cost / routeReport.average_lbs_collected
    // routeReport.salary = has(formValues, `salary.${routeReport.id}`)
    //   ? formValues.salary[routeReport.id]
    //   : routeReport.salary
    routeReport.salary_cost_cpp =
      routeReport.salary / routeReport.average_lbs_collected
    routeReport.average_cost_per_run =
      Number(truck_cost_per_run) +
      routeReport.average_mileage_cost +
      routeReport.average_fuel_cost +
      routeReport.salary
    routeReport.average_cost_per_bin =
      routeReport.average_cost_per_run / routeReport.bins
    routeReport.route_cost_cpp =
      routeReport.average_cost_per_run / routeReport.average_lbs_collected

    // Total report
    let considerThisRouteInTotal = false

    if (routeId.length === 0 || routeId.includes(routeReport.id)) {
      considerThisRouteInTotal = true
    }

    if (considerThisRouteInTotal) {
      report.total.bins += get(routeReport, 'bins', 0)
      report.total.runs += get(routeReport, 'runs', 0)
      report.total.total_lbs_collected += get(
        routeReport,
        'total_lbs_collected',
        0
      )
      report.total.truck_fixed_monthly_cost += Math.round(
        get(routeReport, 'truck_fixed_monthly_cost', 0)
      )
      report.total.total_mileage += get(routeReport, 'total_mileage', 0)

      report.total.totalAverageMileage += get(routeReport, 'average_mileage', 0)
      report.total.totalAverageFuelCost += get(
        routeReport,
        'average_fuel_cost',
        0
      )
      report.total.totalSalary += isFinite(get(routeReport, 'salary', 0))
        ? Number(get(routeReport, 'salary', 0))
        : 0
      report.total.numberOfSalary +=
        isFinite(get(routeReport, 'salary', 0)) &&
        get(routeReport, 'salary', 0) > 0
          ? 1
          : 0
      report.total.totalAverageLbsCollected += get(
        routeReport,
        'average_lbs_collected',
        0
      )
      report.total.totalAverageMileageCost += get(
        routeReport,
        'average_mileage_cost',
        0
      )
      report.total.totalAverageCostPerBin += isFinite(
        get(routeReport, 'average_cost_per_bin', 0)
      )
        ? get(routeReport, 'average_cost_per_bin', 0)
        : 0
      report.total.numberOfAverageCostPerBin +=
        isFinite(get(routeReport, 'average_cost_per_bin', 0)) &&
        get(routeReport, 'average_cost_per_bin', 0) > 0
          ? 1
          : 0

      report.total.average_mileage = Math.round(
        report.total.total_mileage / report.total.runs
      )
      report.total.average_lbs_collected = Math.round(
        report.total.total_lbs_collected / report.total.runs
      )
      report.total.truck_cost_cpp =
        truck_cost_per_run / report.total.average_lbs_collected
      report.total.average_mileage_cost =
        report.total.average_mileage * mileage_charge
      report.total.average_mileage_cost_cpp =
        report.total.average_mileage_cost / report.total.average_lbs_collected
      report.total.average_fuel_cost =
        report.total.average_mileage / average_mpg * price_per_gallon
      report.total.average_fuel_cost_cpp =
        report.total.totalAverageFuelCost /
        report.total.totalAverageLbsCollected
      report.total.salary =
        report.total.totalSalary / report.total.numberOfSalary
      report.total.salary_cost_cpp =
        report.total.salary / report.total.average_lbs_collected
      report.total.average_cost_per_run =
        truck_cost_per_run +
        report.total.average_mileage_cost +
        report.total.average_fuel_cost +
        report.total.salary
      report.total.average_cost_per_bin =
        report.total.totalAverageCostPerBin /
        report.total.numberOfAverageCostPerBin
      report.total.route_cost_cpp =
        report.total.average_cost_per_run / report.total.average_lbs_collected
    }
  })

  return {
    bins: report.total.bins,
    runs: report.total.runs,
    total_lbs_collected: report.total.total_lbs_collected,
    average_lbs_collected: report.total.average_lbs_collected,
    truck_fixed_monthly_cost: report.total.truck_fixed_monthly_cost,
    truck_average_cost_per_run: report.truck_cost_per_run,
    truck_cost_cpp: report.total.truck_cost_cpp,
    total_mileage: report.total.total_mileage,
    average_mileage: report.total.average_mileage,
    average_mileage_cost_cpp: report.total.average_mileage_cost_cpp,
    price_per_gallon: report.price_per_gallon,
    average_mpg: report.average_mpg,
    average_fuel_cost: report.total.average_fuel_cost,
    average_fuel_cost_cpp: report.total.average_fuel_cost_cpp,
    average_salary: report.total.salary,
    salary_cost_cpp: report.total.salary_cost_cpp,
    average_cost_per_bin: report.total.average_cost_per_bin,
    average_cost_per_run: report.total.average_cost_per_run,
    route_cost_cpp: report.total.route_cost_cpp,
  }
}
