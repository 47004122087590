import gql from 'graphql-tag'
import { Partnership } from '../Query/partnerships'

export const createPartnership = gql`
  mutation createPartnership(
    $partnership: PartnershipInput!
  ) {
    createPartnership(
      partnership: $partnership
    ) {
      ${Partnership}
    }
  }
`

export const updatePartnership = gql`
  mutation updatePartnership(
    $id: String!
    $partnership: PartnershipInput!
  ) {
    updatePartnership(
      id: $id
      partnership: $partnership
    ) {
      ${Partnership}
    }
  }
`
