import gql from 'graphql-tag'
import { binModel } from '../Query/bins'

export const createBin = gql`
  mutation createBin(
    $bin: BinInput!
  ) {
    createBin(
      bin: $bin
    ) {
      ${binModel}
    }
  }
`

export const updateBin = gql`
  mutation updateBin(
    $binId: String!
    $bin: BinInput!
  ) {
    updateBin(
      binId: $binId
      bin: $bin
    ) {
      ${binModel}
    }
  }
`
