import './styles.scss'

import axios from 'axios'
import React from 'react'

import _isEmpty from 'lodash/isEmpty'

import { withAuth } from '../../../connectors/auth'
import { withBinDropdownOptions } from '../../../connectors/bins'
import { withUserDropdownOptions } from '../../../connectors/users'
import { Field, reduxForm } from 'redux-form'

import { Button, Form, Header, Icon, Message } from 'semantic-ui-react'

import DatePicker from '../../../components/DatePicker'
import FormField from '../../../components/FormField'
import Select from '../../../components/Select'

import config from '../../../config'
import { withForm } from '../../../connectors/forms'
import validator from 'validator'

import { reasonsPoliceReportOptions } from '../../../forms/Bin/options'

export const validate = values => {
  const errors = {}

  const fields = ['binId', 'userId', 'reason', 'description_of_damages_notes']

  fields.forEach(field => {
    if (!values[field] || validator.isEmpty(values[field])) {
      errors[field] = 'invalid/required'
    }
  })

  if (_isEmpty(values.date_of_incident)) {
    errors.date_of_incident = 'invalid/required'
  }

  return errors
}

const DateField = ({ label, ...props }) => (
  <Form.Field {...props}>
    <label>{label}</label>
    <DatePicker singleDay={true} {...props} />
  </Form.Field>
)

@withAuth
@withBinDropdownOptions
@withUserDropdownOptions
@withForm('PoliceReportForm')
@reduxForm({
  form: 'PoliceReportForm',
  validate,
})
export default class PoliceReportForm extends React.Component {
  constructor(props) {
    super(props)
    let images = []
    let documents = []

    if (
      !_isEmpty(props.initialValues) &&
      !_isEmpty(props.initialValues.photos)
    ) {
      images = props.initialValues.photos
    }

    if (
      !_isEmpty(props.initialValues) &&
      !_isEmpty(props.initialValues.documents)
    ) {
      documents = props.initialValues.documents
    }

    this.state = {
      images,
      documents,
    }

    this.props.change('binId', this.props.binId)
  }

  addImage(image) {
    const images = [...this.state.images, image]

    this.setState(state => ({
      ...state,
      images,
    }))

    this.props.change('photos', images)
  }

  removeImage(index) {
    let images = this.state.images
    images.splice(index, 1)

    this.setState(state => ({
      ...state,
      images,
    }))

    this.props.change('photos', images)
  }

  addDocument(document) {
    const documents = [...this.state.documents, document]

    this.setState(state => ({
      ...state,
      documents,
    }))

    this.props.change('documents', documents)
  }

  removeDocument(index) {
    let documents = this.state.documents
    documents.splice(index, 1)

    this.setState(state => ({
      ...state,
      documents,
    }))

    this.props.change('documents', documents)
  }

  pickImageFile = () => {
    let input = document.createElement('input')
    input.type = 'file'
    input.onchange = _ => {
      let files = Array.from(input.files)
      let file = files[0] || undefined

      if (file) {
        const data = new FormData()
        data.append('file', file)
        const URL = config.FUNCTIONS_URI + '/uploadFile'

        axios.post(URL, data, {}).then(res => {
          this.addImage(res.data.url)
        })
      }
    }
    input.click()
  }

  pickDocumentFile = () => {
    let input = document.createElement('input')
    input.type = 'file'
    input.onchange = _ => {
      let files = Array.from(input.files)
      let file = files[0] || undefined

      if (file) {
        const data = new FormData()
        data.append('file', file)
        const URL = config.FUNCTIONS_URI + '/uploadFile'

        axios.post(URL, data, {}).then(res => {
          this.addDocument(res.data.url)
        })
      }
    }
    input.click()
  }

  parseUserOptions(items) {
    if (!items) {
      return []
    }

    const isAdminOrManager = user => {
      if (!user.contact_type) {
        return false
      }

      return user.contact_type === 'admin' || user.contact_type === 'manager'
    }

    let filteredItems = items.filter(isAdminOrManager)

    // TODO: sanitize input
    return filteredItems.map(item => ({
      key: item.id || 'someId',
      id: item.id || 'someId',
      value: item.id || 'someId',
      text: `${item.first_name || 'John'} ${item.last_name || 'Doe'}`,
    }))
  }

  parseBinOptions(items) {
    if (!items) {
      return []
    }

    // TODO: sanitize input
    return items.map(item => ({
      key: item.id || 'someId',
      id: item.id || 'someId',
      value: item.id || 'someId',
      text: item.serial_number || 'someSerialNumber',
    }))
  }

  render() {
    const {
      formError,
      handleSubmit,
      binDropdownOptions,
      userDropdownOptions,
      viewMode,
      selector,
      editing,
    } = this.props
    const { images, documents } = this.state

    const binOptions = this.parseBinOptions(binDropdownOptions)
    const userOptions = this.parseUserOptions(userDropdownOptions)
    let binIsReadonly = false

    if (editing && selector('reason') === 'bin_missing') {
      binIsReadonly = true
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Field
            name="binId"
            label="Bin"
            placeholder="Choose Bin"
            options={binOptions}
            component={FormField}
            formComponent={Select}
            required
            disabled={viewMode || binIsReadonly}
            search
          />
          <Field
            name="userId"
            label="User"
            placeholder="Choose a User"
            options={userOptions}
            component={FormField}
            formComponent={Select}
            required
            disabled={viewMode}
            search
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="reason"
            label="Reason"
            placeholder="Reason"
            options={reasonsPoliceReportOptions}
            component={FormField}
            required
            formComponent={Select}
            disabled={viewMode}
          />
          <Field
            name="date_of_incident"
            label="Date Of Incident"
            required
            component={FormField}
            onBlur={e => e.preventDefault()}
            formComponent={DateField}
            disabled={viewMode}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="description_of_damages_notes"
            label="Description of Damages / Notes"
            placeholder="Description of Damages / Notes"
            component={FormField}
            required
            formComponent={Form.Input}
            disabled={viewMode}
          />
          <Field
            name="approximate_associated_costs"
            label="Approximate Associated Costs"
            placeholder="Approximate Associated Costs"
            component={FormField}
            formComponent={Form.Input}
            disabled={viewMode}
          />
        </Form.Group>

        <Header>Police Report</Header>

        <Form.Group widths="equal">
          <Field
            name="report_number"
            label="Report Number"
            placeholder="Report Number"
            component={FormField}
            formComponent={Form.Input}
            disabled={viewMode}
          />
          <Field
            name="responding_officers_name"
            label="Responding Officer's Name"
            placeholder="Responding Officer's Name"
            component={FormField}
            formComponent={Form.Input}
            disabled={viewMode}
          />
        </Form.Group>

        <Header>Photos Upload</Header>

        <Form.Group className="FormGroup__fix">
          {images.length < 1 && (
            <div>
              <h5>There are no photos uploaded yet.</h5>
            </div>
          )}

          {images.map((image, index) => {
            return (
              <div
                key={`photo_${index}`}
                className="PoliceReport__ImageContainer"
              >
                <img
                  className="PoliceReport__ImageContainer__Image"
                  src={image}
                  alt="Police Report Evidence"
                />
                {!viewMode && (
                  <Button
                    className="PoliceReport__ImageContainer__RemoveButton"
                    color="red"
                    onClick={e => {
                      e.preventDefault()
                      this.removeImage(index)
                    }}
                  >
                    X
                  </Button>
                )}
              </div>
            )
          })}
        </Form.Group>

        {!viewMode && (
          <Button
            icon
            color="orange"
            onClick={e => {
              e.preventDefault()
              this.pickImageFile()
            }}
          >
            <Icon name="plus" /> Add photo
          </Button>
        )}

        <Header>Documents Upload</Header>

        <Form.Group className="FormGroup__fix FormGroup_fix">
          {documents.length < 1 && (
            <div>
              <h5>There are no documents uploaded yet.</h5>
            </div>
          )}

          {documents.map((document, index) => {
            return (
              <div
                key={`document_${index}`}
                className="PoliceReport__DocumentsContainer"
              >
                <div>
                  <a
                    className="PoliceReport__DocumentsContainer__DocumentLink"
                    href={document}
                    target="_blank"
                  >
                    Document {index + 1}
                  </a>
                  <Button
                    className="PoliceReport__DocumentsContainer__DownloadButton"
                    color="green"
                    onClick={e => {
                      e.preventDefault()
                      console.log('//TODO: Redirect to document URL.')
                    }}
                  >
                    Download
                  </Button>
                  {!viewMode && (
                    <Button
                      className="PoliceReport__DocumentsContainer__RemoveButton"
                      color="red"
                      onClick={e => {
                        e.preventDefault()
                        this.removeDocument(index)
                      }}
                    >
                      X
                    </Button>
                  )}
                </div>
              </div>
            )
          })}
        </Form.Group>

        {!viewMode && (
          <Button
            className="PoliceReport__Documents__AddDocumentButton"
            icon
            color="orange"
            onClick={e => {
              e.preventDefault()
              this.pickDocumentFile()
            }}
          >
            <Icon name="plus" /> Add document
          </Button>
        )}

        <Form.Group widths="equal">
          <Field
            name="notes_on_follow_up_with_police"
            label="Notes on Follow Up With Police"
            placeholder="Notes on Follow Up With Police"
            component={FormField}
            formComponent={Form.Input}
            disabled={viewMode}
          />
        </Form.Group>

        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
