import gql from 'graphql-tag'
import { CompetitorLogModel, CompetitorModel } from '../Query/competitors'

export const addLocationCompetitor = gql`
  mutation($locationId: String!, $competitor: LocationCompetitorInput!, $selection: LocationCompetitorSelectionInput!) {
    addLocationCompetitor(locationId: $locationId, competitor: $competitor, selection: $selection) {
      current_competitors {
        ${CompetitorModel}
      }
      competitor_history {
        ${CompetitorLogModel}
      }
    }
  }
`

export const removeLocationCompetitor = gql`
  mutation($locationId: String!, $competitorId: String!) {
    removeLocationCompetitor(locationId: $locationId, competitorId: $competitorId) {
      current_competitors {
        ${CompetitorModel}
      }
      competitor_history {
        ${CompetitorLogModel}
      }
    }
  }
`
