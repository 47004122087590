import React from 'react'
import moment from 'moment'
import { get } from 'lodash'

import { withRoutes } from '../../connectors/routes'
import { withForm } from '../../connectors/forms'
import { withAuth } from '../../connectors/auth'
import { Button, Message, Icon } from 'semantic-ui-react'

import RouteReportForm from './form'

export const LoadingMessage = ({ message, title = 'Just one second' }) => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>{title}</Message.Header>
      {message}
    </Message.Content>
  </Message>
)

@withRoutes
@withAuth
@withForm('RouteReportForm')
export default class Route extends React.PureComponent {
  state = {
    loading: false,
    routeReport: {},
  }
  /*  componentWillReceiveProps(nextProps) {
    const { selector } = nextProps
    const { selector: oldSelector } = this.props

    if (
      selector('month') !== oldSelector('month') ||
      selector('year') !== oldSelector('year')
    ) {
      this.fetchRouteReport(selector('year'), selector('month'))
    }
  }*/
  componentDidMount() {
    this.renderButtons()

    // Resolve report of route
    //this.fetchRouteReport()
  }
  componentWillReceiveProps(nextProps) {
    const { getRoutesByMarket } = this.props
    if (this.props.selector('market') !== nextProps.selector('market'))
      getRoutesByMarket(nextProps.selector('market'))
  }
  fetchRouteReport(year = moment().year(), month = moment().month() + 1) {
    // Resolve report of route
    const { getRouteReport, routeId } = this.props
    if (routeId) {
      this.setState({ loadingHistory: true, history: null })
      getRouteReport({ routeId, year, month })
        .then(history => {
          this.setState({ history, loadingHistory: false })
        })
        .catch(error =>
          this.setState({ error, loadingHistory: false, history: null })
        )
    }
  }
  onSubmit = ({ route, market, ...args }) => {
    const { downloadRouteReport, closeModal, routeId } = this.props
    this.setState({ submitting: true })
    return downloadRouteReport({ routeId: route || routeId, ...args })
      .then(data => data && this.fireDownload(data.url))
      .then(_ => closeModal())
      .catch(error => this.setState({ error, submitting: false }))
  }

  fireDownload(url) {
    window.open(url, '_blank')
  }

  renderButtons() {
    const { renderButtons, closeModal, submitForm } = this.props

    return renderButtons(
      <div>
        <Button negative onClick={closeModal}>
          Nevermind
        </Button>
        <Button
          positive
          labelPosition="right"
          icon="download"
          onClick={submitForm}
          content="Generate"
        />
      </div>
    )
  }

  render() {
    const { error, submitting, history, loadingHistory } = this.state
    const {
      routeId,
      marketId,
      routes,
      auth: { user: { markets } },
    } = this.props

    if (submitting) {
      return <LoadingMessage message="Generating report..." />
    }
    return (
      <div>
        <RouteReportForm
          key="RouteReportForm"
          onSubmit={this.onSubmit}
          history={history}
          initialValues={{
            year: moment().year(),
            month: moment().month() + 1,
            regenerate: false,
            route: get(routes.routes.find(({ id }) => id === routeId), 'id'),
            market: get(markets.find(({ id }) => id === marketId), 'id'),
          }}
          loadingHistory={loadingHistory}
        />
        {error && (
          <Message error header="Error" key="userError">
            {error.message}
          </Message>
        )}
      </div>
    )
  }
}
