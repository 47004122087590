import './styles.scss'

import React from 'react'

import { Pagination } from 'semantic-ui-react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import { withAuth } from '../../../connectors/auth'

import PoliceReportCard from '../PoliceReportCard'

@withAuth
class PoliceReportGrid extends React.PureComponent {
  state = {
    activePage: 0,
    size: 20,
  }

  onPageChange(_event, data) {
    this.setState({ activePage: data.activePage })
  }

  render() {
    const { policeReports, auth } = this.props
    const { activePage, size } = this.state

    const userId = auth.user.id

    const policeReportsToPaginate = []

    policeReports.forEach(policeReport => {
      const record = policeReport

      if (record.userId === userId) {
        record.isAssignedToMe = true
      }

      policeReportsToPaginate.push(record)
    })

    policeReportsToPaginate.sort((a, b) => {
      return b.isAssignedToMe ? 1 : -1
    })

    let paginated = []

    if (policeReportsToPaginate) {
      let pageNumber = activePage
      if (activePage > 0) {
        pageNumber = pageNumber - 1
      }

      paginated = policeReportsToPaginate.slice(
        pageNumber * size,
        (pageNumber + 1) * size
      )
    }

    return (
      <Grid className="PoliceReportGrid">
        {paginated.length < 1 && (
          <div style={{ textAlign: 'center' }}>
            <h2>There are no any Police Reports yet.</h2>
          </div>
        )}

        <Row start="xs" className="PoliceReportGrid_row">
          {paginated.length > 0 &&
            paginated.map(policeReport => (
              <Col xs={6} sm={4} md={3} key={policeReport.id}>
                <PoliceReportCard data={policeReport} />
              </Col>
            ))}
        </Row>

        <Row>
          {paginated.length > 0 && (
            <Pagination
              className="PoliceReportGrid_pagination"
              onPageChange={this.onPageChange.bind(this)}
              defaultActivePage={1}
              totalPages={Math.ceil(paginated.length / size)}
            />
          )}
        </Row>
      </Grid>
    )
  }
}

export default PoliceReportGrid
