import { withRuns } from '../../../connectors/runs'
import moment from 'moment'
import React from 'react'
import { Button, Grid, Icon, Label, Message, Modal } from 'semantic-ui-react'

import './style.scss'

@withRuns
export default class RescheduleRunModal extends React.Component {
  state = {
    open: true,
    error: null,
  }

  setOpen(open) {
    this.setState({
      open,
    })
  }

  setError(error) {
    this.setState({
      error,
    })
  }

  clearError() {
    this.setState({
      error: null,
    })
  }

  onCancel = () => {
    const { onRescheduledModalClose } = this.props

    onRescheduledModalClose()
    this.clearError()
    this.setOpen(false)
  }

  onConfirm = () => {
    const {
      onRescheduledModalClose,
      onRescheduledSuccessfully,
      run,
      reScheduleRun,
    } = this.props
    const date = moment(run.runDate)
      .utc()
      .hours(12)
      .toISOString()

    this.clearError()
    reScheduleRun(run.runId, date).then(result => {
      if (result.type === 'RUN_MOVED_SUCCESSFULLY') {
        onRescheduledSuccessfully(run)
        onRescheduledModalClose()
        this.setOpen(false)
      } else if (result.type === 'RUN_MOVE_FAILED') {
        this.setError(result.payload.message)
      }
    })
  }

  render() {
    const { run, runs: { loading } } = this.props
    const { open, error } = this.state

    return (
      <Modal
        onClose={() => this.onCancel()}
        onOpen={() => this.setOpen(true)}
        open={open}
        dimmer={'blurring'}
        size={'tiny'}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          Are you sure you want to reschedule this run?
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={8}>
                  <p>
                    <label class="label">Route:</label>
                    <Label
                      circular
                      style={{ backgroundColor: run.route.color }}
                      empty
                    />
                    <span class="label" style={{ color: run.route.color }}>
                      {run.route.name}
                    </span>
                  </p>
                </Grid.Column>
                <Grid.Column textAlign="center" width={8} />
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column textAlign="center" width={8}>
                  <Label image size="large" color="gray">
                    Current date
                    <Label.Detail>
                      {moment(run.oldRunDate).format('MM/DD/YYYY')}
                    </Label.Detail>
                  </Label>
                </Grid.Column>
                <Grid.Column textAlign="center" width={8}>
                  <Label image size="large" color="green">
                    New date
                    <Label.Detail>
                      {moment(run.runDate).format('MM/DD/YYYY')}
                    </Label.Detail>
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {error && (
              <Message color="red" className="reschedule-run-message">
                <Icon name="exclamation triangle" />
                {error}
              </Message>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Confirm"
            loading={loading}
            disabled={loading}
            onClick={() => this.onConfirm()}
            positive
          />
          <Button
            loading={loading}
            disabled={loading}
            color="red"
            onClick={() => this.onCancel()}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
