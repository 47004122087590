import { withAuth } from '../../connectors/auth'
import { cloneDeep } from 'lodash'
import React, { Fragment } from 'react'
import { Dimmer, Header, Label, List, Loader } from 'semantic-ui-react'
import 'url-search-params-polyfill'

import { has } from 'lodash'
import './style.scss'

const columnKeys = [
  'jan',
  'feb',
  'mar',
  'q1',
  'apr',
  'may',
  'jun',
  'q2',
  'jul',
  'aug',
  'sep',
  'q3',
  'oct',
  'nov',
  'dec',
  'q4',
]

const quarterKeys = ['q1', 'q2', 'q3', 'q4']

const monthValuesTemplate = {
  jan: 0,
  feb: 0,
  mar: 0,
  q1: 0,
  apr: 0,
  may: 0,
  jun: 0,
  q2: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  q3: 0,
  oct: 0,
  nov: 0,
  dec: 0,
  q4: 0,
}

function formatAsDecimal(num) {
  return Number(num || '').toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })
}

@withAuth
export default class ActualVSGoalReport extends React.PureComponent {
  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th />
          {columnKeys.map((month, index) => {
            const quarterNumber = Math.floor(index / 4) + 1

            return (
              <th class={`quarter_${quarterNumber}`}>
                <span>{month.toUpperCase()}</span>
              </th>
            )
          })}
          <th class={`annual_goal`}>
            <span>Total</span>
          </th>
        </tr>
      </thead>
    )
  }

  renderTableRow(marketReport) {
    const { year, routeId } = this.props
    const currentRoutes = this.filterRoutes(marketReport, year)
    const lastRoutes = this.filterRoutes(marketReport, Number(year) - 1)
    const cumGoals = cloneDeep(monthValuesTemplate)
    const cumActuals = cloneDeep(monthValuesTemplate)
    const cumVariances = cloneDeep(monthValuesTemplate)
    const versusBudget = cloneDeep(monthValuesTemplate)
    const currentYearGoals = cloneDeep(monthValuesTemplate)
    const currentYearActuals = cloneDeep(monthValuesTemplate)
    const lastYearActuals = cloneDeep(monthValuesTemplate)
    const increaseVersusLY = cloneDeep(monthValuesTemplate)
    let currentYearTotalGoals = 0
    let currentYearTotalActuals = 0
    let lastYearTotalActuals = 0
    let currentYearPreCalculatedGoals = []

    if (routeId === 'all-routes') {
      const marketGoals = marketReport.report.find(
        report => report.year === Number(year)
      )

      if (marketGoals)
        currentYearPreCalculatedGoals.push({
          ...marketGoals.market_goals,
        })
    } else {
      currentRoutes.forEach(route => {
        currentYearPreCalculatedGoals.push({ ...route.goals })
      })
    }

    // Round goals to upper
    currentYearPreCalculatedGoals.forEach(item => {
      Object.keys(item).forEach(key => {
        item[key] = Math.ceil(item[key])
      })
    })

    columnKeys.forEach((currCol, index) => {
      lastRoutes.forEach(route => {
        const actuals = {
          ...route.actuals.quarter_1,
          ...route.actuals.quarter_2,
          ...route.actuals.quarter_3,
          ...route.actuals.quarter_4,
          q1: route.actuals.quarter_1.total,
          q2: route.actuals.quarter_2.total,
          q3: route.actuals.quarter_3.total,
          q4: route.actuals.quarter_4.total,
        }

        lastYearActuals[currCol] += actuals[currCol]

        if (!quarterKeys.includes(currCol))
          lastYearTotalActuals += actuals[currCol]
      })

      let currMonthGoalTotal = 0

      currMonthGoalTotal = currentYearPreCalculatedGoals.reduce(
        (prev, curr) => {
          const goals = {
            ...curr,
            q1: curr.jan + curr.feb + curr.mar,
            q2: curr.apr + curr.may + curr.jun,
            q3: curr.jul + curr.aug + curr.sep,
            q4: curr.oct + curr.nov + curr.dec,
          }
          currentYearGoals[currCol] += goals[currCol]

          if (!quarterKeys.includes(currCol)) {
            currentYearTotalGoals += curr[currCol]
            return prev + curr[currCol]
          } else return prev
        },
        0
      )

      const currMonthActualTotal = currentRoutes.reduce((prev, curr) => {
        const actuals = {
          ...curr.actuals.quarter_1,
          ...curr.actuals.quarter_2,
          ...curr.actuals.quarter_3,
          ...curr.actuals.quarter_4,
          q1: curr.actuals.quarter_1.total,
          q2: curr.actuals.quarter_2.total,
          q3: curr.actuals.quarter_3.total,
          q4: curr.actuals.quarter_4.total,
        }

        currentYearActuals[currCol] += actuals[currCol]
        versusBudget[currCol] =
          (currentYearActuals[currCol] - currentYearGoals[currCol]) /
          currentYearGoals[currCol]
        increaseVersusLY[currCol] =
          (currentYearActuals[currCol] - lastYearActuals[currCol]) /
          lastYearActuals[currCol]

        if (!quarterKeys.includes(currCol)) {
          currentYearTotalActuals += actuals[currCol]
          return prev + actuals[currCol]
        } else return prev
      }, 0)

      if (!quarterKeys.includes(currCol)) {
        let prevMonth = null

        if (index === 0) prevMonth = null
        else prevMonth = columnKeys[index - 1]

        if (quarterKeys.includes(prevMonth)) prevMonth = columnKeys[index - 2]

        if (prevMonth) {
          cumGoals[currCol] = cumGoals[prevMonth] + currMonthGoalTotal
          cumActuals[currCol] = cumActuals[prevMonth] + currMonthActualTotal
        } else {
          cumGoals[currCol] += currMonthGoalTotal
          cumActuals[currCol] += currMonthActualTotal
        }

        cumVariances[currCol] = cumActuals[currCol] - cumGoals[currCol]
      }
    })

    const LBSOverUnderLY = currentYearTotalActuals - lastYearTotalActuals
    const LBSOverUnderBudget = currentYearTotalActuals - currentYearTotalGoals

    const rows = [
      <tr>
        <td>
          <span>CUM GOAL</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={'label-normal'}>
                {!quarterKeys.includes(month)
                  ? formatAsDecimal(Math.ceil(cumGoals[month]))
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>CUM ACT.</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                color={
                  cumVariances[month] < 0
                    ? 'label-red'
                    : cumVariances[month] > 0 ? 'label-green' : 'label-normal'
                }
              >
                {!quarterKeys.includes(month)
                  ? formatAsDecimal(cumActuals[month])
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>VARIANCE</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label
                className={
                  cumVariances[month] < 0
                    ? 'label-red'
                    : cumVariances[month] > 0 ? 'label-green' : 'label-normal'
                }
              >
                {!quarterKeys.includes(month)
                  ? formatAsDecimal(Math.abs(cumVariances[month]))
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>LBS Over/Under Budget</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          let labelClass = 'label-normal'
          let value = currentYearActuals[month] - currentYearGoals[month]

          if (value < 0) {
            labelClass = 'label-red'
            value = `${formatAsDecimal(Math.abs(value))} under budget`
          } else if (value >= 0) {
            labelClass = 'label-green'
            value = `${formatAsDecimal(Math.abs(value))} over budget`
          }

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={labelClass}>{value}</Label>
            </td>
          )
        })}
        <td>
          <Label
            className={LBSOverUnderBudget < 0 ? 'label-red' : 'label-green'}
          >
            {formatAsDecimal(Math.abs(LBSOverUnderBudget))}{' '}
            {LBSOverUnderBudget < 0 ? 'under budget' : 'over budget'}
          </Label>
        </td>
      </tr>,
      <tr>
        <td>
          <span>% Versus Budget</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className="label-normal">
                {!quarterKeys.includes(month)
                  ? isFinite(versusBudget[month])
                    ? `${formatAsDecimal(versusBudget[month])}%`
                    : 'N/A'
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>% Increase Versus {year - 1}</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className="label-normal">
                {!quarterKeys.includes(month)
                  ? isFinite(increaseVersusLY[month])
                    ? `${formatAsDecimal(increaseVersusLY[month])}%`
                    : 'N/A'
                  : ''}
              </Label>
            </td>
          )
        })}
        <td />
      </tr>,
      <tr>
        <td>
          <span>LBS Over/Under {year - 1}</span>
        </td>
        {columnKeys.map((month, index) => {
          const quarterNumber = Math.floor(index / 4) + 1
          let labelClass = 'label-normal'
          let value = currentYearActuals[month] - lastYearActuals[month]

          if (value < 0) {
            labelClass = 'label-red'
            value = `${formatAsDecimal(Math.abs(value))} under ${year - 1}`
          } else if (value >= 0) {
            labelClass = 'label-green'
            value = `${formatAsDecimal(Math.abs(value))} over ${year - 1}`
          }

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={labelClass}>{value}</Label>
            </td>
          )
        })}
        <td>
          <Label className={LBSOverUnderLY < 0 ? 'label-red' : 'label-green'}>
            {formatAsDecimal(Math.abs(LBSOverUnderLY))}{' '}
            {LBSOverUnderLY < 0 ? `under ${year - 1}` : `over ${year - 1}`}
          </Label>
        </td>
      </tr>,
    ]

    return rows
  }

  renderGoalsRow(marketReport) {
    const { year, routeId } = this.props
    const routes = this.filterRoutes(marketReport, year)
    let goals = {
      jan: 0,
      feb: 0,
      mar: 0,
      q1: 0,
      apr: 0,
      may: 0,
      jun: 0,
      q2: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      q3: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      q4: 0,
    }
    let total = 0

    routes.forEach(route => {
      const routeGoals = {
        ...route.goals,
        q1:
          Math.ceil(route.goals.jan) +
          Math.ceil(route.goals.feb) +
          Math.ceil(route.goals.mar),
        q2:
          Math.ceil(route.goals.apr) +
          Math.ceil(route.goals.may) +
          Math.ceil(route.goals.jun),
        q3:
          Math.ceil(route.goals.jul) +
          Math.ceil(route.goals.aug) +
          Math.ceil(route.goals.sep),
        q4:
          Math.ceil(route.goals.oct) +
          Math.ceil(route.goals.nov) +
          Math.ceil(route.goals.dec),
      }

      if (routeId !== 'all-routes') {
        Object.keys(routeGoals).forEach(key => {
          goals[key] += Math.ceil(routeGoals[key] || 0)

          if (!quarterKeys.includes(key))
            total += Math.ceil(routeGoals[key] || 0)
        })
      }
    })

    if (routeId === 'all-routes') {
      const marketGoals = marketReport.report.find(
        report => report.year === Number(year)
      )

      if (marketGoals) {
        goals = {
          ...marketGoals.market_goals,
          q1:
            Math.ceil(marketGoals.market_goals.jan) +
            Math.ceil(marketGoals.market_goals.feb) +
            Math.ceil(marketGoals.market_goals.mar),
          q2:
            Math.ceil(marketGoals.market_goals.apr) +
            Math.ceil(marketGoals.market_goals.may) +
            Math.ceil(marketGoals.market_goals.jun),
          q3:
            Math.ceil(marketGoals.market_goals.jul) +
            Math.ceil(marketGoals.market_goals.aug) +
            Math.ceil(marketGoals.market_goals.sep),
          q4:
            Math.ceil(marketGoals.market_goals.oct) +
            Math.ceil(marketGoals.market_goals.nov) +
            Math.ceil(marketGoals.market_goals.dec),
        }

        total = goals.q1 + goals.q2 + goals.q3 + goals.q4
      }
    }

    const row = (
      <tr key="market-goals-lbs">
        <td>{year} Goal LBS</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={'label-normal'}>
                {formatAsDecimal(Math.ceil(goals[colKey]))}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label className={'label-normal'}>
            {formatAsDecimal(Math.ceil(total))}
          </Label>
        </td>
      </tr>
    )

    return row
  }

  filterRoutes(marketReport, year) {
    const { routeId } = this.props
    const routes = []

    if (!marketReport.report) {
      return routes
    }

    if (routeId === 'all-routes') {
      marketReport.report.forEach(report => {
        if (report.year === Number(year))
          report.routes.forEach(route => {
            routes.push(route)
          })
      })
    } else {
      marketReport.report.forEach(report => {
        if (report.year === Number(year))
          report.routes.forEach(route => {
            if (routeId === route.id) routes.push(route)
          })
      })
    }

    return routes
  }

  renderActualsRowForYear(marketReport, year, title) {
    const routes = this.filterRoutes(marketReport, year)
    const actuals = {
      jan: 0,
      feb: 0,
      mar: 0,
      q1: 0,
      apr: 0,
      may: 0,
      jun: 0,
      q2: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      q3: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      q4: 0,
    }
    let total = 0

    routes.forEach(route => {
      const routeActuals = {
        ...route.actuals.quarter_1,
        ...route.actuals.quarter_2,
        ...route.actuals.quarter_3,
        ...route.actuals.quarter_4,
        q1: route.actuals.quarter_1.total,
        q2: route.actuals.quarter_2.total,
        q3: route.actuals.quarter_3.total,
        q4: route.actuals.quarter_4.total,
      }

      Object.keys(routeActuals).forEach(key => {
        if (columnKeys.includes(key)) {
          actuals[key] += routeActuals[key] || 0

          if (!quarterKeys.includes(key)) total += routeActuals[key] || 0
        }
      })
    })

    const row = (
      <tr key={`market-actuals-lbs-${year}`}>
        <td>{title}</td>
        {columnKeys.map((colKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label className={'label-normal'}>
                {formatAsDecimal(actuals[colKey])}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label className={'label-normal'}>{formatAsDecimal(total)}</Label>
        </td>
      </tr>
    )

    return row
  }

  renderLegends() {
    const legends = {
      YBL:
        'Year Before Last (2 years before the year selected in the year filter above)',
      LY:
        'Last Year (the year before the one selected in the year filter above)',
      CY: 'Current Year (the year selected in the year filter above)',
    }

    return (
      <List className={'legends'}>
        {Object.keys(legends).map(legendKey => (
          <List.Item>
            <span class="legend-title">{legendKey}</span> ={' '}
            <span class="legend-description">{legends[legendKey]}</span>
          </List.Item>
        ))}
      </List>
    )
  }

  renderMarketReport(showTitle, marketReport) {
    const { year, auth: { user: { markets } } } = this.props
    let title = null

    if (showTitle) {
      const market = markets.find(market => market.id === marketReport.market)
      if (market) title = market.name
    }

    return (
      <div
        style={{
          overflowX: 'auto',
        }}
        className="actual-vs-goals-table-container"
      >
        {showTitle && <Header as="h3">{title}</Header>}
        <table className="ui sortable table actual-vs-goals-table">
          {this.renderTableHeader()}
          <tbody>
            {[
              this.renderActualsRowForYear(
                marketReport,
                year - 2,
                `${year - 2} Total LBS`
              ),
              this.renderActualsRowForYear(
                marketReport,
                year - 1,
                `${year - 1} Total LBS`
              ),
              this.renderGoalsRow(marketReport),
              this.renderActualsRowForYear(
                marketReport,
                year,
                `${year} Actual LBS`
              ),
              this.renderTableRow(marketReport),
            ]}
          </tbody>
        </table>
      </div>
    )
  }

  renderSummaryReport(actualVsGoal) {
    const allMarkets = {
      report: [],
    }

    actualVsGoal.forEach(actualVsGoalReportItem => {
      actualVsGoalReportItem.report.forEach(actualVsGoalReportItemReport => {
        const allMarketReportItem = allMarkets.report.find(
          report => report.year === actualVsGoalReportItemReport.year
        )

        if (!allMarketReportItem)
          allMarkets.report.push(actualVsGoalReportItemReport)
        else {
          Object.keys(allMarketReportItem.market_goals).forEach(key => {
            allMarketReportItem.market_goals[key] =
              allMarketReportItem.market_goals[key] +
              actualVsGoalReportItemReport.market_goals[key]
          })

          allMarketReportItem.routes = [
            ...allMarketReportItem.routes,
            ...actualVsGoalReportItemReport.routes,
          ]
        }
      })
    })

    return [
      <Header as="h3">Summary</Header>,
      this.renderMarketReport(false, allMarkets),
    ]
  }

  renderReport() {
    const { data } = this.props

    if (!has(data, 'actualVsGoal')) return null

    const reportCount = data.actualVsGoal.length

    if (reportCount === 1) {
      return this.renderMarketReport(false, data.actualVsGoal[0])
    }

    if (reportCount > 1) {
      return [
        data.actualVsGoal.map(marketReport =>
          this.renderMarketReport(true, marketReport)
        ),
        this.renderSummaryReport(data.actualVsGoal),
      ]
    }

    return false
  }

  render() {
    const { loading } = this.props

    return (
      <Fragment>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {this.renderReport()}
      </Fragment>
    )
  }
}
