import React from 'react'
import { Link } from 'react-router-dom'
import 'url-search-params-polyfill'

import { Divider } from 'semantic-ui-react'
import './style.scss'

export default class ReportsEntry extends React.PureComponent {
  render() {
    return (
      <div class="reports-button-page">
        <Divider hidden />
        <Link to="/app/reports/summary">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Summary
            Report
          </button>
        </Link>
        <Link to="/app/reports/drivers">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Drivers
            Report
          </button>
        </Link>
        <Divider />
        <Link to="/app/reports/npo-locations">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> NPO
            Locations Report
          </button>
        </Link>
        <Link to="/app/reports/leased-locations">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Leased
            Locations Report
          </button>
        </Link>
        <Link to="/app/reports/verbal-locations">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Signed or
            Verbal Locations Report
          </button>
        </Link>
        <Link to="/app/reports/permit">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Permit
            Report
          </button>
        </Link>
        <Link to="/app/reports/property-manager">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Property
            Manager Report
          </button>
        </Link>
        <Divider />
        <Link to="/app/reports/locations-performance">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Locations
            Performance Report
          </button>
        </Link>
        <Divider />
        <Link to="/app/reports/goals-data-entry">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Goals Form
          </button>
        </Link>
        <Link to="/app/reports/actuals-vs-goals">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Actual vs.
            Goals Report
          </button>
        </Link>
        <Link to="/app/reports/previous-year-comparison">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Previous
            Year Comparison
          </button>
        </Link>
        <Link to="/app/reports/cra-route">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> CRA Route
            Report
          </button>
        </Link>
        <Divider />
        <Link to="/app/reports/monthly-route-cost-analysis">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Monthly
            Route Cost Analysis
          </button>
        </Link>
        <Link to="/app/reports/quarterly-route-cost-analysis">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Quarterly
            Route Cost Analysis
          </button>
        </Link>
        <Link to="/app/reports/cumulative-quarterly-review-route-cost-analysis">
          <button class="ui blue icon button">
            <i aria-hidden="true" class="file text outline icon" /> Cumulative
            Quarterly Review Route Cost Analysis
          </button>
        </Link>
      </div>
    )
  }
}
