
if(process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
	console.log('[src/config.js] Starting')
}

let _CONFIG = {}

switch(process.env.REACT_APP_ENV) {
	case 'local':
		_CONFIG.APOLLO_URI = process.env.REACT_APP_API || 'http://localhost:5001/atrs-dev/us-central1/api/graphql?'
		_CONFIG.FUNCTIONS_URI = process.env.REACT_APP_FUNCTIONS_URI || 'http://localhost:5001/atrs-dev/us-central1'
		break
	case 'development':
		_CONFIG.APOLLO_URI = process.env.REACT_APP_API || 'https://us-central1-atrs-dev.cloudfunctions.net/api/graphql?'
		_CONFIG.FUNCTIONS_URI = 'https://us-central1-atrs-dev.cloudfunctions.net'
		break
	case 'stage':
		_CONFIG.APOLLO_URI = process.env.REACT_APP_API || 'https://us-central1-atrs-stag-server.cloudfunctions.net/api/graphql?'
		_CONFIG.FUNCTIONS_URI = 'https://us-central1-atrs-stag-server.cloudfunctions.net'
		break
	case 'production':
		_CONFIG.APOLLO_URI = process.env.REACT_APP_API || 'https://us-central1-atrs-prod-server.cloudfunctions.net/api/graphql?'
		_CONFIG.FUNCTIONS_URI = 'https://us-central1-atrs-prod-server.cloudfunctions.net'
		break
	default:
		_CONFIG.APOLLO_URI = process.env.REACT_APP_API || 'http://localhost:5001/atrs-dev/us-central1/api/graphql?'
		_CONFIG.FUNCTIONS_URI = process.env.REACT_APP_FUNCTIONS_URI || 'http://localhost:5001/atrs-dev/us-central1'
}

if(process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
	console.log({
		processEnv: process.env,
		_CONFIG,
	})
}

export default _CONFIG

if(process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
	console.log('[src/config.js] Completed')
}
