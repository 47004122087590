import React from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _first from 'lodash/first'
import ModalTrigger from '../ModalTrigger'
import { LoadingMessage } from '../Loading'

import { Button, Message } from 'semantic-ui-react'

class ConfirmationForm extends React.PureComponent {
  static propTypes = {
    message: PropTypes.string,
    disabled: PropTypes.bool,
  }

  state = {
    loading: false,
  }

  componentDidMount() {
    this.renderButtons()
  }

  renderButtons() {
    const { renderButtons, closeModal, onSubmit, disabled } = this.props
    const { error, loading } = this.state

    if (disabled) {
      return renderButtons(
        <div>
          <Button positive onClick={closeModal}>
            Understood
          </Button>
        </div>
      )
    }
    return renderButtons(
      <div>
        <Button disabled={loading} negative onClick={closeModal}>
          {error ? 'Close' : 'Cancel'}
        </Button>
        {!error && (
          <Button
            positive
            disabled={loading}
            loading={loading}
            onClick={() => {
              const prom = onSubmit()

              if (_get(prom, 'then')) {
                this.setState({ loading: true }, this.renderButtons)
                prom.then(closeModal).catch(graphQLError => {
                  const error = _first(_get(graphQLError, 'graphQLErrors', []))
                  this.setState({ loading: false, error }, this.renderButtons)
                })

                return prom
              }
              closeModal()
            }}
          >
            Confirm
          </Button>
        )}
      </div>
    )
  }

  render() {
    const { message, loadingTitle, loadingText } = this.props
    const { loading, error } = this.state

    if (loading)
      return (
        <LoadingMessage
          title={loadingTitle || 'Saving this'}
          text={loadingText || ''}
        />
      )
    if (error)
      return (
        <Message negative>
          <Message.Header>{error.message}</Message.Header>
        </Message>
      )

    return <p>{message || 'Are you sure?'}</p>
  }
}

const Confirmation = props => {
  const {
    title,
    trigger,
    onConfirm,
    message,
    disabled,
    loadingTitle,
    loadingText,
    ...modalProps
  } = props

  return (
    <ModalTrigger
      title={title || 'Confirm'}
      trigger={trigger}
      form={
        <ConfirmationForm
          onSubmit={onConfirm}
          message={message}
          disabled={disabled}
          loadingTitle={loadingTitle}
          loadingText={loadingText}
        />
      }
      {...modalProps}
    />
  )
}

export default Confirmation
