import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { Icon, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Route } from 'react-router'
import Logout from '../../components/Logout'
import Version from '../../components/Version'

const LinkIcon = ({ to, name, tooltip, active }) => (
  <Col xs className={active ? 'left-nav__active' : null}>
    <Link to={to}>
      <Popup
        position="right center"
        trigger={<Icon name={name} size="huge" />}
        content={tooltip || name}
      />
    </Link>
  </Col>
)

const RouteLinkIcon = props => (
  <Route
    path={props.to}
    children={({ match }) => <LinkIcon active={match} {...props} />}
  />
)

const Nav = () => {
  return (
    <Row className="left-nav__grid">
      <RouteLinkIcon
        to="/app/dashboard"
        name="world"
        tooltip="Where Are We At"
      />
      <RouteLinkIcon to="/app/users" name="user" tooltip="Users" />
      <RouteLinkIcon to="/app/routes" name="marker" tooltip="Routes" />
      <RouteLinkIcon
        to="/app/reports"
        name="file text outline"
        tooltip="Reports"
      />
      <RouteLinkIcon to="/app/schedule" name="calendar" tooltip="Schedule" />
      <RouteLinkIcon to="/app/locations" name="zip" tooltip="Locations" />
      <RouteLinkIcon
        to="/app/bin-management"
        name="cube"
        tooltip="Bin Management"
      />
      <RouteLinkIcon
        to="/app/maintenance-requests"
        name="wrench"
        tooltip="Maintenance Requests"
      />
      <RouteLinkIcon
        to="/app/police-reports"
        name="taxi"
        tooltip="Police Reports"
      />
      <Col xs className="left-nav__logout">
        <Popup
          position="right center"
          trigger={
            <Logout>
              <Icon name="power" size="huge" />
            </Logout>
          }
          content="Logout"
        />
        <Version />
      </Col>
    </Row>
  )
}

export default Nav
