import MarketsDropdown from '../../components/MenuDropdown/Markets'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Dropdown, Grid } from 'semantic-ui-react'
import { generateArrayOfYears, getCurrentYear } from '../../utils/date'

const Filters = withRouter(
  ({ match: { url }, location: { search }, history, routes, loading }) => {
    let query = new URLSearchParams(window.location.search)

    function resetMarket() {
      query = new URLSearchParams(window.location.search)
      query.delete('market')
      query.delete('route')
      history.push(`${url}?${query.toString()}`)
    }

    function onMarketChange(market) {
      query = new URLSearchParams(window.location.search)
      query.set('market', market.id)
      query.delete('route')
      history.push(`${url}?${query.toString()}`)
    }

    function onRouteChange(value) {
      query = new URLSearchParams(window.location.search)
      let selectedRoutes = []

      if (value.includes('all-routes')) {
        if (routeOptions.length !== value.length) {
          selectedRoutes = routeOptions
            .filter(option => option.value !== 'all-routes')
            .map(item => item.value)
        }
      } else {
        selectedRoutes = value
      }

      if (selectedRoutes.length) {
        query.set('route', selectedRoutes.join(','))
      } else {
        query.delete('route')
      }

      history.push(`${url}?${query.toString()}`)
    }

    function onYearChange(value) {
      query = new URLSearchParams(window.location.search)
      query.set('year', value)
      history.push(`${url}?${query.toString()}`)
    }

    function onQuarterChange(value) {
      query = new URLSearchParams(window.location.search)

      if (value.length) {
        query.set('quarter', value.join(','))
      } else {
        query.delete('quarter')
      }

      history.push(`${url}?${query.toString()}`)
    }

    function dropFilter(name) {
      query = new URLSearchParams(window.location.search)
      query.delete(name)
      history.push(`${url}?${query.toString()}`)
    }

    const routeOptions = [{ text: 'All Routes', value: 'all-routes' }]
    const quarterOptions = [
      {
        text: 'Q1',
        value: 'q1',
      },
      {
        text: 'Q2',
        value: 'q2',
      },
      {
        text: 'Q3',
        value: 'q3',
      },
      {
        text: 'Q4',
        value: 'q4',
      },
    ]
    const years = generateArrayOfYears(50)
    years.unshift(getCurrentYear() + 1)

    if (query.has('market') && routes) {
      routes.forEach(route => {
        routeOptions.push({
          text: route.name,
          value: route.id,
        })
      })
    }

    return (
      <Grid className="Reports__Filters__CRA">
        <Grid.Column mobile={16} computer={3} key="col1">
          <MarketsDropdown
            activeLabel={false}
            label="Select Market"
            showAll
            selected={query.get('market')}
            onSelect={market => {
              if (!market) {
                resetMarket()
              } else {
                onMarketChange(market)
              }
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} key="col2">
          <Dropdown
            fluid
            value={query.get('route') ? query.get('route').split(',') : []}
            selection
            defaultValue="all-routes"
            options={routeOptions}
            multiple
            placeholder="All Routes"
            loading={loading}
            disabled={loading || !routes.length || !query.has('market')}
            onChange={(e, { value }) => {
              if (value) onRouteChange(value)
              else dropFilter('route')
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} key="col3">
          <Dropdown
            fluid
            selection
            defaultValue={query.get('year')}
            value={query.get('year')}
            options={years.map(year => ({ text: year, value: String(year) }))}
            placeholder="Select Year"
            loading={loading}
            disabled={loading || !query.has('market')}
            onChange={(e, { value }) => {
              if (value) {
                onYearChange(value)
              } else {
                dropFilter('year')
              }
            }}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          computer={4}
          key="col4"
          style={{ display: 'flex', alignItems: 'start' }}
        >
          <Dropdown
            fluid
            value={query.get('quarter') ? query.get('quarter').split(',') : []}
            selection
            defaultValue={quarterOptions.map(item => item.value)}
            options={quarterOptions}
            multiple
            placeholder="All Quarters"
            loading={loading}
            disabled={loading || !routes.length || !query.has('market')}
            onChange={(e, { value }) => {
              if (value) onQuarterChange(value)
              else dropFilter('quarter')
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} textAlign="right">
          <Button
            positive
            labelPosition="left"
            icon="print"
            onClick={() => window.print()}
            content="Print"
          />
        </Grid.Column>
      </Grid>
    )
  }
)

export default Filters
