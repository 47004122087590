import React from 'react'
import { Form } from 'semantic-ui-react'

import _isEmpty from 'lodash/isEmpty'

import './styles.scss'

export default ({ onChange, ...props }) => {
  // TODO: fix when wrong PropTypes are passed properly, this is hack
  if (props.multiple && _isEmpty(props.value)) {
    props.value = []
  }

  return (
    <Form.Select onChange={(ev, data) => onChange(data.value)} {...props} />
  )
}
