import React from 'react'
import 'url-search-params-polyfill'
import _get from 'lodash/get'
import { withMarkets, withMarketData } from '../../connectors/markets'
import { withRoutes, withRoutesReport } from '../../connectors/routes'
import { generateRangeDate } from '../../utils/date'

import { Divider, Grid } from 'semantic-ui-react'
import { FormattedNumber } from 'react-intl'

import ChartCard from '../../components/ChartCard'
import ReportsTotal from '../../components/ReportsTotal'
import ReportCard from '../../components/ReportCard'
import Filters from './Filters'
import { dataKeys } from './data-keys'

@withMarkets
@withMarketData
@withRoutesReport
@withRoutes
export default class Reports extends React.PureComponent {
  /**
   *
   * @param {*} search
   * @returns
   */
  allFilterParamsAreSet(search) {
    if (search.get('market') && search.get('route') && search.get('range')) {
      return true
    }

    return false
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps
    const {
      getRouteReports,
      getRoutesByMarket,
      getMarketReports,
      setCurrent,
      location: { search: oldSearch },
    } = this.props
    const query = new URLSearchParams(search)
    const oldQuery = new URLSearchParams(oldSearch)

    const getRange = _ => {
      const range = generateRangeDate(query.get('range'))
      return {
        start: range.start.toISOString(),
        end: range.end.toISOString(),
      }
    }

    const market = query.get('market')
    const route =
      query.get('route') !== 'all-routes' ? query.get('route') : null
    const range = query.get('range')

    // Begin get market report
    if (market && route && range && search !== oldSearch) {
      getRouteReports(route, getRange())
    } else if (market && range && search !== oldSearch) {
      getMarketReports({
        ids: [market],
        ...getRange(),
      })
    }
    // End get market report

    if (market !== oldQuery.get('market')) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  componentDidMount() {
    const {
      current,
      getMarkets,
      getRoutesByMarket,
      history,
      location: { search },
      match: { url },
    } = this.props

    const query = new URLSearchParams(search)
    const market = query.get('market')

    if (!!current) {
      query.set('market', current.id)
      history.push(`${url}?${query.toString()}`)
    } else if (!!market) {
      getRoutesByMarket(market)
    }

    getMarkets()
  }

  render() {
    const {
      routes: { data },
      currentData,
      currentRouteData,
      marketReport,
      location,
    } = this.props

    const query = new URLSearchParams(location.search)
    const chartData = data ? currentRouteData : currentData
    const { trash, sellable } = _get(chartData, 'stats', {})

    const total = trash + sellable

    return (
      <div>
        <Divider hidden />
        <Filters />
        <Divider hidden />
        <Grid>
          <Grid.Column width={12}>
            <ChartCard
              data={chartData}
              dataKeys={dataKeys}
              allParamsAreSet={this.allFilterParamsAreSet(query)}
              title="Weekly Breakdown Information (avg by day)"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <ReportCard
              fluid
              loading={!total}
              title="Total"
              total={<FormattedNumber value={parseInt(total, 10)} />}
              color="black"
              tooltip={total}
            />
            <ReportCard
              fluid
              loading={!sellable}
              title="Sellable"
              total={<FormattedNumber value={parseInt(sellable, 10)} />}
              color="black"
              tooltip={sellable}
            />
            <ReportCard
              fluid
              loading={!trash}
              title="Trash"
              total={<FormattedNumber value={parseInt(trash, 10)} />}
              color="black"
            />
          </Grid.Column>
        </Grid>
        <Divider hidden />
        {marketReport && (
          <Grid>
            <Grid.Column width={16}>
              <ReportsTotal data={chartData && chartData.totals} />
            </Grid.Column>
          </Grid>
        )}
      </div>
    )
  }
}
