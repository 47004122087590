import React from 'react'
import { Segment, Statistic, Header } from 'semantic-ui-react'
import { FormattedNumber } from 'react-intl'
import _ from 'lodash'

import './styles.scss'

export default ({ data, ...props }) => (
  <Segment className="ReportsResumen__Segment">
    <Header size="medium">Sellable Weight</Header>
    {data &&
      data.map(item => (
        <Statistic key={_.uniqueId()} className="ReportsResumen__Statistic">
          <Statistic.Value>
            {<FormattedNumber value={item.cloth + item.misc} />}
          </Statistic.Value>
          <Statistic.Label>{item.name}</Statistic.Label>
        </Statistic>
      ))}
  </Segment>
)
