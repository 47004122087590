import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import _remove from 'lodash/remove'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import 'url-search-params-polyfill'

import { Col, Row } from 'react-flexbox-grid'
import { FormattedNumber } from 'react-intl'
import { Card, Divider, Segment } from 'semantic-ui-react'

import { withAuth } from '../../connectors/auth'
import { withMarketData, withMarkets } from '../../connectors/markets'
import { withRoutesData } from '../../connectors/routes'

import CircleMetric from '../../components/CircleMetric'
import DataTable from '../../components/DataTable'
import ReportCard from '../../components/ReportCard'
import { calculateGoal, distributeRangeInMonths } from '../../selectors/markets'
import MarketReport from './MarketReport'

const columns = ({ renderLink, hidden }) => {
  const cols = [
    {
      title: 'Market',
      name: 'market',
      icon: { name: 'angle down' },
    },
    {
      title: 'Bins',
      name: 'bins',
      align: 'center',
    },
    {
      title: 'Locations',
      name: 'locations',
      align: 'center',
    },
    {
      title: 'Goal',
      name: 'goal',
      align: 'center',
    },
    {
      title: 'Projected/Actual',
      name: 'projectedOrActual',
      align: 'center',
      tooltip: 'Projection for this month',
    },
    {
      title: 'Sellable Goods',
      name: 'total_sellable',
      align: 'center',
    },
    {
      title: 'Cloth',
      name: 'total_cloth',
      align: 'center',
    },
    {
      title: 'Miscel',
      name: 'total_misc',
      align: 'center',
    },
    {
      title: 'Trash',
      name: 'total_trash',
      align: 'center',
    },
    {
      title: 'View',
      name: 'market_filter',
      align: 'center',
      renderCell: renderLink,
    },
  ]

  if (hidden) _forEach(hidden, name => _remove(cols, { name }))

  return cols
}

const CircleMetricCol = props => (
  <Col sm>
    <CircleMetric {...props} />
  </Col>
)

const switchValues = (selectedMarketsDashboard, routesData, marketData) => {
	console.log({
		selectedMarketsDashboard,
		routesData,
		marketData,
	})
  const values =
    selectedMarketsDashboard.length === 1
      ? {
          projection: _get(routesData, 'projection', 0),
          totalProjectedOrActual: _get(marketData, 'totalProjectedOrActual', 0),
          sellable_weight: _get(routesData, 'sellable_weight'),
          total_cloth: _get(routesData, 'total_cloth'),
          total_goals: _get(marketData, 'totalGoals'),
          total_misc: _get(routesData, 'total_misc'),
          total_trash: _get(routesData, 'total_trash'),
          previousYearSellableWeight: _get(
            marketData,
            'previousYearSellableTotal'
          ),
          untilTodaySellableWeight: _get(marketData, 'untilTodaySellableTotal'),
          untilTodayLastYearSellableWeight: _get(
            marketData,
            'untilTodayLastYearSellableTotal'
          ),
          total_bins: _get(marketData, 'totalBins.bin'),
          total_containers: _get(marketData, 'totalBins.container'),
          total_locations: _get(marketData, 'totalLocations.locations'),
          total_bin_locations: _get(marketData, 'totalLocations.bin_locations'),
          total_container_locations: _get(
            marketData,
            'totalLocations.container_locations'
          ),
          avg_per_bin: _get(marketData, 'avgBinWeight.avgPerBin'),
          avg_per_container: _get(marketData, 'avgBinWeight.avgPerContainer'),
          avg_per_location: _get(
            marketData,
            'avgLocationWeight.avgPerLocations'
          ),
        }
      : {
          projection: _get(marketData, 'projected', 0),
          totalProjectedOrActual: _get(marketData, 'totalProjectedOrActual', 0),
          sellable_weight: _get(marketData, 'totalSellable'),
          total_cloth: _get(marketData, 'totals.cloth'),
          total_goals: _get(marketData, 'totalGoals'),
          total_misc: _get(marketData, 'totals.misc'),
          total_trash: _get(marketData, 'totals.trash'),
          previousYearSellableWeight: _get(
            marketData,
            'previousYearSellableTotal'
          ),
          untilTodaySellableWeight: _get(marketData, 'untilTodaySellableTotal'),
          untilTodayLastYearSellableWeight: _get(
            marketData,
            'untilTodayLastYearSellableTotal'
          ),
          total_bins: _get(marketData, 'totalBins.bin'),
          total_containers: _get(marketData, 'totalBins.container'),
          total_locations: _get(marketData, 'totalLocations.locations'),
          total_bin_locations: _get(marketData, 'totalLocations.bin_locations'),
          total_container_locations: _get(
            marketData,
            'totalLocations.container_locations'
          ),
          avg_per_bin: _get(marketData, 'avgBinWeight.avgPerBin'),
          avg_per_container: _get(marketData, 'avgBinWeight.avgPerContainer'),
          avg_per_location: _get(
            marketData,
            'avgLocationWeight.avgPerLocations'
          ),
        }

  return values
}

@withAuth
@withRoutesData
@withMarkets
@withMarketData
@withRouter
export default class Breakdown extends React.Component {
  getTotal(data, ...types) {
    if (!data || !data.totals) {
      return 0
    }
    return types.reduce((ret, type) => {
      return ret + data.totals[type]
    }, 0)
  }

  getWeight(...types) {
    const { marketData } = this.props

    if (!marketData.totalWeight) {
      return 0
    }
    return this.getTotal(marketData, ...types) / marketData.totalWeight
  }

  getTableData() {
    const { markets: { data, range } } = this.props

    if (!data) {
      return
    }

    return data.map(market => {
      const { reports } = market

      return {
        market: market.name,
        bins: reports.bins.bins,
        locations: reports.locations.locations,
        goal: calculateGoal(
          distributeRangeInMonths(range.start, range.end),
          reports.goals
        ),
        projectedOrActual: reports.projectedOrActual.projected,
        total_sellable: this.getTotal(reports, 'cloth', 'misc'),
        total_cloth: this.getTotal(reports, 'cloth'),
        total_misc: this.getTotal(reports, 'misc'),
        total_trash: this.getTotal(reports, 'trash'),
        market_filter: market.id,
      }
    })
  }
  componentDidMount() {
    const {
      getMarketReportsForDashboard,
      setCurrent,
      location: { search },
    } = this.props
    const query = new URLSearchParams(search)

    if (query.get('market')) {
      const selectedMarkets = query.get('market').split(',')

      if (selectedMarkets.length === 1) setCurrent(selectedMarkets[0])
      else setCurrent(null)
    }
    getMarketReportsForDashboard()
  }

  renderLink = ({ market_filter }) => {
    const { match: { url } } = this.props
    return <Link to={`${url}?market=${market_filter}`}>View</Link>
  }

  render() {
    const {
      marketData,
      markets: { loading, selectedMarketsDashboard, range },
      routesData,
    } = this.props

    const {
      total_trash,
      total_cloth,
      total_misc,
      total_bins,
      total_containers,
      avg_per_bin,
      avg_per_container,
      avg_per_location,
      total_goals,
      total_locations,
      sellable_weight,
      projection,
      totalProjectedOrActual,
      previousYearSellableWeight,
      untilTodaySellableWeight,
      untilTodayLastYearSellableWeight,
    } = switchValues(selectedMarketsDashboard, routesData, marketData, {
      filter: range,
    })
    const hiddenCols = !_isNumber(projection) ? ['projection'] : []

    const projectedVsGoalPercentage =
      (totalProjectedOrActual - total_goals) / total_goals * 100
    const sellableLYCompActual = sellable_weight - previousYearSellableWeight
    const sellableLYCompPercentage =
      (sellable_weight - previousYearSellableWeight) /
      previousYearSellableWeight *
      100
    const YtdLYCompPercentage =
      (untilTodaySellableWeight - untilTodayLastYearSellableWeight) /
      untilTodayLastYearSellableWeight *
      100

    return (
      <Segment loading={loading} className="Breakdown__Segment">
        <Row>
          <Col sm={3}>
            <ReportCard
              as={Segment}
              disabled={!_isNumber(totalProjectedOrActual)}
              fluid
              loading={selectedMarketsDashboard && !_isNumber(totalProjectedOrActual)}
              title="Projected/Actual"
              total={
                <FormattedNumber value={parseInt(totalProjectedOrActual, 10)} />
              }
              color="black"
              tooltip={totalProjectedOrActual}
            />
          </Col>
          <Col sm={3}>
            <ReportCard
              fluid
              loading={selectedMarketsDashboard && !_isNumber(sellable_weight)}
              title="Sellable Total"
              total={<FormattedNumber value={sellable_weight} />}
              color="black"
            />
          </Col>
          <Col sm={3}>
            <ReportCard
              fluid
              loading={selectedMarketsDashboard && !_isNumber(total_cloth)}
              title="Total Cloth"
              total={<FormattedNumber value={total_cloth} />}
            />
          </Col>
          <Col sm={3}>
            <ReportCard
              fluid
              loading={selectedMarketsDashboard && !_isNumber(total_misc)}
              title="Total Miscel"
              total={<FormattedNumber value={total_misc} />}
            />
          </Col>
        </Row>
        <Divider hidden />

        <Row>
          <Col sm={2}>
            <ReportCard
              fluid
              loading={selectedMarketsDashboard && !_isNumber(total_bins)}
              title="Total Bins"
              total={<FormattedNumber value={total_bins} />}
              // color="green"
            />
          </Col>
          <Col sm={2}>
            <ReportCard
              disabled={!_isNumber(avg_per_bin)}
              loading={
                (selectedMarketsDashboard && !_isNumber(avg_per_bin)) ||
                !isFinite(avg_per_bin)
              }
              fluid
              title="Avg lbs/bin"
              total={
                <FormattedNumber
                  value={isNaN(avg_per_bin) ? 0 : parseInt(avg_per_bin, 10)}
                />
              }
              // color="red"
              tooltip={avg_per_bin}
            />
          </Col>
          <Col sm={2}>
            <ReportCard
              fluid
              loading={selectedMarketsDashboard && !_isNumber(total_containers)}
              title="Containers"
              total={<FormattedNumber value={total_containers} />}
              // color="green"
            />
          </Col>
          <Col sm={2}>
            <ReportCard
              disabled={!_isNumber(avg_per_container)}
              loading={
                (selectedMarketsDashboard && !_isNumber(avg_per_container)) ||
                !isFinite(avg_per_container)
              }
              fluid
              title="Avg lbs/cont"
              total={
                <FormattedNumber
                  value={
                    isNaN(avg_per_container)
                      ? 0
                      : parseInt(avg_per_container, 10)
                  }
                />
              }
              // color="red"
              tooltip={avg_per_container}
            />
          </Col>
          <Col sm={2}>
            <ReportCard
              fluid
              loading={selectedMarketsDashboard && !_isNumber(total_locations)}
              title="Locations"
              total={<FormattedNumber value={total_locations} />}
              // color="green"
            />
          </Col>
          <Col sm={2}>
            <ReportCard
              disabled={!_isNumber(avg_per_location)}
              loading={selectedMarketsDashboard && !_isNumber(avg_per_location)}
              fluid
              title="Avg lbs/location"
              total={
                <FormattedNumber
                  value={
                    isNaN(avg_per_location) ? 0 : parseInt(avg_per_location, 10)
                  }
                />
              }
              // color="red"
              tooltip={avg_per_location}
            />
          </Col>
        </Row>

        <Divider hidden />
        <Row>
          <Col sm={3}>
            <ReportCard
              as={Segment}
              fluid
              loading={loading}
              title="Market Goal"
              total={<FormattedNumber value={parseInt(total_goals, 10)} />}
              color="black"
              tooltip={total_goals}
            />
          </Col>
          <Col sm={3}>
            <ReportCard
              fluid
              loading={loading}
              title="Projected vs. Goal"
              subtitle={
                isFinite(projectedVsGoalPercentage)
                  ? `${projectedVsGoalPercentage.toFixed(2)}%`
                  : 'N/A'
              }
              total={
                <FormattedNumber
                  value={parseInt(totalProjectedOrActual - total_goals, 10)}
                />
              }
              color={totalProjectedOrActual - total_goals > 0 ? 'green' : 'red'}
            />
          </Col>
          <Col sm={3}>
            <ReportCard
              fluid
              loading={loading}
              title="Sellable LY Comp"
              subtitle={
                isFinite(sellableLYCompPercentage)
                  ? `${sellableLYCompPercentage.toFixed(2)}%`
                  : 'N/A'
              }
              total={
                <FormattedNumber
                  value={`${Number(previousYearSellableWeight)}`}
                />
              }
              tooltip={sellableLYCompActual}
            />
          </Col>
          <Col sm={3}>
            <ReportCard
              fluid
              loading={loading}
              title="YTD LY Comp"
              subtitle={
                isFinite(YtdLYCompPercentage)
                  ? `${YtdLYCompPercentage.toFixed(2)}%`
                  : 'N/A'
              }
              total={
                <FormattedNumber
                  value={parseInt(
                    untilTodaySellableWeight - untilTodayLastYearSellableWeight,
                    10
                  )}
                />
              }
              color={
                untilTodaySellableWeight - untilTodayLastYearSellableWeight > 0
                  ? 'green'
                  : 'red'
              }
            />
          </Col>
        </Row>

        <Divider hidden />

        <Row>
          <Col xs={12} className="CollectionsBreakdown__Segment">
            <Card fluid>
              <Card.Content header="Collections Breakdown" />
              <Card.Content>
                <Row between="sm" className="CircleMetrics__Row">
                  <CircleMetricCol
                    label="Cloth"
                    total={<FormattedNumber value={total_cloth} />}
                    percentage={this.getWeight('cloth') * 100}
                    color="green"
                  />
                  <CircleMetricCol
                    label="Misc"
                    total={<FormattedNumber value={total_misc} />}
                    percentage={this.getWeight('misc') * 100}
                    color="red"
                  />
                  <CircleMetricCol
                    label="Sellable Goods"
                    total={<FormattedNumber value={sellable_weight} />}
                    percentage={this.getWeight('misc', 'cloth') * 100}
                    color="green"
                  />
                  <CircleMetricCol
                    label="Trash"
                    total={<FormattedNumber value={total_trash} />}
                    percentage={this.getWeight('trash') * 100}
                    color="brown"
                    flag={{
                      name: 'flag',
                      color: 'red',
                    }}
                  />
                </Row>
                <Divider hidden />
                <Row>
                  <Col xs={12}>
                    {selectedMarketsDashboard.length === 1 && (
                      <div className="CollectionsBreakdown__Route__Segment">
                        <MarketReport
                          marketId={selectedMarketsDashboard[0]}
                          data={marketData}
                          range={range}
                        />
                      </div>
                    )}
                    {selectedMarketsDashboard.length !== 1 && (
                      <DataTable
                        columns={columns({
                          renderLink: this.renderLink,
                          hidden: hiddenCols,
                        })}
                        data={this.getTableData()}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </Segment>
    )
  }
}
