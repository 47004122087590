import { createSelector } from 'reselect'
import _find from 'lodash/find'
import _get from 'lodash/get'

const _users = state => state.users.users.map(User)
const _search = state => state.users.search
const _currentMarket = state => state.markets.current
const _allUsers = state => state.users.users

export const searchUsers = createSelector(_users, _search, (users, search) => {
  if (!search || search === '') {
    return users
  }
  return users.filter(user =>
    user.name.toLowerCase().includes(search.toLowerCase())
  )
})

export const filterByMarkets = createSelector(
  _allUsers,
  _currentMarket,
  (users, currentMarket) => {
    if (!currentMarket) return users

    return users.filter(user => {
      const market = _find(user.markets, { id: currentMarket })
      if (market) {
        return true
      }
      return false
    })
  }
)

export const User = createSelector(
  state => state,
  user => ({
    ...user,
    name: `${user.first_name} ${user.last_name}`,
    label: `${user.first_name} ${user.last_name}`,
    value: user.id,
  })
)

export const UserDropdownOptions = createSelector(
  data => {
    return _get(data, 'allUsers')
  },
  userDropdownOptions => {
    return {
      userDropdownOptions: userDropdownOptions,
    }
  }
)
