import React, { Fragment } from 'react'
import moment from 'moment'
import { Modal, Label, Grid, Message, Icon, Button } from 'semantic-ui-react'
import { withRuns } from '../../../connectors/runs'
import { WithMutator } from '../../../connectors/mutator'

@withRuns
@WithMutator('deleteRun')
export class RemoveRunConfirmationModal extends React.Component {
  state = {
    open: true,
    error: null,
    removeSuccessfully: false,
  }

  setOpen(open) {
    this.setState({
      open,
    })
  }

  setError(error) {
    this.setState({
      error,
    })
  }

  clearError() {
    this.setState({
      error: null,
    })
  }

  runRemovedSuccessfully() {
    const { onSuccessful, run } = this.props

    this.setState({
      removeSuccessfully: true,
    })

    onSuccessful(run)
  }

  cancel = () => {
    const { onCancelModal } = this.props

    onCancelModal()
    this.clearError()
    this.setOpen(false)
  }

  close = () => {
    const { onCloseModal } = this.props

    onCloseModal()
    this.clearError()
    this.setOpen(false)
  }

  onConfirm = () => {
    const { deleteRun, run } = this.props

    this.clearError()

    deleteRun({ id: `${run.runId}` })
      .then(() => {
        this.runRemovedSuccessfully()
      })
      .catch(error => {
        this.setError(error.message)
      })
  }

  render() {
    const { run, mutating } = this.props
    const { open, error, removeSuccessfully } = this.state

    return (
      <Modal
        onClose={() => this.close()}
        onOpen={() => this.setOpen(true)}
        open={open}
        dimmer={'blurring'}
        size={'tiny'}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Removing Run ...</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {!removeSuccessfully && (
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <p>
                      You are about to delete this run.{' '}
                      <strong>This action is irreversible</strong>.
                      <br />
                      Are you sure you want to proceed?
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <p>
                      <label class="label">Route:</label>
                      <Label
                        circular
                        style={{ backgroundColor: run.route.color }}
                        empty
                      />
                      <span class="label" style={{ color: run.route.color }}>
                        {run.route.name}
                      </span>
                    </p>
                    <p>
                      <label class="label">Driver:</label>
                      {run.driver ? (
                        <span>
                          {run.driver.first_name} {run.driver.last_name}
                        </span>
                      ) : (
                        <span>Not specified</span>
                      )}
                    </p>
                    <p>
                      <label class="label">Driver helper:</label>
                      {run.helper ? (
                        <span>
                          {run.helper.first_name} {run.helper.last_name}
                        </span>
                      ) : (
                        <span>Not specified</span>
                      )}
                    </p>
                    <p>
                      <label class="label">Date:</label>
                      <span>{moment(run.runDate).format('MM/DD/YYYY')}</span>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {error && (
              <Message color="red">
                <Icon name="exclamation triangle" />
                {error}
              </Message>
            )}

            {removeSuccessfully && (
              <Message color="green">
                <Icon name="check" />
                The run has been removed successfully
              </Message>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {removeSuccessfully ? (
            <Button content="Done" onClick={() => this.close()} positive />
          ) : (
            <Fragment>
              <Button
                content="Confirm"
                loading={mutating}
                disabled={mutating}
                onClick={() => this.onConfirm()}
                positive
              />
              <Button
                loading={mutating}
                disabled={mutating}
                color="red"
                onClick={() => this.cancel()}
              >
                Cancel
              </Button>
            </Fragment>
          )}
        </Modal.Actions>
      </Modal>
    )
  }
}

export default RemoveRunConfirmationModal
