import React from 'react'
import _get from 'lodash/get'
import { Route, Switch } from 'react-router-dom'

import { Grid } from 'react-flexbox-grid'
import Location from '../../views/Location'
import LocationsHealth from '../../views/LocationsHealth'
import { SimpleLoading } from '../../components/Loading'

class Locations extends React.PureComponent {
  render() {
    const { loading, match } = this.props

    if (loading) return <SimpleLoading />

    return (
      <Grid>
        <Switch>
          <Route exact path={`${match.path}`} component={LocationsHealth} />
          <Route path={`${match.path}/health`} component={LocationsHealth} />
          <Route
            path={`${match.path}/:locationId`}
            render={({ match }) => (
              <Location locationId={match.params.locationId} />
            )}
          />
        </Switch>
      </Grid>
    )
  }
}

export default props => {
  const locationId = _get(props, 'match.params.locationId')

  return <Locations {...props} locationId={locationId} />
}
