import {
  _USERS_FETCH,
  _USERS_ERROR,
  _USERS_SEARCH,
  _USERS_LOADING,
  _USER_ADDED,
  _USERS_REMOVE,
} from '../actions/users'

const initialState = {
  users: [],
  user: {},
  search: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _USERS_SEARCH:
      return {
        ...state,
        search: action.payload,
      }

    case _USER_ADDED:
      const found = state.users.findIndex(u => u.id === action.payload.id)
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
        users:
          found >= 0
            ? Object.assign([], state.users, { [found]: action.payload })
            : [...state.users, action.payload],
      }

    case _USERS_LOADING:
      return {
        ...state,
        loading: true,
      }

    case _USERS_FETCH:
      return {
        ...state,
        users: action.payload,
        loading: false,
      }
    case _USERS_REMOVE:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
        loading: false,
      }

    case _USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default reducer
