import React from 'react'
import PropTypes from 'prop-types'
import _isArray from 'lodash/isArray'

import { List } from 'semantic-ui-react'
import ModalTrigger from '../ModalTrigger'
import Confirmation from '../Confirmation'
import AddLocationForm from '../../forms/AddLocation'
import { RoutesDropdownMenu } from '../MenuDropdown/Routes'

const LocationOption = ({ text, children, ...props }) => (
  <List.Item {...props}>
    <List.Content>
      {children}
      {!children && <List.Header>{text}</List.Header>}
    </List.Content>
  </List.Item>
)

const MenuLocation = ({
  routeId,
  onEdit,
  onRemove,
  onMoveTo,
  onCopyTo,
  routes,
  locationId,
  isCustom,
  ...location
}) => {
  const locationName = location.name || ''
  const confirmationCopy = {
    title: `Coping Location ${locationName}`,
    message: ({ text }) =>
      `Are you sure to copy the Location ${locationName} to Route ${text}`,
    loadingTitle: 'Coping this Location',
  }
  const confirmationMove = {
    title: `Moving Location ${locationName}`,
    message: ({ text }) =>
      `Are you sure to move the Location ${locationName} to Route ${text}`,
    loadingTitle: 'Moving this Location',
  }
  return (
    <List selection verticalAlign="middle">
      <LocationOption text="Edit">
        <ModalTrigger
          trigger={<List.Header>Edit</List.Header>}
          form={<AddLocationForm locationId={locationId} onSubmit={onEdit} />}
          scrolling={true}
          title={`Location ${locationName}`}
        />
      </LocationOption>
      <LocationOption
        text="Remove"
        {...isCustom && { onClick: () => onRemove(locationId) }}
      >
        {!isCustom && (
          <Confirmation
            trigger={<List.Header>Remove</List.Header>}
            title="Confirm"
            loadingTitle="Removing this Location"
            onConfirm={() => onRemove(routeId, locationId)}
          />
        )}
      </LocationOption>
      {_isArray(routes) && (
        <React.Fragment>
          <LocationOption>
            <RoutesDropdownMenu
              text="Copy to"
              pointing="left"
              className="link item"
              routes={routes}
              confirmation={confirmationCopy}
              onChange={({ value }) => onCopyTo(routeId, locationId, value)}
            />
          </LocationOption>
          <LocationOption>
            <RoutesDropdownMenu
              text="Move to"
              pointing="left"
              className="link item"
              routes={routes}
              confirmation={confirmationMove}
              onChange={({ value }) => onMoveTo(routeId, locationId, value)}
            />
          </LocationOption>
        </React.Fragment>
      )}
    </List>
  )
}
MenuLocation.defaultProps = {
  onRemove: () => {},
  onMoveTo: () => {},
  onCopyTo: () => {},
}
MenuLocation.propTypes = {
  routeId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onRemove: PropTypes.func, // function
  onMoveTo: PropTypes.func, // function
  onCopyTo: PropTypes.func, // function
}

export default MenuLocation
