import React from 'react'

import { Menu } from 'semantic-ui-react'

import WithQuerySearch from '../../connectors/querySearch'

import ContentHeader from '../../components/ContentHeader'
import MarketsDropdown from '../../components/MenuDropdown/Markets'

@WithQuerySearch
class Header extends React.PureComponent {
  render() {
    const { children, search, updateSearch } = this.props

    return (
      <ContentHeader title="Users" showBackButton>
        <MarketsDropdown
          menu
          selected={search.market}
          onSelect={({ id }) =>
            id ? updateSearch({ market: id }) : updateSearch({})
          }
        />
        {children && <Menu.Item>{children}</Menu.Item>}
      </ContentHeader>
    )
  }
}

export default Header
