import React from 'react'
import { Mutation } from 'react-apollo'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isObject from 'lodash/isObject'
import _map from 'lodash/map'
import _concat from 'lodash/concat'
import _merge from 'lodash/merge'
import _pick from 'lodash/pick'

import QL from '../api/Mutation'

const formatError = error => {
  const networkErrors = _get(error, 'networkError.result.errors') // {results: []}
  const graphQLErrors = _get(error, 'graphQLErrors') // []
  const errors = _concat(
    _map(networkErrors, 'message'),
    _map(graphQLErrors, 'message')
  )

  return {
    header: _get(error, 'message'),
    title: _get(error, 'message'),
    description: errors.join(),
    list: _map(errors, message => ({ content: message })),
  }
}

export const WithMutator = (mutateName, ops) => Component => {
  const mutationProps = _isObject(ops) ? ops : {}
  class WrappedComponent extends React.PureComponent {
    pickMutateData = ({ error, data }) => {
      if (error) return Promise.reject(error)

      return _get(data, mutateName)
    }

    mutateProps = mutate => {
      return {
        [mutateName]: variables =>
          mutate({ variables }).then(this.pickMutateData),
      }
    }

    render() {
      if (mutateName) {
        return (
          <Mutation {...mutationProps} mutation={QL[mutateName]}>
            {(mutate, result) => (
              <Component
                {..._merge({}, this.props, _pick(result, ['data', 'called']))}
                {...this.mutateProps(mutate)}
                result={_get(result, `data.${mutateName}`)}
                mutating={_get(result, 'loading')}
                error={_get(result, 'error') && formatError(result.error)}
              />
            )}
          </Mutation>
        )
      }
      if (_isEmpty(mutateName)) {
        return <Component {...this.props} />
      }
    }
  }

  return WrappedComponent
}

export default WithMutator
