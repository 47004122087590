import React from 'react'
import _head from 'lodash/head'
import pdfMake from '../../utils/pdfMake'

import { withAuth } from '../../connectors/auth'
import { withForm } from '../../connectors/forms'
import { withRoutes } from '../../connectors/routes'
import { Button, Message, Icon } from 'semantic-ui-react'

import RouteSheetForm from './form'

export const LoadingMessage = ({ message, title = 'Just one second' }) => (
  <Message icon>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>{title}</Message.Header>
      {message}
    </Message.Content>
  </Message>
)

@withForm('RouteSheetForm')
@withRoutes
@withAuth
export default class Route extends React.PureComponent {
  state = {
    loading: false,
    routeReport: {},
  }
  componentDidMount() {
    this.renderButtons()
  }
  componentWillReceiveProps(nextProps) {
    const { getRoutesByMarket } = this.props
    if (this.props.selector('market') !== nextProps.selector('market'))
      getRoutesByMarket(nextProps.selector('market'))
  }
  onSubmit = args => {
    const { closeModal, getRouteWithLocationHistory } = this.props
    const win = window.open('', '_blank')
    win.document.write(
      '<h1>Loading Route Sheet...</h1><h2>If you have an Ad Blocker, please turn it off for this site</h2>'
    )
    this.setState({ submitting: true })
    return getRouteWithLocationHistory(args.route)
      .then(({ payload }) => {
        const route = payload
        pdfMake.routeSheet(route, { win })
        this.setState({ submitting: false })
        setImmediate(closeModal)
      })
      .catch(error => this.setState({ error, submitting: false }))
  }

  renderButtons() {
    const { renderButtons, closeModal, submitForm } = this.props

    return renderButtons(
      <div>
        <Button negative onClick={closeModal}>
          Nevermind
        </Button>
        <Button
          positive
          labelPosition="right"
          icon="print"
          onClick={submitForm}
          content="Print Sheet"
        />
      </div>
    )
  }

  render() {
    const { error, submitting, history, loadingHistory } = this.state
    const { auth: { user: { markets } } } = this.props

    if (submitting) {
      return <LoadingMessage message="Preparing Route Sheet..." />
    }
    return (
      <div>
        <RouteSheetForm
          key="RouteSheetForm"
          onSubmit={this.onSubmit}
          history={history}
          initialValues={{
            market: _head(markets).id,
          }}
          loadingHistory={loadingHistory}
        />
        {error && (
          <Message error header="Error" key="userError">
            {error.message}
          </Message>
        )}
      </div>
    )
  }
}
