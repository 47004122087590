import React, { useEffect, useState } from 'react'
import { Form, FormGroup, FormRadio } from 'semantic-ui-react'
import './style.scss'

export const RadioSelectField = ({
  options = [],
  defaultValue,
  onChange,
  label,
  error,
  inline,
  ...props
}) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(props.value || defaultValue || '')
    onChange(props.value || defaultValue || '')
  }, [])

  function setCSSClasses({ required }) {
    let classes = 'RadioSelectField__container'

    if (required) {
      classes += ' required'
    }

    return classes
  }

  function renderOptions() {
    return options.map(option => (
      <FormRadio
        label={option.text}
        value={option.id}
        checked={value === option.id}
        onChange={handleChange}
      />
    ))
  }

  const handleChange = (e, { value }) => {
    setValue(value)
    onChange(value)
  }

  return (
    <Form.Field className={setCSSClasses(props)} error={error}>
      <label>{label}</label>
      {inline ? (
        <FormGroup inline>{renderOptions()}</FormGroup>
      ) : (
        renderOptions()
      )}
    </Form.Field>
  )
}
