import _map from 'lodash/map'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'
import _upperCase from 'lodash/upperCase'
import moment from 'moment'
import pdfmake from 'pdfmake/build/pdfmake.js'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfmake.vfs = pdfFonts.pdfMake.vfs

const toNumber = value =>
  typeof value === 'string'
    ? parseFloat(value.replace(',', ''), 10)
    : Number(value)
const currencyFormat = value =>
  toNumber(value).toLocaleString('en-IN', {
    style: 'currency',
    currency: 'USD',
  })
const numberFormat = value => toNumber(value).toLocaleString('en-IN')

function contractSelectionLabel(label) {
  switch (label) {
    case 'Next to ours (within 10 feet)':
      return 'NX'
    case 'Close to ours (within 25 feet)':
      return 'CL'
    case 'Same parking lot':
      return 'SL'
    case 'Across street':
      return 'AS'
    default:
      return label
  }
}

function getCompetitors(data) {
  if (!data.competitors) return ''

  const competitors = data.competitors.map(competitor => {
    const competitorType = competitor.selections
      .map(selection => contractSelectionLabel(selection.label))
      .join(' - ')

    if (competitorType.length > 0)
      return `${competitor.label} (${competitorType})`

    return `${competitor.label}`
  })

  return competitors.join(', \n')
}

const defaultValues = {
  status: '',
  box_code: 'Not Specified',
  square_foo: '',
  value: '',
}
const format = data => name => {
  const value = data[name]
  if (name === 'box_code')
    return _isArray(_get(data, 'bins'))
      ? `${_map(data.bins, bin => bin.box_code || '').join()}`
      : ''

  if (name === 'competitors') {
    return getCompetitors(data)
  }

  if (!value) return defaultValues[name] || ''

  switch (name) {
    case 'box_code':
      return moment(value).format('l')
    case 'square_foo':
      return numberFormat(value)
    case 'value':
      return currencyFormat(value)
    default:
      return value
  }
}
const headerDefinition = ({ locationsCount, binsCount }) => ({
  columns: [
    {
      stack: [
        // second column consists of paragraphs
        'TABLET # ____________',
        `LOCATIONS ${locationsCount}`,
        `BINS      ${binsCount}`,
      ],
      fontSize: 12,
    },
    {
      stack: [
        // second column consists of paragraphs
        'DRIVER ____________',
        'TIME: START ____________',
        'MILES: START ____________',
      ],
      fontSize: 12,
    },
    {
      stack: [
        // second column consists of paragraphs
        'WEATHER ____________',
        'END _________________',
        'END _________________',
      ],
      fontSize: 12,
    },
    {
      stack: [
        // second column consists of paragraphs
        'DATE ____________',
        'TRUCK# ____________',
        'HELPER ____________',
      ],
      fontSize: 12,
    },
    {
      stack: [
        // second column consists of paragraphs
        'CLOTH LBS ____________',
        'MISCEL LBS ____________',
        'TRASH LBS ____________',
      ],
      fontSize: 12,
    },
  ],
})

const tableDefinition = ({ header, title, styles, headerCol, body }) => ({
  header: [
    { text: `${moment().format('MM-DD-YYYY')}`, style: { alignment: 'right' } },
    { text: header, style: 'header' },
  ],
  pageOrientation: 'landscape',
  content: [
    _isFunction(title) ? title() : { text: title, style: 'header' },
    {
      table: {
        headerRows: 1,
        widths: 'auto',
        body,
      },
    },
  ],
  styles,
})
export const table = ({ title, columns, data }) => {
  const locationsCount = !data ? 0 : data.length
  const binsCount = data.reduce((prev, curr) => {
    if (!curr.bins) return prev
    else return prev + curr.bins.length
  }, 0)
  const headerCol = text => ({
    text,
    style: 'headerCol',
  })
  return tableDefinition({
    header: `${_upperCase(title)} ROUTE`,
    title: () => headerDefinition({ locationsCount, binsCount }),
    headerCol,
    body: [
      _map(columns, 'title').map(headerCol),
      ..._map(data, data => _map(_map(columns, 'name'), format(data))),
    ],
    styles: {
      header: {
        fontSize: 22,
        bold: true,
        alignment: 'center',
      },
      headerCol: {
        bold: true,
        alignment: 'center',
      },
    },
  })
}

export const routeSheet = (route, options = {}) => {
  const win = options.win || window.open('', '_blank')
  const columns = [
    { name: 'stop_sequence', title: 'STOP' },
    { name: 'box_code', title: `BIN #'S` },
    { name: 'name', title: 'LOCATION' },
    { name: 'address', title: 'ADDRESS' },
    { name: 'city', title: 'CITY' },
    { name: 'postal', title: 'ZIP' },
    { name: 'status', title: 'STATUS' },
    { name: 'pounds', title: 'COLLECTIONS' },
    { name: 'competitors', title: 'COMPETITORS' },
    { name: 'comments', title: 'COMMENTS' },
  ]
  const docDefinition = table({
    title: route.name,
    columns,
    data: route.locations,
  })
  const pdfDoc = pdfmake.createPdf(docDefinition)
  // Lets print the document
  pdfDoc.print({}, win)
}

export default {
  routeSheet,
  table,
}
