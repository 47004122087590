import PropTypes from 'prop-types'
import React from 'react'
import { Button, Card, List, Message } from 'semantic-ui-react'
import ExistingLocation from '../../forms/ExistingLocation'

import ModalTrigger from '../ModalTrigger'

import './styles.scss'

export default class MarketLocation extends React.PureComponent {
  state = {
    savedLocation: null,
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    location: PropTypes.object,
    editable: PropTypes.bool,
    onEdit: PropTypes.func,
    otherwise: PropTypes.object,
    withBins: PropTypes.bool,
  }

  static defaultProps = {
    editable: true,
    onEdit: () => {},
    location: null,
    otherwise: null,
    withBins: true,
  }

  onEdit = ({ routeId, location }) => {
    const { onEdit } = this.props

    // Remove additional props
    delete location.__typename

    if (!!location.bins) location.bins.forEach(bin => delete bin.__typename)

    return onEdit(location).then(() => {
      this.setState({ savedLocation: location })
    })
  }

  render() {
    const { title, location, editable, options, otherwise } = this.props
    const { savedLocation } = this.state

    let loc = savedLocation || location
    let fromMarket = false

    if (!loc && otherwise) {
      loc = otherwise
      fromMarket = true
    }

    if (loc && options && options.find(l => l.id === loc.name)) {
      loc = options.find(l => l.id === loc.name)
    }

    return (
      <Card className="MarketLocation__Card">
        <Card.Content header={title} />
        <Card.Content>
          <List>
            {editable && (
              <ModalTrigger
                trigger={
                  <List.Content floated="right">
                    <Button icon="pencil" />
                  </List.Content>
                }
                title={title}
                scrolling={true}
                form={
                  <ExistingLocation
                    onSubmit={this.onEdit}
                    location={loc}
                    withBins={false}
                    options={options}
                    simple_locations={true}
                  />
                }
              />
            )}
            {loc && (
              <List.Item>
                <List.Header>
                  {loc.name}
                  {fromMarket && <small>(Market)</small>}
                </List.Header>
                {!loc.address && (
                  <Message size="tiny" warning>
                    No Address for this Location
                  </Message>
                )}
                {loc.address}
              </List.Item>
            )}
            {!loc && (
              <List.Item>
                <List.Icon name="question circle" />
                Set Location
              </List.Item>
            )}
          </List>
        </Card.Content>
      </Card>
    )
  }
}
