import gql from 'graphql-tag'

export const route = `
  id
  name
  bins
  runs
  total_lbs_collected
  average_lbs_collected
  truck_fixed_monthly_cost
  truck_cost_cpp
  total_mileage
  average_mileage
  average_mileage_cost
  average_mileage_cost_cpp
  average_fuel_cost
  average_fuel_cost_cpp
  salary
  salary_cost_cpp
  average_cost_per_run
  average_cost_per_bin
  route_cost_cpp
`

export const routeCostReport = `
  year
  month
  reports {
    market
    runs
    total_truck_lease_payment
    mileage_charge
    price_per_gallon
    average_mpg
    truck_cost_per_run
    routes {
      ${route}
    }
  }
`

export const quarterlyRouteCostReport = `
  year
  months {
    month
    report {
      runs
      total_truck_lease_payment
      mileage_charge
      price_per_gallon
      average_mpg
      truck_cost_per_run
      routes {
        ${route}
      }
    }
  }
`

export const getRouteCostQuery = gql`
  query($markets: [String]!, $year: Int!, $month: String!, $include_previous_year: String) {
    getRouteCost(markets: $markets, year: $year, month: $month, include_previous_year: $include_previous_year) {
      ${routeCostReport}
    }
  }
`

export const getQuarterlyRouteCostQuery = gql`
  query($market: String!, $year: Int!, $quarter: String!, $include_previous_year: String) {
    getQuarterlyRouteCost(market: $market, year: $year, quarter: $quarter, include_previous_year: $include_previous_year) {
      ${quarterlyRouteCostReport}
    }
  }
`

export const getCumulativeQuarterlyReviewRouteCostQuery = gql`
  query($market: String!, $year: Int!, $include_previous_year: String) {
    getCumulativeQuarterlyRouteCost(market: $market, year: $year, include_previous_year: $include_previous_year) {
      ${quarterlyRouteCostReport}
    }
  }
`
