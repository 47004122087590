import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { withRouter } from 'react-router-dom'

import DatePicker from '../../components/DatePicker'
import { withMarkets } from '../../connectors/markets'
import MarketsDropdown from '../../components/MenuDropdown/Markets'
import { withAuth } from '../../connectors/auth'

@withRouter
@withAuth
@withMarkets
export default class Filters extends React.PureComponent {
  state = {
    selectedMarkets: [],
  }

  componentDidMount() {
    this.initSelectedMarkets()
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname, search } } = this.props

    if (
      prevProps.location.pathname === pathname &&
      prevProps.location.search !== search
    ) {
      this.updateSelectedMarkets()
    }
  }

  initSelectedMarkets = () => {
    const {
      location: { search },
      auth: { user },
      setSelectedMarketsForDashboard,
      getMarketReportsForDashboard,
      current,
    } = this.props
    const { markets } = user
    const query = new URLSearchParams(search)
    let marketParam = query.get('market')
    let selectedMarkets = []

    if (marketParam) {
      marketParam = marketParam.split(',')
      selectedMarkets = markets.filter(market =>
        marketParam.some(item => item === market.id)
      )
    } else if (current) {
      selectedMarkets = markets.filter(market => market.id === current.id)
    } else if (user.primary_market) {
      selectedMarkets = markets.filter(
        market => market.id === user.primary_market
      )
    }

    this.setState({
      selectedMarkets,
    })

    this.onMarketChange(selectedMarkets)
    setSelectedMarketsForDashboard(selectedMarkets.map(market => market.id))
    getMarketReportsForDashboard()
  }

  updateSelectedMarkets = () => {
    const {
      location: { search },
      auth: { user },
      setSelectedMarketsForDashboard,
      getMarketReportsForDashboard,
      setCurrent,
    } = this.props
    const { markets } = user
    const query = new URLSearchParams(search)
    let marketParam = query.get('market')
    let selectedMarkets = []

    if (marketParam) {
      marketParam = marketParam.split(',')
      selectedMarkets = markets.filter(market =>
        marketParam.some(item => item === market.id)
      )

      if (marketParam.length === 1) setCurrent(marketParam[0])
    }

    this.setState({
      selectedMarkets,
    })

    this.onMarketChange(selectedMarkets)
    setSelectedMarketsForDashboard(selectedMarkets.map(market => market.id))
    getMarketReportsForDashboard()
  }

  onMarketChange = values => {
    const {
      match: { url },
      location: { search },
      history,
      setCurrent,
    } = this.props
    const query = new URLSearchParams(search)
    let selectedValues = []
    const selectedMarkets = []

    if (values.includes('all')) {
      selectedValues = []
    } else {
      selectedValues = values
    }

    selectedValues.forEach(value => {
      selectedMarkets.push(value.id)
    })

    this.setState({
      selectedMarkets: selectedValues,
    })

    if (selectedMarkets.length === 1) {
      setCurrent(selectedMarkets[0].id)
    }

    if (selectedMarkets.length > 0) {
      query.set('market', selectedMarkets.join(','))
    } else {
      query.delete('market')
    }

    history.push(`${url}?${query.toString()}`)
  }

  setRange = range => {
    const { setCurrentRange, getMarketReportsForDashboard } = this.props

    setCurrentRange(range)
    getMarketReportsForDashboard()
  }

  render() {
    const { markets: { loading, range } } = this.props
    const { selectedMarkets } = this.state

    return (
      <Row start="xs">
        <Col xs={6} md={3}>
          <DatePicker
            value={range}
            onChange={this.setRange}
            disabled={loading}
          />
        </Col>
        <Col xs={6} md={9}>
          <MarketsDropdown
            selected={selectedMarkets}
            onSelect={market => this.onMarketChange(market)}
            multiple
          />
        </Col>
      </Row>
    )
  }
}
