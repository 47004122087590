import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as competitorsActions from '../actions/competitors'

export const WithCompetitorActions = WrappedComponent => {
  return connect(
    _ => ({}),
    dispatch => bindActionCreators(competitorsActions, dispatch)
  )(WrappedComponent)
}

export const WithCompetitors = WrappedComponent => {
  return connect(state => ({
    ...state.competitors,
  }))(WrappedComponent)
}
