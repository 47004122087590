import apolloClient from '../../api/apolloClient'
import { withMarketData, withMarkets } from '../../connectors/markets'
import { withRoutes } from '../../connectors/routes'
import React from 'react'
import 'url-search-params-polyfill'
import { generateRangeDate } from '../../utils/date'

import { Divider } from 'semantic-ui-react'
import { saveGoals as saveGoalsMutation } from '../../api/Mutation/goals'
import Filters from './Filters'
import MonthlyGoalForm from './MonthlyGoalEntryTable'

@withMarkets
@withMarketData
@withRoutes
export default class ReportsMonthlyGoalEntry extends React.PureComponent {
  state = {
    savingGoals: false,
    savedSuccessfully: false,
    errors: [],
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps
    const {
      getRouteReports,
      getRoutesByMarket,
      getMarketReports,
      setCurrent,
      location: { search: oldSearch },
    } = this.props
    const query = new URLSearchParams(search)
    const oldQuery = new URLSearchParams(oldSearch)

    const getRange = _ => {
      const range = generateRangeDate(query.get('range'))
      return {
        start: range.start.toISOString(),
        end: range.end.toISOString(),
      }
    }
    const market = query.get('market')
    if (
      market &&
      query.get('route') &&
      query.get('range') &&
      search !== oldSearch
    ) {
      getRouteReports(query.get('route'), getRange())
    } else if (market && query.get('range') && search !== oldSearch) {
      getMarketReports({
        ids: [market],
        ...getRange(),
      })
    }
    if (market && market !== oldQuery.get('market')) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  componentDidMount() {
    const {
      getMarkets,
      getRoutesByMarket,
      setCurrent,
      location: { search },
    } = this.props

    getMarkets()

    const query = new URLSearchParams(search)
    const market = query.get('market')
    if (market) {
      setCurrent(market)
      getRoutesByMarket(market)
    }
  }

  saveGoals = values => {
    const { location: { search } } = this.props
    const query = new URLSearchParams(search)
    const market = query.get('market')
    const year = query.get('year')

    Object.keys(values.marketGoals).forEach(
      month => (values.marketGoals[month] = Number(values.marketGoals[month]))
    )

    this.setState({
      savingGoals: true,
      savedSuccessfully: false,
      errors: [],
    })

    apolloClient
      .mutate({
        mutation: saveGoalsMutation,
        variables: {
          marketId: market,
          year: year,
          marketGoals: values.marketGoals,
        },
      })
      .then(() => {
        this.setState({
          savedSuccessfully: true,
        })
      })
      .catch(error => {
        this.setState({
          errors: [error.message],
        })
      })
      .finally(() => {
        this.setState({
          savingGoals: false,
          savedSuccessfully: false,
        })
      })
  }

  render() {
    const { location: { search } } = this.props
    const { savingGoals, savedSuccessfully, errors } = this.state
    const query = new URLSearchParams(search)
    const selectedMarkets = query.get('selected-markets')
      ? query.get('selected-markets').split(',')
      : []

    return (
      <div>
        <Divider hidden />
        <Filters loading={savingGoals} />
        <Divider hidden />
        <MonthlyGoalForm
          marketId={query.get('market')}
          selectedMarkets={selectedMarkets}
          year={query.get('year')}
          onSubmit={this.saveGoals}
          savingGoals={savingGoals}
          savedSuccessfully={savedSuccessfully}
          errors={errors}
        />
      </div>
    )
  }
}
