import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Container, Grid, Image, Segment } from 'semantic-ui-react'

import { withAuth } from '../../connectors/auth'

import Loading from '../../components/Loading'

import LoginForm from '../../forms/Login'
import ForgotPasswordForm from '../../forms/ForgotPassword'

import styles from './styles'
import logo from '../../assets/logo-full-color-218x79.png'

@withAuth
export default class AuthLayout extends React.PureComponent {
  render() {
    const { auth } = this.props

    if (!auth.loaded) {
      return <Loading />
    }

    if (auth.user) {
      return <Redirect to="/app/dashboard" />
    }

    return (
      <Grid verticalAlign="middle" style={styles.container}>
        <Grid.Column>
          <Container>
            <Segment padded="very">
              <Image src={logo} style={styles.logo} />
              <Switch>
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPasswordForm}
                />
                <Route path="/auth/login" component={LoginForm} />
              </Switch>
            </Segment>
          </Container>
        </Grid.Column>
      </Grid>
    )
  }
}
