import * as QL from '../api/Query/goals'
import { has } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as goalsActions from '../actions/goals'
import WithQuery from './query'

export const WithGoals = WithQuery({
  selector: data => ({ data }),
  QL,
  queryName: 'goals',
  variablesPicker: props => {
    const { marketId, year, selectedMarkets } = props

    if (!marketId || !year) {
      return null
    }

    if (marketId === 'multiple-markets' && !selectedMarkets.length) {
      return null
    }

    if (selectedMarkets.length)
      return {
        markets: selectedMarkets,
        year,
      }
    else
      return {
        markets: [marketId],
        year,
      }
  },
  fetchPolicy: 'network-only',
})

export const WithActualVsGoals = WithQuery({
  selector: data => ({ data }),
  QL,
  queryName: 'actualVsGoal',
  variablesPicker: props => {
    const { location: { search } } = props
    const markets = []

    const query = new URLSearchParams(search)
    const selectedMarkets = query.get('selected-markets')
      ? query.get('selected-markets').split(',')
      : []
    const marketId = query.get('market')
    const year = query.get('year')

    if (!marketId || !year) return false

    if (marketId === 'multiple-markets' && selectedMarkets.length === 0)
      return false

    if (marketId === 'multiple-markets') {
      selectedMarkets.forEach(market => markets.push(market))
    } else {
      markets.push(marketId)
    }

    return { markets, year }
  },
  fetchPolicy: 'network-only',
})

export const WithPreviousYearComparison = WithQuery({
  selector: data => ({ data }),
  QL,
  queryName: 'previousYearComparison',
  variablesPicker: props => {
    const { location: { search } } = props

    const query = new URLSearchParams(search)
    const year = query.get('year')
    const marketId = query.get('market')

    if (!year) {
      return null
    }
    return {
      marketId: marketId || '',
      year,
    }
  },
  fetchPolicy: 'network-only',
})

export const WithGoalsActions = WrappedComponent =>
  connect(null, dispatch => bindActionCreators(goalsActions, dispatch))(
    WrappedComponent
  )

export const WithGoalsForCraReport = WithQuery({
  selector: data => {
    if (has(data, 'goals'))
      return {
        goals: data.goals[0],
      }
    else return {}
  },
  QL,
  queryName: 'goals',
  variablesPicker: props => {
    const { location: { search } } = props

    const query = new URLSearchParams(search)
    const year = query.get('year')
    const marketId = query.get('market')

    if (!marketId || !year) {
      return null
    }

    return {
      markets: [marketId],
      year,
    }
  },
  fetchPolicy: 'network-only',
})
