import React from 'react'
import _maxBy from 'lodash/maxBy'
import _minBy from 'lodash/minBy'
import _has from 'lodash/has'
import _map from 'lodash/map'
import _filter from 'lodash/filter'

import { Map, TileLayer, Marker, Polyline, Popup, Tooltip } from 'react-leaflet'
import { divIcon } from 'leaflet'

import 'leaflet/dist/leaflet.css'
import './styles.scss'

const roleIcons = {
  start: 'truck',
  end: 'flag',
  unloading: 'law',
  selected: 'pin',
  exist: 'thumbs up',
}
const pickIcon = role => roleIcons[role]

const genKey = ({ id, type, role, color }) => `${id}-${role}-${color}`

const LocationMarker = ({
  id,
  type,
  position,
  role,
  color,
  latitude,
  longitude,
  setCurrentLocation,
  allocation,
  children,
  index,
  ...props
}) => {
  if (role)
    return (
      <Marker
        {...props}
        position={[latitude, longitude]}
        icon={divIcon({
          className: `Map__marker Location__${role}`,
          html: `<span></span><i class='icon large ${pickIcon(role)}'/>`,
          iconSize: [30, 30],
        })}
      >
        {children}
      </Marker>
    )
  else
    return (
      <Marker
        {...props}
        position={[latitude, longitude]}
        onMouseover={() => setCurrentLocation(id)}
        onMouseout={() => setCurrentLocation(null)}
        icon={divIcon({
          className: 'Map__marker',
          html: `<span style="background: ${color}"><span>${allocation ||
            index}<span></span>`,
          iconSize: [30, 30],
        })}
      >
        {children}
      </Marker>
    )
}

const isValidMarker = location =>
  _has(location, 'latitude') && _has(location, 'longitude')

const getBounds = markers => {
  if (!markers.length) {
    return null
  }

  return [
    [
      (_maxBy(markers, 'latitude') || {}).latitude,
      (_maxBy(markers, 'longitude') || {}).longitude,
    ],
    [
      (_minBy(markers, 'latitude') || {}).latitude,
      (_minBy(markers, 'longitude') || {}).longitude,
    ],
  ]
}

export default class MapMarker extends React.Component {
  static defaultProps = {
    start: null,
    end: null,
    location: [],
    markerColor: '#000',
  }

  setCurrentLocation = location => {
    const { onLocationHover } = this.props
    if (!onLocationHover) {
      return
    }

    onLocationHover(location)
  }

  handleClick = location => {
    const { onChange } = this.props

    onChange && onChange(location)
  }

  render() {
    const {
      locations,
      traces,
      renderLocationPopup,
      renderLocationTooltip,
    } = this.props

    const markers = _filter(locations, isValidMarker)

    const bounds = getBounds(markers)

    return (
      <div className="Map__container">
        <Map bounds={bounds ? bounds : undefined}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution=""
          />
          {markers &&
            _map(markers, (location, i) => (
              <LocationMarker
                {...location}
                onClick={() => this.handleClick(location)}
                key={genKey(location)}
                setCurrentLocation={this.setCurrentLocation}
                index={i}
              >
                {location.renderPopup !== false &&
                  renderLocationPopup && (
                    <Popup>{renderLocationPopup(location)}</Popup>
                  )}
                {location.renderTooltip !== false &&
                  renderLocationTooltip && (
                    <Tooltip>{renderLocationTooltip(location)}</Tooltip>
                  )}
              </LocationMarker>
            ))}

          {traces &&
            _map(traces, ({ color, polylines }, i) => (
              <Polyline key={i} color={color} positions={polylines} />
            ))}
        </Map>
      </div>
    )
  }
}
