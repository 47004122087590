import React from 'react'
import { Dropdown, Grid } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { get as _get } from 'lodash'

import { withRoutes } from '../../connectors/routes'

import MarketsDropdown from '../../components/MenuDropdown/Markets'
import DateRangePicker from '../../components/DateRangePicker'
import { locationNPOOptions, statusOptions } from '../../forms/AddLocation/options'

const Filters = withRouter(
  withRoutes(
    ({
      match: { url },
      location: { search },
      history,
      routes: { loading, routes },
      pms = [],
      reportType = 'leased',
    }) => {
      const query = new URLSearchParams(search)

      function updateQuery(name, value) {
        if (value) {
          query.set(name, value)
          history.push(`${url}?${query.toString()}`)
        }
      }

      function resetMarket() {
        query.delete('market')
        query.delete('route')
        history.push(`${url}?${query.toString()}`)
      }

      function setDateRange(start, end) {
        query.set('start', start)
        query.set('end', end)
        history.push(`${url}?${query.toString()}`)
      }

      function resetDateRange() {
        query.delete('start')
        query.delete('end')
        history.push(`${url}?${query.toString()}`)
      }

      function setLeaseDateRange(start, end) {
        query.set('leaseStart', start)
        query.set('leaseEnd', end)
        history.push(`${url}?${query.toString()}`)
      }

      function resetLeaseDateRange() {
        query.delete('leaseStart')
        query.delete('leaseEnd')
        history.push(`${url}?${query.toString()}`)
      }

      function dropFilter(name) {
        query.delete(name)
        history.push(`${url}?${query.toString()}`)
      }

      return (
        <Grid className="Reports__Filters">
          <Grid.Column mobile={16} computer={4}>
            <MarketsDropdown
              activeLabel={false}
              label="All Markets"
              showAll
              selected={query.get('market')}
              onSelect={market => {
                if (!market) {
                  resetMarket()
                } else {
                  updateQuery('market', _get(market, 'id'))
                }
              }}
            />
          </Grid.Column>

          <Grid.Column mobile={16} computer={4} key="col1">
            <Dropdown
              fluid
              defaultValue={query.get('route')}
              value={query.get('route')}
              selection
              options={
                routes && [
                  { text: 'All Routes', value: null },
                  ...routes.map(r => ({
                    text: r.name,
                    value: r.id,
                  })),
                ]
              }
              placeholder="Select Route"
              loading={loading}
              disabled={loading || !routes.length || !query.get('market')}
              onChange={(e, { value }) => {
                if (value) {
                  updateQuery('route', value)
                } else {
                  dropFilter('route')
                }
              }}
            />
          </Grid.Column>

          {reportType !== 'permit' ? (
            <Grid.Column mobile={16} computer={4} key="col2">
              <Dropdown
                fluid
                selection
                defaultValue={query.get('pm')}
                value={query.get('pm')}
                options={[{ text: 'All PMs', value: null }, ...pms]}
                placeholder="Select PM"
                disabled={false}
                onChange={(e, { value }) => {
                  if (value) {
                    updateQuery('pm', value)
                  } else {
                    dropFilter('pm')
                  }
                }}
              />
            </Grid.Column>
          ) : null}

          <Grid.Column mobile={16} computer={4} key="col3">
            <Dropdown
              fluid
              selection
              defaultValue={query.get('status')}
              value={query.get('status')}
              options={[
                { text: 'All Categories', value: null },
                ...statusOptions,
              ]}
              placeholder="Select Category"
              disabled={false}
              onChange={(e, { value }) => {
                if (value) {
                  updateQuery('status', value)
                } else {
                  dropFilter('status')
                }
              }}
            />
          </Grid.Column>

          <Grid.Column mobile={16} computer={4} key="col4">
            <Dropdown
              fluid
              selection
              defaultValue={query.get('npo')}
              value={query.get('npo')}
              options={[
                { text: 'All NPOs', value: null },
                ...locationNPOOptions,
              ]}
              placeholder="Select NPO"
              disabled={false}
              onChange={(e, { value }) => {
                if (value) {
                  updateQuery('npo', value)
                } else {
                  dropFilter('npo')
                }
              }}
            />
          </Grid.Column>

          <Grid.Column mobile={16} computer={4} key="col5">
            <DateRangePicker
              placeholder="Date Range"
              defaultValue={
                query.get('start')
                  ? [new Date(query.get('start')), new Date(query.get('end'))]
                  : []
              }
              hoverRange="month"
              ranges={[]}
              onClean={() => {
                resetDateRange()
              }}
              onOk={([start, end]) => {
                if (start && end) {
                  setDateRange(start, end)
                } else {
                  resetDateRange()
                }
              }}
            />
          </Grid.Column>

          {reportType === 'leased' ? (
            <Grid.Column mobile={16} computer={4} key="col5">
              <DateRangePicker
                placeholder="Lease Period"
                defaultValue={
                  query.get('leaseStart')
                    ? [
                        new Date(query.get('leaseStart')),
                        new Date(query.get('leaseEnd')),
                      ]
                    : []
                }
                ranges={[]}
                onClean={() => {
                  resetLeaseDateRange()
                }}
                onOk={([start, end]) => {
                  if (start && end) {
                    setLeaseDateRange(start, end)
                  } else {
                    resetLeaseDateRange()
                  }
                }}
              />
            </Grid.Column>
          ) : null}
        </Grid>
      )
    }
  )
)

export default Filters
