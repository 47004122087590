import _get from 'lodash/get'
import _map from 'lodash/map'

import * as QL from '../api/Query/reportTags'
import WithQuery from './query'

export const WithReportTags = WithQuery({
  selector: data => ({ reportTags: _get(data, 'reportsDictionary') }),
  QL,
  queryName: 'getReportTags',
  variablesPicker: props => {
    const { shouldNotLoadReportTagsDictionary } = props

    if (shouldNotLoadReportTagsDictionary) {
      return null
    }

    return { name: 'all' }
  },
})

export const WithTagsByMarketsAndDates = WithQuery({
  selector: data => ({
    tagsByMarketsAndDates: _get(data, 'tagsByMarketsAndDates'),
  }),
  QL,
  queryName: 'getTagsByMarketsAndDates',
  variablesPicker: props => {
    const { auth: { user: { markets } }, endDate, marketId, startDate } = props
    let marketIds = []

    // Single Market Id
    if (typeof marketId === 'string' || !marketId) {
      if (!!marketId) {
        marketIds = [marketId]
      } else {
        marketIds = _map(markets, market => market.id)
      }
    } else {
      // Multiple Market Ids
      if (marketId.length === 0) {
        marketIds = _map(markets, market => market.id)
      } else {
        marketIds = [...marketId]
      }
    }

    return { marketIds, startDate, endDate }
  },
})
