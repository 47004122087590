import React from 'react'
import { Query } from 'react-apollo'

import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _map from 'lodash/map'

import { getRoutes as getRoutesQL } from '../api/Query/routes'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/routes'
import {
  getCurrentReportData,
  getReportData,
  prepareRoutes,
  queryRoutes,
  showInactiveRoutesStatus,
  withReport,
  withAllReport,
} from '../selectors/routes'

export const withRoutesReport = WrappedComponent =>
  connect(
    state => ({
      routes: withReport(state),
      currentRouteData: getCurrentReportData(state),
    }),
    dispatch => bindActionCreators(actions, dispatch)
  )(WrappedComponent)

export const withAllRoutesReport = WrappedComponent =>
  connect(
    state => ({
      routes: withAllReport(state),
      currentRouteData: getCurrentReportData(state),
    }),
    dispatch => bindActionCreators(actions, dispatch)
  )(WrappedComponent)

export const withRoutes = WrappedComponent =>
  connect(
    state => ({
      routes: prepareRoutes(state),
    }),
    dispatch => bindActionCreators(actions, dispatch)
  )(WrappedComponent)

export const withRoutesActions = WrappedComponent =>
  connect(null, dispatch => bindActionCreators(actions, dispatch))(
    WrappedComponent
  )

export const withRoutesData = WrappedComponent =>
  connect(state => ({
    routesData: getReportData(state),
  }))(WrappedComponent)

const getRouteIds = props => {
  const route = _get(props, 'route')
  const routes = _get(props, 'routes')

  if (_isString(route)) return [route]
  else if (_isObject(route)) return [_get(route, 'routeId', _get(route, 'id'))]
  if (_isArray(routes))
    return _map(
      routes,
      route =>
        _isObject(route) ? _get(route, 'routeId', _get(route, 'id')) : route
    )
}

export const WithRoutes = Component => {
  class WrappedComponent extends React.Component {
    render() {
      const { routeId, route, routes, ...rest } = this.props
      const ids = routeId ? [routeId] : getRouteIds({ route, routes })

      return (
        <Query query={getRoutesQL} variables={{ ids }}>
          {({ loading, error, data }) => (
            <Component
              error={error}
              loading={loading}
              {...queryRoutes(data, 'routesById')}
              {...rest}
            />
          )}
        </Query>
      )
    }
  }

  return WrappedComponent
}

export const withShowInactiveRoutes = WrappedComponent =>
  connect(state => ({
    showInactiveRoutesStatus: showInactiveRoutesStatus(state),
  }))(WrappedComponent)
