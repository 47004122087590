import { withMarketData, withMarkets } from '../../connectors/markets'
import { withRoutes } from '../../connectors/routes'
import React from 'react'
import 'url-search-params-polyfill'

import { Divider } from 'semantic-ui-react'
import { WithPreviousYearComparison } from '../../connectors/goals'
import Filters from './Filters'
import PreviousYearComparisonReport from './PreviousYearComparisonReport'

@withMarkets
@withMarketData
@withRoutes
@WithPreviousYearComparison
export default class ReportsPreviousYearComparisonIndex extends React.PureComponent {
  extractRoutes() {
    const { data } = this.props
    const routes = []

    if (!data) return routes

    if (!data.previousYearComparison) return routes

    if (
      data.previousYearComparison.length === 0 ||
      data.previousYearComparison.length > 1
    )
      return routes

    data.previousYearComparison[0].yearReports.forEach(report => {
      if (report.routes)
        report.routes.forEach(reportRoute => {
          if (!routes.some(route => route.id === reportRoute.id))
            routes.push({
              id: reportRoute.id,
              name: reportRoute.name,
            })
        })
    })

    return routes
  }

  render() {
    const { location: { search }, loading, data } = this.props

    const query = new URLSearchParams(search)
    const routes = this.extractRoutes()

    return (
      <div>
        <Divider hidden />
        <Filters loading={loading} routes={routes} />
        <Divider hidden />
        <PreviousYearComparisonReport
          routes={routes}
          loading={loading}
          year={query.get('year')}
          data={data}
          reportType={query.get('type')}
          routeId={query.get('route')}
        />
      </div>
    )
  }
}
