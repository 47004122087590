import gql from 'graphql-tag'
import { RouteLocation } from './routes'

export const routesByMarket2 = gql`
  query($marketId: String!) {
    routesByMarket(marketId: $marketId) {
      id
      name
      color
      market {
        id
      }
    }
  }
`

export const routesByMarket = gql`
  query($marketId: String!) {
    routesByMarket(marketId: $marketId) {
      id
      name
      color
      locations {${RouteLocation}}
      trace
      market {
        id
      }
      nextRun {
        run_date
      }
      inactive
    }
  }
`

export const routeWithReports = gql`
  query($routeId: String!, $start: String!, $end: String!) {
    route(id: $routeId) {
      id
      name
      color
      locations {
        id
        bins {
          id
        }
      }
      market {
        id
      }
      reports(start: $start, end: $end) {
        totals {
          cloth
          misc
          trash
        }
        reportList {
          cloth
          misc
          trash
          at
          name
        }
      }
    }
  }
`

export const routesWithReports = gql`
  query($marketId: String!, $start: String!, $end: String!) {
    routesByMarket(marketId: $marketId) {
      id
      name
      color
      locations {
        id
        bins {
          id
        }
      }
      market {
        id
      }
      projection
      reports(start: $start, end: $end) {
        totals {
          cloth
          misc
          trash
        }
      }
      inactive
      activation_history {
        type
        at
      }
      goals(start: $start, end: $end)
    }
  }
`
