import React from 'react'
import Geosuggest from 'react-geosuggest'
import { Form } from 'semantic-ui-react'

import './styles.scss'

export class LocationInput extends React.PureComponent {
  normalizeLocation = ({ gmaps, location, label } = {}) => {
    if (!gmaps) {
      return null
    }

    const ret = gmaps.address_components.reduce((ret, item) => {
      item.types.forEach(type => {
        ret[type] = item.long_name
      })

      return ret
    }, location)

    return {
      name: ret.name,
      city: ret.locality || ret.political,
      address: [ret.street_number, ret.route].join(' '),
      state: ret.administrative_area_level_1 || ret.political,
      postal: ret.postal_code,
      latitude: ret.lat,
      longitude: ret.lng,
    }
  }

  onChange = location => {
    const { onChange } = this.props
    onChange(this.normalizeLocation(location))
  }
  componentWillReceiveProps(nextProps) {
    const { address } = this.props
    if (nextProps.address !== address) {
      this.refs.Geosuggest.update(nextProps.address)
      setTimeout(_ => {
        this.refs.Geosuggest.selectSuggest()
      }, 500)
    }
  }
  render() {
    const { onChange, ...props } = this.props
    return (
      <Geosuggest
        onSuggestSelect={this.onChange}
        className="Geosuggest__container input"
        ref="Geosuggest"
        {...props}
      />
    )
  }
}

export default class LocationField extends React.PureComponent {
  render() {
    const { label, value, ...props } = this.props
    return (
      <Form.Field>
        {label && <label>{label}</label>}
        <LocationInput {...props} />
      </Form.Field>
    )
  }
}
