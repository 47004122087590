import './styles.scss'
import React from 'react'
import { Pagination } from 'semantic-ui-react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import BinCard from '../BinCard'

// Remove routes (colors) of Test_Market from bin to preventing display them on the bin card
function removeTestMarketRoutesFromBins(bin) {
  const TestMarketId = '-L86Q7JvnDuRU6YRBk0I'

  if (bin.routes.length > 0) {
    bin.routes = bin.routes.filter(route => route.market !== TestMarketId)
  }

  return bin
}

class BinGrid extends React.PureComponent {
  state = {
    activePage: 1,
    size: 20,
  }

  onPageChange(_event, data) {
    this.setState({ activePage: data.activePage })
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.bins) !== JSON.stringify(this.props.bins)) {
      const { size, activePage } = this.state
      const lastPageNumber = Math.ceil(this.props.bins.length / size)

      if (lastPageNumber < activePage)
        this.setState({ activePage: lastPageNumber })
    }
  }

  render() {
    const { bins } = this.props
    const { activePage, size } = this.state

    let paginated = []

    if (bins) {
      let pageNumber = activePage

      if (activePage > 0) {
        pageNumber = pageNumber - 1
      }

      paginated = bins.slice(pageNumber * size, (pageNumber + 1) * size)
    }

    return (
      <Grid className="BinGrid">
        {paginated.length < 1 && (
          <div style={{ textAlign: 'center' }}>
            <h2>There are no bins yet.</h2>
          </div>
        )}

        <Row start="xs" className="BinGrid_row">
          {paginated.length > 0 &&
            paginated.map(bin => (
              <Col xs={6} sm={4} md={3} key={bin.id}>
                <BinCard data={removeTestMarketRoutesFromBins(bin)} />
              </Col>
            ))}
        </Row>

        <Row>
          {paginated.length > 0 && (
            <Pagination
              className="BinGrid_pagination"
              onPageChange={this.onPageChange.bind(this)}
              defaultActivePage={activePage}
              totalPages={Math.ceil(bins.length / size)}
            />
          )}
        </Row>
      </Grid>
    )
  }
}

export default BinGrid
