import moment from 'moment'
import React from 'react'
import { Form, Input } from 'semantic-ui-react'

const DateField = ({ label, required, ...props }) => {
  const inputProps = { ...props }

  if (inputProps.value) {
    inputProps.value = moment(inputProps.value)
    inputProps.value = inputProps.value.format()
    inputProps.value = inputProps.value.split('T')[0]
  }

  let classes = 'ui input'

  if (inputProps.disabled) {
    classes = 'ui disabled input'
  }

  return (
    <Form.Field required {...inputProps}>
      <label>{label}</label>
      <div className={classes}>
        <Input {...inputProps} />
      </div>
    </Form.Field>
  )
}

export default DateField
