import _get from 'lodash/get'
import _has from 'lodash/has'
import _isEqual from 'lodash/isEqual'
import _isNil from 'lodash/isNil'
import _omitBy from 'lodash/omitBy'
import moment from 'moment'
import React from 'react'
import { Button, Form, Grid } from 'semantic-ui-react'

import { FieldDateDropdown } from '../../components/DateDropdown'
import DatePicker from '../../components/DatePicker'
import MarketsDropdown from '../../components/MenuDropdown/Markets'
import ReportFilteredTagsInput from '../../components/ReportFilteredTagsInput'
import WithQuerySearch from '../../connectors/querySearch'
import { generateRangeDate } from '../../utils/date'

import { withAuth } from '../../connectors/auth'
import { withMarkets } from '../../connectors/markets'
@withAuth
@withMarkets
@WithQuerySearch
export default class SearchBar extends React.PureComponent {
  state = {
    binTags: null,
    isInitialLoad: true,
    currentMrket: null,
    marketId: null,
    selectedMarketIds: [],
    selectedMarkets: [],
    maintenance_report_marketIds: [],
    taggedDate: {
      named: 'this-week',
    },
    taggedEndDate: null,
    taggedStartDate: null,
    useQuery: true,
  }

  pushCurrentMarketToSelectedMarkets() {
    const { current } = this.props
    const { selectedMarkets } = this.state
    let tempSelectedMarkets = [...selectedMarkets]

    if (selectedMarkets.some(market => current.id === market.id) === false) {
      tempSelectedMarkets = [current, ...selectedMarkets]
    }

    const selectedMarketIds = tempSelectedMarkets.map(market => market.id)

    this.setState({
      selectedMarkets: tempSelectedMarkets,
      selectedMarketIds,
      currentMrket: current,
    })
  }

  componentDidMount() {
    const {
      current,
      history,
      match: { url },
      search,
      setCurrent,
      auth: { user: { primary_market } },
    } = this.props
    const query = new URLSearchParams(search)
    const marketId = query.get('marketId')

    if (!!marketId) {
      setCurrent(marketId)
    } else if (!!current) {
      query.set('marketId', current.id)
      history.push(`${url}?${query.toString()}`)

      this.setState({
        marketId: current.id,
      })
    } else if (!!primary_market) {
      query.set('marketId', primary_market)
      history.push(`${url}?${query.toString()}`)

      this.setState({
        marketId: primary_market,
      })
    }

    this.mapQueryStringValuesToState(search)
  }

  componentWillReceiveProps(nextProps) {
    const { search } = this.props
    const { search: nextSearch } = nextProps

    if (!_isEqual(search, nextSearch)) {
      this.mapQueryStringValuesToState(nextSearch)
    }
  }

  componentDidUpdate() {
    const { current } = this.props
    const { currentMrket } = this.state

    if (current && !currentMrket) {
      this.pushCurrentMarketToSelectedMarkets()
    }
  }

  mapQueryStringValuesToState(search) {
    const { auth: { user: { markets } } } = this.props

    const binTags = JSON.parse(_get(search, 'bin_tags', 'null'))
    const marketId = _get(search, 'marketId')
    const marketIds = _get(search, 'marketIds')

    const taggedDate = JSON.parse(
      _get(search, 'tagged_date', '{"named":"this-week"}')
    )

    const taggedStartDateISOString = _get(search, 'tagged_start_date', null)
    const taggedEndDateISOString = _get(search, 'tagged_end_date', null)
    let taggedStartDate, taggedEndDate

    if (!!taggedStartDateISOString) {
      taggedStartDate = moment(taggedStartDateISOString).utc()
    } else {
      taggedStartDate = moment().utc()
    }
    if (!!taggedEndDateISOString) {
      taggedEndDate = moment(taggedEndDateISOString).utc()
    } else {
      taggedEndDate = moment().utc()
    }

    // Beign process marketIds
    const { selectedMarketIds } = this.state

    let tempSelectedMarketIds = [...selectedMarketIds]

    if (marketIds) tempSelectedMarketIds = marketIds.split(',')

    if (marketId)
      if (tempSelectedMarketIds.some(id => id === marketId) === false)
        tempSelectedMarketIds = [marketId, ...tempSelectedMarketIds]

    const tempSelectedMarkets = markets.filter(market =>
      tempSelectedMarketIds.includes(market.id)
    )
    // End process marketIds

    this.setState({
      binTags,
      isInitialLoad: false,
      marketId,
      selectedMarketIds: tempSelectedMarketIds,
      selectedMarkets: tempSelectedMarkets,
      taggedDate,
      taggedEndDate,
      taggedStartDate,
    })
  }

  setFormValue = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    })
  }

  setDate = (stateKey, date) => {
    let value
    if (stateKey === 'taggedStartDate') {
      value = date.utc().startOf('day')
    } else {
      value = date.utc().endOf('day')
    }
    this.setFormValue(stateKey, value)
  }

  search = () => {
    const { search, updateSearch } = this.props
    const {
      binTags,
      marketId,
      selectedMarketIds,
      taggedDate,
      taggedEndDate,
      taggedStartDate,
    } = this.state

    let searchObject = {
      bin_tags: JSON.stringify(binTags),
      marketId,
      marketIds: selectedMarketIds,
      tagged_date: JSON.stringify(taggedDate),
      tagged_end_date: taggedEndDate ? taggedEndDate.toISOString() : null,
      tagged_start_date: taggedStartDate ? taggedStartDate.toISOString() : null,
    }

    searchObject = _omitBy(searchObject, _isNil)

    // The 'search' object has the previous search values, and if the new
    // 'marketId' is null, it will not replace the old value. We do not want
    // to search with the previous 'marketId' so we delete it from 'search'
    if (_has(search, 'marketId')) {
      delete search['marketId']
    }

    if (_has(search, 'marketIds')) {
      delete search['marketIds']
    }

    updateSearch({
      ...search,
      ...searchObject,
    })
  }

  onSelectMarket = value => {
    const { current, auth: { user: { markets } } } = this.props
    const { selectedMarkets } = this.state
    const valueContainsTheAllMarketOption = value.some(item => item === 'all')
    let selectedValues = []

    if (valueContainsTheAllMarketOption) {
      if (selectedMarkets.length === markets.length) {
        if (!!current) selectedValues = [current]
        else selectedValues = []
      } else {
        selectedValues = markets
      }
    } else {
      selectedValues = value
    }

    const marketIds = selectedValues.map(item => item.id)

    this.setState({
      selectedMarkets: selectedValues,
      selectedMarketIds: marketIds,
    })
  }

  render() {
    const {
      binTags,
      selectedMarkets,
      selectedMarketIds,
      taggedDate: date,
    } = this.state
    let { taggedEndDate: endDate, taggedStartDate: startDate } = this.state

    const isCustomDateRange = date && date.named === 'custom'
    if (!isCustomDateRange) {
      const generatedRange = generateRangeDate(date.named)
      startDate = generatedRange.start
      endDate = generatedRange.end
    }

    return (
      <div className="ui form SearchLocationPanel">
        <Grid>
          <Grid.Row width="16">
            <Grid.Column width="10">
              <Grid.Row>
                <Form.Field width="8">
                  <label>Market</label>
                  <MarketsDropdown
                    activeLabel={false}
                    multiple
                    showAll={true}
                    selected={selectedMarkets}
                    onSelect={value => this.onSelectMarket(value)}
                  />
                </Form.Field>
                <FieldDateDropdown
                  width="8"
                  label="Date Range"
                  current={date && date.named}
                  onChange={value => this.setFormValue('taggedDate', value)}
                />
              </Grid.Row>
              {isCustomDateRange && (
                <Grid.Row>
                  <Form.Field width="8">
                    <label>Start Date</label>
                    <DatePicker
                      controlled={true}
                      onChange={value => this.setDate('taggedStartDate', value)}
                      placeholder="Start Date"
                      singleDay={true}
                      value={startDate}
                    />
                  </Form.Field>
                  <Form.Field width="8">
                    <label>End Date</label>
                    <DatePicker
                      controlled={true}
                      onChange={value => this.setDate('taggedEndDate', value)}
                      placeholder="End Date"
                      singleDay={true}
                      value={endDate}
                    />
                  </Form.Field>
                </Grid.Row>
              )}
              <Grid.Row>
                <Button color="blue" fluid onClick={this.search}>
                  Search
                </Button>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width="6">
              <ReportFilteredTagsInput
                className="SearchLocationPanel"
                endDate={endDate}
                isCustomDateRange={isCustomDateRange}
                label="Tags"
                marketId={
                  selectedMarketIds.length > 0 ? selectedMarketIds : null
                }
                onChange={value => this.setFormValue('binTags', value)}
                startDate={startDate}
                value={binTags}
                width="16"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}
