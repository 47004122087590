import gql from 'graphql-tag'
import { LocationPartial } from './locations'

export const MarketPartial = `
  id
  name
  alias
  staff_members{
    name
    phone
  }
`
export const Market = `
  ${MarketPartial}
  unloading_locations {
    ${LocationPartial}
  }
`

export const getMarkets = gql`
  query($ids: [String]!) {
    marketsByIds(ids: $ids) {
      ${Market}
    }
  }
`

export const getMarketDrivers = gql`
  query getMarketDrivers($ids: [String]!) {
    marketsByIds(ids: $ids) {
      drivers {
        id
        first_name
        last_name
      }
    }
  }
`

export const getMarketsByIds = gql`
  query($ids: [String]!, $start: String!, $end: String!) {
    marketsByIds(ids: $ids) {
      id
      name
      projection
      reports(start: $start, end: $end) {
        bins {
          bins
          bin
          container
        }
        locations {
          locations
          container_locations
          bin_locations
        }
        goals
        projectedOrActual {
          projected
          bins_projected
          containers_projected
        }
        totals {
          cloth
          misc
          trash
          containers_weight
          bins_weight
        }
        reportList {
          cloth
          misc
          trash
          containers_weight
          bins_weight
          at
          name
        }
        previousYearReportList {
          cloth
          misc
          trash
          at
          name
        }
        untilTodayReportList {
          cloth
          misc
          trash
          at
          name
        }
        untilTodayLastYearReportList {
          cloth
          misc
          trash
          at
          name
        }
      }
    }
  }
`

export const getMarketEOMReport = gql`
  query($marketId: String!, $month: Int!, $year: Int!, $regenerate: Boolean) {
    marketEomReport(
      marketId: $marketId
      month: $month
      year: $year
      regenerate: $regenerate
    ) {
      url
      generated_at
    }
  }
`
