function allowDecimalNumber(event, numberOfDecimals = 0) {
  const justNumberRegex = /^[0-9]*,?[0-9]*\.?[0-9]*$/
  const notAllowStartWithZero = /^[0][0-9]/
  const nativeEvent = event.nativeEvent
  const oldValue = nativeEvent.target.defaultValue
  let value = nativeEvent.target.value
  const decimals = value.split('.').length > 1 ? value.split('.')[1] : ''

  if (value === '') value = 0

  if (numberOfDecimals === 0 && nativeEvent.data === '.') {
    value = oldValue
  }

  if (oldValue === '0' && /[0-9]/.test(nativeEvent.data)) {
    value = Number(nativeEvent.data)
  }

  if (
    !justNumberRegex.test(value) ||
    notAllowStartWithZero.test(value) ||
    decimals.length > numberOfDecimals
  ) {
    nativeEvent.target.value = oldValue
  } else {
    nativeEvent.target.value = value
  }
}

function numeric(val) {
  return /^[0-9]*,?[0-9]*\.?[0-9]*$/.test(val) || !val
    ? undefined
    : 'invalid/format'
}

function required(val) {
  return val !== null && val !== undefined ? undefined : 'invalid/required'
}

module.exports = {
  allowDecimalNumber,
  numeric,
  required,
}
