import React from 'react'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'
import _split from 'lodash/split'
import _compose from 'lodash/fp/compose'

import { Input } from 'semantic-ui-react'
import Picker from 'react-month-picker'
import { Dropdown, Modal } from 'semantic-ui-react'

import 'react-month-picker/css/month-picker.css'
import './styles.scss'

const formatToText = value => {
  if (_isEmpty(value)) return 'Custom Month'

  const { year, month } = parseValue(value)

  if (!_isEmpty(year) && !_isEmpty(month))
    return moment(value).format('MMMM, YYYY')

  return 'Custom Month'
}
const parseValue = value => {
  const [year, month] = _split(value, '-')

  return { year, month }
}
const formatValue = (year, month) => {
  return `${year}-${month}`
}

export class MonthPicker extends React.PureComponent {
  static defaultProps = {
    onChange: () => ({}),
  }

  render() {
    const { disabled, render, value, onChange } = this.props
    const text = formatToText(value)

    return (
      <Picker
        years={{ min: 2016, max: moment().year() }}
        value={parseValue(value)}
        onChange={_compose(onChange, formatValue)}
        show={true}
        lang={moment.langData()._monthsShort}
      >
        {!render ? (
          <Input
            icon="calendar"
            iconPosition="left"
            placeholder="Enter Custom Date"
            value={text}
            disabled={disabled}
            className="DatePicker__picker"
            fluid
          />
        ) : (
          render({ value, text })
        )}
      </Picker>
    )
  }
}

export default MonthPicker

export class MonthDropdown extends React.PureComponent {
  state = {
    modalOpen: false,
  }

  other() {
    const { onChange, current, disabled = false, ...props } = this.props

    return (
      <Dropdown
        placeholder={current === 'custom' ? 'Custom' : 'Filter by'}
        options={this.options}
        value={current}
        disabled={disabled}
        selection
        onChange={(ev, { value }) => onChange(value)}
        {...props}
      />
    )
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  onClick = (ev, { value }) => {
    const { onChange } = this.props

    return onChange(value)
  }

  onPicked = value => {
    const { onChange } = this.props

    this.setState({ modalOpen: false }, () => {
      onChange(value)
    })
  }

  render() {
    const { value, disabled = false } = this.props

    return (
      <React.Fragment>
        <Dropdown text={formatToText(value)} disabled={disabled} selection>
          <Dropdown.Menu vertical>
            <Dropdown.Item
              text="This Month"
              value={moment().format('YYYY-MM')}
              onClick={this.onClick}
            />
            <Dropdown.Item
              text="Last Month"
              value={moment()
                .subtract(1, 'month')
                .format('YYYY-MM')}
              onClick={this.onClick}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              icon="calendar"
              content="Custom Month"
              onClick={this.handleOpen}
            />
          </Dropdown.Menu>
        </Dropdown>
        <Modal
          size="mini"
          open={this.state.modalOpen}
          className="Modal__MonthPicker"
          dimmer="blurring"
          onClose={this.handleClose}
        >
          <Modal.Content className="ModalContent">
            <MonthPicker render={() => {}} onChange={this.onPicked} />
          </Modal.Content>
        </Modal>
      </React.Fragment>
    )
  }
}
