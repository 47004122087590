import * as QL from '../api/Query/routeCost'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/routeCost'
import WithQuery from './query'

export const WithRoutCostReport = WithQuery({
  selector: data => {
    if (!data) {
      return {
        routeCostAnalysisData: {},
      }
    }

    if (data.getRouteCost) {
      data.getRouteCost.forEach(yearReport => {
        if (yearReport.reports) {
          yearReport.reports.forEach(report => {
            if (report.routes) {
              report.routes.forEach(route => {
                route.id = route.id.replace(`|${yearReport.year}`, '')
                delete route.__typename
                Object.keys(route).forEach(key => {
                  if (key !== 'name' && key !== 'id' && route[key] === -1)
                    route[key] = NaN
                })
              })
            }
          })
        }
      })
    }

    return {
      routeCostAnalysisData: { ...data },
    }
  },
  QL,
  queryName: 'getRouteCostQuery',
  variablesPicker: props => {
    const { auth: { user: { markets } }, location: { search } } = props
    const allMarkets = markets ? markets.map(market => market.id) : []

    const query = new URLSearchParams(search)
    const date = query.get('date')
    const marketId = query.get('market')
    const include_previous_year =
      query.get('include-previous-year') &&
      query.get('include-previous-year') === 'true'
        ? true
        : false

    if (!date) {
      return null
    }

    if (marketId) {
      if (!allMarkets.includes(marketId)) {
        return null
      }
    }

    if (!moment(date).isValid()) {
      return null
    }

    return {
      markets: marketId ? [marketId] : allMarkets,
      year: moment(date).year(),
      month: moment(date)
        .format('MMM')
        .toLowerCase(),
      include_previous_year,
    }
  },
  fetchPolicy: 'network-only',
})

export const WithQuarterlyRoutCostReport = WithQuery({
  selector: data => {
    if (!data || Object.keys(data).length === 0) {
      return {
        getQuarterlyRouteCostData: [],
      }
    }

    if (data.getQuarterlyRouteCost) {
      data.getQuarterlyRouteCost.forEach(yearReport => {
        delete yearReport.__typename

        if (yearReport.months) {
          yearReport.months.forEach(monthReport => {
            delete monthReport.__typename
            delete monthReport.report.__typename

            if (monthReport.report.routes) {
              monthReport.report.routes.forEach(route => {
                delete route.__typename

                route.id = route.id.replace(
                  `|${yearReport.year}|${monthReport.month}`,
                  ''
                )

                Object.keys(route).forEach(key => {
                  if (key !== 'name' && key !== 'id' && route[key] === -1)
                    route[key] = NaN
                })
              })
            }
          })
        }
      })
    }

    return {
      getQuarterlyRouteCostData: [...data.getQuarterlyRouteCost],
    }
  },
  QL,
  queryName: 'getQuarterlyRouteCostQuery',
  variablesPicker: props => {
    const { auth: { user: { markets } }, location: { search } } = props
    const allMarkets = markets ? markets.map(market => market.id) : []

    const query = new URLSearchParams(search)
    const year = query.get('year')
    const marketId = query.get('market')
    const quarter = query.get('quarter')
    const include_previous_year =
      query.get('include-previous-year') &&
      query.get('include-previous-year') === 'true'
        ? true
        : false

    if (!year || !quarter || !marketId) return null
    if (!allMarkets.includes(marketId)) return null
    if (!moment(year).isValid()) return null

    return {
      market: marketId,
      year: Number(year),
      quarter,
      include_previous_year,
    }
  },
  fetchPolicy: 'network-only',
})

export const WithCumulativeQuarterlyRoutCostReport = WithQuery({
  selector: data => {
    if (!data || Object.keys(data).length === 0) {
      return {
        cumulativeQuarterlyRouteCostData: [],
      }
    }

    if (data.getCumulativeQuarterlyRouteCost) {
      data.getCumulativeQuarterlyRouteCost.forEach(yearReport => {
        delete yearReport.__typename

        if (yearReport.months) {
          yearReport.months.forEach(monthReport => {
            delete monthReport.__typename
            delete monthReport.report.__typename

            if (monthReport.report.routes) {
              monthReport.report.routes.forEach(route => {
                delete route.__typename

                route.id = route.id.replace(
                  `|${yearReport.year}|${monthReport.month}`,
                  ''
                )

                Object.keys(route).forEach(key => {
                  if (key !== 'name' && key !== 'id' && route[key] === -1)
                    route[key] = NaN
                })
              })
            }
          })
        }
      })
    }

    return {
      cumulativeQuarterlyRouteCostData: [
        ...data.getCumulativeQuarterlyRouteCost,
      ],
    }
  },
  QL,
  queryName: 'getCumulativeQuarterlyReviewRouteCostQuery',
  variablesPicker: props => {
    const { auth: { user: { markets } }, location: { search } } = props
    const allMarkets = markets ? markets.map(market => market.id) : []

    const query = new URLSearchParams(search)
    const year = query.get('year')
    const marketId = query.get('market')
    const include_previous_year =
      query.get('include-previous-year') &&
      query.get('include-previous-year') === 'true'
        ? true
        : false

    if (!year || !marketId) return null
    if (!allMarkets.includes(marketId)) return null
    if (!moment(year).isValid()) return null

    return {
      market: marketId,
      year: Number(year),
      include_previous_year,
    }
  },
  fetchPolicy: 'network-only',
})

export const WithRouteCostActions = WrappedComponent =>
  connect(null, dispatch => bindActionCreators(actions, dispatch))(
    WrappedComponent
  )
