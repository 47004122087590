import React from 'react'
import { Query } from 'react-apollo'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../actions/maintenanceRequests'
import {
  searchMaintenanceRequests,
  MaintenanceRequest,
  filterByMarkets,
} from '../selectors/maintenanceRequests'

import * as QL from '../api/Query/maintenanceRequests'

export const withMaintenanceRequestActions = WrappedComponent =>
  connect(null, dispatch => bindActionCreators(actions, dispatch))(
    WrappedComponent
  )

export const withMaintenanceRequestFetchAction = WrappedComponent =>
  connect(null, dispatch =>
    bindActionCreators({ fetch: actions.fetch }, dispatch)
  )(WrappedComponent)

export const withMaintenanceRequests = WrappedComponent =>
  connect(state => ({
    searchedMaintenanceRequests: searchMaintenanceRequests(state),
    maintenanceRequests: state.maintenanceRequests.maintenanceRequests
      ? state.maintenanceRequests.maintenanceRequests.map(MaintenanceRequest)
      : [],
    loadingMaintenanceRequests: state.maintenanceRequests.loading,
    filteredMaintenanceRequests: filterByMarkets(state),
  }))(WrappedComponent)

export const WithMaintenanceRequest = Component => {
  class WrappedComponent extends React.Component {
    render() {
      const { maintenanceRequestId, ...rest } = this.props

      return (
        <Query
          query={QL.maintenanceRequest}
          variables={{ id: maintenanceRequestId }}
        >
          {({ loading, error, data }) => (
            <Component
              error={error}
              loading={loading}
              maintenanceRequest={data.maintenanceRequestData}
              {...rest}
            />
          )}
        </Query>
      )
    }
  }

  return WrappedComponent
}
