import React from 'react'

import { withForm } from '../../connectors/forms'
import { Field } from 'redux-form'

import FormField from '../../components/FormField'
import PartnershipInput from '../../components/PartnershipInput'
import { Form, Grid, Header, Input, Label } from 'semantic-ui-react'
import { PartnershipCard } from './partnershipCard'

const RateInput = props => (
  <Input labelPosition="right" {...props}>
    <input type="number" />
    <Label>c/lb</Label>
  </Input>
)

const RateField = props => <Form.Field {...props} control={RateInput} />

@withForm('LocationForm')
export default class Partnership extends React.PureComponent {
  changePartner = val => {
    const { changeFieldValue } = this.props
    changeFieldValue('partnership', val)
  }

  render() {
    const { selector } = this.props
    const partnership = selector('partnership')

    return (
      <React.Fragment>
        <Header>Partnership Information</Header>
        <Grid columns="equal">
          <Grid.Row stretched>
            <Grid.Column>
              <Field
                name="partnership"
                label="Lookup Existing Partner"
                placeholder="Find by name"
                component={FormField}
                formComponent={PartnershipInput}
              />
              <Field
                name="rate"
                label="Rate"
                parse={val => Number(val) || 0}
                placeholder="1,1"
                component={FormField}
                formComponent={RateField}
              />
            </Grid.Column>
            <Grid.Column>
              <PartnershipCard
                {...partnership}
                partnership={partnership}
                onChange={this.changePartner}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
      </React.Fragment>
    )
  }
}
