import cx from 'classnames'
import { withRoutes } from '../../connectors/routes'
import _isEqual from 'lodash/isEqual'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Link, withRouter } from 'react-router-dom'
import { List, Segment } from 'semantic-ui-react'
import CalendarEvent from './CalendarEvent'
import CopyRunModal from './CopyRunModal'
import EventContextMenu from './EventContextMenu'
import QuickEditRunModal from './QuickEditRunModal'
import RemoveRunConfirmationModal from './RemoveRunConfirmationModal'
import RescheduleRunModal from './ReschedulRunModal'
import CustomToolbar from './Toolbar'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles.scss'

const DranAndDropCalendar = withDragAndDrop(Calendar)

@withRouter
@withRoutes
export default class ScheduleCalendar extends React.PureComponent {
  static propTypes = {
    routeData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        market: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        ]).isRequired,
        runs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            run_date: PropTypes.string.isRequired,
          })
        ),
      })
    ).isRequired,
    reload: PropTypes.any,
    big: PropTypes.bool,
    allowEdit: PropTypes.bool,
    onEditRoute: PropTypes.func,
    filter: PropTypes.object,
  }

  static defaultProps = {
    reload: false,
    big: false,
    allowEdit: false,
    filter: {},
    onNavigate: () => {},
  }

  state = {
    events: [],
    loading: false,
    filter: {},
    currentMonth: moment().month(),
    currentDate: moment().date(),
    localizer: momentLocalizer(moment),
    selectedEventToReschedule: null,
    selectedEventByContextMenu: null,
    showQuickEditRunModal: false,
    showRemoveRunConfirmationModal: false,
    showCopyRunModal: false,
    contextMenuProps: {
      isActive: false,
      position: {
        top: 0,
        left: 0,
      },
    },
  }

  edit = event => {
    // TODO: Let's do this later.  This would be where a user
    // clicks on a date and they can add a RouteRun for the
    // date selected.  Pretty cool, righ!?
    const { onEditRoute } = this.props
    onEditRoute(event)
  }

  calculateDateRange(date) {
    const daysBefore31 = moment(date).add(-31, 'day')
    const start = moment(daysBefore31)
      .weekday(0)
      .startOf('D')
      .toDate()
    const datesDeltaInDays =
      moment(start).weekday() - moment(daysBefore31).weekday()
    const end = moment(date)
      .add(31 + datesDeltaInDays, 'day')
      .endOf('D')
      .toDate()

    return {
      start,
      end,
    }
  }

  initialize(date = moment(), routes) {
    routes = routes ? routes : this.props.routeData
    const { getRouteRuns, filter } = this.props
    const { start, end } = this.calculateDateRange(date)

    if (!routes || !routes.length) {
      return
    }

    this.setState({ loading: true })

    getRouteRuns({
      routeIds: routes.map(r => r.id),
      start: moment(start).toISOString(),
      end: moment(end).toISOString(),
    })
      .then(res => {
        if (res.type === 'ROUTES_ERROR') {
          throw new Error('Could not load route')
        }
        return res
      })
      .then(({ payload }) => this.processEvents(payload))
      .then(events => this.setState({ events }))
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }))
      .then(() => {
        if (filter) {
          this.setState({ filter })
        }
      })
  }

  onNavigate = date => {
    const { onNavigate } = this.props
    onNavigate(moment(date))
    this.initialize(moment(date))
  }

  componentWillReceiveProps(nextProps) {
    const { reload, routeData, filter } = this.props
    const { reload: nextReload, routeData: nextRouteData } = nextProps

    if (reload !== nextReload && nextReload) {
      this.initialize()
    }

    if (routeData && !_isEqual(routeData, nextRouteData)) {
      this.initialize(moment(), nextRouteData)
    }

    if (!_isEqual(filter, nextProps.filter)) {
      this.setState({ filter: nextProps.filter })
    }
  }

  componentDidMount() {
    const { localizer } = this.state

    this.initialize()

    localizer.visibleDays = date => {
      const { start, end } = this.calculateDateRange(date)
      const visibleDays = localizer.range(moment(start), moment(end))

      return visibleDays
    }

    this.setState({
      localizer,
    })
  }

  processEvents(routes) {
    return routes.reduce((events, route) => {
      if (!route.runDetails) {
        return events
      }

      const runs = route.runDetails.map(run => ({
        start: moment(run.run_date).startOf('D'),
        end: moment(run.run_date).endOf('D'),
        runDate: run.run_date,
        runId: run.id,
        route,
        driver: run.driver,
        helper: run.helper,
        complete: !!run.end_time,
        started: !!run.start_time,
        totals: {
          cloth: run.total_cloth,
          misc: run.total_misc,
          trash: run.total_trash,
        },
        optimized_miles: run.optimized_miles,
        is_custom: run.is_custom || null,
        driver_accuracy: run.driver_accuracy,
        totalMiles:
          !!run.end_miles && !!run.start_miles
            ? run.end_miles - run.start_miles
            : undefined,
      }))

      return [...events, ...runs]
    }, [])
  }

  filterEvents(events, { driver, routes }) {
    return events
      .reduce((ac, run) => {
        if (driver && driver !== run.driver && driver !== run.driver.id)
          return ac

        return [...ac, run]
      }, [])
      .reduce((ac, run) => {
        if (routes && routes.length && !routes.includes(run.route.id)) return ac
        return [...ac, run]
      }, [])
  }

  onMoveEvent = ({ event, start, end }) => {
    const { events } = this.state

    events.forEach(stateEvent => {
      if (stateEvent.runId === event.runId) {
        if (
          event.start.format('YYYYMMDD') !== moment(start).format('YYYYMMDD')
        ) {
          const oldEventDate = moment(event.runDate)
          const newEventDate = moment(start)

          newEventDate.set('hour', oldEventDate.get('hour'))
          newEventDate.set('minute', oldEventDate.get('minute'))
          newEventDate.set('second', oldEventDate.get('second'))

          const selectedEventData = {
            ...event,
            runDate: newEventDate.toDate(),
            oldRunDate: oldEventDate.toDate(),
            start: newEventDate.startOf('D'),
            end: newEventDate.endOf('D'),
          }

          this.setState({
            selectedEventToReschedule: selectedEventData,
          })
        }
      }
    })
  }

  onSelectEventForContextMenu(e, event = null) {
    const run = event.event

    this.setState({
      selectedEventByContextMenu: run,
    })

    this.showContextMenu(e)
  }

  showContextMenu = runId => {
    const calendarContainer = document.getElementById(
      'ScheduleCalendar__Segment'
    )
    const calendarContainerCoordinates = calendarContainer.getBoundingClientRect()

    const calendarToolbar = document.getElementById(
      'Schedule_Calendar__Toolbar'
    )
    const calendarToolbarCoordinates = calendarToolbar.getBoundingClientRect()

    const event = document.getElementById(`event-${runId}`)
    const eventCoordinates = event.getBoundingClientRect()

    this.setState({
      contextMenuProps: {
        isActive: true,
        position: {
          left: eventCoordinates.left - calendarToolbarCoordinates.left,
          top:
            eventCoordinates.bottom +
            5 -
            (calendarToolbarCoordinates.top -
              (calendarToolbarCoordinates.top -
                calendarContainerCoordinates.top)),
        },
      },
    })
  }

  hideContextMenu = () => {
    this.setState({
      contextMenuProps: {
        isActive: false,
        position: {
          left: 0,
          top: 0,
        },
      },
    })
  }

  deselectEventToReschedule = () => {
    this.setState({
      selectedEventToReschedule: null,
    })
  }

  onRescheduleRunModalClose = () => {
    this.deselectEventToReschedule()
  }

  onRunRescheduledSuccessfully = run => {
    const { events } = this.state

    this.setState({
      events: events.map(event => {
        if (event.runId !== run.runId) return event

        return {
          ...event,
          start: run.start,
          end: run.end,
          runDate: moment(run.runDate).toISOString(),
        }
      }),
    })

    this.deselectEventToReschedule()
  }

  showRemoveRunConfirmationModal = () => {
    this.setState({
      showRemoveRunConfirmationModal: true,
    })
  }

  onCancelRemoveRunConfirmationModal = () => {
    this.setState({
      showRemoveRunConfirmationModal: false,
    })
  }

  onCloseRemoveRunConfirmationModal = () => {
    this.setState({
      showRemoveRunConfirmationModal: false,
    })
  }

  onRemoveRunSuccessful = run => {
    const { events } = this.state

    this.setState({
      events: events.filter(event => event.runId !== run.runId),
    })
  }

  showQuickEditRunModal = () => {
    this.setState({
      showQuickEditRunModal: true,
    })
  }

  onCloseQuickEditRunModal = () => {
    this.setState({
      showQuickEditRunModal: false,
    })
  }

  onRunEditedSuccessfully = run => {
    const { events } = this.state

    this.setState({
      events: events.map(event => {
        if (event.runId !== run.runId) return event

        return {
          ...event,
          driver: run.driver,
          helper: run.helper,
          start: run.start,
          end: run.end,
          runDate: moment(run.runDate).toISOString(),
        }
      }),
    })
  }

  showCopyRunModal = () => {
    this.setState({
      showCopyRunModal: true,
    })
  }

  onColseCopyRunModal = () => {
    this.setState({
      showCopyRunModal: false,
    })
  }

  onRunCopiedSuccessfully = run => {
    const { events } = this.state

    events.push(run)
    this.setState({
      events,
    })
  }

  render() {
    const { big, allowEdit, date, popup = true } = this.props
    const {
      events,
      loading,
      filter,
      currentMonth,
      currentDate,
      localizer,
      selectedEventToReschedule,
      contextMenuProps,
      showRemoveRunConfirmationModal,
      showQuickEditRunModal,
      selectedEventByContextMenu,
      showCopyRunModal,
    } = this.state

    return (
      <Fragment>
        <Segment
          basic
          className={cx('ScheduleCalendar__Segment', {
            'ScheduleCalendar__container-big': big,
            'ScheduleCalendar__container-small': !big,
          })}
          id="ScheduleCalendar__Segment"
          loading={loading}
        >
          {selectedEventToReschedule && (
            <RescheduleRunModal
              run={selectedEventToReschedule}
              onRescheduledModalClose={this.onRescheduleRunModalClose}
              onRescheduledSuccessfully={this.onRunRescheduledSuccessfully}
            />
          )}
          <DranAndDropCalendar
            localizer={localizer}
            events={this.filterEvents(events, filter)}
            views={{
              month: true,
            }}
            drilldownView={null}
            onNavigate={this.onNavigate}
            components={{
              toolbar: CustomToolbar,
              event: event =>
                event ? (
                  <CalendarEvent
                    {...event}
                    showContextMenu={e =>
                      this.onSelectEventForContextMenu(e, event)
                    }
                    hideContextMenu={e => this.hideContextMenu()}
                  />
                ) : (
                  undefined
                ),
            }}
            eventPropGetter={event => ({
              className: 'ScheduleCalendar__event',
              style: {
                borderColor:
                  event.route.color && event.route.color !== ''
                    ? event.route.color
                    : '#AAAAAA',
                background: big ? event.route.color : 'none',
              },
            })}
            formats={{
              dateFormat: date => {
                return moment(date).format('MMM DD')
              },
              monthHeaderFormat: date => {
                const { start, end } = this.calculateDateRange(date)

                return `From ${moment(start).format(`MMM DD`)} to ${moment(
                  end
                ).format(`MMM DD`)}`
              },
            }}
            dayPropGetter={date => {
              const props = {
                style: {
                  backgroundColor: '#fff',
                },
              }

              if (
                date.getMonth() === currentMonth &&
                date.getDate() === currentDate
              )
                props.style.backgroundColor = '#E1F5FE'

              if (date.getMonth() !== currentMonth)
                props.style.backgroundColor = '#e0e0e0'

              return props
            }}
            messages={{
              next: 'Next Week',
              previous: 'Previous Week',
              today: `Today (${moment().format('MMM DD YYYY')})`,
            }}
            resizable={false}
            showMultiDayTimes={true}
            draggableAccessor={event => !event.complete && !event.started}
            // selectable={allowEdit}
            selectable={false}
            // onDragStart={() => console.log('start dragging')}
            onSelectSlot={allowEdit ? this.edit : null}
            onSelectEvent={allowEdit ? this.edit : null}
            onEventDrop={this.onMoveEvent}
            popup={popup}
            showAllEvents={!popup}
            date={date ? date.toDate() : undefined}
          />
          {contextMenuProps.isActive && (
            <EventContextMenu
              hideContextMenu={this.hideContextMenu}
              top={contextMenuProps.position.top}
              left={contextMenuProps.position.left}
            >
              <Segment>
                <List>
                  <List.Item>
                    <List.Icon name="eye" />
                    <List.Content>
                      <Link
                        target="_blank"
                        to={`/app/reports/run/${
                          selectedEventByContextMenu.runId
                        }`}
                      >
                        View Run
                      </Link>
                    </List.Content>
                  </List.Item>
                  <List.Item
                    disabled={selectedEventByContextMenu.complete}
                    onClick={e => {
                      this.showQuickEditRunModal()
                    }}
                  >
                    <List.Icon name="edit" />
                    <List.Content>Quick Edit</List.Content>
                  </List.Item>
                  <List.Item
                    disabled={selectedEventByContextMenu.complete}
                    onClick={e => this.showCopyRunModal()}
                  >
                    <List.Icon name="copy" />
                    <List.Content>Copy Run</List.Content>
                  </List.Item>
                  <List.Item
                    onClick={e => this.showRemoveRunConfirmationModal()}
                  >
                    <List.Icon name="trash alternate" />
                    <List.Content>Remove Run</List.Content>
                  </List.Item>
                </List>
              </Segment>
            </EventContextMenu>
          )}
        </Segment>
        {showRemoveRunConfirmationModal && (
          <RemoveRunConfirmationModal
            run={selectedEventByContextMenu}
            onCancelModal={this.onCancelRemoveRunConfirmationModal}
            onSuccessful={this.onRemoveRunSuccessful}
            onCloseModal={this.onCloseRemoveRunConfirmationModal}
          />
        )}

        {showQuickEditRunModal && (
          <QuickEditRunModal
            initialValues={{
              run_date: moment(selectedEventByContextMenu.runDate).toDate(),
              driver: selectedEventByContextMenu.driver.id,
              helper: selectedEventByContextMenu.helper
                ? selectedEventByContextMenu.helper.id
                : null,
            }}
            run={selectedEventByContextMenu}
            onCloseModal={this.onCloseQuickEditRunModal}
            onSuccessful={this.onRunEditedSuccessfully}
          />
        )}

        {showCopyRunModal && (
          <CopyRunModal
            initialValues={{
              run_date: moment(selectedEventByContextMenu.runDate).toDate(),
              driver: selectedEventByContextMenu.driver.id,
              helper: selectedEventByContextMenu.helper
                ? selectedEventByContextMenu.helper.id
                : null,
            }}
            run={selectedEventByContextMenu}
            onCloseModal={this.onColseCopyRunModal}
            onSuccessful={this.onRunCopiedSuccessfully}
          />
        )}
      </Fragment>
    )
  }
}
