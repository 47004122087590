import gql from 'graphql-tag'
import { policeReportModel } from '../Query/policeReports'

export const createPoliceReport = gql`
  mutation createPoliceReport(
    $policeReport: PoliceReportInput!
  ) {
    createPoliceReport(
      policeReport: $policeReport
    ) {
      ${policeReportModel}
    }
  }
`

export const updatePoliceReport = gql`
  mutation updatePoliceReport(
    $policeReportId: String!
    $policeReport: PoliceReportInput!
  ) {
    updatePoliceReport(
      policeReportId: $policeReportId
      policeReport: $policeReport
    ) {
      ${policeReportModel}
    }
  }
`
