import gql from 'graphql-tag'
import { RouteLocation } from './routes'

export const Person = `
  id
  email
  first_name
  middle_name
  last_name
  user_name
`

export const TagInfo = `
  id
  title
  label
  children {
    id
    title
    label
    selections {
      id
      label
    }
  }
`

export const RunLocation = `
  location {
    ${RouteLocation}
  }
  notInOriginalRoute
  originalRouteId
  bins {
    id
    box_code
    weight
    edited
    report {
      weight
      binEmpty
      tagInfo {
        ${TagInfo}
      }
      created_at
      edited
    }
  }
`

export const Run = `
  id
  deleted
  driver {
    ${Person}
  }
  helper {
    ${Person}
  }
  end_miles
  end_time
  route {
    id
    name
    color
    market {
      id
      name
    }
    optimized_miles
  }
  optimized_miles
  is_custom
  run_date
  start_miles
  start_time
  total_cloth
  total_misc
  total_trash
  truck_number
  weather
  locations {
    ${RunLocation}
  }
`

export const scheduleRun = gql`
  mutation scheduleRun(
    $run: RouteRunInput!
    $recurring: RecurringSchedule
    $is_custom: Boolean
  ) {
    scheduleRun(run: $run, recurring: $recurring, is_custom: $is_custom) {
      id
      run_date
      mile_calculation_result {
        status
        message
      }
    }
  }
`

export const saveRun = gql`
  mutation saveRun($run: RouteRunInput!) {
    saveRun(run: $run) {
      id
      run_date
    }
  }
`

export const copyRun = gql`
  mutation copyRun($run: RouteRunInput!, $date: String!) {
    copyRun(run: $run, date: $date) {
      ${Run}
    }
  }
`

export const reScheduleRun = gql`
  mutation reScheduleRun($id: String!, $date: String!) {
    reScheduleRun(id: $id, date: $date) {
      ${Run}
    }
  }
`

export const quickEdit = gql`
  mutation quickEdit($id: String!, $date: String!, $driver: String!, $helper: String) {
    quickEdit(id: $id, date: $date, driver: $driver, helper: $helper) {
      ${Run}
    }
  }
`

export const getDriver = gql`
  query data($driverId: String!) {
    userData(id: $driverId) {
      id
      first_name
      last_name
    }
  }
`

export const getRoute = gql`
  query data($routeId: String!) {
    route(id: $routeId) {
      id
      name
      color
      locations {
        id
        name
        city
        address
        state
        postal
        latitude
        longitude
        stop_sequence
        bins {
          id
          box_code
        }
      }
    }
  }
`

export const getMarket = gql`
  query data($ids: [String!]) {
    marketsByIds(ids: $ids) {
      drivers {
        id
        first_name
        last_name
      }
      routes {
        id
        name
        color
      }
    }
  }
`
export const getRunLogs = gql`
  query($id: String!) {
    runById(id: $id) {
      id
      logs {
        type
        payload
        created_at
      }
    }
  }
`

export const getRun = gql`
  query($id: String!) {
    runById(id: $id) {
      ${Run}
    }
  }
`
