import React from 'react'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _toString from 'lodash/toString'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'

import {
  WithLocationsSearch,
  WithTaggedLocationsSearch,
} from '../../connectors/locations'
import { WithScheduleRunFormValues } from '../../connectors/runs'

import {
  Segment,
  Dimmer,
  Loader,
  List,
  Statistic,
  Icon,
  Header,
} from 'semantic-ui-react'
import { FormattedNumber } from 'react-intl'
import locationsFilterOptions from '../../views/LocationsHealth/locationsFilterOptions'

import './styles.scss'

const ignoredMarkets = [
  '-L86Q7JvnDuRU6YRBk0I', // Test_Market
]
const EmptyList = ({ title, message, icon, children }) => (
  <Segment textAlign="center" basic>
    <Header as="h2" icon>
      <Icon name={icon} />
      {title}
      <Header.Subheader>{_toString(message)}</Header.Subheader>
    </Header>
    {children}
  </Segment>
)

export const ItemLocation = ({
  id,
  name,
  address,
  distance,
  onClick,
  smaller,
  ...props
}) => (
  <List.Item
    onClick={
      onClick && (ev => onClick(ev, { id, name, address, distance, ...props }))
    }
    {...props}
  >
    <List.Content floated="right">
      <Statistic size="mini">
        <Statistic.Value>
          <FormattedNumber
            value={_get(distance, 'number')}
            maximumFractionDigits={2}
          />
        </Statistic.Value>
        <Statistic.Label>{_get(distance, 'abbreviation')}</Statistic.Label>
      </Statistic>
    </List.Content>
    {!smaller && <Icon size="big" name="marker" />}
    <List.Content>
      {!props.customizeMode && (
        <List.Header as={Link} to={`/app/locations/${id}`}>
          {name}
        </List.Header>
      )}
      {props.customizeMode && <List.Header>{name}</List.Header>}
      <List.Description>{address}</List.Description>
    </List.Content>
  </List.Item>
)
ItemLocation.defaultProps = {
  smaller: false,
}

export const ItemTaggedLocation = ({
  id,
  name,
  address,
  onClick,
  smaller,
  ...props
}) => (
  <List.Item
    onClick={onClick && (ev => onClick(ev, { id, name, address, ...props }))}
    {...props}
  >
    <List.Content floated="right">
      <Statistic size="mini">
        <Statistic.Value>
          <Icon name="circle" style={{ color: _get(props, 'route.color') }} />
          {_get(props, 'route.name')}
        </Statistic.Value>
      </Statistic>
    </List.Content>
    {!smaller && <Icon size="big" name="marker" />}
    <List.Content>
      <List.Header as={Link} to={`/app/locations/${id}`}>
        {name}
      </List.Header>
      <List.Description>{address}</List.Description>
    </List.Content>
  </List.Item>
)
ItemTaggedLocation.defaultProps = {
  smaller: false,
}

@WithScheduleRunFormValues
export default class ListLocations extends React.PureComponent {
  onItemClick = (ev, props) => {
    const { onChange } = this.props

    onChange(props)
  }

  render() {
    const {
      locations,
      value,
      onChange,
      footer,
      smaller,
      renderItem,
      customizeMode,
    } = this.props

    return (
      <List selection={!!onChange} divided verticalAlign="middle">
        {_map(
          locations,
          (location, i) =>
            !renderItem ? (
              <ItemLocation
                smaller={smaller}
                customizeMode={customizeMode}
                key={i}
                {...location}
                onClick={onChange && this.onItemClick}
                active={value && _get(location, 'id') === _get(value, 'id')}
              />
            ) : (
              renderItem(location, i)
            )
        )}
        {footer}
      </List>
    )
  }
}

@WithLocationsSearch
export class SearchListLocations extends React.Component {
  render() {
    const {
      locations: allLocations,
      loading,
      error,
      locationsFilter,
    } = this.props

    let locations = allLocations

    if (!!locationsFilter && !!allLocations)
      if (locationsFilter === locationsFilterOptions.Active)
        locations = allLocations.filter(location => {
          if (!location.routes) return false
          else
            return location.routes.some(
              route => !ignoredMarkets.includes(route.market.id)
            )
        })
      else if (locationsFilter === locationsFilterOptions.inActive)
        locations = allLocations.filter(location => {
          if (!location.routes) return true
          else
            return location.routes.every(route =>
              ignoredMarkets.includes(route.market.id)
            )
        })

    return (
      <React.Fragment>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        {_isEmpty(locations) && (
          <EmptyList title="No Results Found" message={error} icon="pin" />
        )}
        <ListLocations {...this.props} locations={locations} />
      </React.Fragment>
    )
  }
}

@WithTaggedLocationsSearch
export class SearchTaggedListLocations extends React.Component {
  render() {
    const {
      locations,
      loading,
      error,
      marketId,
      search: { marketIds },
    } = this.props

    return (
      <React.Fragment>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        {_isEmpty(locations) &&
          (marketId || marketIds) && (
            <EmptyList title="No Results Found" message={error} icon="pin" />
          )}
        {_isEmpty(locations) &&
          (!marketId && !marketIds) && (
            <EmptyList
              title="Please select at least one market"
              message={error}
              icon="warning"
            />
          )}
        <ListLocations {...this.props} renderItem={ItemTaggedLocation} />
      </React.Fragment>
    )
  }
}
