import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { NavLink } from 'react-router-dom'
import validator from 'validator'
import { Button, Form, Grid, Message } from 'semantic-ui-react'

import Version from '../../components/Version'

export const validate = ({ email, password }) => {
  const errors = {}

  if (!email || validator.isEmpty(email)) {
    errors.email = 'invalid/required'
  }

  if (!password || validator.isEmpty(password)) {
    errors.password = 'invalid/required'
  }

  return errors
}

const FormField = ({ formComponent, meta, ...props }) => {
  const Component = formComponent
  const error = meta.touched && meta.error
  return <Component error={!!error} {...props} />
}

const LoginForm = ({ handleSubmit, submitting, formError, pristine }) => (
  <Form onSubmit={handleSubmit}>
    <Form.Field>
      <Field
        id="LoginForm_email"
        name="email"
        component={FormField}
        label="Email/Username"
        placeholder="you@domain.com or jsmith"
        formComponent={Form.Input}
        autoComplete="username"
      />
    </Form.Field>
    <Form.Field>
      <Field
        id="LoginForm_password"
        name="password"
        component={FormField}
        type="password"
        label="Password"
        placeholder="******"
        formComponent={Form.Input}
        autoComplete="current-password"
      />
    </Form.Field>
    {formError && (
      <Message negative header="Login Failed" content={formError.message} />
    )}
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <NavLink id="LoginForm_forgotPassword" to="/auth/forgot-password">
            Forgot your password?
          </NavLink>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            id="LoginForm_submit"
            type="submit"
            disabled={pristine || submitting}
          >
            Submit
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Grid columns={1}>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Version />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
)

export default reduxForm({
  form: 'LoginForm',
  validate,
})(LoginForm)
