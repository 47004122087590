import React, { Fragment } from 'react'
import { Button, Modal, Message, Icon } from 'semantic-ui-react'
import {
  WithCompetitorActions,
  WithCompetitors,
} from '../../connectors/competitors'

@WithCompetitors
@WithCompetitorActions
export class RemoveCompetitorModal extends React.Component {
  state = { open: true, location_id: null }

  onOpen = () => {
    this.setState({ open: true })
  }

  removeCompetitor = () => {
    const { locationId, competitor, removeLocationCompetitor } = this.props
    removeLocationCompetitor(locationId, competitor.id)
  }

  close = () => {
    const { resetCompetitorsStatus, onClose } = this.props
    resetCompetitorsStatus()
    onClose()
  }

  render() {
    const { open } = this.state
    const { competitor, inProgress, message, status } = this.props

    return (
      <React.Fragment>
        <Modal
          dimmer="blurring"
          closeOnDimmerClick={false}
          size="small"
          open={open}
          onOpen={this.onOpen}
          onClose={this.close}
        >
          <Modal.Header>Remove Competitor</Modal.Header>
          <Modal.Content scrolling>
            <p>
              Are you sure you want to remove{' '}
              <strong>{competitor.label}</strong>?
            </p>

            {message && (
              <Message.Content
                style={{
                  color:
                    status === 'SUCCESS'
                      ? 'green'
                      : status === 'FAILED' ? 'red' : '',
                }}
              >
                <Icon
                  name={
                    status === 'SUCCESS'
                      ? 'check circle outline'
                      : status === 'FAILED' ? 'exclamation circle' : ''
                  }
                />
                {message}
              </Message.Content>
            )}
          </Modal.Content>
          <Modal.Actions>
            {status === 'SUCCESS' ? (
              <Button positive content="Close" onClick={this.close} />
            ) : (
              <Fragment>
                <Button
                  color="red"
                  content="Nevermind"
                  loading={inProgress}
                  disabled={inProgress}
                  onClick={this.close}
                />
                <Button
                  color="green"
                  content="Confirm"
                  loading={inProgress}
                  disabled={inProgress}
                  onClick={this.removeCompetitor}
                />
              </Fragment>
            )}
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}
