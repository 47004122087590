import { connect } from 'react-redux'
import { change, formValueSelector, submit } from 'redux-form'

export const withForm = formName => WrappedComponent =>
  connect(
    state => ({
      selector: (...prop) => formValueSelector(formName)(state, ...prop),
    }),
    dispatch => ({
      submitForm: () => dispatch(submit(formName)),
      changeFieldValue: (field, value) => {
        dispatch(change(formName, field, value))
      },
    })
  )(WrappedComponent)
