import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import React from 'react'

import { Field, reduxForm } from 'redux-form'
import { Form, Grid, Message } from 'semantic-ui-react'

import FormField from '../../components/FormField'
import ListLocations from '../../components/ListLocations'
import { SimpleLoading } from '../../components/Loading'
import LocationInput from '../../components/LocationInput'
import MapMarker from '../../components/MapMarker'
import { WithLocations } from '../../connectors/markerMap'
import { WithLocationsSearch } from '../../connectors/locations'

import './styles.scss'

export const validate = args => {
  const errors = {}
  const fields = ['location']

  fields.forEach(field => {
    if (!args[field] || _isEmpty(args[field])) {
      errors[field] = 'invalid/required'
    }
  })

  return errors
}

@WithLocations
export class ResultMap extends React.PureComponent {
  render() {
    return <MapMarker {...this.props} />
  }
}

@WithLocationsSearch
export class LocationsPanel extends React.PureComponent {
  render() {
    const { locations, loading, setLocation, ...props } = this.props
    const location = _get(props, 'value')
    const onChange = _get(props, 'onChange')
    const activePin = location
      ? { ...location, role: 'selected', color: 'green' }
      : null

    return (
      <Grid className="Panel__Result">
        <Grid.Column width={4} className="List__Locations">
          <ListLocations locations={locations} {...props} smaller />
          <SimpleLoading loading={loading} />
        </Grid.Column>
        <Grid.Column width={12}>
          <ResultMap
            locations={locations}
            location={activePin}
            onChange={onChange}
          />
          <SimpleLoading loading={loading} />
        </Grid.Column>
      </Grid>
    )
  }
}

@reduxForm({
  form: 'ExistingLocationForm',
  validate,
})
export default class ExistingLocationForm extends React.Component {
  render() {
    const {
      formError,
      handleSubmit,
      lookupLocation,
      simple_locations,
    } = this.props
    const geoCoordinates = [
      _get(lookupLocation, 'latitude'),
      _get(lookupLocation, 'longitude'),
    ]
    return (
      <Form onSubmit={handleSubmit} className="Form__ExistingLocation">
        <Field
          name="lookupLocation"
          label="Lookup Location"
          placeholder="Find by address, zip or anything"
          component={FormField}
          formComponent={LocationInput}
        />
        <Field
          name="location"
          component={FormField}
          formComponent={LocationsPanel}
          geoCoordinates={geoCoordinates}
          simple_locations={simple_locations}
          required
        />

        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
