import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Dropdown, Form, Grid, Message } from 'semantic-ui-react'

import { withAuth } from '../../connectors/auth'

import FormField from '../../components/FormField'
import Select from '../../components/Select'

import validator from 'validator'
import { withForm } from '../../connectors/forms'

const permissions = [
  {
    key: 'admin',
    value: 'admin',
    text: 'Administrator',
  },
  {
    key: 'user',
    value: 'user',
    text: 'Manager',
  },
  {
    key: 'driver',
    value: 'driver',
    text: 'Driver',
  },
  {
    key: 'cra',
    value: 'cra',
    text: 'CRA',
  },
  {
    key: 'route_manager',
    value: 'route_manager',
    text: 'Route Manager',
  },
]

export const validate = args => {
  const errors = {}

  let fields = [
    'first_name',
    'last_name',
    'user_name',
    'password_hash',
    'markets',
  ]

  if (args['markets'])
    if (args['markets'].length > 1) fields.push('primary_market')

  fields.forEach(field => {
    if (!args[field] || !args[field].length) {
      errors[field] = 'invalid/required'
    }

    if (
      !args[field] ||
      (typeof args[field] === 'string' && validator.isEmpty(args[field]))
    ) {
      errors[field] = 'invalid/required'
    }
  })

  // If the user exists, we don't always need to enter a password
  if (args.id && errors.password_hash) {
    delete errors.password_hash
  }

  if (args.primary_email && !validator.isEmail(args.primary_email)) {
    errors['primary_email'] = 'invalid/email_format'
  }

  return errors
}

const parseMarket = market => ({
  text: market.name,
  value: market.id,
})

const DropDownInput = ({ label, value, onChange, type, ...props }) => {
  return (
    <Form.Field {...props}>
      <label>{label}</label>
      <Dropdown
        fluid
        selection
        onChange={(e, { value }) => onChange(value)}
        value={value === '' ? [] : value}
        {...props}
      />
    </Form.Field>
  )
}

@withForm('UserForm')
@withAuth
@reduxForm({
  form: 'UserForm',
  validate,
})
export default class UserForm extends React.PureComponent {
  primaryMarketOptions() {
    const { auth: { user: { markets } }, selector } = this.props
    const selectedMarkets = selector('markets') || []

    return markets
      .filter(market => selectedMarkets.includes(market.id))
      .map(parseMarket)
  }

  render() {
    const { formError, handleSubmit, auth: { user: { markets } } } = this.props
    const primaryMarketOptions = this.primaryMarketOptions()

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Field
                  id="UserForm_first_name"
                  name="first_name"
                  component={FormField}
                  type="text"
                  label="First Name"
                  required
                  placeholder="First Name"
                  formComponent={Form.Input}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  id="UserForm_last_name"
                  name="last_name"
                  component={FormField}
                  type="text"
                  label="Last Name"
                  required
                  placeholder="Last Name"
                  formComponent={Form.Input}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  id="UserForm_user_name"
                  name="user_name"
                  component={FormField}
                  label="Username"
                  required
                  placeholder="jsmith"
                  formComponent={Form.Input}
                  autoComplete="new-password"
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  id="UserForm_password"
                  name="password_hash"
                  component={FormField}
                  type="password"
                  label="Password"
                  placeholder="******"
                  required
                  formComponent={Form.Input}
                  autoComplete="new-password"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  id="UserForm_primary_email"
                  name="primary_email"
                  component={FormField}
                  type="email"
                  label="Email"
                  placeholder="Enter your primary email address"
                  formComponent={Form.Input}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  id="UserForm_phone"
                  name="phone"
                  component={FormField}
                  label="Phone"
                  placeholder="Phone"
                  formComponent={Form.Input}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  id="UserForm_contact_type"
                  name="contact_type"
                  label="Role"
                  placeholder="Start typing to select"
                  options={permissions}
                  component={FormField}
                  formComponent={Select}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  id="UserForm_markets"
                  name="markets"
                  label="Market"
                  placeholder="Market"
                  multiple
                  options={markets.map(parseMarket)}
                  component={FormField}
                  required
                  upward
                  formComponent={DropDownInput}
                />
              </Grid.Column>
              <Grid.Column>
                {primaryMarketOptions.length > 1 && (
                  <Field
                    id="UserForm_primary_market"
                    name="primary_market"
                    label="Primary Market"
                    placeholder="Primary Market"
                    options={primaryMarketOptions}
                    component={FormField}
                    required={primaryMarketOptions.length > 1}
                    upward
                    formComponent={Select}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  id="UserForm_deactivated"
                  name="deactivated"
                  component={FormField}
                  label="Deactivated"
                  formComponent={Form.Checkbox}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {formError && (
            <Message
              negative
              header="Create User Failed"
              content={formError.message}
            />
          )}
        </Form.Field>
      </Form>
    )
  }
}
