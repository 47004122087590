import React from 'react'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { Radio } from 'semantic-ui-react'
import { Icon } from 'semantic-ui-react'

import ModalTrigger from '../../components/ModalTrigger'
import AllRoutesReportForm from '../../forms/AllRoutesReport'
import ContentHeader from '../../components/ContentHeader'
import EditMarket from '../../forms/Market'
import MarketsDropdown from '../../components/MenuDropdown/Markets'
import MenuDropdown from '../../components/MenuDropdown'

import { withRouter, Link } from 'react-router-dom'
import { withRoutes, withShowInactiveRoutes } from '../../connectors/routes'

@withRouter
@withRoutes
@withShowInactiveRoutes
export default class Header extends React.PureComponent {
  state = {
    market: null,
    route: null,
  }

  onSelectMarket = market => {
    const { history } = this.props

    if (!market) {
      return history.push(`/app/routes`)
    }
    history.push(`/app/routes/${market.id}`)
  }

  onSelectRoute = route => {
    const { history, match } = this.props
    const marketId = _get(match, 'params.marketId')

    history.push(`/app/routes/${marketId}/${route.id}`)
  }

  render() {
    const {
      match,
      routes: { routes, loading },
      showInactiveRoutesStatus,
      setShowInactiveRoutesStatus,
    } = this.props
    const marketId = _get(match, 'params.marketId')
    const routeId = _get(match, 'params.routeId')

    return (
      <ContentHeader title="Routes" showBackButton>
        <MarketsDropdown
          showAll={false}
          selected={marketId}
          onSelect={this.onSelectMarket}
          loading={loading}
          menu
        />
        {marketId &&
          routeId && (
            <MenuDropdown
              label="Routes"
              options={routes || []}
              loading={loading}
              active={_find(routes, { id: routeId })}
              activeLabel="Route"
              showAll={false}
              onSelect={this.onSelectRoute}
            />
          )}
        {!routeId && (
          <div className="Routes-header-checkbox">
            <Radio
              label="Active"
              name="showInactiveRoute"
              value="activeRoutes"
              checked={showInactiveRoutesStatus === 'activeRoutes'}
              onChange={(e, { value }) => setShowInactiveRoutesStatus(value)}
            />
            <Radio
              label="Inactive"
              name="showInactiveRoute"
              value="inactiveRoutes"
              checked={showInactiveRoutesStatus === 'inactiveRoutes'}
              onChange={(e, { value }) => setShowInactiveRoutesStatus(value)}
            />
          </div>
        )}
        <Link to={`/app/market/${marketId}`} className="item" target="_blank">
          <Icon name="map" />
          View Map
        </Link>
        <ModalTrigger
          trigger={
            <a className="item">
              <Icon name="pencil" />
              Edit Market
            </a>
          }
          form={<EditMarket marketId={marketId} />}
          title="Editing Market"
        />
        <ModalTrigger
          title="All Routes End of Month Report"
          trigger={
            <a className="item">
              <Icon name="download" />
              EOM All Routes
            </a>
          }
          form={<AllRoutesReportForm marketId={marketId} />}
        />
      </ContentHeader>
    )
  }
}
