import { withMarketData, withMarkets } from '../../connectors/markets'
import React from 'react'
import 'url-search-params-polyfill'

import { Divider } from 'semantic-ui-react'

import { withAuth } from '../../connectors/auth'
import { WithRoutCostReport } from '../../connectors/routeCostAnalysis'
import Filters from './Filters'
import RouteCostAnalysisReport from './RouteCostAnalysisReport'

@withAuth
@withMarkets
@withMarketData
@WithRoutCostReport
export class ReportRouteCostAnalysisViewIndex extends React.Component {
  extractRoutes() {
    const { routeCostAnalysisData } = this.props
    const routes = []

    if (!routeCostAnalysisData) return routes
    if (!routeCostAnalysisData.getRouteCost) return routes

    routeCostAnalysisData.getRouteCost.forEach(yearReport => {
      if (yearReport.reports.length === 1)
        yearReport.reports[0].routes.forEach(routeReport => {
          if (!routes.some(route => route.id === routeReport.id))
            routes.push({
              id: routeReport.id,
              name: routeReport.name,
            })
        })
    })

    return routes
  }

  render() {
    const {
      location: { search },
      auth: { user: { markets } },
      loading,
      routeCostAnalysisData,
    } = this.props

    const query = new URLSearchParams(search)
    const routes = this.extractRoutes()

    return (
      <div>
        <Divider hidden />
        <Filters loading={loading} routes={routes} />
        <Divider hidden />
        <RouteCostAnalysisReport
          marketId={query.get('market')}
          routes={routes}
          date={query.get('date')}
          routeId={query.get('route') ? query.get('route').split(',') : []}
          include_previous_year={
            query.get('include-previous-year') &&
            query.get('include-previous-year') === 'true'
              ? true
              : false
          }
          markets={markets}
          loading={loading}
          routeCostAnalysisData={routeCostAnalysisData}
        />
      </div>
    )
  }
}
