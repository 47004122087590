import './styles.scss'

import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Dropdown, Card, Icon, Button, Popup } from 'semantic-ui-react'

import { withBinActivateAction } from '../../../connectors/bins'

const cricleStyle = color => ({
  backgroundColor: color,
  borderRadius: '3px',
  display: 'inline-block',
  marginBottom: '5px',
  marginRight: '5px',
  height: '20px',
  width: '20px',
})

const binCardClass = colors => {
  if (colors.length > 0) return 'BinCard has-color'
  else return 'BinCard'
}

const ActionsDropdown = withRouter(({ match: { url }, data }) => (
  <Dropdown
    icon={null}
    className="BinCard_actions"
    trigger={
      <Button icon color="orange">
        <Icon name="align justify" />
      </Button>
    }
  >
    <Dropdown.Menu>
      <Dropdown.Item>
        <Link to={`${url}/${data.id}?view=true`}>View Bin</Link>
      </Dropdown.Item>

      <Dropdown.Item>
        <Link to={`${url}/${data.id}?edit=true`}>Edit Bin</Link>
      </Dropdown.Item>

      <Dropdown.Item>
        <Link to={`${url}/${data.id}?maintenance-request=true`}>
          Create Maintenance Request
        </Link>
      </Dropdown.Item>

      <Dropdown.Item>
        <Link to={`${url}/${data.id}?police-report=true`}>
          Create Police Report
        </Link>
      </Dropdown.Item>

      {!data.locationId && (
        <Dropdown.Item>
          <Link to={`${url}/${data.id}?deactivate=true`}>Deactivate Bin</Link>
        </Dropdown.Item>
      )}
    </Dropdown.Menu>
  </Dropdown>
))

const CardInfo = ({ content, icon }) => (
  <Card.Description>
    <Icon name={icon} />
    {content}
  </Card.Description>
)

const CardColors = ({ colors }) => (
  <div>
    {colors.map(color => (
      <Popup
        content={color.name}
        position="top center"
        trigger={<i style={cricleStyle(color.color)} />}
        key={color.id}
      />
    ))}
  </div>
)

const BinCard = withBinActivateAction(({ activate, data }) => (
  <Card className={binCardClass(data.routes)}>
    <Card.Content>
      <CardColors colors={data.routes} />
      <Card.Header>
        {data.serial_number}
        {!data.deactivated && <ActionsDropdown data={data} />}
      </Card.Header>
    </Card.Content>
    <Card.Content>
      <CardInfo content={data.serial_number} />
      <CardInfo content={data.status} />
      {data.locationId && (
        <CardInfo
          content={
            <Link to={`/app/locations/${data.locationId}`}>
              <Icon name="pin" />
              {data.location && data.location.name}
            </Link>
          }
        />
      )}
      {!data.locationId && <CardInfo content="No location." />}
    </Card.Content>

    {false &&
      !data.deactivated &&
      data.locationId && (
        <Button
          color="orange"
          disabled={false}
          onClick={e => {
            e.preventDefault()
            console.log('//TODO: Handle unassign of Bin')
          }}
        >
          <Icon name="window close" />
          Unassign
        </Button>
      )}

    {false &&
      !data.deactivated &&
      !data.locationId && (
        <Button
          color="orange"
          disabled={false}
          onClick={e => {
            e.preventDefault()
            console.log('//TODO: Handle assign of Bin')
          }}
        >
          <Icon name="marker" />
          Assign
        </Button>
      )}

    {data.deactivated && (
      <Button
        color="red"
        disabled={false}
        onClick={e => {
          e.preventDefault()
          activate(data.id)
        }}
      >
        <Icon name="checkmark" />
        Activate
      </Button>
    )}
  </Card>
))

export default BinCard
