import React from 'react'
import _map from 'lodash/map'
import _isFunction from 'lodash/isFunction'

import { Dropdown } from 'semantic-ui-react'
import { MenuDropdown } from './index'
import Confirmation from '../Confirmation'

export const RoutesDropdown = ({ routes, ...props }) => {
  const options = _map(routes, ({ id, name, color }) => ({
    key: id,
    value: id,
    text: name,
    icon: { name: 'circle', style: { color } },
  }))
  return <Dropdown {...props} options={options} />
}
export default class RoutesDropDown extends React.PureComponent {
  render() {
    const { active, ...props } = this.props

    return (
      <MenuDropdown label="Routes" active={active}>
        <RoutesDropdown {...props} />
      </MenuDropdown>
    )
  }
}

const getConfirmationProp = (value, option) =>
  _isFunction(value) ? value(option) : value
const DropdownItem = ({ confirmation, onChange, ...props }) => {
  if (confirmation) {
    const { text, loadingTitle, loadingText, message } = confirmation
    return (
      <Confirmation
        text={getConfirmationProp(text, props)}
        loadingTitle={getConfirmationProp(loadingTitle, props)}
        loadingText={getConfirmationProp(loadingText, props)}
        message={getConfirmationProp(message, props)}
        trigger={<Dropdown.Item {...props} />}
        onConfirm={() => onChange(props)}
      />
    )
  }

  return <Dropdown.Item {...props} onClick={() => onChange(props)} />
}

export const RoutesDropdownMenu = ({
  routes,
  confirmation,
  onChange,
  ...props
}) => {
  const options = _map(routes, ({ id, name, color, ...route }) => ({
    ...route,
    key: id,
    value: id,
    text: name,
    icon: { name: 'circle', style: { color } },
    confirmation,
    onChange,
  }))

  return (
    <Dropdown {...props}>
      <Dropdown.Menu>
        {options &&
          _map(options, option => <DropdownItem key={props.key} {...option} />)}
      </Dropdown.Menu>
    </Dropdown>
  )
}
