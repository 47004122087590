import _get from 'lodash/get'
import moment from 'moment'

import * as QL from '../api/Query/reports'
import WithQuery from './query'

export const WithLocationsReport = WithQuery({
  selector: data => ({ data }),
  selectorWithProps: ({ data }, props) => {
    const {
      leaseStart,
      leaseEnd,
      status,
      npo,
      pm,
      setPMs,
      routeId,
      reportType,
      start,
      end,
    } = props

    const leaseStartFilter = moment(leaseStart).startOf('day')
    const leaseEndFilter = moment(leaseEnd).startOf('day')

    const startFilter = moment(start).startOf('day')
    const endFilter = moment(end).startOf('day')

    let locationsReport = data.locationsReport
    const pmsMap = {}
    const pms = []

    locationsReport = (locationsReport || []).filter(loc => {
      let found = true
      if (status) {
        found = found && loc.status === status
      }
      if (npo) {
        found = found && loc.npo_name === npo
      }

      if (pm) {
        if (reportType === 'property_manager') {
          const partnership = loc.partnership || {}
          found = found && partnership.name === pm
        } else {
          if (loc.partnership) found = found && loc.partnership.name === pm
          else found = false
        }
      }

      if (leaseStart && leaseEnd) {
        const leasedLocationPeriodStart = moment(
          loc.leased_location_period_start
        ).startOf('day')
        const leasedLocationPeriodEnd = moment(
          loc.leased_location_period_end
        ).startOf('day')
        found =
          found &&
          ((leasedLocationPeriodStart.isSameOrAfter(leaseStartFilter) &&
            leasedLocationPeriodStart.isSameOrBefore(leaseEndFilter)) ||
            (leasedLocationPeriodEnd.isSameOrAfter(leaseStartFilter) &&
              leasedLocationPeriodEnd.isSameOrBefore(leaseEndFilter)) ||
            (leaseStartFilter.isSameOrAfter(leasedLocationPeriodStart) &&
              leaseEndFilter.isSameOrBefore(leasedLocationPeriodEnd)))
      }

      if (reportType === 'permit' && start) {
        const permitIssueDate = moment(loc.permit.issued_date).startOf('day')
        const permitExpirationDate = moment(loc.permit.expiration_date).startOf(
          'day'
        )
        found =
          found &&
          ((permitIssueDate.isSameOrAfter(startFilter) &&
            permitIssueDate.isSameOrBefore(endFilter)) ||
            (permitExpirationDate.isSameOrAfter(startFilter) &&
              permitExpirationDate.isSameOrBefore(endFilter)) ||
            (startFilter.isSameOrAfter(permitIssueDate) &&
              endFilter.isSameOrBefore(permitExpirationDate)))
      }

      if (reportType === 'property_manager') {
        const partnership = loc.partnership || {}
        if (partnership.name && !pmsMap[partnership.name]) {
          pmsMap[partnership.name] = true
          pms.push({
            text: partnership.name,
            value: partnership.name,
          })
        }
      } else {
        if (loc.partnership) {
          if (loc.partnership.name && !pmsMap[loc.partnership.name]) {
            pmsMap[loc.partnership.name] = true
            pms.push({
              text: loc.partnership.name,
              value: loc.partnership.name,
            })
          }
        }
      }

      return found
    })

    if (reportType === 'property_manager') {
      locationsReport.sort((a, b) =>
        a.partnership.name.localeCompare(b.partnership.name)
      )
    } else if (!routeId) {
      locationsReport.sort((a, b) => a.route.name.localeCompare(b.route.name))
    }

    setPMs(pms)

    return {
      locationsReport,
    }
  },
  QL,
  queryName: 'locationsReport',
  variablesPicker: props => {
    const { routeId, marketId, reportType: type } = props
    return { routeId, marketId, type }
  },
  fetchPolicy: 'network-only',
})

export const WithDriversReport = WithQuery({
  selector: data => ({ data }),
  selectorWithProps: ({ data = {} }, props) => {
    const { location: { search } } = props

    const query = new URLSearchParams(search)
    const marketId = query.get('market') || null
    const routeId = query.get('routeId') || null
    const driverId = query.get('driverId') || null

    let rawDriversReport = data.driversReport || []
    const drivers = []

    // Get drivers list
    rawDriversReport.forEach(run => {
      if (!drivers.some(driver => driver.value === run.driverId))
        drivers.push({
          text: run.driver,
          value: run.driverId,
        })
    })

    const driversReportFilteredRuns = rawDriversReport.filter(run => {
      let found = true

      if (marketId) {
        found = found && run.marketId === marketId
      }

      if (routeId) {
        found = found && run.routeId === routeId
      }

      if (driverId) {
        found = found && run.driverId === driverId
      }

      return found
    })

    const driversReportMap = {}
    driversReportFilteredRuns.forEach(run => {
      const runLocations = _get(run, 'locations') || []
      const totalEstimate = runLocations.reduce((ac, location) => {
        return (ac += (location.bins || []).reduce(
          (ac, bin) => (ac += _get(bin, 'report.weight', 0)),
          0
        ))
      }, 0)

      const totalActual = run.total_cloth
      let accuracy = 0

      if (totalActual && totalEstimate) {
        accuracy = Math.max(
          0,
          Math.floor(
            100 - Math.abs(totalEstimate - totalActual) / totalActual * 100
          )
        )
      }

      const composedId = `${run.driverId}-${run.routeId}`

      const entry = {
        id: composedId,
        driver: run.driver,
        route: run.route,
        frequency: 1,
        lbs:
          (run.total_cloth || 0) +
          (run.total_misc || 0) +
          (run.total_trash || 0),
        miles: (run.end_miles || 0) - (run.start_miles || 0),
        hours:
          run.start_time && run.end_time
            ? moment
                .duration(moment(run.end_time).diff(moment(run.start_time)))
                .asHours()
            : 0,
        accuracy,
        routeId: run.routeId,
        driverId: run.driverId,
      }

      if (driversReportMap[composedId]) {
        const currentRouteData = driversReportMap[composedId]
        driversReportMap[composedId].frequency = currentRouteData.frequency + 1
        driversReportMap[composedId].lbs = currentRouteData.lbs + entry.lbs
        driversReportMap[composedId].miles =
          currentRouteData.miles + entry.miles
        driversReportMap[composedId].hours =
          currentRouteData.hours + entry.hours
        driversReportMap[composedId].accuracy =
          currentRouteData.accuracy + entry.accuracy
      } else {
        driversReportMap[composedId] = entry
      }
    })

    const totalRow = {
      isTotalRow: true,
      driver: 'Total',
      route: '',
      frequency: 0,
      lbs: 0,
      miles: 0,
      hours: 0,
      accuracy: 0,
      avgLbs: 0,
      avgMiles: 0,
      avgHours: 0,
    }

    const driversReport = Object.keys(driversReportMap).map(id => {
      const rawEntry = driversReportMap[id]
      const entry = Object.assign({}, rawEntry, {
        avgLbs: rawEntry.lbs / rawEntry.frequency,
        avgMiles: rawEntry.miles / rawEntry.frequency,
        avgHours: rawEntry.hours / rawEntry.frequency,
        accuracy: rawEntry.accuracy / rawEntry.frequency,
      })

      totalRow.frequency += entry.frequency
      totalRow.lbs += entry.lbs
      totalRow.miles += entry.miles
      totalRow.hours += entry.hours
      totalRow.accuracy += entry.accuracy
      totalRow.avgLbs += entry.avgLbs
      totalRow.avgMiles += entry.avgMiles
      totalRow.avgHours += entry.avgHours

      return entry
    })

    if (driversReport.length) {
      driversReport.push({
        ...totalRow,
        avgLbs: totalRow.lbs / totalRow.frequency,
        avgMiles: totalRow.miles / totalRow.frequency,
        avgHours: totalRow.hours / totalRow.frequency,
        accuracy: totalRow.accuracy / totalRow.frequency,
      })
    }

    return {
      drivers,
      driversReport,
    }
  },
  QL,
  queryName: 'driversReport',
  variablesPicker: props => {
    const { location: { search }, current } = props
    const query = new URLSearchParams(search)

    const market = current ? current.id : null
    let startAt = moment()
      .subtract(2, 'month')
      .set('date', 1)
      .startOf('day')
      .toDate()
    let endAt = moment()
      .add(1, 'month')
      .set('date', 1)
      .subtract(1, 'day')
      .endOf('day')
      .toDate()

    if (query.get('start') && query.get('end')) {
      startAt = new Date(query.get('start'))
      endAt = new Date(query.get('end'))
    }

    return { market, startAt, endAt }
  },
  fetchPolicy: 'network-only',
})
