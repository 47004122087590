import gql from 'graphql-tag'

export const locationsReport = gql`
  query($marketId: String, $routeId: String, $type: String) {
    locationsReport(
      filters: { marketId: $marketId, routeId: $routeId, type: $type }
    ) {
      id
      name
      city
      address
      state
      postal
      latitude
      longitude
      status
      gis_number
      bins_count
      npo_name
      npo_cost_per_lb
      leased_location_pm
      leased_location_cost_per_month
      leased_location_period_start
      leased_location_period_end
      signed_location_by
      signed_location_date
      verbal_location_by
      verbal_location_date
      leased_atrs_rep
      signed_atrs_rep
      verbal_atrs_rep
      permit_required
      permit {
        id
        signed_by
        email
        address
        phone
        city
        permit_number
        issued_date
        expiration_date
        status
      }
      partnership {
        id
        name
        store
        position
        telephone
        email
        address
      }
      rate
      route {
        id
        name
        color
        code
      }
      collections
    }
  }
`

export const driversReport = gql`
  query($market: String, $startAt: String!, $endAt: String!) {
    driversReport(
      filters: { market: $market, startAt: $startAt, endAt: $endAt }
    ) {
      id
      driverId
      driver
      end_miles
      end_time
      helper
      routeId
      route
      marketId
      run_date
      start_miles
      start_time
      truck_number
      total_cloth
      total_misc
      total_trash
      weather
      deleted
      locations {
        location {
          id
          name
        }
        bins {
          box_code
          id
          report {
            weight
          }
          weight
        }
      }
    }
  }
`
