import React from 'react'
import moment from 'moment'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'

import { Button, Modal, Feed, Message, Icon } from 'semantic-ui-react'
import { WithLocationTagEvents } from '../../connectors/locations'

const TagEvent = ({ title, driver, children, timestamp }) => (
  <Feed.Event>
    <Feed.Label>
      <Icon name="tag" />
    </Feed.Label>
    <Feed.Content>
      <Feed.Summary>
        <Feed.User>{_get(driver, 'first_name')}</Feed.User> {title}
        <Feed.Date>{moment(timestamp).calendar()}</Feed.Date>
      </Feed.Summary>
      {!_isEmpty(children) && (
        <Feed.Meta>
          {_map(children, children =>
            _get(children, 'title', children.label)
          ).join()}
        </Feed.Meta>
      )}
    </Feed.Content>
  </Feed.Event>
)

@WithLocationTagEvents
export default class LocationTagEvents extends React.PureComponent {
  state = { open: false }

  onOpen = () => {
    this.setState({ open: true })
  }

  close = () => this.setState({ open: false })

  render() {
    const { trigger, loading, location } = this.props
    const { open, error } = this.state
    const tagEvents = _get(location, 'history', [])

    return (
      <Modal
        trigger={trigger}
        dimmer="blurring"
        size="tiny"
        open={open}
        onOpen={this.onOpen}
        onClose={this.close}
      >
        <Modal.Header>Location History Tags</Modal.Header>
        <Modal.Content scrolling>
          <Feed>
            {tagEvents &&
              _map(tagEvents, event => <TagEvent {...event} key={event.id} />)}
          </Feed>
          {loading && (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                We are fetching the tags.
              </Message.Content>
            </Message>
          )}
          {error && <Message error header={error} />}
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Close"
            onClick={this.close}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
