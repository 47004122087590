import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { Card, Segment } from 'semantic-ui-react'
import { Row, Col } from 'react-flexbox-grid'

import RouteCard from '../RouteCard'

const RoutesGrid = ({
  routes,
  loading,
  children,
  match: { params: { marketId } },
}) => {
  let showNoRoutesMessage = false

  if (routes === null || routes.length === 0) {
    showNoRoutesMessage = true
  }

  return (
    <Segment loading={loading} className="Routes__Segment">
      <Row start="xs" className="Routes__Row">
        {routes &&
          routes.map(route => (
            <Col xs={6} sm={4} md={3} key={route.id}>
              <Link to={`/app/routes/${marketId}/${route.id}`}>
                <RouteCard data={route} />
              </Link>
            </Col>
          ))}
        {showNoRoutesMessage && (
          <Card fluid header="No Routes on this Market" />
        )}
      </Row>
    </Segment>
  )
}

export default withRouter(RoutesGrid)
