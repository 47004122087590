import { withAuth } from '../../connectors/auth'
import { withMarkets } from '../../connectors/markets'
import _find from 'lodash/find'
import PropTypes from 'prop-types'
import React from 'react'

import MenuDropdown from '../MenuDropdown'
import { Dropdown } from 'semantic-ui-react'

@withAuth
@withMarkets
export default class MarketsDropdown extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    setCurrentOnSelect: PropTypes.bool,
    menu: PropTypes.bool,
  }

  static defaultProps = {
    setCurrentOnSelect: true,
    onSelect: () => {},
    menu: false,
  }

  componentDidMount() {
    const {
      current,
      onSelect,
      selected,
      setCurrent,
      multiple,
      auth: { user: { primary_market, markets } },
    } = this.props

    if (!multiple) {
      if (!!selected) {
        setCurrent(selected)
      } else if (!selected && !!current) {
        onSelect(current)
      } else if (!!primary_market) {
        onSelect(markets.find(market => market.id === primary_market))
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      markets: { loading: nextLoading },
      selected: nextSelected,
    } = nextProps
    const { markets: { loading }, selected } = this.props
    return selected !== nextSelected || loading !== nextLoading
  }

  componentDidUpdate(prevProps) {
    const { selected: oldSelected } = prevProps
    const { selected, setCurrent, multiple } = this.props

    if (!multiple) {
      if (selected !== oldSelected) setCurrent(selected)
      if (selected === undefined) {
        setCurrent(null)
      }
    } else {
      if (selected.length === 1) setCurrent(selected[0].id)
      else setCurrent(null)
    }
  }

  render() {
    const {
      selected,
      label = 'All Markets',
      activeLabel = 'Market',
      showAll = true,
      auth: { user: { markets } },
      markets: { loading },
      menu,
      onSelect,
      multiple,
      onClose,
    } = this.props

    const active = _find(markets, { id: selected })

    if (menu) {
      return (
        <MenuDropdown
          label={label}
          active={active}
          activeLabel={activeLabel}
          loading={loading}
          options={markets}
          showAll={showAll}
          onSelect={onSelect}
        />
      )
    }

    let options = markets.map(market => ({
      key: market.id,
      text: market.name,
      value: market,
    }))

    if (showAll) {
      // Our app generally treats the value of 'All Markets' as null,
      // but the semantic-ui-react Dropdown component shows the placeholder
      // on null value, so here we give the 'All Marketds' option a
      // non-null value and then set it to null when calling onSelect
      options.unshift({
        key: 'all',
        text: label,
        value: 'all',
      })
    }

    if (multiple) {
      return (
        <Dropdown
          options={options}
          multiple={multiple}
          placeholder={label}
          disabled={loading}
          loading={loading}
          onChange={(e, { value }) => {
            onSelect(value !== 'all' ? value : null)
          }}
          fluid
          selection
          value={selected}
          onClose={(e, data) => (onClose ? onClose(e, data) : null)}
        />
      )
    } else {
      return (
        <Dropdown
          options={options}
          placeholder={label}
          defaultValue={active ? active.name : label}
          disabled={loading}
          loading={loading}
          onChange={(e, { value }) => {
            onSelect(value !== 'all' ? value : null)
          }}
          text={active ? active.name : label}
          fluid
          selection
          onClose={(e, data) => (onClose ? onClose(e, data) : null)}
        />
      )
    }
  }
}
