import React from 'react'
import _get from 'lodash/get'
import _split from 'lodash/split'

import WithQuerySearch from '../../connectors/querySearch'

import { Segment, Divider } from 'semantic-ui-react'
import { SearchListLocations, ItemLocation } from '../../components/ListLocations'
import ModalTrigger from '../../components/ModalTrigger'
import AddLocationForm from '../../forms/AddLocation'

import './styles.scss'

const renderItem = (location, index) => (
  <ModalTrigger
    trigger={<ItemLocation {...location} />}
    form={<AddLocationForm location={location} />}
    key={index}
  />
)

@WithQuerySearch
export default class Market extends React.PureComponent {
  render() {
    const { loading, search, locationsFilter } = this.props
    const binSearch = _split(_get(search, 'bin_search', ''), ',')

    return (
      <Segment
        padded={false}
        loading={loading}
        className="BinsSearch__container"
      >
        <Divider hidden />
        {binSearch && (
          <SearchListLocations
            geoCoordinates={binSearch}
            renderItem={renderItem}
            locationsFilter={locationsFilter}
          />
        )}
      </Segment>
    )
  }
}
