import React from 'react'
import { Header } from 'semantic-ui-react'

import SearchCloseLocationsBar from './SearchCloseLocationsBar'
import BinsSearchView from '../../views/BinsSearch'
import locationsFilterOptions from './locationsFilterOptions'
export default class CloseLocationsPanel extends React.PureComponent {
  state = {
    locationsFilter: locationsFilterOptions.Active,
  }

  setLocationsFilter(locationsFilter) {
    this.setState({ locationsFilter })
  }

  render() {
    const { locationsFilter } = this.state

    return (
      <React.Fragment>
        <Header size="large">Search nearby locations</Header>
        <SearchCloseLocationsBar
          setLocationsFilter={this.setLocationsFilter.bind(this)}
          locationsFilter={locationsFilter}
        />
        <BinsSearchView locationsFilter={locationsFilter} />
      </React.Fragment>
    )
  }
}
