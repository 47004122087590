import ModalTrigger from '../../components/ModalTrigger'
import PartnershipForm from '../../forms/Partnership'
import _get from 'lodash/get'
import moment from 'moment'
import React, { Fragment } from 'react'
import { Button, Card, Header, Icon, Segment } from 'semantic-ui-react'

const Signed = ({ partnership }) => (
  <Fragment>
    <Header as="h4" style={{ margin: '0 0 0 0' }}>
      Signed
    </Header>
    <div>
      by <strong>{partnership.signed_location_by}</strong>
      <br />
      on{' '}
      <strong>
        {moment(partnership.signed_location_date).format('MMM DD, YYYY')}
      </strong>
      <br />
      ATRS rep <strong>{partnership.signed_atrs_rep}</strong>
    </div>
  </Fragment>
)

const Leased = ({ partnership }) => (
  <Fragment>
    <Header as="h4" style={{ margin: '0 0 0 0' }}>
      Leased
    </Header>
    <div>
      Monthly Cost <strong>{partnership.leased_location_cost_per_month}</strong>
      <br />
      Start on{' '}
      <strong>
        {moment(partnership.leased_location_period_start).format(
          'MMM DD, YYYY'
        )}
      </strong>
      <br />
      End on{' '}
      <strong>
        {moment(partnership.leased_location_period_end).format('MMM DD, YYYY')}
      </strong>
      <br />
      ATRS rep <strong>{partnership.leased_atrs_rep}</strong>
    </div>
  </Fragment>
)

const Verbal = ({ partnership }) => (
  <Fragment>
    <Header as="h4" style={{ margin: '0 0 0 0' }}>
      Verbal
    </Header>
    <div>
      by <strong>{partnership.verbal_location_by}</strong>
      <br />
      on{' '}
      <strong>
        {moment(partnership.verbal_location_date).format('MMM DD, YYYY')}
      </strong>
      <br />
      ATRS rep <strong>{partnership.verbal_atrs_rep}</strong>
    </div>
  </Fragment>
)

export const PartnershipCard = ({
  name,
  store,
  position,
  partnership,
  onChange,
  size,
  showEditTrigger = true,
  fullDetails = false,
  ...props
}) => {
  return (
    <Card>
      {!name && (
        <Segment vertical textAlign="center">
          <Header as={_get(props, 'as', 'h3')} icon>
            <Icon name="address card" />
          </Header>
        </Segment>
      )}
      {name && (
        <Card.Content>
          {showEditTrigger && (
            <ModalTrigger
              title="Editing partnership"
              trigger={
                <Button
                  icon="pencil"
                  circular
                  floated="right"
                  onClick={ev => ev.preventDefault()}
                />
              }
              form={
                <PartnershipForm
                  onSubmit={onChange}
                  partnership={partnership}
                />
              }
            />
          )}
          <Card.Header>{name}</Card.Header>
          <Card.Meta>
            {position && (
              <Fragment>
                {position} at {store}
              </Fragment>
            )}
          </Card.Meta>
          <Card.Description>
            {partnership.email && (
              <Fragment>
                Email <strong> {partnership.email}</strong>
              </Fragment>
            )}
            {partnership.telephone && (
              <Fragment>
                <br />
                Phone <strong>{partnership.telephone}</strong>
              </Fragment>
            )}
            {partnership.address && (
              <Fragment>
                <br />
                Address <strong>{partnership.address}</strong>
                <br />
              </Fragment>
            )}
            {partnership.signed_location_by && [
              <br />,
              <Signed partnership={partnership} />,
            ]}
            {partnership.leased_location_cost_per_month && [
              <br />,
              <Leased partnership={partnership} />,
            ]}
            {partnership.verbal_location_by && [
              <br />,
              <Verbal partnership={partnership} />,
            ]}
          </Card.Description>
        </Card.Content>
      )}
    </Card>
  )
}
