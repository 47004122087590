import React from 'react'
import moment from 'moment/moment'
import { List, Label, Grid } from 'semantic-ui-react'
import {
  contractSelectionLabel,
  parseCompetitorHistoryType,
} from '../../utils/competitors'

export class CompetitorHistory extends React.Component {
  render() {
    const { competitorHistory } = this.props

    const sortedCompetitors = competitorHistory.sort(
      (competitorPrev, competitorNext) =>
        moment(competitorPrev.timestamp).isBefore(competitorNext.timestamp)
          ? 1
          : -1
    )

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row only="computer">
            <Grid.Column>
              <List
                divided
                relaxed
                size="small"
                className="competitor_history_list"
              >
                {sortedCompetitors.map(history => (
                  <List.Item className="competitor_history_list_item">
                    <div class="competitor_history_list_item_title_labels">
                      <div class="competitor_history_list_item_title">
                        {history.competitor.label}
                      </div>

                      <div class="competitor_history_list_item_labels">
                        {history.competitor.selections.map(selection => (
                          <Label size="tiny" color="gray">
                            {contractSelectionLabel(selection.label)}
                          </Label>
                        ))}
                      </div>
                    </div>
                    <Label
                      basic
                      className="history_log_date"
                      style={{ textAlign: 'center', border: 'none !important' }}
                      color={history.type === 'add' ? 'green' : 'red'}
                    >
                      {parseCompetitorHistoryType(history.type)} on{' '}
                      {moment(history.timestamp).format('MMM DD, YYYY')}
                    </Label>
                  </List.Item>
                ))}
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only="mobile tablet">
            <Grid.Column>
              <List
                divided
                relaxed
                size="small"
                className="competitor_history_list mobile"
              >
                {sortedCompetitors.map(history => (
                  <List.Item className="competitor_history_list_item">
                    <div class="competitor_history_list_item_title_labels">
                      <div class="competitor_history_list_item_title">
                        {history.competitor.label}
                      </div>
                      <div class="competitor_history_list_item_labels">
                        {history.competitor.selections.map(selection => (
                          <Label size="tiny" color="gray">
                            {contractSelectionLabel(selection.label)}
                          </Label>
                        ))}
                      </div>
                    </div>
                    <Label
                      basic
                      className="history_log_date"
                      style={{ textAlign: 'center', border: 'none !important' }}
                      color={history.type === 'add' ? 'green' : 'red'}
                    >
                      <span>{parseCompetitorHistoryType(history.type)} on</span>
                      <span>
                        {moment(history.timestamp).format('MMM DD, YYYY')}
                      </span>
                    </Label>
                  </List.Item>
                ))}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }
}
