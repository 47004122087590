import apolloClient from '../api/apolloClient'
import { trigger } from '../actions'
import { getLocationCompetitorHistory } from '../api/Query/competitors'
import {
  addLocationCompetitor as addLocationCompetitorMutation,
  removeLocationCompetitor as removeLocationCompetitorMutation,
} from '../api/Mutation/competitors'

export const COMPETITORS_FETCH_SUCCESS = 'COMPETITORS_FETCH_SUCCESS'

export const COMPETITORS_REMOVE = 'COMPETITORS_REMOVE'
export const COMPETITORS_REMOVE_SUCCESS = 'COMPETITORS_REMOVE_SUCCESS'

export const COMPETITORS_ADD = 'COMPETITORS_ADD'
export const COMPETITORS_ADD_SUCCESS = 'COMPETITORS_ADD_SUCCESS'

export const COMPETITORS_START_lOADING = 'COMPETITORS_START_lOADING'
export const COMPETITORS_FINISH_lOADING = 'COMPETITORS_FINISH_lOADING'

export const COMPETITORS_ERROR_OCCURRED = 'COMPETITORS_ERROR_OCCURRED'
export const COMPETITORS_RESET_STATUS = 'COMPETITORS_RESET_STATUS'

export const getLocationCompetitors = locationId => {
  return dispatch => {
    dispatch(trigger(COMPETITORS_RESET_STATUS)())
    dispatch(trigger(COMPETITORS_START_lOADING)())

    return apolloClient
      .query({
        query: getLocationCompetitorHistory,
        variables: {
          locationId,
        },
        fetchPolicy: 'network-only',
      })
      .then(res =>
        trigger(COMPETITORS_FETCH_SUCCESS)(res.data.getLocationCompetitor)
      )
      .catch(error => trigger(COMPETITORS_ERROR_OCCURRED)(error.message))
      .then(dispatch)
  }
}

export const addLocationCompetitor = (locationId, competitor, type) => {
  return dispatch => {
    dispatch(trigger(COMPETITORS_RESET_STATUS)())
    dispatch(trigger(COMPETITORS_START_lOADING)())

    return apolloClient
      .mutate({
        mutation: addLocationCompetitorMutation,
        variables: {
          locationId,
          competitor,
          selection: type,
        },
      })
      .then(res => {
        return trigger(COMPETITORS_ADD_SUCCESS)(res.data.addLocationCompetitor)
      })
      .catch(error => {
        return trigger(COMPETITORS_ERROR_OCCURRED)(error.message)
      })
      .then(dispatch)
  }
}

export const removeLocationCompetitor = (locationId, competitorId) => {
  return dispatch => {
    dispatch(trigger(COMPETITORS_RESET_STATUS)())
    dispatch(trigger(COMPETITORS_START_lOADING)())

    return apolloClient
      .mutate({
        mutation: removeLocationCompetitorMutation,
        variables: {
          locationId,
          competitorId,
        },
      })
      .then(res => {
        return trigger(COMPETITORS_REMOVE_SUCCESS)(
          res.data.removeLocationCompetitor
        )
      })
      .catch(error => {
        return trigger(COMPETITORS_ERROR_OCCURRED)(error.message)
      })
      .then(dispatch)
  }
}

export const resetCompetitorsStatus = () => {
  return dispatch => {
    dispatch(trigger(COMPETITORS_RESET_STATUS)())
  }
}
