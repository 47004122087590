import {
  _ROUTES_ERROR,
  _ROUTES_LOADING,
  _ROUTES_LOADED,
  _ROUTES_ADDED,
  _ROUTES_REMOVE,
  _ROUTES_COPY,
  _ROUTES_SET_CURRENT,
  _ROUTES_LOAD_ROUTE,
  _ROUTE_LOAD_RUN,
  _ROUTES_LOAD_ROUTE_DATA,
  _ROUTES_RUN_DELETED,
  _ROUTES_SET_SHOW_INACTIVE_ROUTES,
} from '../actions/routes'

import { _MARKETS_LOADING } from '../actions/markets'

const initialState = {
  routes: [],
  error: null,
  loading: false,
  current: null,
  route: null,
  run: null,
  data: null,
  showInactiveRoutes: 'activeRoutes',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _ROUTES_LOAD_ROUTE_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case _MARKETS_LOADING: {
      return {
        ...state,
        data: null,
      }
    }
    case _ROUTE_LOAD_RUN:
      return {
        ...state,
        run: action.payload,
      }
    case _ROUTES_RUN_DELETED:
      return {
        ...state,
        run: { ...state.run, deleted: new Date().toISOString() },
      }
    case _ROUTES_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        current: null,
        run: null,
      }

    case _ROUTES_LOADED:
      return {
        ...state,
        loading: false,
        routes: action.payload,
        error: null,
      }

    case _ROUTES_REMOVE:
      return {
        ...state,
        routes: state.routes.filter(route => route.id !== action.payload),
        loading: false,
      }

    case _ROUTES_COPY:
      return {
        ...state,
        loading: false,
        routes: state.routes
          ? [...state.routes, action.payload]
          : [action.payload],
      }

    case _ROUTES_LOAD_ROUTE:
      return {
        ...state,
        loading: false,
        route: action.payload,
        routes:
          state.routes &&
          state.routes.reduce((ac, route) => {
            const updateRoute = action.payload

            if (updateRoute.id === route.id) {
              // Route have change of Market, remove it from list
              if (updateRoute.market.id !== route.market.id) return ac
              else ac.push(updateRoute)
            } else {
              ac.push(route)
            }
            return ac
          }, []),
        error: null,
      }

    case _ROUTES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case _ROUTES_ADDED:
      return {
        ...state,
        loading: false,
        routes: state.routes
          ? [...state.routes, action.payload]
          : [action.payload],
      }
    case _ROUTES_SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      }
    case _ROUTES_SET_SHOW_INACTIVE_ROUTES:
      return {
        ...state,
        showInactiveRoutes: action.payload,
      }
    default:
      return state
  }
}

export default reducer
