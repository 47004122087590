import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { NavLink } from 'react-router-dom'
import validator from 'validator'
import { Button, Form, Grid, Message } from 'semantic-ui-react'

export const validate = ({ email, password }) => {
  const errors = {}

  if (!email || validator.isEmpty(email)) {
    errors.email = 'invalid/required'
  }

  if (email && !validator.isEmail(email)) {
    errors.email = 'invalid/email'
  }

  return errors
}

const FormField = ({ formComponent, meta, ...props }) => {
  const Component = formComponent
  const error = meta.touched && meta.error
  return <Component error={!!error} {...props} />
}

const ForgotPasswordForm = ({
  handleSubmit,
  submitting,
  formError,
  formSuccess,
  pristine,
}) => (
  <Form onSubmit={handleSubmit}>
    <Form.Field>
      <Field
        id="ForgotPasswordForm_email"
        name="email"
        component={FormField}
        type="email"
        label="Email"
        placeholder="you@domain.com"
        formComponent={Form.Input}
      />
    </Form.Field>
    {formError && (
      <Message
        negative
        header="Could not reset your password"
        content={formError.message}
      />
    )}
    {formSuccess && (
      <Message positive header="Success" content={formSuccess.message} />
    )}
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <NavLink id="ForgotPasswordForm_login" to="/auth/login">
            Back to Login
          </NavLink>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            id="ForgotPasswordForm_submit"
            type="submit"
            disabled={pristine || submitting}
          >
            Submit
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
)

export default reduxForm({
  form: 'ForgotPasswordForm',
  validate,
})(ForgotPasswordForm)
