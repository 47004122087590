import React from 'react'
import { withRoutes } from '../../connectors/routes'
import { Button, Modal } from 'semantic-ui-react'

@withRoutes
export default class OptimzeRoute extends React.PureComponent {
  state = {
    valid: {},
  }

  close = () => this.setState({ valid: true })

  validateRoute(route) {
    const {
      start_location: start,
      end_location: end,
      unloading_location: unload,
    } = route

    const errors = {}

    if (!start || !start.latitude || !start.longitude) {
      errors.start = true
    }

    if (!end || !end.latitude || !end.longitude) {
      errors.end = true
    }

    if (!unload || !unload.latitude || !unload.longitude) {
      errors.unload = true
    }

    return errors
  }

  optimize = () => {
    const { route, optimizeRoute, getRoute } = this.props

    if (!route) {
      return
    }

    const valid = this.validateRoute(route)
    const { start, end, unload } = valid

    if (start || end || unload) {
      return this.setState({ valid })
    }

    return optimizeRoute(route.id).then(() => {
      getRoute(route.id)
    })
  }

  validString({ start, end, unload }) {
    const ret = []

    if (start) {
      ret.push('start')
    }

    if (end) {
      ret.push('end')
    }

    if (unload) {
      ret.push('unload')
    }

    // 0, 1, 2 are easy cases
    if (!ret.length) return ''
    if (ret.length === 1) return ret.join('')

    const last = ret.splice(ret.length - 1, 1)
    return `${ret.join(', ')} and ${last}`
  }

  render() {
    const { children } = this.props
    const { valid } = this.state

    const { start, end, unload } = valid

    if (start || end || unload) {
      return (
        <Modal size="tiny" open={start || end || unload} onClose={this.close}>
          <Modal.Header>Invalid Route</Modal.Header>
          <Modal.Content>
            <p>
              The route you're trying to optimize must have{' '}
              {this.validString(valid)} locations
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={this.close}
              icon="checkmark"
              labelPosition="right"
              content="Done"
            />
          </Modal.Actions>
        </Modal>
      )
    }

    return React.cloneElement(children, {
      onClick: this.optimize,
    })
  }
}
