import { withAuth } from '../../connectors/auth'
import React from 'react'
import { Route, Switch, matchPath, withRouter } from 'react-router-dom'

import MainHeader from '../../components/MainHeader'
import { Col, Grid, Row } from 'react-flexbox-grid'

import Nav from './Nav'

import './styles.scss'

import DashboardLayout from '../../layouts/Dashboard'
import MarketLayout from '../../layouts/Market'
import RoutesLayout from '../../layouts/Routes'
import UsersLayout from '../../layouts/Users'

import ReportsLayout from '../../layouts/Reports'
import ReportsAnnualGoalLayout from '../../layouts/ReportsAnnualGoal'
import ReportsDriversLayout from '../../layouts/ReportsDrivers'
import ReportsEntryLayout from '../../layouts/ReportsEntry'
import ReportsLeasedLocationsLayout from '../../layouts/ReportsLeasedLocations'
import ReportsLocationsPerformanceLayout from '../../layouts/ReportsLocationsPerformance'
import ReportsLocationsPropertyManagerLayout from '../../layouts/ReportsLocationsPropertyManager'
import ReportsMonthlyGoalLayout from '../../layouts/ReportsMonthlyGoal'
import ReportsMonthlyGoalDataEnrtyLayout from '../../layouts/ReportsMonthlyGoalDataEntry'
import ReportsNPOLocationsLayout from '../../layouts/ReportsNPOLocations'
import ReportsLocationsPermitLayout from '../../layouts/ReportsPermit'
import ReportsSignedOrVerbalLocationsLayout from '../../layouts/ReportsSignedOrVerbalLocations'

import BinManagementLayout from '../../layouts/Bins/BinManagement'
import BinsLayout from '../../layouts/Bins/Bins'
import MaintenanceRequestsLayout from '../../layouts/Bins/MaintenanceRequests'
import PoliceReportsLayout from '../../layouts/Bins/PoliceReports'
import LocationsLayout from '../../layouts/Locations'
import Schedule from '../../layouts/Schedule'
import ReportsCraRouteReport from '../ReportsCraRouteReport'
import ReportsCumulativeQuarterlyReviewRouteCostAnalysisLayout from '../ReportsCumulativeQuarterlyReviewRouteCostAnalysis'
import ReportsActualvsGoalLayout from '../ReportsGoalsVsActuals'
import ReportsPreviousYearComparisonLayout from '../ReportsPreviousYearComparison'
import ReportsQuarterlyRouteCostAnalysisLayout from '../ReportsQuarterlyRouteCostAnalysis'
import ReportsRouteCostAnalysisLayout from '../ReportsRouteCostAnalysis'

const Top = withAuth(({ auth }) => (
  <MainHeader
    user={{
      name: auth.user && `${auth.user.first_name} ${auth.user.last_name}`,
      // role: 'Manager',
    }}
  />
))

const Routes = () => (
  <Switch>
    <Route exact path="/" component={DashboardLayout} />
    <Route path="/app/dashboard" component={DashboardLayout} />
    <Route path="/app/users" component={UsersLayout} />

    <Route path="/app/reports/summary" component={ReportsLayout} />
    <Route path="/app/reports/drivers" component={ReportsDriversLayout} />
    <Route
      path="/app/reports/npo-locations"
      component={ReportsNPOLocationsLayout}
    />
    <Route
      path="/app/reports/leased-locations"
      component={ReportsLeasedLocationsLayout}
    />
    <Route
      path="/app/reports/permit"
      component={ReportsLocationsPermitLayout}
    />
    <Route
      path="/app/reports/property-manager"
      component={ReportsLocationsPropertyManagerLayout}
    />
    <Route
      path="/app/reports/verbal-locations"
      component={ReportsSignedOrVerbalLocationsLayout}
    />
    <Route
      path="/app/reports/locations-performance"
      component={ReportsLocationsPerformanceLayout}
    />
    <Route
      path="/app/reports/goals-data-entry"
      component={ReportsMonthlyGoalDataEnrtyLayout}
    />
    <Route
      path="/app/reports/actuals-vs-goals"
      component={ReportsActualvsGoalLayout}
    />
    <Route
      path="/app/reports/previous-year-comparison"
      component={ReportsPreviousYearComparisonLayout}
    />
    <Route
      path="/app/reports/cumulative-quarterly-review-route-cost-analysis"
      component={ReportsCumulativeQuarterlyReviewRouteCostAnalysisLayout}
    />
    <Route
      path="/app/reports/quarterly-route-cost-analysis"
      component={ReportsQuarterlyRouteCostAnalysisLayout}
    />
    <Route
      path="/app/reports/monthly-route-cost-analysis"
      component={ReportsRouteCostAnalysisLayout}
    />
    <Route
      path="/app/reports/monthly-goal"
      component={ReportsMonthlyGoalLayout}
    />
    <Route
      path="/app/reports/annual-goal"
      component={ReportsAnnualGoalLayout}
    />
    <Route path="/app/reports/cra-route" component={ReportsCraRouteReport} />
    <Route path="/app/reports/run/:runId?" component={ReportsLayout} />
    <Route path="/app/reports" component={ReportsEntryLayout} />
    <Route path="/app/schedule" component={Schedule} />
    <Route path="/app/routes/:marketId?/:routeId?" component={RoutesLayout} />
    <Route path="/app/market/:marketId" component={MarketLayout} />
    <Route path="/app/bins" component={BinsLayout} />
    <Route path="/app/bin-management" component={BinManagementLayout} />
    <Route
      path="/app/maintenance-requests"
      component={MaintenanceRequestsLayout}
    />
    <Route path="/app/police-reports" component={PoliceReportsLayout} />
    <Route path="/app/locations" component={LocationsLayout} />
  </Switch>
)

@withRouter
export default class App extends React.Component {
  render() {
    const { pathname } = this.props.location
    const isAppLayoutFullWidth = matchPath(pathname, {
      path: '/app/market/:marketId',
      exact: true,
      strict: false,
    })

    return (
      <Grid
        className={`app-layout__container${
          isAppLayoutFullWidth ? ' full-width' : ''
        }`}
      >
        <Row>
          <Col xs={12}>
            <Top />
          </Col>
        </Row>
        <Row between="xs">
          {!isAppLayoutFullWidth && (
            <React.Fragment>
              <Col xs={12} md={2} lg={1}>
                <Nav />
              </Col>
              <Col xs={12} md={10} lg={11}>
                <Routes />
              </Col>
            </React.Fragment>
          )}
          {isAppLayoutFullWidth && (
            <React.Fragment>
              <Col>
                <Nav />
              </Col>
              <Col className="content-column">
                <Routes />
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Grid>
    )
  }
}
