import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Header, Menu, Icon } from 'semantic-ui-react'
import CreateButton from '../CreateButton'
import ReportsButton from '../ReportsButton'

import './styles.scss'

export const HeaderItem = ({ text, children, ...props }) => {
  return (
    <Menu.Item {...props}>
      {text && <Header>{text}</Header>}
      {children && children}
    </Menu.Item>
  )
}

@withRouter
export default class ContentHeader extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    showCreateButton: PropTypes.bool,
    showBackButton: PropTypes.bool,
    showReportsButton: PropTypes.bool,
  }

  static defaultProps = {
    showCreateButton: true,
    showBackButton: false,
    showReportsButton: true,
    showPrintButton: false,
  }

  onBack = () => {
    const { history } = this.props
    history.goBack()
  }

  onPrintClick = () => {
    window.print()
  }

  render() {
    const {
      title,
      children,
      showCreateButton,
      showBackButton,
      showReportsButton,
      showPrintButton,
      contentRight,
      subtitle,
    } = this.props

    return (
      <Menu size="large" className="ContentHeader__Menu">
        {showBackButton && (
          <HeaderItem onClick={this.onBack} className="back-button">
            <Icon
              floated="right"
              name="chevron left"
              color="grey"
              size="large"
            />
          </HeaderItem>
        )}
        <Menu.Item>
          <Header>
            <span>{title}</span>
            {subtitle && <span class="header-subtitle">{subtitle}</span>}
          </Header>
        </Menu.Item>
        {children}
        <Menu.Menu position="right">
          {contentRight && contentRight}
          <HeaderItem>
            {showPrintButton && (
              <Button basic icon color="blue" onClick={this.onPrintClick}>
                <Icon name="print" /> Print
              </Button>
            )}
          </HeaderItem>
          <HeaderItem>{showReportsButton && <ReportsButton />}</HeaderItem>
          <HeaderItem>{showCreateButton && <CreateButton />}</HeaderItem>
        </Menu.Menu>
      </Menu>
    )
  }
}
