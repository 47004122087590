import React from 'react'

import { Grid, Row, Col } from 'react-flexbox-grid'

import { Divider } from 'semantic-ui-react'

import HeaderLayout from './HeaderLayout'
import CloseLocationsPanel from './CloseLocationsPanel'
import SearchPanel from './SearchPanel'
import './styles.scss'

export default class LocationsHealth extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Col xs={12}>
          <HeaderLayout />
        </Col>

        <Divider hidden />

        <Row>
          <Col xs={12} md={6}>
            <CloseLocationsPanel />
          </Col>
          <Col xs={12} md={6}>
            <SearchPanel />
          </Col>
        </Row>
      </Grid>
    )
  }
}
