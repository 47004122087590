import apolloClient from '../../api/apolloClient'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'
import pick from 'lodash/pick'
import moment from 'moment'
import React, { Fragment } from 'react'
import { Field, getFormValues, reduxForm } from 'redux-form'
import {
  Button,
  Confirm,
  Dimmer,
  Form,
  Grid,
  Header,
  Loader,
  Message,
} from 'semantic-ui-react'
import 'url-search-params-polyfill'
import { saveRouteCostMutation } from '../../api/Mutation/routeCost'
import FormField from '../../components/FormField'

import { connect } from 'react-redux'
import { allowDecimalNumber, numeric } from '../../utils/validations'
import './style.scss'

const routeReportTemplate = {
  id: null,
  name: null,
  bins: 0,
  runs: 0,
  total_lbs_collected: 0,
  average_lbs_collected: 0,
  truck_fixed_monthly_cost: 0,
  truck_cost_cpp: 0,
  total_mileage: 0,
  average_mileage: 0,
  average_mileage_cost: 0,
  average_mileage_cost_cpp: 0,
  average_fuel_cost: 0,
  average_fuel_cost_cpp: 0,
  salary: 0,
  salary_cost_cpp: 0,
  average_cost_per_run: 0,
  average_cost_per_bin: 0,
  route_cost_cpp: 0,
}

function formatAsDecimal(num, maximumFractionDigits = 2) {
  return Number(num || '').toLocaleString(undefined, {
    maximumFractionDigits,
  })
}

export default class RouteCostAnalysisReport extends React.PureComponent {
  render() {
    const { marketId, date, routeCostAnalysisData } = this.props
    const year = moment(date).year()
    const month = moment(date)
      .format('MMM')
      .toLowerCase()
    const currentYearData = routeCostAnalysisData
      ? routeCostAnalysisData.getRouteCost
        ? routeCostAnalysisData.getRouteCost.find(
            report => Number(report.year) === year
          )
        : {}
      : {}
    const initialValues = {
      year,
      month,
      marketId,
      total_truck_lease_payment: 0,
      salary: {},
      mileage_charge: 0,
      price_per_gallon: 0,
      average_mpg: 0,
      truck_cost_per_run: 0,
    }

    if (currentYearData)
      if (currentYearData.reports) {
        if (currentYearData.reports.length > 0) {
          const marketReport = currentYearData.reports.find(
            marketReport => marketReport.market === marketId
          )

          if (marketReport) {
            initialValues.total_truck_lease_payment =
              marketReport.total_truck_lease_payment
            initialValues.mileage_charge = marketReport.mileage_charge
            initialValues.price_per_gallon = marketReport.price_per_gallon
            initialValues.average_mpg = marketReport.average_mpg
            initialValues.truck_cost_per_run =
              marketReport.total_truck_lease_payment / marketReport.runs

            marketReport.routes.forEach(route => {
              initialValues.salary[`${route.id}`] = route.salary
            })
          }
        }
      }

    return (
      <RouteCostAnalysisReportFormContainer
        form={`RouteCostAnalysisReportForm`}
        initialValues={initialValues}
        {...this.props}
      />
    )
  }
}

@reduxForm({
  enableReinitialize: true,
})
export class RouteCostAnalysisReportForm extends React.PureComponent {
  state = {
    editMode: false,
    formEdited: false,
    showDiscardConfirmModal: false,
    showSaveConfirmModal: false,
    savingReport: false,
    errors: [],
  }

  columns = [
    'name',
    'bins',
    'runs',
    'total_lbs_collected',
    'average_lbs_collected',
    'truck_fixed_monthly_cost',
    'truck_cost_cpp',
    'total_mileage',
    'average_mileage',
    'average_mileage_cost',
    'average_mileage_cost_cpp',
    'average_fuel_cost',
    'average_fuel_cost_cpp',
    'salary',
    'salary_cost_cpp',
    'average_cost_per_run',
    'average_cost_per_bin',
    'route_cost_cpp',
  ]

  currencyColumns = [
    'truck_fixed_monthly_cost',
    'truck_cost_cpp',
    'average_mileage_cost',
    'average_mileage_cost_cpp',
    'average_fuel_cost',
    'average_fuel_cost_cpp',
    'salary_cost_cpp',
    'average_cost_per_run',
    'average_cost_per_bin',
    'route_cost_cpp',
  ]

  columnRules = {
    name: {},
    bins: {},
    runs: {},
    total_lbs_collected: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_lbs_collected: {
      round: true,
    },
    truck_fixed_monthly_cost: {
      // round: true,
      formatAsDecimal: true,
      decimals: 2,
    },
    truck_cost_cpp: {
      formatAsDecimal: true,
      decimals: 3,
    },
    total_mileage: {
      round: true,
    },
    average_mileage: {
      round: true,
    },
    average_mileage_cost: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_mileage_cost_cpp: {
      formatAsDecimal: true,
      decimals: 5,
    },
    average_fuel_cost: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_fuel_cost_cpp: {
      formatAsDecimal: true,
      decimals: 3,
    },
    salary: {
      formatAsDecimal: true,
      decimals: 2,
    },
    salary_cost_cpp: {
      formatAsDecimal: true,
      decimals: 3,
    },
    average_cost_per_run: {
      formatAsDecimal: true,
      decimals: 2,
    },
    average_cost_per_bin: {
      formatAsDecimal: true,
      decimals: 2,
    },
    route_cost_cpp: {
      formatAsDecimal: true,
      decimals: 3,
    },
  }

  componentDidUpdate(prevProps, prevState) {
    const { marketId, date, include_previous_year } = this.props

    if (
      marketId !== prevProps.marketId ||
      date !== prevProps.date ||
      include_previous_year !== prevProps.include_previous_year
    )
      this.setState({
        editMode: false,
      })
  }

  reInitialForm() {
    const { formValues, initialize } = this.props
    initialize(formValues)
  }

  enableEditMode() {
    this.setState({
      editMode: true,
    })
  }

  discardChanges() {
    const { reset } = this.props

    reset()
    this.setState({
      editMode: false,
    })
  }

  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>Route</th>
          <th>Bin Count</th>
          <th>No. of Runs</th>
          <th>Total LBS Collected</th>
          <th>Average LBS Collected</th>
          <th>Truck Fixed Monthly Cost</th>
          <th>Truck Cost (CPP)</th>
          <th>Total Mileage</th>
          <th>Average Mileage</th>
          <th>Average Mileage Cost</th>
          <th>Average Mileage Cost (CPP)</th>
          <th>Average Fuel Cost</th>
          <th>Average Fuel Cost (CPP)</th>
          <th>Salary</th>
          <th>Salary Cost (CPP)</th>
          <th>Average Cost per Run</th>
          <th>Average Cost per Bin</th>
          <th>Route Cost (CPP)</th>
        </tr>
      </thead>
    )
  }

  renderRouteReport(routeReport, previousYearMarketReport) {
    const { date, include_previous_year, routeId, marketId } = this.props
    const { editMode } = this.state
    const year = moment(date).year()
    const rows = []
    let renderRoute = false

    if (routeId.length === 0) {
      renderRoute = true
    } else if (routeId.includes(routeReport.id)) {
      renderRoute = true
    }

    if (!renderRoute) return null

    rows.push(
      <tr className="current-year">
        {this.columns.map(column => {
          switch (column) {
            case 'salary':
              return (
                <td>
                  {marketId ? (
                    <Field
                      name={`salary.${routeReport.id}`}
                      placeholder=""
                      component={FormField}
                      formComponent={Form.Input}
                      disabled={!editMode}
                      validate={[numeric]}
                      onInput={event => allowDecimalNumber(event, 2)}
                    />
                  ) : (
                    `$ ${routeReport.salary}`
                  )}
                </td>
              )
            case 'name':
              return (
                <td rowSpan={include_previous_year ? 2 : 1}>
                  {routeReport[column]}
                  {include_previous_year && (
                    <Fragment>
                      <span class="current-year-number">{year}</span>
                      <span class="previous-year-number">{year - 1}</span>
                    </Fragment>
                  )}
                </td>
              )
            default:
              let colValue = 'N/A'

              if (isFinite(routeReport[column])) {
                if (this.columnRules[column].round) {
                  colValue = `${Math.round(routeReport[column])}`
                } else if (this.columnRules[column].formatAsDecimal) {
                  colValue = `${formatAsDecimal(
                    routeReport[column],
                    this.columnRules[column].decimals
                  )}`
                } else {
                  colValue = `${formatAsDecimal(routeReport[column])}`
                }

                if (this.currencyColumns.includes(column))
                  colValue = `$ ${colValue}`
              }

              return <td>{colValue}</td>
          }
        })}
      </tr>
    )

    if (include_previous_year) {
      let previousYearRouteReport = {}

      if (previousYearMarketReport)
        previousYearRouteReport = previousYearMarketReport.routes.find(
          route => route.id === routeReport.id
        )

      if (!previousYearRouteReport) {
        previousYearRouteReport = cloneDeep(routeReportTemplate)
        previousYearRouteReport.id = routeReport.id
        previousYearRouteReport.name = routeReport.name
      }

      rows.push(
        <tr>
          {this.columns.map(column => {
            if (column !== 'name') {
              let colValue = 'N/A'

              if (isFinite(previousYearRouteReport[column])) {
                colValue = this.currencyColumns.includes(column) ? '$' : ''

                if (this.columnRules[column].round) {
                  colValue = `${colValue} ${Math.round(
                    previousYearRouteReport[column]
                  )}`
                } else if (this.columnRules[column].formatAsDecimal) {
                  colValue = `${colValue} ${formatAsDecimal(
                    previousYearRouteReport[column],
                    this.columnRules[column].decimals
                  )}`
                } else {
                  colValue = `${colValue} ${formatAsDecimal(
                    previousYearRouteReport[column]
                  )}`
                }
              }

              return <td>{colValue}</td>
            } else {
              return null
            }
          })}
        </tr>
      )
    }

    return rows
  }

  renderTotalRowCells(marketReport) {
    return this.columns.map(column => {
      switch (column) {
        case 'name':
          return null
        case 'average_lbs_collected':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${Math.round(marketReport.total[column])}`
                  : Math.round(marketReport.total[column])
                : 'N/A'}
            </td>
          )
        case 'average_mileage':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${Math.round(marketReport.total[column])}`
                  : Math.round(marketReport.total[column])
                : 'N/A'}
            </td>
          )
        case 'truck_cost_cpp':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${formatAsDecimal(marketReport.total[column], 2)}`
                  : formatAsDecimal(marketReport.total[column], 2)
                : 'N/A'}
            </td>
          )
        case 'average_mileage_cost_cpp':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${formatAsDecimal(marketReport.total[column], 5)}`
                  : formatAsDecimal(marketReport.total[column], 5)
                : 'N/A'}
            </td>
          )
        case 'average_fuel_cost':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${formatAsDecimal(marketReport.total[column], 2)}`
                  : formatAsDecimal(marketReport.total[column], 2)
                : 'N/A'}
            </td>
          )
        case 'average_fuel_cost_cpp':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${formatAsDecimal(marketReport.total[column], 3)}`
                  : formatAsDecimal(marketReport.total[column], 3)
                : 'N/A'}
            </td>
          )
        case 'salary_cost_cpp':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${formatAsDecimal(marketReport.total[column], 3)}`
                  : formatAsDecimal(marketReport.total[column], 3)
                : 'N/A'}
            </td>
          )
        case 'route_cost_cpp':
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${formatAsDecimal(marketReport.total[column], 3)}`
                  : formatAsDecimal(marketReport.total[column], 3)
                : 'N/A'}
            </td>
          )
        default:
          return (
            <td>
              {isFinite(marketReport.total[column])
                ? this.currencyColumns.includes(column)
                  ? `$ ${formatAsDecimal(marketReport.total[column])}`
                  : formatAsDecimal(marketReport.total[column])
                : 'N/A'}
            </td>
          )
      }
    })
  }

  renderTotalRow(currentYearMarketReport, previousYearMarketReport) {
    const { include_previous_year, date } = this.props
    const year = moment(date).year()
    const rows = []

    rows.push(
      <tr>
        <th className="total-row-title" rowSpan={include_previous_year ? 3 : 2}>
          Totals
          {include_previous_year && (
            <Fragment>
              <span class="current-year-number">{year}</span>
              <span class="previous-year-number">{year - 1}</span>
            </Fragment>
          )}
        </th>
        <th>Total Bins</th>
        <th>Total Runs</th>
        <th>Total LBS Collected</th>
        <th>Average LBS per Run</th>
        <th>Total Truck Fixed Monthly Cost</th>
        <th>Truck Cost (CPP)</th>
        <th>Total Mileage</th>
        <th>Average Miles per Run</th>
        <th>Average Mileage Cost</th>
        <th>Average Mileage Cost (CPP)</th>
        <th>Average Fuel Cost per Run</th>
        <th>Average Fuel Cost (CPP)</th>
        <th>Average Salary</th>
        <th>Salary Cost (CPP)</th>
        <th>Average Cost per Run</th>
        <th>Average Cost per Bin</th>
        <th>Average Route Cost (CPP)</th>
      </tr>
    )
    rows.push(
      <tr className="current-year total-row">
        {this.renderTotalRowCells(currentYearMarketReport)}
      </tr>
    )

    if (previousYearMarketReport) {
      rows.push(
        <tr className="previous-year total-row">
          {this.renderTotalRowCells(previousYearMarketReport)}
        </tr>
      )
    }

    return rows
  }

  renderMarketReport(currentYearMarketReport, previousYearMarketReport) {
    const { marketId, markets } = this.props

    return (
      <div
        style={{
          overflowX: 'auto',
        }}
        className="route-cost-report-table-container"
      >
        {!marketId && (
          <Header as="h3" className="market-title">
            {
              markets.find(
                market => market.id === currentYearMarketReport.market
              ).name
            }
          </Header>
        )}
        <table className="ui sortable table">
          {this.renderTableHeader()}
          <tbody>
            {currentYearMarketReport.routes.map(routeReport => {
              return [
                this.renderRouteReport(routeReport, previousYearMarketReport),
              ]
            })}
            {this.renderTotalRow(
              currentYearMarketReport,
              previousYearMarketReport
            )}
          </tbody>
        </table>
      </div>
    )
  }

  renderForm() {
    const { editMode } = this.state

    return (
      <Grid>
        <Grid.Row verticalAlign="bottom">
          <Grid.Column mobile={16} computer={11}>
            <Grid className="route-cost-form">
              <Grid.Row verticalAlign="bottom">
                <Grid.Column mobile={16} computer={13}>
                  <Grid>
                    <Grid.Row verticalAlign="bottom">
                      <Grid.Column mobile={16} computer={4}>
                        <Field
                          label="Total truck lease payment"
                          name="total_truck_lease_payment"
                          placeholder=""
                          component={FormField}
                          formComponent={Form.Input}
                          disabled={!editMode}
                          validate={[numeric]}
                          onInput={event => allowDecimalNumber(event, 0)}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={4}>
                        <Field
                          label="Mileage Charge"
                          name="mileage_charge"
                          placeholder=""
                          component={FormField}
                          formComponent={Form.Input}
                          disabled={!editMode}
                          validate={[numeric]}
                          onInput={event => allowDecimalNumber(event, 3)}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={4}>
                        <Field
                          label="Price per Gallon"
                          name="price_per_gallon"
                          placeholder=""
                          component={FormField}
                          formComponent={Form.Input}
                          disabled={!editMode}
                          validate={[numeric]}
                          onInput={event => allowDecimalNumber(event, 2)}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={4}>
                        <Field
                          label="Average MPG"
                          name="average_mpg"
                          placeholder=""
                          component={FormField}
                          formComponent={Form.Input}
                          disabled={!editMode}
                          validate={[numeric]}
                          onInput={event => allowDecimalNumber(event, 0)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column mobile={16} computer={3}>
                  <Field
                    label="Truck Cost per Run"
                    name="truck_cost_per_run"
                    placeholder=""
                    component={FormField}
                    formComponent={Form.Input}
                    validate={[numeric]}
                    disabled={true}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column mobile={16} computer={5} textAlign="right">
            {this.renderButtons()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  renderReport() {
    const { date, routeCostAnalysisReport } = this.props
    const year = moment(date).year()
    const currentYearData =
      routeCostAnalysisReport.length > 0
        ? routeCostAnalysisReport.find(report => Number(report.year) === year)
        : null
    const previousYearData =
      routeCostAnalysisReport.length > 0
        ? routeCostAnalysisReport.find(
            report => Number(report.year) === year - 1
          )
        : null
    let tables = null
    let renderReport = false

    if (currentYearData)
      if (currentYearData.reports && currentYearData.reports.length > 0) {
        renderReport = true
      }

    if (renderReport)
      tables = [
        ...currentYearData.reports.map(marketReport => {
          let previousYearMarketReport = null
          if (previousYearData) {
            if (previousYearData.reports && previousYearData.reports.length > 0)
              previousYearMarketReport = previousYearData.reports.find(
                report => report.market === marketReport.market
              )
          }

          return this.renderMarketReport(marketReport, previousYearMarketReport)
        }),
      ]
    else tables = this.renderNoDataMessage()

    return tables
  }

  showSaveConfirmation() {
    this.setState({
      showSaveConfirmModal: true,
    })
  }

  hideSaveConfirmation() {
    this.setState({
      showSaveConfirmModal: false,
    })
  }

  showDiscardConfirmation() {
    this.setState({
      showDiscardConfirmModal: true,
    })
  }

  hideDiscardConfirmation = () => {
    this.setState({
      showDiscardConfirmModal: false,
    })
  }

  onCancelDiscardChanges = () => {
    this.hideDiscardConfirmation()
  }

  onConfirmDiscardChanges = () => {
    this.discardChanges()
    this.hideDiscardConfirmation()
  }

  onCancelSaveReport = () => {
    this.hideSaveConfirmation()
  }

  onConfirmSaveReport = () => {
    const { routeCostAnalysisReport } = this.props

    this.saveRouteCostReport(routeCostAnalysisReport)
    this.hideSaveConfirmation()
  }

  renderButtons() {
    const { marketId } = this.props
    const { editMode, formEdited } = this.state

    return (
      <React.Fragment>
        {marketId && (
          <React.Fragment>
            {!editMode && (
              <Button color="blue" onClick={() => this.enableEditMode()}>
                Edit
              </Button>
            )}
            {editMode && (
              <React.Fragment>
                <Button
                  color="blue"
                  onClick={() => this.showDiscardConfirmation()}
                  disabled={formEdited}
                >
                  Discard Changes
                </Button>

                <Button
                  color="green"
                  onClick={() => this.showSaveConfirmation()}
                >
                  Save Changes
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderNoDataMessage() {
    return (
      <Message black="true">
        <Message.Header>There is no data to display!</Message.Header>
        <p>Please pick a date to load report</p>
      </Message>
    )
  }

  saveRouteCostReport = report => {
    const year = report[0].year
    const month = report[0].month
    const marketReport = pick(cloneDeep(report[0].reports[0]), [
      'average_mpg',
      'market',
      'mileage_charge',
      'price_per_gallon',
      'routes',
      'runs',
      'total_truck_lease_payment',
      'truck_cost_per_run',
    ])
    const market = marketReport.market

    marketReport.routes.forEach(route => {
      delete route.__typename

      Object.keys(route).forEach(key => {
        if (!isFinite(route[key]) && key !== 'name' && key !== 'id') {
          route[key] = 0
        }
      })
    })

    this.setState({
      savingReport: true,
      errors: [],
    })

    apolloClient
      .mutate({
        mutation: saveRouteCostMutation,
        variables: {
          market,
          year,
          month,
          report: marketReport,
        },
      })
      .then(() => {
        this.reInitialForm()
      })
      .catch(error => {
        this.setState({
          errors: [error.message],
        })
      })
      .finally(() => {
        this.setState({
          editMode: false,
          formEdited: false,
          savingReport: false,
        })
      })
  }

  render() {
    const { marketId, loading, routeCostAnalysisReport } = this.props
    const {
      showDiscardConfirmModal,
      showSaveConfirmModal,
      savingReport,
    } = this.state

    return [
      <div>
        {
          <Confirm
            open={showDiscardConfirmModal}
            size="tiny"
            header="Discard Changes?"
            content="Are sure you want to discard changes?"
            onCancel={this.onCancelDiscardChanges}
            onConfirm={this.onConfirmDiscardChanges}
            cancelButton="No"
            confirmButton="Yes"
          />
        }
        {
          <Confirm
            open={showSaveConfirmModal}
            size="tiny"
            header="Save Changes?"
            content="Are you sure you want to save report?"
            onCancel={this.onCancelSaveReport}
            onConfirm={this.onConfirmSaveReport}
            cancelButton="No"
            confirmButton="Yes"
          />
        }
        {(loading || savingReport) && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {routeCostAnalysisReport ? (
          <Form>
            {marketId && this.renderForm()}
            {this.renderReport()}
          </Form>
        ) : (
          <Fragment>{this.renderNoDataMessage()}</Fragment>
        )}
      </div>,
    ]
  }
}

function calculateMarketReport(report, formValues = null, routeId) {
  let total_truck_lease_payment = 0
  let mileage_charge = 0
  let price_per_gallon = 0
  let average_mpg = 0
  let truck_cost_per_run = 0

  if (formValues) {
    total_truck_lease_payment = formValues.total_truck_lease_payment
    mileage_charge = formValues.mileage_charge
    price_per_gallon = formValues.price_per_gallon
    average_mpg = formValues.average_mpg

    formValues.truck_cost_per_run =
      !formValues.total_truck_lease_payment && !report.runs
        ? 0
        : Number(formValues.total_truck_lease_payment) / report.runs

    if (
      !isFinite(formValues.truck_cost_per_run) ||
      isNaN(formValues.truck_cost_per_run)
    )
      formValues.truck_cost_per_run = 0

    truck_cost_per_run = formValues.truck_cost_per_run
    formValues.truck_cost_per_run = formValues.truck_cost_per_run.toLocaleString(
      'en',
      { maximumFractionDigits: 2 }
    )
  } else {
    total_truck_lease_payment = has(report, 'total_truck_lease_payment')
      ? report.total_truck_lease_payment
      : 0
    mileage_charge = has(report, 'mileage_charge') ? report.mileage_charge : 0
    price_per_gallon = has(report, 'price_per_gallon')
      ? report.price_per_gallon
      : 0
    average_mpg = has(report, 'average_mpg') ? report.average_mpg : 0
    truck_cost_per_run = has(report, 'truck_cost_per_run')
      ? !isFinite(report.truck_cost_per_run) || isNaN(report.truck_cost_per_run)
        ? 0
        : report.truck_cost_per_run
      : 0
  }

  truck_cost_per_run = Number(truck_cost_per_run)

  report.average_mpg = isFinite(average_mpg) ? average_mpg : report.average_mpg
  report.mileage_charge = isFinite(mileage_charge)
    ? mileage_charge
    : report.mileage_charge
  report.price_per_gallon = isFinite(price_per_gallon)
    ? price_per_gallon
    : report.price_per_gallon
  report.total_truck_lease_payment = isFinite(total_truck_lease_payment)
    ? total_truck_lease_payment
    : report.total_truck_lease_payment
  report.truck_cost_per_run = isFinite(truck_cost_per_run)
    ? truck_cost_per_run
    : report.truck_cost_per_run
  report.truck_cost_per_run = truck_cost_per_run
  report['total'] = {
    name: 'Totals',
    bins: 0,
    runs: 0,
    total_lbs_collected: 0,
    truck_fixed_monthly_cost: 0,
    total_mileage: 0,
    salary: 0,
    average_mileage: 0,
    average_lbs_collected: 0,
    truck_cost_cpp: 0,
    average_mileage_cost: 0,
    average_mileage_cost_cpp: 0,
    average_fuel_cost: 0,
    average_fuel_cost_cpp: 0,
    salary_cost_cpp: 0,
    average_cost_per_run: 0,
    average_cost_per_bin: 0,
    route_cost_cpp: 0,
    totalAverageMileage: 0,
    totalAverageFuelCost: 0,
    totalSalary: 0,
    totalAverageLbsCollected: 0,
    totalAverageMileageCost: 0,
    totalAverageCostPerBin: 0,
    numberOfAverageCostPerBin: 0,
    numberOfSalary: 0,
  }

  report.routes.forEach(routeReport => {
    // Route report
    routeReport.truck_fixed_monthly_cost = truck_cost_per_run * routeReport.runs
    routeReport.truck_cost_cpp =
      truck_cost_per_run / routeReport.average_lbs_collected
    routeReport.average_mileage_cost =
      routeReport.average_mileage * mileage_charge
    routeReport.average_mileage_cost_cpp =
      routeReport.average_mileage_cost / routeReport.average_lbs_collected
    routeReport.average_fuel_cost =
      routeReport.average_mileage / average_mpg * price_per_gallon
    routeReport.average_fuel_cost_cpp =
      routeReport.average_fuel_cost / routeReport.average_lbs_collected
    routeReport.salary = has(formValues, `salary.${routeReport.id}`)
      ? formValues.salary[routeReport.id]
      : routeReport.salary
    routeReport.salary_cost_cpp =
      routeReport.salary / routeReport.average_lbs_collected
    routeReport.average_cost_per_run =
      Number(truck_cost_per_run) +
      routeReport.average_mileage_cost +
      routeReport.average_fuel_cost +
      routeReport.salary
    routeReport.average_cost_per_bin =
      routeReport.average_cost_per_run / routeReport.bins
    routeReport.route_cost_cpp =
      routeReport.average_cost_per_run / routeReport.average_lbs_collected

    // Total report
    let considerThisRouteInTotal = false

    if (routeId.length === 0 || routeId.includes(routeReport.id)) {
      considerThisRouteInTotal = true
    }

    if (considerThisRouteInTotal) {
      report.total.bins += get(routeReport, 'bins', 0)
      report.total.runs += get(routeReport, 'runs', 0)
      report.total.total_lbs_collected += get(
        routeReport,
        'total_lbs_collected',
        0
      )
      report.total.truck_fixed_monthly_cost += Math.round(
        get(routeReport, 'truck_fixed_monthly_cost', 0)
      )
      report.total.total_mileage += get(routeReport, 'total_mileage', 0)

      report.total.totalAverageMileage += get(routeReport, 'average_mileage', 0)
      report.total.totalAverageFuelCost += get(
        routeReport,
        'average_fuel_cost',
        0
      )
      report.total.totalSalary += isFinite(get(routeReport, 'salary', 0))
        ? Number(get(routeReport, 'salary', 0))
        : 0
      report.total.numberOfSalary +=
        isFinite(get(routeReport, 'salary', 0)) &&
        get(routeReport, 'salary', 0) > 0
          ? 1
          : 0
      report.total.totalAverageLbsCollected += get(
        routeReport,
        'average_lbs_collected',
        0
      )
      report.total.totalAverageMileageCost += get(
        routeReport,
        'average_mileage_cost',
        0
      )
      report.total.totalAverageCostPerBin += isFinite(
        get(routeReport, 'average_cost_per_bin', 0)
      )
        ? get(routeReport, 'average_cost_per_bin', 0)
        : 0
      report.total.numberOfAverageCostPerBin +=
        isFinite(get(routeReport, 'average_cost_per_bin', 0)) &&
        get(routeReport, 'average_cost_per_bin', 0) > 0
          ? 1
          : 0

      report.total.average_mileage = Math.round(
        report.total.total_mileage / report.total.runs
      )
      report.total.average_lbs_collected = Math.round(
        report.total.total_lbs_collected / report.total.runs
      )
      report.total.truck_cost_cpp =
        truck_cost_per_run / report.total.average_lbs_collected
      report.total.average_mileage_cost =
        report.total.average_mileage * mileage_charge
      report.total.average_mileage_cost_cpp =
        report.total.average_mileage_cost / report.total.average_lbs_collected
      report.total.average_fuel_cost =
        report.total.average_mileage / average_mpg * price_per_gallon
      report.total.average_fuel_cost_cpp =
        report.total.average_fuel_cost / report.total.average_lbs_collected
      report.total.salary =
        report.total.totalSalary / report.total.numberOfSalary
      report.total.salary_cost_cpp =
        report.total.salary / report.total.average_lbs_collected
      report.total.average_cost_per_run =
        truck_cost_per_run +
        report.total.average_mileage_cost +
        report.total.average_fuel_cost +
        report.total.salary
      report.total.average_cost_per_bin =
        report.total.totalAverageCostPerBin /
        report.total.numberOfAverageCostPerBin
      report.total.route_cost_cpp =
        report.total.average_cost_per_run / report.total.average_lbs_collected
    }
  })

  console.log({
	report,
  })
}

function mapStateToProps(state, ownProps) {
  const formValues = getFormValues('RouteCostAnalysisReportForm')(state) || {}
  const { marketId, routeId, routeCostAnalysisData, date } = ownProps
  const year = moment(date).year()
  const routeCostAnalysisReport = routeCostAnalysisData
    ? routeCostAnalysisData.getRouteCost
      ? cloneDeep(routeCostAnalysisData.getRouteCost)
      : []
    : null

  if (routeCostAnalysisReport) {
    routeCostAnalysisReport.forEach(yearReport => {
      if (yearReport.reports) {
        yearReport.reports.forEach(marketReport => {
          if (marketId && Number(yearReport.year) === year) {
            calculateMarketReport(marketReport, formValues, routeId)
          } else {
            calculateMarketReport(marketReport, null, routeId)
          }
        })
      }
    })
  }

  return {
    formValues,
    routeCostAnalysisReport,
  }
}

const RouteCostAnalysisReportFormContainer = connect(mapStateToProps, null)(
  RouteCostAnalysisReportForm
)
