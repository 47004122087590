import React from 'react'
import { withAuth } from '../../connectors/auth'

import { Field, reduxForm } from 'redux-form'
import validator from 'validator'
import { Form, Message, Segment } from 'semantic-ui-react'
import { Row, Col } from 'react-flexbox-grid'

import FormField from '../../components/FormField'
import ColorPicker from '../../components/ColorPicker'
import Select from '../../components/Select'
import './styles.scss'

export const validate = args => {
  const errors = {}

  const fields = ['market', 'color_name', 'name', 'code', 'color']

  fields.forEach(field => {
    if (!args[field] || validator.isEmpty(args[field])) {
      errors[field] = 'invalid/required'
    }
  })

  return errors
}

const parseMarket = market => ({
  key: market.id,
  text: market.name,
  value: market.id,
})

@withAuth
@reduxForm({
  form: 'RouteForm',
  validate,
})
export default class RouteForm extends React.PureComponent {
  render() {
    const {
      formError,
      handleSubmit,
      submitting,
      auth: { user: { markets } },
      editing,
    } = this.props

    return (
      <Segment loading={submitting} vertical as={Form} onSubmit={handleSubmit}>
        <Form.Field>
          <Field
            name="market"
            label="Market"
            placeholder="Market"
            options={markets.map(parseMarket)}
            component={FormField}
            required
            formComponent={Select}
          />
        </Form.Field>
        <Row className="RouteForm__Row">
          <Col xs={6}>
            <Form.Field>
              <Field
                name="name"
                type="text"
                label="Name"
                placeholder="Route Name (PURPLE, RED, etc...)"
                required
                component={FormField}
                formComponent={Form.Input}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name="code"
                type="text"
                label="Code"
                placeholder="Route Code (HOURED, ATLBLUE, etc...)"
                required
                component={FormField}
                formComponent={Form.Input}
              />
            </Form.Field>
            <div className={!editing ? 'hidden' : ''}>
              <Form.Field>
                <Field
                  id="rouote_inactive"
                  name="inactive"
                  component={FormField}
                  label="Inactive"
                  formComponent={Form.Checkbox}
                />
              </Form.Field>
            </div>
          </Col>
          <Col xs={6}>
            <Form.Field>
              <Field
                name="color"
                label="Color"
                component={FormField}
                required
                formComponent={ColorPicker}
              />
            </Form.Field>
          </Col>
        </Row>
        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Segment>
    )
  }
}
