import {
  _AUTH_AUTHENTICATE,
  _AUTH_CLEAR_ERROR,
  _AUTH_ERROR,
  _AUTH_LOADED,
  _AUTH_LOADING,
  _AUTH_PASSWORD_RESET,
  _AUTH_SIGNOUT,
} from '../actions/auth'

import { _MARKETS_CREATE } from '../actions/markets'

const initialState = {
  user: null,
  loading: null,
  loaded: false,
  error: null,
  reset: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _AUTH_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      }
    case _AUTH_LOADING:
      return {
        ...state,
        loading: true,
      }

    case _AUTH_LOADED:
      return {
        ...state,
        loaded: true,
      }

    case _AUTH_AUTHENTICATE:
      return {
        ...state,
        user: action.payload,
        error: null,
      }

    case _MARKETS_CREATE:
      return {
        ...state,
        user: {
          ...state.user,
          markets: [...state.user.markets, action.payload],
        },
      }

    case _AUTH_ERROR:
      return {
        ...state,
        user: null,
        error: action.payload,
      }

    case _AUTH_PASSWORD_RESET:
      return {
        ...state,
        user: null,
        error: null,
        reset: true,
      }

    case _AUTH_SIGNOUT:
      return {
        ...state,
        user: null,
      }

    default:
      return state
  }
}

export default reducer
