import React from 'react'

import { Form, Dropdown } from 'semantic-ui-react'
import './styles.scss'

const options = [
  {
    text: 'This Week',
    value: 'this-week',
  },
  {
    text: 'Last Week',
    value: 'last-week',
  },
  {
    text: 'This Month',
    value: 'this-month',
  },
  {
    text: 'Last Month',
    value: 'last-month',
  },
  {
    text: 'This Quarter',
    value: 'this-quarter',
  },
  {
    text: 'Last Quarter',
    value: 'last-quarter',
  },
  {
    text: 'This Year',
    value: 'this-year',
  },
  {
    text: 'Last Year',
    value: 'last-year',
  },
  {
    text: 'Custom',
    value: 'custom',
  },
]

const DateDropdown = ({ onChange, current, disabled = false, ...props }) => (
  <Dropdown
    placeholder={current === 'custom' ? 'Custom' : 'Filter by'}
    options={options}
    value={current}
    disabled={disabled}
    selection
    fluid
    onChange={(ev, { value }) => onChange({ named: value })}
    {...props}
  />
)

export default DateDropdown

export class FieldDateDropdown extends React.PureComponent {
  render() {
    const { label, width, ...props } = this.props
    return (
      <Form.Field {...{ width }}>
        {label && <label>{label}</label>}
        <DateDropdown {...props} />
      </Form.Field>
    )
  }
}
