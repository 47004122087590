import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isInteger from 'lodash/isInteger'
import _lt from 'lodash/lt'
import PropTypes from 'prop-types'
import React from 'react'

import { Field, reduxForm } from 'redux-form'
// import validator from 'validator'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { Form, Message } from 'semantic-ui-react'

import CheckboxField from '../../components/CheckboxField'
import DatePicker from '../../components/DatePicker'
import FormField from '../../components/FormField'
import Select from '../../components/Select'
import DriverPicker from '../../components/DriverPicker'
import RecurrinSection from './recurring'

import './styles.scss'

export const validate = args => {
  const errors = {}
  const fields = ['market', 'driver', 'route']

  fields.forEach(field => {
    if (!args[field]) {
      errors[field] = 'invalid/required'
    }
  })
  if (_isEmpty(args.date) && !args.is_recurring) {
    errors.date = 'invalid/required'
  }

  if (args.is_recurring) {
    const recurring = _get(args, 'recurring', {})
    const { during, every, amount, ...days } = recurring
    if (!during) {
      errors.recurring = _get(errors, 'recurring', {})
      errors.recurring['during'] = 'invalid/required'
    }
    if (!_isInteger(amount) || _lt(amount, 1)) {
      errors.recurring = _get(errors, 'recurring', {})
      errors.recurring['amount'] = 'invalid/required'
    }
    if (!every) {
      errors.recurring = _get(errors, 'recurring', {})
      errors.recurring['every'] = 'invalid/required'
    }
    if (every === 'weeks' && _isEmpty(days)) {
      errors.recurring = _get(errors, 'recurring', {})
      errors.recurring['sunday'] = 'invalid/required'
      errors.recurring['monday'] = 'invalid/required'
      errors.recurring['tuesday'] = 'invalid/required'
      errors.recurring['wednesday'] = 'invalid/required'
      errors.recurring['thursday'] = 'invalid/required'
      errors.recurring['friday'] = 'invalid/required'
      errors.recurring['saturday'] = 'invalid/required'
    }
  }
  return errors
}

const DateField = ({ label, ...props }) => (
  <Form.Field {...props}>
    <label>{label}</label>
    <DatePicker singleDay={true} {...props} />
  </Form.Field>
)

@reduxForm({
  form: 'ScheduleRunForm',
  validate,
})
export default class ScheduleRunForm extends React.PureComponent {
  static propTypes = {
    markets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    drivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      })
    ),
    helpers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      })
    ),
    onMarketChange: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      onMarketChange,
      onRouteChange,
      initialValues: { market, route } = {},
    } = this.props

    if (market) {
      onMarketChange(market)
    }

    if (route) {
      onRouteChange(route)
    }
  }

  changeMarket = (e, marketId) => {
    const { onMarketChange } = this.props
    onMarketChange(marketId)
  }

  changeRoute = (e, routeId) => {
    const { onRouteChange } = this.props
    onRouteChange(routeId)
  }

  fixCollection(items, getText) {
    if (!items) {
      return []
    }

    return items.map(item => ({
      value: item.id,
      text: getText ? getText(item) : item.name,
    }))
  }

  render() {
    const {
      formError,
      handleSubmit,
      markets,
      routes,
      drivers,
      driver,
      helpers,
      loading,
      selector,
      onCustomizeChecked,
    } = this.props
    const recurring = selector('is_recurring')

    return (
      <Form onSubmit={handleSubmit}>
        <Grid>
          <Row>
            <Col sm>
              <Field
                name="market"
                label="Market"
                component={FormField}
                required
                formComponent={Select}
                options={this.fixCollection(markets)}
                loading={loading}
                onChange={this.changeMarket}
              />
            </Col>
            <Col sm>
              <Field
                name="route"
                label="Route"
                required
                component={FormField}
                formComponent={Select}
                options={this.fixCollection(routes)}
                loading={loading}
                onChange={this.changeRoute}
                driver={driver}
              />
            </Col>
          </Row>
          <Row>
            <Col sm>
              <Field
                name="driver"
                label="Driver"
                component={FormField}
                required
                formComponent={DriverPicker}
                drivers={this.fixCollection(
                  drivers,
                  item => `${item.first_name} ${item.last_name}`
                )}
                driver={driver}
                loading={loading}
              />
            </Col>
            <Col sm>
              <Field
                name="helper"
                label="Helper"
                component={FormField}
                formComponent={DriverPicker}
                drivers={this.fixCollection(
                  helpers,
                  item => `${item.first_name} ${item.last_name}`
                )}
                loading={loading}
                allowEmpty={true}
                emptyOptionText="No helper"
              />
            </Col>
          </Row>

          <RecurrinSection />

          {!recurring && (
            <Row>
              <Col sm>
                <Field
                  name="date"
                  label="Date"
                  required
                  component={FormField}
                  onBlur={e => e.preventDefault()}
                  formComponent={DateField}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Field
                name="customize"
                label="Customize"
                className="field"
                component={FormField}
                formComponent={CheckboxField}
                onChange={(_, data) => onCustomizeChecked(data)}
                toggle
              />
            </Col>
          </Row>
        </Grid>
        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
