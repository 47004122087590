import React from 'react'

import { Icon } from 'semantic-ui-react'
import './styles.scss'

export default ({ color, ...props }) => (
  <div className="Three_Dots__Icon" {...props}>
    <Icon floated="right" name="circle" color={color || 'grey'} size="tiny" />
    <Icon floated="right" name="circle" color={color || 'grey'} size="tiny" />
    <Icon floated="right" name="circle" color={color || 'grey'} size="tiny" />
  </div>
)
