import apolloClient from '../api/apolloClient'
import {
  createPoliceReport as createPoliceReportMutation,
  deleteMutation,
  policeReport as getPoliceReportById,
  policeReportsQuery,
  updatePoliceReport as updatePoliceReportMutation,
} from '../api/Query/policeReports'
import _ from 'lodash'

import { trigger, triggerError } from '../actions'

export const _POLICE_REPORTS_LOADING = 'POLICE_REPORTS_LOADING'
export const _POLICE_REPORTS_FETCH = 'POLICE_REPORTS_FETCH'
export const _POLICE_REPORT_FETCH = 'POLICE_REPORT_FETCH'
export const _POLICE_REPORTS_ERROR = 'POLICE_REPORTS_ERROR'
export const _POLICE_REPORTS_SEARCH = 'POLICE_REPORTS_SEARCH'
export const _POLICE_REPORT_ADDED = 'POLICE_REPORT_ADDED'
export const _POLICE_REPORTS_REMOVE = 'POLICE_REPORTS_REMOVE'

export const clearError = () => {
  return dispatch => {
    dispatch(trigger(_POLICE_REPORTS_ERROR)(null))
  }
}

export const fetch = (status = 'new') => {
  return dispatch => {
    dispatch(trigger(_POLICE_REPORTS_LOADING)())
    return apolloClient
      .query({
        query: policeReportsQuery,
        variables: { status },
        fetchPolicy: 'network-only',
      })
      .then(res => res.data.policeReports)
      .then(trigger(_POLICE_REPORTS_FETCH))
      .catch(
        triggerError(_POLICE_REPORTS_ERROR, 'Could not fetch Police Reports.')
      )
      .then(dispatch)
  }
}

export const getPoliceReport = id => dispatch => {
  return apolloClient
    .query({
      query: getPoliceReportById,
      variables: { id },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.policeReportData)
    .then(trigger(_POLICE_REPORT_FETCH))
    .then(dispatch)
}

export const search = text => {
  return dispatch => {
    return dispatch(trigger(_POLICE_REPORTS_SEARCH)(text))
  }
}

export const addPoliceReport = policeReport => {
  return dispatch => {
    dispatch(trigger(_POLICE_REPORTS_LOADING)())
    return apolloClient
      .mutate({
        mutation: createPoliceReportMutation,
        variables: { policeReport },
        refetchQueries: [
          {
            query: policeReportsQuery,
          },
          {
            query: policeReportsQuery,
          },
        ],
      })
      .then(res => {
        return res.data.createPoliceReport
      })
      .then(trigger(_POLICE_REPORT_ADDED))
      .catch(trigger(_POLICE_REPORTS_ERROR))
      .then(dispatch)
  }
}

const updateCollection = store => (query, action) => {
  try {
    const cached = store.readQuery(query)
    action(cached)(cached)
    store.writeQuery({ ...query, data: cached })
  } catch (err) {}
}

export const updatePoliceReport = policeReport => {
  if (!policeReport.id) {
    return addPoliceReport(policeReport)
  }

  return dispatch => {
    dispatch(trigger(_POLICE_REPORTS_LOADING)())
    return apolloClient
      .mutate({
        mutation: updatePoliceReportMutation,
        variables: { policeReportId: policeReport.id, policeReport },
        update: (store, { data: { updatePoliceReport } }) => {
          const hiddenPoliceReportsQ = {
            query: policeReportsQuery,
          }
          const visiblePoliceReportsQ = {
            query: policeReportsQuery,
          }
          const addPoliceReport = collection => {
            const policeReport =
              _.find(collection.policeReports, {
                id: updatePoliceReport.id,
              }) || {}
            if (!policeReport.id) collection.policeReports.unshift(policeReport)
            _.merge(policeReport, updatePoliceReport)
          }
          const removePoliceReport = collection =>
            (collection.policeReports = collection.policeReports.filter(
              policeReport => policeReport.id !== updatePoliceReport.id
            ))
          const update = updateCollection(store)

          update(
            visiblePoliceReportsQ,
            _ =>
              updatePoliceReport.deactivated
                ? removePoliceReport
                : addPoliceReport
          )
          update(
            hiddenPoliceReportsQ,
            _ =>
              updatePoliceReport.deactivated
                ? addPoliceReport
                : removePoliceReport
          )
        },
      })
      .then(res => {
        return res.data.updatePoliceReport
      })
      .then(trigger(_POLICE_REPORT_ADDED))
      .catch(trigger(_POLICE_REPORTS_ERROR))
      .then(dispatch)
  }
}

export const remove = policeReportId => {
  return dispatch => {
    dispatch(trigger(_POLICE_REPORTS_LOADING)())
    return apolloClient
      .mutate({ mutation: deleteMutation, variables: { policeReportId } })
      .then(res => {
        if (res.data && res.data.deletePoliceReport) return policeReportId
        else throw new Error('Could not delete Police Report.')
      })
      .then(trigger(_POLICE_REPORTS_REMOVE))
      .catch(
        triggerError(
          _POLICE_REPORTS_ERROR,
          `Could not delete Police Report ${policeReportId}.`
        )
      )
      .then(dispatch)
  }
}
