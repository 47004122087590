import React from 'react'
import moment from 'moment'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'

import { Dropdown, Input, Modal } from 'semantic-ui-react'
import { DateRange, Calendar } from 'react-date-range'
import { MonthPicker } from '../MonthPicker'

import './styles.scss'

const displayDateFormat = 'MMM D, YYYY'
const rangeToString = (value, singleDay) => {
  if (_isEmpty(value)) return

  if (singleDay) return `${value.format(displayDateFormat)}`

  const start = _get(value, 'start')
  const end = _get(value, 'end')

  if (!_isEmpty(start) && !_isEmpty(end)) {
    return `${start.format(displayDateFormat)} to ${end.format(
      displayDateFormat
    )}`
  }
}

const ensureValue = (value, singleDay) => {
  if (singleDay) {
    if (!_isEmpty(value)) {
      return moment(value)
    }

    return null
  }

  if (!singleDay && _has(value, 'start') && _has(value, 'end'))
    return {
      start: moment(value.start),
      end: moment(value.end),
    }
}

const SinglePicker = ({
  controlled,
  disabled,
  displayText,
  placeholder,
  showPicker,
  visible,
  ...props
}) => (
  <div style={{ position: 'relative' }}>
    <Input
      icon="calendar"
      iconPosition="left"
      placeholder={placeholder || 'Enter Custom Date'}
      disabled={disabled}
      onClick={showPicker}
      className="DatePicker__picker"
      fluid
      {...!controlled && { defaultValue: displayText }}
      {...controlled && { value: displayText || '' }}
    />
    <Modal open={visible} size="mini" closeOnDimmerClick={true}>
      <div className="DatePicker__picker">
        <Calendar {...props} />
      </div>
    </Modal>
  </div>
)

export class DatePicker extends React.PureComponent {
  state = {
    modalOpen: false,
    modalMonthOpen: false,
  }

  handleOpen = () => this.setState({ modalOpen: true })
  handleMonthOpen = () => this.setState({ modalMonthOpen: true })

  handleClose = () => this.setState({ modalOpen: false })
  handleMonthClose = () => this.setState({ modalMonthOpen: false })

  onClick = (ev, { value }) => {
    const { singleDay, onChange } = this.props
    const start = moment(value).startOf('month')
    const end = moment(value).endOf('month')

    if (singleDay) return onChange(start)

    onChange({ start, end })
  }

  onPicked = value => {
    const { singleDay, onChange } = this.props

    if (singleDay) {
      const adjustedValue = moment(value).hour(12)

      return this.setState({ modalOpen: false }, () => {
        onChange(adjustedValue)
      })
    }

    if (value.startDate && value.endDate) {
      if (!value.startDate.isSame(value.endDate)) this.handleClose()

      return onChange({
        start: _get(value, 'startDate'),
        end: _get(value, 'endDate'),
      })
    }
  }

  onMonthPicked = value => {
    const { singleDay, onChange } = this.props
    // value = "2013-12"

    const start = moment(value).startOf('month')
    const end = moment(value).endOf('month')

    return this.setState({ modalMonthOpen: false }, () => {
      if (singleDay) onChange(start)
      else onChange({ start, end })
    })
  }

  render() {
    const { modalOpen } = this.state
    const { disabled, singleDay, value, preventOnClose, ...props } = this.props
    const dateValue = ensureValue(value, singleDay)
    const displayText = rangeToString(dateValue, singleDay)

    const rangePickerProps = {
      ...props,
      startDate: _get(dateValue, 'start'),
      endDate: _get(dateValue, 'end'),
      onChange: this.onPicked,
    }
    const singlePickerProps = {
      ...props,
      displayText,
      disabled,
      visible: modalOpen,
      date: dateValue,
      onChange: this.onPicked,
      showPicker: this.handleOpen,
    }

    if (singleDay) return <SinglePicker {...singlePickerProps} />

    return (
      <React.Fragment>
        <Dropdown text={displayText} disabled={disabled} selection>
          <Dropdown.Menu vertical>
            <Dropdown.Item
              text="This Month"
              value={moment().format('YYYY-MM')}
              onClick={this.onClick}
            />
            <Dropdown.Item
              text="Last Month"
              value={moment()
                .subtract(1, 'month')
                .format('YYYY-MM')}
              onClick={this.onClick}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              icon="calendar"
              content="Custom Date"
              onClick={this.handleOpen}
            />
            <Dropdown.Item
              icon="calendar"
              content="Custom Month"
              onClick={this.handleMonthOpen}
            />
          </Dropdown.Menu>
        </Dropdown>
        <Modal
          size={singleDay ? 'mini' : 'small'}
          open={this.state.modalOpen}
          className="Modal__MonthPicker"
          dimmer="blurring"
          onClose={!preventOnClose ? this.handleClose : undefined}
        >
          <Modal.Content className="DatePicker__picker">
            <DateRange {...rangePickerProps} />
          </Modal.Content>
        </Modal>
        <Modal
          size="mini"
          open={this.state.modalMonthOpen}
          className="Modal__MonthPicker"
          dimmer="blurring"
          onClose={!preventOnClose ? this.handleMonthClose : undefined}
        >
          <Modal.Content className="ModalContent">
            <MonthPicker render={() => {}} onChange={this.onMonthPicked} />
          </Modal.Content>
        </Modal>
      </React.Fragment>
    )
  }
}

DatePicker.defaultProps = {
  singleDay: false,
}
export default DatePicker
