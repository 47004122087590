import React from 'react'
import PropTypes from 'prop-types'
import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import _has from 'lodash/has'

import { FormattedNumber } from 'react-intl'
import NumericTooltip from '../NumericTooltip'
import { Link } from 'react-router-dom'

import { Button, Table, Icon, Popup } from 'semantic-ui-react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc'
import './styles.scss'

const orderAcrom = {
  asc: 'ascending',
  desc: 'descending',
}
const genId = () =>
  Math.random()
    .toString(36)
    .substring(7)

const TableHeaderTitle = ({ column }) => {
  if (!column.tooltip) {
    return <span>{column.title}</span>
  }
  return (
    <Popup
      trigger={<span className="with-tooltip">{column.title}</span>}
      size="tiny"
    >
      <Popup.Content>{column.tooltip}</Popup.Content>
    </Popup>
  )
}

const Header = ({
  columns,
  onSort,
  column,
  hasMenu,
  direction,
  menu,
  tableTitle,
}) => (
  <Table.Header>
    {tableTitle && (
      <Table.Row>
        <Table.HeaderCell colSpan={columns.length}>
          {tableTitle}
        </Table.HeaderCell>
      </Table.Row>
    )}

    <Table.Row>
      {columns.map(col => (
        <Table.HeaderCell
          key={col.name}
          sorted={
            _get(column, 'name') === col.name
              ? orderAcrom[direction]
              : undefined
          }
          onClick={() => onSort && onSort(col)}
          content={<TableHeaderTitle column={col} />}
        />
      ))}
      {hasMenu && <Table.HeaderCell {...menu} />}
    </Table.Row>
  </Table.Header>
)

const DragHandle = SortableHandle(() => (
  <Button icon>
    <Icon name="resize vertical" />
  </Button>
))

const Row = ({
  rowId,
  rowData,
  columns,
  renderMenu,
  index,
  Cell,
  menu,
  ...props
}) => (
  <Table.Row key={rowId}>
    {columns.map(
      ({
        name,
        align,
        cellClassName,
        style,
        renderCellValue,
        renderCell,
        ...cellProps
      }) => {
        if (!cellClassName) {
          cellClassName = ''
        } else if (typeof cellClassName === 'function') {
          cellClassName = cellClassName(rowData)
        }

        return (
          <Cell
            key={`${name}-${rowId}`}
            textAlign={align}
            className={cellClassName}
            {...cellProps}
          >
            {renderCell
              ? renderCell(rowData)
              : generateContent(
                  rowData[name],
                  name,
                  style,
                  renderCellValue,
                  rowData
                )}
          </Cell>
        )
      }
    )}
    {renderMenu && (
      <Cell {...menu}>{renderMenu({ item: rowData, key: rowId, index })}</Cell>
    )}
  </Table.Row>
)
Row.defaultProps = {
  Cell: Table.Cell,
}
const SortableRow = SortableElement(Row)

const Body = SortableContainer(({ data }) => <Table.Body>{data}</Table.Body>)

const generateContent = (data, name, style = {}, renderCellValue, rowData) => {
  if (renderCellValue) {
    return renderCellValue(data, rowData)
  }

  if (name === 'link') {
    return <Link to={`/app/routes/${data}`}>View</Link>
  }

  if (name === '__drag') {
    return <DragHandle />
  }

  if (typeof data === 'string') return data

  // Account for numbers
  if (typeof data === 'number') {
    return (
      <NumericTooltip value={data || 0}>
        <span style={style}>
          <FormattedNumber value={parseInt(data, 10)} />
        </span>
      </NumericTooltip>
    )
  }

  if (!data) {
    return '-'
  }

  if (!Array.isArray(data)) return ''

  return data.map((content, i) => {
    switch (typeof content) {
      case 'string':
        return (
          <span key={i} style={style}>
            {content}
          </span>
        )
      case 'object':
        const { type, ...props } = content
        return <Icon key={i} style={style} {...props} />
      default:
        return <span key={i} style={style} />
    }
  })
}

const renderRow = ({ data, cell, sortable = true, ...rest }) => {
  const rowId = data.id || genId()
  const UseRow = sortable ? SortableRow : Row
  return (
    <UseRow key={rowId} rowId={rowId} rowData={data} Cell={cell} {...rest} />
  )
}
const sortData = (data, column, direction) => {
  if (!_has(column, 'name') || _isEmpty(direction)) {
    return data
  }

  return _orderBy(data, _get(column, 'name'), direction)
}

export default class DataTable extends React.PureComponent {
  state = {
    column: null,
    storedData: null,
    direction: null,
  }

  static defaultProps = {
    columns: [],
    data: [],
    resetData: false,
    direction: 'asc',
    tableTitle: '',
  }

  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    footer: PropTypes.object,
    data: PropTypes.array,
    onSort: PropTypes.func,
    sortable: PropTypes.bool,
    resetData: PropTypes.bool,
    tableTitle: PropTypes.string,
  }

  sortIt = (column, direction) => {
    const switchDirection = this.state.direction === 'asc' ? 'desc' : 'asc'
    this.setState({
      column,
      direction: _isEmpty(direction) ? switchDirection : direction,
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { data } = state
    const { data: nextData, direction, initialSort, columns } = props

    const column = _get(state, 'column', _find(columns, { name: initialSort }))

    const nextState = {
      column,
      direction,
      data: nextData,
      storedData: sortData(nextData, column, direction),
    }

    if (!_isEqual(data, nextData)) {
      return nextState
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { storedData, direction, column } = this.state

    const nextState = {
      storedData: sortData(storedData, column, direction),
    }
    if (
      direction !== prevState.direction ||
      !_isEqual(column, prevState.column)
    ) {
      this.setState(nextState)
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onSort } = this.props
    const { storedData } = this.state
    const realData = arrayMove(storedData, oldIndex, newIndex)

    this.setState({
      storedData: realData,
    })

    if (onSort) {
      onSort(realData)
    }
  }

  render() {
    const {
      columns,
      sortable,
      renderMenu,
      menu,
      renderFooter,
      footer,
      tableTitle,
    } = this.props
    const { column, direction, storedData } = this.state

    if (sortable && columns[0].name !== '__drag') {
      columns.unshift({
        title: '',
        name: '__drag',
      })
    }

    return (
      <Table sortable={!sortable}>
        <Header
          columns={columns}
          column={column}
          direction={direction}
          onSort={!sortable && this.sortIt}
          hasMenu={!!renderMenu}
          menu={menu}
          tableTitle={tableTitle}
        />
        <Body
          data={_map(storedData, (rowData, index) =>
            renderRow({ data: rowData, columns, index, renderMenu, menu })
          )}
          useDragHandle={true}
          useWindowAsScrollContainer={true}
          helperClass="DataTable__SortableContainer"
          onSortEnd={this.onSortEnd}
        />
        {footer && (
          <Table.Footer>
            {renderRow({
              data: footer,
              columns,
              index: 1,
              cell: Table.HeaderCell,
              sortable: false,
            })}
          </Table.Footer>
        )}
        {renderFooter && renderFooter(this.props)}
      </Table>
    )
  }
}
