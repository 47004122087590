import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'

const ReportChart = ({ width, height, margin, data, dataKeys }) => (
  <BarChart width={width} height={height} data={data.averages} margin={margin}>
    <XAxis dataKey="name" />
    <YAxis />
    <CartesianGrid strokeDasharray="3 3" />
    <Tooltip />
    <Legend />
    {dataKeys.map(item => (
      <Bar key={item.id} dataKey={item.type} fill={item.fill} />
    ))}
  </BarChart>
)

export default ReportChart
