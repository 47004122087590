import convertUnits from 'convert-units'
import _assign from 'lodash/assign'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _first from 'lodash/first'
import _compose from 'lodash/fp/compose'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import 'moment-recur'
import { createSelector } from 'reselect'

import { has } from 'lodash'
import { getLocationAddressFromRawLocation } from '../utils/locations'

// if already exist a location within Km
const _THRESHOLD = 0.05
const _MILE = 0.621371

const getLocationId = routeLocation => ({
  ...routeLocation,
  id: _get(routeLocation, 'locationId', _get(routeLocation, 'id')),
})

const locationReport = location => {
  const actualMonth = moment().startOf('month')
  const currentYear = moment().year()
  const actualMonthName = actualMonth.format('MMMM')
  const reports =
    location && has(location, 'locationEstimatedActualReports')
      ? location.locationEstimatedActualReports
      : []
  const currentMonthReports = !reports
    ? []
    : reports
        .filter(report => {
          const date = report.run_date
          const monthName = moment(date).format('MMMM')
          const year = moment(date).year()

          return actualMonthName === monthName && currentYear === year
        })
        .sort((a, b) => {
          if (moment(a.run_date).isSameOrAfter(b.run_date)) return 1
          else return -1
        })
  const monthDailyWeights = {}
  const months = moment(actualMonth)
    .add(1, 'month')
    .recur()
    .every(1)
    .months()
    .previous(4)
  moment.duration()
  const weeksDates = {}
  const weeksTotals = {
    'week-1': 0,
    'week-2': 0,
    'week-3': 0,
    'week-4': 0,
    'week-5': 0,
    'week-6': 0,
  }

  const monthsTotals = _reduce(
    months,
    (ac, month) => _assign({ [`${month.format('MMMM')}`]: 0 }, ac),
    {}
  )

  const monthsTotalsReports = _reduce(
    months,
    (ac, month) =>
      _assign(
        {
          [`${month.format('MMMM')}`]: {
            run_estimated: 0,
            run_actuals: 0,
            location_estimated: 0,
          },
        },
        ac
      ),
    {}
  )

  // Calculate weeks dates
  let numberOfWeeksUntilToday = 0

  for (let i = 0; i <= 5; i++) {
    const monthName = moment().format('MMMM')
    const startOfWeek = moment()
      .startOf('month')
      .startOf('week')
      .add(i, 'week')
    const endOfWeek = moment()
      .startOf('month')
      .startOf('week')
      .add(i + 1, 'week')
      .add(-1, 'day')
      .endOf('week')

    if (
      startOfWeek.format('MMMM') === monthName ||
      endOfWeek.format('MMMM') === monthName
    )
      weeksDates[`week-${i + 1}`] = {
        start: startOfWeek,
        end: endOfWeek,
      }

    // if (startOfWeek.isSameOrBefore(today) || endOfWeek.isSameOrBefore(today))
    //   numberOfWeeksUntilToday++
  }

  // Totals
  currentMonthReports.forEach(report => {
    const date = report.run_date
    const monthName = moment(date).format('MMMM')

    // Daily
    if (monthDailyWeights[date]) {
      monthDailyWeights[date].weight += Math.round(report.location_actual)

      if (report.route)
        if (
          !monthDailyWeights[date].routes.some(
            route => route.id === report.route.id
          )
        )
          monthDailyWeights[date].routes.push(report.route)
    } else {
      monthDailyWeights[date] = {
        weight: Math.round(report.location_actual),
        routes: report.route ? [{ ...report.route }] : [],
      }
    }

    if (isNaN(monthDailyWeights[date].weight))
      monthDailyWeights[date].weight = 0

    // Monthly
    if (monthsTotalsReports[monthName]) {
      monthsTotals[monthName] += Math.round(report.location_actual)

      if (isNaN(monthsTotals[monthName])) monthsTotals[monthName] = 0
    }

    // Weekly
    Object.keys(weeksDates).forEach(weekNumber => {
      if (
        moment(date).isSameOrAfter(weeksDates[weekNumber].start) &&
        moment(date).isSameOrBefore(weeksDates[weekNumber].end) &&
        actualMonthName === monthName
      ) {
        weeksTotals[weekNumber] += Math.round(report.location_actual)

        if (isNaN(weeksTotals[weekNumber])) weeksTotals[weekNumber] = 0
      }
    })
  })

  numberOfWeeksUntilToday = _filter(weeksTotals, week => week !== 0).length
  const actualMonthTotal = _get(monthsTotals, actualMonthName, 0)
  const weekAverage = actualMonthTotal / numberOfWeeksUntilToday || 0

  return _assign(
    {
      monthsTotals,
      weeksTotals,
      weekAverage,
      actualMonthName,
      actualMonthTotal,
      monthDailyWeights,
    },
    location
  )
}

// const km2mi = km => formatNumber(_isNumber(km) ? km * _MILE : 0)
// const km2Meters = km => formatNumber(_isNumber(km) ? km * 1000 : 0)

function toString() {
  return `${this.number} ${this.abbreviation}`
}

const Distance = (number, abbreviation, km) => ({
  number,
  abbreviation,
  toString: toString,
  rawDistance: km,
})

const formatDistance = data => {
  const distance = pickDistance(data)
  let display = Distance(0, 'ft-us', distance)

  if (distance < _MILE) {
    const formatedDistance = convertUnits(distance)
      .from('km')
      .to('ft-us')
    display = Distance(formatedDistance, 'ft', distance)
  } else {
    const formatedDistance = convertUnits(distance)
      .from('km')
      .to('mi')
    display = Distance(formatedDistance, 'mi.', distance)
  }

  return {
    ...data,
    distance: display,
    rawDistance: distance,
  }
}

const pickDistance = location => _get(location, 'distance', 0)

const hasLocationTooClose = locations =>
  _find(locations, location => pickDistance(location) <= _THRESHOLD)

const sortLocations = locations => _sortBy(locations, 'stop_sequence')
const sortCloseLocations = locations => _sortBy(locations, pickDistance)

const Location = _compose(
  getLocationId,
  getLocationAddressFromRawLocation,
  locationReport
)
const CloseLocation = _compose(formatDistance, getLocationId)

export const Locations = createSelector(
  data => _get(data, 'locations'),
  rawLocations => {
    const locations = _map(sortLocations(rawLocations), Location)
    const location = _first(locations)

    return {
      locations,
      location,
    }
  }
)

export const CloseLocations = createSelector(
  data => _get(data, 'locations'),
  rawLocations => {
    const locations = _map(sortCloseLocations(rawLocations), CloseLocation)
    const location = _first(locations)
    const existingLocation = hasLocationTooClose(rawLocations)

    return {
      locations,
      location,
      existingLocation: existingLocation && CloseLocation(existingLocation),
    }
  }
)

export const LocationReport = createSelector(
  data => _get(data, 'location'),
  rawLocation => {
    const location = Location(rawLocation)

    return {
      location,
    }
  }
)

const sortTaggedLocations = locations => _sortBy(locations, 'timestamp')
export const TaggedLocations = createSelector(
  data => _get(data, 'taggedLocations'),
  rawLocations => {
    const locations = sortTaggedLocations(rawLocations)
    const location = _first(locations)

    return {
      locations,
      location,
    }
  }
)
