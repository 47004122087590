import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'
import _get from 'lodash/get'

import { Query } from 'react-apollo'

const WithQuery = ({
  QL,
  queryName,
  selector,
  variablesPicker,
  selectorWithProps = () => {},
  ...queryOps
}) => Component => {
  class WrappedComponent extends React.PureComponent {
    render() {
      const variables = variablesPicker(this.props)

      if (_isEmpty(variables)) return <Component {...this.props} />

      return (
        <Query {...queryOps} query={QL[queryName]} variables={variables}>
          {result => (
            <Component
              {..._merge(
                {},
                this.props,
                result,
                selector(_get(result, 'data')),
                selectorWithProps(selector(_get(result, 'data')), this.props)
              )}
            />
          )}
        </Query>
      )
    }
  }

  return WrappedComponent
}

export default WithQuery
