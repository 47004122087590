import MapMarker from '../MapMarker'
import { withAuth } from '../../connectors/auth'
import { withBinActions } from '../../connectors/bins'
import { withMarkets } from '../../connectors/markets'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { Link, withRouter } from 'react-router-dom'
import { Button, Card, Divider, Dropdown, Icon } from 'semantic-ui-react'
import { SimpleLoading } from '../Loading'

const MissingBinsMap = ({
  current,
  getMissingBins,
  market,
  auth: { user: { markets } },
  ...props
}) => {
  const [selectedMarket, setSelectedMarket] = useState(
    current ? [current.id] : []
  )
  const [bins, setBins] = useState([])
  const [isLoading, setLoading] = useState(false)

  const handleMarketChange = market => {
    if (market !== 'all-markets') setSelectedMarket(market)
    else setSelectedMarket('all-markets')
  }

  const marketOptions = () => {
    const marketOptions = [
      {
        value: 'all-markets',
        text: 'All Markets',
      },
    ]

    marketOptions.push(
      ...markets.map(market => ({
        value: market.id,
        text: market.name,
      }))
    )

    return marketOptions
  }

  const renderButtons = () => {
    const { closeModal, renderButtons } = props

    return renderButtons(
      <Button
        positive
        onClick={() => {
          closeModal()
        }}
      >
        Close
      </Button>
    )
  }

  // const markerInfo = location => {
  //   return (
  //     <Card fluid>
  //       <Card.Content>
  //         <Card.Header>{location.name}</Card.Header>
  //         <Card.Meta>
  //           {location.distance && location.distance.toString()}
  //         </Card.Meta>
  //         <Card.Description>{location.address}</Card.Description>
  //       </Card.Content>
  //     </Card>
  //   )
  // }

  const markerPopup = binData => {
    return (
      <Card>
        <Card.Content>
          <Card.Header>{binData.name}</Card.Header>
          <Card.Meta>
            {binData.location && (
              <Link to={`/app/locations/${binData.locationId}`}>
                <Icon name="pin" />
                {binData.location.name}
              </Link>
            )}
          </Card.Meta>
          <Card.Description>
            {binData.routes &&
              binData.routes.map(route => (
                <Link
                  to={`/app/routes/${route.market}/${route.id}`}
                  style={{ color: route.color }}
                >
                  <p>
                    <Icon name="marker" />
                    {`${route.name}`}
                  </p>
                </Link>
              ))}
            {binData.location && (
              <p>
                <Icon name="zip" />
                {`${binData.location.address} ${binData.location.city}, ${
                  binData.location.state
                }, ${binData.location.postal}`}
              </p>
            )}
          </Card.Description>
        </Card.Content>
        {/* <Card.Content extra>
          <Button
            positive
            content="Use this one"
            // onClick={() => onSelect(location)}
          />
        </Card.Content> */}
      </Card>
    )
  }

  useEffect(
    () => {
      const marketsToFetch =
        selectedMarket !== 'all-markets'
          ? selectedMarket
          : markets.map(market => market.id)

      setLoading(true)
      getMissingBins(marketsToFetch)
        .then(({ data: { getMissingBins } }) => {
          setBins(getMissingBins)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [selectedMarket]
  )

  useEffect(() => {
		const defaultMarket = current || market || 'all-markets'

		setSelectedMarket(
			defaultMarket !== 'all-markets'
			? defaultMarket.id
			: 'all-markets'
		)
	}, [
		current,
		market,
	])

  return (
    <Fragment>
      {isLoading && <SimpleLoading loading={true} />}
      <Grid>
        <Row>
          <Col xs={4}>
            <Dropdown
              fluid
              value={selectedMarket}
              selection
              options={marketOptions()}
              defaultValue={'all-markets'}
              placeholder="All Markets"
              onChange={(e, { value }) => {
                handleMarketChange(value)
              }}
            />
            {/* <MarketsDropdown
              selected={selectedMarket}
              onSelect={market => {
                handleMarketChange(market)
              }}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Divider />
            <MapMarker
              locations={bins.map(bin => ({
                id: bin.id,
                latitude: bin.location ? bin.location.latitude : null,
                longitude: bin.location ? bin.location.longitude : null,
                name: bin.serial_number,
                routes: bin.routes,
                location: bin.location,
                locationId: bin.locationId,
              }))}
              // renderLocationTooltip={markerInfo}
              renderLocationPopup={markerPopup}
            />
          </Col>
        </Row>
      </Grid>
      {renderButtons()}
    </Fragment>
  )
}

export default withRouter(withAuth(withMarkets(withBinActions(MissingBinsMap))))
