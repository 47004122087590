import get from 'lodash/get'
import has from 'lodash/has'
import React, { Fragment } from 'react'
import { Dimmer, Loader, Message } from 'semantic-ui-react'
import 'url-search-params-polyfill'

import { cloneDeep } from 'lodash'
import './style.scss'

const quarters = {
  q1: ['jan', 'feb', 'mar'],
  q2: ['apr', 'may', 'jun'],
  q3: ['jul', 'aug', 'sep'],
  q4: ['oct', 'nov', 'dec'],
}

const columns = [
  {
    name: 'name',
    rules: {},
    title: 'Quarter',
  },
  {
    name: 'bins',
    rules: {
      round: true,
    },
    title: 'Bin Count',
  },
  {
    name: 'runs',
    rules: {
      round: true,
    },
    title: 'No. of Runs',
  },
  {
    name: 'total_lbs_collected',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
    },
    title: 'Total LBS Collected',
  },
  {
    name: 'average_lbs_collected',
    rules: {
      round: true,
    },
    title: 'Average LBS Collected',
  },
  {
    name: 'truck_fixed_monthly_cost',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
      currency: true,
    },
    title: 'Truck Fixed Monthly Cost',
  },
  {
    name: 'truck_average_cost_per_run',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
      currency: true,
    },
    title: 'Truck Average Cost Per Run',
  },
  {
    name: 'truck_cost_cpp',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
      currency: true,
    },
    title: 'Truck Cost (CPP)',
  },
  {
    name: 'total_mileage',
    rules: {
      round: true,
    },
    title: 'Total Mileage',
  },
  {
    name: 'average_mileage',
    rules: {
      round: true,
    },
    title: 'Average Mileage',
  },
  {
    name: 'average_mileage_cost_cpp',
    rules: {
      formatAsDecimal: true,
      decimals: 5,
      currency: true,
    },
    title: 'Average Mileage Cost (CPP)',
  },
  {
    name: 'price_per_gallon',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
      currency: true,
    },
    title: 'Price per Gallon',
  },
  {
    name: 'average_mpg',
    rules: {
      formatAsDecimal: true,
      decimals: 3,
    },
    title: 'Average MPG',
  },
  {
    name: 'average_fuel_cost',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
      currency: true,
    },
    title: 'Average Fuel Cost',
  },
  {
    name: 'average_fuel_cost_cpp',
    rules: {
      formatAsDecimal: true,
      decimals: 3,
      currency: true,
    },
    title: 'Average Fuel Cost (CPP)',
  },
  {
    name: 'average_salary',
    rules: {
      formatAsDecimal: true,
      decimals: 3,
    },
    title: 'Average Salary',
  },
  {
    name: 'salary_cost_cpp',
    rules: {
      formatAsDecimal: true,
      decimals: 3,
      currency: true,
    },
    title: 'Salary Cost (CPP)',
  },
  {
    name: 'average_cost_per_run',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
      currency: true,
    },
    title: 'Average Cost per Run',
  },
  {
    name: 'average_cost_per_bin',
    rules: {
      formatAsDecimal: true,
      decimals: 2,
      currency: true,
    },
    title: 'Average Cost per Bin',
  },
  {
    name: 'route_cost_cpp',
    rules: {
      formatAsDecimal: true,
      decimals: 3,
      currency: true,
    },
    title: 'Route Cost (CPP)',
  },
]

function formatAsDecimal(num, maximumFractionDigits = 2) {
  return Number(num || '').toLocaleString(undefined, {
    maximumFractionDigits,
  })
}

export default class CumulativeQuarterlyReviewRouteCostReport extends React.PureComponent {
  renderTableHeader() {
    return (
      <thead>
        <tr>{columns.map(col => <th>{col.title}</th>)}</tr>
      </thead>
    )
  }

  renderQuarterReport(
    quarter,
    currentYearQuarterReport,
    previousYearQuarterReport
  ) {
    const { year, include_previous_year } = this.props
    const reportYear = Number(year)
    const rows = []

    rows.push(
      <tr className="current-year">
        <td
          rowSpan={include_previous_year && previousYearQuarterReport ? 2 : 1}
        >
          {quarter.toUpperCase()}
          {include_previous_year &&
            previousYearQuarterReport && (
              <Fragment>
                <span class="current-year-number">{reportYear}</span>
                <span class="previous-year-number">{reportYear - 1}</span>
              </Fragment>
            )}
        </td>
        {this.renderQuarterReportCells(currentYearQuarterReport)}
      </tr>
    )

    if (include_previous_year && previousYearQuarterReport)
      rows.push(
        <tr>{this.renderQuarterReportCells(previousYearQuarterReport)}</tr>
      )

    return rows
  }

  renderQuarterReportCells(report) {
    return (
      <Fragment>
        {columns.map(column => {
          if (column.name === 'name') return null

          let value = 'N/A'

          if (isFinite(report[column.name])) {
            value = report[column.name]

            if (column.rules.round) value = Math.round(value)

            if (column.rules.formatAsDecimal)
              value = formatAsDecimal(value, column.rules.decimals)
          }

          if (value !== 'N/A' && column.rules.currency) value = `$ ${value}`

          return <td>{value}</td>
        })}
      </Fragment>
    )
  }

  renderMarketReport(currentYearReport, previousYearReport) {
    return (
      <div
        style={{
          overflowX: 'auto',
        }}
        className="route-cumulative-quarterly-cost-report-table-container"
      >
        <table className="ui sortable table">
          {this.renderTableHeader()}
          <tbody>
            {Object.keys(quarters).map(quarter => {
              if (previousYearReport) {
                return [
                  this.renderQuarterReport(
                    quarter,
                    currentYearReport.quarters[quarter],
                    previousYearReport.quarters[quarter]
                  ),
                ]
              } else {
                return [
                  this.renderQuarterReport(
                    quarter,
                    currentYearReport.quarters[quarter],
                    null
                  ),
                ]
              }
            })}
            {this.renderAverageRow(currentYearReport, previousYearReport)}
          </tbody>
        </table>
      </div>
    )
  }

  renderAverageRow(currentYearReport, previousYearReport) {
    const { year, include_previous_year } = this.props
    const reportYear = Number(year)
    const rows = []

    const currentYearAverage = {}
    columns.forEach(col => {
      if (col.name === 'name') return true

      const sum = Object.values(currentYearReport.quarters).reduce(
        (prev, curr) => {
          if (isNaN(curr[col.name])) return prev

          return prev + curr[col.name]
        },
        0
      )
      currentYearAverage[col.name] = sum / 4
    })

    rows.push(
      <tr className="current-year">
        <td rowSpan={include_previous_year && previousYearReport ? 2 : 1}>
          Yearly Average
          {include_previous_year &&
            previousYearReport && (
              <Fragment>
                <span class="current-year-number">{reportYear}</span>
                <span class="previous-year-number">{reportYear - 1}</span>
              </Fragment>
            )}
        </td>
        {this.renderQuarterReportCells(currentYearAverage)}
      </tr>
    )

    if (include_previous_year && previousYearReport) {
      const previousYearAverage = {}
      columns.forEach(col => {
        if (col.name === 'name') return true

        const sum = Object.values(previousYearReport.quarters).reduce(
          (prev, curr) => {
            if (isNaN(curr[col.name])) return prev

            return prev + curr[col.name]
          },
          0
        )
        previousYearAverage[col.name] = sum / 4
      })

      rows.push(<tr>{this.renderQuarterReportCells(previousYearAverage)}</tr>)
    }

    return rows
  }

  renderReport() {
    const { year, cumulativeQuarterlyRouteCostData, routeId } = this.props
    const yearReport = year ? Number(year) : null
    let table = null
    let renderReport = false

    let currentYearData =
      cumulativeQuarterlyRouteCostData.length > 0
        ? cumulativeQuarterlyRouteCostData.find(
            report => Number(report.year) === yearReport
          )
        : null
    let previousYearData =
      cumulativeQuarterlyRouteCostData.length > 0
        ? cumulativeQuarterlyRouteCostData.find(
            report => Number(report.year) === yearReport - 1
          )
        : null

    if (currentYearData) {
      renderReport = true

      currentYearData = {
        year: yearReport,
        quarters: {},
        months: [...currentYearData.months],
      }

      Object.keys(quarters).forEach(quarter => {
        currentYearData.quarters[quarter] = calculateQuarterReport(
          cloneDeep(currentYearData),
          quarter,
          routeId
        )
      })
    }

    if (previousYearData) {
      previousYearData = {
        year: yearReport - 1,
        quarters: {},
        months: [...previousYearData.months],
      }

      Object.keys(quarters).forEach(quarter => {
        previousYearData.quarters[quarter] = calculateQuarterReport(
          cloneDeep(previousYearData),
          quarter,
          routeId
        )
      })
    }

    if (renderReport)
      table = this.renderMarketReport(currentYearData, previousYearData)
    else table = this.renderNoDataMessage()

    return table
  }

  renderNoDataMessage() {
    return (
      <Message black>
        <Message.Header>There is no data to display!</Message.Header>
        <p>Please pick a date to load report</p>
      </Message>
    )
  }

  render() {
    const { loading, cumulativeQuarterlyRouteCostData } = this.props

    return [
      <div>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {cumulativeQuarterlyRouteCostData ? (
          <Fragment>{this.renderReport()}</Fragment>
        ) : (
          <Fragment>{this.renderNoDataMessage()}</Fragment>
        )}
      </div>,
    ]
  }
}

function calculateQuarterReport(yearData, quarter, routeId) {
  const months = []
  const average = {}

  quarters[quarter].forEach(month => {
    yearData.months.forEach(monthReport => {
      if (monthReport.month === month) {
        months.push({
          month: monthReport.month,
          report: calculateMonthReport(monthReport.report, routeId),
        })
      }
    })
  })

  columns.forEach(col => {
    if (col.name !== 'name') {
      const sum = months.reduce((prev, curr) => {
        if (isNaN(curr.report[col.name])) return prev

        return prev + curr.report[col.name]
      }, 0)

      average[col.name] = sum / months.length
    }
  })

  return average
}

function calculateMonthReport(report, routeId) {
  const total_truck_lease_payment = has(report, 'total_truck_lease_payment')
    ? report.total_truck_lease_payment
    : 0
  const mileage_charge = has(report, 'mileage_charge')
    ? report.mileage_charge
    : 0
  const price_per_gallon = has(report, 'price_per_gallon')
    ? report.price_per_gallon
    : 0
  const average_mpg = has(report, 'average_mpg') ? report.average_mpg : 0
  const truck_cost_per_run = has(report, 'truck_cost_per_run')
    ? !isFinite(report.truck_cost_per_run) || isNaN(report.truck_cost_per_run)
      ? 0
      : Number(report.truck_cost_per_run)
    : 0

  report.average_mpg = isFinite(average_mpg) ? average_mpg : report.average_mpg
  report.mileage_charge = isFinite(mileage_charge)
    ? mileage_charge
    : report.mileage_charge
  report.price_per_gallon = isFinite(price_per_gallon)
    ? price_per_gallon
    : report.price_per_gallon
  report.total_truck_lease_payment = isFinite(total_truck_lease_payment)
    ? total_truck_lease_payment
    : report.total_truck_lease_payment
  report.truck_cost_per_run = isFinite(truck_cost_per_run)
    ? truck_cost_per_run
    : report.truck_cost_per_run
  report.truck_cost_per_run = truck_cost_per_run
  report['total'] = {
    name: 'Totals',
    bins: 0,
    runs: 0,
    total_lbs_collected: 0,
    truck_fixed_monthly_cost: 0,
    total_mileage: 0,
    salary: 0,
    average_mileage: 0,
    average_lbs_collected: 0,
    truck_cost_cpp: 0,
    average_mileage_cost: 0,
    average_mileage_cost_cpp: 0,
    average_fuel_cost: 0,
    average_fuel_cost_cpp: 0,
    salary_cost_cpp: 0,
    average_cost_per_run: 0,
    average_cost_per_bin: 0,
    route_cost_cpp: 0,
    totalAverageMileage: 0,
    totalAverageFuelCost: 0,
    totalSalary: 0,
    totalAverageLbsCollected: 0,
    totalAverageMileageCost: 0,
    totalAverageCostPerBin: 0,
    numberOfAverageCostPerBin: 0,
    numberOfSalary: 0,
  }

  report.routes.forEach(routeReport => {
    // Route report
    routeReport.truck_fixed_monthly_cost = truck_cost_per_run * routeReport.runs
    routeReport.truck_cost_cpp =
      truck_cost_per_run / routeReport.average_lbs_collected
    routeReport.average_mileage_cost =
      routeReport.average_mileage * mileage_charge
    routeReport.average_mileage_cost_cpp =
      routeReport.average_mileage_cost / routeReport.average_lbs_collected
    routeReport.average_fuel_cost =
      routeReport.average_mileage / average_mpg * price_per_gallon
    routeReport.average_fuel_cost_cpp =
      routeReport.average_fuel_cost / routeReport.average_lbs_collected
    // routeReport.salary = has(formValues, `salary.${routeReport.id}`)
    //   ? formValues.salary[routeReport.id]
    //   : routeReport.salary
    routeReport.salary_cost_cpp =
      routeReport.salary / routeReport.average_lbs_collected
    routeReport.average_cost_per_run =
      Number(truck_cost_per_run) +
      routeReport.average_mileage_cost +
      routeReport.average_fuel_cost +
      routeReport.salary
    routeReport.average_cost_per_bin =
      routeReport.average_cost_per_run / routeReport.bins
    routeReport.route_cost_cpp =
      routeReport.average_cost_per_run / routeReport.average_lbs_collected

    // Total report
    let considerThisRouteInTotal = false

    if (routeId.length === 0 || routeId.includes(routeReport.id)) {
      considerThisRouteInTotal = true
    }

    if (considerThisRouteInTotal) {
      report.total.bins += get(routeReport, 'bins', 0)
      report.total.runs += get(routeReport, 'runs', 0)
      report.total.total_lbs_collected += get(
        routeReport,
        'total_lbs_collected',
        0
      )
      report.total.truck_fixed_monthly_cost += Math.round(
        get(routeReport, 'truck_fixed_monthly_cost', 0)
      )
      report.total.total_mileage += get(routeReport, 'total_mileage', 0)

      report.total.totalAverageMileage += get(routeReport, 'average_mileage', 0)
      report.total.totalAverageFuelCost += get(
        routeReport,
        'average_fuel_cost',
        0
      )
      report.total.totalSalary += isFinite(get(routeReport, 'salary', 0))
        ? Number(get(routeReport, 'salary', 0))
        : 0
      report.total.numberOfSalary +=
        isFinite(get(routeReport, 'salary', 0)) &&
        get(routeReport, 'salary', 0) > 0
          ? 1
          : 0
      report.total.totalAverageLbsCollected += get(
        routeReport,
        'average_lbs_collected',
        0
      )
      report.total.totalAverageMileageCost += get(
        routeReport,
        'average_mileage_cost',
        0
      )
      report.total.totalAverageCostPerBin += isFinite(
        get(routeReport, 'average_cost_per_bin', 0)
      )
        ? get(routeReport, 'average_cost_per_bin', 0)
        : 0
      report.total.numberOfAverageCostPerBin +=
        isFinite(get(routeReport, 'average_cost_per_bin', 0)) &&
        get(routeReport, 'average_cost_per_bin', 0) > 0
          ? 1
          : 0

      report.total.average_mileage = Math.round(
        report.total.total_mileage / report.total.runs
      )
      report.total.average_lbs_collected = Math.round(
        report.total.total_lbs_collected / report.total.runs
      )
      report.total.truck_cost_cpp =
        truck_cost_per_run / report.total.average_lbs_collected
      report.total.average_mileage_cost =
        report.total.average_mileage * mileage_charge
      report.total.average_mileage_cost_cpp =
        report.total.average_mileage_cost / report.total.average_lbs_collected
      report.total.average_fuel_cost =
        report.total.average_mileage / average_mpg * price_per_gallon
      report.total.average_fuel_cost_cpp =
        report.total.totalAverageFuelCost /
        report.total.totalAverageLbsCollected
      report.total.salary =
        report.total.totalSalary / report.total.numberOfSalary
      report.total.salary_cost_cpp =
        report.total.salary / report.total.average_lbs_collected
      report.total.average_cost_per_run =
        truck_cost_per_run +
        report.total.average_mileage_cost +
        report.total.average_fuel_cost +
        report.total.salary
      report.total.average_cost_per_bin =
        report.total.totalAverageCostPerBin /
        report.total.numberOfAverageCostPerBin
      report.total.route_cost_cpp =
        report.total.average_cost_per_run / report.total.average_lbs_collected
    }
  })

  return {
    bins: report.total.bins,
    runs: report.total.runs,
    total_lbs_collected: report.total.total_lbs_collected,
    average_lbs_collected: report.total.average_lbs_collected,
    truck_fixed_monthly_cost: report.total.truck_fixed_monthly_cost,
    truck_average_cost_per_run: report.truck_cost_per_run,
    truck_cost_cpp: report.total.truck_cost_cpp,
    total_mileage: report.total.total_mileage,
    average_mileage: report.total.average_mileage,
    average_mileage_cost_cpp: report.total.average_mileage_cost_cpp,
    price_per_gallon: report.price_per_gallon,
    average_mpg: report.average_mpg,
    average_fuel_cost: report.total.average_fuel_cost,
    average_fuel_cost_cpp: report.total.average_fuel_cost_cpp,
    average_salary: report.total.salary,
    salary_cost_cpp: report.total.salary_cost_cpp,
    average_cost_per_bin: report.total.average_cost_per_bin,
    average_cost_per_run: report.total.average_cost_per_run,
    route_cost_cpp: report.total.route_cost_cpp,
  }
}
