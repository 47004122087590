import React from 'react'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _eq from 'lodash/eq'

import { WithRoutes } from '../../connectors/markets'
import { withRoutesActions } from '../../connectors/routes'
import WithQuerySearch from '../../connectors/querySearch'

import { Segment } from 'semantic-ui-react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import MapMarker from '../../components/MapMarker'
import MenuLocationOptions from '../../components/MenuLocationOptions'

import { WithMarkersByRoutesLocations } from '../../connectors/markerMap'

@WithMarkersByRoutesLocations
class MarketMap extends React.PureComponent {
  render() {
    const { locations, traces, ...rest } = this.props
    return <MapMarker locations={locations} traces={traces} {...rest} />
  }
}

const routesAsOptions = (location, routes) => {
  // Filter the Route that the Location already belongs
  return _filter(routes, ({ id }) => !_eq(id, location.routeId))
}

@WithQuerySearch
@WithRoutes
@withRoutesActions
export default class Market extends React.Component {
  render() {
    const { routes, loading, search } = this.props
    const routeIds = _get(search, 'routes')

    return (
      <Segment
        padded={false}
        loading={loading || !routes}
        className="Market__container"
      >
        <Grid>
          <Row>
            <Col xs>
              <MarketMap
                routes={routes}
                routeIds={routeIds}
                renderLocationPopup={this.renderLocationPopup}
              />
            </Col>
          </Row>
        </Grid>
      </Segment>
    )
  }

  renderLocationPopup = location => {
    const {
      routes,
      deleteLocation,
      editLocation,
      routeMoveLocation,
      routeCopyLocation,
    } = this.props
    return (
      <MenuLocationOptions
        {...location}
        routes={routesAsOptions(location, routes)}
        onEdit={editLocation}
        onRemove={deleteLocation}
        onCopyTo={routeCopyLocation}
        onMoveTo={routeMoveLocation}
      />
    )
  }
}
