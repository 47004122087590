import { trigger, triggerError } from '../actions'
import _get from 'lodash/get'
import { auth } from '../utils/firebase'

import apolloClient from '../api/apolloClient'
import { auth as authQuery, user as userQuery } from '../api/Query/users'

export const _AUTH_AUTHENTICATE = 'AUTH_AUTHENTICATE'
export const _AUTH_ERROR = 'AUTH_ERROR'
export const _AUTH_LOADED = 'AUTH_LOADED'
export const _AUTH_LOADING = 'AUTH_LOADING'
export const _AUTH_SIGNOUT = 'AUTH_SIGNOUT'
export const _AUTH_PASSWORD_RESET = 'AUTH_PASSWORD_RESET'
export const _AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR'

export const queryUserData = authData => {
  const authUser = _get(authData, 'user', authData)
  return apolloClient
    .query({
      query: userQuery,
      variables: { id: _get(authUser, 'uid', '') },
    })
    .then(res => res.data.userData)
    .then(user => ({ ...user, authUser }))
}

export const clearError = () => {
  return dispatch => dispatch(trigger(_AUTH_CLEAR_ERROR)())
}

export const start = () => {
  return dispatch => {
    dispatch(trigger(_AUTH_LOADING)())

    return new Promise((yup, nope) => {
      auth.onAuthStateChanged(user => {
        if (!user) {
          return nope()
        }
        yup(user)
      })
    })
      .then(queryUserData)
      .then(trigger(_AUTH_AUTHENTICATE))
      .then(dispatch)
      .catch(() => {})
      .then(() => {
        dispatch(trigger(_AUTH_LOADED)())
      })
  }
}

export const signout = () => {
  return dispatch => {
    return auth
      .signOut()
      .then(trigger(_AUTH_SIGNOUT))
      .catch(triggerError(_AUTH_ERROR, 'Error signing out'))
      .then(dispatch)
      .then(() => {
        dispatch(trigger(_AUTH_LOADED)())
      })
  }
}

export const signin = ({ email, password, dashboard = true }) => {
  return dispatch => {
    dispatch(trigger(_AUTH_LOADING)())

    return apolloClient
      .query({
        query: authQuery,
        variables: { username: email, password, dashboard },
        fetchPolicy: 'network-only',
      })
      .catch(({ graphQLErrors }) => {
        throw new Error(graphQLErrors.map(e => e.message).join('\n'))
      })
      .then(res => res.data.auth)
      .then(user => auth.signInWithCustomToken(user.token).then(queryUserData))
      .then(trigger(_AUTH_AUTHENTICATE))
      .catch(trigger(_AUTH_ERROR))
      .then(dispatch)
      .then(res => {
        dispatch(trigger(_AUTH_LOADED))
        return res
      })
  }
}

export const signup = ({ email, password }) => {
  return dispatch => {
    dispatch(trigger(_AUTH_LOADING)())

    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(trigger(_AUTH_AUTHENTICATE))
      .catch(triggerError(_AUTH_ERROR, 'Unable to Sign Up'))
      .then(dispatch)
      .then(() => {
        dispatch(trigger(_AUTH_LOADED)())
      })
  }
}

export const resetPassword = ({ email }) => {
  return dispatch => {
    dispatch(trigger(_AUTH_LOADING)())

    return auth
      .sendPasswordResetEmail(email)
      .then(trigger(_AUTH_PASSWORD_RESET))
      .catch(trigger(_AUTH_ERROR))
      .then(dispatch)
  }
}
