import MarketsDropdown from '../../components/MenuDropdown/Markets'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react'
import { generateArrayOfYears, getCurrentYear } from '../../utils/date'
import { WithRouteCostActions } from '../../connectors/routeCostAnalysis'

const Filters = withRouter(
  ({
    match: { url },
    location: { search },
    history,
    routes,
    loading,
    generateCumulativeQuarterlyRouteCostExcelFile,
  }) => {
    let query = new URLSearchParams(window.location.search)

    const [exportToExcelInprogress, setExportToExcelFileInprogress] = useState(
      false
    )

    function resetMarket() {
      query = new URLSearchParams(window.location.search)
      query.delete('market')
      query.delete('route')
      history.push(`${url}?${query.toString()}`)
    }

    function onMarketChange(market) {
      query = new URLSearchParams(window.location.search)
      query.set('market', market.id)
      query.delete('route')
      history.push(`${url}?${query.toString()}`)
    }

    function onRouteChange(value) {
      query = new URLSearchParams(window.location.search)
      let selectedRoutes = []

      if (value.includes('all-routes')) {
        if (routeOptions.length !== value.length) {
          selectedRoutes = routeOptions
            .filter(option => option.value !== 'all-routes')
            .map(item => item.value)
        }
      } else {
        selectedRoutes = value
      }

      if (selectedRoutes.length) {
        query.set('route', selectedRoutes.join(','))
      } else {
        query.delete('route')
      }

      history.push(`${url}?${query.toString()}`)
    }

    function onYearChange(value) {
      query = new URLSearchParams(window.location.search)
      query.set('year', value)
      history.push(`${url}?${query.toString()}`)
    }

    function onPreviousYearIncludedChange(value) {
      query = new URLSearchParams(window.location.search)
      query.set('include-previous-year', value)
      history.push(`${url}?${query.toString()}`)
    }

    function dropFilter(name) {
      query = new URLSearchParams(window.location.search)
      query.delete(name)
      history.push(`${url}?${query.toString()}`)
    }

    function renderExportButton() {
      let disabled = true
      const selectedMarket = query.get('market')
      const selectedYear = query.get('year')

      if (selectedMarket && selectedYear) {
        disabled = false
      }

      return (
        <Button
          color="green"
          disabled={disabled || exportToExcelInprogress}
          onClick={onExportToExcelFileClick}
          loading={exportToExcelInprogress}
        >
          <Icon name="file excel outline" />
          Export to xlsx
        </Button>
      )
    }

    function onExportToExcelFileClick() {
      const allRoutesId = routes.map(route => route.id)
      const selectedMarket = query.get('market')
      const selectedRoutes =
        !query.get('route') || query.get('route') === 'all-routes'
          ? allRoutesId
          : query.get('route').split(',')
      const allRoutes =
        !query.get('route') || query.get('route') === 'all-routes'
      const selectedYear = query.get('year')
      const includePreviousYear = !query.get('include-previous-year')
        ? false
        : query.get('include-previous-year') === 'true' ? true : false

      setExportToExcelFileInprogress(true)

      generateCumulativeQuarterlyRouteCostExcelFile(
        selectedMarket,
        selectedRoutes,
        allRoutes,
        selectedYear,
        includePreviousYear
      )
        .then(data => {
          window.open(
            data.data.generateCumulativeQuarterlyRouteCostExcelFile.url
          )
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setExportToExcelFileInprogress(false)
        })
    }

    const routeOptions = [{ text: 'All Routes', value: 'all-routes' }]
    const years = generateArrayOfYears(50)
    years.unshift(getCurrentYear() + 1)

    if (query.has('market') && routes) {
      routes.forEach(route => {
        routeOptions.push({
          text: route.name,
          value: route.id,
        })
      })
    }

    const previousYearIncludedOptions = [
      {
        text: 'Hide Previous Year Report',
        value: false,
      },
      {
        text: 'Show Previous Year Report',
        value: true,
      },
    ]

    return (
      <Grid className="Reports__Filters__CQRCA">
        <Grid.Column mobile={16} computer={3} key="col1">
          <MarketsDropdown
            activeLabel={false}
            label="Select Market"
            showAll
            selected={query.get('market')}
            onSelect={market => {
              if (!market) {
                resetMarket()
              } else {
                onMarketChange(market)
              }
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} key="col2">
          <Dropdown
            fluid
            value={query.get('route') ? query.get('route').split(',') : []}
            selection
            defaultValue="all-routes"
            options={routeOptions}
            multiple
            placeholder="All Routes"
            loading={loading}
            disabled={loading || !routes.length || !query.has('market')}
            onChange={(e, { value }) => {
              if (value) onRouteChange(value)
              else dropFilter('route')
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} key="col3">
          <Dropdown
            fluid
            selection
            defaultValue={query.get('year')}
            value={query.get('year')}
            options={years.map(year => ({ text: year, value: String(year) }))}
            placeholder="Select Year"
            loading={loading}
            disabled={loading || !query.has('market')}
            onChange={(e, { value }) => {
              if (value) {
                onYearChange(value)
              } else {
                dropFilter('year')
              }
            }}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          computer={4}
          key="col4"
          style={{ display: 'flex', alignItems: 'start' }}
        >
          <Dropdown
            fluid
            value={query.get('include-previous-year') === 'true' ? true : false}
            selection
            defaultValue={false}
            options={previousYearIncludedOptions}
            loading={loading}
            disabled={loading}
            onChange={(e, { value }) => {
              onPreviousYearIncludedChange(value)
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} textAlign="right">
          {renderExportButton()}
        </Grid.Column>
      </Grid>
    )
  }
)

export default WithRouteCostActions(Filters)
