import gql from 'graphql-tag'
import { CompetitorModel } from './competitors'

export const LocationPartial = `
  id
  name
  address
  city
  state
  postal
  latitude
  longitude
  status
`
export const Location = `
  ${LocationPartial}
  bins {
    id
    box_code
  }
`

export const RouteLocation = `
  ${Location}
  locationId
  stop_sequence
  markedCompetitorsAsRemove {
      ${CompetitorModel}
    }
  routes {
    id
    name
    color
  }
`
export const RouteLocationPartial = `
  ${LocationPartial}
  locationId
  stop_sequence
`

export const RouteBase = `
  id
  name
  color
  code
  optimized {
    timestamp
    travel_time
  }
  optimized_miles
  optimized_miles_at
  trace
  start_location {
    ${Location}
  }
  end_location {
    ${Location}
  }
  unloading_location {
    ${Location}
  }
  inactive
`
export const Route = `
  ${RouteBase}
  market {
    id
    unloading_locations {
      ${Location}
    }
  }
  locations {
    ${RouteLocation}
  }
  mile_calculation_result {
    status
    message
  }
`

export const RouteWithLocationHistory = `
  ${RouteBase}
  market {
    id
    unloading_locations {
      ${Location}
    }
  }
  locations {
    id
    name
    address
    city
    state
    postal
    latitude
    longitude
    status
    locationId
    stop_sequence
    bins {
      id
      box_code
    }
    history {
      id
      title
      children {
        id
        label
        selections {
          id
          label
        }
      }
    }
    competitors {
      ${CompetitorModel}
    }
  }
`

export const getRoutes = gql`
  query($ids: [String]!) {
    routesById(ids: $ids) {
      ${Route}
    }
  }
`

export const userRoutes = gql`
  query($userId: String!) {
    routes(userId: $userId) {
      id
      name
      color
      locations {
        ${Location}
      }
      nextRun {
        run_date
      }
    }
  }
`

export const optimizeRoute = gql`
  mutation optimizeRoute($routeId: String!) {
    optimizeRoute(routeId: $routeId) { ${Route} }
  }
`

export const getSingleRoute = gql`
  query($routeId: String!) {
    route(id: $routeId) { ${Route} }
  }
`

export const getSingleRouteWithLocationHistory = gql`
  query($routeId: String!) {
    route(id: $routeId) { ${RouteWithLocationHistory} }
  }
`

export const addLocation = gql`
  mutation addRouteLocation($routeId: String!, $location: LocationInput!) {
    addRouteLocation(routeId: $routeId, location: $location) { ${Route} }
  }
`

export const updateLocation = gql`
  mutation updateLocation($id: String!, $location: LocationInput!) {
    updateLocation(id: $id, location: $location) { ${Location} }
  }
`

export const saveLocationOrder = gql`
  mutation saveLocationOrder($routeId: String!, $locations: [LocationInput]!) {
    saveLocationOrder(routeId: $routeId, locations: $locations) { ${Route} }
  }
`

export const createRoute = gql`
  mutation createRoute($route: RouteInput!) {
    createRoute(route: $route) { ${Route} }
  }
`

export const editRoute = gql`
  mutation editRoute($routeId: String!, $route: RouteEditInput!) {
    editRoute(id: $routeId, route: $route) {${Route}}
  }
`

export const copyRoute = gql`
  mutation copyRoute($routeId: String!) {
    copyRoute(id: $routeId){
      ${Route}
    }
  }
`

export const deleteRoute = gql`
  mutation deleteRoute($routeId: String!) {
    deleteRoute(id: $routeId){
      ${RouteBase}
    }
  }
`

export const routeRuns = gql`
  query data($routeId: String!, $start: String!, $end: String!) {
    route(id: $routeId) {
      runs(start: $start, end: $end) {
        run_date
        route
        driver
        helper
      }
    }
  }
`

export const saveBins = gql`
  mutation updateRouteLocationBins($routeId: String!, $locationId: String!, $bins: [BinInput]!) {
    updateRouteLocationBins(routeId: $routeId, locationId: $locationId, bins: $bins) { 
      ${Route}
    }
  }
`

export const deleteLocation = gql`
  mutation routeRemoveLocation($routeId: String!, $locationId: String!, $date: String) {
    routeRemoveLocation(routeId: $routeId, locationId: $locationId, date: $date) {
      ${Route}
    }
  }
`

export const getRouteRuns = gql`
  query data($routeIds: [String]!, $start: String!, $end: String!) {
    routesById(ids: $routeIds) {
      id
      name
      color
      market {
        id
      }
      optimized_miles
      runDetails(start: $start, end: $end) {
        id
        run_date
        driver {
          id
          first_name
          last_name
        }
        helper {
          id
          first_name
          last_name
        }
        end_time
        total_misc
        total_cloth
        total_trash
        start_miles
        end_miles
        start_time
        optimized_miles
        is_custom
        driver_accuracy
      }
    }
  }
`

export const deleteRun = gql`
  mutation deleteRun($id: String!) {
    deleteRun(id: $id) {
      id
      deleted
    }
  }
`

export const getEOMReport = gql`
  query($routeId: String!, $month: Int!, $year: Int!, $regenerate: Boolean) {
    route(id: $routeId) {
      id
      eomReport(month: $month, year: $year, regenerate: $regenerate) {
        url
        generated_at
      }
    }
  }
`
export const routeMoveLocation = gql`
  mutation routeMoveLocation($routeId: String!, $locationId: String!, $toRouteId: String!) {
    routeMoveLocation(routeId: $routeId, locationId: $locationId, toRouteId: $toRouteId) {
      ${Route}
    }
  }
`
export const routeCopyLocation = gql`
  mutation routeCopyLocation($routeId: String!, $locationId: String!, $toRouteId: String!) {
    routeCopyLocation(routeId: $routeId, locationId: $locationId, toRouteId: $toRouteId) {
      ${Route}
    }
  }
`

export const routeAssignLocation = gql`
  mutation routeAssignLocation($routeId: String!, $marketId: String!, $locationId: String!, $date: String) {
    routeAssignLocation(routeId: $routeId, marketId: $marketId, locationId: $locationId, date: $date) {
      ${Route}
    }
  }
`
