import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import WithQuery from './query'

import * as QL from '../api/Query/runs'
import { Run } from '../selectors/runs'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as actions from '../actions/runs'

export const withRuns = WrappedComponent =>
  connect(
    state => ({
      runs: state.runs,
    }),
    dispatch => bindActionCreators(actions, dispatch)
  )(WrappedComponent)

export const WithRunReport = WithQuery({
  selector: data => Run(data, 'runById'),
  QL,
  queryName: 'getRun',
  fetchPolicy: 'network-only',
  variablesPicker: props => {
    const { runId, run, match } = props
    const id = _get(run, 'id', _get(match, 'params.runId', runId))

    if (_isEmpty(id)) return null

    return { id }
  },
})

export const WithScheduleRunFormValues = WrappedComponent => {
  const selector = formValueSelector('ScheduleRunForm')

  return connect(state => {
    const customizeMode = selector(state, 'customize')

    return {
      customizeMode,
    }
  })(WrappedComponent)
}
