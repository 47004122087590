import './styles.scss'

import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Dropdown, Card, Icon, Button } from 'semantic-ui-react'

const cardStyle = color => ({
  borderTopColor: color,
})

const ActionsDropdown = withRouter(({ match: { url }, data }) => (
  <Dropdown
    icon={null}
    className="PoliceReportCard_actions"
    trigger={
      <Button icon color="orange">
        <Icon name="align justify" />
      </Button>
    }
  >
    <Dropdown.Menu>
      <Dropdown.Item>
        <Link to={`${url}/${data.id}?view=true`}>View Police Report</Link>
      </Dropdown.Item>

      {data.status === 'new' && (
        <Dropdown.Item>
          <Link to={`${url}/${data.id}?edit=true`}>Edit Police Report</Link>
        </Dropdown.Item>
      )}

      {data.status === 'new' && (
        <Dropdown.Item>
          <Link to={`${url}/${data.id}?status=resolved`}>
            Resolve Police Report
          </Link>
        </Dropdown.Item>
      )}
    </Dropdown.Menu>
  </Dropdown>
))

const CardInfo = ({ content, icon }) => (
  <Card.Description>
    <Icon name={icon} />
    {content}
  </Card.Description>
)

const PoliceReportCard = ({ data }) => {
  const isAssignedToMe = data.isAssignedToMe ? '(you)' : ''
  const classNames = data.isAssignedToMe
    ? 'PoliceReportCard PoliceReportCard_assignedToMe'
    : 'PoliceReportCard'

  return (
    <Card style={cardStyle(data.color)} className={classNames}>
      <Card.Content>
        <Card.Header>
          {data.bin.serial_number}
          <ActionsDropdown data={data} />
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <CardInfo content={`Date: ${data.date_of_incident.substring(0, 10)}`} />
        <CardInfo content={`Bin: ${data.bin.serial_number}`} />
        <CardInfo
          content={`User: ${data.user.first_name} ${data.user.last_name} ${
            isAssignedToMe
          }`}
        />
      </Card.Content>
    </Card>
  )
}

export default PoliceReportCard
