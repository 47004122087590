import FormField from '../../components/FormField'
import React from 'react'
import { Button, Grid, Header, Message, Modal } from 'semantic-ui-react'
import DateField from '../../components/DatePickerPureHtml'

export default class GetReAddedDateModal extends React.Component {
  state = {
    selectedDate: null,
    openModal: true,
  }

  onConfirm = () => {
    const { onReAddedDateConfirmed } = this.props
    const { selectedDate } = this.state

    if (!selectedDate) {
      this.setState({
        error: 'Please pick the date!',
        showLoading: false,
      })

      return false
    }

    this.setState({
      error: '',
      showLoading: true,
    })

    onReAddedDateConfirmed(selectedDate)
    this.close()
  }

  close() {
    const { onCloseModal } = this.props
    onCloseModal()

    this.setState({
      openModal: false,
    })
  }

  onChange = data => {
    if (data) {
      this.setState({
        selectedDate: data.value,
      })
    }
  }

  render() {
    const { selectedDate, error, openModal } = this.state

    return (
      <Modal open={openModal} className="form" dimmer={'blurring'}>
        <Header>Set Re-Added Date</Header>
        <Modal.Content>
          <Modal.Description>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <p>Please pick the date you want to set as re-added date</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <DateField
                    name="date"
                    label="Re-Added Date: "
                    component={FormField}
                    onBlur={e => e.preventDefault()}
                    required
                    type="date"
                    onChange={(e, value) => this.onChange(value)}
                    value={selectedDate}
                    error={!!error}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.close()}>
            Nevermind
          </Button>
          <Button content="Confirm" positive onClick={this.onConfirm} />
        </Modal.Actions>
      </Modal>
    )
  }
}
