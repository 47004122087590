import React, { Fragment } from 'react'
import {
  Button,
  Modal,
  Icon,
  Card,
  Segment,
  Header,
  Grid,
  Dimmer,
  Loader,
  Divider,
} from 'semantic-ui-react'

import {
  WithCompetitorActions,
  WithCompetitors,
} from '../../connectors/competitors'
import { WithReportTags } from '../../connectors/reportTags'
import { CurrentCompetitors } from './currentCompetitors'
import { CompetitorHistory } from './competitorsHistory'
import { AddCompetitorModal } from './addCompetitorModal'
import './style.scss'

@WithCompetitorActions
@WithCompetitors
@WithReportTags
class LocationCompetitors extends React.PureComponent {
  state = {
    open: false,
    location_id: null,
    showAddNewCompetitorModal: false,
  }

  onOpen = () => {
    this.setState({ open: true })
  }

  close = () => this.setState({ open: false })

  componentDidUpdate() {
    const { locationId, getLocationCompetitors } = this.props
    const { location_id } = this.state

    if (locationId && locationId !== location_id) {
      this.setState({
        location_id: locationId,
      })

      getLocationCompetitors(locationId)
    }
  }

  showAddNewCompetitorModal = () => {
    this.setState({
      showAddNewCompetitorModal: true,
    })
  }

  hideAddNewCompetitorModal = () => {
    this.setState({
      showAddNewCompetitorModal: false,
    })
  }

  render() {
    const {
      inProgress,
      competitorHistory,
      currentCompetitors,
      reportTags,
      markets,
    } = this.props
    const { open, location_id, showAddNewCompetitorModal } = this.state

    return (
      <Fragment>
        <Modal
          trigger={
            <Card>
              <Segment vertical textAlign="left">
                {inProgress && (
                  <Dimmer active inverted>
                    <Loader inverted />
                  </Dimmer>
                )}
                <Card.Content>
                  <Header as="h4" icon textAlign="center">
                    <Icon name="truck" />
                    Competitors ({currentCompetitors.length})
                  </Header>

                  {
                    <CurrentCompetitors
                      locationId={location_id}
                      currentCompetitors={currentCompetitors}
                      simpleList={true}
                    />
                  }
                </Card.Content>
              </Segment>
            </Card>
          }
          dimmer="blurring"
          closeOnDimmerClick={false}
          size="large"
          open={open}
          onOpen={this.onOpen}
          onClose={this.close}
          scrolling={true}
        >
          <Modal.Header>Competitors</Modal.Header>
          <Modal.Content scrolling>
            <Grid>
              <Grid.Row only="tablet computer">
                <Grid.Column width={9}>
                  <Header as="h3">Current competitors</Header>
                  <Divider />
                  <CurrentCompetitors
                    locationId={location_id}
                    currentCompetitors={currentCompetitors}
                    simpleList={false}
                    showRemoveButton={true}
                  />
                </Grid.Column>
                <Grid.Column width={7}>
                  <Header as="h3">Competitor history</Header>
                  <Divider />
                  <CompetitorHistory competitorHistory={competitorHistory} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={1} only="mobile">
                <Grid.Column>
                  <Header as="h3">Current competitors</Header>
                  <Divider />
                  <CurrentCompetitors
                    locationId={location_id}
                    currentCompetitors={currentCompetitors}
                    simpleList={false}
                  />
                  <div style={{ height: '30px' }} />
                </Grid.Column>
                <Grid.Column>
                  <Header as="h3">Competitor history</Header>
                  <Divider />
                  <CompetitorHistory competitorHistory={competitorHistory} />
                  <div style={{ height: '30px' }} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="left" width={10}>
                  <Button
                    color="blue"
                    icon="plus"
                    labelPosition="left"
                    content="Add Competitor"
                    onClick={this.showAddNewCompetitorModal}
                  />
                </Grid.Column>
                <Grid.Column textAlign="right" width={6}>
                  <Button positive content="Close" onClick={this.close} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>

        {showAddNewCompetitorModal && (
          <AddCompetitorModal
            locationId={location_id}
            onClose={this.hideAddNewCompetitorModal}
            reportTags={reportTags}
            markets={markets}
          />
        )}
      </Fragment>
    )
  }
}

export default LocationCompetitors
