import React from 'react'
import { Container, Menu, Dropdown } from 'semantic-ui-react'

import MainHeader from '../../components/MainHeader'
import NavLink from '../../components/NavLink'
import ContentHeader from '../../components/ContentHeader'
import DateDropdown from '../../components/DateDropdown'
import DatePicker from '../../components/DatePicker'
import ReportCard from '../../components/ReportCard'
import CircleMetric from '../../components/CircleMetric'
import DataTable from '../../components/DataTable'
import RouteCard from '../../components/RouteCard'
import LocationsInput from '../../components/LocationsInput'
import RouteCalendar from '../../components/RouteCalendar'

const StyleGuideHeader = ({ children }) => (
  <h2 style={{ marginTop: '2rem' }}>{children}</h2>
)

const StyleGuideSeparator = ({ children }) => (
  <div style={{ margin: '2rem 0' }}>
    <hr
      style={{
        margin: 0,
        height: '1px',
        border: 0,
        background: '#999',
      }}
    />
  </div>
)

const showRouteCard = false

const StyleGuide = () => (
  <Container>
    <h1>Navigation Components</h1>
    {/* Main Header */}
    <div>
      <StyleGuideHeader>MainHeader</StyleGuideHeader>
      <MainHeader
        user={{
          id: '12345',
          name: 'Jane Smith',
          role: 'Administrator',
        }}
      />
      <StyleGuideSeparator />
      <MainHeader
        user={{
          id: '12345',
          name: 'John Smith',
          role: 'HR',
        }}
        renderMenu={() => [
          { value: 'one', text: 'Option One' },
          { value: 'two', text: 'Twice' },
          { value: 'three', text: 'Three' },
        ]}
        onChange={(e, dropdown) => {
          console.log('YOU SELECTED', dropdown.value)
        }}
      />
    </div>

    {/* Left Nav */}
    <StyleGuideHeader>LeftNavLink</StyleGuideHeader>
    <NavLink tooltip="Tooltip #1" icon="world" />
    <StyleGuideSeparator />
    <NavLink tooltip="Tooltip #2" icon="user" active={true} />

    {/* Component Header */}
    <StyleGuideHeader>ComponentHeader</StyleGuideHeader>
    <ContentHeader title="Where are we at" showBackButton />
    <ContentHeader title="Reports" showBackButton>
      <Menu.Menu>
        <Dropdown item text="Markets">
          <Dropdown.Menu>
            <Dropdown.Item>All Markets</Dropdown.Item>
            <Dropdown.Item>Houston</Dropdown.Item>
            <Dropdown.Item>Austin</Dropdown.Item>
            <Dropdown.Item>Indianapolis</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
      <Menu.Item>View Schedule</Menu.Item>
    </ContentHeader>

    <StyleGuideHeader>Tool Components</StyleGuideHeader>

    {/* Reports */}
    <DateDropdown
      onChange={({ startDate, endDate }) => {
        console.log('YOU SELECTED', startDate.format(), endDate.format())
      }}
    />
    <StyleGuideSeparator />

    <DatePicker
      onChange={({ startDate, endDate }) => {
        console.log('YOU SELECTED', startDate.format(), endDate.format())
      }}
    />
    <StyleGuideSeparator />
    <ReportCard
      title="Total Bins"
      total="2800"
      label="Above 200 bin/market Avg"
      color="green"
    />
    <StyleGuideSeparator />

    <CircleMetric
      label="Misc"
      total="234,248 Lbs"
      color="green"
      flag={{
        name: 'flag',
        color: 'red',
      }}
    />
    <StyleGuideSeparator />

    <DataTable
      columns={[
        {
          title: 'Market',
          name: 'market',
          icon: { name: 'angle down' },
        },
        { title: 'Projections', name: 'projections' },
        { title: 'Total Weight', name: 'total_weight' },
        {
          title: 'Total Selleable Weight',
          name: 'total_selleable',
        },
        { title: 'Total Trash', name: 'total_trash' },
      ]}
      data={[
        {
          market: 'Atlanta',
          projections: '18.265Lbs',
          total_weight: '13.850Lbs',
          total_selleable: '12.937Lbs',
          total_trash: [
            {
              type: 'icon',
              name: 'circle',
              color: 'brown',
            },
            '7%',
            { type: 'icon', name: 'flag', color: 'red' },
          ],
        },
        {
          market: 'Dallas',
          projections: '7.138Lbs',
          total_weight: '6.937Lbs',
          total_selleable: '6.387Lbs',
          total_trash: '15%',
        },
        {
          market: 'Denver',
          projections: '30.86Lbs',
          total_weight: '27.017Lbs',
          total_selleable: '25.386Lbs',
          total_trash: ['5%', { type: 'icon', name: 'flag', color: 'red' }],
        },
      ]}
      footer={{
        data: {
          market: 'Totals',
          projections: 6327,
          total_weight: 92228,
          total_selleable: 900,
          total_trash: 229,
        },
        columns: [
          {
            title: 'Market',
            name: 'market',
            icon: { name: 'angle down' },
          },
          { title: 'Projections', name: 'projections' },
          { title: 'Total Weight', name: 'total_weight' },
          {
            title: 'Total Selleable Weight',
            name: 'total_selleable',
          },
          { title: 'Total Trash', name: 'total_trash' },
        ],
      }}
    />
    <StyleGuideSeparator />

    {showRouteCard && (
      <React.Segment>
        <RouteCard
          data={{
            name: 'Red',
            color: 'red',
            runDate: '10-11-2017',
            totalStops: 6,
            totalBins: 10,
          }}
          onBottomClick={data => console.log('Scheduled Run', data)}
        />
        <StyleGuideSeparator />
      </React.Segment>
    )}

    <div style={{ maxWidth: '300px' }}>
      <RouteCalendar
        routeData={[
          {
            id: '-L0PW2LI4bVvMBAXW-dO',
            market: '-L0PW1nBgqkoTM2H_TVe',
            color: '#f79646',
          },
        ]}
      />
    </div>
    <StyleGuideSeparator />

    <LocationsInput
      label="LOCATIONS INPUT #1"
      onChange={locations => {
        console.log('LOCATIONS INPUT #1', locations)
      }}
    />
    <LocationsInput
      label="LOCATIONS INPUT #2 - With Value"
      onChange={locations => {
        console.log('LOCATIONS INPUT #2', locations)
      }}
      value={[
        {
          name: 'Loc 1',
          address: '123 Fake St',
          city: 'Fakesville',
          state: 'TX',
          postal: '12345',
          latitude: 43.2139,
          longitude: 73.4992,
        },
        {
          name: 'Loc 2',
          address: '123 Fake St',
          city: 'Fakesville',
          state: 'TX',
          postal: '12345',
          latitude: 43.2139,
          longitude: 73.4992,
        },
        {
          name: 'Loc 3',
          address: '123 Fake St',
          city: 'Fakesville',
          state: 'TX',
          postal: '12345',
          latitude: 43.2139,
          longitude: 73.4992,
        },
      ]}
    />

    <LocationsInput
      label="LOCATIONS INPUT #3 - Required"
      onChange={locations => {
        console.log('LOCATIONS INPUT #1', locations)
      }}
      required
    />

    <LocationsInput
      label="LOCATIONS INPUT #4 - Disabled"
      onChange={locations => {
        console.log('LOCATIONS INPUT #1', locations)
      }}
      disabled
    />
  </Container>
)

export default StyleGuide
