import bg from '../../assets/bg-01.jpg'

export default {
  container: {
    height: '100%',
    backgroundImage: `url(${bg})`,
  },

  logo: {
    margin: '0 auto 3rem',
  },
}
