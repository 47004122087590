export const locationNPOOptions = [
	{
		key: 0,
		text: "Phoenix Children's Hospital - Childrens Miracle Network",
		value: "Phoenix Children's Hospital - Childrens Miracle Network",
		location: 'Arizona',
		market: 'Phoenix',
	},
	{
		key: 1,
		text: 'Tucson Medical Center - Childrens Miracle Network',
		value: 'Tucson Medical Center - Childrens Miracle Network',
		location: 'Arizona',
		market: 'Tucson',
	},
	{
		key: 2,
		text: 'Colorado Adaptive Sports',
		value: 'Colorado Adaptive Sports',
		location: 'Colorado',
		market: 'Denver',
	},
	{
		key: 3,
		text: "Children's Medical Center - Childrens Miracle Network",
		value: "Children's Medical Center - Childrens Miracle Network",
		location: 'Dallas',
		market: 'Dallas',
	},
	{
		key: 4,
		text: "Cooks Children's Hospital - Childrens Miracle Network",
		value: "Cooks Children's Hospital - Childrens Miracle Network",
		location: 'Dallas',
		market: 'Dallas',
	},
	{
		key: 5,
		text: 'MADD of North Texas',
		value: 'MADD of North Texas',
		location: 'Dallas',
		market: 'Dallas',
	},
	{
		key: 6,
		text: 'Dells Children Hospital - Childrens Miracle Network',
		value: 'Dells Children Hospital - Childrens Miracle Network',
		location: 'Austin',
		market: 'CTX - Austin',
	},
	{
		key: 7,
		text: 'Houston Area Community Service (HACS)',
		value: 'Houston Area Community Service (HACS)',
		location: 'Houston',
		market: 'Houston',
	},
	{
		key: 8,
		text: 'ARK of Greater Beaumont',
		value: 'ARK of Greater Beaumont',
		location: 'Houston',
		market: 'Houston',
	},
	{
		key: 9,
		text: 'MADD of Oklahoma',
		value: 'MADD of Oklahoma',
		location: 'Oklahoma',
		market: 'Oklahoma City',
	},
	{
		key: 10,
		text: 'Big Brothers Big Sisters of Central Arkansas (Central Arkansas)',
		value: 'Big Brothers Big Sisters of Central Arkansas (Central Arkansas)',
		location: 'Arkansas',
		market: 'Central Arkansas',
	},
	{
		key: 11,
		text: 'Michigan Humane',
		value: 'Michigan Humane',
		location: 'Michigan',
		market: 'Michigan',
	},
	{
		key: 12,
		text: 'MADD of Middle Tennessee',
		value: 'MADD of Middle Tennessee',
		location: 'Tennessee',
		market: 'Nashville',
	},
	{
		key: 13,
		text: "Libby's Legacy Breast Cancer Foundation",
		value: "Libby's Legacy Breast Cancer Foundation",
		location: 'Florida',
		market: 'Orlando',
	},
	{
		key: 14,
		text: 'Tarrant Area Food Bank',
		value: 'Tarrant Area Food Bank',
		location: 'Dallas',
		market: 'Dallas',
	},
	{
		key: 15,
		text: 'Phoenix Children’s Hospital',
		value: 'Phoenix Children’s Hospital',
		location: 'Phoenix',
		market: 'Phoenix',
	},
	{
		key: 16,
		text: 'Cornerstone Church',
		value: 'Cornerstone Church',
		location: 'Phoenix',
		market: 'Phoenix',
	},
	{
		key: 17,
		text: 'Miracles Happen',
		value: 'Miracles Happen',
		location: 'Phoenix',
		market: 'Phoenix',
	},
	{
		key: 18,
		text: 'St. Peters Episcopal Church',
		value: 'St. Peters Episcopal Church',
		location: 'Phoenix',
		market: 'Phoenix',
	},
	{
		key: 19,
		text: 'Arkansas Coalition Against Sexual Assault (Northwest Arkansas)',
		value: 'Arkansas Coalition Against Sexual Assault (Northwest Arkansas)',
		location: 'Arkansas',
		market: 'NW Arkansas',
	},
	{
		key: 20,
		text: "Grant's Gift Foundation",
		value: "Grant's Gift Foundation",
		location: 'SE Louisiana ',
		market: 'SE Louisiana ',
	},
	{
		key: 21,
		text: 'Tarrant County Food Bank',
		value: 'Tarrant County Food Bank',
		location: 'Dallas',
		market: 'Dallas',
	},
	{
		key: 22,
		text: 'Madd North Texas',
		value: 'Madd North Texas',
		location: 'Dallas',
		market: 'Dallas',
	},
	{
		key: 23,
		text: 'MADD (Oklahoma City)',
		value: 'MADD (Oklahoma City)',
		location: 'Oklahoma City',
		market: 'Oklahoma City',
	},
	{
		key: 24,
		text: 'MADD (Nashville)',
		value: 'MADD (Nashville)',
		location: 'Nashville',
		market: 'Nashville',
	},
	{
		key: 26,
		text: "Children's Miracle Network",
		value: "Children's Miracle Network",
		location: 'Oklahoma City',
		market: 'Oklahoma City',
	},
	{
		key: 27,
		text: 'Oklahoma Humane Society',
		value: 'Oklahoma Humane Society',
		location: 'Oklahoma City',
		market: 'Oklahoma City',
	},
	{
		key: 28,
		text: 'Michigan Humane Society',
		value: 'Michigan Humane Society',
		location: 'Michigan',
		market: 'Michigan',
	},
	{
		key: 29,
		text: 'Big Brothers Big Sisters of Central Arkansas (Northwest Arkansas)',
		value: 'Big Brothers Big Sisters of Central Arkansas (Northwest Arkansas)',
		location: 'Arkansas',
		market: 'NW Arkansas',
	},
	{
		key: 30,
		text: 'Arkansas Coalition Against Sexual Assault (Central Arkansas)',
		value: 'Arkansas Coalition Against Sexual Assault (Central Arkansas)',
		location: 'Arkansas',
		market: 'Central Arkansas',
	},
	{
		key: 31,
		text: 'The Arc of Greater Beaumont',
		value: 'The Arc of Greater Beaumont',
		location: 'Houston',
		market: 'Houston',
	},
	{
		key: 32,
		text: 'Donors Choose ECTX',
		value: 'Donors Choose ECTX',
		location: 'East-Central Texas',
		market: 'East-Central Texas',
	},
	{
		key: 33,
		text: 'Donors Choose CTX',
		value: 'Donors Choose CTX',
		location: 'Austin',
		market: 'CTX - Austin',
	},
]

export const statusOptions = [
	{ value: 'pending', text: 'Pending' },
	{ value: 'verbal', text: 'Verbal' },
	{ value: 'signed', text: 'Signed' },
	{ value: 'leased', text: 'Leased' },
]
