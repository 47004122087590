import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import React, { Fragment } from 'react'

import { SimpleLoading } from '../../components/Loading'
import { WithLocationRoutes } from '../../connectors/locations'
import { Button, Header, Icon, List, Message, Modal } from 'semantic-ui-react'
import GetReAddedDateModal from '../ExistingLocation/getReAddedDateModal'

const routeList = routes =>
  _map(routes, ({ id, name, color }) => ({
    key: id,
    value: id,
    content: name,
    icon: { name: 'circle', style: { color } },
  }))

@WithLocationRoutes
export default class ConfirmationModal extends React.PureComponent {
  state = {
    noNeedConfirm: false,
    showReAddedDateModal: false,
    reAddedDate: null,
    selectedLocation: null,
    reAddedDateConfirmed: false,
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { loading, routes } = this.props
    if (prevProps.loading === true && loading === false)
      return { noneedConfirm: _isEmpty(routes) }

    return null
  }

  componentDidUpdate(props, state, snapshot) {
    const { noNeedConfirm } = this.state
    const { location, doAssignment } = props

    if (
      _get(snapshot, 'noneedConfirm') &&
      !noNeedConfirm &&
      !location.date_removed_from_route
    ) {
      this.setState({
        noNeedConfirm: true,
      })

      doAssignment({
        reAddedDate: null,
      })
    }
  }

  openReAddedDateModal() {
    this.setState({
      showReAddedDateModal: true,
    })
  }

  onCloseReAddedDateModal = () => {
    this.closeReAddedDateModal()
  }

  closeReAddedDateModal = () => {
    this.setState({
      showReAddedDateModal: false,
    })
  }

  onReAddedDateConfirmed = value => {
    const { doAssignment } = this.props

    this.setState({
      reAddedDateConfirmed: true,
    })

    doAssignment({
      reAddedDate: value,
    })
  }

  render() {
    const {
      loading,
      routes,
      aimRoute,
      doSingleAssignment,
      doAssignment,
      deny,
      editing,
      open,
      location,
    } = this.props
    const { reAddedDateConfirmed } = this.state

    const foundLocation = !_isEmpty(routes)
    const routeName = aimRoute.name
    let showConfirmationModal = true

    if (foundLocation || loading || !location.date_removed_from_route)
      showConfirmationModal = true
    else if (reAddedDateConfirmed) showConfirmationModal = true
    else showConfirmationModal = false

    return (
      <Fragment>
        {showConfirmationModal && (
          <Modal open={open} onCancel={deny}>
            <Header icon="location" content="Confirm Copy OR Move Location" />
            <Modal.Content>
              {foundLocation && (
                <Message warning icon>
                  <Icon name="warning sign" />
                  <Message.Content>
                    <Message.Header>
                      This location is already assigned to the following routes:
                    </Message.Header>
                    <List items={routeList(routes)} />

                    <Message.Header
                      content={`Selecting Move will remove the location from all routes listed above and assign it to the ${
                        routeName
                      } route.`}
                    />
                    <Message.Header
                      content={`Selecting Copy will not remove from any other routes and only assign it to the ${
                        routeName
                      } route.`}
                    />
                  </Message.Content>
                </Message>
              )}
            </Modal.Content>
            {!foundLocation && (
              <Modal.Actions>
                <Button negative onClick={deny}>
                  Nevermind
                </Button>
                <Button positive onClick={doAssignment}>
                  {!editing ? 'Assign' : 'Update'}
                </Button>
              </Modal.Actions>
            )}
            {foundLocation && (
              <Modal.Actions>
                <Button positive onClick={doSingleAssignment}>
                  Move
                </Button>
                <Button positive onClick={doAssignment}>
                  Copy
                </Button>
                <Button negative onClick={deny}>
                  Cancel
                </Button>
              </Modal.Actions>
            )}
            <SimpleLoading loading={loading} />
          </Modal>
        )}
        {!showConfirmationModal && (
          <GetReAddedDateModal
            onCloseModal={this.onCloseReAddedDateModal}
            onReAddedDateConfirmed={this.onReAddedDateConfirmed}
          />
        )}
      </Fragment>
    )
  }
}
