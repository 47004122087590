import { createSelector } from 'reselect'
import _find from 'lodash/find'

const _policeReports = state =>
  state.policeReports.policeReports
    ? state.policeReports.policeReports.map(PoliceReport)
    : []
const _search = state => state.policeReports.search
const _currentMarket = state => state.markets.current
const _allPoliceReports = state => state.policeReports.policeReports

export const searchPoliceReports = createSelector(
  _policeReports,
  _search,
  (policeReports, search) => {
    if (!search || search === '') {
      return policeReports
    }
    return policeReports.filter(policeReport =>
      policeReport.id.toLowerCase().includes(search.toLowerCase())
    )
  }
)

export const filterByMarkets = createSelector(
  _allPoliceReports,
  _currentMarket,
  (policeReports, currentMarket) => {
    if (!currentMarket) return policeReports

    return policeReports.filter(policeReport => {
      const market = _find(policeReport.markets, { id: currentMarket })
      if (market) {
        return true
      }
      return false
    })
  }
)

export const PoliceReport = createSelector(
  state => state,
  policeReport => ({
    ...policeReport,
    value: policeReport.id,
  })
)
