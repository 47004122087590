import React from 'react'

import { Menu } from 'semantic-ui-react'

import ContentHeader from '../../../components/ContentHeader'

class Header extends React.PureComponent {
  render() {
    const { children } = this.props

    return (
      <ContentHeader title="Maintenance Requests" showBackButton>
        {children && <Menu.Item>{children}</Menu.Item>}
      </ContentHeader>
    )
  }
}

export default Header
