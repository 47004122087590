import React from 'react'
import { Menu } from 'semantic-ui-react'

import MenuDropdown from '../../../components/MenuDropdown'
import WithQuerySearch from '../../../connectors/querySearch'

import ContentHeader from '../../../components/ContentHeader'
import MarketsDropdown from '../../../components/MenuDropdown/Markets'
import { withBins } from '../../../connectors/bins'
import { withRoutesActions } from '../../../connectors/routes'

@withRoutesActions
@WithQuerySearch
@withBins
class Header extends React.PureComponent {
  state = {
    routes: [],
    isRoutesLoading: false,
  }

  handleRouteChange(route) {
    const { filterByRoute } = this.props
    filterByRoute(route)
  }

  handleMarketChange(marketId) {
    const {
      updateSearch,
      getRoutesByMarketSimple,
      filterByRoute,
      filterByMarket,
    } = this.props

    if (marketId) {
      this.setState({
        routes: [],
        isRoutesLoading: true,
      })

      updateSearch({ market: marketId })
      filterByMarket(marketId)

      getRoutesByMarketSimple(marketId, { cached: false }).then(routes => {
        if (routes && !routes.some(route => route.id === null))
          routes.unshift({
            id: null,
            name: 'All Routes',
            code: 'null',
          })

        this.setState({
          routes: routes,
          isRoutesLoading: false,
        })
      })
      filterByRoute(null)
    } else {
      filterByMarket(null)
      updateSearch({})
    }
  }

  componentDidMount() {
    const { search } = this.props

    if (search.market) this.handleMarketChange(search.market)
  }

  render() {
    const { children, search, filteredBins, binsFilterRouteOption } = this.props
    const { routes, isRoutesLoading } = this.state

    return (
      <ContentHeader
        title="Bin Management"
        subtitle={`Bin Count: ${filteredBins.length}`}
        showBackButton
      >
        <MarketsDropdown
          menu
          selected={search.market}
          onSelect={({ id }) => {
            this.handleMarketChange(id)
          }}
        />
        {search.market && (
          <MenuDropdown
            label="Routes"
            options={routes || []}
            loading={isRoutesLoading}
            active={binsFilterRouteOption}
            activeLabel="Route"
            showAll={false}
            onSelect={route => {
              if (!route) {
                this.handleRouteChange(null)
              } else {
                this.handleRouteChange(route)
              }
            }}
          />
        )}
        {children && <Menu.Item>{children}</Menu.Item>}
      </ContentHeader>
    )
  }
}

export default Header
