import apolloClient from '../api/apolloClient'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _has from 'lodash/has'

// Queries
import {
  addLocation as addLocationMutation,
  copyRoute,
  createRoute as createRouteMutation,
  deleteLocation as deleteLocationMutation,
  deleteRoute,
  deleteRun as deleteRunMutation,
  updateLocation as editLocationMutation,
  editRoute as editRouteMutation,
  getEOMReport,
  getRouteRuns as getRouteRunsQuery,
  getSingleRoute,
  getSingleRouteWithLocationHistory,
  optimizeRoute as optimizeRouteMutation,
  routeAssignLocation as routeAssignLocationMutation,
  routeCopyLocation as routeCopyLocationMutation,
  routeMoveLocation as routeMoveLocationMutation,
  saveBins as saveBinsMutation,
  saveLocationOrder as saveLocationOrderMutation,
  userRoutes,
} from '../api/Query/routes'
import {
  routeWithReports,
  routesByMarket,
  routesByMarket2,
  routesWithReports,
} from '../api/Query/routesByMarket'
import { getRun as getRunQuery } from '../api/Query/runs'
import moment from 'moment'

import { trigger, triggerError } from '../actions'

export const _ROUTES_ERROR = 'ROUTES_ERROR'
export const _ROUTES_LOADING = 'ROUTES_LOADING'
export const _ROUTES_LOADED = 'ROUTES_LOADED'
export const _ROUTES_ADDED = 'ROUTES_ADDED'
export const _ROUTES_REMOVE = 'ROUTES_REMOVE'
export const _ROUTES_COPY = 'ROUTES_COPY'
export const _ROUTES_SET_CURRENT = 'ROUTES_SET_CURRENT'
export const _ROUTES_LOAD_ROUTE = 'ROUTES_LOAD_ROUTE'
export const _ROUTES_OPTIMIZE = 'ROUTES_OPTIMIZE'
export const _ROUTES_LOAD_RUNS = 'ROUTES_LOAD_RUNS'
export const _ROUTE_LOAD_RUN = '_ROUTES_LOAD_RUN'
export const _ROUTES_LOAD_ROUTE_DATA = '_ROUTES_LOAD_ROUTE_DATA'
export const _ROUTE_EDIT_LOCATION = '_ROUTE_EDIT_LOCATION'
export const _ROUTES_RUN_DELETED = '_ROUTES_RUN_DELETED'
export const _ROUTES_SET_SHOW_INACTIVE_ROUTES =
  '_ROUTES_SET_SHOW_INACTIVE_ROUTES'

function getResults(results) {
  return results.data && (results.data.routes || results.data.routesByMarket)
}

export const clearError = () => {
  return dispatch => {
    dispatch(trigger(_ROUTES_ERROR)(null))
  }
}

export const getRun = id => dispatch => {
  dispatch(trigger(_ROUTES_LOADING)())
  return apolloClient
    .query({
      query: getRunQuery,
      variables: { id },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.runById)
    .then(trigger(_ROUTE_LOAD_RUN))
    .catch(trigger(_ROUTES_ERROR))
    .then(dispatch)
    .then(() => {
      dispatch(trigger(_ROUTES_LOADED)())
    })
}

export const getRouteRuns = variables => dispatch => {
  return apolloClient
    .query({
      query: getRouteRunsQuery,
      variables,
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.routesById)
    .then(trigger(_ROUTES_LOAD_RUNS))
    .catch(trigger(_ROUTES_ERROR))
    .then(dispatch)
}

export const saveBins = variables => dispatch => {
  return apolloClient
    .mutate({
      mutation: saveBinsMutation,
      variables,
    })
    .then(res => res.data.updateRouteLocationBins)
    .then(trigger(_ROUTES_LOAD_ROUTE))
    .catch(trigger(_ROUTES_ERROR))
    .then(dispatch)
}

export const addLocation = (routeId, location) => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({
        mutation: addLocationMutation,
        variables: { routeId, location },
      })
      .then(res => res.data.addRouteLocation)
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const editLocation = location => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({
        mutation: editLocationMutation,
        variables: { id: location.id, location },
      })
      .then(res => res.data.editLocation)
      .then(trigger(_ROUTE_EDIT_LOCATION))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const deleteLocation = (routeId, locationId, date) => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({
        mutation: deleteLocationMutation,
        variables: {
          routeId,
          locationId,
          date: moment(date).toISOString(),
        },
      })
      .then(response => response.data.routeRemoveLocation)
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const deleteRun = id => dispatch => {
  dispatch(trigger(_ROUTES_LOADING)())

  return apolloClient
    .mutate({
      mutation: deleteRunMutation,
      variables: { id },
    })
    .then(trigger(_ROUTES_RUN_DELETED))
    .then(dispatch)
    .catch(error => {
      dispatch(trigger(_ROUTES_ERROR)(error))
      throw error
    })
}

export const saveLocationOrder = (routeId, locations) => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({
        mutation: saveLocationOrderMutation,
        variables: { routeId, locations },
      })
      .then(response => response.data.saveLocationOrder)
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const optimizeRoute = routeId => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({
        mutation: optimizeRouteMutation,
        variables: { routeId },
      })
      .then(res => res.data.optimizeRoute)
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const getRoutesByMarketSimple = (marketId, ops = {}) => dispatch => {
  return apolloClient
    .query({
      query: routesByMarket2,
      variables: { marketId },
      fetchPolicy: ops.cached ? 'cache-first' : 'network-only',
    })
    .then(getResults)
}

export const getRoutesByMarket = (marketId, ops = {}) => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .query({
        query: routesByMarket,
        variables: { marketId },
        fetchPolicy: ops.cached ? 'cache-first' : 'network-only',
      })
      .then(getResults)
      .then(trigger(_ROUTES_LOADED))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const getRoutesByMarketWithReport = ({
  marketId,
  start,
  end,
}) => dispatch => {
  dispatch(trigger(_ROUTES_LOADING)())

  return apolloClient
    .query({
      query: routesWithReports,
      variables: { marketId, start, end },
    })
    .then(getResults)
    .then(trigger(_ROUTES_LOADED))
    .catch(trigger(_ROUTES_ERROR))
    .then(dispatch)
}

export const getRouteReports = (routeId, { start, end }) => dispatch => {
  dispatch(trigger(_ROUTES_LOADING)())

  return apolloClient
    .query({
      query: routeWithReports,
      variables: { routeId, start, end },
    })
    .then(res => res.data.route)
    .then(trigger(_ROUTES_LOAD_ROUTE_DATA))
    .catch(trigger(_ROUTES_ERROR))
    .then(dispatch)
}

export const getRoutes = () => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .query({
        query: userRoutes,
      })
      .then(getResults)
      .then(trigger(_ROUTES_LOADED))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const getRoute = routeId => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .query({
        query: getSingleRoute,
        variables: { routeId },
        fetchPolicy: 'network-only',
      })
      .then(res => res.data.route)
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const getRouteWithLocationHistory = routeId => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .query({
        query: getSingleRouteWithLocationHistory,
        variables: { routeId },
        fetchPolicy: 'network-only',
      })
      .then(res => res.data.route)
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const addRoute = route => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({
        mutation: createRouteMutation,
        variables: { route },
      })
      .then(res => res.data.createRoute)
      .then(trigger(_ROUTES_ADDED))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const editRoute = (routeId, route) => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({
        mutation: editRouteMutation,
        variables: { routeId, route },
      })
      .then(res => res.data.editRoute)
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .catch(trigger(_ROUTES_ERROR))
      .then(dispatch)
  }
}

export const remove = routeId => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({ mutation: deleteRoute, variables: { routeId } })
      .then(res => {
        if (res.data && res.data.deleteRoute) return routeId
        else throw new Error('Could not delete')
      })
      .then(trigger(_ROUTES_REMOVE))
      .catch(triggerError(_ROUTES_ERROR, `Could not delete Route ${routeId}`))
      .then(dispatch)
  }
}

export const copy = routeId => {
  return dispatch => {
    dispatch(trigger(_ROUTES_LOADING)())
    return apolloClient
      .mutate({ mutation: copyRoute, variables: { routeId } })
      .then(res => {
        console.log(res.data.copyRoute)

        return res.data.copyRoute
      })
      .then(trigger(_ROUTES_COPY))
      .catch(triggerError(_ROUTES_ERROR, `Could not copy Route ${routeId}`))
      .then(dispatch)
  }
}

export const setCurrentRoute = route => {
  return dispatch => {
    dispatch(trigger(_ROUTES_SET_CURRENT)())
  }
}

export const getRouteReport = ({ routeId, year, month }) => {
  return dispatch => {
    return apolloClient
      .query({
        query: getEOMReport,
        variables: { routeId, year, month },
        fetchPolicy: 'network-only',
      })
      .then(
        result =>
          _has(result, 'data.route.eomReport.url') &&
          _get(result, 'data.route.eomReport')
      )
  }
}

export const downloadRouteReport = ({ routeId, year, month, regenerate }) => {
  return dispatch => {
    return apolloClient
      .query({
        query: getEOMReport,
        variables: { routeId, year, month, regenerate },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        if (_has(result, 'data.route.eomReport.url'))
          return _get(result, 'data.route.eomReport')
        else throw new Error('Can`t download report')
      })
  }
}

export const routeMoveLocation = (routeId, locationId, toRouteId) => {
  return dispatch => {
    return apolloClient
      .mutate({
        mutation: routeMoveLocationMutation,
        variables: { routeId, locationId, toRouteId },
      })
      .then(res =>
        _find(_get(res, 'data.routeMoveLocation'), { id: toRouteId })
      )
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .then(dispatch)
  }
}

export const routeCopyLocation = (routeId, locationId, toRouteId) => {
  return dispatch => {
    return apolloClient.mutate({
      mutation: routeCopyLocationMutation,
      variables: { routeId, locationId, toRouteId },
    })
  }
}

export const routeAssignLocation = (routeId, marketId, locationId, date) => {
  return dispatch => {
    return apolloClient
      .mutate({
        mutation: routeAssignLocationMutation,
        variables: {
          routeId,
          marketId,
          locationId,
          date: moment(date).toISOString(),
        },
      })
      .then(res => _get(res, 'data.routeAssignLocation'))
      .then(trigger(_ROUTES_LOAD_ROUTE))
      .then(dispatch)
  }
}

export const setShowInactiveRoutesStatus = status => {
  return dispatch => dispatch(trigger(_ROUTES_SET_SHOW_INACTIVE_ROUTES)(status))
}
