import _assign from 'lodash/assign'
import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'

export const getLocationAddressFromRawLocation = location => {
  const createdProps = {}
  const address = _get(location, 'address')
  const latitude = _get(location, 'latitude')
  const longitude = _get(location, 'longitude')

  if (_isNumber(latitude) && _isNumber(longitude)) {
    const mapLink = `https://www.google.com/maps/search/?api=1&query=${
      location.latitude
    },${location.longitude}`
    const locationMark = { latitude, longitude, role: 'selected' }

    _assign(createdProps, { locationMark, mapLink })
  }

  if (_isString(address)) {
    const city = _get(location, 'city')
    const state = _get(location, 'state')
    const postal = _get(location, 'postal')
    const fullAddress = `${address} ${city}, ${state} ${postal}`

    _assign(createdProps, { fullAddress })
  }

  return _assign(createdProps, location)
}
