import { SimpleLoading } from '../../components/Loading'
import React from 'react'
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Message,
  Modal,
} from 'semantic-ui-react'
import { withAuth } from '../../connectors/auth'
import { WithLocationActions } from '../../connectors/locations'
import { withRoutes, withRoutesActions } from '../../connectors/routes'

@withRoutesActions
@withRoutes
@WithLocationActions
@withAuth
export default class AssignLocationToRouteModal extends React.Component {
  state = {
    open: true,
    selectedMarket: null,
    selectedRoute: null,
    showLoading: false,
    error: '',
  }

  close = () => {
    const { onClose } = this.props
    this.setState({
      open: false,
    })
    onClose()
  }

  onSuccessful = () => {
    const { onSuccessful } = this.props
    if (onSuccessful) onSuccessful()
    this.close()
  }

  confirm = () => {
    const { selectedRoute, selectedMarket } = this.state
    const { location, routeAssignLocation, getLocationRoutes } = this.props

    if (!selectedRoute) {
      this.setState({
        error: 'Please pick a route!',
        showLoading: false,
      })

      return false
    }

    this.setState({
      error: '',
      showLoading: true,
    })

    routeAssignLocation(selectedRoute, selectedMarket, location.id, null)
      .then(() => {
        return getLocationRoutes(location.id)
      })
      .then(result => {
        this.onSuccessful()
      })
      .catch(error => {
        this.setState({
          error: error.message,
        })
      })
      .finally(() => {
        this.setState({
          showLoading: false,
        })
      })
  }

  onMarketChange(market) {
    const { getRoutesByMarket } = this.props

    this.setState({
      selectedMarket: market,
    })

    if (market) {
      getRoutesByMarket(market)
    }
  }

  onRouteChange(route) {
    this.setState({
      selectedRoute: route,
    })
  }

  componentDidMount() {
    const { location } = this.props

    this.setState({
      selectedMarket: location.market,
    })
  }

  render() {
    const {
      selectedMarket,
      selectedRoute,
      error,
      open,
      showLoading,
    } = this.state
    const {
      routes: { loading, routes },
      auth: { user: { markets } },
    } = this.props

    return (
      <Modal open={open} className="form" dimmer={'blurring'} size={'tiny'}>
        <Header content="Assign to Route" />
        <Modal.Content>
          <Grid columns="equal">
            <Grid.Column>
              <Dropdown
                fluid
                defaultValue={selectedMarket}
                selection
                options={
                  markets && [
                    ...markets.map(r => ({
                      text: r.name,
                      value: r.id,
                    })),
                  ]
                }
                placeholder="Select Market"
                loading={loading}
                disabled={loading}
                onChange={(e, { value }) => {
                  this.onMarketChange(value)
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                fluid
                defaultValue={selectedRoute}
                // value={selectedRoute}
                selection
                options={
                  routes && [
                    ...routes.map(r => ({
                      text: r.name,
                      value: r.id,
                    })),
                  ]
                }
                placeholder="Select Route"
                loading={loading}
                disabled={loading || !routes.length || !selectedMarket}
                onChange={(e, { value }) => {
                  this.onRouteChange(value)
                }}
              />
            </Grid.Column>
          </Grid>

          {showLoading && <SimpleLoading loading={true} />}

          {error && (
            <Message negative>
              <p>{error}</p>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={this.close}>
            Nevermind
          </Button>
          <Button disabled={!selectedRoute} positive onClick={this.confirm}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
