import gql from 'graphql-tag'
import { Run } from '../Query/runs'

export const runFields = [
  'id',
  'driver',
  'helper',
  'route',
  'run_date',
  'driver_lat',
  'driver_lng',
  'end_miles',
  'end_time',
  'start_miles',
  'start_time',
  'truck_number',
  'total_cloth',
  'total_misc',
  'total_trash',
  'weather',
  'locations',
]

export const deleteRun = gql`
  mutation deleteRun($id: String!) {
    deleteRun(id: $id) {
      ${Run}
    }
  }
`

export const saveRun = gql`
  mutation saveRun($run: RouteRunInput!) {
    saveRun(run: $run) {
      ${Run}
    }
  }
`
