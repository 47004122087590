import apolloClient from '../api/apolloClient'
import _ from 'lodash'
import {
  maintenanceRequestsQuery,
  createMaintenanceRequest as createMaintenanceRequestMutation,
  updateMaintenanceRequest as updateMaintenanceRequestMutation,
  deleteMutation,
  maintenanceRequest as getMaintenanceRequestById,
} from '../api/Query/maintenanceRequests'

import { trigger, triggerError } from '../actions'

export const _MAINTENANCE_REQUESTS_LOADING = 'MAINTENANCE_REQUESTS_LOADING'
export const _MAINTENANCE_REQUESTS_FETCH = 'MAINTENANCE_REQUESTS_FETCH'
export const _MAINTENANCE_REQUEST_FETCH = 'MAINTENANCE_REQUEST_FETCH'
export const _MAINTENANCE_REQUESTS_ERROR = 'MAINTENANCE_REQUESTS_ERROR'
export const _MAINTENANCE_REQUESTS_SEARCH = 'MAINTENANCE_REQUESTS_SEARCH'
export const _MAINTENANCE_REQUEST_ADDED = 'MAINTENANCE_REQUEST_ADDED'
export const _MAINTENANCE_REQUESTS_REMOVE = 'MAINTENANCE_REQUESTS_REMOVE'

export const clearError = () => {
  return dispatch => {
    dispatch(trigger(_MAINTENANCE_REQUESTS_ERROR)(null))
  }
}

export const fetch = (status = 'new') => {
  return dispatch => {
    dispatch(trigger(_MAINTENANCE_REQUESTS_LOADING)())
    return apolloClient
      .query({
        query: maintenanceRequestsQuery,
        variables: { status },
        fetchPolicy: 'network-only',
      })
      .then(res => res.data.maintenanceRequests)
      .then(trigger(_MAINTENANCE_REQUESTS_FETCH))
      .catch(
        triggerError(
          _MAINTENANCE_REQUESTS_ERROR,
          'Could not fetch Maintenance Requests.'
        )
      )
      .then(dispatch)
  }
}

export const getMaintenanceRequest = id => dispatch => {
  return apolloClient
    .query({
      query: getMaintenanceRequestById,
      variables: { id },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.maintenanceRequestData)
    .then(trigger(_MAINTENANCE_REQUEST_FETCH))
    .then(dispatch)
}

export const search = text => {
  return dispatch => {
    return dispatch(trigger(_MAINTENANCE_REQUESTS_SEARCH)(text))
  }
}

export const addMaintenanceRequest = maintenanceRequest => {
  return dispatch => {
    dispatch(trigger(_MAINTENANCE_REQUESTS_LOADING)())
    return apolloClient
      .mutate({
        mutation: createMaintenanceRequestMutation,
        variables: { maintenanceRequest },
        refetchQueries: [
          {
            query: maintenanceRequestsQuery,
          },
          {
            query: maintenanceRequestsQuery,
          },
        ],
      })
      .then(res => {
        return res.data.createMaintenanceRequest
      })
      .then(trigger(_MAINTENANCE_REQUEST_ADDED))
      .catch(trigger(_MAINTENANCE_REQUESTS_ERROR))
      .then(dispatch)
  }
}

const updateCollection = store => (query, action) => {
  try {
    const cached = store.readQuery(query)
    action(cached)(cached)
    store.writeQuery({ ...query, data: cached })
  } catch (err) {}
}
export const updateMaintenanceRequest = maintenanceRequest => {
  if (!maintenanceRequest.id) {
    return addMaintenanceRequest(maintenanceRequest)
  }

  return dispatch => {
    dispatch(trigger(_MAINTENANCE_REQUESTS_LOADING)())
    return apolloClient
      .mutate({
        mutation: updateMaintenanceRequestMutation,
        variables: {
          maintenanceRequestId: maintenanceRequest.id,
          maintenanceRequest,
        },
        update: (store, { data: { updateMaintenanceRequest } }) => {
          const hiddenMaintenanceRequestsQ = {
            query: maintenanceRequestsQuery,
          }
          const visibleMaintenanceRequestsQ = {
            query: maintenanceRequestsQuery,
          }
          const addMaintenanceRequest = collection => {
            const maintenanceRequest =
              _.find(collection.maintenanceRequests, {
                id: updateMaintenanceRequest.id,
              }) || {}
            if (!maintenanceRequest.id)
              collection.maintenanceRequests.unshift(maintenanceRequest)
            _.merge(maintenanceRequest, updateMaintenanceRequest)
          }
          const removeMaintenanceRequest = collection =>
            (collection.maintenanceRequests = collection.maintenanceRequests.filter(
              maintenanceRequest =>
                maintenanceRequest.id !== updateMaintenanceRequest.id
            ))
          const update = updateCollection(store)

          update(
            visibleMaintenanceRequestsQ,
            _ =>
              updateMaintenanceRequest.deactivated
                ? removeMaintenanceRequest
                : addMaintenanceRequest
          )
          update(
            hiddenMaintenanceRequestsQ,
            _ =>
              updateMaintenanceRequest.deactivated
                ? addMaintenanceRequest
                : removeMaintenanceRequest
          )
        },
      })
      .then(res => {
        return res.data.updateMaintenanceRequest
      })
      .then(trigger(_MAINTENANCE_REQUEST_ADDED))
      .catch(trigger(_MAINTENANCE_REQUESTS_ERROR))
      .then(dispatch)
  }
}

export const remove = maintenanceRequestId => {
  return dispatch => {
    dispatch(trigger(_MAINTENANCE_REQUESTS_LOADING)())
    return apolloClient
      .mutate({ mutation: deleteMutation, variables: { maintenanceRequestId } })
      .then(res => {
        if (res.data && res.data.deleteMaintenanceRequest)
          return maintenanceRequestId
        else throw new Error('Could not delete Maintenance Request.')
      })
      .then(trigger(_MAINTENANCE_REQUESTS_REMOVE))
      .catch(
        triggerError(
          _MAINTENANCE_REQUESTS_ERROR,
          `Could not delete Maintenance Request ${maintenanceRequestId}.`
        )
      )
      .then(dispatch)
  }
}
