import React from 'react'
import PropTypes from 'prop-types'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { withRoutes } from '../../connectors/routes'
import cx from 'classnames'
import { Segment } from 'semantic-ui-react'
import _isEqual from 'lodash/isEqual'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles.scss'

@withRoutes
export default class RouteCalendar extends React.PureComponent {
  static propTypes = {
    routeData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        market: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        ]).isRequired,
        runs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            run_date: PropTypes.string.isRequired,
          })
        ),
      })
    ).isRequired,
    reload: PropTypes.any,
    big: PropTypes.bool,
    allowEdit: PropTypes.bool,
    onEditRoute: PropTypes.func,
    filter: PropTypes.object,
  }

  static defaultProps = {
    reload: false,
    big: false,
    allowEdit: false,
    filter: {},
    onNavigate: () => {},
  }

  state = {
    events: [],
    loading: false,
    filter: {},
    localizer: momentLocalizer(moment),
  }

  edit = event => {
    // TODO: Let's do this later.  This would be where a user
    // clicks on a date and they can add a RouteRun for the
    // date selected.  Pretty cool, righ!?
    const { onEditRoute } = this.props
    onEditRoute(event)
  }

  initialize(date = moment(), routes) {
    routes = routes ? routes : this.props.routeData
    const { getRouteRuns, filter } = this.props

    if (!routes || !routes.length) {
      return
    }

    const start = moment(date)
      .startOf('month')
      .toISOString()
    const end = moment(date)
      .endOf('month')
      .toISOString()

    this.setState({ loading: true })

    getRouteRuns({
      routeIds: routes.map(r => r.id),
      start,
      end,
    })
      .then(res => {
        if (res.type === 'ROUTES_ERROR') {
          throw new Error('Could not load route')
        }
        return res
      })
      .then(({ payload }) => this.processEvents(payload))
      .then(events => this.setState({ events }))
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }))
      .then(() => {
        if (filter) {
          this.setState({ filter })
        }
      })
  }

  onNavigate = date => {
    const { onNavigate } = this.props
    onNavigate(moment(date))
    this.initialize(moment(date))
  }

  componentWillReceiveProps(nextProps) {
    const { reload, routeData, filter } = this.props
    const { reload: nextReload, routeData: nextRouteData } = nextProps

    if (reload !== nextReload && nextReload) {
      this.initialize()
    }

    if (routeData && !_isEqual(routeData, nextRouteData)) {
      this.initialize(moment(), nextRouteData)
    }

    if (!_isEqual(filter, nextProps.filter)) {
      this.setState({ filter: nextProps.filter })
    }
  }

  componentDidMount() {
    this.initialize()
  }

  processEvents(routes) {
    return routes.reduce((events, route) => {
      if (!route.runDetails) {
        return events
      }

      const runs = route.runDetails.map(run => ({
        start: moment(run.run_date),
        end: moment(run.run_date),
        runId: run.id,
        route,
        driver: run.driver,
        complete: !!run.end_time,
        totals: {
          cloth: run.total_cloth,
          misc: run.total_misc,
          trash: run.total_trash,
        },
      }))

      return [...events, ...runs]
    }, [])
  }

  filterEvents(events, { driver, routes }) {
    return events
      .reduce((ac, run) => {
        if (driver && driver !== run.driver && driver !== run.driver.id)
          return ac

        return [...ac, run]
      }, [])
      .reduce((ac, run) => {
        if (routes && routes.length && !routes.includes(run.route.id)) return ac
        return [...ac, run]
      }, [])
  }

  render() {
    const { big, allowEdit, renderEvent, date, popup = true } = this.props
    const { events, loading, filter, localizer } = this.state

    return (
      <Segment
        basic
        className={cx('RouteCalendar__Segment', {
          'RouteCalendar__container-big': big,
          'RouteCalendar__container-small': !big,
        })}
        loading={loading}
      >
        <Calendar
          localizer={localizer}
          events={this.filterEvents(events, filter)}
          views={['month']}
          drilldownView={null}
          onNavigate={this.onNavigate}
          components={{
            event: renderEvent
              ? event => {
                  return renderEvent(event)
                }
              : undefined,
          }}
          eventPropGetter={event => ({
            className: 'RouteCalendar__event',
            style: {
              borderColor:
                event.route.color && event.route.color !== ''
                  ? event.route.color
                  : '#AAAAAA',
              background: big ? event.route.color : 'none',
            },
          })}
          formats={{
            dateFormat: date => {
              return moment(date).format('D')
            },
          }}
          selectable={allowEdit}
          onSelectSlot={allowEdit ? this.edit : null}
          onSelectEvent={allowEdit ? this.edit : null}
          popup={popup}
          step={60}
          date={date ? date.toDate() : undefined}
        />
      </Segment>
    )
  }
}
