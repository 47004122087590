import React from 'react'

import { Grid, Row, Col } from 'react-flexbox-grid'
import { Divider } from 'semantic-ui-react'

import Header from './Header'
import Filters from './Filters'
import Breakdown from './Breakdown'

import './styles.scss'

const Dashboard = () => (
  <Grid>
    <Row>
      <Col xs={12}>
        <Header />
      </Col>
    </Row>
    <Divider hidden />
    <Filters />
    <Divider hidden />
    <Breakdown />
  </Grid>
)

export default Dashboard
