import _get from 'lodash/get'
import { createSelector } from 'reselect'

const _bins = state => (state.bins.bins ? state.bins.bins.map(Bin) : [])
const _market = state => state.bins.filters.market
const _route = state => state.bins.filters.route
const _search = state => state.bins.filters.search
const _allBins = state => state.bins.bins
const _unassignedBins = state => state.bins.unassingedBinsOptions

export const searchBins = createSelector(_bins, _search, (bins, search) => {
  if (!search || search === '') {
    return bins
  }

  search = search.toLowerCase()

  return bins.filter(bin => {
    if (!bin.serial_number) return false

    const serialNumberLowercase = bin.serial_number.toString().toLowerCase()

    return serialNumberLowercase.includes(search)
  })
})

export const filterBins = createSelector(
  _allBins,
  _market,
  _route,
  _search,
  (bins, market, route, search) => {
    bins = bins.map(bin => ({
      ...bin,
      value: bin.id,
      status: bin.missing
        ? 'Missing'
        : bin.locationId ? 'Assigned' : 'Unassigned',
    }))

    if (market) {
      bins = bins.filter(bin => {
        if (bin.market === market) return true
        else return false
      })
    }

    if (route) {
      if (route.id !== null) {
        bins = bins.filter(bin => {
          if (bin.routes.some(binRoute => binRoute.id === route.id)) return true
          else return false
        })
      }
    }

    if (search) {
      search = search.toString().toLowerCase()

      bins = bins.filter(bin => {
        if (!bin.serial_number) return false

        const serialNumberLowercase = bin.serial_number.toString().toLowerCase()
        return serialNumberLowercase.includes(search)
      })
    }

    return bins
  }
)

export const getBinsFilterRouteOption = createSelector(_route, route => {
  return route
})

export const binsCount = createSelector(_allBins, bins => {
  return bins.length
})

export const Bin = createSelector(
  state => state,
  bin => ({
    ...bin,
    value: bin.id,
    status: bin.locationId ? 'Assigned' : 'Unassigned',
    color: bin.locationId ? 'green' : 'red',
  })
)

export const BinDropdownOptions = createSelector(
  data => {
    return _get(data, 'bins')
  },
  binDropdownOptions => {
    return {
      binDropdownOptions: binDropdownOptions,
    }
  }
)

export const getUnassignedBinsOptions = createSelector(
  _unassignedBins,
  unassignedBins => {
    return unassignedBins
  }
)

export const binsFilters = createSelector(
  state => {
    return state.bins.filters
  },
  filters => {
    return filters
  }
)
