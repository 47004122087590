import _concat from 'lodash/concat'
import _find from 'lodash/find'
import _compose from 'lodash/fp/compose'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _max from 'lodash/max'
import _pick from 'lodash/pick'
import _replace from 'lodash/replace'
import React from 'react'

import { runFields } from '../../api/Mutation/runs'
import { WithMutator } from '../../connectors/mutator'
import { WithRunReport } from '../../connectors/runs'
import PropTypes from 'prop-types'

import { withForm } from '../../connectors/forms'
import { withRuns } from '../../connectors/runs'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { removeTypename } from '../../utils'

import { Button, Container, Message, Segment } from 'semantic-ui-react'

import Confirmation from '../../components/Confirmation'
import { LoadingMessage } from '../../components/Loading'
import { has } from 'lodash'
import CreateRunForm from './form'

const formatLocation = _compose(
  loc =>
    _pick(loc, ['location', 'bins', 'notInOriginalRoute', 'originalRouteId']),
  location => distributeLocationWeight(location),
  location => removeLocationAdditionalProperties(location),
  removeTypename()
)

const isBinEmpty = bin => {
  if (has(bin, 'report.tagInfo') && Array.isArray(bin.report.tagInfo)) {
    const tags = bin.report.tagInfo.filter(
      category => category.title === 'Skipped bin'
    )
    return tags.length ? true : false
  } else {
    return false
  }
}

const distributeLocationWeight = location => {
  const weight = _get(location, 'weight', 0)
  const notEmptyBinCount = location.bins.reduce((prev, curr) => {
    if (isBinEmpty(curr)) return prev
    else return prev + 1
  }, 0)

  location.bins.forEach(bin => {
    if (!bin.report) bin.report = {}

    if (isBinEmpty(bin)) {
      bin.weight = 0
      bin.report.weight = 0
    } else {
      bin.weight = notEmptyBinCount ? weight / notEmptyBinCount : weight
      bin.report.weight = notEmptyBinCount ? weight / notEmptyBinCount : weight
    }
  })
  return location
}

const removeLocationAdditionalProperties = location => {
  delete location.location.markedCompetitorsAsRemove
  delete location.location.routes

  return location
}

const processRun = runInfo => {
  if (!runInfo) return {}

  const { deleted, ...run } = runInfo

  const ret = {
    ...runInfo,
    route: _get(run, 'route.id'),
    driver: _get(run, 'driver.id'),
    market: _get(run, 'route.market.id'),
    helper: _get(run, 'helper.id'),
    start_time: moment(_get(run, 'start_time')).format('YYYY-MM-DDTHH:mm'),
    end_time: moment(_get(run, 'end_time')).format('YYYY-MM-DDTHH:mm'),
    date: moment(_get(run, 'run_date')),
  }

  return ret
}

const fixLocationId = (locationId, routeId) =>
  _replace(locationId, `${routeId}-`, '')

const parseLocation = (location, routeLocations, routeId, stopSequence) => {
  const {
    address,
    bins,
    city,
    id,
    latitude,
    longitude,
    name,
    originalRouteId,
    postal,
    state,
  } = location

  return {
    bins: _get(location, 'bins'),
    location: {
      address,
      bins,
      city,
      id,
      latitude,
      longitude,
      name,
      postal,
      state,
      stop_sequence: stopSequence,
    },
    notInOriginalRoute: !_find(routeLocations, routeLocation => {
      const routeLocationId = fixLocationId(
        _get(routeLocation, 'locationId', routeLocation.id),
        routeId
      )
      return routeLocationId === location.id
    }),
    originalRouteId,
  }
}

const parseLocations = (route, run) => {
  const runLocations = _get(run, 'locations')
  const routeId = _get(route, 'id')
  if (_isArray(runLocations) && !_isEmpty(runLocations)) {
    return runLocations
  }

  const routeLocations = _get(route, 'locations')
  if (_isArray(routeLocations)) {
    return _map(routeLocations, location => {
      const locationId = fixLocationId(
        _get(location, 'locationId', location.id),
        routeId
      )

      return {
        bins: _get(location, 'bins'),
        location: { ...location, id: locationId },
      }
    })
  }
}

@withForm('CreateRunForm')
@WithRunReport
@withRuns
@WithMutator('saveRun')
export default class CreateRun extends React.PureComponent {
  static defaultProps = {
    onSubmit: () => Promise.resolve,
    trigger: <Button>Go</Button>,
    closeModal: () => {},
    renderButtons: () => {},
    onCreate: () => {},
    run: null,
    initialValues: {},
  }

  static propTypes = {
    initialValues: PropTypes.shape({
      market: PropTypes.string,
      route: PropTypes.string,
      driver: PropTypes.string,
    }),
    markets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    drivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      })
    ),
    onCreate: PropTypes.func,
    basic: PropTypes.bool,
  }

//   static defaultProps = {
//     initialValues: {},
//   }

  componentDidUpdate() {
    this.renderButtons()
  }

  componentDidMount() {
    // Handle setting the Driver
    const {
      initialValues: { driver, route },
      getDriverInfo,
      getRouteInfo,
      clearDriver,
    } = this.props

    if (driver) {
      getDriverInfo(driver)
    } else {
      clearDriver()
    }

    if (route) {
      getRouteInfo(route)
    }

    this.renderButtons()
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      run,
      mutating,
      called,
    } = this.props

    if (called)
      return renderButtons(
        <Container>
          <Button positive onClick={closeModal}>
            Done
          </Button>
        </Container>
      )

    return renderButtons(
      <div>
        <Confirmation
          trigger={
            <Button negative disabled={mutating}>
              Nevermind
            </Button>
          }
          onConfirm={closeModal}
        />
        <Button
          positive
          onClick={submitForm}
          disabled={mutating}
          loading={mutating}
        >
          {run && run.id ? 'Edit' : 'Create'}
          {run && run.id && !run.end_time && ' and Finalize'}
        </Button>
      </div>
    )
  }

  createRun = run => {
    this.setState({
      submitting: false,
      saved: false,
      error: null,
    })

    const { saveRun, onCreate } = this.props

    const newRun = {
      ..._pick(run, runFields),
      run_date: _get(run, 'date', moment()).toISOString(),
      start_time: moment(_get(run, 'start_time')).toISOString(),
      end_time: moment(_get(run, 'end_time')).toISOString(),
      locations: _map(_get(run, 'locations'), formatLocation),
    }

    setImmediate(() => this.renderButtons())

    return saveRun({ run: newRun }).then(onCreate)
  }

  onMarketChange = marketId => {
    const { getMarketData } = this.props
    getMarketData(marketId)
  }

  onRouteChange = routeId => {
    const { getRouteInfo, changeFieldValue } = this.props

    // If the run is being passed, then we don't do anything when the route changes
    getRouteInfo(routeId)
      .then(x => x.payload)
      .then(route => {
        if (this.props.run && this.props.run.locations) return
        changeFieldValue('locations', parseLocations(route))
      })
  }

  /**
   * Checks if the user exists in the drivers list
   * @param user User
   * @param drivers User[]
   * @returns Boolean
   */
  userExistInDrivers(user, drivers) {
    return drivers.some(item => {
      return user.id === item.id
    })
  }

  /**
   * Pushes the currecnt driver to drivers array if it does not exist
   * @param currentDriver User
   */
  pushCurrentDriverToDrivers(run, drivers) {
    if (!this.userExistInDrivers(run.driver, drivers)) {
      drivers.push(run.driver)
    }
  }

  addLocation = location => {
    const { changeFieldValue, runs: { route }, selector } = this.props

    const locations = selector('locations')
    const stopSequence =
      1 + _max(_map(locations, location => location.location.stop_sequence))
    const routeLocations = _get(route, 'locations')
    const routeId = _get(route, 'id')
    const parsedLocation = parseLocation(
      location,
      routeLocations,
      routeId,
      stopSequence
    )

    const newLocations = _concat(locations, parsedLocation)
    changeFieldValue('locations', newLocations)
  }

  render() {
    const {
      initialValues,
      runs: { markets, routes, drivers, loading, driver, route },
      basic,
      result,
      run,
      error,
      called,
      mutating,
      selector,
    } = this.props

    const runToProcess = !!run ? run : result

    const locations = !!runToProcess
      ? parseLocations(route, runToProcess)
      : selector('locations')

    if (!route && !basic) {
      return <Segment basic loading={true} style={{ height: '200px' }} />
    }

    const values = {
      ...initialValues,
      ...processRun(runToProcess),
      locations,
    }

    if (mutating) {
      return <LoadingMessage tilte="Saving this run" />
    }

    if (called) {
      return (
        <Message positive>
          Your route run has been saved.
          <br />
          <strong>
            <Link to={`/app/reports/run/${runToProcess.id}`}>Click Here</Link>
          </strong>{' '}
          to see your report.
        </Message>
      )
    }

    if (run && drivers) {
      this.pushCurrentDriverToDrivers(run, drivers)
    }

    return [
      <CreateRunForm
        onSubmit={this.createRun}
        initialValues={values}
        markets={markets}
        market={values.market}
        routes={routes}
        drivers={driver ? [values.driver || driver] : drivers}
        driver={values.driver || driver}
        route={values.route || route}
        helpers={drivers}
        loading={loading}
        onMarketChange={this.onMarketChange}
        onRouteChange={this.onRouteChange}
        addLocation={this.addLocation}
        key="createRunForm"
      />,
      error && <Message error {...error} />,
    ]
  }
}
