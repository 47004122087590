import apolloClient from '../api/apolloClient'
import _ from 'lodash'
import {
  usersQuery,
  createUser as createUserMutation,
  updateUser as updateUserMutation,
  deleteMutation,
  user as getUserById,
} from '../api/Query/users'
import { getMarketDrivers as getMarketDriversQuery } from '../api/Query/markets'

import { trigger, triggerError } from '../actions'

export const _USERS_LOADING = 'USERS_LOADING'
export const _USERS_FETCH = 'USERS_FETCH'
export const _USER_FETCH = 'USER_FETCH'
export const _USERS_ERROR = 'USERS_ERROR'
export const _USERS_SEARCH = 'USERS_SEARCH'
export const _USER_ADDED = '_USER_ADDED'
export const _USERS_REMOVE = 'USERS_REMOVE'

export const clearError = () => {
  return dispatch => {
    dispatch(trigger(_USERS_ERROR)(null))
  }
}

export const fetch = (deactivated = false) => {
  return dispatch => {
    dispatch(trigger(_USERS_LOADING)())
    return apolloClient
      .query({
        query: usersQuery,
        variables: { deactivated },
      })
      .then(res => res.data.allUsers)
      .then(trigger(_USERS_FETCH))
      .catch(triggerError(_USERS_ERROR, 'Could not fetch users'))
      .then(dispatch)
  }
}

export const getUser = id => dispatch => {
  return apolloClient
    .query({
      query: getUserById,
      variables: { id },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.userData)
    .then(trigger(_USER_FETCH))
    .then(dispatch)
}

export const search = text => {
  return dispatch => {
    return dispatch(trigger(_USERS_SEARCH)(text))
  }
}

export const addUser = (user, markets) => {
  return dispatch => {
    dispatch(trigger(_USERS_LOADING)())
    return apolloClient
      .mutate({
        mutation: createUserMutation,
        variables: { user, markets },
        refetchQueries: [
          {
            query: usersQuery,
            variables: { deactivated: user.deactivated },
          },
          {
            query: usersQuery,
            variables: { deactivated: !user.deactivated },
          },
          {
            query: getMarketDriversQuery,
            variables: { ids: markets && markets.map(m => m.id) },
          },
        ],
      })
      .then(res => {
        return res.data.createUser
      })
      .then(trigger(_USER_ADDED))
      .catch(trigger(_USERS_ERROR))
      .then(dispatch)
  }
}

const updateCollection = store => (query, action) => {
  try {
    const cached = store.readQuery(query)
    action(cached)(cached)
    store.writeQuery({ ...query, data: cached })
  } catch (err) {}
}
export const updateUser = (user, markets) => {
  if (!user.id) {
    return addUser(user, markets)
  }

  return dispatch => {
    dispatch(trigger(_USERS_LOADING)())
    return apolloClient
      .mutate({
        mutation: updateUserMutation,
        variables: { user, markets },
        update: (store, { data: { updateUser } }) => {
          const hiddenUsersQ = {
            query: usersQuery,
            variables: { deactivated: true },
          }
          const visibleUsersQ = {
            query: usersQuery,
            variables: { deactivated: false },
          }
          const addUser = collection => {
            const user =
              _.find(collection.allUsers, { id: updateUser.id }) || {}
            if (!user.id) collection.allUsers.unshift(user)
            _.merge(user, updateUser)
          }
          const removeUser = collection =>
            (collection.allUsers = collection.allUsers.filter(
              user => user.id !== updateUser.id
            ))
          const update = updateCollection(store)

          update(
            visibleUsersQ,
            _ => (updateUser.deactivated ? removeUser : addUser)
          )
          update(
            hiddenUsersQ,
            _ => (updateUser.deactivated ? addUser : removeUser)
          )
        },
        refetchQueries: [
          {
            query: getMarketDriversQuery,
            variables: { ids: markets && markets.map(m => m.id) },
          },
        ],
      })
      .then(res => {
        return res.data.updateUser
      })
      .then(trigger(_USER_ADDED))
      .catch(trigger(_USERS_ERROR))
      .then(dispatch)
  }
}

export const remove = userId => {
  return dispatch => {
    dispatch(trigger(_USERS_LOADING)())
    return apolloClient
      .mutate({ mutation: deleteMutation, variables: { userId } })
      .then(res => {
        if (res.data && res.data.deleteUser) return userId
        else throw new Error('Could not delete')
      })
      .then(trigger(_USERS_REMOVE))
      .catch(triggerError(_USERS_ERROR, `Could not delete user ${userId}`))
      .then(dispatch)
  }
}
