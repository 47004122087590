import React from 'react'

import { Link } from 'react-router-dom'

import { Icon } from 'semantic-ui-react'

import ContentHeader from '../../components/ContentHeader'

class Header extends React.PureComponent {
  render() {
    return (
      <ContentHeader title="Schedule" showBackButton>
        <Link to="/app/schedule" className="item">
          <Icon name="calendar" />
          View Schedule
        </Link>
      </ContentHeader>
    )
  }
}

export default Header
