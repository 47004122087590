import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import config from '../config'
import { auth } from '../utils/firebase'

const httpLink = createPersistedQueryLink().concat(
  createHttpLink({ uri: config.APOLLO_URI })
)

var _TOKEN = null
const fetchToken = () => {
  const currentUser = auth.currentUser
  if (_TOKEN) return Promise.resolve(_TOKEN)

  if (!currentUser || _TOKEN) return Promise.reject('No login yet')

  return currentUser.getIdToken().then(result => {
    _TOKEN = result
    return _TOKEN
  })
}

// Set the auth header authorization
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  return fetchToken()
    .then(token => ({
      headers: {
        ...headers,
        authorization: token,
      },
    }))
    .catch(error => ({
      headers,
    }))
})

// const httpLink = createHttpLink({ uri: config.APOLLO_URI });
const middlewareLink = new ApolloLink((operation, forward) => {
	if(process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
		console.log(
			`%cApollo call %c${operation.operationName}`,
			'color: gray',
			'font-weight: bold'
		)
	}
  return forward(operation).map(result => {
	if(process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'production') {
		console.log(
			`%cApollo result %c${operation.operationName}`,
			'color: gray',
			'font-weight: bold',
			result
	    )
	}
    return result
  })
})
const link = middlewareLink.concat(authLink.concat(httpLink))

/* eslint import/no-anonymous-default-export: 0 */
export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
