import React from 'react'
import _has from 'lodash/has'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { withForm } from '../../connectors/forms'
import { WithMutator } from '../../connectors/mutator'
import { WithRoutes } from '../../connectors/routes'

import { Button, Message } from 'semantic-ui-react'
import { LoadingMessage } from '../../components/Loading'
import RouteForm from './form'

@withForm('RouteForm')
class Route extends React.PureComponent {
  onSubmit = data => {
    const { createRoute, editRoute, route, editing } = this.props
    const routeData = _pick(data, ['name', 'market', 'code', 'color'])

    if (_has(data, 'inactive')) routeData['inactive'] = data.inactive
    else routeData['inactive'] = false

    if (editing)
      return editRoute({ routeId: _get(route, 'id'), route: routeData })
    else return createRoute({ route: routeData })
  }

  componentDidUpdate() {
    this.renderButtons()
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      mutating,
      result,
    } = this.props

    if (result)
      return renderButtons(
        <Button positive onClick={closeModal}>
          Close
        </Button>
      )

    return renderButtons(
      <div>
        <Button negative onClick={closeModal} disabled={mutating}>
          Nevermind
        </Button>
        <Button positive onClick={submitForm} disabled={mutating}>
          Submit
        </Button>
      </div>
    )
  }

  render() {
    const { error, loading, mutating, result, route, editing } = this.props

    if (result) return <Message positive>Route Saved</Message>

    if (mutating) return <LoadingMessage title="Saving this Route" />

    if (loading) return <LoadingMessage title="Loading Route info..." />

    if (error)
      return <Message error header="Error" key="userError" content={error} />

    return (
      <RouteForm
        key="RouteForm"
        editing={editing}
        onSubmit={this.onSubmit}
        initialValues={
          route && {
            ...route,
            market: route.market && route.market.id,
          }
        }
      />
    )
  }
}

export default class SwitchFormAction extends React.PureComponent {
  render() {
    const editing = _has(this.props, 'route') || _has(this.props, 'routeId')

    const Form = editing
      ? WithMutator('editRoute')(WithRoutes(Route))
      : WithMutator('createRoute')(Route)

    return <Form {...this.props} editing={editing} />
  }
}
