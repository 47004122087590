import _get from 'lodash/get'
import React from 'react'

import { WithLocationActions } from '../../connectors/locations'
import { withRoutesActions } from '../../connectors/routes'

import { LoadingMessage } from '../../components/Loading'
import { has } from 'lodash'
import ExistingLocationForm from '../ExistingLocation'
import Confirmation from './confirmation'

@withRoutesActions
@WithLocationActions
export default class AssignRouteLocation extends React.PureComponent {
  state = {
    location: null,
    mutating: false,
  }

  getSnapshotBeforeUpdate(prevProps, state) {
    const { mutating } = this.state
    if (state.mutating !== mutating) return { renderButtons: true }

    return null
  }

  componentDidUpdate(props, state, snapshot) {
    const { renderButtons } = this.props
    if (snapshot && snapshot.renderButtons) renderButtons(false)
  }

  addLocationToRun = ({ location, date }) => {
    const { addLocationToRun, getLocationRoutes } = this.props

    return getLocationRoutes(location.id)
      .then(locationRoutes => {
        if (!!locationRoutes) {
          location.originalRouteId = locationRoutes[0].id
        } else {
          location.originalRouteId = null
        }
        return location
      })
      .then(addLocationToRun)
  }

  assignLocation = ({ reAddedDate }) => {
    const {
      routeAssignLocation,
      route,
      closeModal,
      showCalculateRouteLengthModal,
    } = this.props
    const { location } = this.state
    const routeId = _get(route, 'id')
    const marketId = _get(route, 'market.id')

    this.setState({ mutating: true })
    return routeAssignLocation(
      routeId,
      marketId,
      location.id,
      reAddedDate
    ).then(result => {
      if (has(result.payload, 'mile_calculation_result'))
        if (!result.payload.mile_calculation_result.status) {
          showCalculateRouteLengthModal(
            result.payload.mile_calculation_result.message
          )
        }

      closeModal()
    })
  }

  singleAssignment = () => {
    const { routeMoveLocation, route, closeModal } = this.props
    const { location } = this.state
    const routeId = route.id

    this.setState({ mutating: true })
    return routeMoveLocation('all', location.id, routeId).then(result => {
      closeModal()
    })
  }

  setLocation = ({ routeId, location }) => {
    return new Promise((resolve, reject) => {
      this.setState({ location, routeId }, () => resolve({ location, routeId }))
    })
  }

  deny = () => {
    const { closeModal } = this.props

    closeModal()
  }

  render() {
    const {
      isCustomRouteForRun,
      routeAssignLocation,
      route,
      simple_locations,
      ...props
    } = this.props
    const { location, mutating } = this.state

    if (location)
      return (
        <React.Fragment>
          <Confirmation
            location={location}
            aimRoute={route}
            doAssignment={this.assignLocation}
            doSingleAssignment={this.singleAssignment}
            deny={this.deny}
            open={!mutating}
          />
          {mutating && <LoadingMessage title="Saving this Route Location" />}
        </React.Fragment>
      )

    return (
      <ExistingLocationForm
        {...props}
        onSubmit={
          !isCustomRouteForRun ? this.setLocation : this.addLocationToRun
        }
        simple_locations={simple_locations}
      />
    )
  }
}
