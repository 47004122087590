import _assign from 'lodash/assign'
import * as bins from './bins'
import * as markets from './markets'
import * as locations from './locations'
import * as partnerships from './partnerships'
import * as runs from './runs'
import * as routes from './routes'
import * as policeReports from './policeReports'
import * as maintenanceRequests from './maintenanceRequests'

export default _assign(
  {},
  bins,
  markets,
  locations,
  partnerships,
  runs,
  routes,
  policeReports,
  maintenanceRequests
)
