import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import { withAuth } from './connectors/auth'
import PrivateRoute from './utils/PrivateRoute'

import Loading from './components/Loading'

import AppLayout from './layouts/App'
import AuthLayout from './layouts/Auth'
import StyleGuide from './layouts/StyleGuide'

// const NoMatch = () => <h1>404 - Page not found.</h1>

@withRouter
@withAuth
export default class Routes extends React.Component {
  componentDidMount() {
    const { start } = this.props
    start()
  }

  render() {
    const { auth: { loaded } } = this.props

    if (!loaded) {
      return <Loading />
    }

    return (
      <Switch>
        <Route exact path="/styleguide" component={StyleGuide} />
        <PrivateRoute path="/app" component={AppLayout} />
        <Route path="/auth" component={AuthLayout} />
        {/* <Route path="*">
          <NoMatch />
        </Route> */}
        {loaded && <Redirect to="/auth/login" />}
      </Switch>
    )
  }
}
