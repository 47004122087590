export const weather = [
  {
    text: 'Sunny',
    value: 'Sunny',
  },
  {
    text: 'Partly Cloudy',
    value: 'Partly Cloudy',
  },
  {
    text: 'Cloudy',
    value: 'Cloudy',
  },
  {
    text: 'Storm',
    value: 'Storm',
  },
  {
    text: 'Hail',
    value: 'Hail',
  },
  {
    text: 'Rain',
    value: 'Rain',
  },
  {
    text: 'Snow',
    value: 'Snow',
  },
  {
    text: 'Windy',
    value: 'Windy',
  },
]
