import DateField from '../../components/DatePickerPureHtml'
import FormField from '../../components/FormField'
import { SimpleLoading } from '../../components/Loading'
import React from 'react'
import { Button, Grid, Header, Message, Modal } from 'semantic-ui-react'
import { WithLocationActions } from '../../connectors/locations'

@WithLocationActions
export default class EditDateRemovedFromRouteModal extends React.Component {
  state = {
    open: true,
    selectedDate: null,
    showLoading: false,
    error: '',
  }

  close = () => {
    const { onClose } = this.props
    this.setState({
      open: false,
    })
    onClose()
  }

  onSuccessful = () => {
    this.close()
  }

  confirm = () => {
    const { selectedDate } = this.state
    const { setLocationDateRemoved, location } = this.props

    if (!selectedDate) {
      this.setState({
        error: 'Please pick the date!',
        showLoading: false,
      })

      return false
    }

    this.setState({
      error: '',
      showLoading: true,
    })

    setLocationDateRemoved(location, selectedDate)
      .then(() => {
        this.onSuccessful(selectedDate)
      })
      .catch(error => {
        this.setState({
          error: error.message,
        })
      })
      .finally(() => {
        this.setState({
          showLoading: false,
        })
      })
  }

  onChange = data => {
    if (data) {
      this.setState({
        selectedDate: data.value,
      })
    }
  }

  componentDidMount() {
    const { date } = this.props

    this.setState({
      selectedDate: date,
    })
  }

  render() {
    const { selectedDate, error, open, showLoading } = this.state

    return (
      <Modal open={open} className="form" dimmer={'blurring'}>
        <Header content="Date Removed" />
        <Modal.Content>
          <p>Please pick the date you want to set as the Date Removed.</p>

          <Grid>
            <Grid.Column width={4}>
              <DateField
                name="date"
                label="Date Removed: "
                component={FormField}
                onBlur={e => e.preventDefault()}
                required
                type="date"
                onChange={(e, value) => this.onChange(value)}
                value={selectedDate}
                error={!!error}
              />
            </Grid.Column>
          </Grid>

          {showLoading && <SimpleLoading loading={true} />}

          {error && (
            <Message negative>
              <p>{error}</p>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button negative onClick={this.close}>
            Nevermind
          </Button>
          <Button positive onClick={this.confirm}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
