import React from 'react'
import { SwatchesPicker } from 'react-color'
import { Form } from 'semantic-ui-react'

export default ({ label, onChange, value, error, required, ...props }) => (
  <Form.Field error={error} required={required}>
    <label>{label}</label>
    <div
      style={
        error
          ? {
              background: '#fff6f6',
              border: '1px solid #e0b4b4',
              color: '#9f3a38',
              borderRadius: '',
              boxShadow: 'none',
            }
          : undefined
      }
    >
      <SwatchesPicker
        {...props}
        color={value}
        onChange={color => onChange(color.hex)}
      />
    </div>
  </Form.Field>
)
