import gql from 'graphql-tag'
import { Location } from '../Query/locations'

export const createLocation = gql`
  mutation createLocation($location: LocationInput!) {
    createLocation(location: $location) { ${Location} }
  }
`

export const updateLocation = gql`
  mutation updateLocation($id: String!, $location: LocationInput!) {
    updateLocation(id: $id, location: $location) { ${Location} }
  }
`

export const setLocationDateInService = gql`
  mutation setLocationDateInService($locationId: String!, $date: String) {
    setLocationDateInService(locationId: $locationId, date: $date) {
      id
      date_in_service
    }
  }
`

export const setLocationDateRemoved = gql`
  mutation setLocationDateRemoved($locationId: String!, $date: String) {
    setLocationDateRemoved(locationId: $locationId, date: $date) {
      id
      date_removed_from_route
    }
  }
`

export const setLocationDateReAdded = gql`
  mutation setLocationDateReAdded($locationId: String!, $date: String) {
    setLocationDateReAdded(locationId: $locationId, date: $date) {
      id
      date_re_added
    }
  }
`

export const setLocationDateRepositioning = gql`
  mutation setLocationDateRepositioning($locationId: String!, $date: String) {
    setLocationDateRepositioning(locationId: $locationId, date: $date) {
      id
      date_repositioning
    }
  }
`

export const setLocationDateConverted = gql`
  mutation setLocationDateConverted($locationId: String!, $date: String) {
    setLocationDateConverted(locationId: $locationId, date: $date) {
      id
      conversion_history {
        at
        converted_to
      }
      location_type
    }
  }
`
