import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { Icon, Button } from 'semantic-ui-react'

import RouteForm from '../../forms/Route'
import UserForm from '../../forms/User'
import MarketForm from '../../forms/Market'
import AddLocationForm from '../../forms/AddLocation'
import RouteSheetForm from '../../forms/RouteSheet'
import ModalTrigger from '../../components/ModalTrigger'
import CreateRun from '../../components/CreateRun'

import BinForm from '../../forms/Bin/Bin'
import MaintenanceRequestForm from '../../forms/Bin/MaintenanceRequest'
import PoliceReportForm from '../../forms/Bin/PoliceReport'

const CreateButton = () => (
  <Dropdown
    floating
    pointing
    icon={null}
    trigger={
      <Button icon color="orange">
        <Icon name="plus" /> Create New
      </Button>
    }
  >
    <Dropdown.Menu>
      <ModalTrigger label="User" form={<UserForm />} title="Create New User" />
      <ModalTrigger
        label="Route"
        form={<RouteForm />}
        title="Create New Route"
        scrolling={true}
      />
      <CreateRun basic trigger={<Dropdown.Item>Run</Dropdown.Item>} />
      <ModalTrigger
        label="Market"
        form={<MarketForm />}
        title="Create New Market"
        scrolling={true}
      />
      <ModalTrigger
        label="Route Sheet"
        form={<RouteSheetForm />}
        title="Create New Route Sheet"
        scrolling={true}
      />
      <ModalTrigger
        label="Special Pickup"
        form={<RouteForm />}
        title="Create New Special Pickup"
        scrolling={true}
      />
      <ModalTrigger
        label="Location"
        form={<AddLocationForm />}
        title="Create New Location"
        scrolling={true}
      />
      <ModalTrigger
        label="Bin"
        form={<BinForm />}
        title="Create New Bin"
        scrolling={true}
      />
      <ModalTrigger
        label="Maintenance Request"
        form={<MaintenanceRequestForm />}
        title="Create New Maintenance Request"
        scrolling={true}
      />
      <ModalTrigger
        label="Police Report"
        form={<PoliceReportForm />}
        title="Create New Police Report"
        scrolling={true}
      />
    </Dropdown.Menu>
  </Dropdown>
)

export default CreateButton
