import React from 'react'

import { Grid, Row, Col } from 'react-flexbox-grid'
import UserCard from '../UserCard'
import { Pagination } from 'semantic-ui-react'

import './styles.scss'

export default class UsersGrid extends React.PureComponent {
  state = {
    activePage: 0,
    size: 20,
  }

  onPageChange(event, data) {
    this.setState({ activePage: data.activePage })
  }

  render() {
    const { users } = this.props
    const { activePage, size } = this.state
    let pageNumber = activePage
    if (activePage > 0) {
      pageNumber = pageNumber - 1
    }

    const paginated = users.slice(pageNumber * size, (pageNumber + 1) * size)

    return (
      <Grid className="Users__Grid">
        <Row start="xs" className="User__Row">
          {paginated.map(user => (
            <Col xs={6} sm={4} md={3} key={user.id}>
              <UserCard data={user} />
            </Col>
          ))}
        </Row>
        <Row>
          <Pagination
            onPageChange={this.onPageChange.bind(this)}
            defaultActivePage={1}
            totalPages={Math.ceil(users.length / size)}
          />
        </Row>
      </Grid>
    )
  }
}
