import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import React from 'react'

import ModalTrigger from '../../components/ModalTrigger'
import { WithMutator } from '../../connectors/mutator'
import { WithRunReport } from '../../connectors/runs'
import moment from 'moment'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'
import { Header, Icon, Popup, Segment } from 'semantic-ui-react'
import { dateDifference } from '../../utils/date'

import { Cell, Pie, PieChart } from 'recharts'

import Confirmation from '../../components/Confirmation'
import RunStopsTable from '../../components/RunStopsTable'
import CreateRun from '../../forms/CreateRun'
import RunLogs from './RunLogs'

import './styles.scss'

const COLORS = {
  Cloth: '#94d3d1',
  Misc: '#ff6665',
  Trash: '#7f7152',
}

const LegendDot = ({ color }) => (
  <div className="LegendDot__container" style={{ background: color }} />
)

const LegendItem = ({ color, label, value, noTotal = false }) => (
  <Row className="LegendItem__container">
    <Col xs={1}>
      <LegendDot color={color} />
    </Col>
    <Col xs>
      {!noTotal && 'Total'} {label}
    </Col>
    <Col xs>{value}</Col>
  </Row>
)

const Legend = ({ data }) => (
  <div>
    <label>Load Cart Summary</label>
    {_map(data, (entry, weightType) => (
      <LegendItem
        key={entry.name}
        color={COLORS[weightType]}
        label={entry.name}
        value={entry.value}
        noTotal={entry.noTotal}
      />
    ))}
  </div>
)

const NoData = () => (
  <div className="NoData__container">
    <span>No Data</span>
  </div>
)

@WithRunReport
@WithMutator('deleteRun')
export default class RunReport extends React.PureComponent {
  state = {
    refreshing: false,
    run: null,
  }

  deleteRun = run => () => {
    const { deleteRun } = this.props
    return deleteRun({ id: run.id })
  }

  refreshRun = () => {
    const { refetch } = this.props

    refetch()
  }

  render() {
    const { run, loading } = this.props
    let data = _get(run, 'totals')
    if (data) {
      const runLocations = _get(run, 'locations')
      const totalEstimate = _reduce(
        runLocations,
        (ac, location) => (ac += _get(location, 'weight')),
        0
      )
      const totalActual = data.Cloth.value
      const accuracy = Math.max(
        0,
        Math.floor(
          100 - Math.abs(totalEstimate - totalActual) / totalActual * 100
        )
      )

      data = {
        ...data,
        Estimate: {
          name: 'Total Estimate',
          value: totalEstimate,
          noTotal: true,
        },
        Accuracy: {
          name: 'Driver Accuracy',
          value: `${accuracy}%`,
          noTotal: true,
        },
      }
    }

    if (_get(run, 'deleted')) {
      return (
        <Segment>
          <NoData />
          <strong>
            This run was deleted on {moment(run.deleted).format('llll')}
          </strong>
        </Segment>
      )
    }

    let optimized_miles = -1

    if (run) {
      if (run.optimized_miles) optimized_miles = run.optimized_miles
      else if (run.route.optimized_miles)
        optimized_miles = run.route.optimized_miles
    }

    return (
      <Segment.Group>
        {run && (
          <Segment className="RunReport__Header">
            <Header as="h3">Route Run Details</Header>
            <Confirmation
              trigger={<Icon name="trash" size="large" />}
              onConfirm={this.deleteRun(run)}
              title="Deletion alert"
              message={
                'Warning! You are about to delete an entire route run, and all associated data. This cannot be undone. Please confirm.'
              }
            />
            <Icon name="refresh" size="large" onClick={this.refreshRun} />
            <ModalTrigger
              title="Edit Run"
              trigger={<Icon name="pencil" size="large" />}
              scrolling={true}
              form={<CreateRun run={run} basic={true} />}
            />
            <Popup
              trigger={
                <RunLogs
                  runId={run.id}
                  trigger={<Icon name="id badge" size="large" />}
                />
              }
              content="Track all driver actions"
            />
            <Link
              to={`/app/routes/${run.route.market.id}/${run.route.id}`}
              className="RunReport__routeLink"
            >
              <div style={{ backgroundColor: run.route.color }} />
              <span>{run.route.name}</span>
            </Link>
            {run.is_custom && (
              <Popup
                trigger={
                  <Icon
                    name="cubes"
                    size="large"
                    className="customized_run_icon"
                  />
                }
                content="Customized Run"
              />
            )}
          </Segment>
        )}
        <Segment
          padded={false}
          loading={loading}
          className="RunReport__container"
        >
          {run && (
            <Grid>
              {run.run_date && (
                <Row style={{ marginBottom: '1rem' }}>
                  <i>
                    Scheduled to run on{' '}
                    {moment(run.run_date.split('T')[0]).format('ll')}
                  </i>
                </Row>
              )}
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  className="RunReport__details"
                >
                  <Row>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Driver</label>
                      <strong>
                        {run.driver
                          ? `${run.driver.first_name} ${run.driver.last_name}`
                          : '-'}
                      </strong>
                      {run.driver && (
                        <small style={{ display: 'block' }}>
                          {run.driver.user_name}
                        </small>
                      )}
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Helper</label>
                      <strong>
                        {run.helper
                          ? `${run.helper.first_name} ${run.helper.last_name}`
                          : '-'}
                      </strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Truck Number</label>
                      <strong>{run.truck_number || '-'}</strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Weather</label>
                      <strong>{run.weather || '-'}</strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Start</label>
                      <strong>
                        {run.start_time
                          ? moment(run.start_time).format('llll')
                          : '-'}
                      </strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>End</label>
                      <strong>
                        {run.end_time
                          ? moment(run.end_time).format('llll')
                          : '-'}
                      </strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Start Miles</label>
                      <strong>{run.start_miles || '-'}</strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>End Miles</label>
                      <strong>{run.end_miles || '-'}</strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Total Time</label>
                      <strong>
                        {run.start_time && run.end_time
                          ? dateDifference(run.start_time, run.end_time).total
                          : '-'}
                      </strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Total Miles</label>
                      <strong>
                        {run.end_miles && run.start_miles
                          ? run.end_miles - run.start_miles
                          : '-'}
                      </strong>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={3}>
                      <label>Optimized Miles</label>
                      <strong>
                        {optimized_miles
                          ? optimized_miles === -1
                            ? 'N/A'
                            : optimized_miles.toFixed(2)
                          : '-'}
                      </strong>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  {data && (
                    <Row>
                      <Col xs style={{ flexGrow: 0 }}>
                        <PieChart width={160} height={160}>
                          <Pie
                            data={_map(
                              _filter(data, item => !item.noTotal),
                              weight => weight
                            )}
                            innerRadius={20}
                            outerRadius={80}
                            paddingAngle={2}
                            dataKey="value"
                          >
                            {_map(data, (entry, weightType) => (
                              <Cell
                                key={weightType}
                                fill={COLORS[weightType]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </Col>
                      <Col xs>
                        <Legend data={data} />
                      </Col>
                    </Row>
                  )}
                  {!data && <NoData />}
                </Col>
              </Row>
            </Grid>
          )}
        </Segment>

        <Segment className="RunReport__Stops" loading={loading}>
          <RunStopsTable stops={_get(run, 'locations', [])} />
        </Segment>
      </Segment.Group>
    )
  }
}
