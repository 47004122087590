import _get from 'lodash/get'
import _map from 'lodash/map'
import React, { Fragment } from 'react'
import { Field, change, reduxForm } from 'redux-form'
import {
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  List,
  Message,
} from 'semantic-ui-react'
import validator from 'validator'

import FormField from '../../components/FormField'
import LocationInput from '../../components/LocationInput'
import ModalTrigger from '../../components/ModalTrigger'
import Select from '../../components/Select'
import { withAuth } from '../../connectors/auth'
import { withBinActions, withBins } from '../../connectors/bins'
import BinForm from '../Bin/Bin'
import { RadioSelectField } from '../../components/RadioSelectField'
import SelectField from '../../components/SelectField'
import { locationNPOOptions, statusOptions } from './options'
import PartnershipSection from './partnership'
import PermitSection from './permit'

import { has } from 'lodash'
import DateField from '../../components/DatePickerPureHtml'
import './styles.scss'

export const validate = args => {
  const errors = {}
  const fields = ['name', 'address', 'city', 'state', 'postal']

  if (!args.simple_location) {
    fields.push('date_in_service')

    if (typeof args.bins !== 'object') {
      errors.bins = 'invalid/type'
    }
  }

  fields.forEach(field => {
    if (!args[field] || validator.isEmpty(args[field])) {
      errors[field] = 'invalid/required'
      errors.location = 'invalid/required'
    }
  })

  return errors
}

const buildBinItem = ({ id, box_code }) => ({
  key: id,
  icon: { name: 'cube' },
  content: (
    <ModalTrigger
      label={<span className="AddLocation_BinListItem">{box_code}</span>}
      title="Editing Bin"
      form={<BinForm binId={id} />}
      scrolling={true}
    />
  ),
})

/**
 * Filters the NPOs by given markets
 *
 * @param NPOs [NPO]
 * @param markets [market]
 * @returns [market]
 */
const filterNPOByMarkets = (NPOs, markets) => {
  const marketNames = markets.map(market => market.name)

  return NPOs.filter(item => {
    return marketNames.includes(item.market)
  })
}

@withBins
@withBinActions
@withAuth
@reduxForm({
  form: 'LocationForm',
  validate,
})
export default class LocationForm extends React.PureComponent {
  state = {
    address: null,
    addOptions: [],
    marketOptions: [],
    simplified: null,
  }

  locationTypeOptions = [
    {
      id: 'bin',
      text: 'Bin',
    },
    {
      id: 'container',
      text: 'Container',
    },
  ]

  prepairMarketsOptions() {
    const { auth: { user: { markets } } } = this.props

    this.setState({
      marketOptions: markets.map(market => ({
        key: market.id,
        text: market.name,
        value: market.id,
      })),
    })
  }

  getMarkets() {
    const { auth: { user: { markets } } } = this.props
    const marketIds = markets.map(market => market.id)

    return marketIds
  }

  componentDidMount() {
    const { fetchUnassingedBinsDropdownOptions, initialValues } = this.props

    this.prepairMarketsOptions()

    if (initialValues && initialValues.market) {
      fetchUnassingedBinsDropdownOptions([initialValues.market])
    } else {
      const marketIds = this.getMarkets()
      fetchUnassingedBinsDropdownOptions(marketIds)
    }
  }

  onMarketChange(marketId) {
    const { fetchUnassingedBinsDropdownOptions } = this.props
    fetchUnassingedBinsDropdownOptions([marketId])
  }

  changeLocation = location => {
    const { changeFieldValue } = this.props

    if (!location.address) {
      return
    }

    _map(location, (value, key) => changeFieldValue(key, value))
  }

  // TODO: sanitize input
  parseBinOptions(items, includeItems) {
    if (!items) {
      return []
    }

    const isAssigned = bin => {
      const found = includeItems.find(item => item.id === bin.id)

      if (found !== undefined) {
        return true
      }

      return !bin.locationId
    }

    let filteredItems = items.filter(isAssigned)

    return filteredItems.map(item => ({
      key: item.id,
      text: item.box_code,
      value: item.id,
      id: item.id,
      box_code: item.box_code,
    }))
  }

  // TODO: sanitize input
  parseOptions(items) {
    if (!items) {
      return []
    }

    return items.map(item => ({
      key: item.id,
      text: item.box_code,
      value: item.id,
      id: item.id,
      box_code: item.box_code,
    }))
  }

  toggleSimplifiedForm() {
    const { simplified } = this.state
    const { dispatch, initialValues } = this.props
    let isSimpleLocation = false

    if (simplified === null) {
      if (has(initialValues, 'simple_location')) {
        isSimpleLocation = !initialValues.simple_location
      } else {
        isSimpleLocation = true
      }
    } else {
      isSimpleLocation = !simplified
    }

    dispatch(change('LocationForm', 'simple_location', isSimpleLocation))

    this.setState({
      simplified: isSimpleLocation,
    })
  }

  render() {
    const {
      formError,
      handleSubmit,
      options,
      withBins,
      unassignedBinsOptions,
      auth: { user: { markets } },
      initialValues,
    } = this.props
    const { addOptions, marketOptions, simplified } = this.state
    let bins = []
    let isSimpleLocation = false

    if (typeof this.props.initialValues !== 'undefined') {
      bins = this.props.initialValues.bins || []
    }

    const binOptions = this.parseBinOptions(unassignedBinsOptions.bins, bins)
    const npos = filterNPOByMarkets(locationNPOOptions, markets)

    if (has(initialValues, 'simple_location')) {
      if (simplified === null) {
        isSimpleLocation = initialValues.simple_location
      } else {
        isSimpleLocation = simplified
      }
    } else {
      isSimpleLocation = simplified || false
    }

    return (
      <Fragment>
        <div class="create_location_form_simplified_toggle">
          <Checkbox
            toggle
            checked={isSimpleLocation}
            onChange={() => this.toggleSimplifiedForm()}
          />
          <label class="text-bold">Simplified form</label>
        </div>
        {isSimpleLocation && (
          <Message color="red">
            <Message.Content>
              This form is <label class="text-bold">Only</label> used for
              creating locations that will be used as Start, End or Unloading
              locations
            </Message.Content>
          </Message>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            {options && (
              <Field
                name="name"
                label="Location Name"
                placeholder="Wal-Mart, CVS ... anything really"
                component={FormField}
                options={[
                  ...options.map(location => ({
                    key: location.id,
                    value: location.name,
                    text: location.name,
                  })),
                  ...addOptions,
                ]}
                required
                formComponent={Select}
                search
                autoFocus
                selection
                allowAdditions
                onAddItem={(e, data) => {
                  this.setState({
                    addOptions: [
                      { key: data.value, text: data.value, value: data.value },
                    ],
                  })
                }}
                onChange={(e, name) => {
                  const location = options.find(
                    location => location.name === name
                  )
                  if (!location) return
                  this.setState({ address: location.address })
                }}
              />
            )}
            {!options && (
              <Field
                name="name"
                label="Location Name"
                placeholder="Wal-Mart, CVS ... anything really"
                component={FormField}
                required
                formComponent={Form.Input}
                autoFocus
              />
            )}
            {!isSimpleLocation && (
              <Fragment>
                <Field
                  name="date_in_service"
                  label="Date in Service"
                  component={FormField}
                  onBlur={e => e.preventDefault()}
                  required
                  type="date"
                  formComponent={DateField}
                />
                <Field
                  name="status"
                  label="Status"
                  component={FormField}
                  formComponent={SelectField}
                  options={statusOptions}
                  multiple={false}
                  allowAdditions={false}
                  parse={option => _get(option, 'value')}
                />
                <Field
                  name="location_type"
                  label="Type"
                  component={FormField}
                  formComponent={RadioSelectField}
                  defaultValue={this.locationTypeOptions[0].id}
                  options={this.locationTypeOptions}
                  inline
                />
              </Fragment>
            )}
          </Form.Group>

          {!isSimpleLocation && (
            <Fragment>
              <Header>
                <h4>Location Bins</h4>
              </Header>

              {withBins && (
                <React.Fragment>
                  <Form.Group widths="equal">
                    <Field
                      name="market"
                      placeholder="Select Market"
                      label="Market"
                      component={FormField}
                      formComponent={Select}
                      options={marketOptions}
                      loading={unassignedBinsOptions.loading}
                      disabled={unassignedBinsOptions.loading}
                      onChange={(e, value) => this.onMarketChange(value)}
                      width={6}
                    />

                    <Field
                      name="bins"
                      label="Bins"
                      options={binOptions}
                      placeholder="Choose any bins"
                      component={FormField}
                      formComponent={SelectField}
                      allowAdditions={false}
                      required
                      keyProp="id"
                      textProp="box_code"
                      loading={unassignedBinsOptions.loading}
                      disabled={unassignedBinsOptions.loading}
                      width={12}
                    />
                  </Form.Group>

                  <List items={_map(bins, buildBinItem)} />

                  <ModalTrigger
                    label="Bin"
                    form={<BinForm />}
                    title="Create New Bin"
                    scrolling={true}
                    trigger={
                      <Button
                        className="AddLocation_CreateNewBinButton"
                        color="orange"
                        onClick={e => {
                          e.preventDefault()
                        }}
                      >
                        <Icon name="zip" />
                        Create New Bin
                      </Button>
                    }
                  />
                </React.Fragment>
              )}
            </Fragment>
          )}

          <Form.Group widths="equal">
            <Field
              name="location"
              label="Lookup Location"
              placeholder="Find by address, zip or anything"
              component={FormField}
              required
              address={this.state.address}
              formComponent={LocationInput}
              onChange={this.changeLocation}
            />
            <Field
              name="gis_number"
              label="GIS Number"
              placeholder="GIS Number"
              component={FormField}
              formComponent={Form.Input}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Field
              name="address"
              label="Address"
              placeholder="123 Washington St"
              component={FormField}
              required
              formComponent={Form.Input}
            />
            <Field
              name="city"
              label="City"
              placeholder="Houston"
              component={FormField}
              required
              formComponent={Form.Input}
            />
            <Field
              name="state"
              label="State"
              placeholder="TX"
              component={FormField}
              required
              formComponent={Form.Input}
            />
            <Field
              name="postal"
              label="Postal Code"
              placeholder="12345"
              component={FormField}
              required
              formComponent={Form.Input}
            />
          </Form.Group>

          {!isSimpleLocation && (
            <Fragment>
              <PartnershipSection />
              <PermitSection />
              {formError && (
                <Message
                  negative
                  header="Create Failed"
                  content={formError.message}
                />
              )}
              <Header>NPO</Header>
              <Form.Group widths="equal">
                <Field
                  name="npo_name"
                  label="Name"
                  placeholder="Enter NPO name"
                  options={npos}
                  component={FormField}
                  formComponent={Select}
                />
                <Field
                  name="npo_cost_per_lb"
                  label="Cost/LB"
                  placeholder="TX"
                  component={FormField}
                  formComponent={Form.Input}
                />
              </Form.Group>
            </Fragment>
          )}
        </Form>
      </Fragment>
    )
  }
}
