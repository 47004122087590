import React from 'react'
import _split from 'lodash/split'
import { withRouter } from 'react-router-dom'

const QuerySearchContext = React.createContext('')

const toURL = (url, values) => {
  const search = new URLSearchParams(values)
  return `${url}?${search.toString()}`
}

const treatParam = ac => (value, name) => {
  if (name === 'routes') ac[name] = _split(value, ',')
  else ac[name] = value
}

export class QuerySearchProvider extends React.Component {
  state = {
    value: '',
    update: values => this.updateSearch(values),
  }

  updateSearch = values => {
    this.setState(values)
  }

  render() {
    return (
      <QuerySearchContext.Provider value={this.state}>
        {this.props.children}
      </QuerySearchContext.Provider>
    )
  }
}

const WithQuerySearch = Component => {
  class WrappedComponent extends React.PureComponent {
    updateSearch = update => values => {
      const { match, history } = this.props
      history.push(toURL(match.url, values))

      update(values)
    }

    render() {
      const { history } = this.props
      return (
        <QuerySearchContext.Consumer>
          {({ value, update }) => {
            const search = new URLSearchParams(history.location.search)
            const params = {}

            search.forEach(treatParam(params))

            return (
              <Component
                search={params}
                {...this.props}
                updateSearch={this.updateSearch(update)}
              />
            )
          }}
        </QuerySearchContext.Consumer>
      )
    }
  }

  return withRouter(WrappedComponent)
}

export default WithQuerySearch
