import React from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { Route, Switch } from 'react-router-dom'

import Header from './Header'

import ReportsPreviousYearComparisonIndex from '../../views/ReportsPreviousYearComparison'
import './styles.scss'

export default class ReportsPreviousYearComparisonLayout extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <Switch>
          <Route component={ReportsPreviousYearComparisonIndex} />
        </Switch>
      </Grid>
    )
  }
}
