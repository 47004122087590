import React from 'react'
import _isArray from 'lodash/isArray'

import { WithPartnerships } from '../../connectors/partnerships'

import { Button } from 'semantic-ui-react'
import SelectField from '../SelectField'
import PartnershipForm from '../../forms/Partnership'
import ModalTrigger from '../ModalTrigger'

import './styles.scss'

@WithPartnerships
export default class PartnershipInput extends React.PureComponent {
  submitedPartnetship = submitedPartnetship => {
    const { onChange } = this.props
    return onChange(submitedPartnetship)
  }

  render() {
    const { partnerships, ...props } = this.props
    const newOption = {
      id: 'create-new',
      children: (
        <ModalTrigger
          title="Creating new partnership"
          trigger={<Button icon="add" fluid content="Create new" />}
          form={<PartnershipForm onSubmit={this.submitedPartnetship} />}
        />
      ),
      selectable: false,
    }

    const emptyOption = {
      id: 'empty',
      selectable: false,
    }

    return (
      <SelectField
        {...props}
        options={
          _isArray(partnerships)
            ? [emptyOption, ...partnerships, newOption]
            : [emptyOption, newOption]
        }
        multiple={false}
        allowAdditions={false}
        keyProp="id"
        textProp="fullName"
      />
    )
  }
}
