import _groupBy from 'lodash/groupBy'

export const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const getTotal = (data, ...types) => {
  if (!data) {
    return 0
  }
  return types.reduce((ret, type) => {
    return ret + data[type]
  }, 0)
}

export const groupByDay = (data, totals) => {
  const groupedData = _groupBy(data, x => x.name)
  const sellable = getTotal(totals, 'cloth', 'misc')
  days.forEach(day => {
    if (!groupedData[day]) {
      groupedData[day] = []
    }
  })

  return {
    stats: { ...totals, sellable },
    averages: days.map(name => ({
      name,
      count: groupedData[name].length,
      ...groupedData[name].reduce(
        (ret, item) => {
          ret.cloth += Math.round(item.cloth / groupedData[name].length)
          ret.misc += Math.round(item.misc / groupedData[name].length)
          ret.trash += Math.round(item.trash / groupedData[name].length)
          return ret
        },
        {
          cloth: 0,
          misc: 0,
          trash: 0,
        }
      ),
    })),
    totals: days.map(name => ({
      name,
      count: groupedData[name].length,
      ...groupedData[name].reduce(
        (ret, item) => {
          ret.cloth += item.cloth
          ret.misc += item.misc
          ret.trash += item.trash
          return ret
        },
        {
          cloth: 0,
          misc: 0,
          trash: 0,
        }
      ),
    })),
  }
}
