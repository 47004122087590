import gql from 'graphql-tag'

export const routeGoals = `
    id
    name
    goals
    actuals
`

export const marketGoals = `
    id
    market_goals
    routes {
      ${routeGoals}
    }
`

export const marketGoalsActuals = `
    year
    market_goals
    routes
`

export const MarketActualVsGoalReport = `
  market
  report {
    ${marketGoalsActuals}
  }
`

export const previousYearComparisonModel = `
    id
    yearReports {
      ${marketGoalsActuals}
    }
`

export const goals = gql`
  query($markets: [String], $year: String!) {
    goals(markets: $markets, year: $year) {
      ${marketGoals}
    }
  }
`

export const actualVsGoal = gql`
  query($markets: [String]!, $year: String!) {
    actualVsGoal(markets: $markets, year: $year) {
      ${MarketActualVsGoalReport}
    }
  }
`

export const previousYearComparison = gql`
  query($marketId: String!, $year: String!) {
    previousYearComparison(marketId: $marketId, year: $year) {
      ${previousYearComparisonModel}
    }
  }
`
