const config = {
	firebase: {
		apiKey: "AIzaSyDNTsht0qfcfMifGD0Dh1ZL74DIvwEmCoM",
		authDomain: "http://localhost:9099",
		databaseURL: "https://atrs-dev-prod-clone.firebaseio.com",
		projectId: "atrs-dev",
		storageBucket: "atrs-dev.appspot.com",
		messagingSenderId: "57695693509",
		appId: "1:57695693509:web:6ea7eee0144acd6bbb1eac"
	}
}

const _APP_ENV = process.env.REACT_APP_ENV

config.env = _APP_ENV

if(_APP_ENV == "production") {
	config.firebase = {
		apiKey: "AIzaSyDDzvnMzTBVYGH31bOESRx3eXICxkvDmg0",
		authDomain: "atrs-prod-server.firebaseapp.com",
		databaseURL: "https://atrs-prod-server.firebaseio.com",
		projectId: "atrs-prod-server",
		storageBucket: "atrs-prod-server.appspot.com",
		messagingSenderId: "321753909642",
		appId: "1:321753909642:web:99bb2fa8db766196326073"
	}
}

if(_APP_ENV == "stage") {
	config.firebase = {
		apiKey: "AIzaSyDjNU50v3TmaFh0LUOZcks9Sp0lXk2c-B0",
		authDomain: "atrs-stag-server.firebaseapp.com",
		databaseURL: "https://atrs-stag-server-prod-clone.firebaseio.com",
		projectId: "atrs-stag-server",
		storageBucket: "atrs-stag-server.appspot.com",
		messagingSenderId: "961433087166",
		appId: "1:961433087166:web:907b9095be4da397cd82ef"
	}
}

if(_APP_ENV == "development") {
	config.firebase = {
		apiKey: "AIzaSyDNTsht0qfcfMifGD0Dh1ZL74DIvwEmCoM",
		authDomain: "atrs-dev.firebaseapp.com",
		databaseURL: "https://atrs-dev-prod-clone.firebaseio.com",
		projectId: "atrs-dev",
		storageBucket: "atrs-dev.appspot.com",
		messagingSenderId: "57695693509",
		appId: "1:57695693509:web:6ea7eee0144acd6bbb1eac"
	}
}

if(_APP_ENV == "local") {
	config.firebase = {
		apiKey: "AIzaSyDNTsht0qfcfMifGD0Dh1ZL74DIvwEmCoM",
		authDomain: process.env.REACT_APP_AUTH_URI || "http://localhost:9099",
		databaseURL: "https://atrs-dev-prod-clone.firebaseio.com",
		projectId: "atrs-dev",
		storageBucket: "atrs-dev.appspot.com",
		messagingSenderId: "57695693509"
	}
}

window.env = _APP_ENV

module.exports = config