import apolloClient from '../api/apolloClient'
import {
  activateBin as activateBinMutation,
  bins as binsQuery,
  createBin as createBinMutation,
  deactivateBin as deactivateBinMutation,
  bin as getBinById,
  getMissingBins as getMissingBinsQuery,
  updateBin as updateBinMutation,
} from '../api/Query/bins'
import _ from 'lodash'

import { trigger, triggerError } from '../actions'

export const _BINS_LOADING = 'BINS_LOADING'
export const _BINS_FETCH = 'BINS_FETCH'
export const _BIN_FETCH = 'BIN_FETCH'
export const _BINS_ERROR = 'BINS_ERROR'
export const _BINS_SEARCH = 'BINS_SEARCH'
export const _BIN_ADDED = '_BIN_ADDED'
export const _BIN_DEACTIVATE = '_BIN_DEACTIVATE'
export const _BIN_ACTIVATE = '_BIN_ACTIVATE'
export const _BIN_SET_FILTER_MARKET = '_BIN_SET_FILTER_MARKET'
export const _BIN_SET_FILTER_ROUTE = '_BIN_SET_FILTER_ROUTE'
export const _BIN_SET_FILTER_STATUS = '_BIN_SET_FILTER_STATUS'
export const _BIN_RESET_FILTER = '_BIN_RESET_FILTER'
export const _BINS_START_FETCH_UNASSIGNED_BINS_OPTIONS =
  'BINS_START_FETCH_UNASSIGNED_BINS_OPTIONS'
export const _BINS_END_FETCH_UNASSIGNED_BINS_OPTIONS =
  'BINS_END_FETCH_UNASSIGNED_BINS_OPTIONS'

export const clearError = () => {
  return dispatch => {
    dispatch(trigger(_BINS_ERROR)(null))
  }
}

export const fetch = (deactivated = false, status = '', markets = []) => {
  return dispatch => {
    dispatch(trigger(_BINS_LOADING)())
    return apolloClient
      .query({
        query: binsQuery,
        variables: {
          deactivated,
          status,
          markets,
        },
        fetchPolicy: 'network-only',
      })
      .then(res => res.data.bins)
      .then(trigger(_BINS_FETCH))
      .catch(triggerError(_BINS_ERROR, 'Could not fetch Bins.'))
      .then(dispatch)
  }
}

export const fetchUnassingedBinsDropdownOptions = (markets = []) => {
  return dispatch => {
    dispatch(trigger(_BINS_START_FETCH_UNASSIGNED_BINS_OPTIONS)())
    return apolloClient
      .query({
        query: binsQuery,
        variables: {
          deactivated: false,
          status: 'unassigned',
          markets,
        },
        fetchPolicy: 'network-only',
      })
      .then(res => res.data.bins)
      .then(trigger(_BINS_END_FETCH_UNASSIGNED_BINS_OPTIONS))
      .catch(triggerError(_BINS_ERROR, 'Could not fetch Bins.'))
      .then(dispatch)
  }
}

export const getBin = id => dispatch => {
  return apolloClient
    .query({
      query: getBinById,
      variables: { id },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.binData)
    .then(trigger(_BIN_FETCH))
    .then(dispatch)
}

export const search = text => {
  return dispatch => {
    return dispatch(trigger(_BINS_SEARCH)(text))
  }
}

export const filterByMarket = market => {
  return dispatch => {
    return dispatch(trigger(_BIN_SET_FILTER_MARKET)(market))
  }
}

export const filterByRoute = route => {
  return dispatch => {
    return dispatch(trigger(_BIN_SET_FILTER_ROUTE)(route))
  }
}

export const setStatusFilter = status => {
  return dispatch => {
    return dispatch(trigger(_BIN_SET_FILTER_STATUS)(status))
  }
}

export const resetFilters = filters => {
  return dispatch => {
    return dispatch(trigger(_BIN_RESET_FILTER)(filters))
  }
}

export const addBin = bin => {
  return dispatch => {
    dispatch(trigger(_BINS_LOADING)())
    return apolloClient
      .mutate({
        mutation: createBinMutation,
        variables: { bin },
        refetchQueries: [
          {
            query: binsQuery,
            variables: { deactivated: bin.deactivated },
          },
          {
            query: binsQuery,
            variables: { deactivated: !bin.deactivated },
          },
        ],
      })
      .then(res => {
        return res.data.createBin
      })
      .then(trigger(_BIN_ADDED))
      .catch(trigger(_BINS_ERROR))
      .then(dispatch)
  }
}

const updateCollection = store => (query, action) => {
  try {
    const cached = store.readQuery(query)
    action(cached)(cached)
    store.writeQuery({ ...query, data: cached })
  } catch (err) {}
}

export const updateBin = bin => {
  if (!bin.id) {
    return addBin(bin)
  }

  return dispatch => {
    dispatch(trigger(_BINS_LOADING)())
    return apolloClient
      .mutate({
        mutation: updateBinMutation,
        variables: { bin },
        update: (store, { data: { updateBin } }) => {
          const hiddenBinsQ = {
            query: binsQuery,
            variables: { deactivated: true },
          }
          const visibleBinsQ = {
            query: binsQuery,
            variables: { deactivated: false },
          }
          const addBin = collection => {
            const bin = _.find(collection.bins, { id: updateBin.id }) || {}
            if (!bin.id) collection.allBin.unshift(bin)
            _.merge(bin, updateBin)
          }
          const removeBin = collection =>
            (collection.bins = collection.bins.filter(
              bin => bin.id !== updateBin.id
            ))
          const update = updateCollection(store)

          update(
            visibleBinsQ,
            _ => (updateBin.deactivated ? removeBin : addBin)
          )
          update(hiddenBinsQ, _ => (updateBin.deactivated ? addBin : removeBin))
        },
      })
      .then(res => {
        return res.data.updateBin
      })
      .then(trigger(_BIN_ADDED))
      .catch(trigger(_BINS_ERROR))
      .then(dispatch)
  }
}

export const deactivate = binId => {
  return dispatch => {
    dispatch(trigger(_BINS_LOADING)())
    return apolloClient
      .mutate({ mutation: deactivateBinMutation, variables: { binId } })
      .then(res => {
        if (res.data && res.data.deactivateBin) return binId
        else throw new Error('Could not deactivate Bin.')
      })
      .then(trigger(_BIN_DEACTIVATE))
      .catch(triggerError(_BINS_ERROR, `Could not deactivate Bin ${binId}.`))
      .then(dispatch)
  }
}

export const activate = binId => {
  return dispatch => {
    dispatch(trigger(_BINS_LOADING)())
    return apolloClient
      .mutate({ mutation: activateBinMutation, variables: { binId } })
      .then(res => {
        if (res.data && res.data.activateBin) return binId
        else throw new Error('Could not activate Bin.')
      })
      .then(trigger(_BIN_ACTIVATE))
      .catch(triggerError(_BINS_ERROR, `Could not activate Bin ${binId}.`))
      .then(dispatch)
  }
}

export const getMissingBins = markets => dispatch =>
  apolloClient.query({
    query: getMissingBinsQuery,
    variables: {
      markets,
    },
    fetchPolicy: 'network-only',
  })
