import React from 'react'
import { Button, Dropdown, Modal } from 'semantic-ui-react'

export default class ModalTrigger extends React.PureComponent {
  static defaultProps = {
    onClose: () => {},
    onCreate: () => {},
  }

  state = {
    open: false,
    buttons: null,
  }

  open = e => {
    e.preventDefault()
    this.setState({ open: true })
  }
  close = () => {
    const { onClose } = this.props
    this.setState({ open: false })

    onClose()
  }

  renderButtons = buttons => this.setState({ buttons })

  componentWillReceiveProps(nextProps) {
    const { open } = nextProps
    if (typeof open === 'boolean' && open !== this.state.open)
      this.setState({ open })
  }
  componentDidMount() {
    const { open } = this.props
    if (typeof open === 'boolean' && open !== this.state.open)
      this.setState({ open })
  }
  render() {
    const {
      label,
      createLabel = 'Create',
      title,
      form,
      showCancel = true,
      onCreate,
      trigger,
      description,
      scrolling,
      ...props
    } = this.props
    const { open, buttons } = this.state

    return (
      <Modal
        {...props}
        closeOnRootNodeClick={false}
        trigger={
          trigger ? (
            React.cloneElement(trigger, { onClick: this.open })
          ) : (
            <Dropdown.Item onClick={this.open}>{label}</Dropdown.Item>
          )
        }
        open={open}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content scrolling={scrolling ? scrolling : undefined}>
          {form &&
            React.cloneElement(form, {
              closeModal: this.close,
              onCreate: this.onCreate,
              renderButtons: this.renderButtons,
              ...form.props,
            })}
          {description && description}
        </Modal.Content>
        {buttons !== false && (
          <Modal.Actions>
            {buttons}
            {!buttons &&
              showCancel && (
                <Button negative onClick={this.close}>
                  Cancel
                </Button>
              )}
            {!buttons && (
              <Button
                positive
                onClick={() => {
                  this.close()
                  onCreate()
                }}
              >
                {createLabel}
              </Button>
            )}
          </Modal.Actions>
        )}
      </Modal>
    )
  }
}
