import React from 'react'
import 'url-search-params-polyfill'
import { Route } from 'react-router-dom'

import { Grid, Row, Col } from 'react-flexbox-grid'
import { Checkbox } from 'semantic-ui-react'

import {
  withMaintenanceRequestActions,
  withMaintenanceRequests,
} from '../../../connectors/maintenanceRequests'

import ModalTrigger from '../../../components/ModalTrigger'
import Confirmation from '../../../components/Confirmation'
import Loading from '../../../components/Loading'

import MaintenanceRequestGrid from '../../../components/BinManagement/MaintenanceRequestGrid'
import MaintenanceRequestForm from '../../../forms/Bin/MaintenanceRequest'

import Header from './Header'

@withMaintenanceRequestActions
@withMaintenanceRequests
class MaintenanceRequestsLayout extends React.PureComponent {
  state = {
    resolvedMaintenanceRequests: false,
  }

  componentDidMount() {
    const { fetch } = this.props
    fetch()
  }

  handleToggleChange = (_e, { checked }) => {
    const { fetch } = this.props
    const resolvedMaintenanceRequests = checked
    this.setState({ resolvedMaintenanceRequests })

    if (resolvedMaintenanceRequests) {
      fetch('resolved')
    } else {
      fetch('new')
    }
  }

  render() {
    const {
      loadingMaintenanceRequests,
      maintenanceRequests,
      fetch,
      updateMaintenanceRequest,
    } = this.props

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header
              children={
                <Checkbox
                  toggle
                  label="Show resolved Maintenance Requests"
                  onChange={this.handleToggleChange}
                />
              }
            />
          </Col>
        </Row>

        <Route
          path="/app/maintenance-requests"
          render={() => [
            loadingMaintenanceRequests && (
              <Loading key="loadingMaintenanceRequests" height={300} />
            ),
            !loadingMaintenanceRequests && (
              <MaintenanceRequestGrid
                maintenanceRequests={maintenanceRequests}
                key="maintenanceRequestGrid"
              />
            ),
          ]}
        />

        <Route
          path="/app/maintenance-requests/:maintenanceRequestId?"
          render={({
            match: { params: { maintenanceRequestId }, url },
            location: { search },
            history,
          }) => {
            const query = new URLSearchParams(search)

            return (
              <div>
                <ModalTrigger
                  open={query.get('view') ? true : false}
                  title="Viewing Maintenance Request"
                  form={
                    <MaintenanceRequestForm
                      maintenanceRequestId={maintenanceRequestId}
                      viewMode={true}
                    />
                  }
                  scrolling={true}
                  onClose={() => {
                    history.push(`/app/maintenance-requests`)
                  }}
                />

                <ModalTrigger
                  open={query.get('edit') ? true : false}
                  title="Editing Maintenance Request"
                  form={
                    <MaintenanceRequestForm
                      maintenanceRequestId={maintenanceRequestId}
                    />
                  }
                  scrolling={true}
                  onClose={() => {
                    history.push(`/app/maintenance-requests`)
                  }}
                />

                <Confirmation
                  open={query.get('status') ? true : false}
                  title="Resolving Maintenance Request"
                  createLabel="Resolve"
                  onConfirm={() => {
                    history.replace(url)

                    const maintenanceRequest = maintenanceRequests.find(
                      maintenanceRequest =>
                        maintenanceRequest.id === maintenanceRequestId
                    )
                    const maintenanceRequestData = {
                      id: maintenanceRequest.id,
                      binId: maintenanceRequest.binId,
                      date: maintenanceRequest.date,
                      notes: maintenanceRequest.notes,
                      other: maintenanceRequest.other,
                      photos: maintenanceRequest.photos,
                      reasons_back_side: maintenanceRequest.reasons_back_side,
                      reasons_front_side: maintenanceRequest.reasons_front_side,
                      reasons_left_side: maintenanceRequest.reasons_left_side,
                      reasons_right_side: maintenanceRequest.reasons_right_side,
                      reasons_roof: maintenanceRequest.reasons_roof,
                      status: 'resolved',
                      userId: maintenanceRequest.userId,
                    }

                    updateMaintenanceRequest(maintenanceRequestData)

                    fetch()
                  }}
                  onClose={() => {
                    history.replace(url)
                  }}
                  message="Are you sure to resolve this Maintenance Request?"
                />
              </div>
            )
          }}
        />
      </Grid>
    )
  }
}

export default MaintenanceRequestsLayout
