import axios from 'axios'
import FormField from '../../../components/FormField'
import MapMarker from '../../../components/MapMarker'
import Select from '../../../components/Select'
import config from '../../../config'
import { withForm } from '../../../connectors/forms'
import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Header, Icon, Message } from 'semantic-ui-react'
import validator from 'validator'
import './styles.scss'

import {
  binChuteOptions,
  binManufactureOptions,
  binMaterialOptions,
  binSizeOptions,
} from '../../../forms/Bin/options'
import DateField from '../../../components/DatePickerPureHtml'

export const validate = values => {
  const errors = {}

  const fields = ['serial_number', 'material', 'size', 'chute', 'manufacture']

  fields.forEach(field => {
    if (!values[field] || validator.isEmpty(values[field])) {
      errors[field] = 'invalid/required'
    }
  })

  if (_isEmpty(values.date_in_service)) {
    errors.date_in_service = 'invalid/required'
  }

  return errors
}

@withForm('BinForm')
@reduxForm({
  form: 'BinForm',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
})
export default class BinForm extends React.Component {
  constructor(props) {
    super(props)
    let images = []

    if (
      !_isEmpty(props.initialValues) &&
      !_isEmpty(props.initialValues.photos)
    ) {
      images = props.initialValues.photos
    }

    this.state = {
      images,
    }
  }

  addImage(image) {
    const images = [...this.state.images, image]

    this.setState(state => ({
      ...state,
      images,
    }))

    this.props.change('photos', images)
  }

  removeImage(index) {
    let images = this.state.images
    images.splice(index, 1)

    this.setState(state => ({
      ...state,
      images,
    }))

    this.props.change('photos', images)
  }

  pickImageFile = () => {
    let input = document.createElement('input')
    input.type = 'file'
    input.onchange = _ => {
      let files = Array.from(input.files)
      let file = files[0] || undefined

      if (file) {
        const data = new FormData()
        data.append('file', file)
        const URL = config.FUNCTIONS_URI + '/uploadFile'

        axios.post(URL, data, {}).then(res => {
          this.addImage(res.data.url)
        })
      }
    }
    input.click()
  }

  render() {
    const {
      formError,
      handleSubmit,
      selector,
      viewMode,
      initialValues,
      markets,
    } = this.props
    const { images } = this.state
    let hasRoutes = false

    if (initialValues && initialValues.routes) {
      if (initialValues.routes.length > 0) hasRoutes = true
    }

    const pickedMaterial = selector('material')
    let sizeOptionsBasedOnPickedMaterial = []

    if (!_isEmpty(pickedMaterial)) {
      sizeOptionsBasedOnPickedMaterial = [...binSizeOptions[pickedMaterial]]
    }

    const isAssigned =
      !_isEmpty(initialValues) && !_isEmpty(initialValues.locationId)

    const name =
      !_isEmpty(initialValues) && !!initialValues.location
        ? initialValues.location.name
        : ''
    const address =
      !_isEmpty(initialValues) && !!initialValues.location
        ? initialValues.location.address
        : ''
    const postal =
      !_isEmpty(initialValues) && !!initialValues.location
        ? initialValues.location.postal
        : ''
    const city =
      !_isEmpty(initialValues) && !!initialValues.location
        ? initialValues.location.city
        : ''
    const state =
      !_isEmpty(initialValues) && !!initialValues.location
        ? initialValues.location.state
        : ''
    const latitude =
      !_isEmpty(initialValues) && !!initialValues.location
        ? initialValues.location.latitude
        : ''
    const longitude =
      !_isEmpty(initialValues) && !!initialValues.location
        ? initialValues.location.longitude
        : ''

    const locationMarkers = [
      {
        latitude: +latitude,
        longitude: +longitude,
        role: 'selected',
      },
    ]

    const locationLink = `https://www.google.com/maps/search/?api=1&query=${+latitude},${+longitude}`

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Field
            name="serial_number"
            label="Bin Serial #"
            placeholder="Bin Serial #"
            component={FormField}
            required
            formComponent={Form.Input}
            disabled={viewMode}
          />
          <Field
            name="date_in_service"
            label="Date In Service"
            component={FormField}
            onBlur={e => e.preventDefault()}
            required
            type="date"
            formComponent={DateField}
            disabled={viewMode}
          />
          <Field
            name="market"
            placeholder="Select Market"
            label="Market"
            component={FormField}
            formComponent={Select}
            options={markets}
            disabled={viewMode || hasRoutes}
          />
          <Field
            name="moleiot_mac"
            label="Mole IoT MAC"
            placeholder="Mole IoT MAC"
            component={FormField}
            formComponent={Form.Input}
            disabled={viewMode}
          />
        </Form.Group>

        <Header>Photos Upload</Header>
        <Form.Group className="FormGroup__fix">
          {images.length < 1 && (
            <div>
              <h5>There are no photos uploaded yet.</h5>
            </div>
          )}

          {images.map((image, index) => {
            return (
              <div key={`photo_${index}`} className="Bin__ImageContainer">
                <img
                  className="Bin__ImageContainer__Image"
                  src={image}
                  alt="Bin Evidence"
                />
                {!viewMode && (
                  <Button
                    className="Bin__ImageContainer__RemoveButton"
                    color="red"
                    onClick={e => {
                      e.preventDefault()
                      this.removeImage(index)
                    }}
                  >
                    X
                  </Button>
                )}
              </div>
            )
          })}
        </Form.Group>

        {!viewMode && (
          <Button
            icon
            color="orange"
            onClick={e => {
              e.preventDefault()
              this.pickImageFile()
            }}
          >
            <Icon name="plus" /> Add photo
          </Button>
        )}

        <Header>Bin Style</Header>
        <Form.Group widths="equal">
          <Field
            name="material"
            label="Material"
            placeholder="Material"
            options={binMaterialOptions}
            component={FormField}
            required
            formComponent={Select}
            disabled={viewMode}
          />
          <Field
            name="size"
            label="Size"
            placeholder="Size"
            options={sizeOptionsBasedOnPickedMaterial}
            component={FormField}
            required
            formComponent={Select}
            disabled={viewMode}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="chute"
            label="Chute"
            placeholder="Chute"
            options={binChuteOptions}
            component={FormField}
            required
            formComponent={Select}
            disabled={viewMode}
          />
          <Field
            name="manufacture"
            label="Manufacture"
            placeholder="Manufacture"
            options={binManufactureOptions}
            component={FormField}
            required
            formComponent={Select}
            disabled={viewMode}
          />
        </Form.Group>

        {isAssigned && (
          <div>
            <Header>Bin Location</Header>
            <p>Name: {name}</p>
            <p>Address: {address}</p>
            <p>Postal: {postal}</p>
            <p>City: {city}</p>
            <p>State: {state}</p>

            <Header>Bin Map</Header>
            <MapMarker locations={locationMarkers} />
            <Button icon href={locationLink} target="_blank">
              <Icon name="marker" />
              {+latitude} {+longitude}
            </Button>
          </div>
        )}

        <Header />
        <Form.Group widths="equal">
          <Field
            id="BinForm_deactivated"
            name="deactivated"
            component={FormField}
            label="Deactivated"
            formComponent={Form.Checkbox}
            disabled={viewMode}
          />
        </Form.Group>

        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
