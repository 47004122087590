import React from 'react'
import PropTypes from 'prop-types'

import { Statistic, Icon } from 'semantic-ui-react'
import { Circle } from 'rc-progress'
import './styles.scss'

const CircleMetric = ({ title, total, label, color, percentage, flag }) => (
  <Statistic className="CircleMetric__container">
    <Circle
      percent={percentage.toFixed(2)}
      strokeColor={color}
      strokeWidth="6"
      trailWidth="6"
    />
    <Statistic.Label>
      {label}
      {flag && <Icon {...flag} />}
    </Statistic.Label>
    <Statistic.Value>{!isNaN(total.props.value) ? total : '-'}</Statistic.Value>

    <h2 className="CircleMetric__caption">{`${Math.round(percentage)}%`}</h2>
  </Statistic>
)
CircleMetric.defaultProps = {
  title: 'value',
  total: '524,187 Lbs',
  label: 'Cloth',
  color: 'red',
  percentage: 38,
  flag: null,
}
CircleMetric.propTypes = {
  title: PropTypes.string,
  total: PropTypes.any,
  label: PropTypes.string,
  color: PropTypes.string,
  percentage: PropTypes.number,
  flag: PropTypes.object,
}
export default CircleMetric
