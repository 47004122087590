import ContentHeader from '../../components/ContentHeader'
import React from 'react'

const Header = () => {
  return (
    <ContentHeader title="Where are we at" showBackButton showPrintButton />
  )
}

export default Header
