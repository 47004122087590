import React from 'react'
import _get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { WithRoutes } from '../../connectors/markets'

import MarketsDropdown from '../../components/MenuDropdown/Markets'
import RoutesDropdown from '../../components/MenuDropdown/Routes'

import ContentHeader from '../../components/ContentHeader'
import WithQuerySearch from '../../connectors/querySearch'

import './styles.scss'

@WithQuerySearch
@WithRoutes
export class RoutesDropDown extends React.Component {
  updateSearch = (av, { value }) => {
    const { search, updateSearch } = this.props
    updateSearch({
      ...search,
      routes: value,
    })
  }

  render() {
    const { routes, search } = this.props

    return (
      <RoutesDropdown
        placeholder="Filter Routes"
        multiple
        selection
        active
        routes={routes}
        onChange={this.updateSearch}
        value={search.routes}
      />
    )
  }
}

@withRouter
export default class Header extends React.Component {
  state = {
    market: null,
    route: null,
  }

  onSelectMarket = market => {
    const { history } = this.props

    if (!market) {
      return history.push(`/app/market`)
    }
    history.push(`/app/market/${market.id}`)
  }

  render() {
    const { match: { params } } = this.props
    const marketId = _get(params, 'marketId')

    return (
      <ContentHeader title="Market" showBackButton>
        <MarketsDropdown
          showAll={false}
          selected={marketId}
          onSelect={this.onSelectMarket}
          menu
        />

        {marketId && <RoutesDropDown label="Routes" marketId={marketId} />}
      </ContentHeader>
    )
  }
}
