import React from 'react'
import PropTypes from 'prop-types'
import ModalTrigger from '../ModalTrigger'
import { Button, Icon } from 'semantic-ui-react'
import _pick from 'lodash/pick'
import _isEqual from 'lodash/isEqual'

import { withRoutes } from '../../connectors/routes'
import { withMarkets } from '../../connectors/markets'
import ScheduleRunForm from '../../forms/ScheduleRun'

const DEFAULT_FIELDS = ['market', 'route', 'driver']

const invalidLocation = location =>
  !Array.isArray(location.bins) || !location.bins.length

const findWarningElId = route => {
  const loc = route.locations.find(invalidLocation)
  if (!loc) {
    return null
  }
  return `warning-${loc.id}`
}

const scrollToWarning = route => {
  const el = document.getElementById(findWarningElId(route))
  return el ? _ => el.scrollIntoView() : _ => {}
}

const Modal = ({
  title,
  form,
  description,
  showCancel,
  createLabel,
  onCreate,
  disabled,
  className,
  icon,
  button,
}) => (
  <ModalTrigger
    title={title}
    trigger={
      <Button
        color="orange"
        disabled={disabled}
        className={className}
        {...button}
      >
        <Icon name="truck" {...icon} />
        Schedule Run
      </Button>
    }
    form={form}
    showCancel={showCancel}
    createLabel={createLabel}
    description={description}
    onCreate={onCreate}
  />
)

@withRoutes
@withMarkets
export default class ScheduleRun extends React.PureComponent {
  static propTypes = {
    market: PropTypes.string,
    route: PropTypes.string,
    driver: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onSchedule: PropTypes.func,
  }

  state = {
    initialValues: {},
  }

  static defaultProps = {
    market: null,
    route: null,
    driver: null,
    disabled: false,
    className: null,
    onSchedule: () => {},
  }

  componentWillReceiveProps(nextProps) {
    const { current } = this.props
    const prev = _pick(this.props, DEFAULT_FIELDS)
    const next = _pick(nextProps, DEFAULT_FIELDS)

    if (!next.market && !!current) {
      next.market = current.id
    }

    if (!_isEqual(prev, next)) {
      this.setState({
        initialValues: next,
      })
    }
  }

  componentDidMount() {
    const { current } = this.props
    const initialValues = _pick(this.props, DEFAULT_FIELDS)

    if (!initialValues.market && !!current) {
      initialValues.market = current.id
    }

    this.setState({ initialValues })
  }

  render() {
    const { onSchedule, route: routeId, routes, ...otherProps } = this.props
    const { initialValues } = this.state

    if (routeId) {
      const route =
        routes.routes && routes.routes.find(route => route.id === routeId)
      if (route && !route.schedulable)
        return (
          <Modal
            {...otherProps}
            title="Warning Schedule Run"
            showCancel={true}
            createLabel={'Understood'}
            description={<p>All locations must have bins associated</p>}
            onCreate={scrollToWarning(route)}
          />
        )
    }

    return (
      <Modal
        {...otherProps}
        title="Schedule Run"
        form={
          <ScheduleRunForm
            onSchedule={onSchedule}
            initialValues={initialValues}
          />
        }
      />
    )
  }
}
