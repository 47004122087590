import './styles.scss'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _startCase from 'lodash/startCase'
import React from 'react'

import ReportCard from '../../components/ReportCard'
import HeaderLayout from '../../layouts/Locations/Header'
import { FormattedNumber } from 'react-intl'
import {
  Button,
  Card,
  Divider,
  Grid,
  Header,
  Icon,
  Segment,
  Statistic,
} from 'semantic-ui-react'
import {
  WithLocationBinsEvents,
  WithLocationEstimatedActualReports,
  WithLocationRoutes,
  WithLocations,
} from '../../connectors/locations'
import DailyWeights from './DailyWeights'
import LocationInformation from './LocationInformation'

import BinsHistory from '../../components/BinHistory/BinsHistory'
import MaintenanceRequestsHistory from '../../components/BinHistory/MaintenanceRequestsHistory'
import PoliceReportsHistory from '../../components/BinHistory/PoliceReportsHistory'

import {
  WithLocationBinLocationsHistory,
  WithLocationMaintenanceRequestsHistory,
  WithLocationPoliceReportsHistory,
} from '../../connectors/locations'
import moment from 'moment'
import { MonthRangePicker } from '../../components/MonthRangePicker'
import NumericTooltip from '../../components/NumericTooltip'
import { WithLocationActions } from '../../connectors/locations'

const buttonStyle = {
  marginBottom: '10px',
}

const WeightStatistic = ({ title, value }) => (
  <NumericTooltip value={value || 0}>
    <Statistic size="tiny" className="simple-statistic">
      <Statistic.Value>
        <Icon name="balance scale" />
        <FormattedNumber value={parseInt(value, 10)} />
      </Statistic.Value>
      <Statistic.Label>{title}</Statistic.Label>
    </Statistic>
  </NumericTooltip>
)

const ColumnReportCard = ({ value, ...props }) => (
  <Grid.Column>
    <ReportCard
      fluid
      total={<FormattedNumber value={parseInt(value, 10)} />}
      tooltip={value}
      {...props}
    />
  </Grid.Column>
)

@WithLocationActions
@WithLocationEstimatedActualReports
@WithLocationBinLocationsHistory
@WithLocationMaintenanceRequestsHistory
@WithLocationPoliceReportsHistory
class LocationView extends React.PureComponent {
  state = {
    monthlyReportIsLoading: false,
    monthlyReports: [],
  }

  onMonthRangePickerChange(start, end) {
    const { getLocationMonthlyEstimatedActualReports, location } = this.props
    const { monthlyReports } = this.state
    const initMonthlyReports = []
    const monthNumbers = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    }
    const startDateString = moment(
      `${start.year}-${monthNumbers[start.month]}-02`
    )
      .startOf('month')
      .toISOString()
    const endDateString = moment(`${end.year}-${monthNumbers[end.month]}-02`)
      .endOf('month')
      .toISOString()

    for (let i = 0; i < 4; i++) {
      initMonthlyReports.push({
        year: moment(endDateString)
          .add(i * -1, 'month')
          .year(),
        month: moment(endDateString)
          .add(i * -1, 'month')
          .format('MMM'),
        total: 0,
      })
    }

    if (monthlyReports.length)
      this.setState({
        monthlyReportIsLoading: true,
      })
    else {
      this.setState({
        monthlyReportIsLoading: true,
        monthlyReports: initMonthlyReports.reverse(),
      })
    }

    getLocationMonthlyEstimatedActualReports(
      location.id,
      startDateString,
      endDateString
    )
      .then(result => {
        this.setState({
          monthlyReports: result,
        })
      })
      .finally(() => {
        this.setState({
          monthlyReportIsLoading: false,
        })
      })
  }

  render() {
    const { location, loading, routes } = this.props
    const { monthlyReportIsLoading, monthlyReports } = this.state

    // const monthsTotals = _get(location, 'monthsTotals')
    const weeksTotals = _get(location, 'weeksTotals')
    const weekAverage = _get(location, 'weekAverage')
    const actualMonthName = _get(location, 'actualMonthName')

    const binLocationsHistory = location.binLocationsHistory || []
    const maintenanceRequestsHistory = location.maintenanceRequestsHistory || []
    const policeReportsHistory = location.policeReportsHistory || []

    return (
      <Segment padded={false} loading={loading} basic>
        <LocationInformation
          {...location}
          location={location}
          routes={routes}
        />
        <Divider hidden />

        <Card>
          <Card.Content header="Location History" />
          <Card.Content>
            <BinsHistory
              items={binLocationsHistory}
              trigger={
                <Button color="green" disabled={false} style={buttonStyle}>
                  <Icon name="cube" />
                  Bins History ({binLocationsHistory.length})
                </Button>
              }
            />

            <MaintenanceRequestsHistory
              items={maintenanceRequestsHistory}
              trigger={
                <Button color="orange" disabled={false} style={buttonStyle}>
                  <Icon name="wrench" />
                  Maintenance Requests History ({
                    maintenanceRequestsHistory.length
                  })
                </Button>
              }
            />

            <PoliceReportsHistory
              items={policeReportsHistory}
              trigger={
                <Button color="red" disabled={false} style={buttonStyle}>
                  <Icon name="taxi" />
                  Police Reports History ({policeReportsHistory.length})
                </Button>
              }
            />
          </Card.Content>
        </Card>

        <Divider hidden />
        <Card>
          <Card.Content>
            <MonthRangePicker
              variance={3}
              onChange={(start, end) =>
                this.onMonthRangePickerChange(start, end)
              }
              className="Location_Month_Range_Picker"
              disabled={monthlyReportIsLoading}
            />
          </Card.Content>
        </Card>
        <Grid columns="equal">
          {monthlyReports &&
            _map(monthlyReports, report => (
              <ColumnReportCard
                loading={monthlyReportIsLoading}
                key={`${report.month}-${report.year}`}
                title={`${report.month} ${report.year}`}
                value={report.total}
              />
            ))}
        </Grid>
        <Segment>
          <Grid columns="equal" verticalAlign="middle">
            <Grid.Column>
              <Header as="h2">{actualMonthName} Weekly</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <WeightStatistic
                title="AVG per week for month"
                value={weekAverage}
              />
            </Grid.Column>
          </Grid>
        </Segment>
        <Grid columns="equal">
          {_map(weeksTotals, (weight, keyname) => (
            <ColumnReportCard
              key={keyname}
              title={_startCase(keyname)}
              value={weight}
            />
          ))}
        </Grid>
        <DailyWeights {...location} />
      </Segment>
    )
  }
}

@WithLocations
@WithLocationBinsEvents
@WithLocationRoutes
export default class NavLayout extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <HeaderLayout {...this.props} />
        <LocationView {...this.props} />
      </React.Fragment>
    )
  }
}
