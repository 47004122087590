import _isArray from 'lodash/isArray'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _flatMap from 'lodash/flatMap'
import _isEmpty from 'lodash/isEmpty'
import _uniqueId from 'lodash/uniqueId'

import { createSelector } from 'reselect'

const _isValidTrace = trace => _isArray(trace)

const groomLocation = picker => {
  return {
    id: _uniqueId(_get(picker, 'id')),
    color: _get(picker, 'color', 'gray'),
    role: _get(picker, 'role', 'pin'),
    allocation: _get(picker, 'allocation'),
    ...picker,
  }
}

const arrangeRouteLocation = (route, location, role, allocation) => ({
  id: `${route.id}-${role}`,
  color: route.color,
  routeId: route.id,
  role,
  allocation,
  ...location,
})

const getRouteLocations = route => {
  const locations = _map(route.locations, location =>
    arrangeRouteLocation(route, location, null, location.stop_sequence)
  )

  const start_location = _get(route, 'start_location')
  const end_location = _get(route, 'end_location')
  const unloading_location = _get(route, 'unloading_location')

  if (start_location)
    locations.push(arrangeRouteLocation(route, start_location, 'start'))
  if (end_location)
    locations.push(arrangeRouteLocation(route, end_location, 'end'))
  if (unloading_location)
    locations.push(arrangeRouteLocation(route, unloading_location, 'unloading'))

  return locations
}
const arrangeRouteTrace = ({ color, trace }) => ({ color, polylines: trace })

const arrangeRoutesTraces = routes =>
  _map(_filter(routes, ({ trace }) => _isValidTrace(trace)), arrangeRouteTrace)

export const arrangeRoute = createSelector(
  state => _get(state, 'routes.routes'),
  (state, route) => route,
  (routes, routeId) => {
    const route = _find(routes, { id: routeId })

    if (route)
      return {
        traces: arrangeRoutesTraces([route]),
        locations: getRouteLocations(route),
      }

    return {}
  }
)

export const arrangeRoutes = createSelector(
  state => _get(state, 'routes'),
  state => _get(state, 'routeIds'),
  (routes, routeIds) => {
    const filterRoutes = _isEmpty(routeIds)
      ? routes
      : _filter(routes, route => _includes(routeIds, _get(route, 'id')))

    return {
      traces: arrangeRoutesTraces(filterRoutes),
      locations: _flatMap(_map(filterRoutes, getRouteLocations)),
    }
  }
)

export const arrangeLocations = createSelector(
  state => _get(state, 'locations'),
  state => _get(state, 'location'),
  (locations, location) => {
    const mergeLocations = _map(locations, groomLocation)

    if (!_isEmpty(location)) mergeLocations.push(groomLocation(location))

    return {
      locations: mergeLocations,
    }
  }
)
