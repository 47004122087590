import { isEqual } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import Picker from 'react-month-picker'
import { Button, ButtonGroup, Icon } from 'semantic-ui-react'

export class MonthRangePicker extends Component {
  state = {
    start: {
      year: 0,
      month: 0,
    },
    end: {
      year: 0,
      month: 0,
    },
    showStartMonthPicker: false,
    showEndMonthPicker: false,
  }
  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  monthNumbers = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  }

  componentDidMount() {
    const { variance } = this.props
    let rangeVariance = variance ? variance : 1

    const startDate = moment().add(-1 * rangeVariance, 'month')
    const endDate = moment()

    const start = {
      year: startDate.year(),
      month: this.months.indexOf(startDate.format('MMM')) + 1,
    }
    const end = {
      year: endDate.year(),
      month: this.months.indexOf(endDate.format('MMM')) + 1,
    }

    this.setState({
      start,
      end,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevState.start, this.state.start) ||
      !isEqual(prevState.end, this.state.end)
    )
      this.triggerOnChange()
  }

  triggerOnChange() {
    const { onChange } = this.props
    const { start, end } = this.state

    const _start = {
      year: start.year,
      month: this.months[start.month - 1],
    }
    const _end = {
      year: end.year,
      month: this.months[end.month - 1],
    }

    if (onChange) onChange(_start, _end)
  }

  handleOnStartChange(year, month) {
    const { variance } = this.props
    let rangeVariance = variance ? variance : 1
    const endDate = moment(
      `${year}-${this.monthNumbers[this.months[month - 1]]}-01`
    ).add(rangeVariance, 'month')

    const start = {
      year,
      month: month,
    }
    const end = {
      year: endDate.year(),
      month: this.months.indexOf(endDate.format('MMM')) + 1,
    }
    this.setState({
      start,
      end,
      showStartMonthPicker: false,
    })
  }

  handleOnEndChange(year, month) {
    const { variance } = this.props
    let rangeVariance = variance ? variance : 1

    const startDate = moment(
      `${year}-${this.monthNumbers[this.months[month - 1]]}-01`
    ).add(-1 * rangeVariance, 'month')

    const end = {
      year,
      month: month,
    }
    const start = {
      year: startDate.year(),
      month: this.months.indexOf(startDate.format('MMM')) + 1,
    }
    this.setState({
      start,
      end,
      showEndMonthPicker: false,
    })
  }

  moveMonth(number) {
    const { start, end } = this.state

    const startDate = moment(
      `${start.year}-${this.monthNumbers[this.months[start.month - 1]]}-01`
    ).add(number, 'month')
    const endDate = moment(
      `${end.year}-${this.monthNumbers[this.months[end.month - 1]]}-01`
    ).add(number, 'month')

    const _start = {
      year: startDate.year(),
      month: this.months.indexOf(startDate.format('MMM')) + 1,
    }
    const _end = {
      year: endDate.year(),
      month: this.months.indexOf(endDate.format('MMM')) + 1,
    }

    this.setState({
      start: _start,
      end: _end,
    })
  }

  showStartMonthPicker() {
    this.setState({
      showStartMonthPicker: true,
    })
  }

  hideStartMonthPicker() {
    this.setState({
      showStartMonthPicker: false,
    })
  }

  showEndMonthPicker() {
    this.setState({
      showEndMonthPicker: true,
    })
  }

  hideEndMonthPicker() {
    this.setState({
      showEndMonthPicker: false,
    })
  }

  render() {
    const { start, end, showStartMonthPicker, showEndMonthPicker } = this.state
    const { disabled, className } = this.props
    const minYear = 2015
    const maxYear = moment().year() + 1

    return (
      <ButtonGroup size="large" widths="5" className={className}>
        <Button
          basic
          labelPosition="left"
          icon="left chevron"
          content="Back"
          onClick={() => this.moveMonth(-1)}
          disabled={disabled}
        />
        <Picker
          years={{ min: minYear, max: maxYear }}
          value={start}
          onChange={(year, month) => this.handleOnStartChange(year, month)}
          show={showStartMonthPicker}
          lang={moment.langData()._monthsShort}
          onClickAway={() => this.hideStartMonthPicker()}
        />
        <Button
          basic
          onClick={() => this.showStartMonthPicker()}
          disabled={disabled}
        >
          <Icon name="calendar" />
          {`From: ${this.months[start.month - 1]} ${start.year}`}
        </Button>
        <Picker
          years={{ min: minYear, max: maxYear }}
          value={end}
          onChange={(year, month) => this.handleOnEndChange(year, month)}
          onClickAway={() => this.hideEndMonthPicker()}
          show={showEndMonthPicker}
          lang={moment.langData()._monthsShort}
        />
        <Button
          basic
          onClick={() => this.showEndMonthPicker()}
          disabled={disabled}
        >
          <Icon name="calendar" />
          {`To: ${this.months[end.month - 1]} ${end.year}`}
        </Button>
        <Button
          basic
          labelPosition="right"
          icon="right chevron"
          content="Forward"
          onClick={() => this.moveMonth(1)}
          disabled={disabled}
        />
      </ButtonGroup>
    )
  }
}
