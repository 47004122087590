import React from 'react'

import { Popup } from 'semantic-ui-react'
import { FormattedNumber } from 'react-intl'

const NumericTooltip = ({ value, children }) => {
  if (!value) return children
  else
    return (
      <Popup
        trigger={children}
        content={<FormattedNumber value={value} />}
        basic
      />
    )
}

export default NumericTooltip
