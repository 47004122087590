import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown, Form } from 'semantic-ui-react'

export default class DriverPicker extends React.PureComponent {
  state = {
    loading: false,
  }

  static propTypes = {
    drivers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    required: PropTypes.bool,
  }

  static defaultProps = {
    required: false,
  }

  onChange = (e, { value }) => {
    const { onChange } = this.props
    onChange(value)
  }

  render() {
    const {
      allowEmpty = false,
      emptyOptionText = '',
      drivers,
      label,
      loading,
      value,
      error,
      required,
      disabled,
    } = this.props

    const { loading: stateLoading } = this.state

    if (allowEmpty) {
      drivers.unshift({
        value: null,
        text: emptyOptionText,
      })
    }

    return (
      <Form.Field error={error} required={required}>
        <label>{label}</label>
        <Dropdown
          onChange={this.onChange}
          options={drivers}
          loading={!drivers || loading || stateLoading}
          placeholder="Choose a Driver"
          selection
          value={value}
          search={true}
          error={error}
          disabled={!!disabled}
        />
      </Form.Field>
    )
  }
}
