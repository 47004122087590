import _clone_deep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import PropTypes from 'prop-types'
import React from 'react'

import { withForm } from '../../connectors/forms'
import { withRuns } from '../../connectors/runs'

import { Button, Message } from 'semantic-ui-react'

import ModalTrigger from '../../components/ModalTrigger'
import Route from '../../views/Route'
import { CalculateRouteLengthModalError } from '../../views/Route/CalculateRouteLengthModalError'
import ScheduleRunForm from './form'

@withForm('ScheduleRunForm')
@withRuns
export default class ScheduleRun extends React.PureComponent {
  static defaultProps = {
    onSubmit: () => Promise.resolve,
    trigger: <Button>Go</Button>,
    closeModal: () => {},
    renderButtons: () => {},
    onSchedule: () => {},
	initialValues: {},
  }

  static propTypes = {
    initialValues: PropTypes.shape({
      market: PropTypes.string,
      route: PropTypes.string,
      driver: PropTypes.string,
    }),
    markets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    drivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      })
    ),
    onSchedule: PropTypes.func,
  }

//   static defaultProps = {
//     initialValues: {},
//   }

  state = {
    submitting: false,
    customize: false,
    goToCustomizeStep: false,
    routeId: null,
    showMileCalculationError: false,
    mileCalculationErrorMessage: '',
  }

  componentDidMount() {
    // Handle setting the Driver
    const { initialValues: { driver }, getDriverInfo, clearDriver } = this.props

    if (driver) {
      getDriverInfo(driver)
    } else {
      clearDriver()
    }

    this.renderButtons()
  }

  componentDidUpdate(_, prevState) {
    const { submitForm } = this.props
    const { customize, routeId, submitting, error } = this.state
    const {
      customize: prevCustomize,
      routeId: prevRouteId,
      submitting: prevSubmitting,
    } = prevState

    if (customize !== prevCustomize || routeId !== prevRouteId || error) {
      this.renderButtons()
    }

    if (customize && submitting && !prevSubmitting) {
      submitForm()
      this.renderButtons()
    }
  }

  renderButtons() {
    const { renderButtons, closeModal, submitForm } = this.props
    const { customize, submitting, error } = this.state

    return renderButtons(
      <div>
        <Button negative onClick={closeModal} disabled={submitting && !error}>
          Nevermind
        </Button>
        {!customize && (
          <Button positive onClick={submitForm} disabled={submitting}>
            Schedule
          </Button>
        )}
        {customize && (
          <Button color="green" onClick={submitForm} disabled={submitting}>
            Customize Run
          </Button>
        )}
      </div>
    )
  }

  onRouteChange = routeId => {
    this.setState({
      ...this.state,
      routeId,
    })
  }

  onCustomizeChecked = customize => {
    const { changeFieldValue } = this.props
    changeFieldValue('is_recurring', false)

    this.setState({
      ...this.state,
      customize,
    })
  }

  goToSubmitStep = () => {
    this.setState({
      ...this.state,
      goToCustomizeStep: false,
      submitting: true,
    })
  }

  setLocationsAndSubmit = locations => {
    const { changeFieldValue } = this.props
    changeFieldValue('locations', locations)

    this.goToSubmitStep()
  }

  showMileCalculationErrorModal = (show, message) => {
    this.setState({
      showMileCalculationError: !show,
      mileCalculationErrorMessage: message,
    })
  }

  onCalculateRouteLengthModalClose = () => {
    const { onSchedule, closeModal } = this.props
    const _ = { saved: true }

    closeModal()
    onSchedule(_)
  }

  scheduleRun = run => {
    const { schedule, onSchedule, closeModal } = this.props
    const { customize } = this.state
    const scheduleData = _pick(run, ['route', 'driver', 'helper'])
    const recurring = _get(run, 'recurring')
    const during = _get(run, 'recurring.during')

    if (_has(run, 'locations')) {
      const mapBins = bins => _map(bins, ({ __typename, ...bin }) => bin)

      scheduleData.locations = _map(
        _get(run, 'locations'),
        ({
          address,
          bins,
          city,
          id,
          latitude,
          longitude,
          name,
          notInOriginalRoute,
          originalRouteId,
          postal,
          state,
          stop_sequence,
        }) => ({
          location: {
            address,
            bins: mapBins(bins),
            city,
            id,
            latitude,
            longitude,
            market: _get(run, 'market'),
            name,
            postal,
            state,
            stop_sequence,
          },
          bins: mapBins(bins),
          notInOriginalRoute,
          originalRouteId,
        })
      )
    }

    if (_has(during, 'start') && _has(during, 'end')) {
      const start = _get(during, 'start')
      const end = _get(during, 'end')
      const startUtcOffset = start.utcOffset()
      const endUtcOffset = end.utcOffset()
      start.add(startUtcOffset, 'minutes')
      end.add(endUtcOffset, 'minutes')

      during.start = start.toISOString()
      during.end = end.toISOString()
      scheduleData.run_date = during.start
    } else {
      scheduleData.run_date = _get(run, 'date').toISOString()
    }

    return schedule(scheduleData, recurring, customize)
      .then(result => {
        if (result.type === 'RUN_SCHEDULED_ERROR') {
          throw new Error(result.payload.message)
        }
        return {
          result: result.payload,
          _: { saved: true },
        }
      })
      .then(({ _, result }) => {
        if (customize) {
          if (!result.data.scheduleRun.mile_calculation_result.status) {
            this.showMileCalculationErrorModal(
              result.data.scheduleRun.mile_calculation_result.status,
              result.data.scheduleRun.mile_calculation_result.message
            )
          } else {
            closeModal()
            onSchedule(_)
          }
        } else {
          closeModal()
          onSchedule(_)
        }
      })
      .catch(error => {
        this.setState({ error: error.message })
      })
  }

  goToCustomizeStep = goToStep => {
    this.setState({
      ...this.state,
      goToCustomizeStep: goToStep,
    })
  }

  onMarketChange = marketId => {
    const { getMarketData } = this.props
    getMarketData(marketId)
  }

  /**
   * Checks if the user exists in the drivers list
   * @param user User
   * @param drivers User[]
   * @returns Boolean
   */
  userExistInDrivers(user, drivers) {
    return drivers.some(item => {
      return user.id === item.id
    })
  }

  /**
   * Pushes the currecnt driver to drivers array if it does not exist
   * @param currentDriver User
   */
  pushCurrentDriverToDrivers(driver, drivers) {
    if (!this.userExistInDrivers(driver, drivers)) {
      drivers.push(driver)
    }
  }

  render() {
    const {
      customize,
      error,
      goToCustomizeStep,
      routeId,
      submitting,
      showMileCalculationError,
      mileCalculationErrorMessage,
    } = this.state
    const {
      initialValues,
      runs: { markets, routes, drivers, loading, driver },
      selector,
    } = this.props

    const helpers = _clone_deep(drivers)
    const clonedDrivers = _clone_deep(drivers)

    const values = {
      ...initialValues,
      market: initialValues.market || markets[0].id,
      recurring: {
        amount: 1,
        every: 'days',
      },
    }

    if (error)
      return (
        <Message error header="Error" key="userError">
          {' '}
          {error}
        </Message>
      )

    if (driver && clonedDrivers) {
      this.pushCurrentDriverToDrivers(driver, clonedDrivers)
    }

    return (
      <React.Fragment>
        <ScheduleRunForm
          onSubmit={
            !customize || submitting
              ? this.scheduleRun
              : () => this.goToCustomizeStep(true)
          }
          onCustomizeChecked={this.onCustomizeChecked}
          initialValues={values}
          markets={markets}
          routes={routes}
          drivers={clonedDrivers}
          driver={driver}
          helpers={helpers}
          loading={loading}
          onMarketChange={this.onMarketChange}
          onRouteChange={this.onRouteChange}
          selector={selector}
        />
        {goToCustomizeStep &&
          !submitting && (
            <ModalTrigger
              form={
                <Route
                  isCustomRouteForRun={true}
                  routeIdForRun={routeId}
                  setLocationsForRun={this.setLocationsAndSubmit}
                />
              }
              onClose={() => {
                this.goToCustomizeStep(false)
              }}
              open={goToCustomizeStep && !submitting}
              scrolling={true}
              title="Customize Run"
            />
          )}

        {showMileCalculationError && (
          <CalculateRouteLengthModalError
            message={mileCalculationErrorMessage}
            onCalculateRouteLengthModalClose={
              this.onCalculateRouteLengthModalClose
            }
          />
        )}
      </React.Fragment>
    )
  }
}
