import React from 'react'

import Header from './Header'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Divider } from 'semantic-ui-react'
import ScheduleView from '../../views/Schedule'

export default class ViewSchedule extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <Divider hidden />
        <Row>
          <ScheduleView />
        </Row>
      </Grid>
    )
  }
}
