import _, { isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'

import NumericTooltip from '../../components/NumericTooltip'
import { FormattedNumber } from 'react-intl'
import { Label, Segment } from 'semantic-ui-react'

import { withMarketData } from '../../connectors/markets'
import { withRoutes, withRoutesData, withRoutesReport } from '../../connectors/routes'

import { Col, Row } from 'react-flexbox-grid'
import DataTable from '../../components/DataTable'
import { calculateGoal, distributeRangeInMonths } from '../../selectors/markets'
const columns = ({
  renderDistribution,
  renderProjected,
  renderWeightPercentage,
}) => [
  {
    title: 'Route',
    name: 'name',
    icon: { name: 'angle down' },
  },
  {
    title: 'Bin',
    name: 'totalBins',
    align: 'center',
  },
  {
    title: 'Bin Avg lbs',
    name: 'bin_avg_lbs',
    align: 'center',
  },
  {
    title: 'Goal',
    name: 'goal',
    align: 'center',
  },
  {
    title: 'Projected/Actual',
    name: 'projection',
    align: 'center',
    renderCell: renderProjected,
  },
  {
    title: 'Sellable Goods',
    name: 'sellable_weight',
    align: 'center',
    renderCell: data => renderWeightPercentage(data, 'sellable_weight'),
  },
  {
    title: 'Cloth',
    name: 'total_cloth',
    align: 'center',
    renderCell: data => renderWeightPercentage(data, 'total_cloth'),
  },
  {
    title: 'Miscel',
    name: 'total_misc',
    align: 'center',
    renderCell: data => renderWeightPercentage(data, 'total_misc'),
  },
  {
    title: 'Trash',
    name: 'total_trash',
    align: 'center',
    renderCell: data => renderWeightPercentage(data, 'total_trash'),
  },
  {
    title: 'Total',
    name: 'total',
    align: 'center',
  },
  {
    title: 'Distribution',
    name: 'distribution',
    align: 'center',
    renderCell: renderDistribution,
  },
]

@withMarketData
@withRoutes
@withRoutesReport
@withRoutesData
export default class MarketReport extends React.PureComponent {
  state = {
    tableData: [],
  }

  componentWillReceiveProps(nextProps) {
    const { marketId: oldMarketId, range: oldRange } = this.props
    const { marketId, range } = nextProps

    if (oldMarketId !== marketId || !_.isEqual(oldRange, range))
      this.getReports(marketId, range)
  }

  componentDidMount() {
    const { marketId, range } = this.props

    this.getReports(marketId, range)
  }

  componentDidUpdate(prevProps, prevState) {
    const { routes: { routes }, range } = this.props

    const data = [...routes]
    data.forEach(route => {
      route['bin_avg_lbs'] = route.total ? route.total / route.totalBins : 0
      route['goal'] = calculateGoal(
        distributeRangeInMonths(range.start, range.end),
        route.goals
      )
    })

    if (!isEqual(data, prevState.tableData)) {
      this.setState({
        tableData: data,
      })
    }
  }

  getReports(marketId, range) {
    const { getRoutesByMarketWithReport } = this.props
    const start = range.start
      ? range.start.format()
      : moment()
          .startOf('week')
          .format()
    const end = range.end
      ? range.end.format()
      : moment()
          .endOf('week')
          .format()
    getRoutesByMarketWithReport({ marketId, start, end })
  }

  getFooterData() {
    const { marketData, routesData } = this.props

    return {
      ...routesData,
      bin_avg_lbs: marketData.totalSellable / marketData.totalBins.bins,
      goal: marketData.totalGoals,
      totalBins: marketData.totalBins.bins,
      name: 'Totals',
      distribution: 1,
    }
  }

  render() {
    const { routes: { loading, routes } } = this.props
    const { tableData } = this.state
    const cols = columns(
      _.pick(this, [
        'renderDistribution',
        'renderProjected',
        'renderWeightPercentage',
      ])
    )

    return (
      <Segment loading={loading} className="Breakdown__Segment">
        {routes && (
          <DataTable
            columns={cols}
            data={tableData}
            footer={this.getFooterData()}
          />
        )}
        {!routes && <strong>No Routes on this market</strong>}
      </Segment>
    )
  }

  renderDistribution = ({ total, style = 'percent' }) => {
    const { routesData } = this.props
    const percent = total / routesData.total || 0
    return <FormattedNumber maximumFractionDigits={2} value={percent} />
  }

  renderProjected = ({ projection, sellable_weight }) => {
    const { range } = this.props
    const today = moment().startOf('day')

    // If the dates we are looking at are before today, then we
    // do not need the projection value, so we just replace it
    // with the sellable_weight value
    let value = range.end < today ? sellable_weight : projection

    return (
      <NumericTooltip value={value}>
        <div>
          {!isNaN(projection) && (
            <FormattedNumber value={value} maximumFractionDigits={0} />
          )}
          {isNaN(projection) && '-'}
        </div>
      </NumericTooltip>
    )
  }

  renderWeightPercentage = (data, type) => {
    const value = data[type]
    const percentage = value / data.total * 100

    return (
      <Row>
        <Col xs={6}>
          <NumericTooltip value={value}>
            <div>
              {isNaN(value) || !isFinite(value) ? (
                '-'
              ) : (
                <FormattedNumber value={value} maximumFractionDigits={0} />
              )}
            </div>
          </NumericTooltip>
        </Col>
        <Col xs={6}>
          {isNaN(percentage) || !isFinite(percentage) ? (
            '-'
          ) : (
            <NumericTooltip value={percentage}>
              <Label circular color={'gray'}>
                <FormattedNumber value={percentage} maximumFractionDigits={0} />%
              </Label>
            </NumericTooltip>
          )}
        </Col>
      </Row>
    )
  }
}
