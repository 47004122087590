import React from 'react'
import {
  Segment,
  Header,
  Message,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react'

import './styles.scss'

class Loading extends React.PureComponent {
  state = {
    message: null,
  }

  timeouts = {
    a: 0,
    b: 0,
    c: 0,
    d: 0,
    e: 0,
  }

  componentDidMount() {
    this.timeouts.a = setTimeout(() => {
      this.setState({ message: 'Still loading...' })
    }, 10000)

    this.timeouts.b = setTimeout(() => {
      this.setState({ message: 'So how was your day?' })
    }, 20000)

    this.timeouts.c = setTimeout(() => {
      this.setState({ message: "It's probably just the internet" })
    }, 30000)

    this.timeouts.d = setTimeout(() => {
      this.setState({ message: 'You should probably get someone' })
    }, 60000)

    this.timeouts.e = setTimeout(() => {
      this.setState({ message: 'Check for zombies' })
    }, 120000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeouts.a)
    clearTimeout(this.timeouts.b)
    clearTimeout(this.timeouts.c)
    clearTimeout(this.timeouts.d)
    clearTimeout(this.timeouts.e)
  }

  render() {
    const { height } = this.props
    const { message } = this.state
    return (
      <div
        className="Loading__container"
        style={height ? { height: `${height}px` } : undefined}
      >
        <Segment circular loading={true} className="Loading__Segment">
          <Header as="h2">Loading ...</Header>
          {message && <Header as="small">{message}</Header>}
        </Segment>
      </div>
    )
  }
}

export const LoadingMessage = ({ title, text, ...props }) => (
  <Message icon {...props}>
    <Icon name="circle notched" loading />
    <Message.Content>
      <Message.Header>{title}</Message.Header>
      {text}
    </Message.Content>
  </Message>
)

export const SimpleLoading = ({ loading }) => (
  <Dimmer active={loading} inverted>
    <Loader />
  </Dimmer>
)

export default Loading
