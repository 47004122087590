import { withAuth } from '../../connectors/auth'
import React, { Fragment } from 'react'
import { Dimmer, Label, Loader } from 'semantic-ui-react'
import './style.scss'

export const AllMarketsGoalsTable = props => {
  const { auth: { user: { markets } }, marketGoals, loading } = props

  const columnKeys = [
    'jan',
    'feb',
    'mar',
    'q1',
    'apr',
    'may',
    'jun',
    'q2',
    'jul',
    'aug',
    'sep',
    'q3',
    'oct',
    'nov',
    'dec',
    'q4',
  ]

  function formatAsDecimal(num) {
    return Number(num || '').toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })
  }

  function renderTableHeader() {
    return (
      <thead>
        <tr>
          <th />
          {columnKeys.map((month, index) => {
            const quarterNumber = Math.floor(index / 4) + 1

            return (
              <th class={`quarter_${quarterNumber}`}>
                <span>{month.toUpperCase()}</span>
              </th>
            )
          })}
          <th class={`annual_goal`}>
            <span>Total</span>
          </th>
        </tr>
      </thead>
    )
  }

  function renderTableRow(marketGoal) {
    const goals = marketGoal.market_goals
    const total = Object.values(goals).reduce((sum, goal) => sum + goal, 0)
    const market = markets.find(market => market.id === marketGoal.id)
    const reports = {
      q1: goals['jan'] + goals['feb'] + goals['mar'],
      q2: goals['apr'] + goals['may'] + goals['jun'],
      q3: goals['jul'] + goals['aug'] + goals['sep'],
      q4: goals['oct'] + goals['nov'] + goals['dec'],
      ...goals,
    }

    const row = (
      <tr key={`${market.id}`}>
        <td>
          <span>{market.name}</span>
        </td>
        {columnKeys.map((columnKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label>
                {reports[columnKey] !== '' &&
                  formatAsDecimal(reports[columnKey])}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label>{formatAsDecimal(total)}</Label>
        </td>
      </tr>
    )

    return row
  }

  function renderTotalRow() {
    const reports = {
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
    }
    let total = 0

    marketGoals.forEach(market => {
      const goals = market.market_goals
      total += Object.values(goals).reduce((sum, goal) => sum + goal, 0)

      const market_reports = {
        q1: goals['jan'] + goals['feb'] + goals['mar'],
        q2: goals['apr'] + goals['may'] + goals['jun'],
        q3: goals['jul'] + goals['aug'] + goals['sep'],
        q4: goals['oct'] + goals['nov'] + goals['dec'],
        ...goals,
      }

      Object.keys(reports).forEach(key => {
        reports[key] += market_reports[key]
      })
    })

    return (
      <tr key="all-markets-total-row">
        <td>
          <span>Total</span>
        </td>
        {columnKeys.map((columnKey, index) => {
          const quarterNumber = Math.floor(index / 4) + 1

          return (
            <td class={`route quarter_${quarterNumber}`}>
              <Label>
                {reports[columnKey] !== '' &&
                  formatAsDecimal(reports[columnKey])}
              </Label>
            </td>
          )
        })}
        <td class="row-total-column">
          <Label>{formatAsDecimal(total)}</Label>
        </td>
      </tr>
    )
  }

  return (
    <div>
      <div
        style={{
          overflowX: 'auto',
          maxHeight: '500px',
        }}
      >
        <table className="ui sortable table monthly-data-entry-form">
          {renderTableHeader()}
          <tbody>
            {loading ? (
              <Dimmer active>
                <Loader />
              </Dimmer>
            ) : marketGoals.length ? (
              <Fragment>
                {marketGoals.map(marketGoal => renderTableRow(marketGoal))}
                {renderTotalRow()}
              </Fragment>
            ) : (
              <tr>
                <td />
                <td colSpan={17}>No data!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default withAuth(AllMarketsGoalsTable)
