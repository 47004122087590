import { createSelector } from 'reselect'
import _find from 'lodash/find'

const _maintenanceRequests = state =>
  state.maintenanceRequests.maintenanceRequests
    ? state.maintenanceRequests.maintenanceRequests.map(MaintenanceRequest)
    : []
const _search = state => state.maintenanceRequests.search
const _currentMarket = state => state.markets.current
const _allMaintenanceRequests = state =>
  state.maintenanceRequests.maintenanceRequests

export const searchMaintenanceRequests = createSelector(
  _maintenanceRequests,
  _search,
  (maintenanceRequests, search) => {
    if (!search || search === '') {
      return maintenanceRequests
    }
    return maintenanceRequests.filter(maintenanceRequest =>
      maintenanceRequest.id.toLowerCase().includes(search.toLowerCase())
    )
  }
)

export const filterByMarkets = createSelector(
  _allMaintenanceRequests,
  _currentMarket,
  (maintenanceRequests, currentMarket) => {
    if (!currentMarket) return maintenanceRequests

    return maintenanceRequests.filter(maintenanceRequest => {
      const market = _find(maintenanceRequest.markets, { id: currentMarket })
      if (market) {
        return true
      }
      return false
    })
  }
)

export const MaintenanceRequest = createSelector(
  state => state,
  maintenanceRequest => ({
    ...maintenanceRequest,
    value: maintenanceRequest.id,
  })
)
