import gql from 'graphql-tag'

export const policeReportModel = `
  id
  binId
  userId
  locationId
  reason
  date_of_incident
  description_of_damages_notes
  approximate_associated_costs
  report_number
  responding_officers_name
  photos
  documents
  notes_on_follow_up_with_police
  status
  location {
    name
    latitude
    longitude
  }
`

export const policeReportWithDetailsModel = `
  id
  binId
  userId
  locationId
  routeId
  marketId
  reason
  date_of_incident
  description_of_damages_notes
  approximate_associated_costs
  report_number
  responding_officers_name
  photos
  documents
  notes_on_follow_up_with_police
  status
  bin {
    serial_number
  }
  user {
    first_name
    last_name
  }
  location {
    name
    latitude
    longitude
  }
`

export const policeReportsQuery = gql`
  query policeReports($status: String) {
    policeReports (
      filter: { status: $status }
    ) {
      ${policeReportWithDetailsModel}
    }
  }
`

export const policeReport = gql`
  query($id: String!) {
    policeReportData(id: $id) {
      ${policeReportWithDetailsModel}
    }
  }
`

export const createPoliceReport = gql`
  mutation createPoliceReport($policeReport: PoliceReportInput!) {
    createPoliceReport(policeReport: $policeReport) {
      ${policeReportWithDetailsModel}
    }
  }
`

export const updatePoliceReport = gql`
  mutation updatePoliceReport($policeReportId: String!, $policeReport: PoliceReportInput!) {
    updatePoliceReport(policeReportId: $policeReportId, policeReport: $policeReport) {
      ${policeReportWithDetailsModel}
    }
  }
`

export const deleteMutation = gql`
  mutation($policeReportId: String!) {
    deletePoliceReport(id: $policeReport){
      ${policeReportWithDetailsModel}
    }
  }
`
