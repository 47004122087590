import apolloClient from '../api/apolloClient'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isString from 'lodash/isString'
import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import { generateRangeDate } from '../utils/date'
// Queries
import {
  getMarketDrivers as getMarketDriversQuery,
  getMarketEOMReport as getMarketEOMReportQuery,
  getMarketsByIds,
  getMarkets as getMarketsQuery,
} from '../api/Query/markets'

import { trigger } from '../actions'
import {
  getReportParams,
  getReportParamsForDashboard,
} from '../selectors/markets'

export const _MARKETS_ERROR = 'MARKETS_ERROR'
export const _MARKETS_CLEAR_ERROR = 'MARKETS_CLEAR_ERROR'
export const _MARKETS_LOADING = 'MARKETS_LOADING'
export const _MARKETS_LOADED = 'MARKETS_LOADED'
export const _MARKETS_FLAT_LOADED = 'MARKETS_FLAT_LOADED'
export const _MARKETS_DATA_LOADED = 'MARKETS_DATA_LOADED'
export const _MARKETS_CREATE = '_MARKETS_CREATE'
export const _MARKETS_DATA_RANGE = '_MARKETS_DATA_RANGE'
export const _MARKETS_SET_CURRENT = 'MARKETS_SET_CURRENT'
export const _MARKETS_SET_CURRENT_RANGE = 'MARKETS_SET_CURRENT_RANGE'
export const _MARKETS_LOAD_DRIVERS = 'MARKETS_LOAD_DRIVERS'
export const _MARKETS_SET_SELECTED_MARKETS_DASHBOARD =
  'MARKETS_SET_SELECTED_MARKETS_DASHBOARD'

function getResults(results) {
  return results.data && (results.data.markets || results.data.marketsByIds)
}

export const clearError = () => {
  return dispatch => dispatch(trigger(_MARKETS_CLEAR_ERROR)())
}

export const setCurrent = current => {
  return dispatch => {
    dispatch(trigger(_MARKETS_SET_CURRENT)(current))
  }
}

export const setSelectedMarketsForDashboard = selectedMarkets => {
  return dispatch => {
    dispatch(trigger(_MARKETS_SET_SELECTED_MARKETS_DASHBOARD)(selectedMarkets))
  }
}

export const setCurrentRange = range => {
  let payload = {}
  if (_isString(range)) {
    payload = {
      named: range,
      start: moment(range).startOf('month'),
      end: moment(range).endOf('month'),
    }
  } else {
    payload =
      range && range.named
        ? {
            ...generateRangeDate(range.named),
            named: range.named,
          }
        : {
            ...range,
            named: 'custom',
          }
  }

  return dispatch => {
    dispatch(trigger(_MARKETS_SET_CURRENT_RANGE)(payload))
  }
}

export const getMarketDrivers = marketId => {
  return dispatch => {
    dispatch(trigger(_MARKETS_LOADING)())
    return apolloClient
      .query({
        query: getMarketDriversQuery,
        variables: { ids: [marketId] },
      })
      .then(res => res.data.marketsByIds)
      .then(data => data.length && data[0].drivers)
      .then(trigger(_MARKETS_LOAD_DRIVERS))
      .catch(trigger(_MARKETS_ERROR))
      .then(dispatch)
  }
}

export const getFlatMarkets = () => {
  return (dispatch, getState) => {
    const variables = getReportParams(getState())

    dispatch(trigger(_MARKETS_LOADING)())
    return apolloClient
      .query({
        query: getMarketsQuery,
        variables,
        fetchPolicy: 'network-only',
      })
      .then(getResults)
      .then(markets => _sortBy(markets, 'name'))
      .then(trigger(_MARKETS_FLAT_LOADED))
      .catch(trigger(_MARKETS_ERROR))
      .then(dispatch)
  }
}
export const getMarkets = () => {
  return (dispatch, getState) => {
    const variables = getReportParams(getState())

    dispatch(trigger(_MARKETS_LOADING)())
    return apolloClient
      .query({
        query: getMarketsByIds,
        variables,
        fetchPolicy: 'network-only',
      })
      .then(getResults)
      .then(markets => _sortBy(markets, 'name'))
      .then(trigger(_MARKETS_LOADED))
      .catch(trigger(_MARKETS_ERROR))
      .then(dispatch)
  }
}

export const getMarketReports = variables => {
  return (dispatch, getState) => {
    const queryVariables = !variables ? getReportParams(getState()) : variables

    dispatch(trigger(_MARKETS_LOADING)())

    if (!queryVariables) {
      return null
    }
    return apolloClient
      .query({
        query: getMarketsByIds,
        variables: queryVariables,
      })
      .then(getResults)
      .then(trigger(_MARKETS_DATA_LOADED))
      .catch(trigger(_MARKETS_ERROR))
      .then(dispatch)
  }
}
export const getMarketReportsForDashboard = variables => {
  return (dispatch, getState) => {
    const queryVariables = !variables
      ? getReportParamsForDashboard(getState())
      : variables

    dispatch(trigger(_MARKETS_LOADING)())

    if (!queryVariables) {
      return null
    }
    return apolloClient
      .query({
        query: getMarketsByIds,
        variables: queryVariables,
      })
      .then(getResults)
      .then(trigger(_MARKETS_DATA_LOADED))
      .catch(trigger(_MARKETS_ERROR))
      .then(dispatch)
  }
}

// This function is a custom range
export const getMarketReportsRange = (start, end) => {
  return (dispatch, getState) => {
    let variables = getReportParams(getState())

    variables.start = start
    variables.end = end

    if (!variables) {
      return null
    }

    dispatch(trigger(_MARKETS_LOADING)())
    return apolloClient
      .query({
        query: getMarketsByIds,
        variables,
      })
      .then(getResults)
      .then(trigger(_MARKETS_DATA_RANGE))
      .catch(trigger(_MARKETS_ERROR))
      .then(dispatch)
  }
}

export const downloadMarketEOMReport = ({
  marketId,
  year,
  month,
  regenerate,
}) => {
  return dispatch => {
    return apolloClient
      .query({
        query: getMarketEOMReportQuery,
        variables: { marketId, year, month, regenerate },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        if (_has(result, 'data.marketEomReport.url'))
          return _get(result, 'data.marketEomReport')
        else throw new Error('Can`t download report')
      })
  }
}
