import React, { useEffect, useState } from 'react'
import './style.scss'

export function EventContextMenu(props) {
  const { children, hideContextMenu } = props
  const [contextPosition, setContextPosition] = useState({
    left: 0,
    top: 0,
  })

  useEffect(() => {
    const hanldeClick = e => {
      const contextMenuClicked = e.target.closest('.context-menu')

      if (!contextMenuClicked) hideContextMenu()
    }

    const hanldeOnContextMenu = e => {
      const contextMenuClicked = e.target.closest('.context-menu')
      const eventClicked = e.target.closest('.ScheduleCalendar__event')

      if (!contextMenuClicked && !eventClicked) hideContextMenu()
    }

    document.addEventListener('click', hanldeClick)
    document.addEventListener('contextmenu', hanldeOnContextMenu)

    return () => {
      document.removeEventListener('click', hanldeClick)
      document.removeEventListener('contextmenu', hanldeOnContextMenu)
    }
  }, [])

  useEffect(
    () => {
      const { left, top } = props
      adjustContextMenu({ left, top })
    },
    [props.left, props.top]
  )

  const adjustContextMenu = (position = { left: 0, top: 0 }) => {
    const contextMenu = document.querySelector('.context-menu')
    const contextMenuRect = contextMenu.getBoundingClientRect()

    const calendarContainer = document.getElementById(
      'ScheduleCalendar__Segment'
    )
    const calendarContainerCoordinates = calendarContainer.getBoundingClientRect()
    const documentHeight = document.body.clientHeight
    const newTop =
      calendarContainerCoordinates.top + contextMenuRect.height + position.top >
      documentHeight
        ? position.top - contextMenuRect.height - 30
        : position.top

    setContextPosition({
      left: position.left,
      top: newTop,
    })
  }

  return (
    <div
      class="context-menu"
      style={{
        left: contextPosition.left,
        top: contextPosition.top,
      }}
      onContextMenu={e => e.preventDefault()}
    >
      {children}
    </div>
  )
}

export default EventContextMenu
