import { DatePicker } from 'rsuite'
import React from 'react'

import './style.scss'

class DatePickerSingle extends React.Component {
	state = {
		selectedDate: Date.now(),
		open: false,
	}

	componentRef = React.createRef()

	handleOutsideClik = event => {
		const datePickerInputContainer = event.target.closest('.rs-picker')
		const datePickerContainer = event.target.closest('.rs-picker-date-menu')

		if(datePickerInputContainer === null && datePickerContainer === null) {
			this.onClose()
		} else {
			this.onClick()
		}
	}

	onSelect = date => {
		const { onSelect } = this.props

		this.setState({
			selectedDate: date,
			open: false,
		})

		onSelect(date)
	}

	/*
	 * onClick no longer works as expected from previou versions of rsuite,
	 * so it is now called by "handleOutsideClik"
	 *
	 * TODO: Refactor to use these components properly, as intended
	 */
	onClick = () => {
		const { disabled } = this.props

		if(!disabled)
			this.setState({
				open: true,
			})
	}

	onClose = () => {
		this.setState({
			open: false,
		})
	}

	componentDidMount() {
		const { value } = this.props

		this.setState({
			selectedDate: value,
		})

		document.addEventListener('click', this.handleOutsideClik)
	}

	componentDidUpdate() {
		const input = this.componentRef.current.querySelector(
			'.rs-picker-toggle-read-only'
		)

		if(input) {
			input.setAttribute('disabled', 'disabled')
		}
	}

	UNSAFE_componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClik)
	}

	render() {
		const { selectedDate, open } = this.state
		const { format, disabled, loading, placeholder } = this.props

		return (
			<div ref={this.componentRef}>
				<DatePicker
					cleanable={false}
					ranges={[]}
					value={selectedDate}
					onSelect={this.onSelect}
					// onClick={this.onClick}
					onClose={this.onClose}
					open={open}
					placeholder={placeholder}
					format={format}
					disabled={disabled}
					loading={loading}
					editable={false}
					onInput={e => {
						e.preventDefault()
					}}
				/>
			</div>
		)
	}
}

export default DatePickerSingle
