import React from 'react'
import _isArray from 'lodash/isArray'
import _flatMap from 'lodash/flatMap'
import _filter from 'lodash/filter'
import _isString from 'lodash/isString'
import _has from 'lodash/has'
import _map from 'lodash/map'
import _head from 'lodash/head'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _isObject from 'lodash/isObject'
import _includes from 'lodash/includes'
import _omit from 'lodash/omit'
import PropTypes from 'prop-types'

import { Form, Dropdown } from 'semantic-ui-react'
import './styles.scss'

const createOption = (getKey, getText) => value => ({
  text: getText(value),
  value: getKey(value),
  key: getKey(value),
  ...value,
})

const newOptions = (options, keyProp, textProp, values) => value => {
  const optionExist = _find(options, { [keyProp]: value })
  const created = true,
    added = true

  if (_get(optionExist, 'selectable') === false) return values
  if (_isEmpty(optionExist))
    if (_isObject(optionExist)) {
      return [...values, optionExist]
    }
  if (!_isEmpty(value) && _isString(value)) {
    return [...values, { [keyProp]: value, [textProp]: value, created, added }]
  }

  return values
}

const optionHasValue = (options, keyProp) => value =>
  _find(options, { [keyProp]: _isObject(value) ? _get(value, keyProp) : value })

const formatValue = (value, keyProp, textProp) => {
  if (!value) return []
  if (_isArray(value))
    return _flatMap(value, value => {
      if (!value) return []
      if (_isString(value)) return { [keyProp]: value, [textProp]: value }
      if (_has(value, textProp) && _has(value, keyProp)) return value
    })
  else if (_isString(value)) return [{ [keyProp]: value, [textProp]: value }]
  else if (_has(value, textProp) && _has(value, keyProp)) return [value]
}

const handleChange = (onChange, options, getKey) => (ev, data) => {
  const value = _get(data, 'value')
  const filter = item => {
    if (item.selectable === false) return false
    if (_isArray(value)) return _includes(value, getKey(item))
    if (_isString(value)) return getKey(item) === value
  }
  const newState = _filter(options, filter)
  onChange(newState)
}

const handleBlur = (onChange, newOption) => (ev, data) => {
  const value = _has(ev, 'target') ? ev.target.value : ''
  if (!_isEmpty(value)) onChange(newOption(value))
}

const handleAddItem = (onChange, newOption) => (ev, data) => {
  const value = _get(data, 'value')
  if (!_isEmpty(value)) onChange(newOption(value))
}

/**
 * Add additional classes to the input container
 *
 * @param {*} props
 * @returns string
 */
const setCSSClasses = ({ required }) => {
  let classes = 'SelectField__container'

  if (required) {
    classes += ' required'
  }

  return classes
}

const formatChange = (onChange, multiple) => values =>
  multiple ? onChange(values) : onChange(_head(values))

const SelectField = ({
  label,
  options = [],
  value,
  onChange,
  error,
  keyExtractor,
  textExtractor,
  keyProp,
  textProp,
  ...props
}) => {
  const { multiple, allowAdditions } = props
  const getKey = keyExtractor(keyProp)
  const getText = keyExtractor(textProp)
  const values = _map(
    formatValue(value, keyProp, textProp),
    createOption(getKey, getText)
  )
  const newOption = newOptions(options, keyProp, textProp, values)
  const applyChange = formatChange(onChange, multiple)
  const prepareOpts = [
    ..._filter(values, value => !optionHasValue(options, keyProp)(value)),
    ..._map(options, createOption(getKey, getText)),
  ]

  return (
    <Form.Field className={setCSSClasses(props)} error={error}>
      <label>{label}</label>
      <Dropdown
        {...props}
        options={_map(prepareOpts, op => _omit(op, ['selectable']))}
        value={
          multiple
            ? _map(values, getKey)
            : _head(values) ? getKey(_head(values)) : ''
        }
        onChange={handleChange(applyChange, prepareOpts, getKey)}
        onAddItem={handleAddItem(applyChange, newOption)}
        onBlur={allowAdditions ? handleBlur(applyChange, newOption) : null}
      />
    </Form.Field>
  )
}
SelectField.defaultProps = {
  keyProp: 'value',
  textProp: 'text',
  keyExtractor: propName => item => _get(item, propName),
  textExtractor: propName => item => _get(item, propName),
  value: [],
  multiple: true,
  search: true,
  selection: true,
  fluid: true,
  allowAdditions: true,
}
SelectField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
}

export default SelectField
