import React from 'react'

import { Field, reduxForm } from 'redux-form'
import { Form, Message } from 'semantic-ui-react'

import { withAuth } from '../../connectors/auth'
import { withRoutes } from '../../connectors/routes'
import FormField from '../../components/FormField'
import Select from '../../components/Select'
import './style.scss'

export const validate = ({ market, route }) => {
  const errors = {}
  if (!market) errors.market = 'invalid/required'
  if (!route) errors.route = 'invalid/required'

  return errors
}

@reduxForm({
  form: 'RouteSheetForm',
  validate,
  destroyOnUnmount: false,
})
@withAuth
@withRoutes
export default class RouteSheetForm extends React.PureComponent {
  changeMarket = (e, marketId) => {
    const { onMarketChange } = this.props
    onMarketChange(marketId)
  }

  changeRoute = (e, routeId) => {
    const { onRouteChange } = this.props
    onRouteChange(routeId)
  }

  fixCollection(items, getText) {
    if (!items) {
      return []
    }

    return items.map(item => ({
      value: item.id,
      text: getText ? getText(item) : item.name,
    }))
  }

  render() {
    const {
      formError,
      handleSubmit,
      routes,
      auth: { user: { markets } },
    } = this.props

    return (
      <Form onSubmit={handleSubmit} id="create-route-sheet-form">
        <Form.Group widths="equal">
          <Form.Field>
            <Field
              name="market"
              label="Market"
              component={FormField}
              required
              formComponent={Select}
              options={this.fixCollection(markets)}
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="route"
              label="Route"
              component={FormField}
              formComponent={Select}
              options={this.fixCollection(routes.routes)}
              loading={routes.loading}
            />
          </Form.Field>
        </Form.Group>

        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
