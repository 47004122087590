import './styles.scss'

import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _compose from 'lodash/fp/compose'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _reverse from 'lodash/reverse'
import moment from 'moment'
import React, { Fragment } from 'react'
import { models } from '../../utils'

import { WithMarkersByRouteLocations } from '../../connectors/markerMap'
import { withMarkets } from '../../connectors/markets'
import { withRoutes } from '../../connectors/routes'
import { Link, Prompt, withRouter } from 'react-router-dom'

import CreateRun from '../../components/CreateRun'
import MapMarker from '../../components/MapMarker'
import MarketLocation from '../../components/MarketLocation'
import MenuLocationOptions from '../../components/MenuLocationOptions'
import ModalTrigger from '../../components/ModalTrigger'
import OptimizeRoute from '../../components/OptimizeRoute'
import RouteCalendar from '../../components/RouteCalendar'
import RouteLocationsTable from '../../components/RouteLocationsTable'
import ScheduleRun from '../../components/ScheduleRun'
import RouteReportForm from '../../forms/RouteReport'
import { has } from 'lodash'
import { Col, Grid, Row } from 'react-flexbox-grid'
import {
  Button,
  Card,
  Divider,
  Segment,
  Grid as SemanticGrid,
  Dropdown,
} from 'semantic-ui-react'
import AssignRouteLocationForm from '../../forms/AssignRouteLocation'
import { getLocationAddressFromRawLocation } from '../../utils/locations'
import { CalculateRouteLengthModalError } from './CalculateRouteLengthModalError'

const locationFields = models.location

@WithMarkersByRouteLocations
class RouteMap extends React.PureComponent {
  render() {
    const {
      color,
      customRunLocations,
      locations,
      route,
      traces,
      ...rest
    } = this.props

    // If these locations are for run instead of route, we may add new
    // locations, in which case we have to alter some properties in order
    // for the markers to render proplery on the map

    let filteredCustomRunLocationsForMap = null

    if (!!customRunLocations) {
      _forEach(customRunLocations, location => {
        location.allocation = location.stop_sequence
        location.color = color
        delete location.role
      })

      filteredCustomRunLocationsForMap = customRunLocations.filter(
        item => item.includeInRun === true
      )
    }

    return (
      <MapMarker
        locations={filteredCustomRunLocationsForMap || locations}
        traces={traces}
        {...rest}
      />
    )
  }
}

@withRoutes
@withMarkets
@withRouter
export default class Route extends React.Component {
  static defaultProps = {
    isCustomRouteForRun: false,
  }

  state = {
    routeId: null,
    hasNewSort: false,
    locations: null,
    resetData: false,
    updated: false,
    routeMileCalculationError: '',
    showRouteMileCalculationError: false,
  }

  onCalculateRouteLengthModalClose = () => {
    this.setState({
      routeMileCalculationError: '',
      showRouteMileCalculationError: false,
    })
  }

  showCalculateRouteLengthModal = message => {
    this.setState({
      routeMileCalculationError: message,
      showRouteMileCalculationError: true,
    })
  }

  saveLocationsForRun() {
    const { setLocationsForRun, isCustomRouteForRun } = this.props
    const { locations } = this.state
    let locationsToSave = []

    if (isCustomRouteForRun) {
      locationsToSave = locations.filter(
        location => location.includeInRun === true
      )
    } else {
      locationsToSave = locations
    }

    if (locationsToSave && locationsToSave.length) {
      setLocationsForRun(locationsToSave)
    }
  }

  renderButtons() {
    const { closeModal, renderButtons } = this.props

    return renderButtons(
      <SemanticGrid columns="equal">
        <SemanticGrid.Column>
          <Button negative onClick={closeModal}>
            Go Back
          </Button>
          <Button
            positive
            onClick={() => {
              this.saveLocationsForRun()
            }}
          >
            Schedule Run
          </Button>
        </SemanticGrid.Column>
      </SemanticGrid>
    )
  }

  setCurrentRoute(nextProps = {}) {
    const {
      getRoute,
      isCustomRouteForRun,
      match: { params: { routeId } },
      routeIdForRun,
    } = this.props
    const {
      match: { params: { routeId: nextRouteId } = {} } = {},
      routeIdForRun: nextRouteIdForRun,
    } = nextProps

    if (!!routeIdForRun && routeIdForRun !== nextRouteIdForRun) {
      getRoute(routeIdForRun)
    } else if (!nextProps || routeId !== nextRouteId) {
      getRoute(nextRouteId || routeId)
    }

    // Reset cached data of route have change
    if (!isCustomRouteForRun && nextRouteId !== _get(this, 'state.routeId'))
      this.setState({
        routeId: nextRouteId,
        hasNewSort: false,
        locations: null,
      })
  }

  setLocationsFromNextPropsRoute(nextProps) {
    const { routes: { route } } = this.props
    const { routes: { route: nextRoute } } = nextProps

    if (!_isEqual(route, nextRoute)) {
      this.setLocationsFromRoute(nextRoute)
    }
  }

  setLocationsFromRoute(route) {
    const locations = _get(route, 'locations')
      .slice()
      .map(location => {
        location.includeInRun = true
        return location
      })

    this.setState({
      ...this.state,
      locations: locations,
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setCurrentRoute(nextProps)
    this.setLocationsFromNextPropsRoute(nextProps)
  }

  componentDidUpdate(_, prevState) {
    const { isCustomRouteForRun } = this.props
    const { locations } = this.state
    const { locations: prevLocations } = prevState

    if (isCustomRouteForRun && !_isEqual(locations, prevLocations)) {
      this.renderButtons()
    }
  }

  componentDidMount() {
    const { isCustomRouteForRun, routeIdForRun, routes: { route } } = this.props

    if (isCustomRouteForRun) {
      this.renderButtons()
    }

    this.setCurrentRoute()
    if (!!route && (!isCustomRouteForRun || route.id === routeIdForRun)) {
      this.setLocationsFromRoute(route)
    }
  }

  onSort = locationsFromTable => {
    const { isCustomRouteForRun } = this.props
    let { locations } = this.state
    let stop_sequence = 0

    locations = _map(locationsFromTable, (locationFromTable, index) => {
      const location = _find(
        locations,
        location => locationFromTable.id === location.id
      )

      if (isCustomRouteForRun) {
        if (location.includeInRun === true)
          location.stop_sequence = ++stop_sequence
        else location.stop_sequence = 0
      } else {
        location.stop_sequence = ++stop_sequence
      }

      return location
    })
    this.setState({ hasNewSort: true, locations })
  }

  onSelectDateRun = event => {
    const { history } = this.props
    if (event.runId) {
      history.push(`/app/reports/runs/${event.runId}`)
    }
  }

  saveOrder = () => {
    const { saveLocationOrder, routes: { route } } = this.props
    const { locations } = this.state
    const routeId = _get(route, 'id')

    if (!locations || !locations.length) {
      return
    }

    this.setState({ resetData: true })

    saveLocationOrder(routeId, locations.map(({ id }) => ({ id })))
      .then(response => {
        let showRouteMileCalculationError = false
        let routeMileCalculationError = ''

        if (has(response, 'payload.mile_calculation_result')) {
          if (!response.payload.mile_calculation_result.status)
            routeMileCalculationError =
              response.payload.mile_calculation_result.message

          showRouteMileCalculationError = !response.payload
            .mile_calculation_result.status
        }

        this.setState({
          hasNewSort: false,
          locations: locations,
          resetData: false,
          routeMileCalculationError,
          showRouteMileCalculationError,
        })
      })
      .then(() => {
        this.setState({ updated: Date.now() })
      })
  }

  saveLocation = type => location => {
    const { routes: { route }, editRoute } = this.props
    const routeId = _get(route, 'id')

    return editRoute(routeId, {
      name: route.name,
      [type]: _pick(location, locationFields),
    }).then(() => {
      this.setState({ updated: Date.now() })
    })
  }

  addLocation = location => {
    const { routes: { route }, isCustomRouteForRun } = this.props
    const { locations } = this.state
    const routeId = _get(route, 'id')
    let stop_sequence = 0

    location.includeInRun = true
    location.notInOriginalRoute = routeId !== location.originalRouteId

    let updatedLocations = locations.concat(
      getLocationAddressFromRawLocation(location)
    )

    updatedLocations = updatedLocations.map(location => {
      if (isCustomRouteForRun) {
        if (location.includeInRun === true)
          location.stop_sequence = ++stop_sequence
        else location.stop_sequence = 0
      } else {
        location.stop_sequence = ++stop_sequence
      }

      return location
    })

    this.setState({
      ...this.state,
      locations: updatedLocations,
    })
  }

  removeLocation = id => {
    const { locations } = this.state

    const locationToRemoveStopSequence = _find(
      locations,
      location => id === location.id
    ).stop_sequence

    // Since we only call this function for custom route for run, we
    // set the correct order of the locations after the operation
    this.setState({
      ...this.state,
      locations: _compose(
        locations =>
          _map(locations, location => {
            if (location.stop_sequence > locationToRemoveStopSequence) {
              location.stop_sequence--
            }
            return location
          }),
        locations => _filter(locations, location => id !== location.id)
      )(locations),
    })
  }

  validateRoute(route) {
    const hasLocations =
      route.start_location && route.end_location && route.unloading_location
    const marketHasUnloading =
      route.market.unloading_locations &&
      route.market.unloading_locations.length
    return hasLocations || marketHasUnloading
  }

  reset = () => {
    const { routes: { route } } = this.props

    const locations = _map(_get(route, 'locations'), (location, index) => {
      location.stop_sequence = index + 1
      location.includeInRun = true
      return location
    })

    this.setState({
      ...this.state,
      hasNewSort: false,
      locations,
    })
  }

  reverse = () => {
    const { locations } = this.state
    const { isCustomRouteForRun } = this.props
    let stop_sequence = 0

    let newLocations = _reverse([...locations])
    newLocations = _map(newLocations, location => {
      if (isCustomRouteForRun) {
        if (location.includeInRun === true)
          location.stop_sequence = ++stop_sequence
        else location.stop_sequence = 0
      } else {
        location.stop_sequence = ++stop_sequence
      }

      return location

      // return {
      //   ...location,
      //   stop_sequence: locations.length + 1 - location.stop_sequence,
      // }
    })
    this.setState({
      ...this.state,
      locations: newLocations,
    })
  }

  toggleLocationInclude_in_run = location => {
    const { locations } = this.state
    let stop_sequence = 0

    let updatedLocations = locations.map(item => {
      if (location.id === item.id) {
        item.includeInRun = !item.includeInRun
      }

      return item
    })

    updatedLocations = updatedLocations.map(location => {
      if (location.includeInRun === true)
        location.stop_sequence = ++stop_sequence
      else location.stop_sequence = 0

      return location
    })

    this.setState({
      locations: updatedLocations,
    })
  }

  toggleAllLocationInclude_in_run = () => {
    const { locations } = this.state
    const includeInRunCount = locations.filter(
      item => item.includeInRun === true
    ).length
    let updatedLocations = []
    let stop_sequence = 0

    if (includeInRunCount === locations.length) {
      updatedLocations = locations.map(item => {
        item.includeInRun = false
        return item
      })
    } else {
      updatedLocations = locations.map(item => {
        item.includeInRun = true
        return item
      })
    }

    updatedLocations = updatedLocations.map(location => {
      if (location.includeInRun === true)
        location.stop_sequence = ++stop_sequence
      else location.stop_sequence = 0

      return location
    })

    this.setState({
      locations: updatedLocations,
    })
  }

  updateBinFillLevelService = (routeId) => {
	console.log('update route with this.value')
  }

  updateBinMinServiceIntervalService = (routeId) => {
	console.log('update route with this.value')
  }

  render() {
    const { routes: { loading, route }, isCustomRouteForRun } = this.props
    const {
      locations,
      hasNewSort,
      resetData,
      updated,
      showRouteMileCalculationError,
      routeMileCalculationError,
    } = this.state
    const showBins = false
    const routeId = _get(route, 'id')
    const marketId = _get(route, 'market.id')

    if (!routeId) {
      return (
        <Segment loading={true} padded={false} style={{ minHeight: '300px' }} />
      )
    }

    const unloading_locations = route.market.unloading_locations || []

    let optimized_miles = undefined
    let optimized_miles_at = undefined

    if (route.optimized_miles) {
      if (route.optimized_miles > 0)
        optimized_miles = route.optimized_miles.toFixed(2)
      else optimized_miles = 'N/A'
    }

    if (has(route, 'optimized.timestamp') && route.optimized_miles_at) {
      if (route.optimized.timestamp > route.optimized_miles_at) {
        optimized_miles_at = moment(Number(route.optimized.timestamp)).format(
          'MMM DD, YYYY, h:mm:ss a'
        )
      } else {
        optimized_miles_at = moment(Number(route.optimized_miles_at)).format(
          'MMM DD, YYYY, h:mm:ss a'
        )
      }
    } else if (route.optimized_miles_at) {
      optimized_miles_at = moment(Number(route.optimized_miles_at)).format(
        'MMM DD, YYYY, h:mm:ss a'
      )
    } else if (has(route, 'optimized.timestamp')) {
      optimized_miles_at = moment(Number(route.optimized.timestamp)).format(
        'MMM DD, YYYY, h:mm:ss a'
      )
    }

	const binFillLevelOptions = [
		{ key: 0, text: 'Disabled', value: 0 },
		{ key: 1, text: '10%', value: 10 },
		{ key: 2, text: '20%', value: 20 },
		{ key: 3, text: '30%', value: 30 },
		{ key: 4, text: '40%', value: 40 },
		{ key: 5, text: '50%', value: 50 },
		{ key: 6, text: '60%', value: 60 },
		{ key: 7, text: '70%', value: 70 },
		{ key: 8, text: '80%', value: 80 },
		{ key: 9, text: '90%', value: 90 },
	]

	const binMinServiceIntervalOptions = [
		{ key: 0, text: 'Disabled', value: 0 },
		{ key: 1, text: '5 days', value: 5 },
		{ key: 2, text: '10 days', value: 10 },
		{ key: 3, text: '15 days', value: 15 },
	]

    return (
      <Segment
        padded={false}
        loading={loading || !route}
        className="Route__container"
      >
        <Prompt
          when={hasNewSort}
          message="Are you sure you want to leave without saving the location order?"
        />

        <Grid>
          <Divider hidden />
          <Row>
            <Col xs>
              <RouteMap
                route={routeId}
                renderLocationPopup={this.renderLocationPopup}
                {...isCustomRouteForRun && {
                  color: route.color,
                  customRunLocations: [...(locations || [])],
                }}
              />
            </Col>
          </Row>
          <Row between="xs" style={{ padding: '1rem 0' }}>
            <Col xs={12} sm={9} md={6} lg={6}>
              {!isCustomRouteForRun && (
                <React.Fragment>
                  <CreateRun
                    market={marketId}
                    route={routeId}
                    disabled={hasNewSort || loading}
                    onSchedule={() => {
                      setTimeout(
                        _ => this.setState({ updated: Date.now() }),
                        1000
                      )
                    }}
                  />
                  <ScheduleRun
                    market={marketId}
                    route={routeId}
                    button={{
                      disabled:
                        hasNewSort || loading || !this.validateRoute(route),
                    }}
                    onSchedule={() => {
                      setTimeout(
                        _ => this.setState({ updated: Date.now() }),
                        1000
                      )
                    }}
                  />
                </React.Fragment>
              )}
              {!isCustomRouteForRun && (
                <React.Fragment>
                  <OptimizeRoute route={route}>
                    <Button color="green" disabled={hasNewSort || loading}>
                      Optimize Route
                    </Button>
                  </OptimizeRoute>
                  {route.optimized && (
                    <div>
                      <small>
                        Last Optimized:{' '}
                        {moment(route.optimized.timestamp).format(
                          'dddd, MMMM Do YYYY, h:mm:ss a'
                        )}
                      </small>
                    </div>
                  )}
                </React.Fragment>
              )}
            </Col>
            {!isCustomRouteForRun && (
              <Col xs={6} lg={2}>
                <ModalTrigger
                  title="Route report tool"
                  trigger={<Button>EOM Report</Button>}
                  form={<RouteReportForm routeId={routeId} />}
                />
              </Col>
            )}
            <Col
              xs={6}
              lg={isCustomRouteForRun ? 6 : 4}
              style={{ textAlign: 'right' }}
            >
              {!isCustomRouteForRun && (
                <Button disabled={!hasNewSort} onClick={this.saveOrder}>
                  Save Order
                </Button>
              )}
              <ModalTrigger
                title={
                  isCustomRouteForRun
                    ? 'Adding Location to run'
                    : `Assigning Location to Route ${route.name}`
                }
                trigger={
                  <Button
                    disabled={(hasNewSort && !isCustomRouteForRun) || loading}
                  >
                    Add Location
                  </Button>
                }
                form={
                  <AssignRouteLocationForm
                    addLocationToRun={this.addLocation}
                    isCustomRouteForRun={isCustomRouteForRun}
                    routeId={routeId}
                    route={route}
                    showCalculateRouteLengthModal={
                      this.showCalculateRouteLengthModal
                    }
                    simple_locations={false}
                  />
                }
              />
              {isCustomRouteForRun && (
                <React.Fragment>
                  <Button color="blue" onClick={this.reverse}>
                    Reverse
                  </Button>
                  <Button color="orange" onClick={this.reset}>
                    Reset
                  </Button>
                </React.Fragment>
              )}
            </Col>
          </Row>
			{/*
			<Row style={{ padding: '1rem' }}>
				<React.Fragment>
					Bin Fill Level:{' '}
					<Dropdown
						inline
						floating
						options={binFillLevelOptions}
						onChange={() => this.updateBinFillLevelService(routeId)}
						defaultValue={binFillLevelOptions[0].value}
						/>
					Min Service Interval:{' '}
					<Dropdown
						inline
						floating
						options={binMinServiceIntervalOptions}
						onChange={() => this.updateBinMinServiceIntervalService(routeId)}
						defaultValue={binMinServiceIntervalOptions[0].value}
						/>
				</React.Fragment>
			</Row>
			*/}
          <Row>
            {!isCustomRouteForRun && (
              <React.Fragment>
                <Col sm lg={4}>
                  <MarketLocation
                    title="Start Location"
                    location={route.start_location}
                    onEdit={this.saveLocation('start_location')}
                    //otherwise={unloading_locations.length && unloading_locations[0]}
                    withBins={showBins}
                  />
                  <MarketLocation
                    title="End Location"
                    location={route.end_location}
                    onEdit={this.saveLocation('end_location')}
                    //otherwise={unloading_locations.length && unloading_locations[0]}
                    withBins={showBins}
                  />
                  <MarketLocation
                    title="Unloading Location"
                    location={route.unloading_location}
                    options={unloading_locations}
                    onEdit={this.saveLocation('unloading_location')}
                    //otherwise={unloading_locations.length && unloading_locations[0]}
                    withBins={showBins}
                  />
                  <Card>
                    <Card.Content header="Optimized Miles" />
                    <Card.Content>
                      <span style={{ fontSize: '22px' }}>
                        {optimized_miles || '-'}
                      </span>
                      {optimized_miles !== 'N/A' &&
                        optimized_miles_at && (
                          <Fragment>
                            <div>
                              <small>
                                Last updated on: {optimized_miles_at}
                              </small>
                            </div>
                          </Fragment>
                        )}
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content header="Route Schedule" />
                    <Card.Content>
                      <RouteCalendar
                        routeData={[route]}
                        reload={updated}
                        popup={false}
                        renderEvent={({ event: { route, start } }) => (
                          <Link
                            to={`/app/schedule?market=${marketId}&route=${
                              routeId
                            }&date=${start.toISOString()}`}
                            className="Route__RunLink"
                          />
                        )}
                      />
                    </Card.Content>
                  </Card>
                </Col>
              </React.Fragment>
            )}
            <Col sm lg>
              {locations && (
                <RouteLocationsTable
                  isCustomRouteForRun={isCustomRouteForRun}
                  locations={locations}
                  onSort={this.onSort}
                  removeLocation={this.removeLocation}
                  toggleLocationInclude_in_run={
                    this.toggleLocationInclude_in_run
                  }
                  toggleAllLocationInclude_in_run={
                    this.toggleAllLocationInclude_in_run
                  }
                  resetData={resetData}
                  routeId={routeId}
                  stopColor={route.color}
                />
              )}
            </Col>
          </Row>
        </Grid>
        {showRouteMileCalculationError && (
          <CalculateRouteLengthModalError
            message={routeMileCalculationError}
            onCalculateRouteLengthModalClose={
              this.onCalculateRouteLengthModalClose
            }
          />
        )}
      </Segment>
    )
  }

  renderLocationPopup = location => {
    const { isCustomRouteForRun, routes: { route }, getRoute } = this.props
    const routeId = _get(route, 'id')

    const onRemove = !isCustomRouteForRun
      ? () => getRoute(routeId)
      : id => this.removeLocation(id)

    return (
      <MenuLocationOptions
        {...location}
        isCustom={isCustomRouteForRun}
        locationId={location.id}
        onEdit={() => getRoute(routeId)}
        onRemove={onRemove}
      />
    )
  }
}
