import React from 'react'
import moment from 'moment'

import { Field, reduxForm } from 'redux-form'
import { Form, Message, Header, Checkbox, Feed, Icon } from 'semantic-ui-react'

import FormField from '../../components/FormField'
import Select from '../../components/Select'
import { LoadingMessage } from './index'
import { withAuth } from '../../connectors/auth'
import { withRoutes } from '../../connectors/routes'

export const validate = ({ year, month }) => {
  const errors = {}
  if (!year) errors.year = 'invalid/required'
  if (!Number.isInteger(month)) errors.month = 'invalid/required'

  return errors
}
const FeedReport = ({ summary, date, url }) => (
  <Feed.Event>
    <Feed.Label icon="archive" />
    <Feed.Content>
      <Feed.Summary>
        {summary}
        <Feed.Date>{moment(date).calendar()}</Feed.Date>
      </Feed.Summary>
      <Feed.Meta>
        <Feed.Like>
          <Icon name="download" />
          <a href={url} target="_blank">
            Download
          </a>
        </Feed.Like>
      </Feed.Meta>
    </Feed.Content>
  </Feed.Event>
)

const newOpt = (text, value) => ({ key: value, value, text })
const MonthSelect = props => (
  <Select
    {...props}
    options={moment.months().map((text, value) => newOpt(text, value + 1))}
  />
)
const YearSelect = props => {
  const options = []
  for (var i = 0; i <= moment().diff('2016-01-01', 'years'); i++) {
    options.push(newOpt(`${2016 + i}`, 2016 + i))
  }
  return <Select {...props} options={options} />
}

@reduxForm({
  form: 'RouteReportForm',
  validate,
  destroyOnUnmount: false,
})
@withAuth
@withRoutes
export default class RouteForm extends React.PureComponent {
  fixCollection(items, getText) {
    if (!items) {
      return []
    }

    return items.map(item => ({
      value: item.id,
      text: getText ? getText(item) : item.name,
    }))
  }

  render() {
    const {
      formError,
      handleSubmit,
      history,
      loadingHistory,
      routes,
      auth: { user: { markets } },
      isAllRoutes,
    } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Form.Field>
            <Field
              name="market"
              label="Market"
              component={FormField}
              required
              formComponent={Select}
              options={this.fixCollection(markets)}
            />
          </Form.Field>
          {!isAllRoutes ? (
            <Form.Field>
              <Field
                name="route"
                label="Route"
                component={FormField}
                formComponent={Select}
                options={this.fixCollection(routes.routes)}
                loading={routes.loading}
                required
              />
            </Form.Field>
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Field
              name="month"
              label="Month of report"
              component={FormField}
              required
              formComponent={MonthSelect}
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="year"
              label="Year of report"
              component={FormField}
              placeholder={moment().year()}
              required
              formComponent={YearSelect}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Field
            name="regenerate"
            label="Force regenerate the report"
            component={FormField}
            formComponent={Checkbox}
            format={val => (!val ? 0 : 1)}
            parse={val => !!val}
          />
        </Form.Field>
        {history && <Header as="h4">Report history</Header>}
        <Feed>
          {loadingHistory && (
            <LoadingMessage message="Loading history of reports..." />
          )}
          {history && (
            <FeedReport
              date={history.gerenated_at}
              url={history.url}
              summary="The report was gerenated by ATRS"
            />
          )}
        </Feed>
        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
