import React from 'react'

import _isString from 'lodash/isString'

import { Checkbox } from 'semantic-ui-react'

const CheckboxField = ({ value, onChange, shouldBeChecked, ...props }) => {
  if (_isString(value)) {
    value = false
  }

  // TODO: Figure out smarter way to handle Redux adding { error: false } to CheckboxField
  if (!props.error) {
    props.error = undefined
  }

  return (
    <Checkbox
      checked={value}
      onChange={(ev, data) => onChange(!value)}
      {...props}
    />
  )
}

export default CheckboxField
