import './styles.scss'

import axios from 'axios'
import React from 'react'
import validator from 'validator'
import config from '../../../config'

import _isEmpty from 'lodash/isEmpty'

import { Field, reduxForm } from 'redux-form'

import { Form, Header, Message } from 'semantic-ui-react'
import { Icon, Button } from 'semantic-ui-react'

import { withAuth } from '../../../connectors/auth'
import { withBinDropdownOptions } from '../../../connectors/bins'
import { withUserDropdownOptions } from '../../../connectors/users'

import FormField from '../../../components/FormField'
import Select from '../../../components/Select'
import DatePicker from '../../../components/DatePicker'

import {
  reasonsFrontSideOptions,
  reasonsLeftSideOptions,
  reasonsBackSideOptions,
  reasonsRightSideOptions,
  reasonsRoofOptions,
} from '../../../forms/Bin/options'

export const validate = values => {
  const errors = {}

  const fields = ['binId', 'userId']

  fields.forEach(field => {
    if (!values[field] || validator.isEmpty(values[field])) {
      errors[field] = 'invalid/required'
    }
  })

  if (_isEmpty(values.date)) {
    errors.date = 'invalid/required'
  }

  return errors
}

const DateField = ({ label, ...props }) => (
  <Form.Field {...props}>
    <label>{label}</label>
    <DatePicker singleDay={true} {...props} />
  </Form.Field>
)

@withAuth
@withBinDropdownOptions
@withUserDropdownOptions
@reduxForm({
  form: 'MaintenanceRequestForm',
  validate,
})
export default class MaintenanceRequestForm extends React.Component {
  constructor(props) {
    super(props)
    let images = []

    if (
      !_isEmpty(props.initialValues) &&
      !_isEmpty(props.initialValues.photos)
    ) {
      images = props.initialValues.photos
    }

    this.state = {
      images,
    }

    this.props.change('binId', this.props.binId)
  }

  addImage(image) {
    const images = [...this.state.images, image]

    this.setState(state => ({
      ...state,
      images,
    }))

    this.props.change('photos', images)
  }

  removeImage(index) {
    let images = this.state.images
    images.splice(index, 1)

    this.setState(state => ({
      ...state,
      images,
    }))

    this.props.change('photos', images)
  }

  pickImageFile = () => {
    let input = document.createElement('input')
    input.type = 'file'
    input.onchange = _ => {
      let files = Array.from(input.files)
      let file = files[0] || undefined

      if (file) {
        const data = new FormData()
        data.append('file', file)
        const URL = config.FUNCTIONS_URI + '/uploadFile'

        axios.post(URL, data, {}).then(res => {
          this.addImage(res.data.url)
        })
      }
    }
    input.click()
  }

  // TODO: sanitize input
  parseUserOptions(items) {
    if (!items) {
      return []
    }

    const isAdminOrManager = user => {
      if (!user.contact_type) {
        return false
      }

      return user.contact_type === 'admin' || user.contact_type === 'manager'
    }

    let filteredItems = items.filter(isAdminOrManager)

    // TODO: sanitize input
    return filteredItems.map(item => ({
      key: item.id || 'someId',
      id: item.id || 'someId',
      value: item.id || 'someId',
      text: `${item.first_name || 'John'} ${item.last_name || 'Doe'}`,
    }))
  }

  parseBinOptions(items) {
    if (!items) {
      return []
    }

    // TODO: sanitize input
    return items.map(item => ({
      key: item.id || 'someId',
      id: item.id || 'someId',
      value: item.id || 'someId',
      text: item.serial_number || 'someSerialNumber',
    }))
  }

  render() {
    const {
      formError,
      handleSubmit,
      binDropdownOptions,
      userDropdownOptions,
      viewMode,
      editing,
      binId,
    } = this.props
    const { images } = this.state

    const binOptions = this.parseBinOptions(binDropdownOptions)
    const userOptions = this.parseUserOptions(userDropdownOptions)

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Field
            name="binId"
            label="Bin"
            placeholder="Choose a Bin"
            options={binOptions}
            component={FormField}
            formComponent={Select}
            required
            disabled={viewMode || (binId && !editing)}
            search
          />
          <Field
            name="date"
            label="Date"
            required
            component={FormField}
            onBlur={e => e.preventDefault()}
            formComponent={DateField}
            disabled={viewMode}
          />
          <Field
            name="userId"
            label="User"
            placeholder="Choose a User"
            options={userOptions}
            component={FormField}
            formComponent={Select}
            required
            disabled={viewMode}
            search
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="notes"
            label="Notes"
            placeholder="Notes"
            component={FormField}
            formComponent={Form.Input}
            disabled={viewMode}
          />
        </Form.Group>

        <Header>Bin Photos</Header>

        <Form.Group className="FormGroup__fix">
          {images.length < 1 && <h5>There are no photos uploaded yet.</h5>}

          {images.map((image, index) => {
            return (
              <div
                key={`photo_${index}`}
                className="MaintenanceRequest__ImageContainer"
              >
                <img
                  className="MaintenanceRequest__ImageContainer__Image"
                  src={image}
                  alt="Maintenance Request Evidence"
                />
                {!viewMode && (
                  <Button
                    className="MaintenanceRequest__ImageContainer__RemoveButton"
                    color="red"
                    onClick={e => {
                      e.preventDefault()
                      this.removeImage(index)
                    }}
                  >
                    X
                  </Button>
                )}
              </div>
            )
          })}
        </Form.Group>

        {!viewMode && (
          <Button
            icon
            color="orange"
            onClick={e => {
              e.preventDefault()
              this.pickImageFile()
            }}
          >
            <Icon name="plus" /> Add photo
          </Button>
        )}

        <Header>Maintenance Reasons</Header>
        <Form.Group widths="equal">
          <Field
            name="reasons_front_side"
            label="Front"
            placeholder="Front"
            options={reasonsFrontSideOptions}
            component={FormField}
            formComponent={Select}
            multiple
            selection
            value={[]}
            disabled={viewMode}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="reasons_back_side"
            label="Back"
            placeholder="Back"
            options={reasonsBackSideOptions}
            component={FormField}
            formComponent={Select}
            multiple
            selection
            value={[]}
            disabled={viewMode}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="reasons_roof"
            label="Roof"
            placeholder="Roof"
            options={reasonsRoofOptions}
            component={FormField}
            formComponent={Select}
            multiple
            selection
            value={[]}
            disabled={viewMode}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="reasons_left_side"
            label="Left Side"
            placeholder="Left Side"
            options={reasonsLeftSideOptions}
            component={FormField}
            formComponent={Select}
            multiple
            selection
            value={[]}
            disabled={viewMode}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="reasons_right_side"
            label="Right Side"
            placeholder="Right Side"
            options={reasonsRightSideOptions}
            component={FormField}
            formComponent={Select}
            multiple
            selection
            value={[]}
            disabled={viewMode}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="other"
            label="Other"
            placeholder="Other"
            component={FormField}
            formComponent={Form.Input}
            disabled={viewMode}
          />
        </Form.Group>

        {formError && (
          <Message
            negative
            header="Create Failed"
            content={formError.message}
          />
        )}
      </Form>
    )
  }
}
