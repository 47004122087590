import React from 'react'
import 'url-search-params-polyfill'
import moment from 'moment'
import { Loader, Dimmer } from 'semantic-ui-react'

import { WithLocationsReport } from '../../connectors/reports'

function getMonthsInterval(start, end) {
  const dateStart = moment(start)
  const dateEnd = moment(end)
  const interim = dateStart.clone()
  const timeValues = []

  while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
    timeValues.push(interim.format('YYYY-MM'))
    interim.add(1, 'month')
  }
  return timeValues
}

@WithLocationsReport
export default class LocationsReportTable extends React.PureComponent {
  renderTableHeader(monthIntervals) {
    return (
      <thead>
        <tr>
          <th>
            <span>Period</span>
          </th>
          <th>
            <span>Signed by</span>
          </th>
          <th>
            <span>Date Signed / Verbal</span>
          </th>
          <th>
            <span>Route</span>
          </th>
          <th>
            <span>Name</span>
          </th>
          <th>
            <span>Location</span>
          </th>
          {monthIntervals.map(m => (
            <th>
              <span>{moment(m).format('MMMM')}</span>
            </th>
          ))}
          <th>
            <span>TOTAL</span>
          </th>
        </tr>
      </thead>
    )
  }

  renderTableRow(location, monthIntervals, lbsTotal, totals) {
    const {
      start = moment()
        .endOf('month')
        .subtract(2, 'months')
        .startOf('month'),
      end = moment().endOf('month'),
    } = this.props

    let total = 0

    let periodColumn = `${moment(start).format('MM/DD/YY')} - ${moment(
      end
    ).format('MM/DD/YY')}`
    if (location.isTotalSummary) {
      periodColumn = 'TOTAL ALL'
    } else if (location.isTotalRow) {
      periodColumn = `${location.route.name.toUpperCase()} TOTAL`
    }

    const row = (
      <tr
        key={`${location.id}-${location.isTotalRow ? 'total' : ''}`}
        style={{ fontWeight: location.isTotalRow ? 'bold' : 'normal' }}
      >
        <td>
          <span>{periodColumn}</span>
        </td>
        <td>
          <span>
            {location.signed_location_by || location.verbal_location_by}
          </span>
        </td>
        <td>
          <span>
            {moment(
              location.signed_location_date || location.verbal_location_date
            ).format('MM/DD/YY')}
          </span>
        </td>
        <td>
          <span>{location.route.name}</span>
        </td>
        <td>
          <span>{location.name}</span>
        </td>
        <td>
          <span>{location.address}</span>
        </td>
        {monthIntervals.map(m => {
          if (location.collections[m] && location.collections[m].weight) {
            total += location.collections[m].weight
          }

          if (lbsTotal) {
            let weight =
              (location.collections[m] && location.collections[m].weight) || 0
            if (
              lbsTotal[location.route.name] &&
              lbsTotal[location.route.name][m]
            ) {
              lbsTotal[location.route.name][m].weight += weight
            } else {
              lbsTotal[location.route.name] = {
                ...(lbsTotal[location.route.name] || {}),
                [m]: {
                  weight,
                },
              }
            }
          }

          return (
            <React.Fragment key={m}>
              <td>
                <span>
                  {Number(
                    location.collections[m] ? location.collections[m].weight : 0
                  ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </span>
              </td>
            </React.Fragment>
          )
        })}
        <td>
          <span>
            {Number(
              location.totals ? location.totals.weight : total
            ).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </span>
        </td>
      </tr>
    )

    if (totals) {
      totals.weight += total
    }

    return row
  }

  render() {
    const {
      locationsReport,
      start = moment()
        .endOf('month')
        .subtract(2, 'months')
        .startOf('month'),
      end = moment().endOf('month'),
      loading,
    } = this.props

    const monthIntervals = getMonthsInterval(start, end)

    let lbsTotal = {}
    const totals = { weight: 0 }
    let currentRoute = ''

    return (
      <div>
        <table
          className="ui sortable table"
          style={{
            display: 'block',
            'overflow-x': 'auto',
          }}
        >
          {this.renderTableHeader(monthIntervals)}
          <tbody>
            {loading ? (
              <Dimmer active>
                <Loader />
              </Dimmer>
            ) : locationsReport && locationsReport.length ? (
              <React.Fragment>
                {locationsReport.map((location, i) => {
                  const displayTotal =
                    currentRoute && currentRoute !== location.route.name
                  const displayLastRouteTotal = i === locationsReport.length - 1

                  const rowElement = this.renderTableRow(
                    location,
                    monthIntervals,
                    lbsTotal,
                    totals
                  )
                  const totalRow = {
                    id: currentRoute,
                    isTotalRow: true,
                    collections: lbsTotal[currentRoute],
                    route: {
                      name: currentRoute,
                    },
                  }
                  const lastRouteTotalRow = {
                    id: location.route.name,
                    isTotalRow: true,
                    collections: lbsTotal[location.route.name],
                    route: {
                      name: location.route.name,
                    },
                  }
                  if (!currentRoute || currentRoute !== location.route.name) {
                    currentRoute = location.route.name
                  }
                  return (
                    <React.Fragment>
                      {displayTotal
                        ? this.renderTableRow(totalRow, monthIntervals)
                        : null}
                      {rowElement}
                      {displayLastRouteTotal
                        ? this.renderTableRow(lastRouteTotalRow, monthIntervals)
                        : null}
                    </React.Fragment>
                  )
                })}
                {this.renderTableRow(
                  {
                    id: 'total-summary',
                    isTotalRow: true,
                    isTotalSummary: true,
                    route: {},
                    totals,
                    collections: monthIntervals.reduce((acc, month) => {
                      acc[month] = Object.keys(lbsTotal).reduce(
                        (lbsAcc, route) => {
                          lbsAcc.weight +=
                            (lbsTotal[route] &&
                              lbsTotal[route][month] &&
                              lbsTotal[route][month].weight) ||
                            0
                          return lbsAcc
                        },
                        { weight: 0 }
                      )
                      return acc
                    }, {}),
                  },
                  monthIntervals
                )}
              </React.Fragment>
            ) : (
              'No data.'
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
