import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions/locations'
import * as QL from '../api/Query/locations'
import _cloneDeep from 'lodash/cloneDeep'
import _every from 'lodash/every'
import _first from 'lodash/first'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _map from 'lodash/map'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import {
  CloseLocations,
  LocationReport,
  Locations as LocationsSelector,
  TaggedLocations,
} from '../selectors/locations'
import { queryRoutes } from '../selectors/routes'
import { generateRangeDate } from '../utils/date'
import WithQuery from './query'

// Default search radius in km
const defaultRadius = 16

const getLocationIds = props => {
  const location = _get(props, 'location')
  const locations = _get(props, 'locations')

  if (_isString(location)) return [location]
  else if (_isObject(location))
    return [_get(location, 'locationId', _get(location, 'id'))]
  if (_isArray(locations))
    return _map(
      locations,
      location =>
        _isObject(location)
          ? _get(location, 'locationId', _get(location, 'id'))
          : location
    )
}

export const WithLocationTagEvents = WithQuery({
  selector: data => ({ location: _get(data, 'location') }),
  QL,
  queryName: 'getLocationHistory',
  variablesPicker: props => {
    const { locationId } = props
    if (_isEmpty(locationId)) return null

    return { locationId }
  },
  fetchPolicy: 'network-only',
})

export const WithLocationMaintenanceRequestsHistory = WithQuery({
  selector: data => ({ location: _get(data, 'location') }),
  QL,
  queryName: 'getLocationMaintenanceRequestsHistory',
  variablesPicker: props => {
    const { locationId } = props
    if (_isEmpty(locationId)) return null

    return { locationId }
  },
  fetchPolicy: 'network-only',
})

export const WithLocationPoliceReportsHistory = WithQuery({
  selector: data => ({ location: _get(data, 'location') }),
  QL,
  queryName: 'getLocationPoliceReportsHistory',
  variablesPicker: props => {
    const { locationId } = props
    if (_isEmpty(locationId)) return null

    return { locationId }
  },
  fetchPolicy: 'network-only',
})

export const WithLocationCompetitorHistory = WithQuery({
  selector: data => ({ location: _get(data, 'location') }),
  QL,
  queryName: 'getLocationCompetitorHistory',
  variablesPicker: props => {
    const { locationId } = props
    if (_isEmpty(locationId)) return null

    return { locationId }
  },
  fetchPolicy: 'network-only',
})

export const WithLocationBinLocationsHistory = WithQuery({
  selector: data => ({ location: _get(data, 'location') }),
  QL,
  queryName: 'getLocationBinLocationsHistory',
  variablesPicker: props => {
    const { locationId } = props
    if (_isEmpty(locationId)) return null

    return { locationId }
  },
  fetchPolicy: 'network-only',
})

export const WithLocationBinsEvents = WithQuery({
  selector: LocationReport,
  QL,
  queryName: 'getLocationBinsHistory',
  variablesPicker: props => {
    const { locationId } = props
    if (_isEmpty(locationId)) return null

    return { locationId }
  },
  fetchPolicy: 'network-only',
})

export const WithLocationEstimatedActualReports = WithQuery({
  selector: LocationReport,
  QL,
  queryName: 'getLocationEstimatedActualReports',
  variablesPicker: props => {
    const { location } = props
    if (_isEmpty(location.id)) return null

    return {
      locationId: location.id,
    }
  },
  fetchPolicy: 'network-only',
})

export const WithLocations = WithQuery({
  selector: LocationsSelector,
  QL,
  queryName: 'getLocations',
  variablesPicker: props => {
    const { locationId, location, locations } = props
    const ids = locationId
      ? [locationId]
      : getLocationIds({ location, locations })

    if (_isEmpty(ids)) return null

    return { ids }
  },
})

export const WithLocationRoutes = WithQuery({
  selector: data => queryRoutes(data, 'locationRoutes'),
  QL,
  queryName: 'getLocationRoutes',
  variablesPicker: props => {
    const { locationId, location } = props
    const id = location ? location.id : locationId

    if (_isEmpty(id)) return null

    return { locationId: id }
  },
  fetchPolicy: 'network-only',
})

export const WithLocationsSearch = WithQuery({
  selector: CloseLocations,
  QL,
  queryName: 'searchLocations',
  fetchPolicy: 'cache-and-network',
  variablesPicker: props => {
    const { geoCoordinates, radius, simple_locations } = props

    if (_isEmpty(geoCoordinates) || !_every(geoCoordinates, Number)) return null

    return {
      geoCoordinates,
      radius: radius || defaultRadius,
      simple_locations: simple_locations,
    }
  },
})

export const WithTaggedLocationsSearch = WithQuery({
  selector: TaggedLocations,
  QL,
  queryName: 'taggedLocations',
  variablesPicker: props => {
    const { binTags: tags, marketId, search } = props

    if (_isEmpty(tags)) return null

    const binTags = _cloneDeep(tags)
    _forEach(binTags, tag => {
      if (
        !!tag.children &&
        !!tag.children.length &&
        tag.children[0].id === 'all'
      ) {
        delete tag.children
      }
    })

    const date = _get(props, 'taggedDate.named', 'this-week')
    let startDate, endDate
    if (date !== 'custom') {
      const generatedRange = generateRangeDate(date)
      startDate = generatedRange.start
      endDate = generatedRange.end
    } else {
      const startDateISOString = _get(props, 'taggedStartDate')
      const endDateISOString = _get(props, 'taggedEndDate')

      if (startDateISOString) {
        startDate = moment.utc(startDateISOString)
      }
      if (endDateISOString) {
        endDate = moment.utc(endDateISOString)
      }
    }

    let marketIds = null

    if (search.marketIds) {
      marketIds = search.marketIds.split(',')
    }

    return { binTags, startDate, endDate, marketId, marketIds }
  },
})

export const WithLocationActions = WrappedComponent =>
  connect(_ => ({}), dispatch => bindActionCreators(actions, dispatch))(
    WrappedComponent
  )

export const WithFetch = Component => {
  class WrappedComponent extends React.Component {
    state = {
      loading: true,
      geoCoordinates: undefined,
    }

    componentDidMount() {
      this.fetch(this.props.url)
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
      const { url } = this.props
      if (prevProps.url !== url) return url
      return null
    }

    componentDidUpdate(prevProps, nextProps, snapshot) {
      if (snapshot) this.fetch(snapshot)
    }

    fetch = url => {
      fetch(url)
        .then(response => {
          return response.json()
        })
        .catch(error => {
          return { error }
        })
        .then(state => {
          if (state.error) return state

          const location = _first(state)
          // Place not found
          if (_isArray(state) && !location)
            return { geoCoordinates: undefined, error: 'Place not found' }

          const lat = _get(location, 'lat')
          const lon = _get(location, 'lon')
          if (lat && lon) return { geoCoordinates: [lat, lon] }
          else return { error: 'Error getting geoCoordinates' }
        })
        .then(state => {
          this.setState({ ...state, loading: false })
        })
    }

    render() {
      return <Component {...this.state} {...this.props} />
    }
  }

  return WrappedComponent
}

export const WithAddressDecode = Component => {
  class WrappedComponent extends React.Component {
    state = {
      loading: true,
      geoCoordinates: undefined,
    }

    componentDidMount() {
      this.decode(this.props.address)
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
      const { address } = this.props
      if (prevProps.address !== address) return address
      return null
    }

    componentDidUpdate(prevProps, nextProps, snapshot) {
      if (snapshot) this.decode(snapshot)
    }

    decode = address => {
      geocodeByAddress(address)
        .then(results => {
          const location = _first(results)

          // Place not found
          if (!location)
            return { geoCoordinates: undefined, error: 'Place not found' }
          return getLatLng(location).then(({ lat, lng }) => ({
            geoCoordinates: [lat, lng],
          }))
        })
        .catch(error => {
          return { error }
        })
        .then(state => {
          this.setState({ ...state, loading: false })
        })
    }

    render() {
      return <Component {...this.state} {...this.props} />
    }
  }

  return WrappedComponent
}

/*
  props: value, onChange, onSelect,
  wrap: results{description}, onChange
*/
export const WithAddressSuggest = Component => {
  class WrappedComponent extends React.PureComponent {
    state = {
      value: this.props.value,
    }

    onChange = value => {
      this.setState({ value })
    }

    middleOnSelect = value => {
      this.setState({ value }, () => {
        this.props.onSelect(value)
      })
    }

    render() {
      const { value, onChange, onSelect, ...restProps } = this.props

      return (
        <PlacesAutocomplete
          value={onChange ? value : this.state.value}
          onChange={onChange || this.onChange}
          onSelect={onChange ? onSelect : this.middleOnSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {
            const inputProps = getInputProps({})
            const results = _map(suggestions, suggestion => ({
              ...suggestion,
              title: suggestion.description,
              props: getSuggestionItemProps(suggestion),
            }))
            return (
              <Component
                results={results}
                loading={loading}
                {...inputProps}
                {...restProps}
              />
            )
          }}
        </PlacesAutocomplete>
      )
    }
  }

  return WrappedComponent
}
