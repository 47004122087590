import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _map from 'lodash/map'
import React from 'react'

import { SimpleLoading } from '../../components/Loading'
import MapMarker from '../../components/MapMarker'
import { Button, Card, Header, Message, Modal } from 'semantic-ui-react'
import { WithLocationsSearch } from '../../connectors/locations'

// Look for near locations to the one about to create
const _RADIUS = 2

export class MapWithNearestLocations extends React.PureComponent {
  locationInfo = location => {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{location.name}</Card.Header>
          <Card.Meta>
            {location.distance && location.distance.toString()}
          </Card.Meta>
          <Card.Description>{location.address}</Card.Description>
        </Card.Content>
      </Card>
    )
  }

  locationPopup = location => {
    const { onSelect } = this.props

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{location.name}</Card.Header>
          <Card.Meta>
            {location.distance && location.distance.toString()}
          </Card.Meta>
          <Card.Description>{location.address}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button
            positive
            content="Use this one"
            onClick={() => onSelect(location)}
          />
        </Card.Content>
      </Card>
    )
  }

  render() {
    const { locations } = this.props

    return (
      <MapMarker
        locations={locations}
        renderLocationTooltip={this.locationInfo}
        renderLocationPopup={this.locationPopup}
      />
    )
  }
}

@WithLocationsSearch
export class ConfirmationModal extends React.PureComponent {
  render() {
    const {
      existingLocation,
      locationData,
      locationId,
      locations,
      open,
      editing,
      deny,
      confirm,
      confirmExisting,
      loading,
    } = this.props
    const specialLocations = [_get(existingLocation, 'id'), locationId]
    let restOfLocations = _filter(
      locations,
      ({ id }) => !_includes(specialLocations, id)
    )
    let foundLocation = _filter(locations, existingLocation => {
      return (
        existingLocation.city === locationData.city &&
        existingLocation.address === locationData.address &&
        existingLocation.state === locationData.state &&
        existingLocation.postal === locationData.postal
      )
    }).length
    const renderPopup = false

    // Display the location with new information
    restOfLocations.push({ ...locationData, role: 'selected', renderPopup })

    if (editing) {
      foundLocation = null
      // Display the location that is about to update
      const editingLocation = _find(locations, { id: locationId })
      if (editingLocation)
        restOfLocations.push({
          ...editingLocation,
          role: 'exist',
          renderPopup,
        })
      // Disable Use Location Popup
      restOfLocations = _map(restOfLocations, location => ({
        ...location,
        renderPopup,
      }))
    }

    // The location already exist by near distance
    if (!editing && existingLocation) {
      restOfLocations.push({ ...existingLocation, role: 'exist' })
    }

    return (
      <Modal open={open} onConfirm={confirm} onCancel={deny}>
        <Header icon="location" content="Confirm this location" />
        <Modal.Content>
          <MapWithNearestLocations
            locations={restOfLocations}
            onSelect={confirmExisting}
          />
          {foundLocation && (
            <Message
              positive
              icon="thumbs up"
              header="This location already exists in the system."
              content="Click on one of the pins to confirm the location"
            />
          )}
        </Modal.Content>
        {!foundLocation && (
          <Modal.Actions>
            <Button negative onClick={deny}>
              Back
            </Button>
            <Button positive onClick={confirm}>
              {!editing ? 'Create' : 'Update'}
            </Button>
          </Modal.Actions>
        )}
        {foundLocation && (
          <Modal.Actions>
            <Button negative onClick={deny}>
              Go Back
            </Button>
          </Modal.Actions>
        )}
        <SimpleLoading loading={loading} />
      </Modal>
    )
  }
}

export default class ArrangeGeocoords extends React.PureComponent {
  render() {
    const { locationData } = this.props
    const role = 'selected'
    const actualLocation = { ...locationData, role }
    const geoCoordinates = [locationData.latitude, locationData.longitude]

    return (
      <ConfirmationModal
        {...this.props}
        location={actualLocation}
        geoCoordinates={geoCoordinates}
        radius={_RADIUS}
      />
    )
  }
}
