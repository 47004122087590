import _map from 'lodash/map'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import MapMarker from '../../components/MapMarker'
import ModalTrigger from '../../components/ModalTrigger'
import BinForm from '../../forms/Bin/Bin'
import { pick } from 'lodash'
import moment from 'moment'
import {
  Button,
  Card,
  Container,
  Grid,
  Header,
  Icon,
  List,
  Segment,
} from 'semantic-ui-react'
import LocationCompetitors from '../../components/LocationCompetitors'
import { PartnershipCard } from '../../forms/AddLocation/partnershipCard'
import AssignLocationToRouteModal from './AssignLocationToRouteModal'
import EditDateConvertedModal from './EditDateConvertedModal'
import EditDateInServiceModal from './EditDateInServiceModal'
import EditDateReAddedModal from './EditDateReAddedModal'
import EditDateRemovedFromRouteModal from './EditDateRemovedFromRouteModal'
import EditDateRepositioningModal from './EditDateRepositioningModal'

const PermitCard = ({
  email,
  phone,
  address,
  signed_by,
  city,
  permit_number,
  issued_date,
  expiration_date,
}) => (
  <Card>
    {!email && !signed_by && !phone && !permit_number ? (
      <Segment vertical textAlign="center">
        <Header as="h4" icon>
          <Icon name="legal" />
          Without Permit
        </Header>
      </Segment>
    ) : (
      <Card.Content>
        <Card.Header>Permit</Card.Header>
        <Card.Meta>Issued Date: {issued_date}</Card.Meta>
        <Card.Description>
          Email <strong>{email}</strong>
          <br />
          Phone <strong>{phone}</strong>
          <br />
          Contact <strong>{address}</strong>
          <br />
          Signed by <strong>{signed_by}</strong>
          <br />
          City <strong>{city}</strong>
          <br />
          Permit Number <strong>{permit_number}</strong>
          <br />
          Expires <strong>{expiration_date}</strong>
          <br />
        </Card.Description>
      </Card.Content>
    )}
  </Card>
)

const buildRouteItem = ({ id, market, color, name }) => ({
  icon: { name: 'circle', style: { color } },
  content: <Link to={`/app/routes/${market.id}/${id}`}>{name}</Link>,
})

const buildBinItem = ({ id, box_code }) => ({
  key: id,
  icon: { name: 'cube' },
  content: (
    <ModalTrigger
      label={<span className="AddLocation_BinListItem">{box_code}</span>}
      title="Viewing Bin"
      id="bin-form-view"
      form={
        <BinForm
          binId={id}
          viewMode={true}
          editButton={
            <ModalTrigger
              trigger={<Button color="orange">Edit</Button>}
              dimmer={true}
              id="bin-form-edit"
              title="Editing Bin"
              form={<BinForm binId={id} />}
              scrolling={true}
            />
          }
        />
      }
      scrolling={true}
    />
  ),
})

class AssignLocationToRouteButton extends React.Component {
  state = {
    showTooltip: false,
  }

  onMouseEnter() {
    const { bins } = this.props

    if (!bins)
      this.setState({
        showTooltip: true,
      })
  }

  onMouseLeave() {
    const { showTooltip } = this.state

    if (showTooltip)
      this.setState({
        showTooltip: false,
      })
  }

  onClick() {
    const { onClick } = this.props

    if (onClick) onClick()
  }

  render() {
    const { bins } = this.props
    const { showTooltip } = this.state

    return (
      <div>
        <span
          class="assign-location-to-route-button-container"
          onMouseEnter={() => this.onMouseEnter()}
          onMouseLeave={() => this.onMouseLeave()}
        >
          {showTooltip && (
            <span class="custom-tooltip">
              At least 1 bin needs to be assigned to the location before it can
              be added to a route.
            </span>
          )}
          <Button
            onClick={() => this.onClick()}
            size="small"
            color="blue"
            content="Assign to route"
            disabled={!!bins ? (bins.length > 0 ? false : true) : true}
          />
        </span>
      </div>
    )
  }
}

export default class LocationInformation extends React.PureComponent {
  state = {
    showEditDateInServiceModal: false,
    showEditDateReAddedModal: false,
    showEditDateRemovedModal: false,
    showEditDateRepositioningModal: false,
    showEditDateConvertedModal: false,
    showAssignToRouteModal: false,
    date_in_service: '',
    date_re_added: '',
    date_removed_from_route: '',
    date_repositioning_date: '',
    converted_date: '',
  }

  componentDidUpdate() {
    const {
      date_in_service,
      date_re_added,
      date_removed_from_route,
      date_repositioning,
      conversion_history,
    } = this.props
    const dates = {
      date_in_service: date_in_service,
      date_re_added: date_re_added,
      date_removed_from_route: date_removed_from_route,
      date_repositioning_date: date_repositioning,
      converted_date: '',
    }
    let updateState = false

    if (this.state.date_in_service !== date_in_service) {
      dates.date_in_service = date_in_service
      updateState = true
    }

    if (this.state.date_re_added !== date_re_added) {
      dates.date_re_added = date_re_added
      updateState = true
    }

    if (this.state.date_removed_from_route !== date_removed_from_route) {
      dates.date_removed_from_route = date_removed_from_route
      updateState = true
    }

    if (this.state.date_repositioning_date !== date_repositioning) {
      dates.date_repositioning_date = date_repositioning
      updateState = true
    }

    if (conversion_history) {
      const date = conversion_history[conversion_history.length - 1].at

      if (this.state.converted_date !== date) {
        dates.converted_date = date
        updateState = true
      }
    }

    if (updateState)
      this.setState({
        ...this.state,
        ...dates,
      })
  }

  showEditDateInServiceModal = () => {
    this.setState({
      showEditDateInServiceModal: true,
    })
  }

  showEditDateReAddedModal = () => {
    this.setState({
      showEditDateReAddedModal: true,
    })
  }

  showEditDateRemovedModal = () => {
    this.setState({
      showEditDateRemovedModal: true,
    })
  }

  showEditDateRepositioningModal = () => {
    this.setState({
      showEditDateRepositioningModal: true,
    })
  }

  showEditDateConvertedModal = () => {
    this.setState({
      showEditDateConvertedModal: true,
    })
  }

  showAssignToRouteModal = () => {
    this.setState({
      showAssignToRouteModal: true,
    })
  }

  onCloseEditDateInServiceModal = () => {
    this.setState({
      showEditDateInServiceModal: false,
    })
  }

  onCloseEditDateReAddedModal = () => {
    this.setState({
      showEditDateReAddedModal: false,
    })
  }

  onCloseEditDateRemovedModal = () => {
    this.setState({
      showEditDateRemovedModal: false,
    })
  }

  onCloseEditDateRepositioningModal = () => {
    this.setState({
      showEditDateRepositioningModal: false,
    })
  }

  onCloseEditDateConvertedModal = () => {
    this.setState({
      showEditDateConvertedModal: false,
    })
  }

  onCloseAssignToRouteModal = () => {
    this.setState({
      showAssignToRouteModal: false,
    })
  }

  onAssignToRouteSuccessful() {}

  renderDates = () => {
    const {
      date_in_service,
      date_re_added,
      date_removed_from_route,
      date_repositioning_date,
      converted_date,
    } = this.state

    return (
      <Fragment>
        <Header
          as="h4"
          content={<label>Date in Service</label>}
          subheader={
            <p className="date-text" onClick={this.showEditDateInServiceModal}>
              {date_in_service
                ? moment(date_in_service).format('MMM DD, YYYY')
                : 'N/A'}
              <Icon className="date-edit-button" name="edit" />
            </p>
          }
        />
        <Header
          as="h4"
          content={<label>Date Removed</label>}
          subheader={
            <p className="date-text" onClick={this.showEditDateRemovedModal}>
              {date_removed_from_route
                ? moment(date_removed_from_route).format('MMM DD, YYYY')
                : 'N/A'}
              <Icon className="date-edit-button" name="edit" />
            </p>
          }
        />
        <Header
          as="h4"
          content={<label>Date Re-Added</label>}
          subheader={
            <p className="date-text" onClick={this.showEditDateReAddedModal}>
              {date_re_added
                ? moment(date_re_added).format('MMM DD, YYYY')
                : 'N/A'}
              <Icon className="date-edit-button" name="edit" />
            </p>
          }
        />
        <Header
          as="h4"
          content={<label>Date Repositioning</label>}
          subheader={
            <p
              className="date-text"
              onClick={this.showEditDateRepositioningModal}
            >
              {date_repositioning_date
                ? moment(date_repositioning_date).format('MMM DD, YYYY')
                : 'N/A'}
              <Icon className="date-edit-button" name="edit" />
            </p>
          }
        />
        <Header
          as="h4"
          content={<label>Date Converted</label>}
          subheader={
            <p className="date-text" onClick={this.showEditDateConvertedModal}>
              {converted_date
                ? moment(converted_date).format('MMM DD, YYYY')
                : 'N/A'}
              <Icon className="date-edit-button" name="edit" />
            </p>
          }
        />
      </Fragment>
    )
  }

  renderModals = () => {
    const { location } = this.props
    const {
      showEditDateInServiceModal,
      showEditDateReAddedModal,
      showEditDateRemovedModal,
      showEditDateRepositioningModal,
      showEditDateConvertedModal,
      showAssignToRouteModal,
      date_in_service,
      date_re_added,
      date_removed_from_route,
      date_repositioning_date,
      converted_date,
    } = this.state

    return (
      <Fragment>
        {showEditDateInServiceModal && (
          <EditDateInServiceModal
            onClose={this.onCloseEditDateInServiceModal}
            date={date_in_service}
            location={location}
          />
        )}
        {showEditDateRemovedModal && (
          <EditDateRemovedFromRouteModal
            onClose={this.onCloseEditDateRemovedModal}
            date={date_removed_from_route}
            location={location}
          />
        )}
        {showEditDateReAddedModal && (
          <EditDateReAddedModal
            onClose={this.onCloseEditDateReAddedModal}
            date={date_re_added}
            location={location}
          />
        )}
        {showEditDateRepositioningModal && (
          <EditDateRepositioningModal
            onClose={this.onCloseEditDateRepositioningModal}
            date={date_repositioning_date}
            location={location}
          />
        )}
        {showEditDateConvertedModal && (
          <EditDateConvertedModal
            onClose={this.onCloseEditDateConvertedModal}
            date={converted_date}
            location={location}
          />
        )}
        {showAssignToRouteModal && (
          <AssignLocationToRouteModal
            onClose={this.onCloseAssignToRouteModal}
            onSuccessful={() => this.onAssignToRouteSuccessful()}
            location={location}
          />
        )}
      </Fragment>
    )
  }

  render() {
    const {
      name,
      fullAddress,
      mapLink,
      locationMark,
      latitude,
      longitude,
      bins,
      status,
      rate,
      partnership,
      permit,
      routes,
      id,
    } = this.props
    const locationMarker = locationMark ? [locationMark] : []

    const Test_MarketId = '-L86Q7JvnDuRU6YRBk0I'
    let cleanedRoutes = routes
      ? routes.filter(
          route => route.market.id !== Test_MarketId && !route.inactive
        )
      : []
    const markets = cleanedRoutes.map(route => route.market.id)

    const partnershipWithAdditionalProps = {
      ...pick(this.props, [
        'leased_location_cost_per_month',
        'leased_location_period_start',
        'leased_location_period_end',
        'signed_location_by',
        'signed_location_date',
        'verbal_location_by',
        'verbal_location_date',
        'leased_atrs_rep',
        'signed_atrs_rep',
        'verbal_atrs_rep',
      ]),
      ...partnership,
    }

    return (
      <Segment className="LocationInformation">
        <Header as="h3">Location Information</Header>
        <Grid>
          <Grid.Row columns="equal" only="tablet computer">
            <Grid.Column>
              <MapMarker locations={locationMarker} />
            </Grid.Column>
            <Grid.Column>
              <Grid columns="equal" stretched className="contain">
                <Grid.Row>
                  <Grid.Column>
                    <Container className="details">
                      <Header as="h4" content={name} subheader={fullAddress} />
                      {this.renderDates()}
                      <Header
                        as="h4"
                        content="Bins"
                        subheader={<List items={_map(bins, buildBinItem)} />}
                      />
                      <Header
                        as="h4"
                        content="Status"
                        subheader={status || '--'}
                      />
                      <Header as="h4" content="Rate" subheader={rate} />
                      <Header
                        as="h4"
                        content="Routes"
                        subheader={
                          cleanedRoutes.length ? (
                            <List items={_map(cleanedRoutes, buildRouteItem)} />
                          ) : (
                            <AssignLocationToRouteButton
                              onClick={() => this.showAssignToRouteModal()}
                              bins={bins}
                            />
                          )
                        }
                      />
                    </Container>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as="h3" content="Partnership" />
                    <PartnershipCard
                      {...partnershipWithAdditionalProps}
                      partnership={partnershipWithAdditionalProps}
                      showEditTrigger={false}
                    />
                    <PermitCard {...permit} as="h4" />
                    <LocationCompetitors locationId={id} markets={markets} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Container>
                    <Button icon href={mapLink} target="_blank">
                      <Icon name="marker" />
                      {latitude} {longitude}
                    </Button>
                  </Container>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1} only="mobile" className="mobile-view">
            <Grid.Column>
              <MapMarker locations={locationMarker} />
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Button icon href={mapLink} target="_blank">
                  <Icon name="marker" />
                  {latitude} {longitude}
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment className="details">
                <Header as="h4" content={name} subheader={fullAddress} />
                {this.renderDates()}
                <Header
                  as="h4"
                  content="Bins"
                  subheader={<List items={_map(bins, buildBinItem)} />}
                />
                <Header as="h4" content="Status" subheader={status || '--'} />
                <Header as="h4" content="Rate" subheader={rate} />
                <Header
                  as="h4"
                  content="Routes"
                  subheader={
                    cleanedRoutes.length ? (
                      <List items={_map(cleanedRoutes, buildRouteItem)} />
                    ) : (
                      <AssignLocationToRouteButton
                        onClick={() => this.showAssignToRouteModal()}
                        bins={bins}
                      />
                    )
                  }
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as="h3" content="Partnership" />
              <PartnershipCard
                {...partnershipWithAdditionalProps}
                partnership={partnershipWithAdditionalProps}
                showEditTrigger={false}
              />
              <PermitCard {...permit} as="h4" />
              <LocationCompetitors locationId={id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderModals()}
      </Segment>
    )
  }
}
