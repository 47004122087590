import React from 'react'

import { Grid, Row, Col } from 'react-flexbox-grid'
import Header from './Header'
import BinsSearch from '../../../views/BinsSearch'

export default class Bins extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <BinsSearch />
      </Grid>
    )
  }
}
