import {
  createLocation as createLocationMutation,
  getLocationMonthlyEstimatedActualReports as getLocationMonthlyEstimatedActualReportsQuery,
  getLocationRoutes as getLocationRoutesQuery,
  getLocations as locationsQL,
} from '../api/Query/locations'
import apolloClient from '../api/apolloClient'
import moment from 'moment'
import {
  setLocationDateConverted as setLocationDateConvertedMutation,
  setLocationDateInService as setLocationDateInServiceMutation,
  setLocationDateReAdded as setLocationDateReAddedMutation,
  setLocationDateRemoved as setLocationDateRemovedMutation,
  setLocationDateRepositioning as setLocationDateRepositioningMutation,
} from '../api/Mutation/locations'

export const getLocations = locationIds => dispatch => {
  return apolloClient
    .query({
      query: locationsQL,
      variables: { locationIds },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.locations)
}

export const createLocation = location => dispatch => {
  return apolloClient
    .query({
      mutation: createLocationMutation,
      variables: { location },
    })
    .then(res => res.data.createLocation)
}

export const getLocationRoutes = locationId => dispatch => {
  return apolloClient
    .query({
      query: getLocationRoutesQuery,
      variables: { locationId },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.locationRoutes)
}

export const setLocationDateInService = (location, date) => dispatch => {
  return apolloClient
    .mutate({
      mutation: setLocationDateInServiceMutation,
      variables: {
        locationId: location.id,
        date: moment(date).toISOString(),
      },
    })
    .then(res => {
      return {
        ...res.data.setLocationDateInService,
        ...location,
      }
    })
}

export const setLocationDateRemoved = (location, date) => dispatch => {
  return apolloClient
    .mutate({
      mutation: setLocationDateRemovedMutation,
      variables: {
        locationId: location.id,
        date: moment(date).toISOString(),
      },
    })
    .then(res => {
      return {
        ...res.data.setLocationDateRemoved,
        ...location,
      }
    })
}

export const setLocationDateReAdded = (location, date) => dispatch => {
  return apolloClient
    .mutate({
      mutation: setLocationDateReAddedMutation,
      variables: {
        locationId: location.id,
        date: moment(date).toISOString(),
      },
    })
    .then(res => {
      return {
        ...res.data.setLocationDateReAdded,
        ...location,
      }
    })
}

export const setLocationDateRepositioning = (location, date) => dispatch => {
  return apolloClient
    .mutate({
      mutation: setLocationDateRepositioningMutation,
      variables: {
        locationId: location.id,
        date: moment(date).toISOString(),
      },
    })
    .then(res => {
      return {
        ...res.data.setLocationDateRepositioning,
        ...location,
      }
    })
}

export const setLocationDateConverted = (location, date) => dispatch => {
  return apolloClient
    .mutate({
      mutation: setLocationDateConvertedMutation,
      variables: {
        locationId: location.id,
        date: moment(date).toISOString(),
      },
    })
    .then(res => {
      return {
        ...res.data.setLocationDateConverted,
        ...location,
      }
    })
}

export const getLocationMonthlyEstimatedActualReports = (
  locationId,
  start,
  end
) => dispatch => {
  return apolloClient
    .query({
      query: getLocationMonthlyEstimatedActualReportsQuery,
      variables: { locationId, start, end },
      fetchPolicy: 'network-only',
    })
    .then(res =>
      res.data.locationMonthlyEstimatedActualReports.map(item => {
        delete item.__typename
        return item
      })
    )
}
