import { withMarketData, withMarkets } from '../../connectors/markets'
import React from 'react'
import { Divider } from 'semantic-ui-react'
import 'url-search-params-polyfill'
import { withAuth } from '../../connectors/auth'
import { WithGoalsForCraReport } from '../../connectors/goals'
import CraRouteReport from './CraRouteReport'
import Filters from './Filters'

@withAuth
@withMarkets
@withMarketData
@WithGoalsForCraReport
export class ReportCraRouteViewIndex extends React.Component {
  extractRoutes() {
    const { data } = this.props
    const routes = []

    if (!data) return routes

    if (!data.goals) return routes

    data.goals.forEach(report => {
      if (report.routes) {
        report.routes.forEach(reportRoute => {
          if (!routes.some(route => route.id === reportRoute.id))
            routes.push({
              id: reportRoute.id,
              name: reportRoute.name,
            })
        })
      }
    })

    return routes
  }

  render() {
    const { location: { search }, loading, data } = this.props

    const query = new URLSearchParams(search)
    const routes = this.extractRoutes()
    let goals = data ? (data.goals ? data.goals[0] : null) : null

    return (
      <div>
        <Divider hidden />
        <Filters loading={loading} routes={routes} />
        <Divider hidden />
        <CraRouteReport
          routes={routes}
          routeId={query.get('route') ? query.get('route').split(',') : []}
          quarter={query.get('quarter') ? query.get('quarter').split(',') : []}
          loading={loading}
          goals={goals}
        />
      </div>
    )
  }
}
