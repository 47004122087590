import DriverPicker from '../../DriverPicker'
import FormField from '../../FormField'
import { withRuns } from '../../../connectors/runs'
import moment from 'moment'
import React, { Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Label,
  Message,
  Modal,
} from 'semantic-ui-react'
import DatePickerSingle from '../../DatePickerSingle'

const validate = args => {
  const fields = [{ id: 'run_date' }]
  const errors = {}

  fields.forEach(field => {
    if (args[field.id] === undefined || args[field.id] === null) {
      errors[field.id] = 'invalid/required'
    }
  })

  return errors
}

@reduxForm({
  form: 'CopyRunForm',
  validate,
})
@withRuns
export class CopyRunModal extends React.Component {
  state = {
    open: true,
    error: null,
    copiedSuccessfully: false,
    runDate: null,
  }

  setOpen(open) {
    this.setState({
      open,
    })
  }

  clearError() {
    this.setState({
      error: null,
    })
  }

  setError(error) {
    this.setState({
      error,
    })
  }

  close() {
    const { onCloseModal } = this.props

    onCloseModal()
    this.setOpen(false)
  }

  copiedSuccessfully(run) {
    const { onSuccessful } = this.props

    onSuccessful({
      driver: run.driver,
      end: moment(run.run_date).endOf('D'),
      helper: run.helper,
      route: run.route,
      runDate: moment(run.run_date).toISOString(),
      runId: run.id,
      start: moment(run.run_date).startOf('D'),
      totals: {
        cloth: null,
        mics: null,
        trash: null,
      },
      optimized_miles: run.optimized_miles || null,
      is_custom: run.is_custom,
    })
    this.setState({
      copiedSuccessfully: true,
    })
  }

  confirm(data) {
    const { run, copy } = this.props
    const { runDate } = this.state
    const run_date = moment(runDate)

    this.clearError()
    copy(
      {
        id: run.runId,
        route: run.route.id,
        driver: data.driver,
        helper: data.helper || null,
        run_date: run_date.toISOString(),
      },
      moment(run_date)
        .set('hours', run_date.get('hours'))
        .set('minutes', run_date.get('minutes'))
        .set('seconds', run_date.get('seconds'))
        .toISOString()
    )
      .then(result => {
        if (result.type === 'RUN_COPY_FAILED')
          this.setError(
            result.payload.graphQLErrors
              ? result.payload.graphQLErrors[0].message
              : result.payload.message
          )
        else if (result.type === 'RUN_COPIED_SUCCESSFULLY')
          this.copiedSuccessfully({
            ...run,
            id: result.payload.id,
            driver: result.payload.driver,
            helper: result.payload.helper,
            route: result.payload.route,
            run_date: result.payload.run_date,
            is_custom: result.payload.is_custom,
          })
      })
      .catch(error => {
        this.setError(error.message)
      })
  }

  onPickDate = date => {
    this.setState({
      runDate: date,
    })
  }

  componentDidMount() {
    const { run, getMarketData, initialValues: { run_date } } = this.props
    this.setState({
      runDate: run_date,
    })
    getMarketData(run.route.market.id)
  }

  render() {
    const {
      run,
      runs: { drivers, loading },
      handleSubmit,
      initialValues: { run_date },
    } = this.props
    const { open, error, copiedSuccessfully } = this.state

    return (
      <Modal
        open={open}
        dimmer={'blurring'}
        size={'tiny'}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Copying Run ...</Modal.Header>
        <Modal.Content>
          {!copiedSuccessfully && (
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <p>
                      <label class="label">Route:</label>
                      <Label
                        circular
                        style={{ backgroundColor: run.route.color }}
                        empty
                      />
                      <span class="label" style={{ color: run.route.color }}>
                        {run.route.name}
                      </span>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <p>
                      <label class="label">Date:</label>
                      <span>{moment(run.runDate).format('MM/DD/YYYY')}</span>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Divider />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      name="driver"
                      label="Driver"
                      component={FormField}
                      formComponent={DriverPicker}
                      drivers={drivers.map(driver => ({
                        value: driver.id,
                        text: `${driver.first_name} ${driver.last_name}`,
                      }))}
                      required
                      loading={loading}
                      disabled={loading}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      name="helper"
                      label="Helper"
                      component={FormField}
                      formComponent={DriverPicker}
                      drivers={drivers.map(driver => ({
                        value: driver.id,
                        text: `${driver.first_name} ${driver.last_name}`,
                      }))}
                      loading={loading}
                      disabled={loading}
                      allowEmpty={true}
                      emptyOptionText="No helper"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label class="label">
                      Please pick the date you want to copy the run to:
                    </label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <DatePickerSingle
                      format="MM/dd/yyyy"
                      value={run_date}
                      onSelect={this.onPickDate}
                      disabled={loading}
                      loading={loading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}

          {error && (
            <Message color="red">
              <Icon name="exclamation triangle" />
              {error}
            </Message>
          )}

          {copiedSuccessfully && (
            <Message color="green">
              <Icon name="check" />
              The run has been copied successfully
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          {copiedSuccessfully ? (
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => this.close()}
              positive
            >
              Done
            </Button>
          ) : (
            <Fragment>
              <Button
                content="Confirm"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit(data => this.confirm(data))}
                positive
              />
              <Button
                loading={loading}
                disabled={loading}
                color="red"
                onClick={() => this.close()}
              >
                Cancel
              </Button>
            </Fragment>
          )}
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CopyRunModal
