import apolloClient from '../api/apolloClient'
import _ from 'lodash'
import {
  copyRun,
  getDriver,
  getMarket,
  getRoute,
  getRunLogs as getRunLogsQuery,
  quickEdit as quickEditMutation,
  reScheduleRun as reScheduleRunMutation,
  saveRun,
  scheduleRun,
} from '../api/Query/runs'

import { trigger } from '../actions'
import { getRoute as getSingleRoute } from '../actions/routes'

export const _RUN_SCHEDULED = 'RUN_SCHEDULED'
export const _RUN_SCHEDULED_ERROR = 'RUN_SCHEDULED_ERROR'
export const _RUN_FETCH_LOADING = 'RUN_FETCH_LOADING'
export const _RUN_FETCH_MARKET = 'RUN_FETCH_MARKET'
export const _RUN_FETCH_ERROR = 'RUN_FETCH_ERROR'
export const _RUN_FETCH_DRIVER = 'RUN_FETCH_DRIVER'
export const _RUN_CLEAR_DRIVER = 'RUN_CLEAR_DRIVER'
export const _RUN_FETCH_ROUTE = 'RUN_FETCH_ROUTE'

export const _RUN_COPY = 'RUN_COPY'
export const _RUN_COPIED_SUCCESSFULLY = 'RUN_COPIED_SUCCESSFULLY'
export const _RUN_COPY_FAILED = 'RUN_COPY_FAILED'

export const _RUN_MOVED_SUCCESSFULLY = 'RUN_MOVED_SUCCESSFULLY'
export const _RUN_MOVE_FAILED = 'RUN_MOVE_FAILED'

export const _RUN_EDITED_SUCCESSFULLY = 'RUN_EDITED_SUCCESSFULLY'
export const _RUN_EDIT_FAILED = 'RUN_EDIT_FAILED'

export const _RUN_SAVED = 'RUN_SAVED'

/**
 * Fetches market data needed for the Schedule Run
 * dropdown
 *
 * @param  {string} marketId Market ID
 * @return {Promise}
 */
export const getMarketData = marketId => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .query({
      query: getMarket,
      variables: { ids: [marketId] },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.marketsByIds)
    .then(markets => {
      if (!markets || !markets.length) {
        throw new Error('Invalid Market')
      }
      return markets[0]
    })
    .then(trigger(_RUN_FETCH_MARKET))
    .catch(trigger(_RUN_FETCH_ERROR))
    .then(dispatch)
}

export const clearDriver = () => dispatch => {
  return dispatch(trigger(_RUN_CLEAR_DRIVER)())
}

export const getDriverInfo = driverId => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .query({
      query: getDriver,
      variables: { driverId },
    })
    .then(res => res.data.userData)
    .then(trigger(_RUN_FETCH_DRIVER))
    .catch(trigger(_RUN_FETCH_ERROR))
    .then(dispatch)
}

export const getRouteInfo = routeId => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .query({
      query: getRoute,
      variables: { routeId },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.route)
    .then(trigger(_RUN_FETCH_ROUTE))
    .catch(trigger(_RUN_FETCH_ERROR))
    .then(dispatch)
}

export const save = run => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .mutate({
      mutation: saveRun,
      variables: { run },
    })
    .then(res => res.data.saveRun)
    .then(trigger(_RUN_SAVED))
    .catch(trigger(_RUN_FETCH_ERROR))
    .then(dispatch)
}

/**
 * Schedule the run
 * @param  {Object} run Run object, See `RunRouteInput` in GraphQL
 * @return {Promise}
 */
export const schedule = (run, recurring, is_custom) => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .mutate({
      mutation: scheduleRun,
      variables: { run, recurring, is_custom },
    })
    .then(trigger(_RUN_SCHEDULED))
    .then(_ => {
      getSingleRoute(run.route)(dispatch)
      return _
    })
    .catch(trigger(_RUN_SCHEDULED_ERROR))
    .then(dispatch)
}

/**
 * Copy a run
 * @param  {Number} id
 * @param  {Date} date
 * @return {Promise}
 */
export const copy = (run, date) => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .mutate({
      mutation: copyRun,
      variables: { run, date },
    })
    .then(result => {
      return result.data.copyRun
    })
    .then(trigger(_RUN_COPIED_SUCCESSFULLY))
    .catch(trigger(_RUN_COPY_FAILED))
    .then(dispatch)
}

/**
 * Reschedule (move) a run
 * @param  {Number} id
 * @param  {Date} date
 * @return {Promise}
 */
export const reScheduleRun = (id, date) => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .mutate({
      mutation: reScheduleRunMutation,
      variables: { id, date },
    })
    .then(result => {
      return result.data.copyRun
    })
    .then(trigger(_RUN_MOVED_SUCCESSFULLY))
    .catch(trigger(_RUN_MOVE_FAILED))
    .then(dispatch)
}

/**
 * Quick Edit a run
 * @param  {Number} id
 * @param  {Date} date
 * @return {Promise}
 */
export const quickEdit = (id, date, driver, helper) => dispatch => {
  dispatch(trigger(_RUN_FETCH_LOADING)())

  return apolloClient
    .mutate({
      mutation: quickEditMutation,
      variables: { id, date, driver, helper: helper || null },
    })
    .then(result => {
      return result.data.quickEdit
    })
    .then(trigger(_RUN_EDITED_SUCCESSFULLY))
    .catch(trigger(_RUN_EDIT_FAILED))
    .then(dispatch)
}

export const getRunLogs = runId => dispatch => {
  return apolloClient
    .query({
      query: getRunLogsQuery,
      variables: { id: runId },
      fetchPolicy: 'network-only',
    })
    .then(res => _.get(res, 'data.runById.logs'))
}
