import { withAuth } from '../../connectors/auth'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
import { generateArrayOfYears, getCurrentYear } from '../../utils/date'
import MarketsDropdown from '../../components/MenuDropdown/Markets'
import { WithGoalsActions } from '../../connectors/goals'
import { withMarkets } from '../../connectors/markets'

const Filters = withRouter(
  ({
    match: { url },
    location: { search },
    history,
    routes,
    loading,
    current,
    auth: { user: { markets, primary_market } },
    generateActualVsGoalsExcelFile,
  }) => {
    // Search from the react router is not up to date!!!
    let query = new URLSearchParams(window.location.search)

    const selectedMarketIds = query.get('selected-markets')
      ? query.get('selected-markets').split(',')
      : []
    const selectedMarketBasedOnRoute = markets.filter(market =>
      selectedMarketIds.includes(market.id)
    )
    const [selectedMarket, setSelectedMarket] = useState(
      query.get('market') || ''
    )
    const [selectedMultipleMarkets, setSelectedMultipleMarkets] = useState({
      selectedMarkets: [...selectedMarketBasedOnRoute],
      selectedMarketIds: [...selectedMarketIds],
    })
    const [reportShouldBeReload, setReportShouldBeReload] = useState(false)
    const [selectedYear, setSelectedYear] = useState(query.get('year') || '')
    const [selectedRoute, setSelectedRoute] = useState('all-routes')
    const [exportToExcelInprogress, setExportToExcelFileInprogress] = useState(
      false
    )

    const marketsOptions = markets.map(market => ({
      text: market.name,
      value: market.id,
    }))
    const singleSelectMarketOptions = [
      {
        text: 'Multiple Markets',
        value: 'multiple-markets',
      },
      ...marketsOptions,
    ]
    const routeOptions =
      routes.length && selectedMarket !== 'multiple-markets'
        ? routes.map(route => ({
            text: route.name,
            value: route.id,
          }))
        : []
    const years = generateArrayOfYears(50)
    years.unshift(getCurrentYear() + 1)

    function onMarketChange(value) {
      setSelectedMarket(value)

      if (value === 'multiple-markets') {
        setSelectedRoute('all-routes')
      } else {
        setSelectedMultipleMarkets({
          selectedMarketIds: [],
          selectedMarkets: [],
        })

        setSelectedRoute('all-routes')
      }
    }

    function onYearChange(value) {
      setSelectedYear(value)
    }

    function onRouteChange(value) {
      setSelectedRoute(value)
    }

    function dropFilter(name) {
      query.delete(name)
      history.push(`${url}?${query.toString()}`)
    }

    function onChangeMultipleSelectMarket(value) {
      const valueContainsTheAllMarketOption = value.some(item => item === 'all')
      let selectedValues = []

      if (valueContainsTheAllMarketOption) {
        if (selectedMultipleMarkets.selectedMarkets.length === markets.length) {
          selectedValues = []
        } else {
          selectedValues = markets
        }
      } else {
        selectedValues = value
      }

      const marketIds = selectedValues.map(item => item.id)

      setSelectedMultipleMarkets({
        selectedMarkets: selectedValues,
        selectedMarketIds: marketIds,
      })
    }

    function doLoadReport() {
      if (selectedMarket) query.set('market', selectedMarket)
      else query.delete('market')

      if (selectedRoute) query.set('route', selectedRoute)
      else query.delete('route')

      if (selectedYear) query.set('year', selectedYear)
      else query.delete('year')

      if (selectedMultipleMarkets.selectedMarketIds.length)
        query.set(
          'selected-markets',
          selectedMultipleMarkets.selectedMarketIds.join(',')
        )
      else query.delete('selected-markets')

      setReportShouldBeReload(false)
      history.push(`${url}?${query.toString()}`)
    }

    function onExportToExcelClick() {
      setExportToExcelFileInprogress(true)
      let selectedMarkets = []

      if (selectedMarket === 'multiple-markets') {
        selectedMarkets = [...selectedMultipleMarkets.selectedMarketIds]
      } else if (selectedMarket !== 'multiple-markets') {
        selectedMarkets = [selectedMarket]
      }

      generateActualVsGoalsExcelFile(
        selectedMarkets,
        selectedRoute,
        selectedYear,
        selectedMarkets.length === markets.length
      )
        .then(data => {
          window.open(data.data.generateActualVsGoalsExcelFile.url)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setExportToExcelFileInprogress(false)
        })
    }

    function renderExportButton() {
      let disabled = true

      if (selectedYear) {
        if (
          selectedMarket === 'multiple-markets' &&
          selectedMultipleMarkets.selectedMarketIds.length > 0
        ) {
          disabled = false
        } else if (selectedMarket !== 'multiple-markets' && selectedMarket) {
          disabled = false
        }
      }

      return (
        <Button
          color="green"
          disabled={disabled || exportToExcelInprogress}
          onClick={onExportToExcelClick}
          loading={exportToExcelInprogress}
        >
          <Icon name="file excel outline" />
          Export to xlsx
        </Button>
      )
    }

    // ComponentDidMount
    useEffect(() => {
      const queryMarketId = query.get('market')
      const selectedMarketId = queryMarketId
        ? queryMarketId
        : current ? current.id : primary_market ? primary_market : null

      setReportShouldBeReload(false)
      setSelectedMarket(selectedMarketId)
      setSelectedYear(query.get('year'))
      setSelectedRoute(query.get('route'))

      const selectedMarketIds = query.get('selected-markets')
        ? query.get('selected-markets').split(',')
        : []
      setSelectedMultipleMarkets({
        selectedMarkets: markets.filter(market =>
          selectedMarketIds.includes(market.id)
        ),
        selectedMarketIds,
      })
    }, [])

    // ComponentDidUpdate by selectedMarket, selectedYear, selectedRoute
    useEffect(
      () => {
        doLoadReport()
      },
      [selectedMarket, selectedYear, selectedRoute]
    )

    // ComponentDidUpdate by selectedMultipleMarkets
    useEffect(
      () => {
        setReportShouldBeReload(true)
      },
      [selectedMultipleMarkets.selectedMarketIds]
    )

    return (
      <Grid className="Reports__Filters">
        <Grid.Column mobile={16} computer={4}>
          <Dropdown
            fluid
            selection
            options={singleSelectMarketOptions}
            placeholder="Select Market"
            onChange={(e, { value }) => {
              onMarketChange(value)
            }}
            value={selectedMarket}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={4} key="col1">
          <Dropdown
            fluid
            value={selectedRoute}
            selection
            defaultValue="all-routes"
            options={
              routeOptions && [
                { text: 'All Routes', value: 'all-routes' },
                ...routeOptions,
              ]
            }
            placeholder="Select Route"
            loading={loading}
            disabled={loading || !routeOptions.length}
            onChange={(e, { value }) => {
              onRouteChange(value)
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={4} key="col2">
          <Dropdown
            fluid
            selection
            defaultValue={selectedYear}
            value={selectedYear}
            options={years.map(year => ({ text: year, value: String(year) }))}
            placeholder="Select Year"
            onChange={(e, { value }) => {
              if (value) {
                onYearChange(value)
              } else {
                dropFilter('year')
              }
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={4} textAlign="right">
          {renderExportButton()}
        </Grid.Column>
        {selectedMarket === 'multiple-markets' && (
          <Fragment>
            <Grid.Column mobile={16} computer={13} key="col3">
              <MarketsDropdown
                activeLabel={false}
                multiple
                showAll={true}
                selected={selectedMultipleMarkets.selectedMarkets}
                onSelect={value => onChangeMultipleSelectMarket(value)}
              />
            </Grid.Column>
            <Grid.Column mobile={16} computer={3} key="col4">
              <Button
                fluid
                primary
                disabled={!reportShouldBeReload}
                onClick={doLoadReport}
              >
                Update Report
              </Button>
            </Grid.Column>
          </Fragment>
        )}
      </Grid>
    )
  }
)

export default withMarkets(WithGoalsActions(withAuth(Filters)))
