import React, { Fragment } from 'react'
// import _isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Checkbox, Dropdown, Icon, Popup, HeaderContent } from 'semantic-ui-react'
import './styles.scss'

import { withRoutes } from '../../connectors/routes'

import DataTable from '../DataTable'
import ModalTrigger from '../../components/ModalTrigger'

import AddLocationForm from '../../forms/AddLocation'
import { CalculateRouteLengthModalError } from '../../views/Route/CalculateRouteLengthModalError'
import RemoveRouteLocationConfirmationModal from '../RemoveRouteLocationConfirmationModal'

@withRoutes
export default class RouteLocationsTable extends React.Component {
  static propTypes = {
    stopColor: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        address: PropTypes.string,
        city: PropTypes.string,
        bins: PropTypes.array,
        includeInRun: PropTypes.bool,
      })
    ),
    onSort: PropTypes.func,
    resetData: PropTypes.bool,
    saveBins: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onSort: () => {},
    resetData: false,
    stopColor: '#000',
    locations: [],
  }

  state = {
    showRemoveLocationConfirmation: false,
    locationForRemove: null,
    routeMileCalculationError: '',
    showRouteMileCalculationError: false,
  }

  showRemoveLocationConfirmation(location) {
    this.setState({
      showRemoveLocationConfirmation: true,
      locationForRemove: location,
    })
  }

  onCloseRemoveLocationConfirmation = () => {
    this.setState({
      showRemoveLocationConfirmation: false,
    })
  }

  onCalculateRouteLengthModalClose = () => {
    this.setState({
      routeMileCalculationError: '',
      showRouteMileCalculationError: false,
    })
  }

  showCalculateRouteLengthModal = message => {
    this.setState({
      routeMileCalculationError: message,
      showRouteMileCalculationError: true,
    })
  }

  deleteLocation = location => () => {
    const {
      isCustomRouteForRun,
      removeLocation,
      routeId,
      deleteLocation,
    } = this.props

    if (!isCustomRouteForRun) {
      deleteLocation(routeId, location.id)
    } else {
      removeLocation(location.id)
    }
  }

  saveLocation = location => {
    const { routeId, getRoute } = this.props

    getRoute(routeId)
  }

	changeLocationService = location => {
		console.log('Set location always_service')
	}

  // shouldComponentUpdate(nextProps) {
  // const { locations, resetData } = this.props
  // const { locations: nextLocations, resetData: nextResetData } = nextProps

  // return (
  //   !_isEqual(locations, nextLocations) || !_isEqual(resetData, nextResetData)
  // )
  // }

  render() {
    const {
      isCustomRouteForRun,
      stopColor,
      locations,
      warning,
      routes,
      toggleAllLocationIiclude_in_run,
      ...props
    } = this.props
    // const routeName = _get(routes, 'route.name')

    if (!locations || !locations.length) {
      return null
    }

    const includeInRunCount = locations.filter(
      item => item.includeInRun === true
    ).length
    const defaultIndeterminateStatus =
      locations.length !== includeInRunCount && includeInRunCount > 0
    const allIncludedInRun = includeInRunCount === locations.length
    const columns = [
      {
        title: '',
        name: 'sequence',
        align: 'center',
        cellClassName: function(rowData) {
          if (!rowData.location.includeInRun && isCustomRouteForRun)
            return 'to-be-removed RouteLocationsTable__sequence'
          else return 'RouteLocationsTable__sequence'
        },
      },
      {
        title: 'Name',
        name: 'name',
        cellClassName: function(rowData) {
          if (!rowData.location.includeInRun && isCustomRouteForRun)
            return 'to-be-removed'
        },
      },
      {
        title: 'Location',
        name: 'address',
        cellClassName: function(rowData) {
          if (!rowData.location.includeInRun && isCustomRouteForRun)
            return 'to-be-removed'
        },
        // Check for address or the UI displays junk data
        renderCellValue: (item, rowData) =>
          rowData.location.address ? item : 'Invalid Address',
      },
      {
        title: 'Bins',
        name: 'bins',
        cellClassName: function(rowData) {
          if (!rowData.location.includeInRun && isCustomRouteForRun)
            return 'to-be-removed'
        },
        renderCellValue: (item, rowData) => {
          // Cases where this should show a warning sign
          const error = !item
            ? 'Location must have bins associated'
            : !rowData.location.address ? 'Invalid address' : null

          return !error ? (
            item.length
          ) : (
            <Popup
              trigger={
                <Icon
                  id={`warning-${rowData.id}`}
                  color="red"
                  name="warning sign"
                />
              }
              content={error}
            />
          )
        },
      },
    ]

	const serviceOptions = [
		{ key: 0, text: 'OFF', value: false },
		{ key: 1, text: 'ON', value: true },
	]

    if (isCustomRouteForRun) {
      columns.push({
        title: (
          <React.Fragment>
            {defaultIndeterminateStatus && (
              <Checkbox
                defaultIndeterminate
                onClick={toggleAllLocationIiclude_in_run}
              />
            )}
            {!defaultIndeterminateStatus && (
              <Checkbox
                checked={allIncludedInRun}
                onClick={toggleAllLocationIiclude_in_run}
              />
            )}
          </React.Fragment>
        ),
        align: 'center',
        cellClassName: function(rowData) {
          if (!rowData.location.includeInRun) return 'to-be-removed'
        },
        renderCellValue: (item, rowData) => {
          return (
            <Checkbox
              checked={rowData.location.includeInRun}
              tooltip="Include in run"
              onChange={(event, data) => {
                const { toggleLocationInclude_in_run } = this.props
                toggleLocationInclude_in_run(rowData.location, data.checked)
              }}
            />
          )
        },
      })
    }

    const [firstColumn, ...otherColumns] = columns
    firstColumn.style = {
      background: stopColor,
    }

    const {
      showRemoveLocationConfirmation,
      locationForRemove,
      routeMileCalculationError,
      showRouteMileCalculationError,
    } = this.state

    return (
      <Fragment>
        {showRemoveLocationConfirmation && (
          <RemoveRouteLocationConfirmationModal
            location={locationForRemove}
            route={routes.route}
            onClose={this.onCloseRemoveLocationConfirmation}
            isCustomRouteForRun={isCustomRouteForRun}
            showCalculateRouteLengthModal={this.showCalculateRouteLengthModal}
          />
        )}
        {showRouteMileCalculationError && (
          <CalculateRouteLengthModalError
            message={routeMileCalculationError}
            onCalculateRouteLengthModalClose={
              this.onCalculateRouteLengthModalClose
            }
          />
        )}
        <DataTable
          columns={[firstColumn, ...otherColumns]}
          data={locations.map((location, index) => ({
            id: location.id,
            sequence: location.stop_sequence,
            name: location.name,
            address: `${location.address}, ${location.city}`,
            bins: location.bins,
            location,
            includeInRun: location.includeInRun,
          }))}
          sortable={true}
          renderMenu={({ item, key }) => (
            <Dropdown icon="ellipsis vertical" floating button className="icon">
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/app/locations/${item.id}`}>
                  View
                </Dropdown.Item>
                <ModalTrigger
                  trigger={<Dropdown.Item>Edit</Dropdown.Item>}
                  form={
                    <AddLocationForm
                      location={item.location}
                      onSubmit={this.saveLocation}
                    />
                  }
                  scrolling={true}
                  title={`${item.name} Bins`}
                />
                {/* {isCustomRouteForRun && (
                <Dropdown.Item onClick={this.deleteLocation(item)}>
                  Remove
                </Dropdown.Item>
              )} */}
                {!isCustomRouteForRun && (
                  <Dropdown.Item
                    onClick={() =>
                      this.showRemoveLocationConfirmation(item.location)
                    }
                  >
                    Remove
                  </Dropdown.Item>
                )}
				{/*
                <Dropdown.Item>
					<HeaderContent>
						Service:{' '}
						<Dropdown
							inline
							options={serviceOptions}
							defaultValue={serviceOptions[0].value}
							onChange={() => this.changeLocationService(item.location)}
							/>
					</HeaderContent>
				</Dropdown.Item>
				*/}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {...props}
        />
      </Fragment>
    )
  }
}
