import moment from 'moment'

const getRange = (duration, fromx = moment(), ops = {}) => {
  const end = fromx.clone()
  if (ops.until === 'yesterday') {
    end.subtract(1, 'days').endOf('day')
  } else if (ops.until === 'today') {
    end.endOf('day')
  } else end.endOf(duration)

  const start = end.clone().startOf(duration)

  return {
    start,
    end,
  }
}

const getLastRange = duration =>
  getRange(duration, moment().subtract(1, `${duration}s`))

export const generateRangeDate = value => {
  const [type, duration] = value.split('-')
  switch (type) {
    case 'this':
      return getRange(duration, moment(), { until: 'today' })
    case 'last':
      return getLastRange(duration)
    default:
      return getRange('week')
  }
}

export const dateDifference = (startDate, endDate) => {
  const tempDur = moment.duration(
    moment(endDate).diff(moment(startDate)),
    'milliseconds'
  )
  const hours = tempDur.hours()
  const minutes = tempDur.minutes()

  return {
    total: `${pad(tempDur.hours())}:${pad(tempDur.minutes())}`,
    hours,
    minutes,
  }
}

export const pad = n => (n < 10 ? `0${n}` : n)

export const getCurrentYear = () => {
	return moment().year()
}

export function generateArrayOfYears(n = 9) {
// 	const dateNow = new Date()
//   var max = dateNow.getFullYear()
	var max = getCurrentYear()
	// var max = moment().year()
  var min = max - n
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}
