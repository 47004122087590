import { createSelector } from 'reselect'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _reduce from 'lodash/reduce'
import _map from 'lodash/map'
import _has from 'lodash/has'
import _assign from 'lodash/assign'
import _every from 'lodash/every'
import _compose from 'lodash/fp/compose'

// Old compatibility
// Weight where store in the root of bin
const fixOldWeight = run =>
  _assign({}, run, {
    locations: _map(_get(run, 'locations'), stop =>
      _assign({}, stop, {
        bins: _map(_get(stop, 'bins'), bin => {
          const weight = _get(bin, 'report.weight', _get(bin, 'weight'))
          // Old compatibility
          // Weight where store in the root of bin
          return _assign({}, bin, {
            report: _assign({}, _get(bin, 'report', {}), { weight }),
          })
        }),
      })
    ),
  })

const reduceBinsTotal = (total, bin) => {
  return _get(bin, 'report.weight', 0) + total
}

const isBinFiniched = bin => {
  return _has(bin, 'report.weight')
}

const Stop = stop => {
  const bins = _get(stop, 'bins')

  return {
    ...stop,
    weight: _reduce(bins, reduceBinsTotal, 0),
    complete: _every(bins, isBinFiniched),
  }
}

const gattherLocationsWeight = run => {
  return {
    ...run,
    locations: _map(_get(run, 'locations'), Stop),
  }
}

const buildReport = run => {
  const { total_misc, total_cloth, total_trash } = run

  if (total_misc + total_cloth + total_trash === 0) return run

  return {
    ...run,
    totals: {
      Cloth: { name: 'Cloth', value: total_cloth },
      Misc: { name: 'Misc', value: total_misc },
      Trash: { name: 'Trash', value: total_trash },
    },
  }
}

// There is a bug with GraphQL JSON compressor (it mixs all repeated things)
// So we make the data uniqe to prevent mixing on the back-end
// We need to remove uniqer!
const removeTagUniqer = run => {
  if (!run.locations) return run

  run.locations.forEach(location => {
    if (!location.bins) return true

    location.bins.forEach(bin => {
      if (!_has(bin, 'report.tagInfo')) return true
      if (!bin.report.tagInfo) return true

      bin.report.tagInfo.forEach(tag => {
        tag.id = tag.id.replace(/[|]/g, '')
      })
    })
  })

  return run
}

const arrangeRun = _compose(buildReport, gattherLocationsWeight, fixOldWeight)
export const Run = createSelector(
  (data, entry) => _get(data, entry),
  run => {
    if (_isEmpty(run)) return {}

    const cleanedRun = removeTagUniqer(run)

    return {
      run: arrangeRun(cleanedRun),
    }
  }
)
