export function contractSelectionLabel(label) {
  switch (label) {
    case 'Next to ours (within 10 feet)':
      return 'NX'
    case 'Close to ours (within 25 feet)':
      return 'CL'
    case 'Same parking lot':
      return 'SL'
    case 'Across street':
      return 'AS'
    default:
      return label
  }
}

export function parseCompetitorHistoryType(type) {
  switch (type) {
    case 'add':
      return 'Added'
    case 'remove':
      return 'Removed'
    default:
      return type
  }
}
