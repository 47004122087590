import React, { Fragment } from 'react'
import { List, Label, Button, Message } from 'semantic-ui-react'
import { contractSelectionLabel } from '../../utils/competitors'
import { RemoveCompetitorModal } from './removeCompetitorModal'

export class CurrentCompetitors extends React.Component {
  state = {
    selectedCompetitorToRemove: null,
  }

  selectCompetitorToRemove = competitor => {
    this.setState({
      selectedCompetitorToRemove: competitor,
    })
  }

  deselectCompetitorToRemove = () => {
    this.setState({
      selectedCompetitorToRemove: null,
    })
  }

  render() {
    const {
      currentCompetitors,
      locationId,
      simpleList,
      showRemoveButton,
    } = this.props
    const { selectedCompetitorToRemove } = this.state

    return (
      <React.Fragment>
        {simpleList ? (
          <List size="small" className="current_competitor_list simple_list">
            {currentCompetitors.map(competitor => {
              return (
                <List.Item className="current_competitor_list_item">
                  <List.Content verticalAlign="middle">
                    <div class="current_competitor_list_item_title">
                      {competitor.label}
                    </div>
                    <div class="current_competitor_list_item_labels">
                      {competitor.selections.map(selection => (
                        <Label size="mini" color="gray">
                          {contractSelectionLabel(selection.label)}
                        </Label>
                      ))}
                    </div>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        ) : (
          <Fragment>
            {currentCompetitors.length > 0 ? (
              <List
                devided
                relaxed
                size="small"
                className="current_competitor_list full_list"
              >
                {currentCompetitors.map(competitor => {
                  return (
                    <List.Item className="current_competitor_list_item">
                      <List.Icon
                        name="truck"
                        size="big"
                        verticalAlign="middle"
                        className="current_competitor_list_item_icon"
                      />
                      <div class="current_competitor_list_item_title_labels">
                        <div class="current_competitor_list_item_title">
                          {competitor.label}
                        </div>
                        <div class="current_competitor_list_item_labels">
                          {competitor.selections.map(selection => (
                            <Label size="tiny" color="gray">
                              {contractSelectionLabel(selection.label)}
                            </Label>
                          ))}
                        </div>
                      </div>
                      <List.Content className="current_competitor_list_item_button">
                        {showRemoveButton && (
                          <Button
                            compact
                            size="small"
                            color="red"
                            onClick={() =>
                              this.selectCompetitorToRemove(competitor)
                            }
                          >
                            Remove
                          </Button>
                        )}
                      </List.Content>
                    </List.Item>
                  )
                })}
              </List>
            ) : (
              <Message.Content>No competitor exists!</Message.Content>
            )}
          </Fragment>
        )}

        {selectedCompetitorToRemove && (
          <RemoveCompetitorModal
            locationId={locationId}
            onClose={this.deselectCompetitorToRemove}
            competitor={selectedCompetitorToRemove}
          />
        )}
      </React.Fragment>
    )
  }
}
