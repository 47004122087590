import gql from 'graphql-tag'
import { Market } from '../Query/markets'

export const createMarket = gql`
  mutation createMarket(
    $market: MarketInput!
  ) {
    createMarket(
      market: $market
    ) {
      ${Market}
    }
  }
`

export const updateMarket = gql`
  mutation updateMarket(
    $marketId: String!
    $market: MarketInput!
  ) {
    updateMarket(
      marketId: $marketId
      market: $market
    ) {
      ${Market}
    }
  }
`
