export const permitFields = [
  'id',
  'signed_by',
  'email',
  'address',
  'phone',
  'city',
  'permit_number',
  'issued_date',
  'expiration_date',
  'status',
]

export const Permit = `
  id
  signed_by
  email
  address
  phone
  city
  permit_number
  issued_date
  expiration_date
  status
`
