import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import 'url-search-params-polyfill'

import { withBinActions, withBins } from '../../../connectors/bins'
import { withMarkets } from '../../../connectors/markets'
import { Col, Grid, Row } from 'react-flexbox-grid'

import Confirmation from '../../../components/Confirmation'
import Loading from '../../../components/Loading'
import BinGrid from '../../../components/BinManagement/BinGrid'
import ModalTrigger from '../../../components/ModalTrigger'
import Header from './Header'

import { withAuth } from '../../../connectors/auth'
import BinForm from '../../../forms/Bin/Bin'
import MaintenanceRequestForm from '../../../forms/Bin/MaintenanceRequest'
import PoliceReportForm from '../../../forms/Bin/PoliceReport'
import { Button, ButtonContent, Dropdown, Icon, Input } from 'semantic-ui-react'
import MissingBinsMap from '../../../components/MissingBinsMap'
import './style.scss'

@withRouter
@withBinActions
@withBins
@withAuth
@withMarkets
class BinManagementLayout extends React.PureComponent {
  state = {
    status: 'assigned',
  }

  filterOptions = [
    {
      key: 'All bins',
      text: 'All bins',
      value: 'all',
    },
    {
      key: 'Assigned',
      text: 'Assigned',
      value: 'assigned',
      selected: true,
    },
    {
      key: 'Unassigned',
      text: 'Unassigned',
      value: 'unassigned',
    },
    {
      key: 'Missing',
      text: 'Missing',
      value: 'missing',
    },
    {
      key: 'Deactivated',
      text: 'Deactivated',
      value: 'deactivated',
    },
    {
      key: 'No Market',
      text: 'No Market',
      value: 'no-market',
    },
  ]

  getMarkets() {
    const { auth: { user: { markets } } } = this.props
    const marketIds = markets.map(market => market.id)

    return marketIds
  }

  componentDidMount() {
    const { fetch } = this.props
    const { status } = this.state

    this.resetBinFilters()
    const markets = this.getMarkets()
    fetch(false, status, markets)
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search }, filterByMarket } = this.props
    const oldQuery = new URLSearchParams(search)
    const nextQuery = new URLSearchParams(nextProps.location.search)

    if (oldQuery.get('market') !== nextQuery.get('market')) {
      filterByMarket(nextQuery.get('market'))
    }
  }

  resetBinFilters() {
    const { resetFilters, location: { search } } = this.props
    const { status } = this.state
    const query = new URLSearchParams(search)

    resetFilters({
      market: query.get('market'),
      page: 0,
      search: '',
      status: status,
    })
  }

  handleFilterTypeChange = (_e, { value }) => {
    const { fetch, setStatusFilter } = this.props

    const { status } = this.state
    let markets = []

    if (value !== status) {
      if (
        value === 'assigned' ||
        value === 'deactivated' ||
        value === 'all' ||
        value === 'missing'
      )
        markets = this.getMarkets()

      this.setState({ status: value })
      setStatusFilter(value)
      fetch(false, value, markets)
    }
  }

  handleSearchInput = event => {
    const { search } = this.props
    search(event.target.value)
  }

  render() {
    const {
      loadingBins,
      filteredBins,
      deactivate,
      filterByMarket,
      filterByRoute,
      binsFilterRouteOption,
      current,
    } = this.props
    const { status } = this.state

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header
              filterByRoute={filterByRoute}
              filterByMarket={filterByMarket}
              binsFilterRouteOption={binsFilterRouteOption}
              children={
                <Fragment>
                  <Dropdown
                    id="filter-bin-type-dropdown"
                    options={this.filterOptions}
                    onChange={this.handleFilterTypeChange}
                    defaultValue={status}
                  />
                  <Input
                    id="search-bin-input"
                    placeholder="Search bins ..."
                    onInput={this.handleSearchInput}
                  />
                  {status === 'missing' && (
                    <ModalTrigger
                      size="large"
                      trigger={
                        <Button
                          compact
                          positive
                          size="mini"
                          id="search-bin-map-button"
                        >
                          <ButtonContent visible>
                            <Icon name="marker" />
                            Map
                          </ButtonContent>
                        </Button>
                      }
                      title="Missing bins"
                      scrolling={true}
                      form={<MissingBinsMap market={current} />}
                    />
                  )}
                </Fragment>
              }
            />
          </Col>
        </Row>

        <Route
          path="/app/bin-management"
          render={() => [
            loadingBins && <Loading key="loadingBins" height={300} />,
            !loadingBins && <BinGrid bins={filteredBins} key="binsGrid" />,
          ]}
        />

        <Route
          path="/app/bin-management/:binId?"
          render={({
            match: { params: { binId }, url },
            location: { search },
            history,
          }) => {
            const query = new URLSearchParams(search)

            return (
              <div>
                <ModalTrigger
                  open={query.get('view') ? true : false}
                  title="Viewing Bin"
                  form={<BinForm binId={binId} viewMode={true} />}
                  scrolling={true}
                  onClose={() => {
                    history.goBack()
                  }}
                />

                <ModalTrigger
                  open={query.get('edit') ? true : false}
                  title="Editing Bin"
                  form={<BinForm binId={binId} />}
                  scrolling={true}
                  onClose={() => {
                    history.goBack()
                  }}
                />

                <Confirmation
                  open={query.get('deactivate') ? true : false}
                  title="Deactivating Bin"
                  createLabel="Deactivate"
                  onConfirm={() => {
                    history.replace(url)
                    deactivate(binId)
                  }}
                  onClose={() => {
                    history.replace(url)
                  }}
                  message="Are you sure to deactivate this Bin?"
                />

                <ModalTrigger
                  open={query.get('maintenance-request') ? true : false}
                  title="Create Maintenance Request"
                  form={<MaintenanceRequestForm binId={binId} />}
                  scrolling={true}
                  onClose={() => {
                    history.push(`/app/bin-management`)
                  }}
                />

                <ModalTrigger
                  open={query.get('police-report') ? true : false}
                  title="Create Police Report"
                  form={<PoliceReportForm binId={binId} />}
                  scrolling={true}
                  onClose={() => {
                    history.goBack()
                  }}
                />
              </div>
            )
          }}
        />
      </Grid>
    )
  }
}

export default BinManagementLayout
