import React from 'react'
import _get from 'lodash/get'

import WithQuerySearch from '../../connectors/querySearch'
import { Divider, Header, Segment } from 'semantic-ui-react'
import { SearchTaggedListLocations } from '../../components/ListLocations'
import SearchBar from './SearchBar'

@WithQuerySearch
class WrapSearch extends React.PureComponent {
  render() {
    const { search } = this.props
    const binTags = JSON.parse(_get(search, 'bin_tags', 'null'))
    const marketId = _get(search, 'marketId')
    const taggedDate = JSON.parse(_get(search, 'tagged_date', 'null'))
    const taggedEndDate = _get(search, 'tagged_end_date', null)
    const taggedStartDate = _get(search, 'tagged_start_date', null)

    return (
      <Segment padded={false} className="BinsSearch__container">
        <Divider hidden />
        <SearchTaggedListLocations
          {...this.props}
          marketId={marketId}
          binTags={binTags}
          taggedDate={taggedDate}
          taggedEndDate={taggedEndDate}
          taggedStartDate={taggedStartDate}
        />
      </Segment>
    )
  }
}

export default class SearchPanel extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Header size="large">Maintenance report</Header>
        <SearchBar />
        <WrapSearch />
      </React.Fragment>
    )
  }
}
