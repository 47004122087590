import React from 'react'

import { Card } from 'semantic-ui-react'

import ReportChart from '../ReportChart'
import './styles.scss'

const margin = { top: 5, right: 30, left: 20, bottom: 5 }

export default ({ title, data, dataKeys, allParamsAreSet, ...props }) => (
  <Card fluid>
    <Card.Content header={title} />
    <Card.Content>
      {data &&
        allParamsAreSet && (
          <ReportChart
            width={700}
            height={400}
            margin={margin}
            data={data}
            dataKeys={dataKeys}
            {...props}
          />
        )}

      {!allParamsAreSet && (
        <span className="ChartCard__no-data">
          Please select a Market, Route, and Date range in order to generate the
          report
        </span>
      )}
      {!data &&
        allParamsAreSet && (
          <span className="ChartCard__no-data">No Data Available</span>
        )}
    </Card.Content>
  </Card>
)
