import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Dropdown, Icon } from 'semantic-ui-react'

const ReportsButton = () => (
  <Dropdown
    floating
    pointing
    icon={null}
    trigger={
      <Button icon color="blue">
        <Icon name="file text outline" /> Reports
      </Button>
    }
  >
    <Dropdown.Menu>
      <Dropdown.Item>
        <Link to="/app/reports">All Reports</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/summary">Summary Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/drivers">Drivers Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/npo-locations">NPO Locations Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/leased-locations">Leased Locations Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/verbal-locations">
          Signed or Verbal Locations Report
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/permit">Permit Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/property-manager">Property Manager Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/locations-performance">
          Locations Performance Report
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/actuals-vs-goals">Actual vs. Goals Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/previous-year-comparison">
          Previous Year Comparison
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/monthly-route-cost-analysis">
          Monthly Route Cost Analysis
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/quarterly-route-cost-analysis">
          Quarterly Route Cost Analysis
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/cumulative-quarterly-review-route-cost-analysis">
          Cumulative Quarterly Review Route Cost Analysis
        </Link>
      </Dropdown.Item>
      {/* <Dropdown.Item>
        <Link to="/app/reports/monthly-goal">Monthly Goal Report</Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link to="/app/reports/annual-goal">Annual Goal Report</Link>
      </Dropdown.Item> */}
      <Dropdown.Item>
        <Link to="/app/reports/cra-route">CRA Route Report</Link>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)

export default ReportsButton
