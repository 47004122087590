import React from 'react'

import { Link } from 'react-router-dom'

import { LoadingMessage } from '../../../components/Loading'
import { Button, Message } from 'semantic-ui-react'

import _get from 'lodash/get'
import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'

import { withAuth } from '../../../connectors/auth'
import WithMutator from '../../../connectors/mutator'
import { withForm } from '../../../connectors/forms'

import {
  WithPoliceReport,
  withPoliceReportFetchAction,
} from '../../../connectors/policeReports'

import moment from 'moment'

import PoliceReportForm from './form'

@withAuth
@withPoliceReportFetchAction
@withForm('PoliceReportForm')
class PoliceReport extends React.PureComponent {
  onSubmit = data => {
    const {
      createPoliceReport,
      updatePoliceReport,
      policeReport,
      editing,
      fetch,
    } = this.props

    const policeReportData = _pick(data, [
      'binId',
      'userId',
      'reason',
      'date_of_incident',
      'description_of_damages_notes',
      'approximate_associated_costs',
      'report_number',
      'responding_officers_name',
      'photos',
      'documents',
      'notes_on_follow_up_with_police',
    ])

    if (!_isEmpty(policeReportData.date_of_incident)) {
      policeReportData.date_of_incident = moment(
        policeReportData.date_of_incident
      ).toISOString()
    }

    if (editing) {
      policeReportData.id = _get(policeReport, 'id')
      updatePoliceReport({
        policeReportId: _get(policeReport, 'id'),
        policeReport: policeReportData,
      }).then(_res => {
        fetch()
      })
    } else {
      policeReportData.status = 'new'
      createPoliceReport({ policeReport: policeReportData }).then(_res => {
        fetch()
      })
    }
  }

  componentDidUpdate() {
    this.renderButtons()
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      mutating,
      result,
      viewMode,
    } = this.props

    if (result)
      return renderButtons(
        <Button positive onClick={closeModal}>
          Close
        </Button>
      )

    return renderButtons(
      <div>
        <Button negative onClick={closeModal} disabled={mutating}>
          Close
        </Button>
        {!viewMode && (
          <Button positive onClick={submitForm} disabled={mutating}>
            Submit
          </Button>
        )}
      </div>
    )
  }

  render() {
    const {
      error,
      loading,
      mutating,
      result,
      policeReport,
      binId,
      viewMode,
      editing,
    } = this.props

    if (result)
      return (
        <Message positive>
          Police Report saved. If you want to create Maintenance Request for
          same Bin,
          <Link
            to={`/app/bin-management/${result.binId}?maintenance-request=true`}
          >
            click here
          </Link>.
        </Message>
      )
    if (mutating) return <LoadingMessage title="Saving Police Report..." />
    if (loading) return <LoadingMessage title="Loading Police Report info..." />
    if (error)
      return (
        <Message
          error
          header="Error"
          key="policeReportInfo"
          content={error.description}
        />
      )

    return (
      <PoliceReportForm
        key="PoliceReportForm"
        onSubmit={this.onSubmit}
        initialValues={
          policeReport && {
            ...policeReport,
          }
        }
        editing={editing}
        error={error}
        binId={binId}
        viewMode={viewMode}
      />
    )
  }
}

export default class SwitchFormAction extends React.PureComponent {
  render() {
    const editing =
      _has(this.props, 'policeReport') || _has(this.props, 'policeReportId')

    const Form = editing
      ? WithMutator('updatePoliceReport')(WithPoliceReport(PoliceReport))
      : WithMutator('createPoliceReport')(PoliceReport)

    return <Form {...this.props} editing={editing} />
  }
}
