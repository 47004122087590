import MarketsDropdown from '../../components/MenuDropdown/Markets'
import moment from 'moment'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react'
import DatePickerSingle from '../../components/DatePickerSingle'
import { withAuth } from '../../connectors/auth'
import { WithRouteCostActions } from '../../connectors/routeCostAnalysis'

const Filters = withRouter(
  ({
    auth: { user: { markets } },
    match: { url },
    location: { search },
    history,
    routes,
    loading,
    generateRouteCostExcelFile,
  }) => {
    // Search from the react router is not up to date!!!
    let query = new URLSearchParams(window.location.search)

    const [exportToExcelInprogress, setExportToExcelFileInprogress] = useState(
      false
    )

    function resetMarket() {
      query = new URLSearchParams(window.location.search)
      query.delete('market')
      history.push(`${url}?${query.toString()}`)
    }

    function onMarketChange(market) {
      query = new URLSearchParams(window.location.search)
      query.set('market', market.id)
      query.delete('route')
      history.push(`${url}?${query.toString()}`)
    }

    function dropFilter(name) {
      query = new URLSearchParams(window.location.search)
      query.delete(name)
      history.push(`${url}?${query.toString()}`)
    }

    function onPickDate(value) {
      const date = moment(value).format('YYYY-MM')
      query = new URLSearchParams(window.location.search)
      query.set('date', date)
      history.push(`${url}?${query.toString()}`)
    }

    function onPreviousYearIncludedChange(value) {
      query = new URLSearchParams(window.location.search)
      query.set('include-previous-year', value)
      history.push(`${url}?${query.toString()}`)
    }

    function onRouteChange(value) {
      query = new URLSearchParams(window.location.search)
      let selectedRoutes = []

      if (value.includes('all-routes')) {
        if (routeOptions.length !== value.length) {
          selectedRoutes = routeOptions
            .filter(option => option.value !== 'all-routes')
            .map(item => item.value)
        }
      } else {
        selectedRoutes = value
      }

      if (selectedRoutes.length) {
        query.set('route', selectedRoutes.join(','))
      } else {
        query.delete('route')
      }

      history.push(`${url}?${query.toString()}`)
    }

    function onExportToExcelClick() {
      setExportToExcelFileInprogress(true)

      const marketFilterValue = query.get('market')
        ? [query.get('market')]
        : markets.map(market => market.id)
      const routesFilterValue = query.get('route')
        ? query.get('route').split(',')
        : routeOptions.length > 1
          ? routeOptions
              .filter(option => option.value !== 'all-routes')
              .map(item => item.value)
          : []
      const dateFilterValue = query.get('date')
      const includePreviousYearFilterValue =
        query.get('include-previous-year') === 'true' ? true : false
      let allRoutes = true

      if (query.get('route')) {
        const selectedRoutes = query.get('route').split(',')

        if (selectedRoutes.length + 1 !== routeOptions.length) allRoutes = false
      }

      generateRouteCostExcelFile(
        marketFilterValue,
        routesFilterValue,
        allRoutes,
        dateFilterValue,
        includePreviousYearFilterValue
      )
        .then(data => {
          // console.log(data)
          window.open(data.data.generateRouteCostExcelFile.url)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setExportToExcelFileInprogress(false)
        })
    }

    function renderExportButton() {
      let disabled = true
      const selectedMonth =
        query.get('date') && moment(query.get('date')).isValid()
          ? moment(query.get('date')).toDate()
          : null

      if (selectedMonth) disabled = false

      return (
        <Button
          color="green"
          disabled={disabled || exportToExcelInprogress}
          onClick={onExportToExcelClick}
          loading={exportToExcelInprogress}
        >
          <Icon name="file excel outline" />
          Export to xlsx
        </Button>
      )
    }

    const routeOptions = [{ text: 'All Routes', value: 'all-routes' }]
    const previousYearIncludedOptions = [
      {
        text: 'Hide Previous Year Report',
        value: false,
      },
      {
        text: 'Show Previous Year Report',
        value: true,
      },
    ]

    if (query.has('market') && routes) {
      routes.forEach(route => {
        routeOptions.push({
          text: route.name,
          value: route.id,
        })
      })
    }

    return (
      <Grid className="Reports__Filters">
        <Grid.Column mobile={16} computer={3} key="col1">
          <MarketsDropdown
            activeLabel={false}
            label="All Markets"
            showAll
            selected={query.get('market')}
            onSelect={market => {
              if (!market) {
                resetMarket()
              } else {
                onMarketChange(market)
              }
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} key="col2">
          <Dropdown
            fluid
            value={query.get('route') ? query.get('route').split(',') : []}
            selection
            defaultValue="all-routes"
            options={routeOptions}
            multiple
            placeholder="All Routes"
            loading={loading}
            disabled={loading || !routes.length || !query.has('market')}
            onChange={(e, { value }) => {
              if (value) onRouteChange(value)
              else dropFilter('route')
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} key="col3">
          <DatePickerSingle
            format="yyyy-MM"
            placeholder="Pick a date"
            value={
              query.get('date') && moment(query.get('date')).isValid()
                ? moment(query.get('date')).toDate()
                : null
            }
            onSelect={onPickDate}
            disabled={loading}
            loading={loading}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          computer={4}
          key="col4"
          style={{ display: 'flex', alignItems: 'start' }}
        >
          <Dropdown
            fluid
            value={query.get('include-previous-year') === 'true' ? true : false}
            selection
            defaultValue={false}
            options={previousYearIncludedOptions}
            loading={loading}
            disabled={loading}
            onChange={(e, { value }) => {
              onPreviousYearIncludedChange(value)
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={3} textAlign="right">
          {renderExportButton()}
        </Grid.Column>
      </Grid>
    )
  }
)

export default withAuth(WithRouteCostActions(Filters))
