import { connect } from 'react-redux'
import {
  arrangeRoute,
  arrangeRoutes,
  arrangeLocations,
} from '../selectors/markerMap'

export const WithMarkersByRouteLocations = WrappedComponent =>
  connect((state, props) => arrangeRoute(state, props.route))(WrappedComponent)

export const WithMarkersByRoutesLocations = WrappedComponent =>
  connect((state, props) => arrangeRoutes(props))(WrappedComponent)

export const WithLocations = WrappedComponent =>
  connect((state, props) => arrangeLocations(props))(WrappedComponent)
