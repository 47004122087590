import React, { Fragment } from 'react'
import {
  Dimmer,
  //   Grid,
  Header,
  Loader,
  Message,
} from 'semantic-ui-react'
import 'url-search-params-polyfill'

import { cloneDeep } from 'lodash'
import './style.scss'

function formatAsDecimal(num, maximumFractionDigits = 2) {
  return Number(num || '').toLocaleString(undefined, {
    maximumFractionDigits,
  })
}

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]

const quarterMonths = {
  q1: ['jan', 'feb', 'mar'],
  q2: ['apr', 'may', 'jun'],
  q3: ['jul', 'aug', 'sep'],
  q4: ['oct', 'nov', 'dec'],
}

const monthReportTemplate = {
  goal: 0,
  wawa: 0,
  gl_percentage: 0,
}

const quarterReportTemplate = {
  goal: 0,
  goal_cntr: 0,
  wawa: 0,
  wawa_cntr: 0,
  gl_percentage: 0,
}

export default class CraRouteReport extends React.PureComponent {
  renderNoDataMessage() {
    return (
      <Message black>
        <Message.Header>There is no data to display!</Message.Header>
        <p>Please provide the requirements to load the report</p>
      </Message>
    )
  }

  renderRouteQuarter(route, quarter) {
    const tds = []

    quarterMonths[quarter].forEach(month => {
      tds.push([
        <td className={`data-${quarter}`}>
          {formatAsDecimal(route[month].goal, 2)}
        </td>,
        <td className={`data-${quarter}`}>
          {formatAsDecimal(route[month].wawa, 2)}
        </td>,
        <td className={`data-${quarter}`}>
          {isFinite(route[month].gl_percentage)
            ? `${formatAsDecimal(Math.round(route[month].gl_percentage, 2))}%`
            : 'N/A'}
        </td>,
      ])
    })

    tds.push([
      <td className={`data-${quarter}`}>
        {formatAsDecimal(route[quarter].goal, 2)}
      </td>,
      <td className={`data-${quarter}`}>
        {isFinite(route[quarter].goal_cntr)
          ? `${formatAsDecimal(Math.round(route[quarter].goal_cntr, 2))}%`
          : 'N/A'}
      </td>,
      <td className={`data-${quarter}`}>
        {formatAsDecimal(route[quarter].wawa, 2)}
      </td>,
      <td className={`data-${quarter}`}>
        {isFinite(route[quarter].gl_percentage)
          ? `${formatAsDecimal(Math.round(route[quarter].gl_percentage, 2))}%`
          : 'N/A'}
      </td>,
      <td className={`data-${quarter}`}>
        {isFinite(route[quarter].wawa_cntr)
          ? `${formatAsDecimal(Math.round(route[quarter].wawa_cntr, 2))}%`
          : 'N/A'}
      </td>,
    ])

    return tds
  }

  renderRouteAllQuarter(route) {
    const tds = []

    tds.push([
      <td>{formatAsDecimal(route['all_quarters'].goal, 2)}</td>,
      <td>
        {isFinite(route['all_quarters'].goal_cntr)
          ? `${formatAsDecimal(
              Math.round(route['all_quarters'].goal_cntr, 2)
            )}%`
          : 'N/A'}
      </td>,
      <td>{formatAsDecimal(route['all_quarters'].wawa, 2)}</td>,
      <td>
        {isFinite(route['all_quarters'].gl_percentage)
          ? `${formatAsDecimal(
              Math.round(route['all_quarters'].gl_percentage, 2)
            )}%`
          : 'N/A'}
      </td>,
      <td>
        {isFinite(route['all_quarters'].wawa_cntr)
          ? `${formatAsDecimal(
              Math.round(route['all_quarters'].wawa_cntr, 2)
            )}%`
          : 'N/A'}
      </td>,
    ])

    return tds
  }

  renderTableHeader() {
    const { quarter } = this.props
    const firstTrTds = []
    const secondTrTds = []
    const trs = [[], []]
    let quartersToShow = ['q1', 'q2', 'q3', 'q4']

    if (quarter.length > 0)
      quartersToShow = quarter.sort((a, b) => {
        return Number(a.replace('q', '')) - Number(b.replace('q', ''))
      })

    firstTrTds.push(
      <th className="title" rowSpan={2}>
        ROUTE
      </th>
    )

    quartersToShow.forEach(quarterKey => {
      quarterMonths[quarterKey].forEach(month => {
        firstTrTds.push(
          <th className={`header-${quarterKey}`} colSpan={3}>
            {month.toUpperCase()}
          </th>
        )
        secondTrTds.push(
          <th className={`header-${quarterKey}`}>GOAL</th>,
          <th className={`header-${quarterKey}`}>WAWA</th>,
          <th className={`header-${quarterKey}`}>%/GL</th>
        )
      })

      firstTrTds.push(
        <th className={`header-${quarterKey}`} colSpan={5}>
          {quarterKey.toUpperCase()}
        </th>
      )
      secondTrTds.push(
        <th className={`header-${quarterKey}`}>GOAL</th>,
        <th className={`header-${quarterKey}`}>%CNTR</th>,
        <th className={`header-${quarterKey}`}>WAWA</th>,
        <th className={`header-${quarterKey}`}>%/GL</th>,
        <th className={`header-${quarterKey}`}>%CNTR</th>
      )
    })

    firstTrTds.push(
      <th className={'header-quarter-all'} colSpan={5}>
        Total/YTD
      </th>
    )
    secondTrTds.push(
      <th className={'header-quarter-all'}>GOAL</th>,
      <th className={'header-quarter-all'}>%CNTR</th>,
      <th className={'header-quarter-all'}>WAWA</th>,
      <th className={'header-quarter-all'}>%/GL</th>,
      <th className={'header-quarter-all'}>%CNTR</th>
    )

    trs[0].push(<tr>{firstTrTds}</tr>)
    trs[0].push(<tr>{secondTrTds}</tr>)

    return <thead>{trs}</thead>
  }

  renderTotalRowQuarter(quarter, totals) {
    const tds = []

    quarterMonths[quarter].forEach(month => {
      tds.push([
        <td className={`total-row-${quarter}`}>
          {formatAsDecimal(totals[month].goal, 2)}
        </td>,
        <td className={`total-row-${quarter}`}>
          {formatAsDecimal(totals[month].wawa, 2)}
        </td>,
        <td className={`total-row-${quarter}`}>
          {isFinite(totals[month].gl_percentage)
            ? `${formatAsDecimal(Math.round(totals[month].gl_percentage, 2))}%`
            : 'N/A'}
        </td>,
      ])
    })

    tds.push([
      <td className={`total-row-${quarter}`}>
        {formatAsDecimal(totals[quarter].goal, 2)}
      </td>,
      <td className={`total-row-${quarter}`}>
        {isFinite(totals[quarter].goal_cntr)
          ? `${formatAsDecimal(Math.round(totals[quarter].goal_cntr, 2))}%`
          : 'N/A'}
      </td>,
      <td className={`total-row-${quarter}`}>
        {formatAsDecimal(totals[quarter].wawa, 2)}
      </td>,
      <td className={`total-row-${quarter}`}>
        {isFinite(totals[quarter].gl_percentage)
          ? `${formatAsDecimal(Math.round(totals[quarter].gl_percentage, 2))}%`
          : 'N/A'}
      </td>,
      <td className={`total-row-${quarter}`}>
        {isFinite(totals[quarter].wawa_cntr)
          ? `${formatAsDecimal(Math.round(totals[quarter].wawa_cntr, 2))}%`
          : 'N/A'}
      </td>,
    ])

    return tds
  }

  renderTotalRowAllQuarter(totals) {
    const tds = []

    tds.push([
      <td className={`total-row-q-all`}>
        {formatAsDecimal(totals['all_quarters'].goal, 2)}
      </td>,
      <td className={`total-row-q-all`}>
        {/* {isFinite(totals['all_quarters'].goal_cntr)
          ? `${formatAsDecimal(
              Math.round(totals['all_quarters'].goal_cntr, 2)
            )}%`
          : 'N/A'} */}
      </td>,
      <td className={`total-row-q-all`}>
        {formatAsDecimal(totals['all_quarters'].wawa, 2)}
      </td>,
      <td className={`total-row-q-all`}>
        {isFinite(totals['all_quarters'].gl_percentage)
          ? `${formatAsDecimal(
              Math.round(totals['all_quarters'].gl_percentage, 2)
            )}%`
          : 'N/A'}
      </td>,
      <td className={`total-row-q-all`}>
        {/* {isFinite(totals['all_quarters'].wawa_cntr)
          ? `${formatAsDecimal(
              Math.round(totals['all_quarters'].wawa_cntr, 2)
            )}%`
          : 'N/A'} */}
      </td>,
    ])

    return tds
  }

  renderPrintLayout(quarters, report, market, year) {
    return (
      <div id="cra-route-report-table-container-print-version">
        <Header as="h1" className="report-title">
          CRA Route Report
        </Header>
        <Header as="h2" className="market-header">
          {market && market.name} {year}
        </Header>

        {quarters.length > 0
          ? quarters.includes('q1') &&
            this.renderPrintLayoutQuarter('Quarter 1', 'q1', report)
          : this.renderPrintLayoutQuarter('Quarter 1', 'q1', report)}
        {quarters.length > 0
          ? quarters.includes('q2') &&
            this.renderPrintLayoutQuarter('Quarter 2', 'q2', report)
          : this.renderPrintLayoutQuarter('Quarter 2', 'q2', report)}
        {quarters.length > 0
          ? quarters.includes('q3') &&
            this.renderPrintLayoutQuarter('Quarter 3', 'q3', report)
          : this.renderPrintLayoutQuarter('Quarter 3', 'q3', report)}
        {quarters.length > 0
          ? quarters.includes('q4') &&
            this.renderPrintLayoutQuarter('Quarter 4', 'q4', report)
          : this.renderPrintLayoutQuarter('Quarter 4', 'q4', report)}
        {this.renderPrintLayoutYTD('Total/YTD', report)}
      </div>
    )
  }

  renderPrintLayoutQuarter(header, quarter, report) {
    const firstTrTds = []
    const secondTrTds = []
    const headerTrs = [[], []]

    firstTrTds.push(
      <th className="title" rowSpan={2}>
        ROUTE
      </th>
    )

    quarterMonths[quarter].forEach(month => {
      firstTrTds.push(
        <th className={`header-${quarter}`} colSpan={3}>
          {month.toUpperCase()}
        </th>
      )
      secondTrTds.push(
        <th className={`header-${quarter}`}>GOAL</th>,
        <th className={`header-${quarter}`}>WAWA</th>,
        <th className={`header-${quarter}`}>%/GL</th>
      )
    })

    firstTrTds.push(
      <th className={`header-${quarter}`} colSpan={5}>
        {quarter.toUpperCase()}
      </th>
    )
    secondTrTds.push(
      <th className={`header-${quarter}`}>GOAL</th>,
      <th className={`header-${quarter}`}>%CNTR</th>,
      <th className={`header-${quarter}`}>WAWA</th>,
      <th className={`header-${quarter}`}>%/GL</th>,
      <th className={`header-${quarter}`}>%CNTR</th>
    )

    headerTrs[0].push(<tr>{firstTrTds}</tr>)
    headerTrs[0].push(<tr>{secondTrTds}</tr>)

    return (
      <Fragment>
        <Header>{header}</Header>
        <table style={{ width: '100%' }}>
          <thead>{headerTrs}</thead>
          <tbody>
            {report.routes.map(route => {
              return (
                <tr>
                  <td className="title">{route.name}</td>
                  {quarter === 'q1' && this.renderRouteQuarter(route, 'q1')}
                  {quarter === 'q2' && this.renderRouteQuarter(route, 'q2')}
                  {quarter === 'q3' && this.renderRouteQuarter(route, 'q3')}
                  {quarter === 'q4' && this.renderRouteQuarter(route, 'q4')}
                </tr>
              )
            })}
            <tr>
              <td className="title">Total</td>
              {quarter === 'q1' &&
                this.renderTotalRowQuarter('q1', report.total)}
              {quarter === 'q2' &&
                this.renderTotalRowQuarter('q2', report.total)}
              {quarter === 'q3' &&
                this.renderTotalRowQuarter('q3', report.total)}
              {quarter === 'q4' &&
                this.renderTotalRowQuarter('q4', report.total)}
            </tr>
          </tbody>
        </table>
      </Fragment>
    )
  }

  renderPrintLayoutYTD(header, report) {
    const headerTds = [
      <th className="title">ROUTE</th>,
      <th className={'header-quarter-all'}>GOAL</th>,
      <th className={'header-quarter-all'}>%CNTR</th>,
      <th className={'header-quarter-all'}>WAWA</th>,
      <th className={'header-quarter-all'}>%/GL</th>,
      <th className={'header-quarter-all'}>%CNTR</th>,
    ]

    return (
      <Fragment>
        <Header>{header}</Header>
        <table>
          <thead>{headerTds}</thead>
          <tbody>
            {report.routes.map(route => {
              return (
                <tr>
                  <td className="title">{route.name}</td>
                  {this.renderRouteAllQuarter(route)}
                </tr>
              )
            })}
            <tr>
              <td>Total</td>
              {this.renderTotalRowAllQuarter(report.total)}
            </tr>
          </tbody>
        </table>
      </Fragment>
    )
  }

  showReport() {
    const { goals, quarter, markets, marketId, routeId, year } = this.props
    let quartersToCalculate = ['q1', 'q2', 'q3', 'q4']
    let market = null

    if (quarter.length > 0) quartersToCalculate = quarter

    if (marketId) market = markets.find(market => market.id === marketId)

    // Begin filter routes
    let routes = []
    if (routeId.length > 0) {
      goals.routes.forEach(
        route => (routeId.includes(route.id) ? routes.push(route) : null)
      )
    } else {
      routes = [...goals.routes]
    }
    // End filter routes

    // Begin Calculate report
    const report = {
      routes: [],
      total: {
        jan: cloneDeep(monthReportTemplate),
        feb: cloneDeep(monthReportTemplate),
        mar: cloneDeep(monthReportTemplate),
        apr: cloneDeep(monthReportTemplate),
        may: cloneDeep(monthReportTemplate),
        jun: cloneDeep(monthReportTemplate),
        jul: cloneDeep(monthReportTemplate),
        aug: cloneDeep(monthReportTemplate),
        sep: cloneDeep(monthReportTemplate),
        oct: cloneDeep(monthReportTemplate),
        nov: cloneDeep(monthReportTemplate),
        dec: cloneDeep(monthReportTemplate),
        q1: cloneDeep(quarterReportTemplate),
        q2: cloneDeep(quarterReportTemplate),
        q3: cloneDeep(quarterReportTemplate),
        q4: cloneDeep(quarterReportTemplate),
        all_quarters: cloneDeep(quarterReportTemplate),
      },
    }

    routes.forEach(route => {
      const routeReport = {
        q1: {
          goal: 0,
          goal_cntr: 0,
          wawa: 0,
          gl_percentage: 0,
          wawa_cntr: 0,
        },
        q2: {
          goal: 0,
          goal_cntr: 0,
          wawa: 0,
          gl_percentage: 0,
          wawa_cntr: 0,
        },
        q3: {
          goal: 0,
          goal_cntr: 0,
          wawa: 0,
          gl_percentage: 0,
          wawa_cntr: 0,
        },
        q4: {
          goal: 0,
          goal_cntr: 0,
          wawa: 0,
          gl_percentage: 0,
          wawa_cntr: 0,
        },
        all_quarters: {
          goal: 0,
          goal_cntr: 0,
          wawa: 0,
          gl_percentage: 0,
          wawa_cntr: 0,
        },
      }
      const actual = {
        ...route.actuals.quarter_1,
        ...route.actuals.quarter_2,
        ...route.actuals.quarter_3,
        ...route.actuals.quarter_4,
      }

      routeReport['name'] = route.name

      months.forEach(month => {
        let quarterNumber = 0

        Object.keys(quarterMonths).forEach(quarterKey => {
          if (quarterMonths[quarterKey].includes(month))
            quarterNumber = quarterKey
        })

        // Calculate total row
        report.total[month].goal += Math.ceil(route.goals[month])
        report.total[month].wawa += actual[month]
        report.total[month].gl_percentage =
          report.total[month].wawa / report.total[month].goal * 100

        report.total[quarterNumber].goal += Math.ceil(route.goals[month])
        report.total[quarterNumber].wawa += actual[month]
        report.total[quarterNumber].gl_percentage =
          report.total[quarterNumber].wawa /
          report.total[quarterNumber].goal *
          100

        if (quartersToCalculate.includes(quarterNumber)) {
          report.total.all_quarters.goal += Math.ceil(route.goals[month])
          report.total.all_quarters.wawa += actual[month]
          report.total.all_quarters.gl_percentage =
            report.total.all_quarters.wawa /
            report.total.all_quarters.goal *
            100
        }

        // Calculate month report for route
        routeReport[month] = {
          goal: Math.ceil(route.goals[month]),
          wawa: actual[month],
          gl_percentage: actual[month] / Math.ceil(route.goals[month]) * 100,
        }

        // Calculate quarter report for route
        routeReport[quarterNumber].goal += Math.ceil(route.goals[month])
        routeReport[quarterNumber].wawa += actual[month]
        routeReport[quarterNumber].gl_percentage =
          routeReport[quarterNumber].wawa /
          routeReport[quarterNumber].goal *
          100

        // Calculate all quarters report for route
        if (quartersToCalculate.includes(quarterNumber)) {
          routeReport.all_quarters.goal += Math.ceil(route.goals[month])
          routeReport.all_quarters.wawa += actual[month]
          routeReport.all_quarters.gl_percentage =
            routeReport.all_quarters.wawa / routeReport.all_quarters.goal * 100
        }
      })

      report.routes.push(routeReport)
    })
    // End Calculate report

    // Begin Calculate CNTR
    const sectionsToCalculateCNTR = [...quartersToCalculate, 'all_quarters']
    sectionsToCalculateCNTR.forEach(CNTRSection => {
      report.routes.forEach(routeReport => {
        const goalCNTR =
          routeReport[CNTRSection].goal / report.total[CNTRSection].goal * 100
        const wawaCNTR =
          routeReport[CNTRSection].wawa / report.total[CNTRSection].wawa * 100

        routeReport[CNTRSection].goal_cntr = goalCNTR
        routeReport[CNTRSection].wawa_cntr = wawaCNTR

        report.total[CNTRSection].goal_cntr += goalCNTR
        report.total[CNTRSection].wawa_cntr += wawaCNTR
      })
    })
    // End Calculate CNTR

    return [
      <div
        style={{
          overflowX: 'auto',
        }}
        className="cra-route-report-table-container"
      >
        <table className="ui sortable table cra-route-report-table">
          {this.renderTableHeader()}
          <tbody>
            {report.routes.map(route => {
              return (
                <tr>
                  <td className="title">{route.name}</td>
                  {quarter.length > 0
                    ? quarter.includes('q1') &&
                      this.renderRouteQuarter(route, 'q1')
                    : this.renderRouteQuarter(route, 'q1')}
                  {quarter.length > 0
                    ? quarter.includes('q2') &&
                      this.renderRouteQuarter(route, 'q2')
                    : this.renderRouteQuarter(route, 'q2')}
                  {quarter.length > 0
                    ? quarter.includes('q3') &&
                      this.renderRouteQuarter(route, 'q3')
                    : this.renderRouteQuarter(route, 'q3')}
                  {quarter.length > 0
                    ? quarter.includes('q4') &&
                      this.renderRouteQuarter(route, 'q4')
                    : this.renderRouteQuarter(route, 'q4')}
                  {this.renderRouteAllQuarter(route)}
                </tr>
              )
            })}
            <tr>
              <td className="title">Total</td>
              {quarter.length > 0
                ? quarter.includes('q1') &&
                  this.renderTotalRowQuarter('q1', report.total)
                : this.renderTotalRowQuarter('q1', report.total)}
              {quarter.length > 0
                ? quarter.includes('q2') &&
                  this.renderTotalRowQuarter('q2', report.total)
                : this.renderTotalRowQuarter('q2', report.total)}
              {quarter.length > 0
                ? quarter.includes('q3') &&
                  this.renderTotalRowQuarter('q3', report.total)
                : this.renderTotalRowQuarter('q3', report.total)}
              {quarter.length > 0
                ? quarter.includes('q4') &&
                  this.renderTotalRowQuarter('q4', report.total)
                : this.renderTotalRowQuarter('q4', report.total)}
              {this.renderTotalRowAllQuarter(report.total)}
            </tr>
          </tbody>
        </table>
      </div>,
      this.renderPrintLayout(quarter, report, market, year),
    ]
  }

  render() {
    const { goals, loading } = this.props

    return (
      <Fragment>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {!goals && this.renderNoDataMessage()}
        {goals && this.showReport()}
      </Fragment>
    )
  }
}
