import apolloClient from '../api/apolloClient'
import {
  generateActualVsGoalsExcelFile as generateActualVsGoalsExcelFileMutation,
  generateGoalsExcelFile as generateGoalsExcelFileMutation,
} from '../api/Mutation/goals'

export const generateGoalsExcelFile = (markets, year, allMarkets) => {
  return dispatch => {
    return apolloClient.mutate({
      mutation: generateGoalsExcelFileMutation,
      variables: {
        markets,
        year,
        allMarkets,
      },
    })
  }
}

export const generateActualVsGoalsExcelFile = (
  markets,
  route,
  year,
  allMarkets
) => {
  return dispatch => {
    return apolloClient.mutate({
      mutation: generateActualVsGoalsExcelFileMutation,
      variables: {
        markets,
        route,
        year,
        allMarkets,
      },
    })
  }
}
