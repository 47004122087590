import apolloClient from '../api/apolloClient'
import {
  generateCumulativeQuarterlyRouteCostExcelFileMutation,
  generateQuarterlyRouteCostExcelFileMutation,
  generateRouteCostExcelFileMutation,
  saveRouteCostMutation,
} from '../api/Mutation/routeCost'
import { getRouteCostQuery } from '../api/Query/routeCost'

export const getRouteCostAction = ({
  markets,
  routes,
  year,
  month,
  previous_year_included,
}) => {
  return apolloClient.query({
    query: getRouteCostQuery,
    variables: { markets, routes, year, month, previous_year_included },
    fetchPolicy: 'network-only',
  })
}

export const saveRouteCostAction = ({ marketId, report, year, month }) => {
  return apolloClient.mutate({
    mutation: saveRouteCostMutation,
    variables: {
      marketId,
      report,
      year,
      month,
    },
  })
}

export const generateRouteCostExcelFile = (
  markets,
  routes,
  allRoutes,
  date,
  includePreviousYear
) => {
  return dispatch => {
    return apolloClient.mutate({
      mutation: generateRouteCostExcelFileMutation,
      variables: {
        markets,
        routes,
        allRoutes,
        date,
        includePreviousYear,
      },
    })
  }
}

export const generateQuarterlyRouteCostExcelFile = (
  market,
  routes,
  allRoutes,
  year,
  quarter,
  includePreviousYear
) => {
  return dispatch => {
    return apolloClient.mutate({
      mutation: generateQuarterlyRouteCostExcelFileMutation,
      variables: {
        market,
        routes,
        allRoutes,
        year,
        quarter,
        includePreviousYear,
      },
    })
  }
}

export const generateCumulativeQuarterlyRouteCostExcelFile = (
  market,
  routes,
  allRoutes,
  year,
  includePreviousYear
) => {
  return dispatch => {
    return apolloClient.mutate({
      mutation: generateCumulativeQuarterlyRouteCostExcelFileMutation,
      variables: {
        market,
        routes,
        allRoutes,
        year,
        includePreviousYear,
      },
    })
  }
}
