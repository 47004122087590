import React from 'react'
import _get from 'lodash/get'

import { Header as SemanticHeader, Button } from 'semantic-ui-react'
import ContentHeader, { HeaderItem } from '../../components/ContentHeader'
import LocationTagEvents from '../../components/LocationTagEvents'
import { AddLocationModal } from '../../forms/AddLocation'

class Header extends React.PureComponent {
  render() {
    const { location } = this.props

    return (
      <ContentHeader
        showBackButton
        title={location ? 'Location' : 'Locations'}
        contentRight={[
          <HeaderItem key="tag-history">
            <LocationTagEvents
              trigger={<Button basic>Tag history</Button>}
              locationId={_get(location, 'id')}
            />
          </HeaderItem>,
          <HeaderItem key="edit-location">
            <AddLocationModal
              trigger={<Button icon="pencil">Edit</Button>}
              locationId={_get(location, 'id')}
            />
          </HeaderItem>,
        ]}
      >
        {location && (
          <SemanticHeader as="h2">
            {_get(location, 'name')}
            <SemanticHeader.Subheader>
              {_get(location, 'address')}
            </SemanticHeader.Subheader>
          </SemanticHeader>
        )}
      </ContentHeader>
    )
  }
}

export default Header
