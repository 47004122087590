import React from 'react'
import { Query } from 'react-apollo'

import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _map from 'lodash/map'

import * as actions from '../actions/markets'
import * as QL from '../api/Query/markets'
import { routesByMarket } from '../api/Query/routesByMarket'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { queryRoutes } from '../selectors/routes'
import {
  Markets as MarketsSelector,
  getCurrentMarket,
  getCurrentMarketData,
  getReportData,
  getReportDataRange,
  getWeeklyReport,
} from '../selectors/markets'

export const withMarkets = WrappedComponent =>
  connect(
    state => ({
      markets: state.markets,
      current: getCurrentMarket(state),
      currentData: getCurrentMarketData(state),
    }),
    dispatch => bindActionCreators(actions, dispatch)
  )(WrappedComponent)

export const withMarketData = WrappedComponent =>
  connect(state => ({
    marketData: getReportData(state),
    marketDataRange: getReportDataRange(state),
    marketReport: getWeeklyReport(state),
  }))(WrappedComponent)

export const WithRoutes = Component => {
  class WrappedComponent extends React.Component {
    render() {
      const { marketId, ...rest } = this.props

      if (!marketId) return <Component {...rest} />

      return (
        <Query query={routesByMarket} variables={{ marketId }}>
          {({ loading, error, data }) => (
            <Component
              error={error}
              loading={loading}
              {...queryRoutes(data, 'routesByMarket')}
              {...rest}
            />
          )}
        </Query>
      )
    }
  }

  return WrappedComponent
}

const getMarketIds = props => {
  const market = _get(props, 'market')
  const markets = _get(props, 'markets')

  if (_isString(market)) return [market]
  else if (_isObject(market))
    return [_get(market, 'marketId', _get(market, 'id'))]
  if (_isArray(markets))
    return _map(
      markets,
      market =>
        _isObject(market)
          ? _get(market, 'marketId', _get(market, 'id'))
          : market
    )
}
export const WithMarkets = Component => {
  class WrappedComponent extends React.Component {
    render() {
      const { marketId, market, markets, ...rest } = this.props
      const ids = marketId ? [marketId] : getMarketIds({ market, markets })

      if (_isEmpty(ids)) {
        return <Component {...rest} />
      }

      return (
        <Query query={QL.getMarkets} variables={{ ids }}>
          {({ loading, error, data }) => (
            <Component
              {...rest}
              error={error}
              loading={loading}
              {...MarketsSelector(data, 'marketsByIds')}
            />
          )}
        </Query>
      )
    }
  }

  return WrappedComponent
}
