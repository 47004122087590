import React, { useState, useEffect } from 'react'
import './styles.scss'
import config from '../../config'

const Version = () => {
	const [apiVersion, setApiVersion] = useState(null)

	const UI_VERSION = process.env.REACT_APP_VERSION

	useEffect(() => {
		fetch(config.FUNCTIONS_URI + '/version')
			.then(res => res.json())
			.then(res => { setApiVersion(res.version) })
			.catch(err => console.log('Error retrieving API version', err))
	}, [])

	return (
		<div className="nav__version">
			<div>ui {UI_VERSION}</div>
			{apiVersion && <div>api {apiVersion}</div>}
		</div>
	)
}

export default Version
