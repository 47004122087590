import React from 'react'
import 'url-search-params-polyfill'
import moment from 'moment'
import { Loader, Dimmer } from 'semantic-ui-react'

export default class DriversReportTable extends React.PureComponent {
  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>
            <span>Driver</span>
          </th>
          <th>
            <span>Period</span>
          </th>
          <th>
            <span>Route</span>
          </th>
          <th>
            <span>Frequency</span>
          </th>
          <th>
            <span>Lbs</span>
          </th>
          <th>
            <span>AVG Lbs</span>
          </th>
          <th>
            <span>Miles</span>
          </th>
          <th>
            <span>AVG Miles</span>
          </th>
          <th>
            <span>HRS</span>
          </th>
          <th>
            <span>AVG HRS</span>
          </th>
          <th>
            <span>Accuracy</span>
          </th>
        </tr>
      </thead>
    )
  }

  formatNumber = n =>
    Number(n).toLocaleString(undefined, { maximumFractionDigits: 2 })

  renderTableRow(reportRow) {
    const {
      start = moment()
        .subtract(2, 'month')
        .set('date', 1)
        .toDate(),
      end = moment()
        .add(1, 'month')
        .set('date', 1)
        .subtract(1, 'day')
        .toDate(),
    } = this.props

    let periodColumn = `${moment(start).format('MM/DD/YY')} 
                          - ${moment(end).format('MM/DD/YY')}
                        `

    const row = (
      <tr
        key={`${reportRow.id}-${reportRow.isTotalRow ? 'total' : ''}`}
        style={{
          fontWeight: reportRow.isTotalRow ? 'bold' : 'normal',
          backgroundColor: reportRow.isTotalRow ? 'rgba(0,0,0,0.2)' : null,
        }}
      >
        <td>
          <span>{reportRow.driver}</span>
        </td>
        <td>
          <span>{periodColumn}</span>
        </td>
        <td>
          <span>{reportRow.route}</span>
        </td>
        <td>
          <span>{reportRow.frequency}</span>
        </td>
        <td>
          <span>{this.formatNumber(reportRow.lbs)}</span>
        </td>
        <td>
          <span>{this.formatNumber(reportRow.avgLbs)}</span>
        </td>
        <td>
          <span>{this.formatNumber(reportRow.miles)}</span>
        </td>
        <td>
          <span>{this.formatNumber(reportRow.avgMiles)}</span>
        </td>
        <td>
          <span>{this.formatNumber(reportRow.hours)}</span>
        </td>
        <td>
          <span>{this.formatNumber(reportRow.avgHours)}</span>
        </td>
        <td>
          <span>{this.formatNumber(reportRow.accuracy)}%</span>
        </td>
      </tr>
    )

    return row
  }

  renderDriverData = reportGroup => {
    const rows = reportGroup.reports.map(report => this.renderTableRow(report))
    rows.push(this.renderSubtotalRow(reportGroup.reports, reportGroup.driverId))

    return rows
  }

  renderTotalRow() {
    const { driversReport } = this.props

    const row = driversReport.find(report => report.isTotalRow)
    return row ? this.renderTableRow(row) : null
  }

  renderSubtotalRow(reports, key) {
    let frequency = 0
    let lbs = 0
    let miles = 0
    let hours = 0
    let accuracies = 0

    reports.forEach(report => {
      frequency += report.frequency
      lbs += report.lbs
      miles += report.miles
      hours += report.hours
      accuracies += report.accuracy
    })

    const row = (
      <tr
        key={`dirver-subtotal-${key}`}
        style={{ fontWeight: 'bold', backgroundColor: 'rgba(0,0,0,0.10)' }}
      >
        <td>
          <span>Total {reports[0].driver}</span> {/* name */}
        </td>
        <td>
          <span /> {/* period */}
        </td>
        <td>
          <span />
          {/* route */}
        </td>
        <td>
          <span>{this.formatNumber(frequency)}</span>
          {/* frequency */}
        </td>
        <td>
          <span>{this.formatNumber(lbs)}</span>
          {/* lbs */}
        </td>
        <td>
          <span>{this.formatNumber(lbs / frequency)}</span>
          {/* avgLbs */}
        </td>
        <td>
          <span>{this.formatNumber(miles)}</span>
          {/* miles */}
        </td>
        <td>
          <span>{this.formatNumber(miles / frequency)}</span>
          {/* avgMiles */}
        </td>
        <td>
          <span>{this.formatNumber(hours)}</span>
          {/* hours */}
        </td>
        <td>
          <span>{this.formatNumber(hours / frequency)}</span>
          {/* avgHours */}
        </td>
        <td>
          <span>{this.formatNumber(accuracies / frequency)}%</span>
          {/* accuracy */}
        </td>
      </tr>
    )

    return row
  }

  putDataInGroupByDriver() {
    const { driversReport } = this.props
    const drivers = []

    if (driversReport) {
      driversReport.forEach(reportItem => {
        if (!reportItem.isTotalRow) {
          // Not total row
          if (
            !drivers.some(driver => driver.driverId === reportItem.driverId)
          ) {
            drivers.push({
              driverId: reportItem.driverId,
              reports: [reportItem],
            })
          } else {
            drivers.forEach(driver => {
              if (driver.driverId === reportItem.driverId)
                driver.reports.push(reportItem)
            })
          }
        }
      })
    }

    return drivers
  }

  render() {
    const { loading } = this.props
    const driversReportGrouped = this.putDataInGroupByDriver()
    const tableRows = [
      ...driversReportGrouped
        .map(reportGroup => this.renderDriverData(reportGroup))
        .flat(),
      this.renderTotalRow(),
    ]

    return (
      <div
        style={{
          display: 'block',
          'overflow-x': 'auto',
        }}
      >
        <table className="ui sortable table">
          {this.renderTableHeader()}
          <tbody>
            {loading ? (
              <Dimmer active>
                <Loader />
              </Dimmer>
            ) : tableRows && tableRows.length ? (
              <React.Fragment>
                {tableRows.map((row, i) => {
                  return <React.Fragment>{row}</React.Fragment>
                })}
              </React.Fragment>
            ) : (
              'No data.'
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
