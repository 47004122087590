import gql from 'graphql-tag'

export const CompetitorModel = `
  id
  label
  selections {
    id
    label
  }
`

export const CompetitorLogModel = `
  competitor {
    ${CompetitorModel}
  }
  timestamp
  type
`

export const getLocationCompetitorHistory = gql`
  query($locationId: String!) {
    getLocationCompetitor(locationId: $locationId) {
      current_competitors {
        ${CompetitorModel}
      }
      competitor_history {
        ${CompetitorLogModel}
      }
    }
  } 
`
