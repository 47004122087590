import _map from 'lodash/map'
import moment from 'moment'
import React, { Fragment } from 'react'

import NumericTooltip from '../../components/NumericTooltip'
import { reduce } from 'lodash'
import { FormattedNumber } from 'react-intl'
import { Header, Label, Table } from 'semantic-ui-react'

const Routes = ({ routes }) => {
  return (
    <Fragment>
      {routes.map(route => (
        <Label
          id={route.id}
          style={{
            backgroundColor: route.color,
            color: 'white',
            marginBottom: '3px',
          }}
          size="tiny"
        >
          {route.name}
        </Label>
      ))}
    </Fragment>
  )
}

const TableRow = ({ date, weight, routes }) => (
  <Table.Row>
    <Table.Cell>{moment(date).format('LL')}</Table.Cell>
    <NumericTooltip value={weight || 0}>
      <Table.Cell width="2">
        <FormattedNumber value={parseInt(weight, 10)} />
      </Table.Cell>
    </NumericTooltip>
    <Table.Cell width="2">
      <Routes routes={routes} />
    </Table.Cell>
  </Table.Row>
)

export default class DailyWeights extends React.PureComponent {
  render() {
    const { monthDailyWeights } = this.props

    const total = reduce(
      monthDailyWeights,
      (total, report, index) => {
        return total + report.weight
      },
      0
    )

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Header as="h2">Daily Weights</Header>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_map(monthDailyWeights, (report, date) => (
            <TableRow
              key={date}
              date={date}
              weight={report.weight}
              routes={report.routes}
            />
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell textAlign="right">
              <Header>Total:</Header>
            </Table.HeaderCell>
            <NumericTooltip value={total || 0}>
              <Table.HeaderCell width="2" colSpan="2">
                <FormattedNumber value={parseInt(total, 10)} />
              </Table.HeaderCell>
            </NumericTooltip>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }
}
