import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import React from 'react'

import { locationFields } from '../../api/Query/locations'
import { partnershipFields } from '../../api/Query/partnerships'
import { permitFields } from '../../api/Query/permits'
import { withForm } from '../../connectors/forms'
import { WithLocations } from '../../connectors/locations'
import WithMutator from '../../connectors/mutator'

import { LoadingMessage } from '../../components/Loading'
import LocationTagEvents from '../../components/LocationTagEvents'
import ModalTrigger from '../../components/ModalTrigger'
import moment from 'moment'
import { Button, Grid, Message } from 'semantic-ui-react'
import Confirmation from './confirmation'
import LocationForm from './form'

@WithLocations
@withForm('LocationForm')
class AddLocation extends React.PureComponent {
  static defaultProps = {
    onSubmit: () => {},
    trigger: <Button>Go</Button>,
    closeModal: () => {},
    renderButtons: () => {},
  }

  state = {
    submitting: false,
  }

  componentDidMount() {
    this.renderButtons()
  }

  componentDidUpdate(prevProps) {
    if (_get(this.props, 'mutating') !== _get(prevProps, 'mutating')) {
      this.renderButtons()
    }
  }

  renderButtons() {
    const {
      renderButtons,
      closeModal,
      submitForm,
      mutating,
      editing,
      location,
      locationId,
    } = this.props

    return renderButtons(
      <Grid columns="equal">
        <Grid.Column>
          {editing && (
            <LocationTagEvents
              trigger={<Button floated="left">Tag History</Button>}
              locationId={_get(location, 'id', locationId)}
            />
          )}
        </Grid.Column>
        <Grid.Column>
          <Button negative onClick={closeModal} disabled={mutating}>
            Nevermind
          </Button>
          <Button
            positive
            onClick={submitForm}
            disabled={mutating}
            loading={mutating}
          >
            Next
          </Button>
        </Grid.Column>
      </Grid>
    )
  }

  setConfirmation = ({
    location,
    permit,
    partnership,
    bins,
    simple_location,
    location_type,
    ...address
  }) => {
    const groomedBins = _map(_filter(bins, bin => !!bin.id), bin =>
      _pick(bin, bin.created ? ['box_code'] : ['box_code', 'id'])
    )

    const additionalLocationFieldsFromPartnership = partnership
      ? {
          leased_location_cost_per_month:
            partnership.leased_location_cost_per_month,
          leased_location_period_start:
            partnership.leased_location_period_start,
          leased_location_period_end: partnership.leased_location_period_end,
          signed_location_by: partnership.signed_location_by,
          signed_location_date: partnership.signed_location_date,
          verbal_location_by: partnership.verbal_location_by,
          verbal_location_date: partnership.verbal_location_date,
          leased_atrs_rep: partnership.leased_atrs_rep,
          signed_atrs_rep: partnership.signed_atrs_rep,
          verbal_atrs_rep: partnership.verbal_atrs_rep,
        }
      : {
          leased_location_cost_per_month: null,
          leased_location_period_start: null,
          leased_location_period_end: null,
          signed_location_by: null,
          signed_location_date: null,
          verbal_location_by: null,
          verbal_location_date: null,
          leased_atrs_rep: null,
          signed_atrs_rep: null,
          verbal_atrs_rep: null,
        }

    // Need to do this cuz stuff
    const groomedLocation = {
      ..._omit(_pick(address, locationFields), ['id']),
      ...additionalLocationFieldsFromPartnership,
      permit: _pick(permit, permitFields),
      partnership: _pick(partnership, partnershipFields),
      bins: groomedBins,
      simple_location: simple_location ? true : false,
      location_type,
    }

    return this.setState({ data: groomedLocation })
  }

  denyConfirmation = () => {
    const data = null
    this.setState({ data })
  }

  submit = () => {
    const {
      onSubmit,
      closeModal,
      editing,
      location,
      createLocation,
      updateLocation,
    } = this.props

    const { data } = this.state

    if (data.date_in_service)
      data.date_in_service = moment(data.date_in_service).toISOString()

    const prom = editing
      ? updateLocation({ id: location.id, location: data })
      : createLocation({ location: data })

    return prom
      .then(location => {
        setImmediate(closeModal)
        return location
      })
      .then(onSubmit)
  }

  render() {
    const {
      loading,
      changeFieldValue,
      location,
      options,
      withBins = true,
      editing,
      mutating,
      onSubmit,
      error,
    } = this.props

    const { data } = this.state
    const locationMark = { ...data, role: 'selected' }

    // Inject some location props to its partnership
    if (location && location.partnership) {
      location.partnership = {
        ...location.partnership,
        leased_location_cost_per_month: location.leased_location_cost_per_month,
        leased_location_period_start: location.leased_location_period_start,
        leased_location_period_end: location.leased_location_period_end,
        signed_location_by: location.signed_location_by,
        signed_location_date: location.signed_location_date,
        verbal_location_by: location.verbal_location_by,
        verbal_location_date: location.verbal_location_date,
        leased_atrs_rep: location.leased_atrs_rep,
        signed_atrs_rep: location.signed_atrs_rep,
        verbal_atrs_rep: location.verbal_atrs_rep,
      }
    }

    if (loading)
      return (
        <LoadingMessage
          title="Location is loading"
          text="We are fetching the Location info."
        />
      )
    if (mutating) return <LoadingMessage title="Saving this Location" />
    if (error) return <Message negative {...error} />

    return (
      <React.Fragment>
        <LocationForm
          onSubmit={this.setConfirmation}
          initialValues={location}
          options={options}
          changeFieldValue={changeFieldValue}
          withBins={withBins}
        />
        <Confirmation
          editing={editing}
          open={!_isEmpty(data)}
          locationData={locationMark}
          locationId={_get(location, 'id')}
          confirm={this.submit}
          deny={this.denyConfirmation}
          confirmExisting={onSubmit}
        />
      </React.Fragment>
    )
  }
}

export default class SwitchFormAction extends React.PureComponent {
  render() {
    const editing =
      _has(this.props, 'location') || _has(this.props, 'locationId')
    const Form = editing
      ? WithMutator('updateLocation')(AddLocation)
      : WithMutator('createLocation')(AddLocation)

    return <Form {...this.props} editing={editing} />
  }
}

export const AddLocationModal = ({ trigger, ...props }) => {
  const editing = _has(props, 'location') || _has(props, 'locationId')

  return (
    <ModalTrigger
      trigger={trigger}
      form={<SwitchFormAction {...props} />}
      scrolling={true}
      title={editing ? 'Editing location' : 'Creating location'}
    />
  )
}
