import React from 'react'

import { Grid, Row, Col } from 'react-flexbox-grid'
import Header from './Header'
import MarketView from '../../views/Market'
import WithQuerySearch from '../../connectors/querySearch'

@WithQuerySearch
export default class Market extends React.PureComponent {
  render() {
    const { match: { params } } = this.props

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <MarketView marketId={params.marketId} />
      </Grid>
    )
  }
}
