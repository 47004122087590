import {
  _MAINTENANCE_REQUESTS_FETCH,
  _MAINTENANCE_REQUESTS_ERROR,
  _MAINTENANCE_REQUESTS_SEARCH,
  _MAINTENANCE_REQUESTS_LOADING,
  _MAINTENANCE_REQUEST_ADDED,
  _MAINTENANCE_REQUESTS_REMOVE,
} from '../actions/maintenanceRequests'

const initialState = {
  maintenanceRequests: [],
  maintenanceRequest: {},
  search: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _MAINTENANCE_REQUESTS_SEARCH:
      return {
        ...state,
        search: action.payload,
      }

    case _MAINTENANCE_REQUEST_ADDED:
      const found = state.maintenanceRequests.findIndex(
        maintenanceRequest => maintenanceRequest.id === action.payload.id
      )
      return {
        ...state,
        loading: false,
        maintenanceRequest: action.payload,
        error: null,
        maintenanceRequests:
          found >= 0
            ? Object.assign([], state.maintenanceRequests, {
                [found]: action.payload,
              })
            : [...state.maintenanceRequests, action.payload],
      }

    case _MAINTENANCE_REQUESTS_LOADING:
      return {
        ...state,
        loading: true,
      }

    case _MAINTENANCE_REQUESTS_FETCH:
      return {
        ...state,
        maintenanceRequests: action.payload,
        loading: false,
      }
    case _MAINTENANCE_REQUESTS_REMOVE:
      return {
        ...state,
        maintenanceRequests: state.users.filter(
          maintenanceRequest => maintenanceRequest.id !== action.payload
        ),
        loading: false,
      }

    case _MAINTENANCE_REQUESTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default reducer
