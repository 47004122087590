import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { DatePicker } from 'rsuite'
import './style.scss'
import 'rsuite/DatePicker/styles/index.css';

const CustomToolbar = toolbar => {
  const [datePickerIsOpen, setDatePicker] = useState(false)

  const goToBack = () => {
    const previuesWeekStart = toolbar.localizer.add(toolbar.date, -7, 'day')
    toolbar.onNavigate('prev', previuesWeekStart)
  }

  const goToNext = () => {
    const nextWeekStart = toolbar.localizer.add(toolbar.date, 7, 'day')
    toolbar.onNavigate('next', nextWeekStart)
  }

  const goToCurrent = () => {
    const now = new Date()
    toolbar.onNavigate('current', now)
  }

  const onDatePickerButtonClick = () => {
    if (!datePickerIsOpen) setDatePicker(true)
  }

  const onSelectDate = date => {
    toolbar.onNavigate('current', date)
    closeDatePicker()
  }

  const closeDatePicker = () => {
    setDatePicker(false)
  }

	const hanldeOutsideClick = e => {
		const datePickerContainer = e.target.closest('.rs-calendar')
		const datePickerButton = e.target.closest('.date-picker-button')

		if(!datePickerContainer && !datePickerButton) {
			closeDatePicker()
		} else {
			setDatePicker(true)
		}
	}

  useEffect(() => {
    document.addEventListener('click', hanldeOutsideClick)

    return function removeEventListener() {
      document.removeEventListener('click', hanldeOutsideClick)
    }
  }, [])

  return (
    <div className="Schedule_Calendar__Toolbar" id="Schedule_Calendar__Toolbar">
      <div className="rbc-toolbar">
        <span className="rbc-toolbar-label">{toolbar.label}</span>
        <div className="buttons">
          <button
            type="button"
            className="date-picker-button"
            onClick={onDatePickerButtonClick}
          >
            Go to date
			<DatePicker
				caretAs={null}
              ranges={[]}
              open={datePickerIsOpen}
              placement="bottomEnd"
              onSelect={onSelectDate}
            />
          </button>

          <span className="rbc-btn-group">
            <button type="button" onClick={goToBack}>
              Back
            </button>
            <button type="button" onClick={goToCurrent}>
              Today ({moment().format('MMM DD YYYY')})
            </button>
            <button type="button" onClick={goToNext}>
              Next
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

export default CustomToolbar
