import gql from 'graphql-tag'

import { Partnership } from './partnerships'
import { Permit } from './permits'

export const LocationTagEvent = `
  children{
    id
    title
    label
  }
  driver{
    id
    first_name
  }
  routeId
  timestamp
  title
`
export const LocationBinEvent = `
  driver{
    id
    first_name
  }
  routeId
  timestamp
  weight
`

export const LocationRunReport = `
  location_actual
  location_estimated
  location_id
  run_date
  run_id
  run_total_actual
  run_total_estimated
  route {
    id
    color
    name
  }
`

export const LocationRoute = `
  id
  name
  color
  code
  market {
    id
  }
  inactive
`

export const locationFields = [
  'id',
  'name',
  'address',
  'city',
  'state',
  'postal',
  'latitude',
  'longitude',
  'permit_required',
  'partnership',
  'permit',
  'status',
  'date_in_service',
  'date_re_added',
  'date_removed_from_route',
  'date_repositioning',
  'rate',
  'gis_number',
  'npo_name',
  'npo_cost_per_lb',
  'leased_location_cost_per_month',
  'leased_location_period_start',
  'leased_location_period_end',
  'signed_location_by',
  'signed_location_date',
  'verbal_location_by',
  'verbal_location_date',
  'leased_atrs_rep',
  'signed_atrs_rep',
  'verbal_atrs_rep',
  'simple_location',
  'location_type',
]

export const LocationPartial = `
  id
  name
  address
  city
  state
  postal
  latitude
  longitude
  permit_required
  partnership {
    ${Partnership}
  }
  permit {
    ${Permit}
  }
  status
  market
  routes {
    id
    name
    color
    code
    market {
      id
    }
    inactive
  }
  rate
  date_in_service
  date_re_added
  date_removed_from_route
  date_repositioning
  gis_number
  npo_name
  npo_cost_per_lb
  leased_location_cost_per_month
  leased_location_period_start
  leased_location_period_end
  signed_location_by
  signed_location_date
  verbal_location_by
  verbal_location_date
  leased_atrs_rep
  signed_atrs_rep
  verbal_atrs_rep
  simple_location
  location_type
  conversion_history {
    at
    converted_to
  }
`

export const Location = `
  ${LocationPartial}
  bins {
    id
    box_code
  }
`

export const nearestLocation = `
  ... on NearestLocation {
    distance
  }
`

export const TaggedLocation = `
  id
  address
  binId
  name
  route {
    name,
    color
  }
  routeId
  runId
  tagId
  timestamp
`

export const LocationMonthEstimatedActualTotal = `
  year
  month
  total
`

export const getLocations = gql`
  query data($ids: [String]!) {
    locations(ids: $ids) {
      ${Location}
    }
  }
`

export const searchLocations = gql`
  query data($geoCoordinates: [Float], $radius: Float, $simple_locations: Boolean) {
    locations(geoCoordinates: $geoCoordinates, radius: $radius, simpleLocations: $simple_locations) {
      ${Location}
      ${nearestLocation}
    }
  }
`

export const taggedLocations = gql`
  query data($binTags: [TagInfoInput], $startDate: String, $endDate: String, $marketId: String, $marketIds: [String]) {
    taggedLocations(binTags: $binTags, startDate: $startDate, endDate: $endDate, marketId: $marketId, marketIds: $marketIds) {
      ${TaggedLocation}
    }
  }
`

export const getLocationHistory = gql`
  query($locationId: String!) {
    location(id: $locationId) {
      id
      history {
        ${LocationTagEvent}
      }
    }
  }
`

export const getLocationMaintenanceRequestsHistory = gql`
  query($locationId: String!) {
    location(id: $locationId) {
      id
      maintenanceRequestsHistory {
        id
        binId
        date
        bin {
          serial_number
        }
      }
    }
  }
`

export const getLocationPoliceReportsHistory = gql`
  query($locationId: String!) {
    location(id: $locationId) {
      id
      policeReportsHistory {
        id
        binId
        date_of_incident
        bin {
          serial_number
        }
      }
    }
  }
`

export const getLocationBinLocationsHistory = gql`
  query($locationId: String!) {
    location(id: $locationId) {
      id
      binLocationsHistory {
        id
        binId
        timestamp
        type
        bin {
          serial_number
        }
      }
    }
  }
`

export const getLocationBinsHistory = gql`
  query($locationId: String!) {
    location(id: $locationId) {
      id
      binsHistory {
        ${LocationBinEvent}
      }
    }
  }
`

export const getLocationEstimatedActualReports = gql`
  query($locationId: String!) {
    location(id: $locationId) {
      id
      locationEstimatedActualReports {
        ${LocationRunReport}
      }
    }
  }
`
export const getLocationMonthlyEstimatedActualReports = gql`
  query($locationId: String!, $start: String!, $end: String!) {
    locationMonthlyEstimatedActualReports(locationId: $locationId, start: $start, end: $end) { 
      ${LocationMonthEstimatedActualTotal}
    }
  }
`

export const createLocation = gql`
  mutation createLocation($location: LocationInput!) {
    createLocation(location: $location) { ${Location} }
  }
`

export const updateLocation = gql`
  mutation updateLocation($id: String!, $location: LocationInput!) {
    updateLocation(id: $id, location: $location) { ${Location} }
  }
`

export const getLocationRoutes = gql`
  query data($locationId: String!) {
    locationRoutes(locationId: $locationId) {
      ${LocationRoute}
    }
  }
`
