import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Header from './Header'
import ReportsDriversView from '../../views/ReportsDrivers'

import './styles.scss'

export default class ReportsDrivers extends React.PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Header />
          </Col>
        </Row>
        <Switch>
          <Route component={ReportsDriversView} />
        </Switch>
      </Grid>
    )
  }
}
