import React from 'react'

import { ApolloProvider } from 'react-apollo'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { QuerySearchProvider } from './connectors/querySearch'

import apolloClient from './api/apolloClient'
import configureStore from './store'
import Routes from './routes'

import ReactGA from 'react-ga'

import moment from 'moment'

ReactGA.initialize('UA-114061143-1', { testMode: true })
const store = configureStore()

const history = createBrowserHistory()
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

moment.updateLocale('en', {
  week: {
    dow: 6,
  },
})

class App extends React.Component {
  render() {
    return (
      <IntlProvider locale="en">
        <Provider store={store}>
          <ApolloProvider client={apolloClient}>
            <Router history={history}>
              <QuerySearchProvider>
                <Routes />
              </QuerySearchProvider>
            </Router>
          </ApolloProvider>
        </Provider>
      </IntlProvider>
    )
  }
}

export default App
