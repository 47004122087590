import {
  _RUN_CLEAR_DRIVER,
  _RUN_COPIED_SUCCESSFULLY,
  _RUN_COPY_FAILED,
  _RUN_EDITED_SUCCESSFULLY,
  _RUN_EDIT_FAILED,
  _RUN_FETCH_DRIVER,
  _RUN_FETCH_ERROR,
  _RUN_FETCH_LOADING,
  _RUN_FETCH_MARKET,
  _RUN_FETCH_ROUTE,
  _RUN_MOVED_SUCCESSFULLY,
  _RUN_MOVE_FAILED,
  _RUN_SAVED,
  _RUN_SCHEDULED,
  _RUN_SCHEDULED_ERROR,
} from '../actions/runs'

import { _AUTH_AUTHENTICATE } from '../actions/auth'

const initialState = {
  markets: [],
  routes: [],
  drivers: [],
  driver: null,
  route: null,
  loading: false,
  error: null,
  saved: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _RUN_FETCH_LOADING:
      return {
        ...state,
        loading: true,
        saved: false,
      }
    case _AUTH_AUTHENTICATE:
      return {
        ...state,
        markets: action.payload.markets,
        loading: false,
      }
    case _RUN_CLEAR_DRIVER:
      return {
        ...state,
        driver: null,
      }
    case _RUN_FETCH_DRIVER:
      return {
        ...state,
        driver: action.payload,
      }
    case _RUN_FETCH_ROUTE:
      return {
        ...state,
        route: action.payload,
        loading: false,
      }
    case _RUN_SCHEDULED:
      return {
        ...state,
        loading: false,
        saved: true,
      }
    case _RUN_SCHEDULED_ERROR:
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      }
    case _RUN_FETCH_MARKET:
      return {
        ...state,
        drivers: action.payload.drivers,
        routes: action.payload.routes,
        loading: false,
      }
    case _RUN_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case _RUN_SAVED:
      return {
        ...state,
        loading: false,
      }
    case _RUN_COPY_FAILED:
      return {
        ...state,
        loading: false,
      }
    case _RUN_COPIED_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
      }
    case _RUN_MOVE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case _RUN_MOVED_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
      }
    case _RUN_EDIT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case _RUN_EDITED_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
