import React from 'react'
import 'url-search-params-polyfill'

import { withMarketData, withMarkets } from '../../connectors/markets'
import { WithActualVsGoals } from '../../connectors/goals'

import { Divider } from 'semantic-ui-react'
import ActualVSGoalReport from './ActualvsGoalReport'
import Filters from './Filters'

@withMarkets
@withMarketData
@WithActualVsGoals
export default class ReportsMonthlyGoalEntry extends React.PureComponent {
  componentDidMount() {
    const { getMarkets, setCurrent, location: { search } } = this.props

    getMarkets()

    const query = new URLSearchParams(search)
    const market = query.get('market')
    if (market) {
      setCurrent(market)
    }
  }

  extractRoutes() {
    const { data } = this.props
    const routes = []

    if (!data) return routes

    if (!data.actualVsGoal) return routes

    if (data.actualVsGoal.length > 1) return routes

    data.actualVsGoal.forEach(marketReport => {
      marketReport.report.forEach(yearReport => {
        yearReport.routes.forEach(reportRoute => {
          if (!routes.some(route => route.id === reportRoute.id))
            routes.push({
              id: reportRoute.id,
              name: reportRoute.name,
            })
        })
      })
    })

    return routes
  }

  render() {
    const { location: { search }, loading, data } = this.props
    const query = new URLSearchParams(search)
    const selectedMarkets = query.get('selected-markets')
      ? query.get('selected-markets').split(',')
      : []

    const routes = this.extractRoutes()

    return (
      <div>
        <Divider hidden />
        <Filters loading={loading} routes={routes} />
        <Divider hidden />
        <ActualVSGoalReport
          marketId={query.get('market')}
          selectedMarkets={selectedMarkets}
          routes={routes}
          year={query.get('year')}
          routeId={query.get('route')}
          loading={loading}
          data={data}
        />
      </div>
    )
  }
}
