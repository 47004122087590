import React from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import _concat from 'lodash/concat'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _isEqual from 'lodash/isEqual'

import { withAuth } from '../../connectors/auth'
import { WithTagsByMarketsAndDates } from '../../connectors/reportTags'
import ReportTagsInput from '../ReportTagsInput'

import './styles.scss'

@withAuth
@WithTagsByMarketsAndDates
export default class ReportFilteredTagsField extends React.PureComponent {
  state = {
    binTags: [],
  }

  componentWillReceiveProps(nextProps) {
    const {
      tagsByMarketsAndDates: currentTagsByMarketsAndDates,
      value: currentValue,
    } = this.props

    const { tagsByMarketsAndDates, value } = nextProps
    if (
      !!tagsByMarketsAndDates &&
      (!_isEqual(value, currentValue) ||
        !_isEqual(tagsByMarketsAndDates, currentTagsByMarketsAndDates))
    ) {
      this.setOptionsAndRemoveUnavailableBinTagsBasedOnCriteria(nextProps)
    }
  }

  setOptionsAndRemoveUnavailableBinTagsBasedOnCriteria({
    value,
    tagsByMarketsAndDates,
    onChange,
  }) {
    const binTags = _filter(value, tag =>
      _find(tagsByMarketsAndDates, option => tag.id === option.id)
    )

    _forEach(binTags, tag => {
      if (!!tag.children) {
        if (!!_find(tag.children, subtag => subtag.id === 'all')) {
          tag.children = _filter(tag.children, subtag => subtag.id === 'all')
        }
      }
    })

    this.setState({ binTags })
    onChange(binTags)
  }

  render() {
    const { label, loading, onChange, tagsByMarketsAndDates } = this.props

    const { binTags } = this.state

    const options = _cloneDeep(tagsByMarketsAndDates)

    _forEach(options, option => {
      if (
        option.children &&
        option.children.length > 1 &&
        !_find(option.children, subtag => subtag.id === 'all')
      ) {
        option.children = _concat({ id: 'all', title: 'All' }, option.children)
      }
    })

    return (
      <ReportTagsInput
        label={label}
        loading={loading}
        onChange={onChange}
        options={options}
        shouldNotLoadReportTagsDictionary={true}
        value={binTags}
      />
    )
  }
}
