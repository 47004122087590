import moment from 'moment'
import {
  _MARKETS_CLEAR_ERROR,
  _MARKETS_CREATE,
  _MARKETS_DATA_LOADED,
  _MARKETS_DATA_RANGE,
  _MARKETS_ERROR,
  _MARKETS_FLAT_LOADED,
  _MARKETS_LOAD_DRIVERS,
  _MARKETS_LOADED,
  _MARKETS_LOADING,
  _MARKETS_SET_CURRENT,
  _MARKETS_SET_CURRENT_RANGE,
  _MARKETS_SET_SELECTED_MARKETS_DASHBOARD,
} from '../actions/markets'

const initialState = {
  markets: [],
  current: null,
  error: null,
  loading: false,
  range: {
    start: moment().startOf('month'),
    end: moment().endOf('month'),
    named: moment().format('YYYY-MM'),
  },
  selectedMarketsDashboard: [],
  data: [],
  dataRange: [],
  drivers: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case _MARKETS_CREATE:
      return {
        ...state,
        loading: false,
      }
    case _MARKETS_LOAD_DRIVERS:
      return {
        ...state,
        drivers:
          action.payload &&
          action.payload.map(driver => ({
            ...driver,
            name: `${driver.first_name} ${driver.last_name}`,
          })),
        error: null,
        loading: false,
      }
    case _MARKETS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case _MARKETS_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      }

    case _MARKETS_LOADED:
      return {
        ...state,
        loading: false,
        markets: action.payload,
        error: null,
      }

    case _MARKETS_FLAT_LOADED:
      return {
        ...state,
        loading: false,
        flatMarkets: action.payload,
      }

    case _MARKETS_DATA_LOADED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }

    case _MARKETS_DATA_RANGE:
      return {
        ...state,
        loading: false,
        dataRange: action.payload,
        error: null,
      }

    case _MARKETS_SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      }

    case _MARKETS_SET_CURRENT_RANGE:
      return {
        ...state,
        range: action.payload,
      }

    case _MARKETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case _MARKETS_SET_SELECTED_MARKETS_DASHBOARD:
      return {
        ...state,
        selectedMarketsDashboard: action.payload,
      }
    default:
      return state
  }
}

export default reducer
